<template>
  <tr :class="{ editing: isOpen }">
    <td
      class="p-0"
      :colspan="colspan"
    >
      <collapsible-content :is-open="isOpen">
        <slot />
      </collapsible-content>
    </td>
  </tr>
</template>

<script>
import CollapsibleContent from '@/components/CollapsibleContent.vue'
export default {
  components: {
    CollapsibleContent,
  },
  props: ['colspan', 'isOpen'],
}
</script>

<style lang="scss">
.editable-row {
  &-content-section {
    // stacked content sections separated by a white divider
    border-bottom: 2px solid theme('colors.white');
    padding: 1.25em;
    background: theme('colors.white');

    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
