<template>
  <PageSection>
    <SectionHeader>
      <template #section-title>{{ $t('taxman.implicitFacets.select') }}</template>
      <template #section-actions>
        <p v-if="isValidating">
          {{ $t('taxman.isValidating') }}<Loading-spinner class="spinner--md flex-inline ml-3" />
        </p>
      </template>
    </SectionHeader>

    <div class="splitView border-t border-t-slate">
      <div class="splitView-unit">
        <h3 class="text-acai splitView-title">
          {{ $t('taxman.implicitFacets.available') }}
        </h3>

        <TreeOfFacets
          id="parentbox"
          :sticky-search-selectors="{
            parent: '#parentbox',
            relative: '#parentbox',
          }"
          :node-classes="
            (nodeId) => ({
              'is-checked': isFacetSelected(nodeId),
            })
          "
          class="TreeBox--border-top"
          :key-prefix="'implicit'"
        >
          <template #node="{ nodeId, modifiers }">
            <div
              v-if="byId(nodeId).parentId !== null"
              v-test="'node-controls'"
              class="Tree-controls"
              :class="{ 'is-active': modifiers['is-checked'] }"
            >
              <template v-if="modifiers['is-checked']">
                <span class="iconCircle iconCircle--sm">
                  <SvgIcon
                    class="text-blueberry"
                    name="24-ic-check"
                  />
                </span>
              </template>
              <template v-else>
                <button
                  class="button button--icon button--round button--primary"
                  @click="addFacet(nodeId)"
                >
                  <SvgIcon name="24-ic-check" />
                </button>
              </template>
            </div>
          </template>
        </TreeOfFacets>
      </div>

      <div class="splitView-unit">
        <h3 class="text-acai splitView-title">
          {{ $t('taxman.implicitFacets.selected') }}
        </h3>

        <ul class="Tree">
          <TreeLayout
            :node-id="'root'"
            :get-children="(nodeId) => selected[nodeId] || []"
            :key-prefix="'selected'"
            :expanded="true"
          >
            <template #default="{ nodeId }">
              <NodeFacet
                :record="byId(nodeId)"
                :detail-level="getDetailLevelById(byId(nodeId).detailLevel)"
              />

              <div
                v-if="byId(nodeId).parentId !== null"
                v-test="'node-controls'"
                class="Tree-controls is-active"
              >
                <button
                  class="button button--icon button--round button--primary"
                  @click="removeFacet(nodeId)"
                >
                  <SvgIcon name="24-ic-clear" />
                </button>
              </div>
            </template>
            <!-- Parked for future update
            <template #marker="{ item, level }">
              <span class="Tree-marker" v-if="level > 1">
                <span class="marker is-warning" v-for="error in facetWarnings(item)" :key="error.id">
                  {{error.id}}
                </span>
                <span class="marker is-invalid" v-for="error in facetErrors(item)" :key="error.id">
                  {{error.id}}
                </span>
              </span>
            </template> -->
          </TreeLayout>
        </ul>

        <ValidationGroup
          :validator="v$"
          :server-errors="serverErrors"
          :server-warnings="serverWarnings"
          class="mx-4 mt-4"
        />
      </div>
    </div>
  </PageSection>
</template>

<script>
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'
import { pathToRoot } from '@/store/utils/taxonomy'

import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import TreeLayout from '@/components/TreeLayout.vue'
import TreeOfFacets from '@/components/TreeOfFacets.vue'
import NodeFacet from '@/components/NodeFacet.vue'

export default {
  components: {
    ValidationGroup,
    TreeLayout,
    TreeOfFacets,
    NodeFacet,
  },
  inject: ['validateWithServer'],
  props: {
    buffer: Array,
    meta: Object,
    v$: Object,
    updateBuffer: Function,
    updateMeta: Function,
    serverErrors: Array,
    serverWarnings: Array,
    isValidating: Boolean,
  },

  computed: {
    ...mapGetters('taxonomy', ['getDetailLevelById']),
    ...mapGetters('taxonomy/facets', ['byId']),

    selected() {
      return {
        root: Object.keys(this.meta),
        ...this.meta,
      }
    },
  },

  async created() {
    if (this.$store.getters['taxonomy/facets/isEmpty']) {
      this.$store.dispatch('taxonomy/facets/getTree')
    }
  },

  methods: {
    addFacet(nodeId) {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      let current = this.buffer
      if (current === null) {
        current = []
      }

      current.push(nodeId)
      this.updateBuffer(current)

      const rootId = pathToRoot(nodeId, this.$store.getters['taxonomy/facets/byId']).pop()
      const hash = cloneDeep(this.meta)

      if (hash[rootId]) {
        hash[rootId].push(nodeId)
      } else {
        hash[rootId] = [nodeId]
      }

      this.updateMeta(hash)

      this.validateWithServer()
    },

    removeFacet(nodeId) {
      let current = this.buffer

      current.splice(current.indexOf(nodeId), 1)
      if (current.length < 1) {
        current = null
      }

      this.updateBuffer(current)

      const hash = cloneDeep(this.meta)

      // inneficient like this because we can't expect to load the full tree first
      const rootId = Object.keys(hash).find((key) => {
        return hash[key].includes(nodeId)
      })
      const target = hash[rootId].indexOf(nodeId)

      hash[rootId].splice(target, 1)

      if (hash[rootId].length < 1) {
        delete hash[rootId]
      }

      this.updateMeta(hash)

      this.validateWithServer()
    },

    isFacetSelected(nodeId) {
      return this.buffer && this.buffer.indexOf(nodeId) !== -1
    },

    // Used for markers in the future
    facetErrors(item) {
      return this.serverErrors.filter((e) => e.detail !== null && e.detail.includes(item.id))
    },
    facetWarnings(item) {
      return this.serverWarnings.filter((e) => e.detail !== null && e.detail.includes(item.id))
    },

    renderCondition(node, level) {
      return level === 1 ? node.hasChildren === true : true
    },
  },
}
</script>
