import api from '@/store/api/taxonomy'
import { normalizeSearch, normalizeImplicitFacets } from '@/store/utils/taxonomy'

const STATUS_INITIAL = 'INITIAL'
const STATUS_LOADING = 'LOADING'
const STATUS_ERROR = 'ERROR'
const STATUS_SUCCESS = 'SUCCESS'

const initialState = () => ({
  records: {},
  treeStructure: {},
  hash: null,
  meta: null,
  error: null,
  status: STATUS_INITIAL,
  treePromise: null,
})

const state = initialState()

export const newTermModel = ({ parentId = null, termType = null, detailLevel = null }) => {
  return {
    id: null,
    parentId,
    termName: null,
    displayName: null,
    origin: 'WN',
    detailLevel,
    termType,
    description: null,
    implicitFacets: null,
    ingredientFacetParentId: null,
    _meta: {
      implicitFacetsByRoots: {},
    },
  }
}

const getters = {
  byId: (state) => (id) => state.records[id],
  byCode: (state) => (code) => Object.values(state.records).find((rec) => rec.code === code),
  childIds: (state) => (id) => {
    const target = state.treeStructure[id]
    return target || []
  },

  isEmpty: (state) => state.status === STATUS_INITIAL,
  isSuccessful: (state) => state.status === STATUS_SUCCESS,
  isLoading: (state) => state.status === STATUS_LOADING,
  isError: (state) => state.status === STATUS_ERROR,
  allTermChildIds: (state, getters) => (termId) => {
    const { childIds: childIdsGetter } = getters
    let childIds = [termId]

    const cumulateChildIds = function cumulate(children) {
      children.forEach((childId) => {
        childIds.push(childId)
        if (childIdsGetter(childId)) {
          cumulate(childIdsGetter(childId))
        }
      })
    }

    cumulateChildIds(childIdsGetter(termId))

    return childIds
  },

  treePromise: (state) => state.treePromise,
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },

  async getTree({ commit, state }) {
    try {
      commit('SET_STATUS', STATUS_LOADING)

      state.treePromise = api.fetchTermsTree()
      const response = await state.treePromise
      const { data, metadata: meta } = response.data

      commit('BUILD_TREE', data)

      commit('taxonomy/TYPES_SET_DATA', meta.termTypes, { root: true })
      commit('taxonomy/LEVELS_SET_DATA', meta.detailLevels, { root: true })

      commit('SET_STATUS', STATUS_SUCCESS)
    } catch (error) {
      commit('SET_STATUS', STATUS_ERROR)
      throw error
    }
  },

  async checkHash({ state }) {
    const response = await api.fetchTermsHash()
    const { hash } = response.data.data

    if (hash !== state.hash) {
      return false
    }

    return true
  },

  async getById({ commit }, id) {
    const response = await api.fetchTerm(id)
    const { data } = response.data

    const normalized = normalizeImplicitFacets(data.implicitFacets)

    const record = {
      ...data,
      implicitFacets: normalized.ids,
      _meta: {
        implicitFacetsByRoots: normalized.rootHash,
      },
    }

    commit('STORE_RECORD', record)
    commit('taxonomy/facets/STORE_RECORDS', normalized.records, { root: true })

    return record
  },

  async search(context, { query, options = {} }) {
    const response = await api.fetchTerms(query, options.cancelToken)
    let { data, metadata: meta } = response.data

    data = normalizeSearch(data)

    return { data, meta }
  },

  async getWhere({ commit }, { query } = {}) {
    const response = await api.fetchTerms(query)
    const { data, metadata: meta } = response.data

    commit('STORE_RECORDS', data)

    return { data, meta }
  },

  async getLinks(context, id) {
    const response = await api.fetchTermLinks(id)
    const { data } = response.data

    return data
  },

  saveTerm({ commit }, { oldModel, newModel }) {
    // POST
    let method = api.postTerm
    let args = { ...newModel }

    delete args._meta

    let onSuccess = ({ data: record, metadata: meta }) => {
      commit('STORE_RECORD', record)
      commit('ADD_TO_TREE_STRUCTURE', {
        parentId: record.parentId,
        childId: record.id,
      })

      if (meta.hash) {
        commit('UPDATE_HASH', meta.hash)
      }
    }

    // PUT
    if (newModel.id !== null) {
      method = api.putTerm

      args = { oldModel, newModel }

      onSuccess = ({ data, metadata: meta }) => {
        const normalized = normalizeImplicitFacets(data.implicitFacets)

        const record = {
          ...data,
          implicitFacets: normalized.ids,
          _meta: {
            implicitFacetsByRoots: normalized.rootHash,
          },
        }

        commit('STORE_RECORD', record)
        commit('taxonomy/facets/STORE_RECORDS', normalized.records, { root: true })

        if (meta.hash) {
          commit('UPDATE_HASH', meta.hash)
        }
      }
    }

    return new Promise((resolve, reject) => {
      method(args)
        .then(({ data }) => {
          onSuccess(data)

          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeFromRecordsAndTreeStructure({ commit, getters }, id) {
    const term = state.records[id]
    const allTermChildIds = getters.allTermChildIds(id)
    const parentId = term.parentId

    commit('REMOVE_FROM_RECORDS', allTermChildIds)
    commit('REMOVE_FROM_TREE_STRUCTURE', { id, parentId, allTermChildIds })
  },

  deleteTerm({ state }, id) {
    const term = state.records[id]

    return new Promise((resolve, reject) => {
      api
        // DELETE
        .deleteTerm(term.code)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  moveTerms({ getters, commit }, { termId, newParentId }) {
    const { byId } = getters

    const termCode = byId(termId).code
    const oldParentCode = byId(byId(termId).parentId).code
    const newParentCode = byId(newParentId).code

    const oldModel = {
      code: termCode,
      parentCode: oldParentCode,
      hierarchyCode: 'baseterm',
    }

    const newModel = {
      code: termCode,
      parentCode: newParentCode,
      hierarchyCode: 'baseterm',
    }

    return new Promise((resolve, reject) => {
      api
        // PUT
        .moveNodes('terms', { oldModel, newModel })
        .then(
          ({
            data: {
              data: { id, parentId: newParentId },
              metadata,
            },
          }) => {
            const record = byId(id)
            const oldParentId = record.parentId

            commit('MOVE_TERM_IN_TREE_STRUCTURE', {
              id,
              oldParentId,
              newParentId,
            })

            commit('STORE_RECORD', { ...record, parentId: newParentId })

            if (metadata) {
              commit('UPDATE_HASH', metadata.hash)
            }

            resolve()
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  },

  validateTerm(context, term) {
    return new Promise((resolve, reject) => {
      api
        .validateTerm(term)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  },

  RESET_STATE(state) {
    Object.assign(state, initialState())
  },

  REMOVE_FROM_RECORDS(state, idsList) {
    const { records } = state

    idsList.forEach((id) => {
      delete records[id]
    })
  },

  STORE_RECORDS(state, newRecords) {
    const { records } = state

    newRecords.forEach((newRecord) => {
      records[newRecord.id] = newRecord
    })
  },

  STORE_RECORD(state, newRecord) {
    const { records } = state

    records[newRecord.id] = newRecord
  },

  STORE_ERROR(state, error) {
    state.error = error
  },

  BUILD_TREE(state, data) {
    state.records = data.records
    state.treeStructure = data.treeStructure
    state.hash = data.hash
  },

  UPDATE_HASH(state, hash) {
    state.hash = hash
  },

  ADD_TO_TREE_STRUCTURE(state, { parentId, childId }) {
    const found = state.treeStructure[parentId]

    if (!found) {
      state.treeStructure[parentId] = [childId]
    } else {
      state.treeStructure[parentId].push(childId)
    }
  },

  MOVE_TERM_IN_TREE_STRUCTURE(state, { id, oldParentId, newParentId }) {
    const idIndex = state.treeStructure[oldParentId].indexOf(id)

    state.treeStructure[oldParentId].splice(idIndex, 1)

    if (state.treeStructure[newParentId] === undefined) {
      state.treeStructure[newParentId] = []
    }
    state.treeStructure[newParentId].push(id)
  },

  REMOVE_FROM_TREE_STRUCTURE(state, { id, parentId, allTermChildIds }) {
    const { treeStructure } = state
    const parentTreeStructure = treeStructure[parentId]
    const termIndex = parentTreeStructure.indexOf(id)
    // remove the deleted term from the parent tree structure
    parentTreeStructure.splice(termIndex, 1)

    allTermChildIds.forEach((id) => {
      delete treeStructure[id]
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
