<template>
  <div class="box UI">
    <p>Just plain elements, no spacing by default</p>

    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>

    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe modi nam harum quos, architecto
      dolore ducimus amet deleniti quo itaque sit temporibus aliquid soluta minima, reiciendis
      pariatur repudiandae vero magnam!
    </p>

    <hr />

    <div class="copy">
      <p>This is a copy block</p>

      <h1>Heading 1</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe modi nam harum quos,
        architecto dolore ducimus amet deleniti quo itaque sit temporibus aliquid soluta minima,
        reiciendis pariatur repudiandae vero magnam!
      </p>

      <h2>Heading 2</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt, aspernatur. Sed magnam
        perspiciatis quae inventore, deserunt unde tenetur similique consectetur. Blanditiis
        laboriosam ipsa saepe, sed obcaecati delectus dolor nostrum magni.
      </p>

      <ul>
        <li>Unordered list item.</li>
        <li>Aliquam tincidunt mauris eu risus.</li>
        <li>
          Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
          ultricies in, diam. Sed arcu. Cras consequat
        </li>
        <li>Vestibulum auctor dapibus neque.</li>
        <li>Nunc dignissim risus id metus.</li>
      </ul>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim nam sed deleniti delectus
        aspernatur assumenda! Fugit iste provident ex pariatur perferendis nemo porro labore,
        recusandae repellat in, accusantium praesentium! Rerum?
      </p>

      <ol>
        <li>Ordered list item.</li>
        <li>Aliquam tincidunt mauris eu risus.</li>
        <li>
          Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
          ultricies in, diam. Sed arcu. Cras consequat
        </li>
        <li>Vestibulum auctor dapibus neque.</li>
        <li>Nunc dignissim risus id metus.</li>
      </ol>

      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur voluptate nulla iure
        amet accusantium rem ipsa quos, nobis quis, fugit eveniet itaque tempora atque delectus
        possimus! Dolor quae tempora quam!
      </p>

      <dl>
        <dt>Definition list</dt>
        <dd>Nunc dignissim risus id metus.</dd>
        <dt>Vestibulum auctor dapibus neque.</dt>
        <dd>Nunc dignissim risus id metus.</dd>
      </dl>

      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur voluptate nulla iure
        amet accusantium rem ipsa quos, nobis quis, fugit eveniet itaque tempora atque delectus
        possimus! Dolor quae tempora quam!
      </p>

      <h3>Heading 3</h3>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consequuntur voluptate nulla iure
        amet accusantium rem ipsa quos, nobis quis, fugit eveniet itaque tempora atque delectus
        possimus! Dolor quae tempora quam!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UITypography',
}
</script>
