<template>
  <div>
    <div class="table-responsive-actions">
      <slot name="ResponsiveActions"></slot>
    </div>
    <table
      role="grid"
      class="table"
    >
      <slot></slot>
    </table>
  </div>
</template>

<script>
import { uniq } from 'lodash'
export default {
  mounted() {
    this.setupStructure()
  },

  methods: {
    setupStructure() {
      const thead = this.$el.querySelector('thead')
      const tbody = this.$el.querySelector('tbody')
      const headings = [...thead.querySelectorAll('th')]
      const headingsText = headings.map((th) => th.innerText)
      const rows = [...tbody.querySelectorAll('tbody tr')]

      this.setAttribute(thead, 'role', 'rowgroup')
      this.setAttribute(tbody, 'role', 'rowgroup')
      this.setAttribute(thead.querySelector('tr'), 'role', 'row')
      headings.forEach((th) => this.setAttribute(th, 'role', 'columnHeader'))
      rows.forEach((tr) => this.setAttribute(tr, 'role', 'row'))

      rows.forEach((tr) => {
        this.setAttribute(tr, 'role', 'row')
        const cells = [...tr.querySelectorAll('td')]
        cells.forEach((td, index) => {
          td.setAttribute('data-th', headingsText[index])
          this.setAttribute(td, 'role', 'gridcell')
        })
      })
    },
    setAttribute(element, attribute, values) {
      element.setAttribute(
        attribute,
        uniq((element.getAttribute(attribute) || '').split(' ').concat(values)).join(' ')
      )
    },
  },
}
</script>
