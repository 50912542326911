<template>
  <footer class="footer">
    <div class="footer-column footer-column1">
      <h3 class="footer-title text-base mb-1">{{ $t('actions.emailUs') }}</h3>
      <p
        class="text-xs"
        v-html="$t('actions.directSupport')"
      />
    </div>

    <section class="footer-column footer-column2">
      <h3 class="footer-title text-base">{{ $t('actions.callUs') }}</h3>
      <section class="footer-contacts text-xs">
        <div>
          <h4 class="text-sm">{{ $t('regions.europe') }}</h4>
          <ul class="links">
            <li>
              <a :href="`tel:${contact.europe1.number}`">{{ contact.europe1.format }}</a>
            </li>
            <li>
              <a :href="`tel:${contact.europe2.number}`">{{ contact.europe2.format }}</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 class="text-sm">{{ $t('regions.na') }}</h4>
          <ul>
            <li>
              <a :href="`tel:${contact.na.number}`">{{ contact.na.format }}</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 class="text-sm">{{ $t('regions.apac') }}</h4>
          <ul>
            <li>
              <a :href="`tel:${contact.apac.number}`">{{ contact.apac.format }}</a>
            </li>
          </ul>
        </div>
        <div>
          <h4 class="text-sm">{{ $t('regions.mena') }}</h4>
          <ul>
            <li>
              <a :href="`tel:${contact.mena.number}`">{{ contact.mena.format }}</a>
            </li>
          </ul>
        </div>
      </section>
    </section>

    <section class="footer-column footer-column3">
      <h3 class="footer-title font-bold mb-1">{{ $t('titles.usingWinnow') }}</h3>
      <ul class="links">
        <li v-if="$can('read', 'waste-log')">
          <router-link
            v-test="{ footer: 'waste-log' }"
            :to="{
              name: 'waste-log',
            }"
            >{{ $t('actions.seeWasteLog') }}</router-link
          >
        </li>
        <li v-if="$can('read', 'covers-sales')">
          <router-link
            v-test="{ footer: 'covers-sales' }"
            :to="{
              name: 'covers-sales',
            }"
            >{{ $t('actions.addCoversSales') }}</router-link
          >
        </li>
        <li>
          <a
            v-test="{ footer: 'help-centre' }"
            href="http://help.winnowsolutions.com"
            target="_blank"
            >{{ $t('navigation.helpCentre') }}</a
          >
        </li>
      </ul>
    </section>
  </footer>
</template>

<script>
import contact from '@/contact.json'
import { mapGetters } from 'vuex'

export default {
  name: 'HubFooter',
  data() {
    return {
      contact,
    }
  },
  computed: {
    ...mapGetters({
      isReseller: 'auth/isReseller',
    }),
  },
}
</script>

<style lang="scss">
.footer {
  background-size: cover;
  color: theme('colors.grey.900');
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: stretch;
  gap: theme('spacing.8');
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: theme('spacing.4') theme('spacing.8') theme('spacing.8');
  flex-wrap: wrap;
  @include respond(medium) {
    flex-wrap: nowrap;
  }

  a {
    color: theme('colors.grey.900');
    text-decoration: underline;
    &:hover {
      color: theme('colors.acai.DEFAULT');
    }
  }
}

.footer-title {
  position: relative;
}

.footer-logo {
  flex: 1 0 100%;
  padding-bottom: theme('spacing.6');
  padding-left: theme('spacing.4');
  padding-right: theme('spacing.4');
  color: theme('colors.slate.hsluv');

  .wordmark {
    margin: 0 auto;
  }
}

.footer-column1 {
  flex: 0 1 auto;
  max-width: 13rem;
}

.footer-column2 {
  flex: 1 0 auto;
}

.footer-column3 {
  flex: 1 0 auto;
  max-width: 13rem;
  @include respond(medium) {
    text-align: right;
  }
}

.footer-contacts {
  display: grid;
  gap: theme('spacing.4');
  grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
}
</style>
