export default function (overwrites) {
  return {
    id: null,
    source: null,
    imageUrl: null,
    imageMeta: [],
    srcBlob: null,
    type: null,
    description: null,
    sizes: [],
    name: null,
    archive: null,
    keywords: [],
    ...overwrites,
  }
}
