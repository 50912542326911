import mapFoodItem from './get-food-item'
export default function ({ records, duplicateMappings }) {
  const foodItemRecords = Object.values(records).reduce((records, foodItem) => {
    records[foodItem.id] = mapFoodItem(foodItem)
    return records
  }, {})
  const duplicateRecords = duplicateMappings.reduce((duplicateRecords, ids) => {
    duplicateRecords[ids[0]] = {
      taxonomyCode: records[ids[0]].taxonomyCode,
      taxonomyMapping: records[ids[0]].taxonomyMapping,
      instances: ids,
    }
    return duplicateRecords
  }, {})

  const duplicateMappingIds = Object.keys(duplicateRecords)
  return { foodItemRecords, duplicateRecords, duplicateMappingIds }
}
