<template>
  <div>
    <h3 class="border-b-slate border-b pb-4 mb-3">
      {{ $t('organisations.sendingControls.subTitle') }}
    </h3>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <div class="pb-6">
        <!--Allowed Domains -->
        <sending-controls-handler
          class="box-content-separation pb-6"
          :location-id="locationId"
          :items="domains"
          save-action="saveNodeSendingControlsDomains"
        >
          <template #title>
            <h4 class="sending-controls-type-title">
              {{ $t('organisations.sendingControls.domains.title') }}
            </h4>
          </template>

          <template #add-item="{ addInPending, locationItems }">
            <add-domain-form
              class="mt-3"
              :location-domains="locationItems"
              @submit-domain="(name) => addInPending({ name, locationId })"
            />
          </template>
        </sending-controls-handler>

        <!--Excepted Emails -->
        <sending-controls-handler
          :location-id="locationId"
          :items="emails"
          save-action="saveNodeSendingControlsEmails"
        >
          <template #title>
            <div class="flex flex-middle">
              <h4 class="sending-controls-type-title">
                {{ $t('organisations.sendingControls.emails.title') }}
              </h4>
              <SvgIcon
                v-tooltip="{
                  theme: 'tooltip-white',
                  content: $t('organisations.sendingControls.emails.description'),
                  placement: 'top',
                }"
                class="text-slate ml-2"
                name="24-ic-info"
              ></SvgIcon>
            </div>
          </template>

          <template #add-item="{ addInPending, locationItems }">
            <add-email-form
              class="mt-3"
              :location-emails="locationItems"
              :covered-domains="domains"
              @submit-email="(name) => addInPending({ name, locationId })"
            />
          </template>
        </sending-controls-handler>
      </div>
    </loaded-content>
  </div>
</template>
<script>
import LoadedContent from '@/components/LoadedContent.vue'

import { useToast } from 'vue-toastification'
import AddDomainForm from './SubscribersSendingControls/AddDomainForm.vue'
import AddEmailForm from './SubscribersSendingControls/AddEmailForm.vue'
import SendingControlsHandler from './SubscribersSendingControls/SendingControlsHandler.vue'

export default {
  components: {
    LoadedContent,
    AddDomainForm,
    AddEmailForm,
    SendingControlsHandler,
  },
  props: {
    locationId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
    }
  },

  computed: {
    sendingControls() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId).sendingControls
    },
    domains() {
      return this.sendingControls ? this.sendingControls.approvedDomains : []
    },
    emails() {
      return this.sendingControls ? this.sendingControls.emails : []
    },
  },
  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeSendingControls', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },
}
</script>
<style lang="scss"></style>
