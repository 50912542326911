export default function sendOrganisation(data) {
  return {
    id: data.id,
    name: data.name,
    level: data.level,
    archived: data.archived,
    parentId: data.parentId,
    localisation: data.localisation && {
      locale: data.localisation.locale,
      timeZone: data.localisation.timezone,
      currencyCode: data.localisation.currencyCode,
      weightUnitId: data.localisation.weightUnitId,
      temperatureUnitId: data.localisation.temperatureUnitId,
    },
    locationDetails: data.locationDetails && {
      customerSuccessManager: data.locationDetails.customerSuccessManager && {
        id: data.locationDetails.customerSuccessManager.id,
      },
      packageId: data.locationDetails.packageId,
      sharedImageLibraryEnabled: data.locationDetails.sharedImageLibraryEnabled,
    },
  }
}
