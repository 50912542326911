<template>
  <div class="box UI">
    <h2 class="formSection-title">Buttons</h2>

    <div class="demo -mt-3">
      <div class="demo-buttons flex">
        <button class="button button--primary">Primary</button>
        <button class="button button--secondary">Secondary</button>
        <button class="button button--tertiary">Tertiary</button>
        <button class="button button--tertiary-warning">Tertiary warning</button>
        <button class="button button--carrot">Carrot</button>
        <ButtonSave :is-saving="true">
          <template #icon><SvgIcon name="24-ic-settings" /></template>
          Hidden
        </ButtonSave>
        <ButtonWithSpinner
          class="button--secondary"
          :in-progress="true"
        >
          <template #icon><SvgIcon name="24-ic-settings" /></template>
          Hidden
        </ButtonWithSpinner>
      </div>
      <div class="demo-buttons flex">
        <button class="button button--sm button--primary">Primary</button>
        <button class="button button--sm button--secondary">Secondary</button>
        <button class="button button--sm button--tertiary">Tertiary</button>
        <button class="button button--sm button--tertiary-warning">Tertiary warning</button>
        <button class="button button--sm button--carrot">Carrot</button>
      </div>

      <div class="demo-buttons flex">
        <button class="button button--primary">
          <SvgIcon
            name="48-ic-settings"
            sm
          />
          <span>Primary</span>
        </button>
        <button class="button button--primary">
          <span>Primary</span>
          <SvgIcon
            name="48-ic-settings"
            sm
          />
        </button>
        <button class="button button--primary">Primary</button>
        <button class="button button--sm button--primary">
          <SvgIcon
            name="48-ic-settings"
            sm
          />
          <span>Primary</span>
        </button>
        <button class="button button--sm button--primary">
          <span>Primary</span>
          <SvgIcon
            name="48-ic-settings"
            sm
          />
        </button>
        <button class="button button--sm button--primary">Primary</button>

        <button class="button button--primary">
          <SvgIcon name="24-ic-check" />
          <span>Primary</span>
        </button>
        <button class="button button--primary">
          <SvgIcon name="24-ic-view_on" />
          <span>Primary</span>
        </button>
      </div>
    </div>

    <div class="demo inverted bg-acai">
      <div class="demo-buttons">
        <button class="button button--inverted-outline">Inverted outline</button>
      </div>

      <div class="demo-buttons">
        <button class="button button--sm button--inverted-outline">Inverted outline</button>
      </div>
    </div>

    <h2 class="formSection-title mt-6">Modal</h2>

    <button
      class="button button--primary"
      @click="$refs.someModal.open()"
    >
      Open Modal
    </button>

    <Modal ref="someModal">
      <template #default="{ close }">
        <div class="modal-header">
          <h3 class="uppercase">Some modal title</h3>
          <button
            class="button button--icon button--inverted"
            @click="close()"
          >
            <SvgIcon name="24-ic-clear" />
          </button>
        </div>
        <div class="modal-body">
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Provident repellat delectus
            dignissimos enim sunt accusantium. Vitae, accusantium? Quam doloremque sit illo minus
            ratione, reprehenderit accusantium? Obcaecati molestiae odio inventore deleniti!
          </p>
        </div>
      </template>
    </Modal>

    <h2 class="formSection-title mt-6">Grid</h2>

    <div class="grid-layout">
      <div class="grid-unit">1</div>
      <div class="grid-unit">2</div>
      <div class="grid-unit">3</div>
      <div class="grid-unit">4</div>
      <div class="grid-unit">5</div>
      <div class="grid-unit">6</div>
      <div class="grid-unit">7</div>
      <div class="grid-unit">8</div>
      <div class="grid-unit">9</div>
      <div class="grid-unit">10</div>
      <div class="grid-unit">11</div>
      <div class="grid-unit">12</div>
    </div>
    <div class="grid-layout">
      <div class="grid-unit">1</div>
      <div class="grid-unit">2</div>
      <div class="grid-unit">3</div>
    </div>

    <h2 class="formSection-title mt-6">Toggle switch</h2>

    <div class="formGroup">
      <div class="flex flex-between">
        <div id="visionLabel">Toggle me as a button</div>
        <ToggleSwitch
          type="button"
          :labelled-by="'visionLabel'"
        ></ToggleSwitch>
      </div>
    </div>
    <div class="formGroup">
      <div class="flex flex-between">
        <label for="visionCheck">Toggle me as a checkbox ({{ toggleSwitch }})</label>
        <ToggleSwitch
          :id="'visionCheck'"
          v-model="toggleSwitch"
          type="checkbox"
          :name="'visionCheck'"
          :checked="toggleSwitch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch.vue'
import ButtonSave from '@/components/ButtonSave.vue'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
export default {
  name: 'UIElements',
  components: {
    Modal,
    ToggleSwitch,
    ButtonSave,
    ButtonWithSpinner,
  },
  data() {
    return {
      toggleSwitch: true,
    }
  },
}
</script>
