<template>
  <button
    v-test="'sort-button'"
    type="button"
    :class="['button-sort gap-0', sortedClass]"
    @click="onClick()"
  >
    <span><slot></slot></span>

    <span class="icon">
      <span class="sr-only">, {{ sortedState }}</span>
      <SvgIcon
        name="24-ic-arrow-up"
        v-if="sorted === 'ascending'"
      ></SvgIcon>
      <SvgIcon
        name="24-ic-arrow-down"
        v-else
      ></SvgIcon>
    </span>
  </button>
</template>

<script>
export default {
  props: [
    'sorted', // `ascending` or `descending`
  ],
  emits: ['sorted'],
  data() {
    return {
      sortedState: this.sorted,
    }
  },
  computed: {
    sortedClass() {
      if (this.sortedState) {
        return `button-sort--${this.sortedState}`
      }
      return ''
    },
  },
  watch: {
    sorted(newState) {
      this.sortedState = newState || ''
    },
  },
  methods: {
    onClick() {
      this.switchStatus()
      this.$emit('sorted', this.sortedState)
    },
    switchStatus() {
      switch (this.sortedState) {
        case '': {
          this.sortedState = 'ascending'
          break
        }
        case 'ascending': {
          this.sortedState = 'descending'
          break
        }
        case 'descending': {
          this.sortedState = 'ascending'
          break
        }
      }
    },
  },
}
</script>
<style>
.button-sort {
  padding: 0 0 0 theme('spacing.1');
  border: 0;
  margin-top: calc(-1 * theme('spacing.px'));
  margin-left: calc(-1 * theme('spacing.1'));
  line-height: 1;
  text-align: left;
  justify-content: flex-start;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: theme('borderRadius.DEFAULT');
  &:focus:focus-visible,
  &:active,
  &:active:focus-visible {
    @apply shadow-btn;
    outline: 0;
  }
  > .icon {
    color: theme('colors.slate.DEFAULT');
  }
  &:hover,
  &:focus:focus-visible,
  &:active,
  &:active:focus:focus-visible {
    > .icon {
      color: theme('colors.blueberry.DEFAULT');
    }
  }

  th & {
    vertical-align: initial;
  }
}
.button-sort--ascending {
  > .icon {
    color: theme('colors.blueberry.DEFAULT');
  }
}

.button-sort--descending {
  > .icon {
    color: theme('colors.blueberry.DEFAULT');
  }
}
</style>
