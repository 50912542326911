<script>
export default {
  name: 'StaticTree',
  props: {
    tree: Object,
    level: {
      type: Number,
      default: 1,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    keyPrefix: String,
  },
  emits: ['node-click'],
  data() {
    return {
      showChildren: this.expanded,
    }
  },

  watch: {
    'tree.children': {
      handler: function (newValue) {
        if (this.expanded && newValue && newValue.length > 0) {
          this.showChildren = true
        }
      },
    },
  },

  methods: {
    toggleChildren() {
      if (this.showChildren === false) {
        this.showChildren = true
      } else {
        this.showChildren = false
      }
    },
  },
}
</script>

<template>
  <li
    :class="[
      'Tree-item',
      'level-' + level,
      {
        'is-root': level === 1,
        'is-leaf': !tree.children.length,
      },
    ]"
  >
    <div
      v-if="tree.id !== 'root'"
      v-test="{ node: tree.id }"
      class="Tree-label"
      @click="$emit('node-click', tree)"
    >
      <button
        v-if="tree.children.length > 0"
        v-test="'expander'"
        class="Tree-expander"
        @click="toggleChildren()"
      >
        <SvgIcon
          :class="{
            'is-rotated-90': !showChildren,
          }"
          name="24-ic-arrow-down"
        />
      </button>

      <slot
        :node="tree"
        :level="level"
      />
    </div>

    <template v-if="tree.children.length > 0 && showChildren">
      <ul
        v-test="{ 'children-of': tree.id }"
        class="Tree"
      >
        <template
          v-for="child in tree.children"
          :key="`${keyPrefix}${child.id}`"
        >
          <StaticTree
            :tree="child"
            :key-prefix="keyPrefix"
            :level="tree.id === 'root' ? level : level + 1"
            :expanded="expanded"
            @click="$emit('node-click', $event)"
          >
            <template
              v-for="slot in Object.keys($slots)"
              #[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </StaticTree>
        </template>
      </ul>
    </template>
  </li>
</template>
