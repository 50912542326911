<template>
  <subscribers-sending-controls
    :location-id="nodeId"
    class="px-4"
  />
  <template v-if="node.level === orgLevels">
    <subscribers-daily-reports
      class="collapsible-section--first"
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('SUBSCRIBERS-DAILY')"
      @open="openSection('SUBSCRIBERS-DAILY')"
      @close="closeSection('SUBSCRIBERS-DAILY')"
    />
    <subscribers-summary-reports
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('SUBSCRIBERS-SUMMARY')"
      @open="openSection('SUBSCRIBERS-SUMMARY')"
      @close="closeSection('SUBSCRIBERS-SUMMARY')"
    />
    <subscribers-cross-site-reports
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('SUBSCRIBERS-XSITE')"
      @open="openSection('SUBSCRIBERS-XSITE')"
      @close="closeSection('SUBSCRIBERS-XSITE')"
    />
    <subscribers-transactions-extract
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('SUBSCRIBERS-EXTRACT')"
      @open="openSection('SUBSCRIBERS-EXTRACT')"
      @close="closeSection('SUBSCRIBERS-EXTRACT')"
    />
  </template>
</template>
<script>
import { ORG_LEVELS } from '@/store/constants'
import openSectionsMixin from '@/mixins/openSectionsMixin'
import SubscribersSendingControls from './ReportingSubscribers/SubscribersSendingControls.vue'
import SubscribersDailyReports from './ReportingSubscribers/SubscribersDailyReports.vue'
import SubscribersSummaryReports from './ReportingSubscribers/SubscribersSummaryReports.vue'
import SubscribersCrossSiteReports from './ReportingSubscribers/SubscribersCrossSiteReports.vue'
import SubscribersTransactionsExtract from './ReportingSubscribers/SubscribersTransactionsExtract.vue'

export default {
  components: {
    SubscribersSendingControls,
    SubscribersDailyReports,
    SubscribersSummaryReports,
    SubscribersCrossSiteReports,
    SubscribersTransactionsExtract,
  },
  mixins: [openSectionsMixin],
  props: {
    nodeId: String,
  },
  computed: {
    node() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    orgLevels() {
      return ORG_LEVELS
    },
  },
}
</script>
