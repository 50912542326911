<template>
  <div class="wrapper flex flex-col flex-grow">
    <slot name="content">
      <section
        class="box flex-grow flex flex-col"
        :aria-busy="isLoading"
      >
        <!-- Header stuff goes here -->
        <slot name="header" />
        <slot />
      </section>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    // don't add types or it will be automatically cast to `false` and the static content check will fail
    isLoading: {
      required: false,
    },
    hasError: {
      required: false,
    },
    hasData: {
      required: false,
    },
    errorMessage: {
      required: false,
      type: String,
    },
  },
  computed: {
    // check if we have dynamically loaded content via the presence of the `isLoading` prop
    dynamicContent() {
      return this.isLoading !== undefined
    },
  },
}
</script>
