<template>
  <LayoutDefault
    extended-content
    class="pt-6"
  >
    <Hero>{{ $t('titles.menuMapping') }}</Hero>
    <PageSection
      class="wrapper"
      id="code-builder"
    >
      <SectionHeader>
        <template #section-title>{{ $t('menuMapping.mappingCodeBuilder') }}</template>
      </SectionHeader>

      <CodeBuilder
        @reset="onCodeReset"
        class="mb-8"
      />

      <div class="section-header">
        <div class="section-navigation-tabs section-navigation-tabs-row">
          <TabsLvl2Links v-test="'tabs'">
            <li>
              <router-link
                v-test="'terms'"
                :to="{ path: '/menu-mapping/terms', query: $route.query }"
                class="tab-item"
              >
                <span>{{ $t('taxman.baseTerms') }}</span>
              </router-link>
            </li>
            <li>
              <router-link
                v-test="'facets'"
                :to="{ path: '/menu-mapping/facets', query: $route.query }"
                class="tab-item"
              >
                <span>{{ $t('taxman.facets') }}</span>
              </router-link>
            </li>
          </TabsLvl2Links>
        </div>
      </div>

      <router-view ref="tree-view"></router-view>
    </PageSection>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
import CodeBuilder from './CodeBuilder/CodeBuilder.vue'

export default {
  name: 'MenuMapping',
  components: {
    LayoutDefault,
    Hero,
    CodeBuilder,
    TabsLvl2Links,
  },
  methods: {
    onCodeReset() {
      this.$router.push({ path: '/menu-mapping/terms' })
    },
  },
}
</script>
