<template>
  <div class="calendar">
    <div class="calendar-nav">
      <slot
        name="nav"
        :center="realCenter"
        :prev="prevMonth"
        :next="nextMonth"
        :reset="reset"
      />
    </div>
    <div class="calendar-container">
      <template
        v-for="week in weeks"
        :key="week.id"
      >
        <div
          v-test="{ week: week.id }"
          class="calendar-week"
        >
          <template
            v-for="day in week.days"
            :key="day.id"
          >
            <div
              v-test="{ day: day.id }"
              class="calendar-day"
            >
              <slot
                name="day"
                :day="day"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  withLocale,
  getWeekdaysShort,
  getWeekdays,
  add,
  subtract,
  isAfter,
  isBefore,
  isSame,
  startOf,
  endOf,
  weekday,
  formatDate,
  normalizeCalendarDay,
} from './adapter-moment'
import { DATE_FORMAT_ISO_8601 } from '@/store/constants'

export default {
  name: 'CSCalendar',
  props: {
    center: {
      type: Date,
      default() {
        return new Date()
      },
    },
    showDaysAround: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      today: new Date(),
      realCenter: startOf(this.center, 'day'),
    }
  },
  computed: {
    locale() {
      return this.$store.getters['auth/locale']
    },

    firstDay() {
      let firstDay = startOf(this.realCenter, 'month')
      let localeStartOfWeek = this.localeStartOfWeek

      while (withLocale(this.locale, () => weekday(firstDay)) % 7 !== localeStartOfWeek) {
        firstDay = add(firstDay, -1, 'day')
      }

      return firstDay
    },

    lastDay() {
      let lastDay = endOf(this.realCenter, 'month')
      let localeStartOfWeek = this.localeStartOfWeek

      let localeEndOfWeek = (localeStartOfWeek + 6) % 7
      while (withLocale(this.locale, () => weekday(lastDay)) % 7 !== localeEndOfWeek) {
        lastDay = add(lastDay, 1, 'day')
      }

      return lastDay
    },

    days() {
      let startDay = this.firstDay
      let endDay = this.lastDay
      let days = []

      while (isBefore(startDay, endDay)) {
        days.push(this.buildDay(startDay))
        startDay = add(startDay, 1, 'day')
      }
      return days
    },

    weeks() {
      let weeks = []
      let i = 0

      while (this.days[i]) {
        let daysOfWeek = this.days.slice(i, i + 7)
        if (!this.showDaysAround) {
          daysOfWeek = daysOfWeek.filter((d) => d.isCurrentMonth)
        }
        weeks.push({
          id: `week-of-${daysOfWeek[0].id}`,
          days: daysOfWeek,
          missingDays: 7 - daysOfWeek.length,
        })
        i += 7
      }
      return weeks
    },

    weekdaysShort() {
      return withLocale(this.locale, getWeekdaysShort)
    },

    weekdays() {
      return withLocale(this.locale, getWeekdays)
    },

    localeStartOfWeek() {
      let day = withLocale(this.locale, () => formatDate(startOf(this.center, 'week'), 'dddd'))
      let idx = this.weekdays.indexOf(day)
      return idx >= 0 ? idx : 0
    },
  },
  methods: {
    buildDay(date) {
      const id = formatDate(date, DATE_FORMAT_ISO_8601)
      const wd = withLocale(this.locale, () => weekday(date))

      return normalizeCalendarDay({
        id,
        date: date,
        number: date.getDate(),
        // isDisabled: this.dayIsDisabled(date),
        // isFocused: this.get('focusedId') === id,
        isCurrentMonth: date.getMonth() === this.realCenter.getMonth(),
        isToday: isSame(date, this.today, 'day'),
        isAfterToday: isAfter(date, this.today),
        // isSelected: this.dayIsSelected(date, calendar)
        weekday: wd,
        weekdayShort: this.weekdaysShort[wd],
      })
    },

    prevMonth() {
      this.realCenter = subtract(this.realCenter, 1, 'month')
    },
    nextMonth() {
      this.realCenter = add(this.realCenter, 1, 'month')
    },
    reset() {
      this.realCenter = startOf(this.center, 'day')
    },
  },
}
</script>
