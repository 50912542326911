<template>
  <span
    class="status-label mr-3 mb-1 -mt-1"
    :class="`status-label--${statusColor}`"
    :data-test-mapping-status="statusColor"
  >
    {{ statusText }}
  </span>
</template>
<script>
import { FOOD_ITEM_STATES } from '@/store/constants'
export default {
  props: ['status'],
  computed: {
    statusText() {
      return this.$t(`menus.foodItems.status.${this.status}`)
    },
    // map food item statuses to corresponding colors classes
    statusColor() {
      let colorClass = ''
      switch (this.status) {
        case FOOD_ITEM_STATES.APPROVED:
          colorClass = 'success'
          break
        case FOOD_ITEM_STATES.PENDING:
          colorClass = 'warning'
          break
        case FOOD_ITEM_STATES.FAILED:
          colorClass = 'danger'
          break
        case FOOD_ITEM_STATES.UNMAPPED:
          colorClass = 'neutral'
          break
        default:
          colorClass = 'info'
      }
      return colorClass
    },
  },
}
</script>
