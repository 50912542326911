import api from '@/store/api/menus'

export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    imagesById: (state, getters) => (ids) => {
      if (!ids) return []
      return ids.map((id) => getters.imageById(id))
    },
    imageById: (state) => (id) => state.records[id],
  },
  actions: {
    getImages({ commit }, { menuId, stageId, useSaved, foodGroupId, foodItemId, query }) {
      return api
        .fetchImageRecommendations({ menuId, stageId, foodGroupId, foodItemId, query })
        .then(({ records, imageIds, total }) => {
          if (useSaved) {
            commit('STORE_ONLY_NEW_IMAGES', { records })
          } else {
            commit('STORE_IMAGES', { records })
          }
          return {
            imageIds,
            total,
          }
        })
    },
    getImage({ commit }, { imageId }) {
      return api.fetchImage({ imageId }).then((image) => {
        commit('UPDATE_IMAGE', image)
      })
    },
    createImage({ commit }, { data }) {
      return api.postImage({ data }).then((image) => {
        commit('UPDATE_IMAGE', image)
        return image
      })
    },
    updateImage({ commit }, { imageId, data }) {
      return api.patchImage({ imageId, data }).then((image) => {
        commit('UPDATE_IMAGE', image)
        return image
      })
    },
  },
  mutations: {
    STORE_IMAGES(state, { records }) {
      state.records = { ...state.records, ...records }
    },
    STORE_ONLY_NEW_IMAGES(state, { records }) {
      state.records = { ...records, ...state.records }
    },
    UPDATE_IMAGE(state, image) {
      state.records[image.id] = { ...state.records[image.id], ...image }
    },
  },
}
