import moment from 'moment'
import api from '@/store/api/waste-log'
import { normalizeTransactionHistory } from '@/store/utils/waste-log'
import { saveAs } from 'file-saver'

const state = {
  records: {},
  meta: null,
  stages: [],
  food: [],
  custom1: [],
  transactionIds: [],
  transactionHistory: [],

  filterOptions: {
    foodItems: {
      records: [],
      loaded: false,
      fingerprint: null,
    },
    stages: {
      records: [],
      loaded: false,
      fingerprint: null,
    },
    trackers: {
      records: [],
      loaded: false,
      fingerprint: null,
    },
  },
}

export const transactionTypes = ['other', 'waste_manual', 'rework', 'stock_out', 'staff_food']

const getters = {
  byId: (state) => (id) => state.records[id],
  transactions: (state) => {
    return Object.values(state.records)
  },

  transactionIds: (state) => state.transactionIds,
  transactionHistory: (state) => state.transactionHistory,
  meta: (state) => state.meta,
  stages: (state) => state.stages,
  custom1: (state) => state.custom1,
  food: (state) => state.food,

  filterFoodItems: (state) => state.filterOptions.foodItems.records,
  filterStages: (state) => state.filterOptions.stages.records,
  filterTrackers: (state) => state.filterOptions.trackers.records,

  filterFoodItemsLoaded: (state) => state.filterOptions.foodItems.loaded,
  filterStagesLoaded: (state) => state.filterOptions.stages.loaded,
  filterTrackersLoaded: (state) => state.filterOptions.trackers.loaded,
}

const actions = {
  getMoreWaste({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      api
        .fetchAllWaste(params)
        .then((response) => {
          const payload = response.data
          commit('CLEAR_META')

          commit('STORE_RECORDS', payload.data)
          commit(
            'APPEND_TO_TRANSACTIONS_IDS',
            payload.data.map((t) => t.id)
          )
          commit('STORE_META', payload.metadata)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllWaste({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      api
        .fetchAllWaste(params)
        .then((response) => {
          const payload = response.data
          commit('CLEAR_TRANSACTIONS_IDS')
          commit('CLEAR_RECORDS')
          commit('CLEAR_META')

          commit('STORE_RECORDS', payload.data)
          commit(
            'APPEND_TO_TRANSACTIONS_IDS',
            payload.data.map((t) => t.id)
          )
          commit('STORE_META', payload.metadata)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getPrevTransaction({ commit, getters }, { id, params }) {
    return new Promise((resolve, reject) => {
      api
        .fetchPrevTransaction(id, params)
        .then((response) => {
          const transaction = response.data.data
          if (transaction && !getters.byId(transaction.id)) {
            commit('STORE_RECORD', transaction)
          }
          resolve(transaction)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllStages({ commit, rootGetters }, { menuId, filter }) {
    const locale = rootGetters['auth/preferredMenuLocale']

    return new Promise((resolve, reject) => {
      api
        .fetchAllStages({ menuId, locale, filter })
        .then((response) => {
          commit('STAGE_SET_DATA', response.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTransactionHistory({ commit, rootGetters }, id) {
    const locale = rootGetters['auth/preferredMenuLocale']

    return new Promise((resolve, reject) => {
      api
        .fetchTransactionHistory(id, { locale })
        .then((response) => {
          commit('STORE_TRANSACTION_HISTORY', normalizeTransactionHistory(response.data.data))
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllCustom1({ commit, rootGetters }, { filter }) {
    const locale = rootGetters['auth/preferredMenuLocale']

    return new Promise((resolve, reject) => {
      api
        .fetchAllCustom1({ locale, filter })
        .then((response) => {
          commit('CUSTOM1_SET_DATA', response.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllFood({ commit, rootGetters }, params) {
    const locale = rootGetters['auth/preferredMenuLocale']

    return new Promise((resolve, reject) => {
      api
        .fetchAllFood({
          filter: { ...params.filter },
          locale,
          page: params.page,
          size: params.size,
        })
        .then((response) => {
          commit('FOOD_SET_DATA', response.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveItem({ commit, rootGetters }, payload) {
    const locale = rootGetters['auth/preferredMenuLocale']

    return new Promise((resolve, reject) => {
      api
        .putWasteLog(payload, { locale })
        .then((response) => {
          commit('STORE_RECORD', response.data.data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteItem(store, id) {
    return api.deleteWasteLog(id).then(() => id)
  },

  deleteItems(_, items) {
    return api.deleteWasteLogsEvents(items)
  },

  downloadWaste(store, params = {}) {
    return new Promise((resolve, reject) => {
      const fileName = `Transactions_${moment(params.filter.start).format('DD_MM_YYYY')}_-_${moment(
        params.filter.end
      ).format('DD_MM_YYYY')}.csv`

      api
        .downloadWasteLog(params)
        .then((response) => {
          // Add BOM at the start of the blob so old Excel can parse as utf-8
          const blob = new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8' })
          saveAs(blob, fileName)

          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getFilterFoodItems({ commit, state, rootGetters }, filters) {
    const locale = rootGetters['auth/preferredMenuLocale']

    const currentFingerprint = state.filterOptions.foodItems.fingerprint
    const newFingerprint = { site: filters.site, start: filters.start, end: filters.end, locale }

    return new Promise((resolve, reject) => {
      if (JSON.stringify(currentFingerprint) === JSON.stringify(newFingerprint)) {
        resolve()
      } else {
        commit('START_FILTER_FOOD_ITEMS')
        api
          .fetchFilterFoodItems({
            filter: { ...filters },
            locale,
          })
          .then((response) => {
            commit('STORE_FILTER_FOOD_ITEMS', {
              records: response.data.data,
              fingerprint: newFingerprint,
            })
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit('FINISH_FILTER_FOOD_ITEMS')
          })
      }
    })
  },

  getFilterStages({ commit, state, rootGetters }, filters) {
    const locale = rootGetters['auth/preferredMenuLocale']

    const currentFingerprint = state.filterOptions.stages.fingerprint
    const newFingerprint = { site: filters.site, start: filters.start, end: filters.end, locale }

    return new Promise((resolve, reject) => {
      if (JSON.stringify(currentFingerprint) === JSON.stringify(newFingerprint)) {
        resolve()
      } else {
        commit('START_FILTER_STAGES')
        api
          .fetchFilterStages({
            filter: { ...filters },
            locale,
          })
          .then((response) => {
            commit('STORE_FILTER_STAGES', {
              records: response.data.data,
              fingerprint: newFingerprint,
            })
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit('FINISH_FILTER_STAGES')
          })
      }
    })
  },

  getFilterTrackers({ commit, state, rootGetters }, filters) {
    const locale = rootGetters['auth/preferredMenuLocale']

    const currentFingerprint = state.filterOptions.trackers.fingerprint
    const newFingerprint = { site: filters.site, start: filters.start, end: filters.end }

    return new Promise((resolve, reject) => {
      if (JSON.stringify(currentFingerprint) === JSON.stringify(newFingerprint)) {
        resolve()
      } else {
        commit('START_FILTER_TRACKERS')
        api
          .fetchFilterTrackers({
            filter: { ...filters },
            locale,
          })
          .then((response) => {
            commit('STORE_FILTER_TRACKERS', {
              records: response.data.data,
              fingerprint: newFingerprint,
            })
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            commit('FINISH_FILTER_TRACKERS')
          })
      }
    })
  },

  deleteRecordsById({ commit }, ids) {
    ids.forEach((id) => {
      commit('CLEAR_RECORD', id)
    })
  },
}

const mutations = {
  STORE_RECORDS(state, newRecords) {
    const { records } = state

    newRecords.forEach((newRecord) => {
      records[newRecord.id] = newRecord
    })
  },

  STORE_RECORD(state, newRecord) {
    state.records[newRecord.id] = newRecord
  },

  STORE_META(state, meta) {
    state.meta = meta
  },

  CLEAR_RECORD(state, id) {
    const transactionIndex = state.transactionIds.indexOf(id)
    delete state.records[id]
    state.transactionIds.splice(transactionIndex, 1)
  },

  CLEAR_RECORDS(state) {
    state.records = {}
  },

  APPEND_TO_TRANSACTIONS_IDS(state, ids) {
    ids.forEach((id) => {
      state.transactionIds.push(id)
    })
  },

  REMOVE_LAST_FROM_TRANSACTIONS_IDS(state, amount) {
    state.transactionIds.splice(-amount, amount)
  },

  CLEAR_TRANSACTIONS_IDS(state) {
    const newArr = state.transactionIds.splice(0, 0)
    state.transactionIds = newArr
  },

  CLEAR_META(state) {
    state.meta = null
  },

  STAGE_SET_DATA(state, payload) {
    state.stages = payload.data
  },
  FOOD_SET_DATA(state, payload) {
    state.food = payload.data
  },
  CUSTOM1_SET_DATA(state, payload) {
    state.custom1 = payload.data
  },

  START_FILTER_FOOD_ITEMS(state) {
    state.filterOptions.foodItems.loaded = false
  },
  FINISH_FILTER_FOOD_ITEMS(state) {
    state.filterOptions.foodItems.loaded = true
  },
  STORE_FILTER_FOOD_ITEMS(state, { records, fingerprint }) {
    state.filterOptions.foodItems.records = records
    state.filterOptions.foodItems.fingerprint = fingerprint
  },

  START_FILTER_STAGES(state) {
    state.filterOptions.stages.loaded = false
  },
  FINISH_FILTER_STAGES(state) {
    state.filterOptions.stages.loaded = true
  },
  STORE_FILTER_STAGES(state, { records, fingerprint }) {
    state.filterOptions.stages.records = records
    state.filterOptions.stages.fingerprint = fingerprint
  },

  START_FILTER_TRACKERS(state) {
    state.filterOptions.trackers.loaded = false
  },
  FINISH_FILTER_TRACKERS(state) {
    state.filterOptions.trackers.loaded = true
  },
  STORE_FILTER_TRACKERS(state, { records, fingerprint }) {
    state.filterOptions.trackers.records = records
    state.filterOptions.trackers.fingerprint = fingerprint
  },
  STORE_TRANSACTION_HISTORY(state, payload) {
    state.transactionHistory = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
