<template>
  <LayoutDefault>
    <Hero class="Home-hero text-center">
      <template #default>{{ $t('messages.welcomeBack', { name: user.firstName }) }}</template>
      <template #hero-content>
        <QuickLinks />
      </template>
    </Hero>

    <div
      v-if="$can('read', 'homepage-dashboard')"
      class="pentaho-wrapper"
    >
      <!-- @TODO: postMessage integration for proper responsive layout -->
      <iframe
        ref="dashboard"
        data-hj-allow-iframe
        class="pentaho-iframe"
        :src="homepageDashboardUrl"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
        scrolling="no"
      ></iframe>
    </div>

    <PageSection v-if="isReportRecipient">
      <SectionHeader>
        <template #section-title>{{ $t('messages.reportRecipient.title') }}</template>
      </SectionHeader>
      <div class="copy">
        <p class="font-bold h3">{{ $t('messages.reportRecipient.body.reports') }}</p>
        <i18n-t
          tag="p"
          scope="global"
          keypath="messages.reportRecipient.body.profile"
        >
          <template #profileLink>
            <router-link :to="{ name: 'profile' }">
              {{ $t('messages.reportRecipient.body.profileLink') }}
            </router-link>
          </template>
        </i18n-t>
        <i18n-t
          tag="p"
          scope="global"
          keypath="messages.reportRecipient.body.training"
        >
          <template #trainingLink>
            <router-link :to="{ name: 'training' }">
              {{ $t('messages.reportRecipient.body.trainingLink') }}
            </router-link>
          </template>
        </i18n-t>
        <p>{{ $t('messages.reportRecipient.body.assistance') }}</p>
      </div>
    </PageSection>
  </LayoutDefault>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import queryString from 'query-string'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import QuickLinks from '@/components/QuickLinks.vue'
const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'HomePage',
  components: {
    LayoutDefault,
    Hero,
    QuickLinks,
  },
  computed: {
    ...mapGetters(['user', 'userSettings', 'isMultiSiteUser', 'isReseller']),
    isReportRecipient() {
      return this.user.groups.indexOf('Report Recipients') >= 0
    },
    siteUserType() {
      return this.isMultiSiteUser || this.isReseller ? 'multi_site' : 'single_site'
    },
    homepageDashboardUrl() {
      let baseUrl = `${
        import.meta.env.VUE_APP_PENTAHO_URL
      }/pentaho/api/repos/%3Apublic%3AWinnow%3Adashboards%3A${
        this.siteUserType
      }_homepage.wcdf/generatedContent`
      let params = queryString.stringify({
        p_weight: this.userSettings.weight.code,
        p_locale: this.userSettings.locale.code,
      })
      return `${baseUrl}?${params}`
    },
  },
  mounted() {
    this.bindIframeResize()
  },
  beforeUnmount() {
    window.removeEventListener('message', this.iframeMessageHandler)
  },
  methods: {
    bindIframeResize() {
      this.iframeMessageListener = window.addEventListener('message', this.iframeMessageHandler)
    },
    iframeMessageHandler(ev) {
      if (ev.origin === import.meta.env.VUE_APP_PENTAHO_URL && ev.data) {
        this.$refs.dashboard.style.height = Math.ceil(ev.data) + 'px'
      }
    },
  },
}
</script>
