<template>
  <component
    :is="stateComponent"
    ref="card"
    v-click-outside="clearServerWarnings"
    v-tooltip="{
      theme: tooltipState,
      content:
        (serverErrors.GENERAL.length && $t(`validation.${serverErrors.GENERAL[0].key}`)) ||
        (serverWarnings.GENERAL.length && $t(`CS.warnings.${serverWarnings.GENERAL[0].key}`)),
      shown: serverErrors.GENERAL.length || serverWarnings.GENERAL.length,
      triggers: [],
    }"
    :day="day"
    :item="item"
    :ranges="ranges"
    :currency="currency"
    :server-errors="serverErrors"
    :server-warnings="serverWarnings"
    :is-saving="isSaving"
    @open="onOpen"
    @close="onClose"
    @save="onSave"
    @clear-messages="clearServerMessages"
  />
</template>
<script>
import StateActive from './StateActive.vue'
import StateInactive from './StateInactive.vue'
import StateEditable from './StateEditable.vue'
import StateBlank from './StateBlank.vue'
import { useToast } from 'vue-toastification'
import { useValidation } from '@/composables/useValidation'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useStore } from 'vuex'

export default {
  components: {
    StateActive,
    StateInactive,
    StateEditable,
    StateBlank,
  },
  props: {
    day: {
      type: Object,
    },
    item: {
      type: Object,
    },
    ranges: {
      type: Object,
    },
    open: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      serverErrors,
      serverWarnings,
      processValidationErrors,
      processValidationWarnings,
      clearServerMessages,
      clearServerWarnings,
    } = useValidation({
      translationsBaseWarnings: 'CS.warnings',
      errorStructure: ['GENERAL', 'covers', 'sales'],
    })
    const { getters } = useStore()
    const { selectedSite } = useSelectedSite({ userId: getters['auth/userId'] })
    return {
      toast: useToast(),
      serverErrors,
      serverWarnings,
      processValidationErrors,
      processValidationWarnings,
      clearServerMessages,
      clearServerWarnings,
      selectedSite,
    }
  },
  data() {
    return {
      isEditing: false,
      isSaving: false,
    }
  },

  computed: {
    isEmpty() {
      const { covers, sales } = this.item
      // Could be either null or 0
      return !covers && !sales
    },
    stateComponent() {
      if (!this.item) return 'StateBlank'

      if (this.item.active === false) return 'StateInactive'
      if (this.isEditing || this.open || this.isEmpty) return 'StateEditable'

      return 'StateActive'
    },
    tooltipState() {
      if (this.serverErrors.GENERAL.length) return 'tooltip-danger'
      if (this.serverWarnings.GENERAL.length) return 'tooltip-warning'
      return null
    },
  },
  watch: {
    item: {
      handler: function () {
        this.isEditing = false
      },
      immediate: true,
    },
  },
  methods: {
    onOpen() {
      this.isEditing = true
      this.$nextTick(() => {
        this.$refs.card.$refs.coversInput.focus()
      })
    },
    onClose() {
      this.isEditing = false
    },
    onSave({ data, wasDirty }) {
      this.isSaving = true
      this.clearServerWarnings()
      if (wasDirty) {
        this.compareToPreviousState(data.active, wasDirty)
      }

      this.$store
        .dispatch('covers-sales/save', {
          legacyId: this.selectedSite.legacyId,
          model: {
            old: { ...this.item },
            new: data,
          },
        })
        .then((response) => {
          if (response.metadata && response.metadata.warnings) {
            this.processValidationWarnings(response.metadata.warnings)
          }
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch((errorObj) => {
          this.clearServerMessages()
          if (errorObj.status === 422) {
            this.processValidationErrors(errorObj.data.errors)
          } else {
            this.toast.error(this.$t('toast.error.saveData'))
          }
        })
        .finally(() => {
          this.isSaving = false
          this.analytics.track('Activation - Save C&S', {
            id: this.item.id,
            date: this.item.date,
          })
        })
    },
    compareToPreviousState(active, wasDirty) {
      //check if the new item is inactive and if it was being edited prior to deactivation
      if (!active && wasDirty) {
        this.serverWarnings['GENERAL'].push({
          key: 'OUT_OF_BOUNDS',
          id: 'closeDay',
        })
      }
      return
    },
  },
}
</script>

<style lang="scss">
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
  text-align: left;
  padding: theme('spacing.1') theme('spacing.2') 0;
  border: 1px solid theme('colors.acai.DEFAULT');
  border-radius: theme('borderRadius.lg');
  color: theme('colors.acai.DEFAULT');
  min-height: to-rem(200px);

  &.is-inactive {
    background: theme('colors.grey.100');
    border-color: theme('colors.grey.100');
  }

  &.is-today {
    border-color: theme('colors.blueberry.DEFAULT') !important;
    border-width: 3px !important;
    margin: calc(-1 * theme('spacing[0.5]'));
  }

  &.is-external {
    border-color: theme('colors.grey.100');
    color: theme('colors.grey.400');
    border-style: solid;
  }

  &.is-invalid {
    border-color: theme('colors.apple.DEFAULT');
  }

  &[aria-role='button'] {
    outline: none;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus {
      box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv');
    }

    &:active {
      box-shadow: none;
    }
  }

  &.is-clickable {
    cursor: pointer;

    &:hover {
      border-color: theme('colors.blueberry.DEFAULT') !important;
      text-decoration: none;
      color: theme('colors.acai.DEFAULT');
    }
  }
}

.card-tooltip {
  position: absolute;
  top: calc(-1 * theme('spacing.5'));
  left: 0;
  width: 100%;
  text-align: center;
  color: theme('colors.blueberry.DEFAULT');

  font-size: theme('fontSize.xs');
  font-weight: theme('fontWeight.bold');
  text-transform: uppercase;
}

.card-header {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: theme('fontSize.base');

  > div {
    flex: 0 0 auto;
  }

  .card-weekday {
    color: theme('colors.grey.400');
  }
}

.card-body {
  flex: 1 0 auto;
  padding-top: theme('spacing.4');
  display: flex;
  flex-direction: column;

  > .CS-form {
    flex: 1 0 auto;
  }
}

.CSAttr {
  position: relative;
}

.CSAttr-value {
  position: relative;

  > span {
    position: relative;
    display: block;
    font-size: theme('fontSize.base');
    height: theme('spacing.inputHeight');
    line-height: to-rem(25px); // super weird.
    font-weight: theme('fontWeight.bold');
    padding: theme('spacing.inputPaddingY') theme('spacing.2');
    border: theme('borderSize.input') solid transparent;
    white-space: nowrap;
  }

  /* stylelint-disable selector-no-qualifying-type */
  > span.is-null {
    font-weight: theme('fontWeight.normal');
    color: theme('colors.grey.400');
  }
  /* stylelint-enable */

  .icon {
    position: absolute;
    top: calc(theme('spacing.inputPaddingY') + theme('borderSize.input') + theme('spacing.1'));
    right: calc(#{theme('spacing.2')} + theme('borderSize.input'));
  }

  .textIcon {
    position: absolute;
    top: calc(100% + theme('spacing.2'));
    left: calc(#{theme('spacing.2')} + theme('borderSize.input'));

    font-size: theme('fontSize.xs');
    font-weight: theme('fontWeight.normal');
    font-style: normal;
    text-transform: uppercase;
    z-index: 3;
  }
}

.CSAttr-bar {
  position: absolute;
  top: calc(100% - theme('spacing[1.5]'));
  // left: theme('spacing.2');
  width: calc(100% - (2 * #{theme('spacing.2')}));
  margin: 0 theme('spacing.2');
}

.bar {
  position: relative;
  display: block;
  width: 0;
  height: theme('spacing.3');
  border-radius: theme('borderRadius.DEFAULT');
  transition: width 0.3s ease-in-out;
  border-left: theme('spacing.1') solid transparent;
  border-right: theme('spacing.1') solid transparent;
}

.bar--orange {
  background: theme('colors.carrot.DEFAULT');
  border-left-color: theme('colors.carrot.DEFAULT');
  border-right-color: theme('colors.carrot.DEFAULT');
}

.bar--blue {
  background: theme('colors.aqua.DEFAULT');
  border-left-color: theme('colors.aqua.DEFAULT');
  border-right-color: theme('colors.aqua.DEFAULT');
}

.CS-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .formControl {
    position: relative;
    padding-left: theme('spacing.2');
    padding-right: theme('spacing.2');
    padding-right: calc(#{theme('spacing.2')} + theme('spacing.6') + theme('spacing.1'));
    font-weight: theme('fontWeight.bold');
    color: theme('colors.acai.DEFAULT');
    background: transparent;

    &::placeholder {
      font-weight: theme('fontWeight.normal');

      // need to override normal color for IE 11
      color: theme('colors.grey.400');
    }
  }

  label {
    position: relative;
    margin: 0;

    .icon {
      position: absolute;
      top: calc(theme('spacing.inputPaddingY') + theme('borderSize.input') + theme('spacing.1'));
      right: calc(#{theme('spacing.2')} + theme('borderSize.input'));
    }

    .textIcon {
      position: absolute;
      top: calc(theme('spacing.inputPaddingY') + theme('borderSize.input') + theme('spacing.1'));
      right: calc(#{theme('spacing.2')} + theme('borderSize.input'));

      font-size: theme('fontSize.xs');
      font-weight: theme('fontWeight.normal');
      font-style: normal;
    }
  }
}

.CS-form-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: theme('spacing.6');

  .spinner {
    margin: 0 auto;
  }
}

.CS-inactive {
  text-align: center;

  .iconCircle {
    color: theme('colors.white');
    background: theme('colors.slate.DEFAULT');
  }
}
</style>
