import { WEIGHT_METRIC_KEY, WEIGHT_US_STANDARD_KEY } from '@/store/constants'

const POUNDS_TO_GRAMS_RATIO = 453.59237
const GRAMS_TO_POUNDS_RATIO = 0.00220462262
const GRAMS_TO_OUNCES_RATIO = 0.035274
const OUNCES_TO_GRAMS_RATIO = 28.3495
const GRAMS_PER_KG = 1000

export function fromGrams(val, unitType) {
  if (unitType === WEIGHT_METRIC_KEY) {
    return val / GRAMS_PER_KG
  } else {
    return val * GRAMS_TO_POUNDS_RATIO
  }
}

export function toGrams(val, unitType) {
  if (unitType === WEIGHT_METRIC_KEY) {
    return val * GRAMS_PER_KG
  } else {
    return val * POUNDS_TO_GRAMS_RATIO
  }
}

/**
 * Display a cost depending on desired measurement preference
 * @param { Number } costPerKg - the value to be converted
 * @param { String } weightPreference - the measurement system
 * @returns { Number } - the converted value
 */
export function costPerWeight(costPerKg, weightPreference) {
  if (weightPreference === WEIGHT_US_STANDARD_KEY) {
    return costPerKg * (POUNDS_TO_GRAMS_RATIO / GRAMS_PER_KG)
  } else {
    return costPerKg * 1 // make sure we export a number
  }
}

/** Converts cost/lbs to cost/kg or keeps the original cost depending on the weight preference
 *  @param { Number } cost - the value to be converted
 *  @param { String } weightPreference - the measurement system
 *  @returns { Number } - the converted value
 */
export function toCostPerKg(cost, weightPreference) {
  if (weightPreference === WEIGHT_US_STANDARD_KEY) {
    return cost / (POUNDS_TO_GRAMS_RATIO / GRAMS_PER_KG)
  } else {
    return cost * 1 // make sure we export a number
  }
}

export const boundsForPrecision3 = (val) =>
  (val * 1000) % 10 === 0
    ? {
        low: val - 0.005,
        high: val + 0.004,
      }
    : {
        low: val,
        high: val,
      }

export const gramsToOunces = (val) => {
  return val * GRAMS_TO_OUNCES_RATIO
}
export const ouncesToGrams = (val) => {
  return val * OUNCES_TO_GRAMS_RATIO
}
export default {
  fromGrams,
  toGrams,
  boundsForPrecision3,
  gramsToOunces,
  ouncesToGrams,
}
