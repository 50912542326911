<template>
  <div>
    <div
      v-if="orderedStages.length"
      class="formGroup formGroup--inline my-4 mx-0"
    >
      <div class="label">{{ $t('labels.alphabeticOrdering') }}</div>
      <ToggleSwitch
        :id="'orderingToggle'"
        type="button"
        :state="!menu.manualOrdering"
        :disabled="readonly"
        data-test-toggle-ordering
        @toggle="toggleSortMode"
      />
    </div>
    <loaded-content
      :is-loading="stagesLoadingStatus === 'LOADING'"
      :has-data="stagesLoadingStatus === 'LOADED'"
      :has-error="stagesLoadingStatus === 'ERROR'"
    >
      <p
        v-if="!orderedStages.length"
        class="mb-4"
        data-test-empty-list
      >
        {{ $t('menus.tabletEditor.stages.noRecords') }}
      </p>
      <ordered-grid>
        <ordered-grid-item
          v-for="(stage, index) in orderedStages"
          :key="stage.id"
          :index="index"
          :length="orderedStages.length"
          :image-url="stage.imageUrl"
          :title="stage.displayName || stage.name"
          :link="{
            name: 'tablet-editor-food-groups',
            params: { ...$route.params, stageId: stage.id },
          }"
          :has-warning="menuIsNonEnglish && !stage.nameEnglish"
          :warning-message="$t('menus.tabletEditor.stage.messages.noTranslation')"
          :reordering-active="menu.manualOrdering && !readonly"
          data-test-stage
          @move="
            moveItem({
              item: stage,
              list: orderedStages,
              oldIndex: $event.oldIndex,
              newIndex: $event.newIndex,
              callback: updateList,
            })
          "
        />
        <ordered-grid-create
          v-if="!readonly"
          key="none"
          :title="$t('menus.tabletEditor.stages.actions.createNew')"
          :link="{
            name: 'tablet-editor-stage-create',
            query: $route.query,
          }"
        />
      </ordered-grid>
    </loaded-content>
  </div>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import OrderedGridMixin from '@/mixins/ordered-grid'
import menuMixin from '../menuMixin'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LoadedContent,
    ToggleSwitch,
  },
  mixins: [OrderedGridMixin, menuMixin],
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      stagesLoadingStatus: 'IDLE',
    }
  },
  computed: {
    stages() {
      return this.$store.getters['menu-management/menus/stagesInMenu'](this.menuId)
    },
    orderedStages() {
      return this.stages.slice().sort((a, b) => a.displayOrder - b.displayOrder)
    },
    readonly() {
      return (this.menu && this.menu.archived) || !this.$can('manage', 'menus')
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.stagesLoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/stages/getStages', { menuId: this.menuId })
        .then(() => {
          this.stagesLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.stagesLoadingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.getData'))
        })
    },
    updateList(list) {
      list.forEach((stage, index) => {
        this.$store.dispatch('menu-management/stages/updateStage', {
          id: stage.id,
          displayOrder: index,
        })
      })
      this.saveData(list)
    },
    saveData(list) {
      let newStageIds = list.map((stage) => stage.id)
      this.$store
        .dispatch('menu-management/menus/updateMenu', {
          menuId: this.menuId,
          data: { stageIds: newStageIds },
        })
        .then(() => {
          this.$store.commit('menu-management/menus/UPDATE_MENU', {
            id: this.menuId,
            stageIds: newStageIds,
          })
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    toggleSortMode(alphabeticallySortedChildren) {
      this.stagesLoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/menus/updateMenu', {
          menuId: this.menuId,
          data: {
            manualOrdering: !alphabeticallySortedChildren,
          },
        })
        .then(() => {
          return this.getData()
        })
        .catch(() => {
          this.stagesLoadingStatus = 'LOADED'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
  },
}
</script>
