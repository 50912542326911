<template>
  <LayoutCentralMessage>
    <h1 class="text-white mt-8 mb-2">{{ $t('messages.pageNotFound') }}</h1>
    <p
      class="t1"
      v-html="$t('messages.errorCode', { code: '404' })"
    ></p>
    <SvgIcon
      class="NotFound-illustration text-acai-900"
      name="error-page"
    />
    <div class="py-8">
      <!-- Add link here instead of routerlink so that we make sure the homepage also does a full refresh and clears any
        selected site set by using direct urls -->
      <a
        v-if="$can('read', 'generic')"
        href="/"
        class="button button--inverted-outline"
        >{{ $t('actions.goHome') }}</a
      >
    </div>
  </LayoutCentralMessage>
</template>
<script>
import LayoutCentralMessage from '@/layouts/LayoutCentralMessage/LayoutCentralMessage.vue'

export default {
  name: 'NotFound',
  components: {
    LayoutCentralMessage,
  },
}
</script>
<style lang="scss">
.NotFound-illustration {
  width: 35vh;
  height: 35vh;
  min-height: to-rem(300px);
  min-width: to-rem(300px);
  max-width: 100%;
  @include respond-max(medium) {
    width: 50vw;
    height: 50vw;
    min-height: auto;
    min-width: auto;
  }
}
</style>
