<template>
  <input
    :value="modelValue"
    @input="handleChange"
  />
</template>
<script>
export default {
  props: {
    modelValue: String,
  },
  emits: ['update:modelValue', 'commaPress'],
  methods: {
    handleChange(evt) {
      let val = evt.target.value

      // check if the last char is comma (,)
      if (val.indexOf(',') === val.length - 1) {
        // remove comma from the passed value
        this.$emit('update:modelValue', val.replace(/,/g, ''))
        // trigger "commaPress" event
        this.$emit('commaPress')
      } else {
        this.$emit('update:modelValue', val)
      }
    },
  },
}
</script>
