<template>
  <li
    :key="'none'"
    class="ordered-grid-item ordered-grid-item--with-action ordered-grid-item--centered"
    data-test-create
    @click.stop="$router.push(link)"
  >
    <div class="ordered-grid-item-image-placeholder">
      <span class="button button--icon m-5">
        <SvgIcon
          name="48-ic-expand"
          xl
        />
      </span>
      <router-link
        class="text-center font-bold"
        :to="link"
        @click="$event.stopImmediatePropagation()"
      >
        {{ title }}
      </router-link>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    title: String,
    link: Object,
  },
}
</script>

<style></style>
