import { ref } from 'vue'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
export function useValidation({
  // This composable can have a function provided that formats the error messages
  formatErrorMessage,
  // base keys for selecting translations in language files: [`${base}.SOME_KEY`]
  translationsBaseErrors = 'validation',
  translationsBaseWarnings = 'validation',
  // a message to show when the error is not status 422. we show an error toast with this message
  genericErrorMessage,
  errorStructure = ['GENERAL'],
} = {}) {
  const toast = useToast()
  const { t, messages, locale } = useI18n()
  if (!genericErrorMessage) {
    genericErrorMessage = t('toast.error.saveData')
  }
  /**
   * serverErrors and serverWarnings are objects with keys associated to different error sources (see below)
   * {
   *   GENERAL: [{...}],
   *   fieldName: [{...}],
   *   someOtherUsefulSource: [{...}]
   *   ...
   * }
   */
  const ErrorConstructor = function () {
    return errorStructure.reduce((constructor, key) => {
      constructor[key] = []
      return constructor
    }, {})
  }
  const serverErrors = ref(new ErrorConstructor())
  const serverWarnings = ref(new ErrorConstructor())

  /**
   * translationBase is the beginning of an i18n translation key
   * most generic validation messages look like "validation.[key]"
   * the keys are going to be provided by the error objects
   */

  const processValidation = (errors) => {
    /** Incoming error objects look like this:
     * {
     *   source, - what is the error referring to
     *   level, - HARD, SOFT or empty/null for differentiating between errors and warning
     *   key, - an identifier for the error message (we use this to match with translation keys)
     *   detail, - this can be anything, like an `id` to reference a newly created item
     *   message - server generated message for the error, we should only use this when translating the keys is impossible
     * }
     * if we don't have a source, we use a 'GENERAL' bucket to put the errors in
     */
    processValidationErrors(errors.filter((e) => e.level === 'HARD' || !e.level))
    processValidationWarnings(errors.filter((e) => e.level === 'SOFT'))
  }

  const processValidationErrors = (errors) => {
    errors.forEach((e) => {
      // create categories for errors depending on sources
      // source should be a field or something identifiable in the form that can be addressed
      // GENERAL is for errors not related to a certain field
      // this rule isn't really enforced yet on the backend so expect to see GENERAL being used more than it should
      const source = e.source || 'GENERAL'
      let category = serverErrors.value
      let categorySource = category[source] || []
      categorySource.push({
        id: categorySource.length + 1,
        key: e.key,
        detail: e.detail,
        message: errorFormatter(e, translationsBaseErrors),
      })
      serverErrors.value[source] = categorySource
    })
  }
  const processValidationWarnings = (errors) => {
    errors.forEach((e) => {
      // create categories for warnings depending on sources
      // source should be a field or something identifiable in the form that can be addressed
      // GENERAL is for errors not related to a certain field
      // this rule isn't really enforced yet on the backend so expect to see GENERAL being used more than it should
      const source = e.source || 'GENERAL'
      let category = serverWarnings.value
      let categorySource = category[source] || []
      categorySource.push({
        id: categorySource.length + 1,
        key: e.key,
        detail: e.detail,
        message: errorFormatter(e, translationsBaseWarnings),
      })
      serverWarnings.value[source] = categorySource
    })
  }

  const errorFormatter = (error, translationsBase) => {
    const hasTranslation = messages.value[locale.value][`${translationsBase}.${error.key}`]
    if (formatErrorMessage) {
      return formatErrorMessage(error)
    } else if (hasTranslation) {
      return t(`${translationsBase}.${error.key}`, { detail: error.detail })
    } else {
      return error.message
    }
  }

  /**
   * This is the main bread and butter of this composable
   *
   * It needs to be used in catch blocks or promises
   */
  const handleValidationErrorsInResponse = (response) => {
    clearServerMessages()
    if (response.status === 422) {
      processValidation(response.data.errors)
    } else {
      toast.error(genericErrorMessage)
    }
  }

  /**
   * General purpose cleanup, can either clean everything or just a specific key if needed
   */
  const clearServerErrors = (key = null) => {
    if (key === null) {
      serverErrors.value = new ErrorConstructor()
    } else {
      serverErrors.value[key] = []
    }
  }
  const clearServerWarnings = (key = null) => {
    if (key === null) {
      serverWarnings.value = new ErrorConstructor()
    } else {
      serverWarnings.value[key] = []
    }
  }
  const clearServerMessages = (key) => {
    clearServerErrors(key)
    clearServerWarnings(key)
  }
  return {
    serverErrors,
    serverWarnings,
    handleValidationErrorsInResponse,
    processValidation,
    processValidationErrors,
    processValidationWarnings,
    clearServerMessages,
    clearServerWarnings,
    clearServerErrors,
  }
}
