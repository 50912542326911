<template>
  <PageSection>
    <SectionHeader>
      <template #section-title>{{ $t('taxman.properties') }}</template>
    </SectionHeader>

    <form>
      <div class="grid-layout grid--half pb-4">
        <div class="grid-unit">
          <div
            v-if="buffer.origin === 'WN'"
            v-test="'current-origin'"
            class="TermDetails-origin"
          >
            <SvgIcon
              name="48-ic-winnow"
              xl
            />
          </div>

          <div class="formSection">
            <div class="formGroup">
              <label>{{ $t('taxman.location') }}</label>

              <template v-if="parent && parent.detailLevel === null">
                <div>
                  <SvgIcon
                    class="Tree-folder mr-2 inline"
                    name="folder"
                  />
                  <span v-test="'parent'">{{ parentName }}</span>
                </div>
              </template>
              <template v-else>
                <!-- prettier-ignore -->
                <div
                  v-if="buffer.parentId !== null"
                  v-test="'parent'"
                  :class="['has-dot', 'level-' + parentDetailLevelClass]"
                >{{ parentName }}</div>
                <div
                  v-else
                  v-test="'parent'"
                  class="text-grey-400"
                >
                  {{ $t('taxman.rootNode') }}
                </div>
              </template>
            </div>

            <ValidationGroup
              v-slot="{ hasAnyErrors, hasWarnings }"
              :validator="v$.buffer.termName"
              :server-errors="serverErrors.termName"
              :server-warnings="serverWarnings.termName"
              class="formGroup"
            >
              <label for="term-name">{{ $t('taxman.termName') }} <b>*</b></label>
              <input
                id="term-name"
                v-model.trim="v$.buffer.termName.$model"
                type="text"
                :class="['formControl', { 'is-warning': hasWarnings, 'is-invalid': hasAnyErrors }]"
                @input="clearServerMessages('termName')"
              />
            </ValidationGroup>

            <ValidationGroup
              v-slot="{ hasAnyErrors, hasWarnings }"
              :server-errors="serverErrors.displayName"
              :server-warnings="serverWarnings.displayName"
              class="formGroup"
            >
              <label for="display-name">{{ $t('taxman.displayName') }}</label>
              <input
                id="display-name"
                v-model.trim="buffer.displayName"
                type="text"
                :class="['formControl', { 'is-warning': hasWarnings, 'is-invalid': hasAnyErrors }]"
                @input="clearServerMessages('displayName')"
              />
            </ValidationGroup>

            <ValidationGroup
              v-slot="{ hasAnyErrors, hasWarnings }"
              :validator="v$.buffer.termType"
              :server-errors="serverErrors.termType"
              :server-warnings="serverWarnings.termType"
              class="formGroup"
            >
              <label for="term-type">{{ $t('taxman.termType') }} <b>*</b></label>
              <select
                id="term-type"
                v-model="v$.buffer.termType.$model"
                :class="['customSelect', { 'is-warning': hasWarnings, 'is-invalid': hasAnyErrors }]"
                @change="clearServerMessages('termType')"
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('taxman.termType.placeholder') }}
                </option>
                <option
                  v-for="{ id, name } in termTypes"
                  :key="id"
                  :value="id"
                >
                  {{ name }}
                </option>
              </select>
            </ValidationGroup>

            <ValidationGroup
              v-slot="{ hasAnyErrors, hasWarnings }"
              :validator="v$.buffer.detailLevel"
              :server-errors="serverErrors.detailLevel"
              :server-warnings="serverWarnings.detailLevel"
              class="formGroup"
            >
              <label for="detail-level">{{ $t('taxman.detailLevel') }} <b>*</b></label>
              <select
                id="detail-level"
                v-model="v$.buffer.detailLevel.$model"
                :class="['customSelect', { 'is-warning': hasWarnings, 'is-invalid': hasAnyErrors }]"
                @change="clearServerMessages('detailLevel')"
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('taxman.detailLevel.placeholder') }}
                </option>
                <option
                  v-for="{ id, name } in termLevels"
                  :key="id"
                  :value="id"
                >
                  {{ name }}
                </option>
              </select>
            </ValidationGroup>
          </div>
        </div>
        <div class="grid-unit">
          <div class="formSection">
            <ValidationGroup
              v-slot="{ hasAnyErrors, hasWarnings }"
              :validator="v$.buffer.description"
              :server-errors="serverErrors.description"
              :server-warnings="serverWarnings.description"
              class="formGroup"
            >
              <label for="description">{{ $t('taxman.description') }} <b>*</b></label>
              <textarea
                id="description"
                v-model.trim="v$.buffer.description.$model"
                :class="['formControl', { 'is-warning': hasWarnings, 'is-invalid': hasAnyErrors }]"
                rows="12"
                @input="clearServerMessages('description')"
              ></textarea>
            </ValidationGroup>
          </div>
        </div>
      </div>
    </form>
  </PageSection>

  <FormTermLinks
    v-if="isEditing"
    :record="record"
  />

  <div
    v-if="serverErrors.GENERAL.length || serverWarnings.GENERAL.length"
    class="genericErrors"
  >
    <div class="genericErrors-icon">
      <SvgIcon name="24-ic-warning" />
    </div>
    <ValidationGroup
      :server-errors="serverErrors.GENERAL"
      :server-warnings="serverWarnings.GENERAL"
    />
  </div>

  <div class="flex flex-right mb-6 wrapper">
    <ValidationSummary
      :validator="v$.buffer"
      :server-errors="serverErrors"
      :server-warnings="serverWarnings"
      class="mt-1"
    />
    <div>
      <Loading-spinner
        v-if="isSaving"
        class="spinner--md spinner--displaceButton"
      />
      <button
        v-else
        v-test="'save'"
        type="submit"
        class="button button--primary"
        @click.prevent="save()"
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('actions.save') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { dasherize } from '@/utils/string'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { cloneDeep } from 'lodash'

import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationSummary from '@/components/Validation/ValidationSummary.vue'

import FormTermLinks from '@/components/FormTermLinks.vue'
import { useToast } from 'vue-toastification'
import { useValidation } from '@/composables/useValidation'

const actualName = (item) => {
  return item.displayName || item.termName
}

export default {
  components: {
    ValidationGroup,
    ValidationSummary,
    FormTermLinks,
  },
  props: {
    record: Object,
    parentId: String,
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation({
        errorStructure: [
          'GENERAL',
          'termName',
          'displayName',
          'description',
          'termType',
          'detailLevel',
        ],
      })
    return {
      toast: useToast(),
      v$: useVuelidate(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },
  data() {
    return {
      isSaving: false,
      isValidating: false,
      parent: false,
      buffer: cloneDeep(this.record),
      selected: null,
    }
  },
  validations() {
    return {
      buffer: {
        termName: {
          required,
        },
        description: {
          required,
        },
        termType: {
          required,
        },
        detailLevel: {
          required,
        },
      },
    }
  },

  computed: {
    ...mapGetters('taxonomy', ['termTypes', 'termLevels', 'getDetailLevelById']),
    ...mapState('taxonomy', {
      optionsLoading: (state) => state.options.loading,
    }),
    parentName() {
      return this.parent ? `${actualName(this.parent)} (${this.parent.code})` : ''
    },
    parentDetailLevelClass() {
      return this.parent ? dasherize(this.getDetailLevelById(this.parent.detailLevel)) : ''
    },
  },

  created() {
    if (this.parentId !== null) {
      this.parent = this.$store.getters['taxonomy/facets/byId'](this.parentId)

      this.$store
        .dispatch('taxonomy/facets/getById', this.parentId)
        .then((facet) => {
          this.parent = facet
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
        })
    }
  },

  methods: {
    save() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.isSaving = true

      this.$store
        .dispatch('taxonomy/facets/saveFacet', {
          oldModel: cloneDeep(this.record),
          newModel: this.buffer,
        })
        .then(() => {
          this.$router.push({ name: 'facet-index' })

          const message = this.$t('toast.success.taxman.saveFacet')
          this.$nextTick(() => {
            this.toast.success(message)
          })
        })
        .catch(this.handleValidationErrorsInResponse)
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}
</script>
