<template>
  <div>
    <slot
      :client-errors="clientErrors"
      :all-errors="allErrors"
      :has-errors="hasErrors"
      :has-server-errors="hasServerErrors"
      :has-any-errors="hasAnyErrors"
      :has-warnings="hasWarnings"
      :all-warnings="allWarnings"
    >
    </slot>
    <template v-if="!custom">
      <template v-if="hasErrors">
        <div
          v-for="(error, i) in clientErrors"
          :key="`c${error.key + i}`"
          class="field-validation"
          data-test-field-validation
        >
          <SvgIcon
            name="24-ic-warning"
            class="text-carrot icon--sm"
            sm
          ></SvgIcon>
          {{ $t(`validation.${error.key}`, error.$params) }}
        </div>
      </template>
      <template v-if="hasServerErrors">
        <div
          v-for="(error, i) in serverErrors"
          :key="`s${error.key + i}`"
          class="field-validation"
          data-test-field-validation
        >
          <SvgIcon
            name="24-ic-warning"
            class="text-carrot icon--sm"
            sm
          ></SvgIcon>
          <div>
            <div v-if="withIds">
              <span class="marker is-invalid">{{ error.id }}</span>
            </div>
            {{ $t(`validation.${error.key}`) }}
          </div>
        </div>
      </template>
      <template v-if="hasWarnings">
        <div
          v-for="(warning, i) in serverWarnings"
          :key="`w${warning.key + i}`"
          class="field-validation"
          data-test-field-validation
        >
          <SvgIcon
            name="24-ic-info"
            class="text-carrot icon--sm"
            sm
          ></SvgIcon>
          <div>
            <div v-if="withIds">
              <span class="marker is-warning">{{ warning.id }}</span>
            </div>
            {{ $t(`validation.${warning.key}`) }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    validator: {
      type: Object,
    },
    serverErrors: {
      type: Array,
      default: () => [],
    },
    serverWarnings: {
      type: Array,
      default: () => [],
    },
    withIds: {
      type: Boolean,
      default: false,
    },
    trackingData: {
      type: Object,
      default: null,
    },
    custom: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activeErrors() {
      if (!this.validator) return []
      return (this.validator.$errors || []).map((error) => ({
        ...error,
        id: error.$uid,
        validationKey: error.$validator,
      }))
    },
    hasErrors() {
      return !!this.activeErrors.length
    },
    firstErrorMessage() {
      if (this.hasErrors) return this.activeErrors[0].$message
      else return ''
    },
    clientErrors() {
      return this.activeErrors.map((el) => ({ ...el, key: el.validationKey }))
    },
    allErrors() {
      return this.clientErrors.concat(this.serverErrors)
    },
    hasServerErrors() {
      return !!this.serverErrors.length
    },
    hasAnyErrors() {
      return this.hasErrors || this.hasServerErrors
    },
    hasWarnings() {
      return !!this.serverWarnings.length
    },
    allWarnings() {
      return [...this.serverWarnings]
    },
  },
  watch: {
    allErrors: {
      handler() {
        if (this.trackingData && this.firstErrorMessage) {
          this.analytics.track(this.trackingData.action, {
            ...this.trackingData.data,
            error: this.firstErrorMessage,
          })
        }
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
.formControl,
.customSelect,
.multiselect__tags {
  .is-warning > &,
  &.is-warning {
    border-color: theme('colors.carrot.DEFAULT');
    @apply shadow-inputWarning;

    &:focus {
      @apply shadow-ctrl outline-none border-transparent;
    }
  }
  .is-invalid > &,
  &.is-invalid {
    border-color: theme('colors.carrot.DEFAULT');
    @apply shadow-inputError;

    &:focus {
      @apply shadow-ctrl outline-none border-transparent;
    }
  }
}
.field-validation {
  @apply my-1 text-xs text-grey-900 flex gap-1;
}
.is-inverted .field-validation {
  @apply text-grey-100;
}
.customControl--radio.is-warning .customControl-label::after {
  @apply shadow-inputWarning;
}

.customControl-input {
  .is-warning > &,
  &.is-warning {
    ~ .customControl-label::before {
      border-color: theme('colors.carrot.DEFAULT');
      border-width: theme('borderSize.input');
      @apply shadow-inputWarning;
    }
    ~ .customControl-label::after {
      border-width: theme('borderSize.input');
      border-color: theme('colors.carrot.DEFAULT');
    }

    &:checked .customControl-label::before {
      border-color: theme('colors.carrot.hsluv');
    }

    &:focus {
      ~ .customControl-label::before {
        @apply shadow-btnWarning;
      }

      &:not(:checked) ~ .customControl-label::before {
        border-color: theme('colors.carrot.DEFAULT');
      }
    }

    &:active ~ .customControl-label::before {
      border-color: theme('colors.carrot.DEFAULT');
    }
  }

  .is-invalid > &,
  &.is-invalid {
    ~ .customControl-label::before {
      border-width: theme('borderSize.input');
      border-color: theme('colors.carrot.DEFAULT');
      @apply shadow-inputError;
    }
    ~ .customControl-label::after {
      border-width: theme('borderSize.input');
      border-color: theme('colors.carrot.DEFAULT');
    }

    &:checked .customControl-label::before {
      border-color: theme('colors.carrot.hsluv');
    }

    &:focus {
      ~ .customControl-label::before {
        @apply shadow-btnWarning;
      }

      &:not(:checked) ~ .customControl-label::before {
        border-color: theme('colors.carrot.DEFAULT');
      }
    }

    &:active ~ .customControl-label::before {
      border-color: theme('colors.carrot.DEFAULT');
    }
  }
}

.customControl--checkbox.is-invalid,
.customControl--radio.is-invalid,
.customControl--checkbox.is-warning,
.customControl--radio.is-warning {
  ~ .field-validation {
    @apply my-0;
  }
  ~ .field-validation .icon {
    @apply ml-1 mr-2;
  }
}
</style>
