<template>
  <span>{{ fullName }}</span>
</template>
<script>
export default {
  functional: true,
  props: {
    record: Object,
  },
  computed: {
    fullName() {
      const name = this.record.displayName || this.record.termName
      const fullName = `${name} (${this.record.code})`
      return fullName
    },
  },
}
</script>
