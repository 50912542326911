<template>
  <page-section>
    <!-- Header -->
    <section-header>
      <!-- Navigation tabs for assigned/all trackers -->
      <template #section-title>Assign and deploy menu to trackers</template>
      <template #section-navigation-tabs>
        <TabsLvl2Links id="issue-types-navigation">
          <li
            v-for="filter in filters"
            :key="filter.id"
          >
            <button
              class="tab-item"
              data-test-filter-option
              :class="{ 'is-active': filter.id === selectedFilter }"
              :aria-pressed="filter.id === selectedFilter"
              @click="toggleTrackersDisplay({ id: filter.id })"
            >
              <span>{{ filter.title }}</span>
            </button>
          </li>
        </TabsLvl2Links>
      </template>
      <!-- Filter results -->
      <template #section-navigation-actions>
        <filter-input
          v-model="search"
          :total-results="availableItems"
          data-test-search-name
          @search="onSearch"
        >
          {{ $t('menus.menuDeploy.labels.filter') }}
        </filter-input>
      </template>
    </section-header>

    <!-- Deployment -->
    <div
      class="flex flex-wrap -mx-1 mb-4 gap-2 items-center"
      data-test-deploy-section
      :aria-busy="menuStatus === 'VALIDATING'"
    >
      <!-- Deploy button -->
      <button
        v-if="showAssigned"
        type="button"
        class="mx-1 button button--primary"
        :disabled="!deployAvailable"
        data-test-deploy
        @click="triggerDeployment"
      >
        <SvgIcon name="24-ic-check-all" />
        <span>{{ $t('menus.menuDeploy.actions.deploy') }}</span>
      </button>

      <div
        v-if="menuStatus === 'VALIDATING'"
        class="flex flex-start flex-middle"
      >
        <Loading-Spinner class="spinner mx-2" />
        <span class="text-sm text-blueberry">{{
          $t('menus.menuDeploy.labels.validatingMenu')
        }}</span>
      </div>

      <!-- Error message for validation issues -->
      <div
        v-if="menuStatus === 'INVALID' && hasFoodItemIssues"
        class="alert alert--warning mx-1"
      >
        <SvgIcon name="24-ic-warning" />
        <i18n-t
          keypath="menus.menuDeploy.errors.issues"
          tag="p"
          scope="global"
        >
          <template #issuesLink>
            <router-link :to="{ name: 'menu-issues' }">{{
              $t('menus.menuDeploy.errors.issuesLink')
            }}</router-link>
          </template>
        </i18n-t>
      </div>

      <!-- Error message for no changes -->
      <div
        v-if="menuStatus === 'INVALID' && errors['NO_CHANGES_SINCE_LAST_DEPLOY']"
        class="alert alert--info mx-1"
      >
        <SvgIcon name="24-ic-info" />
        {{ $t('menus.menuDeploy.errors.noChanges') }}
      </div>
    </div>

    <!-- Organisation filter -->
    <Organisation-browser
      v-if="!showAssigned"
      class="box-table"
      :selected-node-id="selectedOrg"
      :focused-node-id="selectedOrg"
      :is-editable="false"
      :archived-available="!readonly"
      @node-select="selectOrgFilter"
    />

    <!-- Advanced filters -->
    <Collapsible-section
      v-if="!showAssigned"
      class="collapsible-section--first box-content-full-width"
      data-test-advanced-filters
    >
      <template #header>
        {{ $t('titles.advancedFilters') }}
      </template>
      <template #header-side>
        <FilterChipTeaser
          v-if="selectedPackageIds && selectedPackageIds.length"
          :model-value="selectedPackageNames"
          :loading="packageLoadingStatus === 'LOADING'"
          data-test-package-chip
        />
      </template>

      <!-- Package filter -->
      <Loaded-content
        :is-loading="packageLoadingStatus === 'LOADING'"
        :has-error="packageLoadingStatus === 'ERROR'"
        :has-data="packageLoadingStatus === 'LOADED' && !!packages.length"
        class="flex flex-wrap"
      >
        <AdvancedFiltersCheckboxList
          v-model="selectedPackageIds"
          :list="packages"
          data-test-package-selector
        >
          <template #title>
            {{ $t('menus.menuDeploy.labels.package') }}
          </template>
        </AdvancedFiltersCheckboxList>
      </Loaded-content>
    </Collapsible-section>

    <!-- Main content -->
    <Loaded-content
      :is-loading="trackersLoadingStatus === 'LOADING'"
      :has-error="trackersLoadingStatus === 'ERROR'"
      :has-data="trackersLoadingStatus === 'LOADED' && showData"
      data-test-trackers
    >
      <!-- No results message for assigned tab -->
      <template
        v-if="showAssigned && !readonly"
        #no-data
      >
        <i18n-t
          keypath="menus.menuDeploy.errors.noResultsAssigned"
          tag="p"
          scope="global"
        >
          <template #compatibleTrackers>
            <router-link :to="{ query: { filter: 'available' } }">
              {{ $t('menus.menuDeploy.errors.noResultsAssigned.compatible-trackers') }}
            </router-link>
          </template>
        </i18n-t>
      </template>

      <!-- No results message for 'all' or available tab -->
      <template
        v-else-if="!showAssigned"
        #no-data
      >
        {{ $t('menus.menuDeploy.errors.noResultsAvailable') }}
      </template>
      <template
        v-else-if="readonly"
        #no-data
      >
        {{ $t('menus.menuDeploy.errors.noResultsEditingDisabled') }}
      </template>

      <div class="flex flex-between flex-wrap flex-middle mb-2">
        <!-- Available trackers counter / title -->
        <div aria-live="assertive">
          <template v-if="showAssigned">
            {{ $t('menus.menuDeploy.title', { count: availableItems }) }}
          </template>
          <template v-else>
            {{ $t('menus.menuDeploy.titleAll', { count: availableItems }) }}
          </template>
        </div>

        <!-- Refresh results button -->
        <button
          type="button"
          class="button button--sm button--secondary"
          @click="getTrackers"
        >
          <SvgIcon
            name="24-ic-repeat"
            sm
          />
          <span>{{ $t('menus.menuDeploy.actions.refreshList') }}</span>
        </button>
      </div>

      <Sortable-table
        class="box-table"
        :headers="headers"
        :sorted-by="sortBy.col"
        :sorted-dir="sortBy.dir"
        @sorted="onSort"
      >
        <tbody>
          <tr
            v-for="tracker in trackers"
            :key="tracker.id"
            class="tr-pop"
            :class="{
              'tr-highlight--highlightLemon':
                tracker.activeMenu && tracker.activeMenu.id === menuId && !showAssigned,
            }"
            data-test-tracker
          >
            <!-- Tracker name column -->
            <td data-test-name>
              <div v-if="tracker.organisation">{{ tracker.organisation.parsedName }}</div>
              <div
                v-else
                class="text-grey-400 flex flex-middle"
              >
                <span>{{ $t('menus.menuDeploy.labels.noSite') }}</span>
              </div>
              <div class="text-sm text-grey-400">{{ tracker.name }}</div>
            </td>

            <!-- Tracker package column -->
            <td data-test-package>
              {{ tracker.organisation.packageName }}
            </td>

            <!-- Vision enabled column -->
            <td
              class="text-center"
              data-test-vision
            >
              <SvgIcon
                v-if="tracker.visionEnabled"
                class="m-auto"
                name="24-ic-vision"
              />
              <span
                v-if="tracker.visionEnabled"
                class="sr-only"
              >
                {{ $t('menus.form.visionLabel') }}
              </span>
            </td>

            <!-- Last online / last ping column -->
            <td data-test-last-update>
              <div>{{ moment(tracker.lastPing).fromNow() }}</div>
              <div class="text-sm text-grey-400">
                {{ formatDate(tracker.lastPing) }}
                -
                {{ formatTime(tracker.lastPing) }}
              </div>
            </td>

            <!-- Active menu column -->
            <td data-test-active-menu>
              <div
                v-if="tracker.activeMenuId === 'OLD_MENU'"
                class="text-grey-400"
              >
                {{ $t('labels.oldMenu') }}
              </div>
              <div v-else-if="tracker.activeMenu && tracker.activeMenu.id">
                <router-link
                  :to="{ name: 'menu-summary', params: { menuId: tracker.activeMenu.id } }"
                >
                  {{ tracker.activeMenu.name }}
                </router-link>
              </div>
              <div v-else>{{ $t('labels.notAvailable') }}</div>
              <div
                v-if="tracker.activeMenu && tracker.activeMenu.description"
                class="text-sm text-grey-400"
              >
                {{ tracker.activeMenu.description }}
              </div>
            </td>

            <!-- Tracker updated column -->
            <td>
              <div>{{ formatDate(tracker.updated) }}</div>
              <div class="text-sm">{{ formatTime(tracker.updated) }}</div>
            </td>

            <!-- Actions column -->
            <td>
              <!-- Unassign button -->
              <ButtonWithSpinner
                v-if="tracker.activeMenuId === menuId"
                type="button"
                class="button--secondary show-on-hover-action"
                :in-progress="savingStatuses[tracker.id] === 'SAVING'"
                :disabled="readonly"
                data-test-unassign
                @click="unassignTracker(tracker)"
              >
                {{ $t('menus.menuDeploy.actions.unassign') }}
              </ButtonWithSpinner>

              <!-- Assign button -->
              <ButtonWithSpinner
                v-else
                type="button"
                class="button--primary show-on-hover-action"
                spinner-class="'spinner--white'"
                :in-progress="savingStatuses[tracker.id] === 'SAVING'"
                :disabled="errors['NO_CHANGES_SINCE_LAST_DEPLOY'] || readonly"
                data-test-assign
                @click="assignTracker(tracker)"
              >
                {{ $t('menus.menuDeploy.actions.assign') }}
              </ButtonWithSpinner>
            </td>
          </tr>
        </tbody>
      </Sortable-table>
      <Pagination
        :total="availableItems"
        :size="pageSize"
      />
    </Loaded-content>
  </page-section>
</template>

<script>
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
import FilterInput from '@/components/FilterInput.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import SortableTable from '@/components/SortableTable/SortableTable.vue'
import OrganisationBrowser from '@/components/OrgBrowser/OrgBrowser.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import FilterChipTeaser from '@/components/AdvancedFiltersChipTeaser.vue'
import AdvancedFiltersCheckboxList from '@/components/AdvancedFiltersCheckboxList.vue'
import moment from 'moment-timezone'
import { PAGESIZE_MENUS } from '@/store/constants'
import { intersection } from 'lodash'
import { DATE_FORMAT, TIME_FORMAT } from '@/store/constants'
import { useToast } from 'vue-toastification'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'

export default {
  components: {
    TabsLvl2Links,
    FilterInput,
    LoadedContent,
    Pagination,
    SortableTable,
    OrganisationBrowser,
    CollapsibleSection,
    FilterChipTeaser,
    AdvancedFiltersCheckboxList,
    ButtonWithSpinner,
  },
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      pageSize: PAGESIZE_MENUS,
      availableItems: 0,
      trackersLoadingStatus: 'IDLE',
      // toggle to see all trackers or just the ones the menu is deployed on
      menuIdBackups: {},
      search: this.$route.query.search,
      selectedFilter: this.$route.query.filter || 'assigned',
      selectedOrg: this.$route.query.org,
      selectedPackageIds: this.$route.query.packages || ['ALL'],
      savingStatuses: {},
      menuStatus: 'LOADING',
      deployStatus: 'IDLE',
      packageLoadingStatus: 'IDLE',
      errors: {},
    }
  },
  computed: {
    readonly() {
      return this.menu.archived
    },
    userTimezone() {
      return moment.tz.guess()
    },
    errorsList() {
      return Object.keys(this.errors)
    },
    hasFoodItemIssues() {
      return !!intersection(
        [
          'DUPLICATE_MAPPINGS_EXCEPTION',
          'PARENT_CHILD_CONFLICTS_EXCEPTION',
          'COST_NOT_VALID',
          'PORTION_NOT_VALID',
        ],
        this.errorsList
      ).length
    },
    filters() {
      return [
        {
          id: 'assigned',
          title: this.$t('menus.menuDeploy.navigation.assigned'),
        },
        {
          id: 'available',
          title: this.$t('menus.menuDeploy.navigation.available'),
        },
      ]
    },
    headers() {
      return [
        { id: 'th-name', title: this.$t('menus.menuDeploy.headers.tracker'), sortable: true },
        {
          id: 'th-packageName',
          title: this.$t('menus.menuDeploy.headers.packageName'),
          sortable: false,
        },
        {
          id: 'th-vision',
          title: this.$t('menus.menuDeploy.headers.vision'),
          class: `td-small-col`,
        },
        { id: 'th-lastPing', title: this.$t('menus.menuDeploy.headers.lastPing'), sortable: true },
        {
          id: 'th-activeMenu',
          title: this.$t('menus.menuDeploy.headers.activeMenu'),
        },
        { id: 'th-updated', title: this.$t('menus.menuDeploy.headers.updated'), sortable: true },
        {
          id: 'th-actions',
          title: this.$t('menus.menuDeploy.headers.actions'),
          hidden: true,
          class: 'td-small-col',
        },
      ]
    },
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    organisationId() {
      return this.menu.organisation.id
    },
    trackers() {
      if (this.showAssigned) {
        return this.$store.getters['menu-management/trackers/assignedTrackersForMenu'](this.menuId)
      }
      return this.$store.getters['menu-management/organisations/trackersForOrganisation'](
        this.organisationId
      )
    },
    page() {
      return this.$route.query.page - 1
    },
    sortBy() {
      let key = this.$route.query.sortBy || 'name'
      let dir = key.charAt(0) === '-' ? 'descending' : 'ascending'
      let col = `th-${dir === 'descending' ? key.slice(1) : key}`

      return {
        key,
        col,
        dir,
      }
    },
    showAssigned() {
      return this.selectedFilter === this.filters[0].id
    },
    deployAvailable() {
      if (this.readonly) return false
      let isAvailable = false
      isAvailable = !!this.showAssigned
      isAvailable = isAvailable && this.availableItems > 0
      isAvailable = isAvailable && this.deployStatus === 'IDLE'
      return isAvailable
    },
    packages() {
      return this.$store.getters['hierarchy/nodes/packages']
    },
    selectedPackages() {
      if (!this.selectedPackageIds) return []
      return this.selectedPackageIds
        .map((id) => this.packages.find((pkg) => pkg.id == id))
        .filter((pkg) => !!pkg)
    },
    selectedPackageNames() {
      return this.selectedPackages.map(({ name }) => name)
    },
    showData() {
      if (this.selectedPackageIds === null) return false
      return !!this.trackers.length
    },
  },
  watch: {
    $route(to, from) {
      this.search = to.query.search
      this.setupSelectedFilter(to)
      this.setupOrgFilter(to)
      if (to.query.filter !== from.query.filter) {
        this.validateMenu()
      }
      this.getTrackers()
    },
    packages(list) {
      if (!list.length) {
        this.getPackages()
      } else {
        this.packageLoadingStatus = 'LOADED'
      }
    },
    selectedPackageIds() {
      this.selectPackageFilter()
    },
  },
  created() {
    this.setupSelectedFilter(this.$route)
    this.setupOrgFilter(this.$route)
    this.setupPackageFilter(this.$route)
    this.validateMenu()
    this.getTrackers()
    if (!this.packages.length) {
      this.getPackages()
    } else {
      this.packageLoadingStatus = 'LOADED'
    }
  },
  methods: {
    moment,
    setupSelectedFilter(route) {
      this.selectedFilter = route.query.filter ? route.query.filter : this.filters[0].id
    },
    setupOrgFilter(route) {
      this.selectedOrg = !this.showAssigned ? route.query.org || this.organisationId : undefined
    },
    setupPackageFilter(route) {
      if (this.showAssigned) return (this.selectedPackageIds = [])
      else {
        return (this.selectedPackageIds = route.query.packages
          ? route.query.packages.split('|')
          : [])
      }
    },
    validateMenu() {
      if (this.readonly) return
      this.menuStatus = 'VALIDATING'
      return this.$store
        .dispatch('menu-management/menus/checkMenuIssues', { menuId: this.menuId })
        .then((validationError) => {
          if (validationError) {
            this.menuStatus = 'INVALID'
            if (validationError.status === 422) {
              this.processValidationErrors(validationError.data.errors)
            } else {
              return Promise.reject(validationError)
            }
          } else {
            this.menuStatus = 'VALID'
          }
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
        })
    },
    getTrackers(silent = true) {
      this.trackersLoadingStatus = silent ? 'LOADING' : 'LOADED'
      let storeAction = `menu-management/trackers/`
      if (this.showAssigned) {
        storeAction += 'getAssignedTrackers'
      } else {
        storeAction += 'getCompatibleTrackers'
      }
      let filters = {}
      if (this.search) {
        filters['name'] = this.search
      }
      if (this.selectedOrg && !this.showAssigned) {
        filters['organisationId'] = this.selectedOrg
      }
      if (this.selectedPackageIds && this.selectedPackageIds.length && !this.showAssigned) {
        filters['packageIds'] = this.selectedPackageIds
      }
      if (!Object.keys(filters).length) {
        filters = undefined
      }
      return this.$store
        .dispatch(storeAction, {
          organisationId: this.organisationId,
          menuId: this.menuId,
          sortBy: this.sortBy.key,
          page: this.page || undefined,
          size: this.pageSize,
          filters,
        })
        .then(({ total }) => {
          this.trackersLoadingStatus = 'LOADED'
          this.availableItems = total
          if (!total) {
            this.menuStatus = 'INVALID'
            this.errors['DEPLOY_NO_ASSIGNED_TRACKERS'] = true
          }
        })
        .catch(() => {
          this.trackersLoadingStatus = 'ERROR'
        })
    },
    getPackages() {
      this.packageLoadingStatus = 'LOADING'
      return this.$store
        .dispatch('hierarchy/nodes/getPackages')
        .then(() => {
          this.packageLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.packageLoadingStatus = 'ERROR'
        })
    },
    onSort({ column, direction }) {
      let id = column.slice(3)
      let sortBy = direction === 'descending' ? `-${id}` : id
      this.$router.push({ query: { ...this.$route.query, sortBy } })
    },
    toggleTrackersDisplay(filter) {
      let org = this.$route.query.org
      if (filter.id === 'assigned') {
        org = undefined
      }
      let query = { ...this.$route.query, filter: filter.id, page: undefined, org }
      this.$router.push({ query })
    },
    unassignTracker(tracker) {
      this.saveTrackerChanges('unassignTracker', {
        trackerId: tracker.id,
        oldMenuId: tracker.activeMenuId,
        activeMenuId: null,
      })
    },
    assignTracker(tracker) {
      this.saveTrackerChanges('assignTracker', {
        trackerId: tracker.id,
        oldMenuId: tracker.activeMenuId === 'OLD_MENU' ? null : tracker.activeMenuId,
        activeMenuId: this.menuId,
      })
    },
    saveTrackerChanges(type, { trackerId, activeMenuId, oldMenuId }) {
      this.savingStatuses[trackerId] = 'SAVING'
      this.$store
        .dispatch(`menu-management/trackers/${type}`, { trackerId, activeMenuId, oldMenuId })
        .then(() => (this.savingStatuses[trackerId] = 'SAVED'))
        .then(() => {
          return this.$store.dispatch('menu-management/menus/getMenu', { menuId: this.menuId })
        })
        .catch((err) => {
          if (err.status === 422) {
            let errors = err.data.errors || []
            let textError = errors
              .map((error) => {
                return this.$t(`menus.menuDeploy.errors.${error.key}`)
              })
              .join('<br />')
            this.toast.error(textError)
          } else {
            this.toast.error(this.$t('toast.error.saveData'))
          }
          this.savingStatuses[trackerId] = 'ERROR'
        })
    },
    triggerDeployment() {
      this.deployStatus = 'IN_PROGRESS'
      this.$store
        .dispatch('menu-management/menus/deployMenu', { menuId: this.menuId })
        .then(() => {
          this.deployStatus = 'DEPLOYED'
          this.getTrackers(false)
          this.toast.success(this.$t('menus.menuDeploy.success.deploy'))
        })
        .catch((err) => {
          this.deployStatus = 'ERROR'
          if (err.status === 422) {
            let errors = err.data.errors || []
            let textError = errors
              .map((error) => {
                return this.$t(`menus.menuDeploy.errors.${error.key}`)
              })
              .join('<br />')
            this.toast.error(textError)
          } else {
            this.toast.error(this.$t('menus.menuDeploy.errors.notDeployed'))
          }
        })
    },
    onSearch() {
      let search = this.search ? this.search : undefined
      this.$router.push({ query: { ...this.$route.query, search, page: undefined } })
    },
    processValidationErrors(errors) {
      this.errors = errors.reduce((hash, error) => {
        hash[error.key] = true
        return hash
      }, {})
    },
    selectOrgFilter(id) {
      let org = id
      this.selectedOrg = id
      this.$router.push({ query: { ...this.$route.query, org, page: undefined } })
    },
    selectPackageFilter() {
      let packages =
        this.selectedPackageIds && this.selectedPackageIds.length
          ? this.selectedPackageIds.join('|')
          : undefined
      if (this.$route.query.packages !== packages) {
        this.$router.push({
          query: {
            ...this.$route.query,
            packages,
            page: undefined,
          },
        })
      }
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT)
    },
    formatTime(date) {
      return moment(date).format(TIME_FORMAT)
    },
  },
}
</script>

<style></style>
