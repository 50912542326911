<template>
  <div>
    <div class="flex flex-middle flex-between mb-3">
      <!-- Title and edit -->
      <slot name="title" />
      <button
        v-if="locationItems.length && !isAddMode"
        data-test-sending-controls-edit-button
        class="button button--link"
        @click="toggleEdit('REMOVE')"
      >
        {{
          $t(`organisations.sendingControls.actions.${isRemoveMode ? 'cancelEdit' : 'editList'}`)
        }}
      </button>
    </div>

    <!--Current Items List -->
    <ul :class="['flex flex-wrap', hasChanges && 'box-content-separation pb-3']">
      <li
        v-for="item in displayedItems"
        :key="item.id"
      >
        <sending-control-chip
          :id="item.id"
          data-test-sending-controls-item-chip
          :source-location-id="item.locationId"
          :location-id="locationId"
          :removable="isRemoveMode"
          @remove="addInPending(item)"
        >
          {{ item.name }}
          <template #tooltip-content>
            <span>{{ $t('organisations.sendingControls.domains.messages.inherited') }} </span>
            <router-link
              data-test-sending-controls-parent-link
              :to="{
                name: 'hierarchy-node-reporting-subscribers',
                params: { nodeId: item.locationId },
                query: $route.query,
              }"
              >{{ displaySource(item.sourceLocation) }}</router-link
            >
          </template>
        </sending-control-chip>
      </li>

      <!-- Add button -->
      <li
        v-if="!isRemoveMode"
        class="flex flex-middle"
      >
        <button
          class="button button--link px-3 py-2"
          data-test-sending-controls-add-item
          @click="toggleEdit('ADD')"
        >
          <SvgIcon
            :class="[isAddMode && 'rotate-45']"
            name="24-ic-add"
          />
          <span class="font-bold">{{
            $t(isAddMode ? 'actions.close' : 'organisations.sendingControls.actions.add')
          }}</span>
        </button>
      </li>
    </ul>

    <!-- Pending list -->
    <div v-if="hasChanges">
      <h2 data-test-sending-controls-pending-message>
        {{
          $t(
            `organisations.sendingControls.messages.${
              isAddMode ? 'pendingAddItems' : 'pendingRemoveItems'
            }`
          )
        }}
      </h2>
      <ul class="flex flex-wrap">
        <li
          v-for="({ name, id, locationId: sourceLocationId }, index) in pendingItems"
          :key="id"
        >
          <sending-control-chip
            :id="id"
            :source-location-id="sourceLocationId"
            :location-id="locationId"
            data-test-sending-controls-pending-chip
            :removable="true"
            @remove="pendingItems.splice(index, 1)"
          >
            {{ name }}
          </sending-control-chip>
        </li>
      </ul>
    </div>
    <slot
      v-if="isAddMode"
      name="add-item"
      :location-items="allItemsNames"
      :add-in-pending="addInPending"
    />
    <ValidationResults
      data-test-sending-controls-validation-results
      class="mt-8"
      :server-errors="serverErrors"
    />

    <!-- Buttons Bar -->
    <div class="buttonGroup flex-top mt-8">
      <ButtonSave
        data-test-sending-controls-submit-btn
        type="button"
        :disabled="!hasChanges || savingStatus === 'SAVING'"
        :is-saving="savingStatus === 'SAVING'"
        @click="saveData"
      >
        {{ $t('actions.saveChanges') }}
      </ButtonSave>

      <button
        :disabled="!hasChanges"
        type="button"
        class="button button--secondary"
        @click="$refs.confirmationModal.open()"
      >
        <span>{{ $t('actions.cancel') }}</span>
      </button>
    </div>

    <!-- Edit Confirmation Modal -->
    <Modal ref="confirmationModal">
      <template #default="{ close }">
        <div class="modal-body">
          {{ $t('organisations.sendingControls.messages.confirmation') }}
          <div class="buttonGroup flex-center mt-4">
            <button
              class="button button--secondary"
              @click="close"
            >
              <SvgIcon name="24-ic-clear" />
              <span>{{ $t('actions.cancel') }}</span>
            </button>
            <button
              type="button"
              class="button button--primary"
              @click="
                () => {
                  pendingItems = []
                  editMode = null
                  close()
                }
              "
            >
              <SvgIcon name="24-ic-check" />
              <span>{{ $t('actions.confirm') }}</span>
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import Modal from '@/components/Modal.vue'
import { getOrgDisplayName } from '@/utils/organisations'

import { useToast } from 'vue-toastification'
import SendingControlChip from './SendingControlsHandler/SendingControlChip.vue'
import { useValidation } from '@/composables/useValidation'
import ButtonSave from '@/components/ButtonSave.vue'
let ErrorMap = function () {
  return {
    organisation: [],
  }
}

export default {
  components: {
    SendingControlChip,
    ValidationResults,
    Modal,
    ButtonSave,
  },
  props: {
    locationId: String,
    items: {
      type: Array,
      default: () => [],
    },
    saveAction: String,
  },
  setup: () => {
    const { serverErrors, handleValidationErrorsInResponse } = useValidation({
      ErrorConstructor: function () {
        return {
          organisation: [],
        }
      },
    })
    return {
      toast: useToast(),
      serverErrors,
      handleValidationErrorsInResponse,
    }
  },
  data() {
    return {
      currentItems: [],
      pendingItems: [],
      editMode: null, // "ADD" "REMOVE"
      savingStatus: 'IDLE',
    }
  },

  computed: {
    locationItems() {
      return this.currentItems.filter((d) => d.locationId === this.locationId).map((d) => d.name)
    },
    allItemsNames() {
      return this.currentItems.concat(this.pendingItems).map((item) => item.name)
    },
    displayedItems() {
      // filter out the items that are in pending (they are the items to be removed)
      return this.currentItems.filter(
        (filteredItem) => !this.pendingItems.find((item) => item.id === filteredItem.id)
      )
    },
    hasChanges() {
      return this.pendingItems.length
    },
    isRemoveMode() {
      return this.editMode === 'REMOVE'
    },
    isAddMode() {
      return this.editMode === 'ADD'
    },
  },
  watch: {
    items(newItems) {
      this.currentItems = newItems
    },
  },

  created() {
    this.currentItems = this.items
  },

  methods: {
    saveData() {
      this.savingStatus = 'SAVING'
      this.serverErrors = new ErrorMap()

      this.$store
        .dispatch(`hierarchy/nodes/${this.saveAction}`, {
          nodeId: this.locationId,
          payload: this.isRemoveMode
            ? // for remove mode we just send the displayed items they are alredy filtered
              this.displayedItems
            : // for add mode we need to concat the current items with what we have in pending
              this.currentItems.concat(this.pendingItems),
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
          this.initPendingItems()
          this.editMode = null
        })
        .catch((e) => {
          this.handleValidationErrorsInResponse(e)
          this.savingStatus = 'ERROR'
          throw e
        })
    },
    initPendingItems() {
      this.pendingItems = []
    },

    addInPending(item) {
      this.pendingItems.push({ ...item })
    },

    toggleEdit(type) {
      if (this.editMode === type) {
        if (this.pendingItems.length) {
          this.$refs.confirmationModal.open()
        } else {
          this.editMode = null
        }
      } else {
        this.editMode = type
      }
    },

    displaySource(sourceLocation) {
      return `${sourceLocation.level}: ${getOrgDisplayName(sourceLocation)}`
    },
  },
}
</script>
