<template>
  <div
    v-if="isOpen"
    class="formGroup formGroup--inline"
  >
    <label
      class="sr-only"
      :for="id"
    >
      <slot name="label">{{ $t('actions.search') }}</slot>
    </label>
    <input
      :id="id"
      ref="searchInput"
      v-model="searchTerm"
      class="formControl m-0"
      type="search"
      data-test-search-input
      @input="$emit('update:model-value', searchTerm)"
    />
    <button
      class="button button--icon"
      @click="clear"
    >
      <SvgIcon name="24-ic-clear" />
      <span class="sr-only">{{ $t('actions.clear') }}</span>
    </button>
  </div>
  <button
    v-else
    class="button button--icon"
    data-test-search-button
    @click="isOpen = true"
  >
    <SvgIcon name="24-ic-search" />
    <span class="sr-only">
      <slot name="label">{{ $t('actions.search') }}</slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    id: String,
    modelValue: String,
    preopen: Boolean,
  },
  emits: ['update:modelValue', 'update:model-value'],
  data() {
    return {
      isOpen: this.preopen || false,
      searchTerm: this.modelValue || '',
    }
  },
  watch: {
    isOpen(state) {
      if (state === true) {
        this.focus()
      }
    },
    modelValue(val) {
      this.searchTerm = val
    },
    preopen(state) {
      this.isOpen = state
    },
  },
  methods: {
    focus() {
      this.$nextTick().then(() => {
        this.$refs.searchInput.focus()
      })
    },
    clear() {
      this.searchTerm = ''
      this.$emit('update:modelValue', this.searchTerm)
      this.isOpen = false
    },
  },
}
</script>

<style></style>
