<template>
  <Collapsible-section
    id="localisationSection"
    tag="section"
    :force-open="isOpen"
    data-test-localisation-section
    @open="onOpen"
    @close="onClose"
  >
    <template #header>
      {{ $t('organisations.overview.advancedSettings.title') }}
    </template>
    <!-- Summary of selected options -->
    <template #header-side>
      <Loading-Spinner v-if="loadingStatus === 'LOADING'" />
    </template>
    <template #default>
      <Loaded-content
        :is-loading="loadingStatus === 'LOADING'"
        :has-error="loadingStatus === 'ERROR'"
        :has-data="loadingStatus === 'LOADED' && !!node"
      >
        <form
          :action="$route.fullPath"
          novalidate
          @submit.prevent="saveData"
          @reset.prevent="resetValues"
        >
          <fieldset class="formGroup flex flex-wrap gap-4">
            <legend class="font-bold w-full flex-grow">
              {{ $t('organisations.overview.sharedImageLibrary.label.title') }}
            </legend>
            <div class="customControl customControl--radio">
              <input
                id="imageLibraryShared"
                v-model="v$.buffer.sharedImageLibraryEnabled.$model"
                name="imageLibrary"
                type="radio"
                class="customControl-input"
                :value="true"
              />
              <label
                for="imageLibraryShared"
                class="customControl-label"
              >
                {{ $t('organisations.overview.sharedImageLibrary.label.shared') }}
              </label>
            </div>
            <div class="customControl customControl--radio">
              <input
                id="imageLibraryRestricted"
                v-model="v$.buffer.sharedImageLibraryEnabled.$model"
                name="imageLibrary"
                type="radio"
                class="customControl-input"
                :value="false"
              />
              <label
                for="imageLibraryRestricted"
                class="customControl-label"
              >
                {{ $t('organisations.overview.sharedImageLibrary.label.restricted') }}
              </label>
            </div>
          </fieldset>

          <!-- Propagate changes check -->
          <div
            v-if="node.level >= 4 && node.level <= 15 && node.childrenCount"
            class="formGroup customControl customControl--checkbox"
          >
            <input
              id="localisationPropagate"
              v-model="v$.propagateChanges.$model"
              data-test-localisation-propagate
              type="checkbox"
              class="customControl-input"
            />
            <label
              class="customControl-label"
              for="localisationPropagate"
            >
              {{ $t('organisations.messages.propagateToChildren', { count: node.childrenCount }) }}
            </label>
          </div>
          <div class="buttonGroup">
            <button
              type="submit"
              class="button button--primary"
              :disabled="!v$.$anyDirty || savingStatus === 'SAVING'"
              data-test-save
            >
              <SvgIcon
                v-if="savingStatus !== 'SAVING'"
                name="24-ic-check"
              />
              <Loading-Spinner
                v-if="savingStatus === 'SAVING'"
                class="icon spinner--white"
              />
              <span>{{ $t('actions.saveTheseChanges') }}</span>
            </button>
            <button
              type="reset"
              class="button button--secondary"
              :disabled="!v$.$anyDirty || savingStatus === 'SAVING'"
              data-test-cancel
            >
              {{ $t('actions.cancel') }}
            </button>
          </div>
        </form>
      </Loaded-content>
    </template>
  </Collapsible-section>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from 'vue-toastification'

function AdvancedSettingsModel(overwrites) {
  return {
    sharedImageLibraryEnabled: null,
    ...overwrites,
  }
}

export default {
  components: {
    LoadedContent,
    CollapsibleSection,
  },
  props: {
    node: Object,
    isOpen: Boolean,
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      toast: useToast(),
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      propagateChanges: false,
      buffer: this.mapAdvancedSettingsModel(),
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
    }
  },
  validations: {
    propagateChanges: {},
    buffer: {
      sharedImageLibraryEnabled: {},
    },
  },
  watch: {
    isOpen(open) {
      if (open && this.loadingStatus === 'IDLE') {
        this.loadData()
      }
    },
  },
  created() {
    if (this.cachedStatus === false) {
      this.loadData()
    } else {
      this.loadingStatus = 'LOADED'
    }
  },
  methods: {
    saveData() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('hierarchy/nodes/saveNodeChanges', {
          nodeId: this.node.id,
          propagateChanges: this.propagateChanges,
          data: {
            locationDetails: {
              sharedImageLibraryEnabled: this.v$.buffer.sharedImageLibraryEnabled.$model,
            },
          },
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.resetValues()
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    mapAdvancedSettingsModel() {
      return new AdvancedSettingsModel({
        sharedImageLibraryEnabled: this.node.locationDetails.sharedImageLibraryEnabled,
      })
    },
    resetValues() {
      this.buffer = this.mapAdvancedSettingsModel()
      this.propagateChanges = false
      this.v$.$reset()
    },
    onOpen() {
      this.$emit('open')
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
