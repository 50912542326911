<template>
  <Loaded-content
    :is-loading="loadingStatus === 'LOADING'"
    :has-error="loadingStatus === 'ERROR'"
    :has-data="loadingStatus === 'LOADED'"
  >
    <div
      ref="navigator"
      class="org-navigator"
      role="tree"
      aria-orientation="horizontal"
      @keydown.left.stop.prevent="goToPreviousLevel"
      @keydown.right.stop.prevent="goToNextLevel"
    >
      <slot :accessible-levels="accessibleLevels">
        <Organisation-navigator-column
          v-for="level in accessibleLevels"
          :key="level.level"
          ref="level"
          :selected-node-id="selectedNodeId"
          :highlighted-node-id="level.highlightedNodeId"
          :focused-node-id="focusedNodeId"
          :parent-id="level.parentId"
          :level-number="level.level"
          :next-level="level.nextLevel"
          :has-more="level.hasMore"
          :show-archived="showArchived"
          :custom-selected-node="customSelectedNode"
          @node-focus="onNodeFocus($event, level.leve)"
          @node-select="$emit('node-select', $event)"
        />
      </slot>
    </div>
  </Loaded-content>
</template>

<script>
import { WINNOW_ORG_ID } from '@/store/constants'
import LoadedContent from '@/components/LoadedContent.vue'
import OrganisationNavigatorColumn from './OrgNavigatorColumn.vue'
import { uniq, union } from 'lodash'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

function LevelModel(overwrites) {
  return {
    highlightedNodeId: null, // this is the node that gets highlighted / focused on each level column
    level: null, // number of the level
    parentId: null, // parent reference for getting child nodes to be displayed in the column
    nextLevel: null, // the following level that this node can access
    hasMore: false, // displays the "More on L[x]" button where `x` is the value we store here
    ...overwrites,
  }
}
export default {
  components: {
    LoadedContent,
    OrganisationNavigatorColumn,
  },
  props: {
    selectedNodeId: {
      type: [Array, String],
    },
    focusedNodeId: {
      type: [Array, String],
    },
    // we can force the navigator to mark a custom node as being selected
    // this should be a node structure from the $store
    // it is currently being used for placing the moving node correctly in the hierarchy
    customSelectedNode: Object,
    showArchived: {
      type: Boolean,
      default: false,
    },
    excludedLevels: {
      type: Array,
      default() {
        return []
      },
    },
    disabledLevels: {
      type: Array,
      default() {
        return []
      },
    },
  },
  emits: ['node-select', 'node-focus', 'level-index-change'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      focusedIndex: 0, // this points to the level that has or can have focus inside
      loadingStatus: 'IDLE',
    }
  },
  computed: {
    ...mapGetters({
      nodeById: 'hierarchy/nodes/byId',
      childrenNodes: 'hierarchy/nodes/getChildrenNodes',
      levels: 'hierarchy/nodes/levelsList',
    }),
    // the order of ids that can be used to boot the focusing inside the navigator
    // basically: fallbacks
    focusNodeCandidates() {
      return [this.focusedNodeId, this.selectedNodeId]
    },
    firstFocusCandidate() {
      return this.focusNodeCandidates
        .filter((id) => !!id)
        .map((id) => this.extractIdFormat(id).nodeId)[0]
    },
    // max index that can be reached by the focus pointer
    maxFocusIndex() {
      return (
        this.accessibleLevels.filter(({ level }) => this.disabledLevels.indexOf(level) == -1)
          .length - 1
      )
    },
    // this is the node that currently "has data"
    // it can be the actual focused node or the parent of a "more" button
    focusedNode() {
      return this.nodeById(this.firstFocusCandidate)
    },
    // this can be the level of the last node in the path or a level on which a "more" button sits
    focusedLevel() {
      let focusedNodeId = this.focusNodeCandidates.find((id) => !!id)
      let { nodeId, level } = this.extractIdFormat(focusedNodeId)
      let nodeChildren = this.getUniqueChildrenLevels(nodeId)
      if (level && nodeChildren.indexOf(level) > -1) return level
      if (!level && this.focusedNode) return this.focusedNode.level
      return 0
    },
    // these are the levels that can be accessed from the focused node
    nextLevelNumbers() {
      if (!this.focusedNode) return [1]
      let children = this.getUniqueChildrenLevels(this.focusedNode.id).filter(
        (level) => level > this.focusedLevel
      )
      return children
    },

    // this is the next level that can be reached from the focused node
    lastLevelNumber() {
      let pathLevels = this.pathNodes.map((node) => node.level)
      let lastLevel = pathLevels[pathLevels.length - 1] + 1
      let nextLevels = [...this.nextLevelNumbers]

      if (lastLevel > 16) return null
      // overwrite the last level with the customSelectedNode level when we have it
      if (this.customSelectedNode && this.customSelectedNode.parentId === this.focusedNode.id) {
        nextLevels.push(this.customSelectedNode.level)
        nextLevels.sort((a, b) => a - b)
      }
      if (nextLevels.length) {
        lastLevel = nextLevels[0]
      }
      return lastLevel
    },

    // this is the column that should appear at the end and should have no selections or should be empty
    lastLevel() {
      if (!this.focusedNode) return null
      if (!this.lastLevelNumber) return null

      return new LevelModel({
        highlightedNodeId: null,
        level: this.lastLevelNumber,
        nextLevel: this.nextLevelNumbers[1],

        parentId: this.focusedNode.id,
        hasMore: !!this.nextLevelNumbers[1],
      })
    },

    // "breadcrumb" arrangement of the node and its parent nodes
    // should be sorted like the qualified name
    pathNodes() {
      if (!this.focusedNode) return []
      let nodes = this.getHierarchy(this.focusedNode.id).map((node) => ({
        id: node.id,
        level: node.level,
        parentId: node.parentId,
        name: node.name,
      }))
      let firstNode = nodes[0]
      if (firstNode && firstNode.id === WINNOW_ORG_ID) {
        firstNode = { ...firstNode }
        firstNode.level = 0
        nodes[0] = firstNode
      }
      return nodes
    },

    // the levels structure that gets displayed on columns
    accessibleLevels() {
      // start to build up the levels by first using all the levels on which real nodes are placed
      let pathLevels = this.pathNodes.map((node) => {
        // create a list of all levels that can be accessed by the node and it's parent
        let parentLevels = this.getUniqueChildrenLevels(node.parentId)
        let childrenLevels = this.getUniqueChildrenLevels(node.id)
        // the list should contain only levels that come after the node's level
        let nextLevels = union(parentLevels, childrenLevels).filter((level) => level > node.level)
        // the first item in the list of levels is the next column that can be opened by selecting the node
        let nextLevel = nextLevels[0]
        return new LevelModel({
          highlightedNodeId: node.id,
          level: node.level,
          parentId: node.parentId,
          nextLevel: nextLevel,
          hasMore: false,
        })
      })

      // add the last level
      if (this.lastLevel) {
        pathLevels.push(this.lastLevel)
      }

      // add intermediary levels
      // because the children of a node can be placed on multiple levels, we need to create
      // intermediary levels that show up when the user has selected a lower tier
      pathLevels = pathLevels.flatMap((pathLevel, index, pathLevels) => {
        if (!pathLevel.nextLevel) return [pathLevel]
        else {
          let nodeId = pathLevel.highlightedNodeId
          let nextInPath = pathLevels[index + 1] || pathLevels[pathLevels.length - 1]
          let childrenLevels = this.getUniqueChildrenLevels(nodeId)
          // find the levels that are between each pair of nodes
          let missingChildren = childrenLevels.filter((childLevel) => {
            return childLevel > pathLevel.level && childLevel < nextInPath.level
          })
          // build columns for them with a fake id
          missingChildren = missingChildren.map((childLevel) => {
            return new LevelModel({
              highlightedNodeId: `${nodeId}-${childLevel}`,
              level: childLevel,
              nextLevel: childrenLevels.filter((level) => level > childLevel)[0],
              parentId: nodeId,
              hasMore: true,
            })
          })
          // now check if the current level has sibling on lower levels. in which case
          // the user needs to access them so the `more` button becomes available
          pathLevel.hasMore = !!this.getUniqueChildrenLevels(pathLevel.parentId).filter(
            (childLevel) => childLevel > pathLevel.level
          ).length

          return [pathLevel].concat(missingChildren)
        }
      })

      // remove any excluded levels
      if (this.excludedLevels.length) {
        pathLevels = pathLevels
          .filter((level) => this.excludedLevels.indexOf(level.level) == -1)
          .map((level) => {
            let { nextLevel, hasMore } = level
            if (this.excludedLevels.indexOf(level.nextLevel) > -1) {
              nextLevel = null
              hasMore = false
            }
            return { ...level, nextLevel, hasMore }
          })
      }

      return pathLevels
    },
  },
  watch: {
    focusedNodeId() {
      this.focusedIndex = this.getLevelIndex(this.focusedLevel)
    },
    showArchived() {
      // if the selected node is archived select the root node as fallback
      if (this.focusedNode && this.focusedNode.archived) {
        this.changeFocusedLevel(0)
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    getTree(id) {
      this.loadingStatus = 'LOADING'
      return this.$store
        .dispatch(`hierarchy/nodes/getTree`, id)
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch((e) => {
          this.toast.error(this.$t('toast.error.getData'))
          this.loadingStatus = 'ERROR'
          throw e
        })
    },

    init() {
      this.getTree(this.firstFocusCandidate).then(() => {
        this.changeFocusedLevel(this.getLevelIndex(this.focusedLevel))
      })
    },

    // this breaks down the focused node to extract the id and level
    // level is stored in id when the user clicks on a "More" button
    extractIdFormat(id) {
      if (!id) return null
      let fakeNodeSplit = id.toString().split('-')
      if (fakeNodeSplit.length > 1) {
        return {
          nodeId: fakeNodeSplit[0],
          level: parseFloat(fakeNodeSplit[1], 10),
        }
      }
      return {
        nodeId: id,
        level: null,
      }
    },

    // Recursively get the parents of a node.
    // This includes the original node
    // and it should look like the "qualifiedName" in terms of order.
    // The selected node should be the last item and each parent should
    // be arranged from right to left
    getHierarchy(id, nodes = []) {
      let node =
        this.customSelectedNode && this.customSelectedNode.id === id
          ? this.customSelectedNode
          : this.nodeById(id)
      if (!node) return nodes
      // adds the initial node as the single original item in the list
      if (!nodes.length) {
        nodes.push(node)
      }
      let parent = this.nodeById(node.parentId)
      if (parent) {
        // starts adding parents to the left of the node
        nodes.unshift(parent)
        return this.getHierarchy(parent.id, nodes)
      }
      // when no more parents are found, return the list
      return nodes
    },

    // gets all the levels that the node id's children are placed on
    // returns the list with all duplicates removed and sorted ascending
    getUniqueChildrenLevels(nodeId) {
      let children = this.childrenNodes(nodeId).filter((node) =>
        this.showArchived ? true : !node.archived
      )
      return uniq(children.map(({ level }) => level)).sort((a, b) => a - b)
    },

    getLevelIndex(levelId) {
      return this.accessibleLevels.findIndex((level) => level.level === levelId)
    },

    scrollToEndOfRow() {
      const $navigator = this.$refs.navigator

      if ($navigator) {
        this.$nextTick(() => {
          $navigator.scroll({
            left: $navigator.scrollWidth,
            behavior: 'smooth',
          })
        })
      }
    },
    goToPreviousLevel() {
      let index = this.focusedIndex
      index = index - 1 >= 0 ? index - 1 : index
      this.changeFocusedLevel(index)
    },
    goToNextLevel() {
      let index = this.focusedIndex
      index = index + 1 <= this.maxFocusIndex ? index + 1 : index
      this.changeFocusedLevel(index)
    },
    changeFocusedLevel(index) {
      this.focusedIndex = index
      if (this.accessibleLevels[index]) {
        this.$emit('level-index-change', this.accessibleLevels[index].level)
        if (this.$refs['level']) {
          this.$refs['level'][index].focus()
        }
      }
    },
    onNodeFocus(nodeId, level) {
      this.changeFocusedLevel(this.getLevelIndex(level))
      this.scrollToEndOfRow()
      this.$emit('node-focus', nodeId)
    },
  },
}
</script>

<style lang="scss">
.org-navigator {
  overflow-x: auto;
  overflow-y: hidden;
  resize: vertical;
  height: to-rem(250px);
  display: flex;
  background: theme('colors.white');
  border: to-rem(1px) solid theme('colors.slate.DEFAULT');
  scroll-snap-type: x mandatory;
}
</style>
