import { transform, isObject } from 'lodash'

export default function getOldValues(record, data) {
  return transform(data, (result, newValue, key) => {
    const oldValue = record[key]

    if (!Array.isArray(newValue) && isObject(newValue) && isObject(oldValue)) {
      result[key] = getOldValues(oldValue, newValue)
    } else if (oldValue !== undefined) {
      result[key] = oldValue
    } else {
      result[key] = null
    }
  })
}
