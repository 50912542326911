export default (user) => ({
  username: user.username,
  firstName: user.firstName,
  fullName: `${user.firstName} ${user.lastName}`,
  lastName: user.lastName,
  email: user.email,
  numberOfAccessibleSites: user.numberOfAccessibleSites,
  subjectOrganizationId:
    user.subjectOrganizationId || (user.subjectOrganization && user.subjectOrganization.id),
  ...(user.subjectOrganization && { subjectOrganization: user.subjectOrganization }),
  groups: user.groups,
  id: user.id + '', // convert id to string because it sometimes might be coming in as a number
  verified: user.verified,
  archived: user.archived,
  createdBy: user.createdBy,
  createdDate: user.createdDate,
  lastLogin: user.lastLogin,
  isPasswordResetPending: user.userFlags?.isPasswordResetPending,
  isSSOUser: user.userFlags?.ssoUser,
})
