<template>
  <Filter-chip
    :is-loading="loadingStatus === 'LOADING'"
    :removable="removable"
    :title="modelValue.join(', ')"
    @remove="$emit('remove')"
  >
    <Loading-Spinner
      v-if="loadingStatus === 'LOADING'"
      class="m-2"
    />
    <template v-else>{{ teaser }}</template>
  </Filter-chip>
</template>

<script>
import FilterChip from '@/components/FilterChip.vue'
export default {
  components: {
    FilterChip,
  },
  props: {
    modelValue: Array,
    loading: Boolean,
    removable: Boolean,
  },
  emits: ['remove'],
  data() {
    return {
      loadingStatus: 'IDLE',
    }
  },
  computed: {
    teaser() {
      let chunk = this.modelValue
      const length = chunk.length

      if (length > 2) {
        chunk = chunk.slice(0, 2)
        chunk.push(`+${length - 2}`)
      }

      return chunk.join(', ')
    },
  },
  watch: {
    loading(isLoading) {
      this.loadingToggle(isLoading)
    },
  },
  created() {
    this.loadingToggle(this.loading)
  },
  methods: {
    loadingToggle(isLoading) {
      if (isLoading) {
        this.loadingStatus = 'LOADING'
      } else {
        this.loadingStatus = 'LOADED'
      }
    },
  },
}
</script>

<style></style>
