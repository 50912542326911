<!--
  This is an organisation node
  - up and down keyboard presses are prevented from scrolling the page
  - space is also prevented from scrolling the page
-->
<template>
  <li
    class="org-picker-node button"
    :class="{ 'org-picker-node--with-indicator': showExpandIndicator }"
    :aria-expanded="openState"
    data-hierarchy-quick-select-button
    @keydown.down.prevent="next"
    @keydown.up.prevent="refocus"
    @keydown.space.prevent="toggle"
    @keydown.enter="toggle"
    @keydown="onKeyPress"
    @click="toggle"
  >
    <slot>
      <span
        v-if="node"
        class="org-picker-node-label"
        >{{ node.name }}</span
      >
    </slot>

    <slot
      name="icon"
      :open-state="openState"
    >
      <SvgIcon
        v-if="showExpandIndicator"
        :class="['org-picker-node-icon', openState && 'icon-open']"
        aria-hidden="true"
        name="24-ic-arrow-down"
      />
    </slot>
    <transition name="slide-in-bottom">
      <Organisation-picker-list
        v-if="openState"
        :id="id"
        ref="list"
        :parent-id="parentId"
        :show-archived="showArchived"
        @return-focus="refocus"
        @node-select="onNodeSelect"
      />
    </transition>
  </li>
</template>

<script>
import OrganisationPickerList from './OrgPickerList.vue'
export default {
  components: {
    OrganisationPickerList,
  },
  props: {
    // id of the currently selected node at its current level
    id: {
      type: [String, Number],
    },
    // parentId is required if id is not provided
    // this is for bringing the nodes that can be selected for the current level
    parentId: {
      type: [String, Number],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    showExpandIndicator: {
      type: Boolean,
      default: true,
    },
    showArchived: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close', 'node-select'],
  data() {
    return {
      openState: this.isOpen,
    }
  },
  computed: {
    node() {
      if (this.id) {
        return this.$store.getters['hierarchy/nodes/byId'](this.id)
      } else {
        return null
      }
    },
    parent() {
      if (this.id) {
        return this.$store.getters['hierarchy/nodes/byId'](this.id)
      } else if (this.parentId) {
        return this.$store.getters['hierarchy/nodes/byId'](this.parentId)
      } else {
        return null
      }
    },
  },
  watch: {
    isOpen(newState) {
      this.openState = newState
    },
  },
  methods: {
    open() {
      this.openState = true
      this.$emit('open')
    },
    close() {
      this.openState = false
      this.$emit('close')
    },
    toggle() {
      this.openState = !this.openState
      if (this.openState === true) {
        this.$emit('open')
      } else {
        this.refocus()
      }
    },
    next() {
      if (this.openState === true) {
        this.$refs['list'].goToNextNode()
      } else this.open()
    },
    refocus() {
      this.$el.focus()
      this.close()
    },
    onNodeSelect(id) {
      this.refocus()
      this.$emit('node-select', id)
    },
    onKeyPress(ev) {
      if (ev.key.length === 1 && ev.key !== ' ') {
        this.$nextTick(() => {
          this.$refs['list'].quickSearch()
        })
      }
    },
  },
}
</script>

<style lang="scss">
.org-picker-node {
  border-radius: 0;
  border: 0;
  position: relative;
  margin: 0 0 0 theme('spacing.px');
  will-change: padding-right;
  transition: padding-right 0.1s ease-out;
  padding: 0.5rem;
  width: 100%;
  min-width: 0;
  display: flex;
  justify-content: flex-start !important; // otherwise overwritten by button class
  // @TODO: revisit this after final import order is settled for styles
  align-items: center;

  &:hover,
  &:focus,
  &[aria-expanded='true'] {
    background-color: theme('colors.acai.200');
    cursor: pointer;
    .icon {
      opacity: 1;
    }
  }
  &:focus,
  &[aria-expanded='true'] {
    z-index: 2;
  }

  // add chevron expand/collapse indicator
  &--with-indicator {
    will-change: padding-right;
    transition: padding-right 0.1s ease-out;
  }
  .org-picker-node-icon {
    transition: opacity 0.05s ease-out, transform 0.2s ease-out;
    will-change: opacity, transform;
    z-index: 0;
    margin: 0 0 0 theme('spacing.2');
    &:last-child {
      margin: 0 0 0 theme('spacing.2');
    }
  }

  .icon-open {
    transform: rotate(-180deg);
  }

  // the actual text in the node
  &-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@include respond-min(medium) {
  .org-picker-node {
    max-width: fit-content;
    width: auto;
    &--allow-shrink {
      flex: 1 1 8ch;
      min-width: 6ch;
    }
    // left border
    &::before {
      content: '';
      border-left: to-rem(1px) solid theme('colors.slate.DEFAULT');
      display: block;
      position: absolute;
      top: 0;
      left: to-rem(-1px);
      bottom: 0;
      width: 0;
      z-index: 0;
    }
    &:first-child::before {
      display: none;
    }
    .org-picker-node-icon {
      position: absolute;
      top: 50%;
      right: theme('spacing.1');
      opacity: 0;
      transform: translateY(-50%);

      // reset styles cascading from .button .icon
      margin: 0;
      &:last-child {
        margin: 0;
      }
    }
    &--with-indicator {
      padding-right: theme('spacing.2');

      &:hover,
      &:focus,
      &[aria-expanded='true'] {
        padding-right: theme('spacing.7');
      }
    }
    .icon-open {
      transform: translateY(-50%) rotate(-180deg);
    }
    &:focus {
      margin-right: theme('spacing.px');
    }
  }
}
</style>
