<script setup>
import { computed } from 'vue'

const props = defineProps({
  prefix: {
    type: String,
    default: 'svg',
  },
  name: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    default: 'currentColor',
  },
  xs: {
    type: Boolean,
    default: false,
  },
  sm: {
    type: Boolean,
    default: false,
  },
  md: {
    type: Boolean,
    default: false,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  xl: {
    type: Boolean,
    default: false,
  },
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
const classes = ['icon']

if (props.xs) {
  classes.push('icon--xs')
}
if (props.sm) {
  classes.push('icon--sm')
}
if (props.md) {
  classes.push('icon--md')
}
if (props.lg) {
  classes.push('icon--lg')
}
if (props.xl) {
  classes.push('icon--xl')
}
</script>

<template>
  <svg
    aria-hidden="true"
    :class="classes"
  >
    <use
      :xlink:href="symbolId"
      :fill="color"
    />
  </svg>
</template>
