<template>
  <td class="whitespace-nowrap">
    <span
      v-if="$slots['info']"
      class="pr-1"
    >
      <slot name="info" />
    </span>
    <slot>{{ val }}</slot>
    <small
      v-if="subText || $slots['sub']"
      class="t-new-line"
    >
      <slot name="sub">{{ subText }}</slot>
    </small>
  </td>
</template>

<script>
export default {
  props: ['decimals', 'modelValue', 'sub'],
  computed: {
    val() {
      let val = parseFloat(this.modelValue, 10)
      if (val != null && val >= 0) {
        val = parseFloat(val).toFixed(this.decimals || 2)
      } else {
        val = '-'
      }
      return val
    },
    subText() {
      return this.val !== '-' && this.sub ? this.sub : ''
    },
  },
}
</script>

<style></style>
