export default function mapMenuSettings(json) {
  return {
    languages: json.data.languages.map((language) => ({
      name: language.name,
      code: language.code,
    })),
    currencies: json.data.currencies.map((currency) => ({
      name: currency.name,
      country: currency.country,
      code: currency.code,
      displayName: `${currency.code} - ${currency.country} ${currency.name}`,
    })),
  }
}
