<template>
  <collapsible-section
    data-test-daily-reports-section
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.subscribers.dailyReports.title') }}
    </template>

    <template #header-side="{ isOpen }">
      <div
        v-if="isOpen"
        class="flex flex-right flex-expand"
      >
        {{ $t('organisations.subscribers.dailyReports.subTitle') }}
      </div>
      <template v-else>
        <Loading-Spinner
          v-if="loadingStatus === 'LOADING'"
          class="ml-2"
        />
        <filter-chip
          v-else
          :disabled="!storeConfigs.enabled"
        >
          {{
            storeConfigs.enabled
              ? $t('organisations.configurations.dailyReports.sendingActiveStatus')
              : $t('organisations.configurations.dailyReports.sendingInactiveStatus')
          }}
        </filter-chip>
      </template>
    </template>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <form
        action="/"
        @submit.prevent="saveChanges"
      >
        <div class="grid-layout grid--half">
          <div class="grid-unit">
            <!-- Sending -->
            <div class="formGroup p-4 configSendingWrapper">
              <div class="customControl customControl--checkbox">
                <input
                  id="config-field-daily-enabled"
                  v-model="v$.buffer.enabled.$model"
                  data-test-config-field-daily-enabled
                  type="checkbox"
                  class="customControl-input"
                />
                <label
                  class="customControl-label"
                  for="config-field-daily-enabled"
                >
                  <span class="d-block font-bold">
                    {{ $t('organisations.configurations.dailyReports.sendingLabel') }}
                  </span>
                  <span class="text-sm">
                    {{
                      $t(
                        `organisations.configurations.dailyReports.sendingLabelSubtitle.${
                          buffer.enabled ? 'enabled' : 'disabled'
                        }`
                      )
                    }}
                  </span>
                </label>
              </div>
            </div>

            <!-- Start date -->
            <ValidationGroup
              v-slot="{ hasAnyErrors }"
              :validator="v$.buffer.startSending"
              class="formGroup d-inline-block"
            >
              <label
                class="font-bold"
                for="config-field-daily-starting-date"
              >
                {{ $t('organisations.configurations.dailyReports.sendingDateLabel') }}
              </label>
              <input
                id="config-field-daily-starting-date"
                v-model="v$.buffer.startSending.$model"
                data-test-config-field-daily-starting-date
                type="date"
                :class="['formControl', { 'is-invalid': hasAnyErrors }]"
              />
            </ValidationGroup>

            <!-- Focus -->
            <fieldset class="formGroup flex gap-4 flex-wrap">
              <legend class="label font-bold flex flex-middle w-full">
                {{ $t('organisations.configurations.focusLabel') }}
                <SvgIcon
                  v-tooltip="{
                    theme: 'tooltip-neutral',
                    content: $t('organisations.configurations.focusLabelDescription'),
                    placement: 'top',
                  }"
                  class="text-slate ml-2"
                  name="24-ic-info"
                ></SvgIcon>
              </legend>
              <div
                v-for="{ value, labelKey } in reportFocusOptions"
                :key="value"
                class="customControl customControl--radio"
              >
                <input
                  v-bind="{ [`data-test-config-field-daily-focus-${value}`]: '' }"
                  :id="`config-field-daily-focus-${value}`"
                  v-model="v$.buffer.focus.$model"
                  type="radio"
                  name="focus-daily"
                  class="customControl-input"
                  :value="value"
                />
                <label
                  :for="`config-field-daily-focus-${value}`"
                  class="customControl-label"
                >
                  {{ $t(labelKey) }}
                </label>
              </div>
            </fieldset>

            <!-- Time Period -->
            <fieldset class="formGroup flex flex-wrap gap-4">
              <legend class="label font-bold flex flex-middle w-full">
                {{ $t('organisations.configurations.dailyReports.timePeriodLabel') }}
                <SvgIcon
                  v-tooltip="{
                    theme: 'tooltip-neutral',
                    content: $t('organisations.configurations.dailyReports.timePeriodLabelDesc'),
                    placement: 'top',
                  }"
                  class="text-slate ml-2"
                  name="24-ic-info"
                >
                </SvgIcon>
              </legend>
              <div
                v-for="{ value, labelKey } in reportTimePeriodOptions"
                :key="value"
                class="customControl customControl--radio"
              >
                <input
                  v-bind="{ [`data-test-config-field-daily-time-period-${value}`]: '' }"
                  :id="`config-field-daily-time-period-${value}`"
                  v-model="v$.buffer.timePeriod.$model"
                  type="radio"
                  name="time-period"
                  class="customControl-input"
                  :value="value"
                />
                <label
                  :for="`config-field-daily-time-period-${value}`"
                  class="customControl-label"
                >
                  {{ $t(labelKey) }}
                </label>
              </div>
            </fieldset>

            <!-- Report Settings -->
            <div
              v-for="{ key, labelKey, labelSubtitleKey } in reportSettings"
              :key="key"
              class="formGroup customControl customControl--checkbox"
            >
              <input
                v-bind="{ [`data-test-config-field-daily-${key}`]: '' }"
                :id="`config-field-daily-${key}`"
                v-model="v$.buffer[key].$model"
                type="checkbox"
                class="customControl-input"
              />
              <label
                class="customControl-label"
                :for="`config-field-daily-${key}`"
              >
                <span class="d-block font-bold">
                  {{ $t(labelKey) }}
                </span>
                <span class="text-sm">
                  {{ $t(labelSubtitleKey) }}
                </span>
              </label>
            </div>
          </div>

          <!-- Report Sections -->
          <div class="grid-unit">
            <fieldset>
              <legend class="mb-8 font-bold">
                {{ $t('organisations.configurations.reportSectionsLegend') }}
              </legend>

              <visibility-toggle
                v-for="{ key, labelKey, labelSubtitleKey } in reportSections"
                :id="`config-field-daily-${key}`"
                :key="key"
                v-model="v$.buffer[key].$model"
                class="formGroup"
              >
                <template #default>
                  <span class="flex-column">
                    <span :class="[v$.buffer[key].$model && 'font-bold']">
                      {{ $t(labelKey) }}
                    </span>
                    <span class="text-sm">
                      {{ $t(labelSubtitleKey) }}
                    </span>
                  </span>
                </template>
              </visibility-toggle>
            </fieldset>
          </div>
        </div>

        <!-- Buttons Bar -->
        <div class="buttonGroup flex-between">
          <ButtonSave
            data-test-configs-submit-btn
            :disabled="!hasChanges || v$.$anyError || savingStatus === 'SAVING'"
            :is-saving="savingStatus === 'SAVING'"
          >
            {{ $t('actions.saveChanges') }}
          </ButtonSave>

          <router-link
            class="button button--link"
            :to="{
              name: 'hierarchy-node-reporting-subscribers',
              query: { ...$route.query, openItems: 'SUBSCRIBERS-DAILY' },
            }"
          >
            <SvgIcon name="24-ic-users" />
            <span>{{ $t('organisations.configurations.subscribersButton') }}</span>
          </router-link>
        </div>
      </form>
    </loaded-content>
  </collapsible-section>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import { isEqual } from 'lodash'
import { useToast } from 'vue-toastification'

import VisibilityToggle from '@/components/VisibilityToggle.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import FilterChip from '@/components/FilterChip.vue'
import ButtonSave from '@/components/ButtonSave.vue'

function Model(overwrites) {
  return {
    actionPlan: false,
    comparisonPoints: false,
    excludeCustom1: false,
    excludeCustom2: false,
    custom3: false,
    customBreakdown: false,
    enabled: false,
    events: false,
    focus: null,
    foodGroupBreakdown: false,
    help: false,
    hideCosts: false,
    otherProductionAreas: false,
    planYourDay: false,
    startSending: null,
    timePeriod: null,
    topAreas: false,
    topItems: false,
    uncategorised: false,
    vision: false,
    ...overwrites,
  }
}

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    VisibilityToggle,
    ValidationGroup,
    FilterChip,
    ButtonSave,
  },
  props: {
    locationId: String,
    preOpen: Boolean,
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
      buffer: {},
    }
  },
  computed: {
    storeConfigs() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId).dailyReportsConfigs
    },
    hasChanges() {
      return !isEqual(this.storeConfigs, this.buffer)
    },
    reportSettings() {
      return [
        {
          key: 'comparisonPoints',
          labelKey: 'organisations.configurations.comparisonPointsLabel',
          labelSubtitleKey: 'organisations.configurations.comparisonPointsLabelSubtitle',
        },
        {
          key: 'events',
          labelKey: 'organisations.configurations.eventsLabel',
          labelSubtitleKey: 'organisations.configurations.eventsLabelSubtitle',
        },
        {
          key: 'hideCosts',
          labelKey: 'organisations.configurations.hideCostsLabel',
          labelSubtitleKey: 'organisations.configurations.hideCostsLabelSubtitle',
        },
        {
          key: 'customBreakdown',
          labelKey: 'organisations.configurations.customBreakdownLabel',
          labelSubtitleKey: 'organisations.configurations.customBreakdownLabelSubtitle',
        },
        {
          key: 'excludeCustom2',
          labelKey: 'organisations.configurations.dailyReports.excludeCustom2Label',
          labelSubtitleKey: 'organisations.configurations.dailyReports.excludeCustom2LabelSubtitle',
        },
        {
          key: 'excludeCustom1',
          labelKey: 'organisations.configurations.dailyReports.excludeCustom1Label',
          labelSubtitleKey: 'organisations.configurations.dailyReports.excludeCustom1LabelSubtitle',
        },
      ]
    },
    reportSections() {
      return [
        {
          key: 'planYourDay',
          labelKey: 'organisations.configurations.dailyReports.planDayLabel',
          labelSubtitleKey: 'organisations.configurations.dailyReports.planDayLabelSubtitle',
        },
        {
          key: 'actionPlan',
          labelKey: 'organisations.configurations.actionPlanLabel',
          labelSubtitleKey: 'organisations.configurations.actionPlanLabelSubtitle',
        },
        {
          key: 'vision',
          labelKey: 'organisations.configurations.visionLabel',
          labelSubtitleKey: 'organisations.configurations.visionLabelSubtitle',
        },
        {
          key: 'topAreas',
          labelKey: 'organisations.configurations.topAreasLabel',
          labelSubtitleKey: 'organisations.configurations.topAreasLabelSubtitle',
        },
        {
          key: 'foodGroupBreakdown',
          labelKey: 'organisations.configurations.foodGroupBreakdownLabel',
          labelSubtitleKey: 'organisations.configurations.foodGroupBreakdownLabelSubtitle',
        },
        {
          key: 'topItems',
          labelKey: 'organisations.configurations.topItemsLabel',
          labelSubtitleKey: 'organisations.configurations.topItemsLabelSubtitle',
        },
        {
          key: 'otherProductionAreas',
          labelKey: 'organisations.configurations.otherProductionAreasLabel',
          labelSubtitleKey: 'organisations.configurations.otherProductionAreasLabelSubtitle',
        },
        {
          key: 'uncategorised',
          labelKey: 'titles.uncategorised',
          labelSubtitleKey: 'organisations.configurations.uncategorisedLabelSubtitle',
        },
        {
          key: 'help',
          labelKey: 'organisations.configurations.helpLabel',
          labelSubtitleKey: 'organisations.configurations.helpLabelSubtitle',
        },
        {
          key: 'custom3',
          labelKey: 'organisations.configurations.dailyReports.custom3Label',
          labelSubtitleKey: 'organisations.configurations.dailyReports.custom3LabelSubtitle',
        },
      ]
    },
    reportFocusOptions() {
      return [
        {
          value: 'WEIGHT',
          labelKey: 'titles.weight',
        },
        {
          value: 'VALUE',
          labelKey: 'titles.value',
        },
        {
          value: 'MIXED',
          labelKey: 'titles.mixed',
        },
      ]
    },
    reportTimePeriodOptions() {
      return [
        {
          value: 'HOURLY',
          labelKey: 'organisations.configurations.dailyReports.timePeriodOptHourly',
        },
        {
          value: 'MEAL_TIMES',
          labelKey: 'organisations.configurations.dailyReports.timePeriodOptMealTimes',
        },
      ]
    },
  },
  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeDailyReportsConfigs', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.init()
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },
  validations() {
    return {
      buffer: {
        actionPlan: {},
        comparisonPoints: {},
        excludeCustom1: {},
        excludeCustom2: {},
        custom3: {},
        customBreakdown: {},
        enabled: {},
        events: {},
        focus: {},
        foodGroupBreakdown: {},
        help: {},
        hideCosts: {},
        otherProductionAreas: {},
        planYourDay: {},
        startSending: {
          required,
        },
        timePeriod: {},
        topAreas: {},
        topItems: {},
        uncategorised: {},
        vision: {},
      },
    }
  },
  methods: {
    init() {
      this.buffer = new Model(this.storeConfigs)
    },
    saveChanges() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.savingStatus = 'SAVING'

      const changes = Object.entries(this.buffer).reduce(
        (acc, [key, value]) =>
          Object.assign(acc, this.storeConfigs[key] === this.buffer[key] ? {} : { [key]: value }),
        {}
      )

      this.$store
        .dispatch('hierarchy/nodes/saveNodeDailyReportsConfigs', {
          nodeId: this.locationId,
          payload: changes,
        })
        .then(() => {
          this.v$.buffer.$reset()
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((e) => {
          this.savingStatus = 'ERROR'

          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
        .finally(() => {
          this.init()
        })
    },
  },
}
</script>
<style lang="scss">
.configSendingWrapper {
  border: solid 1px theme ('colors.slate.DEFAULT');
}
</style>
