export default function (system) {
  return {
    id: system.id,
    name: system.configuration?.name,
    type: system.type,
    createdDate: system.created,
    updatedDate: system.updated,
    isAuthorised: system.authorised,
    authorisedDate: system.authorisationDate,
    components: system.components,
  }
}
