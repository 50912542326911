export const ranges = (items) => {
  if (items.length < 1) {
    return null
  }

  let minCovers = 0
  let maxCovers = null
  let minSales = 0
  let maxSales = null

  items.forEach((item) => {
    const { covers, sales } = item

    if (covers !== null) {
      minCovers = Math.min(covers, minCovers)
      maxCovers = maxCovers === null ? covers : Math.max(covers, maxCovers)
    }

    if (sales !== null) {
      minSales = Math.min(sales, minSales)
      maxSales = maxSales === null ? sales : Math.max(sales, maxSales)
    }
  })

  return {
    covers: {
      min: minCovers,
      max: maxCovers,
    },
    sales: {
      min: minSales,
      max: maxSales,
    },
  }
}
