<template>
  <tr data-test-conflict-details-row>
    <td data-test-col-name>
      <router-link
        :to="{
          name: 'food-editor',
          params: {
            foodItemId: foodItemId,
          },
          query: {
            menuId: menuId,
            returnPath: $route.path,
          },
        }"
      >
        {{ foodItem.displayName }}
      </router-link>
    </td>
    <td data-test-col-mapping>
      <router-link
        :to="{
          name: 'food-item-mapping-facets',
          params: {
            foodItemId: foodItemId,
          },
          query: {
            menuId: menuId,
            returnPath: $route.path,
          },
        }"
      >
        {{ foodItem.taxonomy.mapping }}
      </router-link>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['foodItemId', 'menuId'],
  computed: {
    ...mapGetters('menu-management/food-items', ['foodItemById']),
    foodItem() {
      return this.foodItemById(this.foodItemId)
    },
  },
}
</script>

<style></style>
