<script>
import { debounce, cloneDeep } from 'lodash'
import { DEBOUNCE_DELAY } from '@/store/constants'
import { isBlank } from '@/utils/string'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { addSearchMarks } from '@/store/utils/taxonomy'
import { useToast } from 'vue-toastification'

import NodeTerm from '@/components/NodeTerm.vue'

export default {
  components: {
    NodeTerm,
  },
  props: {
    search: Function,
    clear: Function,
    onStart: {
      type: Function,
      default: () => function () {},
    },
    onSelect: {
      type: Function,
      default: () => function () {},
    },
    action: String,
    query: {
      type: Object,
      default: () => ({}),
    },
    includeIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { toast: useToast() }
  },
  data() {
    return {
      isRunning: false,
      token: null,
      source: null,
      results: [],
      matches: [],
      isOpen: false,
      lastSuccessful: false,
    }
  },
  computed: {
    isBlank() {
      return isBlank(this.token)
    },
    ...mapGetters('taxonomy', ['getDetailLevelById']),
  },
  watch: {
    query() {
      this.runTask()
    },
  },
  methods: {
    runTask: debounce(async function () {
      if (this.source !== null) {
        this.source.cancel()
      }

      this.onStart()

      if (this.isBlank) {
        this.clearSearch()
        return
      }

      this.isRunning = true
      this.lastSuccessful = true
      this.source = axios.CancelToken.source()

      await this.performSearch()

      this.isRunning = false
      this.source = null
    }, DEBOUNCE_DELAY),

    async performSearch() {
      try {
        const { data } = await this.$store.dispatch(this.action, {
          query: {
            ...this.query,
            search: this.token,
            size: 100,
          },
          options: { cancelToken: this.source.token },
        })

        this.results = data
        this.matches = data.map((branch) => {
          let match = cloneDeep(branch[branch.length - 1])
          return addSearchMarks(match)
        })
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.lastSuccessful = false
          this.toast.error(this.$t('toast.error.getData'))
        }
      }
    },

    selectMatch(i) {
      const branch = this.results[i]
      this.onSelect(branch)
    },

    clearSearch() {
      this.token = null
      this.$refs.search.value = ''
      this.results = []
      this.matches = []
      this.lastSuccessful = false
    },

    open() {
      this.isOpen = true
    },

    close() {
      this.isOpen = false
    },
  },
}
</script>

<template>
  <div
    v-click-outside="close"
    class="SearchBar-container"
  >
    <div
      class="SearchBar"
      @click="open()"
    >
      <div class="SearchBar-input formGroup--search">
        <SvgIcon
          v-if="includeIcon"
          name="24-ic-search"
          xl
          class="p-3"
        />
        <input
          ref="search"
          type="text"
          class="formControl"
          :placeholder="$t('search.placeholder')"
          @input="
            ($event) => {
              token = $event.target.value
              open()
              runTask()
            }
          "
        />
        <div
          v-if="results.length && !isBlank && !isRunning"
          class="SearchBar-count"
        >
          {{ $t('search.results', { count: results.length }) }}
        </div>
      </div>
      <button
        v-if="!isBlank"
        class="SearchBar-clear button button--icon button--round button--primary"
        @click="clearSearch"
      >
        <SvgIcon name="24-ic-clear" />
      </button>
    </div>

    <div
      v-if="isOpen && lastSuccessful"
      class="SearchBar-results shadow-sm"
    >
      <Loading-Spinner
        v-if="isRunning"
        class="spinner--md mx-auto"
      />
      <template v-else>
        <ul v-if="results.length">
          <template
            v-for="(record, i) in matches"
            :key="record.id"
          >
            <li class="ResultNode">
              <button
                class="ResultNode-item"
                @click.prevent="
                  () => {
                    selectMatch(i)
                    close()
                  }
                "
              >
                <NodeTerm
                  :record="record"
                  :detail-level="getDetailLevelById(record.detailLevel)"
                />
              </button>

              <slot
                name="result"
                :node-id="record.id"
              />
            </li>
          </template>
        </ul>
        <p
          v-else
          class="px-2 py-2"
        >
          {{ $t('messages.noResults') }}
        </p>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.SearchBar-container {
  position: relative;
  flex: 1 1 auto;
}

.SearchBar {
  display: flex;
  align-items: center;
}

.SearchBar-input {
  flex: 1 1 auto;
}

.SearchBar-count {
  padding: 0 theme('spacing.1');
  flex: 0 1 auto;
  font-size: theme('fontSize.sm');
  color: theme('colors.grey.400');
  white-space: nowrap;
}

.SearchBar-clear {
  margin-left: theme('spacing.2');
  flex: 0 0 auto;
}

.SearchBar-results {
  @apply shadow;
  background: theme('colors.white');
  border: 2px solid theme('colors.carrot.DEFAULT');

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  // needs rework
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;

  max-height: calc((theme('spacing.5') + theme('spacing[2.5]')) * 20 + theme('spacing.1'));
  overflow-y: auto;

  .spinner {
    margin-top: theme('spacing.4');
    margin-bottom: theme('spacing.4');
  }
}

.ResultNode {
  position: relative;
  display: flex;
  align-items: flex-start;

  &:hover {
    background: theme('colors.grey.100');
  }
}

.ResultNode-item {
  padding: theme('spacing.1') theme('spacing.2') theme('spacing.1') theme('spacing.10');
  background: none;
  border: 0;
  display: block;
  width: 100%;
  text-align: left;

  color: theme('colors.acai.DEFAULT');

  overflow: hidden;

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv');
  }
}

.ResultNode-controls {
  padding: theme('spacing.1');
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;
  display: none;

  .ResultNode:hover &,
  &.is-active {
    display: block;
  }

  .button,
  > .iconCircle {
    margin: 0;
    float: left;
  }

  .show-on-hover {
    display: none;
  }

  .ResultNode:hover & .hide-on-hover {
    display: none;
  }

  .ResultNode:hover & .show-on-hover {
    // .button
    display: inline-flex;
  }
}
</style>
