<script setup>
/**
 * Tabs that work with links, as navigation. They are styled as primary tabs but that can be changed as long as the same behaviour is required
 *
 * It has a slot where you can add all of your router-links.
 * It takes those elements, divides them based on how many they fit in the container and displays a more button to access the ones that didn't.
 * It updates the separation whenever the screen resizes or the route changes
 *
 * Children need to be html `<li>` elements that have a `<router-link class="tab-item">` inside. The content inside each router-link can be whatever.
 */

import { ref } from 'vue'
import { useResponsiveTabs } from '@/composables/useResponsiveTabs'

defineProps({
  id: {
    type: String,
    default: 'tab-navigation',
  },
})
// this is a ref to the slot wrapper, it's a <ul> that should contain all of the links
const list = ref(null)
// this is a ref to the more button element in the template
const moreToggle = ref(null)
// this is a ref to the secondary list element in the template
const moreList = ref(null)

const {
  primaryList,
  secondaryList,
  handleResize,
  openSecondaryList,
  closeSecondaryList,
  isMoreButtonActive,
  isMoreOpen,
} = useResponsiveTabs({ moreToggle, moreList, list })
</script>
<template>
  <nav class="tabs-lvl-2">
    <resize-observer @notify="handleResize" />
    <ul
      class="tabs-list"
      ref="list"
    >
      <primaryList />
      <li
        class="relative"
        v-click-outside="() => closeSecondaryList()"
      >
        <button
          class="tab tab-more"
          :class="{ 'tab--selected': isMoreButtonActive }"
          ref="moreToggle"
          @click="openSecondaryList"
          :aria-controls="`${id}-more`"
          :aria-expanded="isMoreOpen"
        >
          <span>{{ $t('actions.more') }}</span>
          <SvgIcon
            sm
            name="24-ic-arrow-down"
          ></SvgIcon>
        </button>
        <ul
          :id="`${id}-more`"
          class="tab-more-list"
          v-show="isMoreOpen"
          ref="moreList"
        >
          <secondaryList />
        </ul>
      </li>
    </ul>
  </nav>
</template>
