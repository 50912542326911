import axios from 'axios'
import queryString from 'query-string'
import { store } from './index'
import router from '@/router'
import { isPlainObject } from 'lodash'

const instance = axios.create({
  baseURL: `${import.meta.env.VUE_APP_API_HOST}${import.meta.env.VUE_APP_API_NAMESPACE}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.response.use(undefined, function (err) {
  if (import.meta.env.DEV) {
    console.error('intercepted', err)
  }

  if (axios.isCancel(err)) {
    return Promise.reject(err)
  }

  return new Promise(function () {
    // add exception for the sites call: even if it returns 403, the user should still see the homepage dashboard
    const isSitesRequest = err.config.url.indexOf('/sites/allowed') >= 0
    const overwriteAccess = [isSitesRequest].filter((condition) => !!condition)
    if (err.response.status === 401) {
      store.dispatch('auth/logout')
      // .then(() => {
      // app.$router.push('/login')
      // })
    }
    if (err.response.status === 403 && !overwriteAccess.length) {
      router.replace('/access-denied')
      // stop returning the error because it's unnecessary to continue with more information
      // the page should redirect and that's all the user should know about it
      // the rest of the info is in the network panel of the dev tools
      return
    }
    throw err.response
  })
})

export default instance

export function spitURL(url, query) {
  const { filter, ...rest } = query

  let params = {}

  let parentId = ''

  if (filter) {
    Object.keys(filter).forEach((k) => {
      if (filter[k] !== undefined && filter[k] !== null) {
        // if one of the filters is an object, we need to transform it to our agreed format:
        // ex: `filter: { configuration: { name: 'test name'}}` needs to become `filter[configuration]=name:test name`
        if (isPlainObject(filter[k])) {
          const queryItems = []
          Object.keys(filter[k]).forEach((k2) => {
            queryItems.push(`${k2}:${filter[k][k2]}`)
          })
          params[`filter[${k}]`] = queryItems
        } else {
          params[`filter[${k}]`] = filter[k]
        }
      }
    })
  }

  Object.keys(rest).forEach((k) => {
    if (rest[k] !== undefined && rest[k] !== null) {
      params[k] = rest[k]
    }
  })

  let queryParams = queryString.stringify(params)

  if (queryParams.length) {
    return `${url}${parentId}?${queryParams}`
  }
  return url
}

// Serialize query params and add to url
export function buildURL(url, query) {
  let params = {}
  const { filters, search, sortBy, pageNumber, pageSize, facetGroupCode } = query

  if (filters) {
    params = formatFilters(filters)
  }

  if (sortBy) {
    params.sortBy = sortBy
  }

  if (search) {
    params.search = search
  }

  if (pageNumber) {
    params.page = pageNumber
  }

  if (pageSize) {
    params.size = pageSize
  }

  ;['offset', 'limit'].forEach((key) => {
    if (query[key] !== undefined) {
      params[key] = query[key]
    }
  })

  if (facetGroupCode) {
    params.facetGroupCode = facetGroupCode
  }

  let queryParams = queryString.stringify(params)

  if (queryParams.length) {
    return `${url}?${queryParams}`
  }
  return url
}

export function formatFilters(filters = {}) {
  return Object.keys(filters).reduce((params, k) => {
    params[`filter[${k}]`] = filters[k]
    return params
  }, {})
}
