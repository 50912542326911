<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Breadcrumbs
      class="mx-4 ps-4"
      :history="[{ name: 'menus', title: $t('navigation.menus') }]"
      :current="$t('navigation.createMenu')"
    />
    <Hero>{{ $t('titles.createMenu') }} </Hero>

    <PageSection>
      <SectionHeader>
        <template #section-title>{{ $t('menus.translations.title') }}</template>
      </SectionHeader>

      <FiltersGroup
        behaviour="radio"
        :filters="availableFilters"
        :selected-id="selectedFilter"
        :save-to-url="true"
        class="mb-4"
      />

      <div
        v-if="menuItemsLoading"
        class="m-8 flex flex-center"
      >
        <Loading-Spinner class="spinner--lg" />>
      </div>

      <form
        v-else
        action="/"
        @submit.prevent="onSubmit"
        @reset.prevent="resetChanges"
      >
        <SortableTable
          class="box-table"
          :headers="headers"
        >
          <tbody>
            <tr
              v-for="menuItem in buffer"
              :key="menuItem.id"
            >
              <td>{{ menuItem.type }}</td>
              <td>{{ menuItem.localName }}</td>
              <td>{{ menuItem.defaultEnglishName }}</td>
              <td class="td-input">
                <label
                  class="sr-only"
                  :for="menuItem.id"
                >
                  {{ $t('menus.translations.label') }} {{ menuItem.localName }}
                </label>
                <input
                  :id="menuItem.id"
                  v-model="menuItem.englishName"
                  v-test="'translate-input'"
                  type="text"
                  autocomplete="off"
                  class="formControl"
                  @input="setChangesInProgress"
                  @change="translationChanged(menuItem, $event.target.value)"
                  @keydown.enter.prevent
                />
              </td>
            </tr>
          </tbody>
        </SortableTable>

        <Pagination
          :total="availableItems"
          :size="pageSize"
        />

        <div
          v-if="changesInProgress"
          v-test="'form-actions'"
          class="buttonGroup flex-center my-8"
        >
          <button
            v-test="'reset-button'"
            type="reset"
            class="button button--secondary"
          >
            {{ $t('menus.translations.actions.reset') }}
          </button>
          <button
            v-test="'save-button'"
            type="submit"
            class="button button--primary"
          >
            {{ $t('menus.translations.actions.save') }}
          </button>
        </div>
        <div
          v-else
          v-test="'skip-translations'"
          class="buttonGroup flex-center my-8"
        >
          <router-link
            v-test="'continue-button'"
            :to="{ name: 'menus' }"
            class="button button--primary"
          >
            {{ $t('menus.translations.actions.continue') }}
          </router-link>
        </div>
      </form>
    </PageSection>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import SortableTable from '@/components/SortableTable/SortableTable.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import FiltersGroup from '@/components/FiltersGroup.vue'
import { cloneDeep } from 'lodash'
import { PAGESIZE_MENUS } from '@/store/constants'
import { mapState, mapActions } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LayoutDefault,
    Hero,
    Breadcrumbs,
    SortableTable,
    Pagination,
    FiltersGroup,
  },
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      pageSize: PAGESIZE_MENUS,
      buffer: [],
    }
  },
  computed: {
    ...mapState('menu-management/translations', [
      'menuItems',
      'availableItems',
      'menuItemsLoading',
      'newTranslations',
      'previousState',
      'changesSaved',
      'changesInProgress',
    ]),
    headers() {
      return [
        { id: 'th1', title: this.$t('menus.translations.headers.menuItem') },
        { id: 'th2', title: this.$t('menus.translations.headers.localName') },
        { id: 'th3', title: this.$t('menus.translations.headers.englishTranslation') },
        { id: 'th4', title: this.$t('menus.translations.headers.correctedTranslation') },
      ]
    },
    availableFilters() {
      return [
        { id: 'Food Item', title: this.$t('menus.translations.filters.foodItems') },
        { id: 'Stage', title: this.$t('menus.translations.filters.stages') },
        { id: 'Food Group', title: this.$t('menus.translations.filters.foodGroups') },
        { id: 'All', title: this.$t('menus.translations.filters.all') },
      ]
    },
    selectedFilter() {
      return this.$route.query.filteredBy || this.availableFilters[0].id
    },
  },
  created() {
    const pageNumber = this.$route.query.page ? parseInt(this.$route.query.page - 1) : undefined
    this.$store.dispatch('menu-management/translations/setMenuId', this.menuId)
    this.$store
      .dispatch('menu-management/translations/getMenuItems', {
        page: pageNumber,
        filter: { itemType: this.selectedFilter },
      })
      .then(() => {
        this.buffer = cloneDeep(this.menuItems)
        this.restoreProgress()
      })
  },
  methods: {
    ...mapActions('menu-management/translations', ['saveTranslations', 'setChangesInProgress']),
    /**
     * If any translations have been done to some of the menu items,
     * this action loads them into the current set of paginated items
     */
    restoreProgress() {
      this.newTranslations.forEach((translatedItem) => {
        let menuItem = this.buffer.find((menuItem) => menuItem.id === translatedItem.id)
        let translation = translatedItem.englishName
        if (menuItem) {
          menuItem.englishName = translation
        }
      })
    },
    onSubmit() {
      this.saveTranslations()
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    translationChanged(changedItem, translation) {
      let index = this.newTranslations.findIndex((item) => item.id === changedItem.id)
      translation = translation.trim()
      if (index >= 0 && translation) {
        // update existing translation
        this.$store.dispatch('menu-management/translations/editTranslation', { index, translation })
      } else if (index >= 0 && !translation) {
        this.$store.dispatch('menu-management/translations/removeTranslation', index)
      } else if (index < 0) {
        // otherwise add a new translation
        this.$store.dispatch('menu-management/translations/addNewTranslation', {
          id: changedItem.id,
          translation,
        })
      }
    },
    resetChanges() {
      this.$store.dispatch('menu-management/translations/resetTranslations')
      // empty all input values
      this.buffer = cloneDeep(this.menuItems)
    },
  },
}
</script>
