<template>
  <collapsible-section
    data-test-sector-properties-collapsible
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.SectorProperties.title') }}
    </template>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <form
        action="/"
        @submit.prevent="saveData"
      >
        <div class="form-layout--x3 mb-8">
          <!-- Sector -->
          <div>
            <label for="reportingNodeSector">{{
              $t('organisations.SectorProperties.labels.sector')
            }}</label>
            <select
              id="reportingNodeSector"
              v-model="v$.buffer.sectorId.$model"
              data-test-sector-props-sector-field
              class="customSelect"
              @change="
                () => {
                  buffer.subSectorId = ''
                }
              "
            >
              <option value>
                {{ $t('actions.none') }}
              </option>
              <option
                v-for="{ value, id } in sectors"
                :key="id"
                :value="id"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <!-- Sub Sector -->
          <div>
            <label for="reportingNodeSubSector">{{
              $t('organisations.SectorProperties.labels.subSector')
            }}</label>
            <select
              id="reportingNodeSubSector"
              v-model="v$.buffer.subSectorId.$model"
              data-test-sector-props-subSector-field
              :disabled="!selectedSectorHasSubsectors || !buffer.sectorId"
              class="customSelect"
            >
              <option value>
                {{ $t('actions.none') }}
              </option>
              <option
                v-for="{ value, id } in subSectors"
                :key="id"
                :value="id"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <!-- Kitchen Operation -->
          <div>
            <label for="reportingNodeKitchenOperation">{{
              $t('organisations.SectorProperties.labels.kitchenOperation')
            }}</label>
            <select
              id="reportingNodeKitchenOperation"
              v-model="v$.buffer.kitchenOperationId.$model"
              data-test-sector-props-kitchenOperation-field
              class="customSelect"
            >
              <option value>
                {{ $t('actions.none') }}
              </option>
              <option
                v-for="{ value, id } in kitchenOperations"
                :key="id"
                :value="id"
              >
                {{ value }}
              </option>
            </select>
          </div>
          <!--Annualised Food Cost -->
          <ValidationGroup
            v-slot="{ hasAnyErrors }"
            :validator="v$.buffer.annualisedFoodCost"
          >
            <label for="reportingNodeFoodCost">{{
              $t('organisations.SectorProperties.labels.foodCost')
            }}</label>
            <input
              id="reportingNodeFoodCost"
              v-model="v$.buffer.annualisedFoodCost.$model"
              data-test-sector-props-annualisedFoodCost-field
              type="number"
              step="any"
              :class="['formControl', { 'is-invalid': hasAnyErrors }]"
              :placeholder="$t('organisations.SectorProperties.placeholders.foodCost')"
            />
          </ValidationGroup>
          <!-- % Food Prepared in Advance -->
          <div>
            <label for="reportingNodeFoodPreparedInAdvance">{{
              $t('organisations.SectorProperties.labels.foodPercentage')
            }}</label>
            <select
              id="reportingNodeFoodPreparedInAdvance"
              v-model="v$.buffer.preparedFoodPercentageId.$model"
              data-test-sector-props-foodPercentage-field
              class="customSelect"
            >
              <option value>
                {{ $t('actions.none') }}
              </option>
              <option
                v-for="{ value, id } in preparedFoodPercentages"
                :key="id"
                :value="id"
              >
                {{ value }}
              </option>
            </select>
          </div>
        </div>
        <ButtonSave
          data-test-sector-props-submit-btn
          :disabled="!v$.$anyDirty || v$.$anyError || savingStatus === 'SAVING'"
          :is-saving="savingStatus === 'SAVING'"
        >
          {{ $t('actions.saveChanges') }}
        </ButtonSave>
      </form>
    </loaded-content>
  </collapsible-section>
</template>
<script>
import { decimal } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import CollapsibleSection from '@/components/CollapsibleSection.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import { useToast } from 'vue-toastification'
import ButtonSave from '@/components/ButtonSave.vue'

const isBlank = (val) => /\S/.test(val) === false || val === null
const positive = (value) => (!isBlank(value) ? value > 0 : true)

function Model(overrides) {
  return {
    sectorId: '',
    subSectorId: '',
    kitchenOperationId: '',
    annualisedFoodCost: '',
    preparedFoodPercentageId: '',
    ...overrides,
  }
}

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    ValidationGroup,
    ButtonSave,
  },
  props: {
    locationId: String,
    preOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      buffer: {},
      savingStatus: 'IDLE',
      loadingStatus: 'IDLE',
    }
  },
  computed: {
    nodeSectorProperties() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId).sectorProperties
    },
    sectorProperties() {
      return this.$store.getters['hierarchy/nodes/sectorProperties']
    },
    sectors() {
      return this.sectorProperties.sectors
    },
    subSectors() {
      if (this.buffer.sectorId) {
        return this.sectors.find((sector) => sector.id === this.buffer.sectorId).subSectors
      }
      return []
    },
    kitchenOperations() {
      return this.sectorProperties.kitchenOperations
    },
    preparedFoodPercentages() {
      return this.sectorProperties.preparedFoodPercentages
    },
    selectedSectorHasSubsectors() {
      return !!this.subSectors.length
    },
  },
  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeSectorProperties', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.buffer = new Model(this.nodeSectorProperties)
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },
  validations() {
    return {
      buffer: {
        sectorId: {},
        subSectorId: {},
        kitchenOperationId: {},
        annualisedFoodCost: {
          decimal,
          positive,
        },
        preparedFoodPercentageId: {},
      },
    }
  },
  methods: {
    saveData() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.savingStatus = 'SAVING'

      this.$store
        .dispatch('hierarchy/nodes/saveNodeSectorPropertiesChanges', {
          nodeId: this.locationId,
          payload: {
            ...this.buffer,
            annualisedFoodCost: parseInt(this.buffer.annualisedFoodCost, 10),
          },
        })
        .then(() => {
          this.v$.buffer.$reset()
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((e) => {
          this.savingStatus = 'ERROR'

          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
    },
  },
}
</script>
