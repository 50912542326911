import processQualifiedName from '@/store/utils/processQualifiedName'
export default function (site) {
  return {
    id: site.id,
    qualifiedName: site.qualifiedName,
    wasteEventsTotal: site.wasteEventsTotal,
    baselineStart: site.baselineStart,
    baselineEnd: site.baselineEnd,
    archived: site.archived,
    timeZone: site.timeZone,
    menuId: site.menuId,
    ...processQualifiedName(site.qualifiedName),
  }
}
