/**
 * Transform the permissions structure coming from the backend to our internal abilities format that can be used by CASL
 *
 * incoming permissions look like:
 * ```
 * {
 *   'covers-sales': 'READ_WRITE_DELETE',
 *   'waste-log': 'READ',
 *   ...
 * }
 * ```
 *
 * output structure looks like:
 * ```
 * [
 *   {subject: 'covers-sales', action: 'read'},
 *   {subject: 'covers-sales', action: 'write'},
 *   {subject: 'covers-sales', action: 'delete'},
 *   {subject: 'waste-log', action: 'read'},
 *   ...
 * ]
 * ```
 * @param {Object} permissions key/value pairs of actions a user can take on certain "subjects"
 * @returns {Array} list of subject/action pairs
 */
export default function mapPermissionsToCASLAbilities(permissions) {
  const subjects = Object.keys(permissions)
  const actions = Object.values(permissions)
  return subjects.flatMap((subject, index) => {
    // Split the permission keys (coming from backend services) into a list so it can be further processed
    const subjectActions = actions[index]
    return subjectActions.map((action) => ({ subject, action }))
  })
}
