export default function mapWasteTarget(data) {
  return {
    id: data.id,
    effectiveFromDate: data.effectiveFromDate,
    value: data.value,
    type: data.type,
    targetEndDate: data.targetEndDate,
    description: data.description,
    sourceLocation: {
      id: data.sourceLocation.id,
      level: data.sourceLocation.level,
      name: data.sourceLocation.name,
      qualifiedName: data.sourceLocation.qualifiedName,
    },
  }
}
