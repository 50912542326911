<template>
  <LayoutDefault>
    <template #siteNav>
      <SiteSelector @loaded="handleSitesLoaded" />
    </template>

    <Hero>{{ $t('titles.coversAndSales') }}</Hero>
    <LoadedContent
      :is-loading="isLoading"
      :has-data="!!selectedSite"
      :has-error="false"
    >
      <template v-if="selectedSite">
        <PageSection>
          <CSCurrentWeek>
            <template #default="{ isLoading }">
              <SectionHeader>
                <template #section-title>
                  {{ $t('titles.last7Days') }}
                  <Loading-Spinner
                    v-if="isLoading"
                    class="inline-flex spinner--md"
                  />
                </template>
              </SectionHeader>

              <CurrentWeek>
                <template #day="{ day }">
                  <CSCard
                    :day="day"
                    :item="getItemByDate(day.id)"
                    :open="true"
                    :currency="currency"
                  />
                </template>
              </CurrentWeek>
            </template>
          </CSCurrentWeek>
        </PageSection>

        <PageSection>
          <CSCurrentMonth>
            <template #default="{ ranges, isLoading, loadPrev, loadNext, loadToday }">
              <SectionHeader>
                <template #section-title>
                  {{ $t('titles.calendar') }}
                  <Loading-Spinner
                    v-if="isLoading"
                    class="spinner--md inline-flex"
                  />
                </template>
                <template #section-actions>
                  <ul
                    v-if="!isLoading"
                    class="legend"
                  >
                    <span class="legend-item before:bg-carrot">
                      {{ $t('titles.covers') }}
                    </span>
                    <span class="legend-item before:bg-aqua">
                      {{ $t('titles.sales') }}
                    </span>
                  </ul>
                </template>
                <!-- <div v-else class="CSLegend">
                <span class="CSLegend-covers">
                  {{ $t('titles.covers') }}
                </span>
                <span class="CSLegend-sales">
                  {{ $t('titles.sales') }}
                </span>
              </div> -->
              </SectionHeader>

              <Calendar>
                <template #nav="{ center, prev, next, reset }">
                  <div class="monthNav">
                    <button
                      class="button button--sm button--primary mr-2"
                      @click.prevent="
                        () => {
                          reset()
                          loadToday()
                        }
                      "
                    >
                      <SvgIcon name="24-ic-timer" />
                      <span>{{ $t('titles.today') }}</span>
                    </button>

                    <button
                      class="button button--icon"
                      @click.prevent="
                        () => {
                          prev()
                          loadPrev()
                        }
                      "
                    >
                      <SvgIcon name="24-ic-arrow-prev" />
                    </button>

                    <button
                      class="button button--icon"
                      @click.prevent="
                        () => {
                          next()
                          loadNext()
                        }
                      "
                    >
                      <SvgIcon name="24-ic-arrow-next" />
                    </button>

                    <span class="monthNav-label">{{ formatMonth(center) }}</span>
                  </div>
                </template>
                <template #day="{ day }">
                  <CSCard
                    v-if="day.isCurrentMonth"
                    :day="day"
                    :item="getItemByDate(day.id)"
                    :ranges="ranges"
                    :currency="currency"
                  />
                  <CSCard
                    v-else
                    :day="day"
                  />
                </template>
              </Calendar>
            </template>
          </CSCurrentMonth>
        </PageSection>
      </template>
      <template #no-results>
        <NoSiteSelected />
      </template>
    </LoadedContent>
  </LayoutDefault>
</template>

<script setup>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Calendar from '@/components/Calendar/Calendar.vue'
import CurrentWeek from '@/components/CurrentWeek/CurrentWeek.vue'
import CSCurrentMonth from '@/components/CSCurrentMonth/CSCurrentMonth.vue'
import CSCurrentWeek from '@/components/CSCurrentWeek/CSCurrentWeek.vue'
import CSCard from '@/components/CSCard/CSCard.vue'
import SiteSelector from '@/components/SiteSelector/SiteSelector.vue'
import NoSiteSelected from '@/components/NoSiteSelected/NoSiteSelected.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import moment from 'moment'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

const { getters } = useStore()

const userId = computed(() => getters['auth/userId'])
const getItemByDate = computed(() => getters['covers-sales/getItemByDate'])
const { selectedSite } = useSelectedSite({ userId: userId.value })
const currency = computed(() => (selectedSite.value ? selectedSite.value.currency : ''))
const isLoading = ref(true)
const formatMonth = (date) => {
  return moment(date).format('MMMM Y')
}
const handleSitesLoaded = () => {
  isLoading.value = false
}
</script>

<style lang="scss">
.weekContainer {
  position: relative;
  margin-top: calc(-1 * theme('spacing.4'));
  margin-right: calc(-1 * theme('spacing.3'));
  margin-bottom: 0;
  margin-left: calc(-1 * theme('spacing.3'));
  padding-top: theme('spacing.6');
  padding-bottom: theme('spacing.2');
  overflow-x: auto;
  overflow-y: visible;
}

.week {
  display: flex;
  align-items: stretch;
}

.week-day {
  flex: 1 0 auto;
  width: to-rem(173px);
  padding: 0 theme('spacing.2');

  display: flex;
  flex-direction: column;

  > .card {
    flex: 1 0 auto;
  }
}

.calendar {
  width: 100%;
}

.calendar-container {
  position: relative;
  overflow-x: auto;
  overflow-y: visible;
  margin: 0 calc(-1 * theme('spacing.3'));
  padding: theme('spacing.7') 0 theme('spacing.2');
}

.calendar-week {
  display: flex;
  align-items: stretch;

  & + & {
    margin-top: theme('spacing.6');
  }
}

.calendar-day {
  flex: 1 0 auto;
  width: to-rem(173px);
  padding: 0 theme('spacing.2');

  display: flex;
  flex-direction: column;

  > .card {
    flex: 1 0 auto;
  }
}

.calendar-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: calc(-1 * theme('spacing.2'));
}

.monthNav {
  display: flex;
  align-items: center;
}

.monthNav-label {
  color: theme('colors.blueberry.DEFAULT');
  padding-left: theme('spacing.1');
}
</style>
