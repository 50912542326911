<template>
  <div
    v-if="allowedSites.length > 1"
    data-test-site-selector
    class="siteSelector"
    :class="{ 'siteSelector--keepOpen': keepOpen }"
  >
    <Loading-Spinner
      v-if="loadingStatus === 'LOADING'"
      class="spinner"
    />
    <Toggle
      v-else
      ref="$siteSelectorToggle"
      :keep-open="keepOpen"
      data-test-site-selector
    >
      <template #trigger>
        <div class="siteSelector-trigger">
          <template v-if="selectedSite">
            <p class="siteSelector-org">
              <span>{{ selectedSite.orgName }}</span>
            </p>
            <p class="siteSelector-site">
              <span>{{ selectedSite.parsedName }}</span>
            </p>
          </template>
          <template v-else>
            <p class="siteSelector-org my-3">
              <span>{{ label || $t('actions.selectSite') }}</span>
            </p>
          </template>
          <SvgIcon
            v-if="!keepOpen"
            name="24-ic-arrow-down"
          />
        </div>
      </template>
      <template #content>
        <SearchSites
          class="siteSelector-popover"
          @change="handleSiteChange"
        />
      </template>
    </Toggle>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import Toggle from '@/components/Toggle/Toggle.vue'
import SearchSites from '@/components/SearchSites/SearchSites.vue'
import { computed, ref } from 'vue'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
defineProps({
  label: String,
  keepOpen: Boolean,
})
const emit = defineEmits(['loaded'])
// DOM refs
const $siteSelectorToggle = ref(null)

// composables
const { getters, dispatch } = useStore()
const toast = useToast()
const { t } = useI18n()
// computed
const userId = computed(() => getters['auth/userId'])
const allowedSites = computed(() => getters['auth/organisations/sites'])

const { selectedSite, changeSelectedSite, initSelectedSite } = useSelectedSite({
  userId: userId.value,
  allowedSites: allowedSites,
})

// methods
const handleSiteChange = (selectedSite) => {
  // find the real data because selectedSite is the format used to work with the site selector
  // it changes things to make highlighting work
  const site = allowedSites.value.find(({ legacyId }) => selectedSite.legacyId === legacyId)
  changeSelectedSite(site)
  $siteSelectorToggle.value.toggle()
  window.analytics.track('Selected a site', {
    selectedSite: site,
  })
}
const loadingStatus = ref('LOADING')
dispatch('auth/organisations/getAllowedSites')
  .then(initSelectedSite)
  .catch(() => {
    toast.error(t('toast.error.getData'))
  })
  .finally(() => {
    loadingStatus.value = 'LOADED'
    emit('loaded')
  })
</script>

<style lang="scss">
// the whole dropdown situation is WIP. need to try popper.js again

.siteSelector {
  position: relative;
  padding: theme('spacing.4');
  display: flex;
  background: theme('colors.blueberry.DEFAULT');
  min-height: theme('spacing.20');

  // https://github.com/Akryum/v-tooltip/issues/160#issuecomment-440657784
  .v-popover {
    display: flex;

    > .trigger {
      flex: 1 1 auto;
      max-width: 100%;
      outline: none;
    }
  }

  .Toggle-trigger {
    display: block;
  }

  .Toggle-content {
    position: relative;
  }

  .spinner > div {
    background: theme('colors.white');
  }
}

.siteSelector--lg {
  margin-bottom: theme('spacing.8');
  background-color: transparent;

  .siteSelector-trigger {
    font-size: theme('fontSize.2xl');
    @include respond($max: medium) {
      font-size: theme('fontSize.xl');
    }
    @include respond($max: small) {
      font-size: theme('fontSize.base');
    }
  }

  .spinner {
    --bubble-size: theme('spacing.3')
    --bubble-spacing: theme('spacing[1.5]')
  }
}

.siteSelector--keepOpen {
  .siteSelector-popover {
    position: static;
    transform: none;
  }

  .siteSelector-trigger {
    padding-right: 0;
  }

  .siteSelector-org {
    justify-content: center;
  }
}

.siteSelector--emphasis {
  .siteSelector-org,
  .siteSelector-trigger {
    color: theme('colors.acai.DEFAULT');

    .icon {
      color: theme('colors.blueberry.DEFAULT');
    }

    &:hover {
      color: theme('colors.blueberry.DEFAULT');
    }
  }
  /* stylelint-disable selector-max-compound-selectors */
  .formGroup--search .formControl:focus + .icon {
    color: theme('colors.blueberry.DEFAULT');
  }
  /* stylelint-enable */
  .spinner > div {
    background-color: theme('colors.blueberry.DEFAULT');
  }
}

.siteSelector-trigger {
  position: relative;
  display: block;
  padding-right: 1.5em;
  color: theme('colors.white');

  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.siteSelector-org {
  color: theme('colors.white');
  font-weight: theme('fontWeight.bold');
  display: flex;
  max-width: 100%;
}

.siteSelector-site {
  font-size: theme('fontSize.sm');
  display: flex;
  max-width: 100%;
}

.siteSelector-org,
.siteSelector-site {
  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    direction: rtl; // not so great
  }
}

.siteSelector-popover {
  background: theme('colors.white');
  border: 1px solid theme('colors.slate.DEFAULT');
  border-radius: theme('borderRadius.DEFAULT');

  // needs rework
  position: absolute;
  top: calc(100% + theme('spacing.1'));
  left: 0;
  z-index: 1;
  max-width: calc(100vw - theme('spacing.8'));
  width: to-rem(600px);
}

.SearchBox-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid theme('colors.slate.DEFAULT');

  .formGroup--search {
    flex: 1 0 auto;
  }
}

.SearchBox-count {
  flex: 0 1 auto;
  font-size: theme('fontSize.sm');
  padding: 0 theme('spacing.1');
  color: theme('colors.grey.400');
}

.SearchBox-hint {
  font-size: theme('fontSize.sm');
  margin: theme('spacing.1') 0 calc(-1 * theme('spacing.1'));
  padding: 0 theme('spacing.2');
  color: theme('colors.grey.400');
}

.results {
  max-height: to-rem(280px);
  overflow-x: auto;
}

.results-item {
  a {
    display: block;
    padding: theme('spacing.2') theme('spacing.2');
    color: theme('colors.acai.DEFAULT');

    &:hover {
      background: theme('colors.acai.100');
      text-decoration: none;
    }
  }
}
</style>
