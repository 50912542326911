import { isEqual } from 'lodash'

export default function ({ to, from, ignoreList }) {
  // check if there are any query param changes for which we need to reload the data
  // keep a map of params and before/after changes
  // { key: [before, after]}
  let queryDiff = {}
  Object.keys(from.query).forEach((key) => {
    queryDiff[key] = [from.query[key]]
  })
  Object.keys(to.query).forEach((key) => {
    let val = to.query[key]
    if (queryDiff[key]) {
      queryDiff[key].push(val)
    } else {
      queryDiff[key] = [undefined, val]
    }
  })
  // turn that map into an array and filter out all params that didn't change
  // also filter out any params from the `ignoredKeys`
  return Object.keys(queryDiff).reduce((diff, key) => {
    let original = queryDiff[key][0]
    let change = queryDiff[key][1]

    if (ignoreList.indexOf(key) < 0 && !isEqual(original, change))
      diff.push({ [key]: { original, change } })
    return diff
  }, [])
}
