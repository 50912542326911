<template>
  <div>
    <h2 class="p-4">Section Header with title, actions, navigation and navigation actions</h2>
    <div class="box">
      <section-header>
        <template #section-title> This is the title </template>
        <template #section-actions>
          <button class="button button--secondary">
            <SvgIcon name="24-ic-check" />
            <span>Action</span>
          </button>
        </template>
        <template #section-navigation>
          <filters-group
            behaviour="radio"
            selected-id="f01"
            :filters="[
              { id: 'f01', title: 'Some' },
              { id: 'f02', title: 'kind' },
              { id: 'f03', title: 'of' },
              { id: 'f04', title: 'navigation' },
            ]"
            :save-to-url="false"
          />
        </template>
        <template #section-navigation-actions>
          <button class="button button--secondary">
            <SvgIcon name="24-ic-add" />
            <span>Nav action</span>
          </button>
        </template>
      </section-header>
    </div>

    <h2 class="p-4">Section Header with navigation and actions</h2>
    <div class="box">
      <section-header>
        <template #section-navigation-actions>
          <button class="button button--secondary">
            <SvgIcon name="24-ic-check" />
            <span>Action</span>
          </button>
        </template>
        <template #section-navigation>
          <filters-group
            behaviour="radio"
            selected-id="f11"
            :filters="[
              { id: 'f11', title: 'Some' },
              { id: 'f12', title: 'kind' },
              { id: 'f13', title: 'of' },
              { id: 'f14', title: 'navigation' },
            ]"
            :save-to-url="false"
          />
        </template>
      </section-header>
    </div>

    <h2 class="p-4">Section Header with only a title</h2>
    <div class="box">
      <section-header>
        <template #section-title> This is the title </template>
      </section-header>
    </div>

    <h2 class="p-4">Section Header with only an action</h2>
    <div class="box">
      <section-header>
        <template #section-actions>
          <button class="button button--secondary">
            <SvgIcon name="24-ic-check" />
            <span>Action</span>
          </button>
        </template>
      </section-header>
    </div>

    <h2 class="p-4">Section Header with only subnavigation</h2>
    <div class="box">
      <section-header>
        <template #section-navigation>
          <filters-group
            behaviour="radio"
            selected-id="f21"
            :filters="[
              { id: 'f21', title: 'Some' },
              { id: 'f22', title: 'kind' },
              { id: 'f23', title: 'of' },
              { id: 'f24', title: 'navigation' },
            ]"
            :save-to-url="false"
          />
        </template>
      </section-header>
    </div>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue'
import FiltersGroup from '@/components/FiltersGroup.vue'
export default {
  components: {
    SectionHeader,
    FiltersGroup,
  },
}
</script>

<style></style>
