<template>
  <g class="timeline-indicator">
    <g :transform="`translate(${position}, 0)`">
      <rect
        class="timeline-indicator-cursor"
        :height="height"
        :opacity="visible ? 1 : 0"
        :x="-(size / 2)"
        :width="size"
      />
      <text
        class="timeline-indicator-value"
        text-anchor="middle"
        :y="labelPosition"
        :opacity="visible ? 1 : 0"
        >{{ label }}</text
      >
    </g>
  </g>
</template>

<script>
export default {
  props: {
    label: String,
    labelPosition: Number,
    position: Number,
    height: Number,
    visible: Boolean,
    size: {
      type: Number,
      default: 2,
    },
  },
}
</script>

<style lang="scss">
.timeline-indicator-cursor {
  transition: all 0.1s ease-out;
  fill: theme('colors.aqua.DEFAULT');
  stroke: none;
  pointer-events: none;
  will-change: width, x;
}
</style>
