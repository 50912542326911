<script setup>
import { computed } from 'vue'
import { useAbility } from '@casl/vue'
const props = defineProps({
  items: {
    type: Array,
    default: null,
  },
})

const { can } = useAbility()

const itemsCount = computed(() => props.items.length)

const emits = defineEmits(['handleDelete'])

function handleDelete() {
  emits('handleDelete')
}
</script>

<template>
  <div class="bulk-actions">
    <button
      v-if="can('delete', 'waste-log') && itemsCount > 0"
      class="button button--primary"
      data-test-bulk-delete-button
      @click="handleDelete"
    >
      <SvgIcon name="24-ic-delete" />
      <span>{{ $t('actions.bulkDelete', { count: itemsCount }) }}</span>
    </button>
  </div>
</template>

<style lang="scss">
.bulk-actions {
  margin-left: theme('spacing.2');
  gap: theme('spacing.1');
  display: flex;
}
</style>
