<template>
  <Loaded-content
    :is-loading="loadingStatus === 'LOADING'"
    :has-error="loadingStatus === 'ERROR'"
    :has-data="loadingStatus === 'LOADED' && !!node"
  >
    <template #default>
      <div class="px-8">
        <h1
          class="Hero-title"
          data-test-selected-node-title
        >
          {{ displayedName }}
        </h1>
        <TabsLvl1Links class="">
          <li
            v-for="tab in navigation"
            :key="tab.title"
            v-bind="{ [tab.testHook]: true }"
          >
            <router-link
              class="tab-item"
              :to="tab.route"
            >
              {{ tab.title }}
            </router-link>
          </li>
        </TabsLvl1Links>
      </div>
      <PageSection>
        <div
          v-if="node.archived"
          class="alert alert--warning mb-4"
          data-test-archived-message
        >
          <p class="alert__description">
            <SvgIcon name="24-ic-info" />
            <span class="flex-inline">{{ $t('organisations.messages.deactivated') }} </span>
            <ButtonWithSpinner
              type="button"
              class="button--link py-0 inline mx-1"
              data-test-activate-button
              @click="activateNode"
              :in-progress="savingStatus === 'SAVING'"
            >
              {{ $t('actions.reactivate') }}
            </ButtonWithSpinner>
          </p>
        </div>

        <router-view @node-updated="onNodeUpdate" />
      </PageSection>
    </template>
  </Loaded-content>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import TabsLvl1Links from '@/components/Tabs/TabsLvl1Links.vue'
import { getOrgDisplayName } from '@/utils/organisations'
import { WINNOW_ORG_ID } from '@/store/constants'
import { useToast } from 'vue-toastification'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
export default {
  components: {
    LoadedContent,
    ButtonWithSpinner,
    TabsLvl1Links,
  },
  props: {
    nodeId: String,
  },
  emits: ['node-updated'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
    }
  },
  computed: {
    isWinnow() {
      return this.nodeId === WINNOW_ORG_ID
    },
    navigation() {
      return [
        {
          route: {
            name: 'hierarchy-node-overview',
            query: { ...this.$route.query },
          },
          title: this.$t('organisations.navigation.overview'),
          testHook: 'data-test-tabs-overview',
        },
        {
          route: {
            name: 'hierarchy-node-users',
            query: { ...this.$route.query },
          },
          title: this.$t('organisations.navigation.users'),
          testHook: 'data-test-tabs-users',
        },
        {
          route: {
            name: 'hierarchy-node-reporting',
            query: { ...this.$route.query },
          },
          title: this.$t('organisations.navigation.reporting'),
          testHook: 'data-test-tabs-overview',
        },
      ].filter((tab) => {
        if (this.nodeId === WINNOW_ORG_ID && tab.route.name === 'hierarchy-node-overview')
          return false
        if (this.nodeId === WINNOW_ORG_ID && tab.route.name === 'hierarchy-node-reporting')
          return false
        return true
      })
    },
    node() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    nodeLevel() {
      return this.$store.getters['hierarchy/nodes/getLevelName'](this.node.level)
    },
    displayedName() {
      return getOrgDisplayName(this.node)
    },
  },
  watch: {
    node: {
      handler(node) {
        if (this.isWinnow && !!node) this.loadingStatus = 'LOADED'
      },
      immediate: true,
    },
  },
  created() {
    if (this.loadingStatus !== 'LOADED') {
      this.loadingStatus = 'LOADING'
    }
    if (this.isWinnow) {
      this.rerouteWinnowNodeToUsers()
      return
    }
    this.$store
      .dispatch('hierarchy/nodes/getNode', this.nodeId)
      .then(() => {
        this.loadingStatus = 'LOADED'
      })
      .catch(() => {
        this.loadingStatus = 'ERROR'
      })
  },
  methods: {
    activateNode() {
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('hierarchy/nodes/saveNodeChanges', {
          nodeId: this.node.id,
          data: { archived: false },
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
          this.$emit('node-updated', this.nodeId)
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    onNodeUpdate() {
      this.$emit('node-updated', this.nodeId)
    },
    rerouteWinnowNodeToUsers() {
      let isOnUsersTab = this.$route.matched
        .map((r) => r.name)
        .filter((name) => name === 'hierarchy-node-users').length
      if (!isOnUsersTab) {
        this.$router.push({ name: 'hierarchy-node-users' })
      }
    },
  },
}
</script>

<style></style>
