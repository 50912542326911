<template>
  <tbody>
    <tr
      v-for="(change, index) in visibleChanges"
      :key="change.key"
      :class="{
        'tr--strong': !index,
        'tr-pop': !change.ignored,
        'tr--disabled': change.ignored && !forceActive,
      }"
      :style="{ display: collapsible && index > 0 && openItem !== foodItem.id ? 'none' : null }"
    >
      <th
        v-if="!index"
        scope="rowgroup"
        :rowspan="visibleChanges.length"
      >
        <div class="flex flex-between flex-column">
          <div>{{ foodItem.name }}</div>
          <div
            v-if="showGroupActions"
            class="flex flex-column flex-top flex-right pl-1 mt-4"
          >
            <ButtonWithSpinner
              v-if="foodItem.action === 'DELETE'"
              class="button--link mb-2"
              :in-progress="savingStatus === 'SAVING'"
              :disabled="foodItem.ignored && !forceActive"
              @click="applyChange({ foodItemId: foodItem.id })"
            >
              <template #icon>
                <SvgIcon name="24-ic-delete" />
              </template>
              {{ $t('actions.delete') }}
            </ButtonWithSpinner>
            <ButtonWithSpinner
              v-else
              class="button--link mb-2"
              :in-progress="savingStatus === 'SAVING'"
              :disabled="foodItem.ignored && !forceActive"
              @click="applyChange({ foodItemId: foodItem.id })"
            >
              <template #icon><SvgIcon name="24-ic-check" /></template>
              {{ $t('actions.apply') }}
            </ButtonWithSpinner>

            <button
              v-if="foodItem.ignored"
              type="button"
              class="button button--link mb-2"
              @click="ignoreChanges({ changes: foodItem.changes, isIgnored: false })"
            >
              <SvgIcon name="unlock" />
              <span>Include</span>
            </button>
            <button
              v-if="!foodItem.ignored"
              type="button"
              class="button button--link mb-2"
              @click="ignoreChanges({ changes: foodItem.changes, isIgnored: true })"
            >
              <SvgIcon name="24-ic-lock" />
              <span>Ignore</span>
            </button>
          </div>
          <div
            v-if="errorMessages.length"
            class="alert alert--warning"
          >
            <ol>
              <li
                v-for="error in errorMessages"
                :key="error.message"
                class="mb-2"
              >
                {{ error.message }}
              </li>
            </ol>
          </div>
        </div>
      </th>
      <!-- Action icon -->
      <td v-if="headerIds.includes('th-action')">
        <SvgIcon
          v-tooltip="foodItem.action"
          :name="actionIcons[foodItem.action]"
        />
      </td>
      <!-- Prop column -->
      <td v-if="headerIds.includes('th-prop')">
        <!--
          Cost label gets formatted depending on user weight preferences
          Cost / (lbs | kg)
        -->
        <b v-if="change.key === 'cost'">
          {{
            $t(`menus.bulkUpdate.fields.${change.key}`, {
              unit: $t(`settings.weight.short.${weightPreference}`),
            })
          }}
        </b>
        <!--
          Portion gets formatted depending on user weight preferences
          Portion (grams | oz)
        -->
        <b v-else-if="change.key === 'portion'">
          {{ $t(`menus.bulkUpdate.fields.${change.key}`, { unit: weightUnit }) }}
        </b>
        <b v-else>{{ $t(`menus.bulkUpdate.fields.${change.key}`) }}</b>
      </td>
      <!-- Old value column -->
      <td
        v-if="headerIds.includes('th-old')"
        :class="{ 'break-all': change.key === 'taxonomyCode' }"
        :style="{ 'max-width': '15rem', overflow: 'hidden' }"
      >
        <ul
          v-if="change.type === 'REFERENCES' && change.oldValue"
          class="chip-container -my-2"
        >
          <Chip
            v-for="(group, index) in change.oldValue"
            :key="index"
            tag="li"
          >
            {{ group.name }}<span v-if="group.stage"> ({{ group.stage.name }})</span>
          </Chip>
        </ul>
        <template v-else-if="change.oldValue">
          {{ displayValue(change.oldValue, change.key) }}
        </template>
        <template v-else> - </template>
      </td>
      <!-- New value column -->
      <td
        v-if="headerIds.includes('th-new')"
        :class="{ 'break-all': change.key === 'taxonomyCode' }"
        :style="{ 'max-width': '15rem', overflow: 'hidden' }"
      >
        <ul
          v-if="change.type === 'REFERENCES' && change.newValue"
          class="chip-container -my-2"
        >
          <Chip
            v-for="(group, index) in change.newValue"
            :key="index"
            tag="li"
          >
            {{ group.name }}<span v-if="group.stage"> ({{ group.stage.name }})</span>
          </Chip>
        </ul>
        <template v-else-if="change.newValue">
          {{ displayValue(change.newValue, change.key) }}
        </template>
        <template v-else> - </template>
      </td>
      <!-- Action buttons column -->
      <td v-if="headerIds.includes('th-actions')">
        <div class="buttonGroup">
          <!-- @TODO: IGNORE / INCLUDE buttons -->
          <span
            v-tooltip="{
              theme: 'tooltip-warning',
              content: ignoreDependenciesMessage(change.key),
              placement: 'top',
            }"
            class="show-on-hover-action"
          >
            <button
              v-if="ignoreChangeButtonIsVisible(change)"
              type="button"
              class="button button--link"
              :disabled="ignoreChangeButtonIsDisabled(change)"
              @click="ignoreChanges({ changes: [change], isIgnored: true })"
            >
              <SvgIcon name="24-ic-lock" />
              <span>Ignore</span>
            </button>
          </span>
          <button
            v-if="change.ignored && foodItem.action !== 'DELETE'"
            type="button"
            class="button button--link"
            @click="ignoreChanges({ changes: [change], isIgnored: false })"
          >
            <SvgIcon name="unlock" />
            <span>Include</span>
          </button>

          <span
            v-tooltip="{
              theme: 'tooltip-warning',
              content: savingDependenciesMessage(change.key),
              placement: 'top',
            }"
            class="show-on-hover-action"
          >
            <ButtonWithSpinner
              v-if="!foodItem.ignored"
              class="button--link"
              :disabled="applyChangeButtonIsDisabled(change)"
              @click="applyChange({ foodItemId: foodItem.id, changeId: change.id })"
              :in-progress="change.status === 'SAVING'"
            >
              <template #icon><SvgIcon name="24-ic-check" /></template>
              {{ $t('actions.apply') }}
            </ButtonWithSpinner>
          </span>

          <button
            v-if="collapsible && !index && openItem !== foodItem.id"
            type="button"
            class="button button--link show-on-hover-action"
            @click="openItem = foodItem.id"
          >
            <SvgIcon name="24-arrow-down" />
            <span>Expand</span>
          </button>
          <button
            v-if="collapsible && !index && openItem === foodItem.id"
            type="button"
            class="button button--link show-on-hover-action"
            @click="openItem = null"
          >
            <SvgIcon name="24-ic-arrow-up" />
            <span>Collapse</span>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Chip from '@/components/Chip/Chip.vue'
import { useToast } from 'vue-toastification'
import FoodItemMixin from '@/pages/Menus/FoodItems/FoodItemMixin'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
export default {
  components: {
    Chip,
    ButtonWithSpinner,
  },
  mixins: [FoodItemMixin],
  props: {
    foodItem: Object,
    collapsible: Boolean,
    showGroupActions: Boolean,
    headerIds: Array,
    savingStatusOverwrite: String,
    showIgnored: {
      type: Boolean,
      default: true,
    },
    forceActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['save', 'saved'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      menuId: this.$route.params.menuId,
      actionIcons: {
        NEW: '24-ic-add',
        MODIFIED: '24-ic-edit',
        DELETE: 'waste-log',
      },
      savingStatus: 'IDLE',
      errorMessages: [],
    }
  },
  computed: {
    visibleChanges() {
      return this.showIgnored
        ? this.foodItem.changes
        : this.foodItem.changes.filter(({ ignored }) => !ignored)
    },
  },
  watch: {
    savingStatusOverwrite(newStatus) {
      this.savingStatus = newStatus
    },
  },
  methods: {
    savingDependenciesMessage(field) {
      // check if name and nameEnglish have been saved
      let changedFields = this.foodItem.changes.map((change) => change.key)
      let hasNameChange = changedFields.includes('name')
      let hasNameEnglishChange = changedFields.includes('nameEnglish')
      let nameAndNameEnglishNotSaved = hasNameChange && hasNameEnglishChange
      let action = this.foodItem.action

      if (action === 'DELETE') {
        return 'Saving individual fields on deleted items is not allowed'
      }
      if (action === 'NEW' && field === 'name') {
        return 'English name will also be saved'
      }
      if (action === 'NEW' && field === 'nameEnglish') {
        return 'Name will also be saved'
      }
      if (field === 'stages' && action === 'NEW' && nameAndNameEnglishNotSaved) {
        return 'This will also save the name, english name and corresponding food group changes'
      }
      if (field === 'stages') {
        return 'This will also apply the corresponding food group changes'
      }
      if (field === 'foodGroups' && action === 'NEW' && nameAndNameEnglishNotSaved) {
        return 'This will also save the name, english name and corresponding stage changes'
      }
      if (field === 'foodGroups') {
        return 'This will also apply the corresponding stage changes'
      }
      if (field === 'externalSystem.name' && action === 'NEW' && nameAndNameEnglishNotSaved) {
        return 'This will also save the name, english name and external id'
      }
      if (field === 'externalSystem.name') {
        return 'This will also save the external id'
      }
      if (field === 'externalId' && action === 'NEW' && nameAndNameEnglishNotSaved) {
        return 'This will also save the name, english name and external system'
      }
      if (field === 'externalId') {
        return 'This will also save the external system'
      }
      if (
        action === 'NEW' &&
        !['name', 'nameEnglish', 'stages', 'foodGroups'].includes(field) &&
        nameAndNameEnglishNotSaved
      ) {
        return 'This will also save the name and english name'
      }
    },
    ignoreDependenciesMessage(field) {
      let action = this.foodItem.action
      if (field === 'name' && action === 'NEW') {
        return 'Name field is required for creating a new item.'
      }
      if (field === 'nameEnglish' && action === 'NEW') {
        return 'English name field is required for creating a new item.'
      }
      if (action === 'DELETE') {
        return 'Ignoring individual fields on deleted items is not allowed'
      }
      switch (field) {
        case 'stages': {
          return 'This will also ignore the corresponding food group changes'
        }
        case 'foodGroups': {
          return 'This will also ignore the corresponding stage changes'
        }
        case 'externalSystem.name': {
          return 'This will also ignore the external id'
        }
        case 'externalId': {
          return 'This will also ignore the external system'
        }
      }
    },
    applyChange({ foodItemId, changeId }) {
      this.errorMessages = []
      this.savingStatus = 'SAVING'
      this.$emit('save')
      this.$store
        .dispatch('menu-management/bulk-update/saveFoodItem', {
          menuId: this.menuId,
          foodItemId,
          changeIds: changeId ? [changeId] : null,
        })
        .then(() => {
          this.$emit('saved')
          this.savingStatus = 'SAVED'
        })
        .catch((err) => {
          this.savingStatus = 'ERROR'
          if (err.data) {
            this.errorMessages = err.data.errors
          }
          this.$emit('saved')
          this.toast.error(this.$t('toast.error.saveData'))
        })
        .then(() =>
          this.$store.dispatch('menu-management/menus/checkMenuIssues', { menuId: this.menuId })
        )
    },
    ignoreChanges({ changes, isIgnored }) {
      // ignore stages and food groups together
      let stageChanges = changes.find((change) => change.key === 'stages')
      let foodGroupChanges = changes.find((change) => change.key === 'foodGroups')
      if (stageChanges && !foodGroupChanges) {
        let foodGroupChange = this.foodItem.changes.find((change) => change.key === 'foodGroups')
        if (foodGroupChange) changes.push()
      }
      if (!stageChanges && foodGroupChanges) {
        let stageChange = this.foodItem.changes.find((change) => change.key === 'stages')
        if (stageChange) changes.push()
      }

      // ignore external id and system together
      let externalSystemChange = changes.find((change) => change.key === 'externalSystem.name')
      let externalIdChange = changes.find((change) => change.key === 'externalId')
      if (externalSystemChange && !externalIdChange) {
        changes.push(this.foodItem.changes.find((change) => change.key === 'externalId'))
      }
      if (!externalSystemChange && externalIdChange) {
        changes.push(this.foodItem.changes.find((change) => change.key === 'externalSystem.name'))
      }

      // apply ignored list
      changes.forEach((change) => {
        this.$store.commit('menu-management/bulk-update/UPDATE_CHANGE', {
          id: change.id,
          ignored: isIgnored,
        })
      })
    },
    ignoreChangeButtonIsDisabled(change) {
      if (this.foodItem.action === 'DELETE') return true
      if (this.foodItem.action === 'NEW' && (change.key === 'name' || change.key === 'nameEnglish'))
        return true
      return false
    },
    ignoreChangeButtonIsVisible(change) {
      if (change.ignored) return false
      return true
    },
    applyChangeButtonIsDisabled(change) {
      if (this.foodItem.action === 'DELETE') return true
      if (this.forceActive) return true
      if (change.ignored) return true
      return false
    },
    // Custom formatting for some of the props
    displayValue(value, key) {
      if (key === 'cost') {
        // Needs to be converted to correct value depending on user weight preference (kg/lbs)
        return this.convertCostForDisplay(value)
      }
      if (key === 'portion') {
        // Needs to be converted to correct value depending on user weight preference (oz/grams)
        return this.convertWeightForDisplay(value)
      }
      return value
    },
  },
}
</script>

<style></style>
