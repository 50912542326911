<template>
  <nav
    v-test="'breadcrumbs'"
    aria-label="Breadcrumb"
  >
    <ul class="breadcrumbs">
      <li
        v-for="route in history"
        :key="route.name"
        class="breadcrumbs-item"
      >
        <router-link
          v-test="'breadcrumbs-link'"
          :to="route.route || { name: route.name }"
          >{{ route.title }}</router-link
        >
        <SvgIcon
          class="inline"
          name="24-ic-arrow-next"
        />
      </li>
      <li
        class="breadcrumbs-item"
        aria-current="page"
      >
        <slot>
          <span v-test="'breadcrumb-parent'">
            {{ current }}
          </span>
        </slot>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'HubBreadcrumbs',
  props: [
    /**
     * @Array of objects containing a `name` and a `title`.
     * `name` is a route name from the router,
     * `title` is the text that should be displayed,
     * `route` instead of name if you want complete control over the route
     */
    'history',

    /**
     * @Text displayed for the current page
     * Current is also a <slot> so it can be added that way if custom HTML is required.
     */
    'current',
  ],
}
</script>
<style lang="scss">
.breadcrumbs {
  margin-top: 0;
  margin-left: calc(-1 * theme('spacing.4'));
  padding: 0 theme('spacing.4');
  // remove uppercase inherited from Hero component
  text-transform: none;
}

.breadcrumbs-item {
  display: inline;

  a {
    color: theme('colors.blueberry.DEFAULT');
  }

  .icon {
    margin: calc(-1 * theme('spacing.px')) theme('spacing[1.5]') 0;
    vertical-align: middle;
  }
}

.breadcrumbs--neutral {
  .breadcrumbs-item a {
    color: theme('colors.acai.DEFAULT');
  }
}
</style>
