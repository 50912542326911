// Functions to support vue-toastification

// Filter out duplicate notifications of the same type and message
export const filterBeforeCreate = (toast, toasts) => {
  const toastExists = toasts.find(
    (existing) => existing.type === toast.type && existing.content === toast.content
  )

  if (toastExists) {
    // Discard the toast
    return false
  }

  return toast
}
