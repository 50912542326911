export default function (newData) {
  return {
    id: newData.id,
    name: newData.name,
    nameEnglish: newData.nameEnglish,
    displayOrder: newData.displayOrder,
    image: newData.imageId,
    customBreakdownId: newData.custom1Id,
    foodGroupIds: newData.foodGroupIds,
    alphabeticallySortedChildren:
      newData.manualOrdering !== undefined ? !newData.manualOrdering : undefined,
  }
}
