import api from '@/store/api/users'
import getOldValues from '@/store/utils/getOldValues'

const state = {
  records: {},
  userGroupRecords: {},
  internalUserIds: [],
  meta: null,
}

const getters = {
  byId: (state, getters, rootState, rootGetters) => (id) => ({
    ...state.records[id],
    subjectOrganization: rootGetters['hierarchy/nodes/byId'](
      state.records[id].subjectOrganizationId
    ),
  }),
  usersById: (state, getters) => (ids) => {
    if (!Array.isArray(ids)) return []
    return ids.map((id) => getters.byId(id))
  },
  internalUserIds: (state) => state.internalUserIds,
  userGroups: (state) => Object.values(state.userGroupRecords),
  meta: (state) => state.meta,
}

const actions = {
  getUsers({ commit }, { query }) {
    return api.fetchUsers({ query }).then(({ records, ids, organisationRecords, total }) => {
      commit('STORE_RECORDS', records)
      return { records, ids, organisationRecords, total }
    })
  },
  getInternalUsers({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .fetchInternalUsers()
        .then(({ records, ids, metadata }) => {
          commit('STORE_RECORDS', records)
          commit('STORE_INTERNAL_USER_IDS', ids)
          commit('STORE_META', metadata)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getuserGroups({ commit }) {
    return api.fetchUserGroups().then((records) => {
      commit('STORE_USER_GROUP_RECORDS', records)
    })
  },
  createUser({ commit }, data) {
    return api.postUser(data).then(({ record, subjectOrganization }) => {
      commit('hierarchy/nodes/UPDATE_RECORD', subjectOrganization, { root: true })
      commit('STORE_RECORD', record)
      return { record, subjectOrganization }
    })
  },
  updateUser({ commit, getters }, { id, ...data }) {
    return api
      .patchUser({ userId: id, old: getOldValues(getters.byId(id), data), new: data })
      .then(({ record, subjectOrganization }) => {
        // need to update the org first otherwise the users list will throw an error
        // as it would be missing when updating the users list
        commit('hierarchy/nodes/UPDATE_RECORD', subjectOrganization, { root: true })
        commit('STORE_RECORD', record)
        return { record, subjectOrganization }
      })
  },
  resendLoginDetails(store, { userId }) {
    return api.passwordReset({ userId })
  },
  forcePasswordReset({ commit }, { userId }) {
    return api.forcePasswordReset({ userId }).then((record) => {
      commit('STORE_RECORD', record)
      return record
    })
  },
  deactivateUser({ commit }, { userId }) {
    return api
      .postActiveStatus({
        userId,
        activeStatus: 'INACTIVE',
      })
      .then((record) => {
        commit('STORE_RECORD', record)
        return record
      })
  },
  reactivateUser({ commit }, { userId }) {
    return api
      .postActiveStatus({
        userId,
        activeStatus: 'ACTIVE',
      })
      .then((record) => {
        commit('STORE_RECORD', record)
        return record
      })
  },
}

const mutations = {
  STORE_RECORDS(state, records) {
    state.records = { ...state.records, ...records }
  },

  STORE_RECORD(state, record) {
    state.records[record.id] = record
  },

  STORE_INTERNAL_USER_IDS(state, idsList) {
    state.internalUserIds = idsList
  },

  STORE_USER_GROUP_RECORDS(state, records) {
    state.userGroupRecords = records
  },

  STORE_META(state, meta) {
    state.meta = meta
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
