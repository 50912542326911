<template>
  <div class="flex inline-chips">
    <ul
      ref="container"
      class="inline-chips--list"
    >
      <filter-chip
        v-for="(item, index) in items"
        :key="index"
        tag="li"
      >
        {{ item }}
      </filter-chip>
    </ul>
    <div class="inline-chips--hiddenCount">
      <span v-if="hiddenItems">{{ $t('labels.moreItems', { count: hiddenItems }) }}</span>
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash'

import FilterChip from '@/components/FilterChip.vue'
import { DEBOUNCE_DELAY } from '@/store/constants'

export default {
  components: {
    FilterChip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hiddenItems: 0,
    }
  },

  mounted() {
    this.countHiddenItems()
    window.addEventListener('resize', debounce(this.countHiddenItems, DEBOUNCE_DELAY))
  },
  unmounted() {
    window.removeEventListener('resize', this.countHiddenItems)
  },
  methods: {
    countHiddenItems() {
      const container = this.$refs.container
      let count = 0

      container.childNodes.forEach((node) => {
        const { offsetTop, offsetHeight, offsetLeft, offsetWidth } = node

        if (
          offsetTop + offsetHeight > container.clientHeight ||
          offsetLeft + offsetWidth > container.clientWidth
        ) {
          count++
        }
      })
      this.hiddenItems = count
    },
  },
}
</script>
<style lang="scss">
.inline-chips {
  &--list {
    flex: 9;
    overflow-y: hidden;
    height: 3.125rem; /* 50px */
    position: relative;
  }
  &--hiddenCount {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: theme('spacing.3');
  }
}
</style>
