import { merge } from 'lodash'
import api from '@/store/api/systems-management'

export default {
  namespaced: true,
  state: {
    records: {},
    sortedRecordIds: [],
    totalRecordsAvailable: 0,
  },
  getters: {
    byId: (state) => (id) => state.records[id],
    sortedSystems: (state, getters) => state.sortedRecordIds.map((id) => getters['byId'](id)),
    totalRecordsAvailable: (state) => state.totalRecordsAvailable,
  },
  actions: {
    getSystems({ commit }, { query, signal }) {
      return api.fetchSystems({ query, signal }).then(({ records, sortedRecordIds, total }) => {
        commit('STORE_RECORDS', records)
        commit('STORE_SORT_ORDER', sortedRecordIds)
        commit('UPDATE_TOTAL_RECORDS', total)
      })
    },
    getSystem({ commit }, id) {
      return api.fetchSystem(id).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
    buildSystem({ commit }, id) {
      return api.patchSystem(id, { type: 'ARCHON' }).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
    changeAuthorisation({ commit }, { id, authorised }) {
      return api.patchSystem(id, { authorised }).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
    renameSystem({ commit }, { id, name }) {
      return api.patchSystem(id, { configuration: { name } }).then((system) => {
        commit('STORE_RECORDS', { [system.id]: system })
      })
    },
  },
  mutations: {
    STORE_RECORDS(state, records) {
      state.records = merge(state.records, records)
    },
    STORE_SORT_ORDER(state, ids) {
      state.sortedRecordIds = ids
    },
    UPDATE_TOTAL_RECORDS(state, count) {
      state.totalRecordsAvailable = count
    },
  },
}
