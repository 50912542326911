<template>
  <Collapsible-section
    class="collapsible-section--first box-table"
    data-test-advanced-filters
  >
    <template #header>
      {{ $t('titles.advancedFilters') }}
    </template>
    <template #header-side>
      <Loading-Spinner v-if="loadingStatus === 'LOADING' && !selectedFilterLabelsList.length" />
      <FilterChipTeaser
        v-for="filter in selectedFilterLabelsList"
        :key="filter.key"
        :model-value="filter.value"
        :loading="loadingStatus === 'LOADING'"
        :removable="true"
        data-test-filter-chip
        @remove="removeItem(filter.key)"
      />
    </template>

    <!-- Package filter -->
    <Loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
      class="layout-col-x3"
    >
      <div>
        <div class="formGroup">
          <label for="#filter-food-item">{{ $t('menus.foodItems.filters.stages') }}</label>
          <MultiSelect
            id="filter-food-item"
            data-test-filter-stages
            :model-value="selectedStages"
            :model-value-type="
              typeof stages[0].id === 'number' ? 'LIST_OF_NUMBERS' : 'LIST_OF_STRINGS'
            "
            :options="stages"
            track-by="id"
            label="displayName"
            :placeholder="
              selectedFilterLabels.stages.length
                ? $t('filters.numApplied', { count: selectedFilterLabels.stages.length })
                : $t('filters.add')
            "
            @update:modelValue="onStageChange"
          />
        </div>
      </div>
      <div>
        <div class="formGroup">
          <label for="filter-food-groups">{{ $t('menus.foodItems.filters.foodGroups') }}</label>
          <MultiSelect
            id="filter-food-groups"
            data-test-filter-food-groups
            :model-value="selectedFoodGroups"
            :model-value-type="
              typeof foodGroups[0].id === 'number' ? 'LIST_OF_NUMBERS' : 'LIST_OF_STRINGS'
            "
            :options="foodGroups"
            track-by="id"
            label="displayName"
            :placeholder="
              selectedFilterLabels.foodGroups.length
                ? $t('filters.numApplied', { count: selectedFilterLabels.foodGroups.length })
                : $t('filters.add')
            "
            @update:modelValue="onFoodGroupChange"
          />
        </div>
      </div>
    </Loaded-content>
  </Collapsible-section>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import MultiSelect from '@/components/MultiSelect.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import FilterChipTeaser from '@/components/AdvancedFiltersChipTeaser.vue'

export default {
  components: {
    LoadedContent,
    MultiSelect,
    CollapsibleSection,
    FilterChipTeaser,
  },
  props: {
    menuId: String,
    selectedStages: {
      type: Array,
      default: () => [],
    },
    selectedFoodGroups: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['filter'],
  data() {
    return {
      loadingStatus: 'IDLE',
      selectedFilterLabels: {
        stages: [],
        foodGroups: [],
      },
    }
  },
  computed: {
    stages() {
      return this.$store.getters['menu-management/menus/stagesInMenu'](this.menuId)
        .map((stage) => ({
          ...stage,
          displayName: stage.displayName || stage.nameEnglish || stage.name,
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    },
    foodGroups() {
      return this.$store.getters['menu-management/menus/foodGroupsInMenu'](this.menuId)
        .map((fg) => {
          let fgStage = this.$store.getters['menu-management/stages/stageById'](
            fg.stageIds && fg.stageIds[0]
          )
          let displayName = fg.displayName || fg.nameEnglish || fg.name
          if (fgStage.id) {
            let fgStageName = fgStage.displayName || fgStage.nameEnglish || fgStage.name
            displayName = `${displayName} (${fgStageName})`
          }
          return {
            ...fg,
            displayName,
          }
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    },
    selectedFilterLabelsList() {
      return Object.keys(this.selectedFilterLabels)
        .map((key) => ({
          key,
          value: this.selectedFilterLabels[key],
        }))
        .filter((f) => !!f.value.length)
    },
  },
  watch: {
    selectedStages: {
      handler(ids) {
        this.importStageIds(ids)
      },
      immediate: true,
    },
    selectedFoodGroups: {
      handler(ids) {
        this.importFoodGroupIds(ids)
      },
      immediate: true,
    },
  },
  created() {
    this.loadingStatus = 'LOADING'
    this.$store
      .dispatch('menu-management/stages/getStages', { menuId: this.menuId })
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.importStageIds(this.selectedStages)
        this.importFoodGroupIds(this.selectedFoodGroups)
      })
      .catch(() => {
        this.loadingStatus = 'ERROR'
      })
  },
  methods: {
    removeItem(key) {
      this.selectedFilterLabels[key] = []
      this.$emit('filter', { [key]: undefined })
    },
    importStageIds(ids) {
      this.selectedFilterLabels.stages = this.stages
        .filter((stage) => ids.includes(stage.id))
        .map((stage) => stage.displayName)
    },
    importFoodGroupIds(ids) {
      this.selectedFilterLabels.foodGroups = this.foodGroups
        .filter((foodGroup) => ids.includes(foodGroup.id))
        .map((foodGroup) => foodGroup.displayName)
    },
    onStageChange(selectedStages) {
      this.selectedFilterLabels.stages = selectedStages.map(
        (id) => this.stages.find((stage) => stage.id === id).name
      )
      if (this.selectedFilterLabels.stages.length) {
        this.$emit('filter', { stages: selectedStages })
      } else {
        this.$emit('filter', { stages: undefined })
      }
    },
    onFoodGroupChange(selectedFoodGroups) {
      this.selectedFilterLabels.foodGroups = selectedFoodGroups.map(
        (id) => this.foodGroups.find((fg) => fg.id === id).name
      )
      if (this.selectedFilterLabels.foodGroups.length) {
        this.$emit('filter', { foodGroups: selectedFoodGroups })
      } else {
        this.$emit('filter', { foodGroups: undefined })
      }
    },
  },
}
</script>

<style></style>
