<script>
import { clone, debounce } from 'lodash'
import MultiSelect from '@/components/MultiSelect.vue'
import axios from 'axios'

import { DEBOUNCE_DELAY } from '@/store/constants'
import { isBlank } from '@/utils/string'
import api from '@/store/api/organisation'

export default {
  components: {
    MultiSelect,
  },
  props: ['id', 'modelValue', 'locationId'],
  emits: ['update:modelValue'],
  data() {
    return {
      isRunning: false,
      source: null,
      selected: this.modelValue,
      options: [],
    }
  },
  computed: {
    displayOptions() {
      return this.selected.reduce((options, item) => {
        const index = options.findIndex((option) => option.id === item.id)
        const option = options[index]

        if (option && !option.$isDisabled) {
          options[index] = { ...option, $isDisabled: true }
        }
        return options
      }, clone(this.options))
    },
  },
  watch: {
    modelValue(newValue) {
      this.selected = newValue
    },
  },
  mounted() {
    this.$refs[this.id].focusInput()
  },
  methods: {
    runTask: debounce(async function (token) {
      if (this.source !== null) {
        this.source.cancel()
      }

      if (isBlank(token)) {
        this.options = []
        return
      }

      this.isRunning = true
      this.source = axios.CancelToken.source()
      await this.performSearch({
        query: {
          size: 100,
          search: token,
        },
        options: { cancelToken: this.source.token },
      })

      this.isRunning = false
      this.source = null
    }, DEBOUNCE_DELAY),

    async performSearch(params) {
      const { query, options = {} } = params
      const data = await api.fetchNodeAuthorizedSubscribers(
        query,
        this.locationId,
        options.cancelToken
      )
      this.options = data
    },
    handleSelect(value) {
      this.$emit('update:modelValue', value)
    },
    isSelected(option) {
      return !!this.selected.find((selected) => selected.id === option.id)
    },
  },
}
</script>

<template>
  <MultiSelect
    :id="id"
    :ref="id"
    v-model="selected"
    track-by="id"
    :options="displayOptions"
    :block-keys="['Delete']"
    :placeholder="$t('actions.typeSearch')"
    :use-internal-search="false"
    :is-loading="isRunning"
    @select="handleSelect"
    @search-change="runTask"
  >
    <template #selection="{ values, isOpen }">
      <span
        v-if="values.length && !isOpen"
        class="multiselect__single"
      >
        {{ $t('actions.typeSearch') }}
      </span>
    </template>
    <template #option="{ option }">
      <div class="flex flex-top gap-2">
        <SvgIcon :name="!!isSelected(option) ? '24-ic-check' : '24-ic-add'" />
        <div>
          <div>{{ option.fullName }}</div>
          <div class="secondary-text">{{ option.email }}</div>
        </div>
      </div>
    </template>
    <template #no-options>{{ $t('filters.noOptionsSearch') }}</template>
    <template #no-result>{{ $t('filters.noResults') }}</template>
  </MultiSelect>
</template>
