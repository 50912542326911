<script>
export default {
  name: 'TreeLayout',
  inject: {
    preopen: {
      default: () =>
        function () {
          return {}
        },
    },
  },
  props: {
    nodeId: String,
    level: {
      type: Number,
      default: 1,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    keyPrefix: String,
    getChildren: {
      type: Function,
      default: () => function () {},
    },
    nodeClasses: {
      type: [Function, Array],
      default: () => function () {},
    },
  },
  emits: ['node-click'],
  data() {
    return {
      showChildren: this.preopen()[this.nodeId] === true ? true : this.expanded,
    }
  },
  computed: {
    modifiers() {
      if (Array.isArray(this.nodeClasses)) {
        let hash = {}
        this.nodeClasses.forEach((func) => {
          hash = { ...hash, ...func(this.nodeId) }
        })

        return hash
      }

      return this.nodeClasses(this.nodeId)
    },
    children() {
      return this.getChildren(this.nodeId)
    },

    isPreopened() {
      return this.preopen()
    },
  },
  watch: {
    isPreopened: {
      handler: function (hash) {
        this.showChildren = hash[this.nodeId] === true
      },
    },
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren
    },
  },
}
</script>

<template>
  <li
    :id="`tree-${keyPrefix}-${nodeId}`"
    :class="[
      'Tree-item',
      'level-' + level,
      {
        ...modifiers,
        'is-root': level === 1,
        'is-leaf': !children.length,
      },
    ]"
  >
    <div
      v-if="nodeId !== 'root'"
      v-test="{ node: nodeId }"
      class="Tree-label"
      @click="$emit('node-click', nodeId)"
    >
      <button
        v-if="children.length > 0"
        v-test="'expander'"
        class="Tree-expander"
        @click.stop="toggleChildren()"
      >
        <SvgIcon
          :class="{
            'is-rotated-90': !showChildren,
          }"
          name="24-ic-arrow-down"
        />
      </button>

      <slot
        :node-id="nodeId"
        :level="level"
        :modifiers="modifiers"
      />
    </div>

    <template v-if="children.length > 0 && (showChildren || nodeId === 'root')">
      <ul
        v-test="{ 'children-of': nodeId }"
        class="Tree"
      >
        <template
          v-for="childId in children"
          :key="`${keyPrefix}${childId}`"
        >
          <TreeLayout
            :node-id="childId"
            :key-prefix="keyPrefix"
            :level="nodeId === 'root' ? level : level + 1"
            :node-classes="nodeClasses"
            :expanded="expanded"
            :get-children="getChildren"
            @node-click="$emit('node-click', $event)"
          >
            <template
              v-for="slot in Object.keys($slots)"
              #[slot]="scope"
            >
              <slot
                :name="slot"
                v-bind="scope"
              />
            </template>
          </TreeLayout>
        </template>
      </ul>
    </template>
  </li>
</template>
