<template>
  <LayoutDefault
    extended-content
    class="pt-6"
  >
    <Hero>{{ $t('titles.profile') }} </Hero>

    <div class="wrapper">
      <div class="box">
        <Loading-Spinner
          v-if="isLoadingSettings"
          class="spinner--lg mx-auto my-8"
        />
        <form
          v-else
          class="grid-layout grid--half grid--multiline"
          @submit.prevent="saveSettings()"
        >
          <!-- Personal Details -->
          <div class="grid-unit">
            <fieldset class="formSection">
              <legend class="formSection-title">
                <h2>{{ $t('titles.personalDetails') }}</h2>
              </legend>
              <!-- First Name -->
              <ValidationGroup
                class="formGroup"
                :validator="v$.buffer.user.firstName"
                v-slot="{ hasAnyErrors }"
              >
                <label for="me-fname">{{ $t('profile.firstName') }}</label>
                <input
                  id="me-fname"
                  v-model="v$.buffer.user.firstName.$model"
                  type="text"
                  :placeholder="$t('profile.placeholderFirstName')"
                  class="formControl"
                  :class="{ 'is-invalid': hasAnyErrors }"
                />
              </ValidationGroup>
              <!-- Last Name -->
              <ValidationGroup
                class="formGroup"
                :validator="v$.buffer.user.lastName"
                v-slot="{ hasAnyErrors }"
              >
                <label for="me-lname">{{ $t('profile.lastName') }}</label>
                <input
                  id="me-lname"
                  v-model="v$.buffer.user.lastName.$model"
                  type="text"
                  :placeholder="$t('profile.placeholderLastName')"
                  class="formControl"
                  :class="{ 'is-invalid': hasAnyErrors }"
                />
              </ValidationGroup>
              <!-- Email -->
              <div class="formGroup">
                <label for="me-email">{{ $t('profile.username') }}</label>
                <input
                  id="me-email"
                  v-model="buffer.user.email"
                  type="text"
                  disabled
                  class="formControl"
                />
              </div>
              <!-- Job title -->
              <Validation-group
                v-if="!isWinnowUser"
                class="formGroup"
                :validator="v$.buffer.user.jobTitle"
                v-slot="{ hasAnyErrors }"
              >
                <label for="me-job-title">{{ $t('profile.labels.jobTitle') }}</label>
                <select
                  id="me-job-title"
                  v-model="v$.buffer.user.jobTitle.$model"
                  class="customSelect"
                  :class="{ 'is-invalid': hasAnyErrors }"
                  data-test-job-title-selector
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('profile.labels.selectJobTitle') }}
                  </option>
                  <option
                    v-for="job in jobTitles"
                    :key="job.title"
                    :value="job.title"
                  >
                    {{ $t(`profile.jobTitle.${job.jobKey}`) }}
                  </option>
                </select>
              </Validation-group>
            </fieldset>
          </div>

          <!-- Preferences -->
          <div class="grid-unit">
            <fieldset class="formSection">
              <legend class="formSection-title">
                <h2>{{ $t('titles.preferences') }}</h2>
              </legend>
              <!-- Languages -->
              <div class="formGroup">
                <div class="flex flex-between flex-middle">
                  <label for="me-selectLanguage">{{ $t('profile.language') }}</label>
                  <small class="text-acai t-helper">{{ $t('profile.selectLanguage') }}</small>
                </div>
                <select
                  id="me-selectLanguage"
                  v-model="languageSelectedKey"
                  data-test-language-selector
                  class="customSelect"
                  @change="analytics.track('Profile - Changed language')"
                >
                  <option
                    v-for="{ key, value } in settings.locale"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
              <!-- Menu language -->
              <fieldset class="formGroup mb-3">
                <legend class="label">{{ $t('profile.menuLanguage') }}</legend>
                <div class="customControl customControl--radio">
                  <input
                    id="localLanguage"
                    class="customControl-input"
                    v-model="preferredMenuLanguageKey"
                    type="radio"
                    name="menuLanguage"
                    :value="preferredMenuLanguageOptions.local.key"
                    data-test-local-language
                  />
                  <label
                    for="localLanguage"
                    class="customControl-label"
                    >{{ $t('profile.preferLocalName') }}</label
                  >
                </div>
                <div class="customControl customControl--radio">
                  <input
                    id="systemTranslation"
                    class="customControl-input"
                    v-model="preferredMenuLanguageKey"
                    type="radio"
                    name="menuLanguage"
                    :value="preferredMenuLanguageOptions.system.key"
                    data-test-system-translation
                  />
                  <label
                    for="systemTranslation"
                    class="customControl-label"
                    >{{ $t('profile.preferSystemTranslation') }}</label
                  >
                </div>
              </fieldset>
              <!-- Weight units -->
              <div class="formGroup mt-2">
                <label for="me-selectWeight">{{ $t('profile.weightUnits') }}</label>
                <select
                  id="me-selectWeight"
                  v-model="weightSelectedKey"
                  class="customSelect"
                  @change="
                    analytics.track('Profile - Changed weight units', { weight: weightSelectedKey })
                  "
                >
                  <option
                    v-for="{ key, value } in settings.weight"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
              <!-- Temperature units -->
              <div class="formGroup">
                <label for="me-selectTemperature">{{ $t('profile.temperatureUnits') }}</label>
                <select
                  id="me-selectTemperature"
                  v-model="temperatureSelectedKey"
                  class="customSelect"
                  @change="
                    analytics.track('Profile - Changed temperature units', {
                      temperature: temperatureSelectedKey,
                    })
                  "
                >
                  <option
                    v-for="{ key, value } in settings.temperature"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
            </fieldset>
          </div>

          <!-- Form actions -->
          <div class="grid-unit grid-unit--isFullWidth">
            <div class="buttonGroup buttonGroup--halfs flex-right">
              <!-- Reset button -->
              <button
                v-test="'reset'"
                class="button button--secondary"
                :disabled="isSaving"
                @click.prevent="revert()"
              >
                <SvgIcon name="24-ic-clear" />
                <span>{{ $t('actions.revert') }}</span>
              </button>
              <!-- Submit button -->
              <ButtonSave
                data-test-save
                :is-saving="isSaving"
              />
            </div>
          </div>
        </form>
      </div>

      <!-- Organisations -->
      <div class="box">
        <Loading-Spinner
          v-if="isLoadingOrganisations"
          class="spinner--lg mx-auto my-8"
        />
        <section v-else>
          <h2 class="formSection-title">{{ $t('titles.organisations') }}</h2>
          <div
            v-if="selectedSite"
            class="d-table text-acai"
          >
            <!-- Role -->
            <div class="d-table-tr">
              <div class="d-table-td d-table-td--icon">
                <SvgIcon name="shield" />
              </div>
              <div class="d-table-td d-table-td--label font-bold">{{ $t('titles.role') }}:</div>
              <div class="d-table-td">
                <div v-test="'role'">{{ rolesString }}</div>
              </div>
            </div>
          </div>
          <p v-else>{{ $t('titles.noSiteSelected') }}</p>
        </section>
      </div>

      <!-- Security & Privacy -->
      <div class="box">
        <div class="grid-layout grid--half">
          <div class="grid-unit">
            <section
              class="formSection"
              v-if="userMetadata.ssoUser"
              data-test-sso-security
            >
              <h2 class="formSection-title">{{ $t('titles.security') }}</h2>
              <p class="text-acai mb-4">
                {{ $t('profile.security.ssoResetPassword') }}
              </p>
            </section>
            <section
              class="formSection"
              v-else
            >
              <h2 class="formSection-title">{{ $t('titles.security') }}</h2>
              <p class="text-acai mb-4">{{ $t('actions.resetYourPasswordIf') }}</p>
              <ButtonWithSpinner
                class="button--primary button--small-screen-full-width"
                :in-progress="resetEmailLoading"
                @click.prevent="resendLogin()"
                spinner-class="spinner--white"
              >
                <template #icon><SvgIcon name="24-ic-repeat" /></template>
                {{ $t('actions.resetPassword') }}
              </ButtonWithSpinner>
              <p
                v-if="resetEmailSent"
                class="text-mint mt-3"
              >
                {{ $t('profile.security.resetPasswordEmailSent') }}
              </p>
              <p
                v-if="resetEmailError"
                class="text-apple mt-3"
              >
                {{ $t('profile.security.resetPasswordEmailFailed') }}
              </p>
            </section>
          </div>
          <div class="grid-unit">
            <section class="formSection">
              <h2 class="formSection-title">{{ $t('titles.privacy') }}</h2>
              <div class="formGroup">
                <p
                  class="mb-4"
                  v-html="$t('profile.GDPRStatus')"
                ></p>
                <p
                  class="text-acai"
                  v-html="$t('profile.GDPRConsentRevoke')"
                ></p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </LayoutDefault>
</template>

<script>
import { mapGetters, mapState, useStore } from 'vuex'
import { computed } from 'vue'
import { useSelectedSite } from '@/composables/useSelectedSite'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import { required, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import axios from '@/store/api-axios'
import { loadLanguageAsync } from '@/languages/index'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import ButtonSave from '@/components/ButtonSave.vue'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
export default {
  name: 'ProfilePage',
  components: {
    LayoutDefault,
    Hero,
    ValidationGroup,
    ButtonSave,
    ButtonWithSpinner,
  },
  setup: () => {
    const { getters } = useStore()
    const userId = computed(() => getters['auth/userId'])
    const { selectedSite } = useSelectedSite({ userId })
    return {
      v$: useVuelidate(),
      toast: useToast(),
      selectedSite,
    }
  },
  data() {
    return {
      isLoadingSettings: true,
      buffer: null,
      isSaving: false,
      languageSelectedKey: null,
      temperatureSelectedKey: null,
      weightSelectedKey: null,
      preferredMenuLanguageKey: null,
      resetEmailSent: false,
      resetEmailError: false,
      resetEmailLoading: false,
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'userSettings',
      'userMetadata',
      'settings',
      'rolesString',
      'jobTitles',
      'isWinnowUser',
    ]),
    ...mapState('auth/organisations', {
      isLoadingOrganisations: (state) => state.loadingStatus === 'LOADING',
    }),
    ...mapGetters('auth', ['keycloakUserId']),
    preferredMenuLanguageOptions() {
      return {
        local: (this.settings.preferredMenuLanguage || []).find(
          (option) => option.code === 'LOCAL_NAME'
        ),
        system: (this.settings.preferredMenuLanguage || []).find(
          (option) => option.code === 'SYSTEM_TRANSLATION'
        ),
      }
    },
  },
  created() {
    Promise.all([
      this.$store.dispatch('auth/getSettings'),
      this.$store.dispatch('auth/getJobTitles'),
      this.$store.dispatch('auth/organisations/getAllowedSites'),
    ])
      .then(() => {
        this.setupViewModel()

        // if we put this in .finally() things will break in case of errors
        this.isLoadingSettings = false
      })
      .catch(() => {
        this.toast.error(this.$t('toast.error.profile.getSettings'))
      })
  },
  validations() {
    return {
      buffer: {
        user: {
          firstName: {
            requiredFirstName: required,
          },
          lastName: {
            requiredLastName: required,
          },
          jobTitle: {
            required: requiredIf(!this.isWinnowUser),
          },
        },
      },
    }
  },
  methods: {
    generateBuffer() {
      this.buffer = {
        user: { ...this.user },
        userSettings: { ...this.userSettings },
        userMetadata: { ...this.userMetadata },
      }
    },
    setupViewModel() {
      this.generateBuffer()

      this.languageSelectedKey = this.buffer.userSettings.locale.key
      this.temperatureSelectedKey = this.buffer.userSettings.temperature.key
      this.weightSelectedKey = this.buffer.userSettings.weight.key
      this.preferredMenuLanguageKey = this.buffer.userSettings.preferredMenuLanguage.key
    },
    revert() {
      this.analytics.track('Profile - Revert changes')
      this.setupViewModel()
    },
    saveSettings() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.analytics.track('Profile - Save changes')
      this.isSaving = true

      // set the correct data object on the buffer based on the language, temperature, weight and preferred menu language selections
      this.buffer.userSettings.locale = this.settings.locale.find(
        (settings) => settings.key === this.languageSelectedKey
      )

      this.buffer.userSettings.temperature = this.settings.temperature.find(
        (settings) => settings.key === this.temperatureSelectedKey
      )

      this.buffer.userSettings.weight = this.settings.weight.find(
        (settings) => settings.key === this.weightSelectedKey
      )

      this.buffer.userSettings.preferredMenuLanguage = this.settings.preferredMenuLanguage.find(
        (settings) => settings.key === this.preferredMenuLanguageKey
      )

      this.$store
        .dispatch('auth/saveSettings', this.buffer)
        .then(async () => {
          const locale = this.$store.getters['auth/locale']
          await loadLanguageAsync(locale)
          moment.locale(locale)
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
        .finally(() => {
          this.isSaving = false
        })
      this.$forceUpdate()
    },
    resendLogin() {
      this.resetEmailSent = false
      this.resetEmailError = false
      this.resetEmailLoading = true
      this.analytics.track('Profile - Resend login details')
      axios({
        method: 'put',
        url: `${import.meta.env.VUE_APP_KEYCLOAK_URL}/admin/realms/${
          import.meta.env.VUE_APP_KEYCLOAK_REALM
        }/users/${this.keycloakUserId}/execute-actions-email`,
        params: {
          redirect_uri: import.meta.env.VUE_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
          client_id: import.meta.env.VUE_APP_KEYCLOAK_CLIENT_ID,
        },
        data: ['UPDATE_PASSWORD'],
      })
        .then(() => {
          this.resetEmailSent = true
        })
        .catch(() => {
          this.resetEmailError = true
        })
        .finally(() => {
          this.resetEmailLoading = false
        })
    },
  },
}
</script>
