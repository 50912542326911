export default (el, binding) => {
  if (import.meta.env.DEV || import.meta.env.VUE_APP_FORCE_TEST_HOOKS) {
    if (typeof binding.value === 'string') {
      el.setAttribute(`data-test-${binding.value}`, '')
    } else {
      Object.keys(binding.value).forEach((value) => {
        el.setAttribute(`data-test-${value}`, binding.value[value])
      })
    }
  }
}
