<template>
  <div
    role="region"
    aria-labelledby="organisation-browser-title"
    data-test-hierarchy-browser
    @keydown.esc="triggerMoveCancel"
  >
    <div
      class="org-browser-header"
      :class="{ 'org-browser-header--moving': isBrowserOpen && movingNodeId }"
    >
      <div class="flex flex-middle w-min-0">
        <Collapsible-toggle
          :open="isBrowserOpen"
          @open="isBrowserOpen = true"
          @close="isBrowserOpen = false"
        />
        <slot
          name="title"
          :is-open="isOpen"
        >
          <h2
            id="organisation-browser-title"
            :class="['mx-1 text-base', { 'sr-only': !isBrowserOpen || movingNodeId }]"
          >
            {{ $t('hierarchy.title.browser') }}
          </h2>
          <div
            v-if="movingNodeId"
            class="mx-1 text-base"
          >
            {{ $t('hierarchy.message.nodeToBeMoved', { name: movingNode.name }) }}
          </div>
        </slot>

        <OrgPicker
          v-if="!isBrowserOpen"
          ref="picker"
          :selected-node-id="internalSelectedId"
          :show-archived="false"
          aria-labelledby="organisation-browser-title"
          @node-select="$emit('node-select', $event)"
        />
      </div>
      <div class="flex flex-middle -mx-1">
        <div
          v-if="isBrowserOpen && archivedAvailable"
          class="mx-1 customControl customControl--checkbox"
        >
          <input
            id="hierachy-show-archived"
            v-model="showArchived"
            data-hierarchy-show-archived
            type="checkbox"
            class="customControl-input"
          />
          <label
            class="customControl-label"
            for="hierachy-show-archived"
          >
            {{ $t('action.showArchived') }}
          </label>
        </div>

        <CopyToClipboard
          v-if="!isBrowserOpen"
          class="mx-1"
          :model-value="() => selectedNode.qualifiedName"
          :success-message="$t('organisations.messages.copyPath')"
        />

        <OrgFilter
          id="filter-nodes-browser-closed"
          class="mx-1"
          @on-select="onSearch"
        />
      </div>
    </div>

    <Collapsible-content :is-open="isBrowserOpen">
      <OrgNavigator
        ref="navigator"
        class="-mx-3 -mt-3"
        :selected-node-id="movingNodeId || internalSelectedId"
        :focused-node-id="internalFocusedId"
        :custom-selected-node="movingNode"
        :disabled-levels="movingNodeId ? [movingNode.level] : []"
        :show-archived="archivedAvailable && showArchived"
        aria-labelledby="OrganisationBrowser"
        @node-focus="changeFocusedNode"
        @node-select="changeSelectedNode"
        @level-index-change="changeFocusedLevel"
      >
        <template #default="{ accessibleLevels }">
          <OrgNavigator-column
            v-for="level in accessibleLevels"
            :key="level.level"
            :ref="`level-${level.level}`"
            :selected-node-id="movingNodeId || internalSelectedId"
            :highlighted-node-id="level.highlightedNodeId"
            :focused-node-id="internalFocusedId"
            :parent-id="level.parentId"
            :custom-selected-node="movingNode"
            :level-number="level.level"
            :next-level="level.nextLevel"
            :has-more="level.hasMore"
            :disabled-nodes="
              movingNodeId && level.level === movingNode.level ? ['MORE', 'NODES'] : []
            "
            :allow-create="isEditable"
            :show-archived="showArchived"
            @node-focus="changeFocusedNode"
            @node-select="changeSelectedNode"
          >
            <template #top>
              <div
                v-if="showMoveInvalidTarget(level)"
                id="invalid-move-target"
                class="flex flex-middle gap-1 org-browser-move-warning"
              >
                <SvgIcon name="24-ic-warning" />
                <span data-test-move-node-warning>{{ $t('hierarchy.message.cannotMove') }}</span>
              </div>
            </template>
            <template #default="{ nodes, nextLevelId }">
              <!-- Template node when moving
                Should be visible as target and as source
              -->
              <OrgNavigator-node
                v-if="canMoveToLevel(level.level)"
                :is-selected="true"
                :node="movingNode"
                :parent-id="level.parentId"
                role="treeitem"
              />
              <!-- Regular node -->
              <OrgNavigator-node
                v-for="node in nodes"
                :id="node.id"
                :key="node.id"
                :ref="`node-${node.id}`"
                :is-selected="node.id === movingNodeId || node.id === internalSelectedId"
                :is-highlighted="node.id === level.highlightedNodeId"
                :is-archived="node.archived"
                :allow-create="isEditable && level.level > 0 && level.level < 16"
                :tabindex="node.id === internalFocusedId ? 0 : -1"
                :disabled="disableNode(node)"
                role="treeitem"
                v-bind="{
                  'aria-selected': node.id === internalSelectedId,
                  'aria-describedby': disableNode(node)
                    ? `invalid-move-target`
                    : `level-${level.level}-title`,
                  'aria-expanded': disableNode(node)
                    ? false
                    : level.nextLevel && node.id === level.highlightedNodeId,
                  'aria-owns': disableNode(node)
                    ? undefined
                    : level.nextLevel && internalSelectedId && `level-${level.nextLevel}`,
                }"
                @node-focus="changeFocusedNode"
                @node-select="changeSelectedNode"
              />

              <!-- More on L[x] node -->
              <OrgNavigator-node
                v-if="level.hasMore"
                :ref="`node-${nextLevelId}`"
                :is-selected="internalSelectedId === nextLevelId"
                :is-highlighted="level.highlightedNodeId === nextLevelId"
                :tabindex="internalFocusedId === nextLevelId ? 0 : -1"
                :disabled="movingNodeId && movingNode.level === level.level"
                role="treeitem"
                v-bind="{
                  'aria-expanded': nextLevelId === level.highlightedNodeId,
                  'aria-owns': internalSelectedId && `level-${level.nextLevel}`,
                }"
                @node-focus="changeFocusedNode(nextLevelId)"
              >
                <div>
                  {{ $t('hierarchy.fakeNode.title', { level: level.nextLevel }) }}
                </div>
                <div class="text-sm">{{ $t('hierarchy.fakeNode.subtitle') }}</div>
              </OrgNavigator-node>
            </template>
          </OrgNavigator-column>
        </template>
      </OrgNavigator>
      <div class="-mx-1 flex mt-3 hierarchy-browser-bottom">
        <div class="hierarchy-browser-selected-sequence flex flex-middle flex-expand mx-1">
          <CopyToClipboard
            class="mr-2"
            :model-value="() => focusedNodeQualifiedName"
            :success-message="$t('organisations.messages.copyPath')"
          />
          <output
            data-hierarchy-qualified-name
            form="hierarchy-levels-form"
            >{{ focusedNodeQualifiedName }}
          </output>
        </div>
        <!-- Buttons Bar -->
        <div class="flex -mx-2">
          <OrgMoveActions
            v-if="focusedNodeCanBeMoved || !!movingNodeId"
            ref="move-actions"
            :node-id="internalFocusedId"
            :moving-node-id="movingNodeId"
            @move="onMove"
            @move-success="onMoveSuccess"
            @move-cancel="cancelMove"
            @validate-target="onValidateTargetForMoving"
          />
          <button
            v-if="!movingNodeId"
            type="button"
            :disabled="!focusedNodeCanBeOpened"
            class="mx-2 button button--primary"
            data-test-open-node
            @click="changeSelectedNode(internalFocusedId)"
          >
            {{ $t('actions.open') }}
          </button>
        </div>
      </div>
    </Collapsible-content>
  </div>
</template>

<script>
import CollapsibleToggle from '@/components/CollapsibleToggle.vue'
import CollapsibleContent from '@/components/CollapsibleContent.vue'
import OrgPicker from '@/components/OrgBrowser/OrgPicker.vue'
import OrgNavigator from '@/components/OrgBrowser/OrgNavigator.vue'
import OrgNavigatorColumn from '@/components/OrgBrowser/OrgNavigatorColumn.vue'
import OrgNavigatorNode from '@/components/OrgBrowser/OrgNavigatorNode.vue'
import OrgMoveActions from '@/components/OrgBrowser/OrgMoveActions.vue'
import OrgFilter from '@/components/OrgBrowser/OrgFilter.vue'
import CopyToClipboard from '@/components/CopyToClipboard.vue'

import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
export default {
  components: {
    CollapsibleToggle,
    CollapsibleContent,
    OrgPicker,
    OrgNavigator,
    OrgNavigatorColumn,
    OrgNavigatorNode,
    OrgMoveActions,
    OrgFilter,
    CopyToClipboard,
  },
  props: {
    selectedNodeId: {
      type: [String, Number],
    },
    focusedNodeId: {
      type: [String, Number],
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    archivedAvailable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['node-select', 'node-focus'],
  data() {
    return {
      movingNodeId: null,
      internalFocusedId: this.focusedNodeId,
      internalSelectedId: this.selectedNodeId,
      movingNode: null,
      isBrowserOpen: this.isOpen,
      moveTargetIsValid: false,
      creatingNodeOnLevel: null,
      showArchived: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('hierarchy/nodes', {
      nodeById: 'byId',
    }),
    rootNodeId() {
      return this.user.subjectOrganization
    },
    focusedNode() {
      return this.nodeById(this.internalFocusedId)
    },
    selectedNode() {
      return this.nodeById(this.internalSelectedId || this.rootNodeId)
    },
    focusedNodeQualifiedName() {
      if (this.focusedNode) {
        return this.focusedNode.qualifiedName
      }
      return null
    },
    focusedNodeCanBeMoved() {
      // we can't move if we don't have a focused node
      if (!this.focusedNode) return false
      // we can't move if the focused node is the root node for the user
      if (this.internalFocusedId === this.rootNodeId) return false
      // we can't move if the focused node is different than the focused id which can also be a "more" button
      if (this.focusedNode.id !== this.internalFocusedId) return false
      // we can move if the component has been made editable
      if (this.isEditable) return true

      return false
    },
    focusedNodeCanBeOpened() {
      // node can't be opened if it's already open
      if (this.internalSelectedId === this.internalFocusedId) return false
      // also can't open if there isn't something focused
      if (!this.focusedNode) return false

      return true
    },
  },
  watch: {
    focusedNodeId: {
      handler(id) {
        this.internalFocusedId = id || this.selectedNodeId || this.rootNodeId
      },
      immediate: true,
    },
    selectedNodeId: {
      handler(id) {
        this.internalSelectedId = id || this.rootNodeId
      },
      immediate: true,
    },
    isBrowserOpen(isOpen) {
      if (isOpen) this.$emit('browser-expand')
      else this.$emit('browser-collapse')
      if (!isOpen && this.movingNodeId) {
        this.triggerMoveCancel()
      }
    },
  },
  methods: {
    changeSelectedNode(id) {
      if (this.movingNodeId) {
        this.$refs['move-actions'].selectTarget()
      } else {
        this.changeFocusedNode(id)
        if (this.focusedNodeCanBeOpened) {
          this.internalSelectedId = id
          this.$emit('node-select', id)
        }
      }
    },

    canMoveToLevel(level) {
      // we can only move nodes to the same level as they started on
      return this.moveTargetIsValid && level == this.movingNode.level
    },

    disableNode(node) {
      if (!this.movingNodeId) return false
      // disable nodes on same level with the node being moved
      else return this.movingNode.level <= node.level
    },

    // these trigger focus on columns which will trigger the appropriate node to focus
    changeFocusedLevel(levelNumber) {
      let $ref = this.$refs[`level-${levelNumber}`]
      if ($ref[0]) {
        $ref[0].focus()
      }
    },

    showMoveInvalidTarget(level) {
      if (!this.movingNodeId) return false
      return !level.highlightedNodeId && level.level !== this.movingNode.level
    },
    changeFocusedNode(id) {
      if (this.internalFocusedId !== id) {
        this.$emit('node-focus', id)
      }
      this.internalFocusedId = id
      if (this.movingNodeId && id !== this.movingNode.id) {
        this.movingNode.parentId = id
      }

      this.$nextTick(() => {
        if (this.$refs[`node-${id}`] && this.$refs[`node-${id}`].length) {
          this.$refs.navigator.scrollToEndOfRow()
          this.$refs[`node-${id}`][0].$el.focus()
        }
      })
    },
    onSearch(id) {
      // we need to ensure the tree exists when emiting the searched node
      // this is to verify if the node can be opened
      // the verification and emitting is built into the `changeSelectedNode` method
      if (this.$refs['picker']) {
        this.changeSelectedNode(id)
        if (!this.focusedNode) {
          this.$refs['picker'].getTree(id).then(() => {
            this.changeSelectedNode(id)
          })
        }
      }
      if (this.$refs['navigator']) {
        this.changeSelectedNode(id)
        if (!this.focusedNode) {
          this.$refs['navigator'].getTree(id).then(() => {
            this.changeSelectedNode(id)
          })
        }
      }
    },
    onMove(id) {
      this.movingNodeId = id
      this.movingNode = cloneDeep(this.nodeById(id))
      this.changeFocusedNode(this.movingNode.id)
    },
    onMoveSuccess(id) {
      this.cancelMove(id)
    },
    cancelMove(previousId) {
      this.movingNodeId = null
      this.movingNode = null
      this.moveTargetIsValid = false
      this.changeFocusedNode(previousId)
    },
    onValidateTargetForMoving(isValid) {
      this.moveTargetIsValid = isValid
    },
    triggerMoveSelectTarget() {
      if (this.movingNodeId) {
        this.$refs['move-actions'].selectTarget()
      }
    },
    triggerMoveCancel() {
      if (this.movingNodeId) {
        this.$refs['move-actions'].cancelMove()
      }
    },
  },
}
</script>

<style lang="scss">
.org-browser-header {
  border-radius: theme('borderRadius.lg') theme('borderRadius.lg') 0 0;
  display: flex;
  align-items: middle;
  justify-content: space-between;
  padding: theme('spacing.2') theme('spacing.4');
}

.org-browser-header--moving {
  background-color: theme('colors.acai.DEFAULT');
  color: theme('colors.white');

  button,
  label {
    color: inherit;
  }
}

.org-browser-move-warning {
  padding: theme('spacing.1');
  background-color: theme('colors.carrot.DEFAULT');
  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  color: theme('colors.white');
}
</style>
