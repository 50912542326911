import axios, { spitURL } from '../api-axios'
import { BASE_URLS } from '@/store/constants'
import mapSiteBackdate from './mappings/get-site-backdate'
import mapBackdateProcessStatus from './mappings/get-backdate-process-status'
const { BACKDATE } = BASE_URLS

export default {
  fetchBackdateSites({ menuId, query }) {
    return axios
      .get(spitURL(`${BACKDATE}/${menuId}`, query))
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((data) => ({
        sites: data.sites.map(mapSiteBackdate),
      }))
  },
  postBackdateSites(payload) {
    return axios
      .post(BACKDATE, payload)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapBackdateProcessStatus)
  },
  fetchProcessingStatusForMenuId(menuId) {
    return axios
      .get(`${BACKDATE}/status/${menuId}`)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((data) => data.map(mapBackdateProcessStatus))
  },
}
