import api from '@/store/api/taxonomy'
import terms from './terms'
import facets from './facets'

const state = {
  options: {
    termTypes: [],
    detailLevels: [],
    loading: false,
    error: false,
  },
}

const getters = {
  termTypes: (state) => state.options.termTypes,
  termLevels: (state) => state.options.detailLevels,
  getTermTypeById: (state, getters) => (id) => {
    return getters.termTypes.find((el) => el.id === id).name
  },
  getDetailLevelById: (state, getters) => (id) => {
    const obj = getters.termLevels.find((el) => el.id === id)
    return obj ? obj.name : null
  },
}

const actions = {
  getTermOptions({ commit }) {
    commit('OPTIONS_LOADING_START')
    return new Promise((resolve, reject) => {
      api
        .fetchTermOptions()
        .then((response) => {
          const { termTypes, detailLevels } = response.data.data
          commit('TYPES_SET_DATA', termTypes)
          commit('LEVELS_SET_DATA', detailLevels)
          resolve()
        })
        .catch((error) => {
          commit('OPTIONS_SET_ERROR', error)
          reject(error)
        })
        .finally(() => {
          commit('OPTIONS_LOADING_END')
        })
    })
  },
}

const mutations = {
  TYPES_SET_DATA(state, items) {
    state.options.termTypes = items
  },
  LEVELS_SET_DATA(state, items) {
    state.options.detailLevels = items
  },

  OPTIONS_SET_ERROR(state, error) {
    state.options.error = error
  },
  OPTIONS_LOADING_START(state) {
    state.options.loading = true
  },
  OPTIONS_LOADING_END(state) {
    state.options.loading = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    terms,
    facets,
  },
}
