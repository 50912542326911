<script>
import { mapGetters } from 'vuex'

import NodeGeneric from '@/components/NodeGeneric.vue'
import Toggle from '@/components/Toggle/Toggle.vue'

export default {
  components: {
    Toggle,
    NodeGeneric,
  },
  props: {
    facetId: String,
    onSelect: {
      type: Function,
      default: () => function () {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('taxonomy/facets', ['byId', 'childIds']),
  },
  methods: {
    actualName(record) {
      return record.displayName || record.termName
    },
  },
}
</script>

<template>
  <Toggle class="filterDropdown">
    <template #trigger="{ isOpen }">
      <button class="button button--tertiary gap-1">
        <span>{{ facetId ? actualName(byId(facetId)) : $t('taxman.allFacets') }}</span>
        <SvgIcon
          :class="['inline', { 'rotate-180': isOpen }]"
          name="24-ic-arrow-down"
        />
      </button>
    </template>
    <template #content="{ toggle }">
      <ul>
        <li class="ResultNode">
          <button
            class="ResultNode-item px-3 with-separator"
            @click.prevent="
              () => {
                onSelect(null)
                toggle()
              }
            "
          >
            {{ $t('taxman.allFacets') }}
          </button>
        </li>
        <template
          v-for="id in childIds('root')"
          :key="id"
        >
          <li class="ResultNode">
            <button
              class="ResultNode-item px-3"
              @click.prevent="
                () => {
                  onSelect(id)
                  toggle()
                }
              "
            >
              <NodeGeneric :record="byId(id)" />
            </button>
          </li>
        </template>
      </ul>
    </template>
  </Toggle>
</template>

<style lang="scss">
.filterDropdown {
  position: relative;
  display: flex;
  flex-direction: column;

  .Toggle-trigger {
    flex: 1 0 100%;
    border-left: 1px solid theme('colors.slate.DEFAULT');
    border-right: 1px solid theme('colors.slate.DEFAULT');

    // yuck
    button {
      padding-left: theme('spacing.2');
      padding-right: theme('spacing.3');
      height: 100%;
      color: theme('colors.acai.DEFAULT');
      text-decoration: none;
    }

    .icon {
      transition: none;
    }
  }

  .Toggle-content {
    margin-top: theme('spacing.px');
    position: absolute;
    top: 100%;
    left: 0;
    min-width: to-rem(300px);
    background: theme('colors.white');
    z-index: $zindex-sticky;
    box-shadow: 0 2px 6px rgb(0, 0, 0, 0.25);

    max-height: calc((theme('spacing.10')) * 10 + 1px);
    overflow-y: auto;
  }
}

button.with-separator {
  border-bottom: 1px solid theme('colors.grey.200');
}
</style>
