<template>
  <div class="mt-8">
    <loaded-content
      :is-loading="isLoading"
      :has-error="hasError"
      :has-data="!!similarMappings.length"
    >
      <table-responsive>
        <thead-generator :headers="headers" />
        <transition-group
          v-test="'results'"
          name="fade"
          tag="tbody"
        >
          <template
            v-for="item in similarMappings"
            :key="item.id"
          >
            <food-mapping-similar-row
              v-test="'item'"
              :item="item"
              :food-item="foodItem"
              @select="onSelect(item)"
              @deselect="onDeselect"
            />
          </template>
        </transition-group>
      </table-responsive>
    </loaded-content>
  </div>
</template>
<script>
import TheadGenerator from '@/components/TheadGenerator/TheadGenerator.vue'
import TableResponsive from '@/components/TableResponsive/TableResponsive.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import FoodMappingSimilarRow from './FoodMappingSimilarRow.vue'

export default {
  components: {
    TheadGenerator,
    TableResponsive,
    LoadedContent,
    FoodMappingSimilarRow,
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('reset-code')
    next()
  },
  props: {
    foodItemId: String,
  },
  emits: ['reset-code', 'new-mapping'],
  data() {
    return {
      isLoading: true,
      hasError: false,
    }
  },
  computed: {
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
    similarMappings() {
      return this.$store.getters['menu-management/similar-mappings/similarMappingsByIds'](
        this.foodItem.similarMappingIds
      )
    },
    headers() {
      return [
        { id: 'th0', title: 'Food Item' },
        { id: 'th1', title: 'Interpreted' },
        { id: 'th2', title: 'Match' },
        { id: 'th3', title: 'Actions' },
      ]
    },
  },
  created() {
    this.$store
      .dispatch('menu-management/similar-mappings/getSimilarMappings', {
        foodItemId: this.foodItemId,
      })
      .then(() => {
        this.isLoading = false
      })
      .catch(() => {
        this.hasError = true
      })
  },
  methods: {
    onSelect(item) {
      this.$emit('new-mapping', {
        code: item.taxonomy.code,
        mapping: item.taxonomy.mapping,
        // send a true term value so that the save actions can be shown.
        // having a term is a minimum requirement for a valid code without which a code can't be saved
        // we can't get an actual term object because that would mean decoding all similar mapping codes in the list
        // this is not practical at this point in time as the object itself is used just as a truthy check.
        // we are inferring that the term is `true` because all similar mappings should have `approved` status
        term: true,
      })
      this.scrollToTop()
    },
    onDeselect() {
      this.$emit('reset-code')
      this.scrollToTop()
    },
    scrollToTop() {
      document.body.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    },
  },
}
</script>
