export const normalizeTransactionHistory = (data) => {
  const fieldsTranslationsKeysMap = {
    foodItem: 'titles.foodItem',
    stage: 'titles.stage',
    customBreakdown: 'titles.custom1',
    cost: 'titles.value',
    type: 'titles.type',
    weight: 'titles.weight',
  }
  /**
   * picks the changed value based on a provided key and change
   * @returns {String | Number | null}
   */
  const pickChangedValue = (key, change) => {
    if (change[key] !== null && typeof change[key] === 'object') {
      return change[key].name
    }
    return change[key]
  }

  return data.map((action) => ({
    ...action,
    changes:
      // in case of delete or restore changes are null
      action.changes &&
      action.changes.map((change) => {
        const { field } = change
        const newValue = pickChangedValue('new', change)
        const prevValue = pickChangedValue('old', change)

        let newValTranslationKey = ''

        /*
          There should be no such case when both old and new values to be "null". 
          In case the old value is "null" the change will reflect that a new field was added,
          In case the new value is "null" the change will reflect that the field was removed
          Otherwise, the field was present and the value was edited
        */

        if (prevValue === null) {
          newValTranslationKey = 'wasteHistory.update.add'
        } else if (newValue === null) {
          newValTranslationKey = 'wasteHistory.update.remove'
        } else {
          newValTranslationKey = 'wasteHistory.update.edit'
        }

        return {
          ...change,
          title: {
            translationKey: newValTranslationKey,
            value: newValue,
            fieldTranslationKey: fieldsTranslationsKeysMap[field],
          },
          subtitle: {
            translationKey: prevValue !== null ? 'wasteHistory.update.previousVal' : '',
            value: prevValue,
          },
        }
      }),
  }))
}
