<template>
  <LayoutDefault>
    <template #siteNav>
      <SiteSelector @loaded="handleSitesLoaded" />
    </template>

    <Hero>
      <template #default>
        {{ $t($route.name === 'waste-log-list' ? 'titles.wasteLog' : 'titles.wasteGallery') }}
      </template>
      <template
        #hero-content
        v-if="dateFrom && dateTo && selectedSite"
      >
        <i18n-t
          v-if="timeZone"
          keypath="messages.customTimeZone"
          tag="p"
          class="WL-timezone mb-2 mx-4"
          scope="global"
        >
          <span>{{ timeZone }}</span>
        </i18n-t>
        <div
          v-if="!timeZone"
          class="WL-timezone mb-4 alert alert--neutral"
        >
          <SvgIcon name="24-ic-info"></SvgIcon>
          <span>{{ $t('messages.customTimeZoneNotSupported') }}</span>
        </div>
      </template>
    </Hero>

    <div class="wrapper">
      <div class="box WL-box">
        <LoadedContent
          :is-loading="isLoadingSites"
          :has-data="!!selectedSite"
          :has-error="false"
        >
          <template v-if="selectedSite">
            <div class="WL-dateTime">
              <div class="flex">
                <Datepicker
                  v-if="dateFrom && dateTo"
                  class="WL-datePicker"
                  :date-from="dateFrom"
                  :date-to="dateTo"
                  :date-format="dateFormat"
                  :end-date="maxDate"
                  input-classes="formControlPlaintext customSelect"
                  input-id="dateRangePicker"
                  :input-placeholder="$t('actions.selectDateRange')"
                  :input-label="$t('actions.selectDateRange')"
                  :timezone="timeZone"
                  @select-date-range="applyDateRange"
                />
                <ButtonWithSpinner
                  v-if="!isLoading && $route.name !== 'waste-log-grid' && hasResults"
                  class="button--secondary flex-fixed ml-2 WL-export"
                  spinner-class="spinner--white"
                  :in-progress="isDownloadingWaste"
                  @click.prevent="download()"
                >
                  {{ $t('actions.export') }}
                </ButtonWithSpinner>
              </div>

              <BulkActions
                :items="selectedItems"
                @handle-delete="handleDelete"
              />

              <div class="flex -mx-1 flex-1 flex-right items-center">
                <router-link
                  data-test-wl-list-view-button
                  :title="$t('waste.actions.viewAsList')"
                  :active-class="'button--primary button--inverted'"
                  :class="['button', 'button--icon', 'mx-1']"
                  :to="{
                    name: 'waste-log-list',
                    params: $route.params,
                    query: $route.query.page ? { ...$route.query, page: undefined } : $route.query,
                  }"
                >
                  <SvgIcon name="24-ic-list" />
                  <span class="sr-only">{{ $t('waste.actions.viewAsList') }}</span>
                </router-link>
                <router-link
                  data-test-wl-grid-view-button
                  :title="$t('waste.actions.viewAsGrid')"
                  :active-class="'button--primary button--inverted'"
                  :class="['button', 'button--icon', 'mx-1']"
                  :to="{
                    name: 'waste-log-grid',
                    params: $route.params,
                    query: $route.query.page ? { ...$route.query, page: undefined } : $route.query,
                  }"
                >
                  <SvgIcon name="24-ic-cards" />
                  <span class="sr-only">{{ $t('waste.actions.viewAsGrid') }}</span>
                </router-link>
                <router-link
                  class="button button--primaryTransparent mx-1"
                  :to="{
                    name: 'waste-timeline',
                    params: {
                      siteId: selectedSite.legacyId,
                    },
                    query: { start: $route.query.start, end: $route.query.end },
                  }"
                >
                  <SvgIcon name="24-ic-gallery" />
                  <span>{{ $t('waste.actions.viewAsTimeline') }}</span>
                </router-link>
              </div>
            </div>

            <AdvancedFilters
              :preopen-filters="preopenFilters"
              :weight-unit="userSettings.weight.code"
              :currency="selectedSite ? selectedSite.currency : ''"
              :meta="meta ? meta.filters : {}"
              :filters="{
                minWeight,
                maxWeight,
                minCost,
                maxCost,
                types,
                foodItems,
                stages,
                trackers,
                categorised,
                status,
              }"
              @submit="applyFilters($event)"
            />

            <div class="section-header">
              <div class="section-navigation-tabs section-navigation-tabs-row">
                <TabsLvl2Links class="tabs--WL">
                  <li
                    v-for="(tab, i) in tabs"
                    :key="i"
                    :class="{
                      [`tabs-item--${tab.id}`]: true,
                      'tab-separate': i === tabs.length - 1,
                    }"
                  >
                    <router-link
                      v-slot="{ href, navigate }"
                      custom
                      :to="{
                        query: tab.query,
                      }"
                    >
                      <a
                        v-test="'filterTransactions'"
                        :class="['tab', { 'is-active': tab.isActive }]"
                        :href="href"
                        @click="navigate"
                      >
                        <span>{{ tab.title }}</span>
                      </a>
                    </router-link>
                  </li>
                </TabsLvl2Links>
              </div>
            </div>

            <router-view
              data-test-waste-log-content
              :is-loading="isLoading"
              @get-data="getData"
            />
          </template>
          <template #no-results>
            <NoSiteSelected
              :svg-id="'svg-no-results-waste'"
              data-test-no-site-selected
            />
          </template>
        </LoadedContent>
        <Modal ref="deleteConfirmModal">
          <template #default="{ close }">
            <div class="modal-body WL-confirm-modal">
              <div
                class="content"
                :aria-busy="deletingStatus === 'DELETING'"
              >
                <h2 class="h3">{{ $t('titles.bulkDelete', { count: selectedItemsCount }) }}</h2>
                <div>
                  <p>{{ $t('messages.bulkDeleteWarning') }}</p>
                  <p>
                    {{ $t('messages.bulkDeleteConfirm', { count: selectedItemsCount }) }}
                  </p>
                </div>
              </div>

              <div
                v-if="deletingStatus === 'DELETING'"
                class="flex flex-center p-4"
              >
                <Loading-Spinner class="spinner--lg" />
              </div>
              <div
                v-else
                class="buttonGroup flex-center"
              >
                <button
                  data-test-WL-cancel-bulk-delete
                  type="button"
                  class="button button--secondary"
                  @click="close"
                >
                  <SvgIcon name="24-ic-clear" />
                  <span>{{ $t('actions.cancel') }}</span>
                </button>
                <button
                  data-test-WL-confirm-bulk-delete
                  type="button"
                  class="button button--primary"
                  @click="handleConfirmDelete(close)"
                >
                  <SvgIcon name="24-ic-delete" />
                  <span>{{ $t('actions.bulkDeleteConfirm') }}</span>
                </button>
              </div>
            </div>
          </template>
        </Modal>
      </div>
    </div>
  </LayoutDefault>
</template>

<script>
import { computed, ref } from 'vue'
import { difference } from 'lodash'
import { mapGetters, useStore } from 'vuex'
import moment from 'moment-timezone'
import { useToast } from 'vue-toastification'
import { transactionTypes } from '@/store/modules/waste-log'
import { toGrams, boundsForPrecision3 } from '@/utils/weight'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import SiteSelector from '@/components/SiteSelector/SiteSelector.vue'
import NoSiteSelected from '@/components/NoSiteSelected/NoSiteSelected.vue'
import Datepicker from '@/components/Datepicker/Datepicker.vue'
import AdvancedFilters from '@/components/AdvancedFilters.vue'
import BulkActions from '@/components/BulkActions.vue'
import Modal from '@/components/Modal.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import queryDiff from '@/utils/queryDiff'
import { wasteLogParamsToProps } from '@/router/utils'
import { DATE_FORMAT, IANA_TIMEZONE_CHECK, VALID_TIMEZONE_MAPPINGS } from '@/store/constants'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
import { useSelectedSite } from '@/composables/useSelectedSite'
export default {
  name: 'WasteLog',
  components: {
    LayoutDefault,
    Hero,
    SiteSelector,
    NoSiteSelected,
    Datepicker,
    AdvancedFilters,
    BulkActions,
    Modal,
    TabsLvl2Links,
    ButtonWithSpinner,
    LoadedContent,
  },

  provide() {
    return {
      // Required as provided objects are not reactive.
      // https://vuejs.org/guide/components/provide-inject.html#working-with-reactivity
      selectedItems: computed({
        get: () => this.selectedItems,
        set: (value) => {
          this.selectedItems = value
        },
      }),
    }
  },

  props: {
    siteId: String,
    preopenFilters: {
      type: Boolean,
      default: false,
    },

    start: String,
    end: String,
    sortBy: String,
    categorised: Boolean,
    status: String,
    page: Number,
    size: Number,

    minWeight: Number,
    maxWeight: Number,
    minCost: Number,
    maxCost: Number,
    types: Array,
    foodItems: Array,
    stages: Array,
    trackers: Array,
  },
  setup() {
    const isLoading = ref(true)
    const isLoadingSites = ref(true)
    const { getters } = useStore()
    const userId = computed(() => getters['auth/userId'])
    const { selectedSite } = useSelectedSite({ userId: userId.value })

    return {
      toast: useToast(),
      selectedSite,
      isLoading,
      isLoadingSites,
    }
  },
  data() {
    return {
      isDownloadingWaste: false,

      // passed to datepicker
      dateFormat: DATE_FORMAT,
      dateFrom: '',
      dateTo: '',

      // last selectable date in the datepicker
      maxDate: '',

      selectedItems: [],
      deletingStatus: 'IDLE',
    }
  },

  computed: {
    ...mapGetters({
      userSettings: 'auth/userSettings',
    }),
    ...mapGetters('waste-log', ['byId', 'meta', 'transactionIds', 'transactions']),

    hasResults() {
      return this.meta?.total > 0
    },

    timeZone() {
      let timezone = this.selectedSite ? this.selectedSite.timeZone : null

      // Here are a few timezones for testing:
      // let timezone = this.selectedSite ? 'Europe/Bucharest' : null // + 3
      // let timezone = this.selectedSite ? 'Europe/London' : null // + 1
      // let timezone = this.selectedSite ? 'Pacific/Kiritimati' : null // + 14
      // let timezone = this.selectedSite ? 'US/Samoa' : null // -11

      // example of "invalid" timezone.
      // @TODO: remove the regex test and mapping once v-calendar updates and supports proper IANA timezone identification
      // let timezone = `Etc/GMT+3`

      timezone = VALID_TIMEZONE_MAPPINGS[timezone] || timezone
      const isValidTimezone = IANA_TIMEZONE_CHECK.test(timezone)
      return isValidTimezone ? timezone : undefined
    },

    tabs() {
      const { query } = this.$route

      return [
        {
          id: 'all',
          title: this.$t('titles.allTransactions'),
          query: {
            ...query,
            categorised: this.categorised || undefined,
            status: this.status === 'new' ? this.status : undefined,
            page: undefined,
          },
          isActive: this.categorised !== false && !['modified', 'deleted'].includes(this.status),
        },
        {
          id: 'uncategorised',
          title: this.$t('titles.uncategorised'),
          query: {
            ...query,
            categorised: false,
            status: this.status === 'new' ? this.status : undefined,
            page: undefined,
          },
          isActive: this.categorised === false,
        },
        {
          id: 'modified',
          title: this.$t('titles.modified'),
          query: {
            ...query,
            categorised: this.categorised || undefined,
            status: 'modified',
            page: undefined,
          },
          isActive: this.status === 'modified',
        },
        {
          id: 'deleted',
          title: this.$t('titles.deleted'),
          query: {
            ...query,
            categorised: this.categorised || undefined,
            status: 'deleted',
            page: undefined,
          },
          isActive: this.status === 'deleted',
        },
      ]
    },

    activeTab() {
      const match = this.tabs.find((tab) => tab.isActive) || this.tabs[0]

      return match.title
    },

    dataQuery() {
      const {
        categorised,
        status,
        page,
        sortBy,
        size,
        minWeight,
        maxWeight,
        minCost,
        maxCost,
        types,
        foodItems,
        stages,
        trackers,
      } = wasteLogParamsToProps(this.$route)

      const weightUnit = this.userSettings.weight.code
      const parsedMinWeight =
        minWeight !== undefined
          ? Math.floor(toGrams(boundsForPrecision3(minWeight).low, weightUnit))
          : undefined
      const parsedMaxWeight =
        maxWeight !== undefined
          ? Math.ceil(toGrams(boundsForPrecision3(maxWeight).high, weightUnit))
          : undefined
      return {
        filter: {
          categorised,
          status,
          start: this.dateFrom,
          end: this.dateTo,
          minWeight: parsedMinWeight,
          maxWeight: parsedMaxWeight,
          minCost,
          maxCost,
          type: difference(transactionTypes, types).length > 0 ? types : undefined,
          foodItem: foodItems,
          stage: stages,
          tracker: trackers.length > 0 ? trackers : undefined,
          site: this.selectedSite.legacyId,
        },
        locale: this.$store.getters['auth/preferredMenuLocale'],
        sortBy,
        page,
        size,
      }
    },

    exportQuery() {
      return {
        filter: {
          ...this.dataQuery.filter,
          weightUnit: this.userSettings.weight.code,
          timeZone: this.timeZone,
          site: this.selectedSite.legacyId,
        },
        locale: this.dataQuery.locale,
        sortBy: this.dataQuery.sortBy,
      }
    },

    selectedItemsCount() {
      return this.selectedItems.length
    },
  },

  watch: {
    $route(to, from) {
      // update the route
      const ignoreList = ['onlyImages', 'displayMode', 'preopenFilters']

      // then if there are any changes, reload the data
      if (queryDiff({ to, from, ignoreList }).length) {
        // set the new incoming dates
        this.setDates(to.query.start, to.query.end)
        // then get the data
        this.getFilters({
          start: this.dateFrom,
          end: this.dateTo,
          site: this.selectedSite.legacyId,
        })
        this.getData()
      }
      // https://winnow.atlassian.net/browse/HUB-3579
      // we're tracking historic transactions in order to see if we impact anyone by archiving data older than a year
      if (to.query.start && moment(to.query.start).isBefore(moment().subtract(1, 'years'))) {
        this.analytics.track('Historic transaction lookup')
      }
    },
  },

  unmounted() {
    moment.tz.setDefault()
  },

  methods: {
    handleSitesLoaded() {
      this.isLoadingSites = false
      if (!this.selectedSite) return
      // once the sites have been loaded, we should have a selected site so we can get the actual data for the content
      moment.tz.setDefault(this.timeZone)

      this.setDates(this.start, this.end)

      this.getFilters({
        start: this.dateFrom,
        end: this.dateTo,
        site: this.selectedSite.legacyId,
      })
      this.getData()
    },
    setDates(start, end) {
      this.maxDate = moment().add(1, 'day').startOf('day').toISOString()

      if (start && end) {
        this.dateFrom = moment(start).toISOString()
        this.dateTo = moment(end).toISOString()
      } else {
        this.dateFrom = moment().startOf('day').subtract(1, 'month').toISOString()
        this.dateTo = moment().endOf('day').toISOString()
      }
    },

    getFilters(params) {
      this.$store.dispatch('waste-log/getFilterFoodItems', params)
      this.$store.dispatch('waste-log/getFilterStages', params)
      this.$store.dispatch('waste-log/getFilterTrackers', params)
    },

    getData() {
      let routeActions = {
        'waste-log-list': 'getAllWaste',
        'waste-log-grid': this.$route.query.page ? 'getMoreWaste' : 'getAllWaste',
      }
      this.isLoading = true

      this.$store
        .dispatch(`waste-log/${routeActions[this.$route.name]}`, this.dataQuery)
        .then(() => {
          this.isLoading = false
        })

        .catch((e) => {
          this.isLoading = false
          this.toast.error(this.$t('toast.error.getData'))
          throw e
        })
    },

    // startDate and endDate coming in as ISO strings
    applyDateRange({ startDate, endDate }) {
      if (startDate && endDate) {
        this.analytics.track('Activation - Date range changed', {
          startDate,
          endDate,
        })

        this.$router.push({
          query: {
            ...this.$route.query,
            page: undefined,
            start: startDate,
            end: endDate,
          },
        })
      }
    },

    applyFilters(query) {
      // special case for types, need to seralize values only when not all are selected
      const types =
        difference(transactionTypes, query.types).length > 0 ? query.types.join(',') : undefined

      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
          types,
          trackers: query.trackers ? query.trackers.join(',') : undefined,
          page: undefined,
        },
      })
    },

    download() {
      this.isDownloadingWaste = true

      this.$store
        .dispatch('waste-log/downloadWaste', this.exportQuery)
        .catch(() => {
          this.toast.error(this.$t('toast.error.WL.exportData'))
        })
        .finally(() => {
          this.isDownloadingWaste = false

          this.analytics.track('Activation - Export transactions', {
            startDate: this.exportQuery.filter.start,
            endDate: this.exportQuery.filter.end,
            categorised: this.exportQuery.filter.categorised,
          })
        })
    },

    handleDelete() {
      this.analytics.track('Waste Log bulk deletion transaction delete selected')
      this.$refs.deleteConfirmModal.open()
    },

    handleCancelDelete(close) {
      this.analytics.track('Waste Log bulk deletion transaction cancel')
      close()
    },

    async handleConfirmDelete(callback) {
      this.deletingStatus = 'DELETING'

      const transactionsToDelete = this.transactions.filter((transaction) =>
        this.selectedItems.includes(transaction.id)
      )

      try {
        await this.deleteTransactions(transactionsToDelete)
        this.analytics.track('Waste Log bulk deletion transaction confirm', {
          count: transactionsToDelete.length,
        })

        let newPage = Math.floor(this.transactionIds.length / this.size)

        if (this.dataQuery.page === newPage) {
          this.getData()
        } else {
          this.$router.push({ query: { ...this.$route.query, page: newPage } })
        }

        this.toast.success(this.$t('toast.success.bulkDelete', { count: this.selectedItemsCount }))
        this.selectedItems = []
        this.deletingStatus = 'DELETED'
      } catch (error) {
        this.deletingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.deleteData'))
      } finally {
        callback()
        this.deletingStatus = 'IDLE'
      }
    },

    deleteTransactions(transactions) {
      return this.$store.dispatch('waste-log/deleteItems', transactions).then(() => {
        const deletedIds = transactions.map((transaction) => transaction.id)
        // Delete the selected transactions from the store
        this.$store.dispatch('waste-log/deleteRecordsById', deletedIds)
        // Remove the last N ids from the store so that transactionsIds will be in sync with transactions after getMoreWast action is finished.
        this.$store.commit(
          'waste-log/REMOVE_LAST_FROM_TRANSACTIONS_IDS',
          this.size - deletedIds.length
        )
      })
    },
  },
}
</script>
<style lang="scss">
.WL-box {
  min-height: calc(theme('spacing.8') * 10);
}

.WL-export {
  display: none;

  @include respond(small) {
    display: inline-flex;
  }

  .icon {
    position: relative;
    top: -2px;
  }
}

.WL-loading {
  padding: to-rem(100px) 0;
}

.WL-sort {
  padding: 0 !important;
  margin-top: calc(-1 * theme('spacing.px'));
  border: 0;
  line-height: 1;
  text-align: left;
  justify-content: start;

  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none;
  }

  .chevron {
    margin-bottom: theme('spacing[0.5]');

    &:last-child {
      margin: 0;
    }
  }

  .chevron--up {
    border-bottom-color: theme('colors.acai.200');
  }

  .chevron--down {
    border-top-color: theme('colors.acai.200');
  }

  .flex {
    flex-direction: column;
    margin-left: theme('spacing.2');
  }
}

.WL-sort--active {
  .chevron--up {
    border-bottom-color: theme('colors.acai.DEFAULT');
  }

  .chevron--down {
    border-top-color: theme('colors.acai.200');
  }
}

.WL-sort--inversed {
  .chevron--up {
    border-bottom-color: theme('colors.acai.200');
  }

  .chevron--down {
    border-top-color: theme('colors.acai.DEFAULT');
  }
}

.WL-timezone span {
  font-weight: theme('fontWeight.bold');
}

.WL-dateTime {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  margin: calc(-1 * theme('spacing.6')) calc(-1 * theme('spacing.4')) 0;
  padding: theme('spacing.2') theme('spacing.4');

  & > * {
    &:not(:last-child) {
      margin-right: theme('spacing.2');
    }

    margin-top: theme('spacing.2');
  }

  @include respond($max: small) {
    display: block;
  }
}

.WL-datePicker {
  min-width: 30ch;

  .asd__month-name {
    color: theme('colors.acai.DEFAULT');
  }

  .asd__mobile-close {
    color: theme('colors.white');
    background: theme('colors.slate.DEFAULT');
  }

  input {
    padding-right: 2.5rem;

    &::placeholder {
      text-align: right;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.WL-confirm-modal {
  margin: theme('spacing.6');

  h2 {
    padding-bottom: theme('spacing.3');
  }

  p:first-child {
    padding-bottom: theme('spacing.6');
  }

  .buttonGroup {
    margin-top: theme('spacing.8');
  }
}
</style>
