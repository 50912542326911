export default (data) =>
  data
    ? {
        sendIkeaDaily: data.sendIkeaDaily,
        sendDaily: data.sendDaily,
        sendWeekly: data.sendWeekly,
        sendMonthly: data.sendMonthly,
        weekEnding: data.weekEnding,
      }
    : null
