export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    organisationById: (state) => (id) => state.records[id],
    trackersForOrganisation: (state, getters, rootState, rootGetters) => (id) => {
      return state.records[id]
        ? rootGetters['menu-management/trackers/trackersById'](state.records[id].trackerIds)
        : null
    },
  },
  actions: {},
  mutations: {
    STORE_ORGANISATIONS(state, records) {
      state.records = { ...state.records, ...records }
    },
    UPDATE_ORGANISATION(state, updates) {
      state.records[updates.id] = { ...state.records[updates.id], ...updates }
    },
  },
}
