<template>
  <div
    :class="[
      'card',
      {
        'is-today': isToday,
        'is-external': isOutsideCurrentMonth,
      },
    ]"
  >
    <CardHeader :day="day" />
    <div class="card-body"></div>
  </div>
</template>

<script>
import CardHeader from './CardHeader.vue'

export default {
  components: {
    CardHeader,
  },
  props: {
    day: {
      type: Object,
    },
  },
  computed: {
    isToday() {
      return this.day.isToday
    },
    isOutsideCurrentMonth() {
      return this.day.isCurrentMonth === false
    },
  },
}
</script>
