<template>
  <transition-group
    tag="ol"
    name="ordered-grid"
    class="ordered-grid"
    ><slot
  /></transition-group>
</template>

<script>
export default {}
</script>

<style></style>
