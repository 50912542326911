<template>
  <LayoutDefault extended-content>
    <div class="pentaho-wrapper">
      <iframe
        ref="dashboard"
        data-hj-allow-iframe
        class="pentaho-iframe"
        :src="iframeUrl"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
        scrolling="no"
      ></iframe>
    </div>
  </LayoutDefault>
</template>

<script>
import { mapGetters } from 'vuex'
import queryString from 'query-string'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import { useToast } from 'vue-toastification'
import InsightsBetaMessage from './InsightsBetaMessage.vue'
export default {
  name: 'MultiSiteDashboard',
  components: {
    LayoutDefault,
  },
  setup() {
    return { toast: useToast() }
  },
  computed: {
    ...mapGetters({
      userSettings: 'auth/userSettings',
    }),
    iframeUrl() {
      const params = queryString.stringify({
        p_weight: this.userSettings.weight.code,
        p_locale: this.userSettings.locale.code,
      })
      const address =
        '/pentaho/api/repos/%3Apublic%3AWinnow%3Adashboards%3AMulti_Site_Dashboard.wcdf/generatedContent'
      return `${import.meta.env.VUE_APP_PENTAHO_URL}${address}?${params}`
    },
  },
  mounted() {
    this.toast.success({
      component: InsightsBetaMessage,
    })
    window.addEventListener('message', this.iframeMessageHandler)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.iframeMessageHandler)
    this.toast.clear()
  },
  methods: {
    iframeMessageHandler(ev) {
      if (ev.origin === import.meta.env.VUE_APP_PENTAHO_URL && ev.data && ev.data.pageheight) {
        this.$refs.dashboard.style.height = Math.ceil(ev.data.pageheight) + 'px'
      }
    },
  },
}
</script>
