<script>
import { mapGetters } from 'vuex'

import TreeLayout from '@/components/TreeLayout.vue'
import SearchRecords from '@/components/SearchRecords.vue'
import NodeFacet from '@/components/NodeFacet.vue'
import StickySearch from '@/components/StickySearch.vue'
import SearchFilter from '@/components/SearchFilter.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    TreeLayout,
    SearchRecords,
    NodeFacet,
    StickySearch,
    SearchFilter,
  },

  provide() {
    return {
      // function because it needs to be reactive
      // https://github.com/vuejs/vue/issues/7017#issuecomment-480906691
      preopen: () => this.preopen,
    }
  },
  props: {
    nodeClasses: {
      type: Function,
      default: () => function () {},
    },
    stickySearchSelectors: {
      type: Object,
      default: () => ({
        parent: '.fixedColumn-main',
        relative: '.fixedColumn-column',
      }),
    },
    keyPrefix: {
      type: String,
      default: 'facets',
    },
    rootId: {
      type: String,
      default: 'root',
    },
    searchQuery: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['nodeClick', 'resultClick'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      isLoadingRoots: false,
      preopen: {
        [this.rootId]: true,
      },
      activeResult: null,
      facetFilter: null,
    }
  },

  computed: {
    ...mapGetters('taxonomy/facets', ['byId', 'childIds']),
    ...mapGetters('taxonomy', ['getDetailLevelById']),

    defQuery() {
      const filter = this.facetFilter ? { facetGroupCode: this.byId(this.facetFilter).code } : {}
      return {
        ...filter,
        ...this.searchQuery,
      }
    },
  },

  async created() {
    const isSuccessful = this.$store.getters['taxonomy/facets/isSuccessful']
    const isLoading = this.$store.getters['taxonomy/facets/isLoading']
    const treePromise = this.$store.getters['taxonomy/facets/treePromise']

    if (isSuccessful) {
      try {
        const isUpToDate = await this.$store.dispatch('taxonomy/facets/checkHash')

        if (!isUpToDate) {
          await this.$store.dispatch('taxonomy/facets/getTree')
        }
      } catch (error) {
        this.toast.error(this.$t('toast.error.getData'))
      }
      return
    }

    try {
      this.isLoadingRoots = true

      if (!isLoading) {
        await this.$store.dispatch('taxonomy/facets/getTree')
      } else {
        await treePromise
      }
    } catch (error) {
      this.toast.error(this.$t('toast.error.getData'))
    } finally {
      this.isLoadingRoots = false
    }
  },
  methods: {
    onSearch(branch) {
      const record = branch[branch.length - 1].record

      const hash = {}
      branch.slice(0, -1).forEach(({ record }) => {
        hash[record.id] = true
      })

      this.preopen = hash

      this.$nextTick(() => {
        this.$scrollTo(`#tree-${this.keyPrefix}-${record.id}`, 1, {
          offset: -1 * (50 + 36),
        })
      })

      this.$emit('resultClick', record.id)
      this.activeResult = record.id
    },

    setFacetFilter(facet) {
      this.facetFilter = facet
    },
  },
}
</script>

<template>
  <div>
    <StickySearch :selectors="stickySearchSelectors">
      <div class="flex-column w-full">
        <slot name="sticky-top-content" />
        <div class="flex">
          <template v-if="rootId === 'root'">
            <div class="text-slate">
              <SvgIcon
                name="24-ic-search"
                xl
                class="p-3"
              />
            </div>
            <SearchFilter
              :facet-id="facetFilter"
              :on-select="setFacetFilter"
            />
          </template>

          <SearchRecords
            :action="'taxonomy/facets/search'"
            :on-select="onSearch"
            :query="defQuery"
            :include-icon="rootId !== 'root'"
            class="px-2"
          >
            <template #result="{ nodeId }">
              <slot
                name="result"
                :node-id="nodeId"
              />
            </template>
          </SearchRecords>
        </div>
      </div>
    </StickySearch>

    <div class="py-2 with-StickySearch">
      <Loading-Spinner
        v-if="isLoadingRoots"
        class="spinner--md mx-auto my-2"
      />
      <ul
        v-else
        class="Tree"
      >
        <TreeLayout
          :node-id="rootId"
          :get-children="(nodeId) => childIds(nodeId)"
          :key-prefix="keyPrefix"
          :node-classes="[
            (nodeId) => ({
              'is-match': activeResult === nodeId,
            }),
            nodeClasses,
          ]"
          @node-click="$emit('nodeClick', $event)"
        >
          <template #default="{ nodeId, modifiers }">
            <NodeFacet
              :record="byId(nodeId)"
              :detail-level="getDetailLevelById(byId(nodeId).detailLevel)"
              @anchor-click="
                ($event) => {
                  return byId(nodeId).parentId === null ? setFacetFilter($event) : undefined
                }
              "
            />

            <slot
              name="node"
              :node-id="nodeId"
              :modifiers="modifiers"
            />
          </template>
        </TreeLayout>
      </ul>
    </div>
  </div>
</template>
