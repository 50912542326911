<template>
  <Collapsible-section
    id="localisationSection"
    class="collapsible-section--first"
    tag="section"
    :force-open="isOpen"
    data-test-localisation-section
    @open="onOpen"
    @close="onClose"
  >
    <template #header>
      {{ $t('organisations.overview.localisation.title') }}
    </template>
    <!-- Summary of selected options -->
    <template #header-side>
      <Loading-Spinner v-if="loadingStatus === 'LOADING'" />
      <ul
        v-if="!isOpen && loadingStatus === 'LOADED'"
        data-test-localisation-summary
      >
        <Filter-chip
          v-if="selectedLocale"
          tag="li"
        >
          {{ selectedLocale.name }}
        </Filter-chip>
        <Filter-chip
          v-if="selectedCurrency"
          tag="li"
        >
          {{ selectedCurrency.code }} - {{ selectedCurrency.country }}
        </Filter-chip>
        <Filter-chip
          v-if="selectedTimezone"
          tag="li"
        >
          {{ selectedTimezone.code }} - {{ selectedTimezone.name }}
        </Filter-chip>
        <Filter-chip
          v-if="selectedWeight"
          tag="li"
        >
          {{ selectedWeight.description }}
        </Filter-chip>
        <Filter-chip
          v-if="selectedTemperature"
          tag="li"
        >
          {{ selectedTemperature.description }}
        </Filter-chip>
      </ul>
    </template>
    <template #default>
      <Loaded-content
        :is-loading="loadingStatus === 'LOADING'"
        :has-error="loadingStatus === 'ERROR'"
        :has-data="loadingStatus === 'LOADED' && !!node"
      >
        <form
          :action="$route.fullPath"
          novalidate
          @submit.prevent="saveData"
          @reset.prevent="resetValues"
        >
          <div class="form-layout--x3 mb-4">
            <!-- Locale selector -->
            <Validation-group
              :validator="v$.buffer.locale"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="localisationLocale">{{
                $t('organisations.overview.localisation.labels.locale')
              }}</label>
              <select
                id="localisationLocale"
                v-model="v$.buffer.locale.$model"
                class="customSelect"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-localisation-locale
                required
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('organisations.overview.localisation.placeholders.locale') }}
                </option>
                <option
                  v-for="locale in localesSorted"
                  :key="locale.code"
                  :value="locale.code"
                >
                  {{ locale.name }} – {{ locale.code }}
                </option>
              </select>
            </Validation-group>

            <!-- Currency selector -->
            <Validation-group
              :validator="v$.buffer.currency"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="localisationCurrency">
                {{ $t('organisations.overview.localisation.labels.currency') }}
              </label>
              <select
                id="localisationCurrency"
                v-model="v$.buffer.currency.$model"
                class="customSelect"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-localisation-currency
                required
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('organisations.overview.localisation.placeholders.currency') }}
                </option>
                <option
                  v-for="currency in currenciesSorted"
                  :key="currency.code"
                  :value="currency.code"
                >
                  {{ currency.code }} - {{ currency.country }}
                </option>
              </select>
            </Validation-group>

            <!-- Timezone selector -->
            <Validation-group
              :validator="v$.buffer.timezone"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="localisationTimezone">
                {{ $t('organisations.overview.localisation.labels.timezone') }}
              </label>
              <select
                id="localisationTimezone"
                v-model="v$.buffer.timezone.$model"
                class="customSelect"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-localisation-timezone
                required
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('organisations.overview.localisation.placeholders.timezone') }}
                </option>
                <option
                  v-for="timezone in timezonesSorted"
                  :key="timezone.code"
                  :value="timezone.code"
                >
                  {{ timezone.code }} - {{ timezone.name }}
                </option>
              </select>
            </Validation-group>

            <!-- Weight unit selector -->
            <Validation-group
              :validator="v$.buffer.weight"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="localisationWeightUnits">
                {{ $t('organisations.overview.localisation.labels.weightUnits') }}
              </label>
              <select
                id="localisationWeightUnits"
                v-model="v$.buffer.weight.$model"
                class="customSelect"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-localisation-weight
                required
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('organisations.overview.localisation.placeholders.weightUnits') }}
                </option>
                <option
                  v-for="weight in weightUnits"
                  :key="weight.id"
                  :value="weight.id"
                >
                  {{ weight.description }}
                </option>
              </select>
            </Validation-group>

            <!-- Temperature unit selector -->
            <Validation-group
              :validator="v$.buffer.temperature"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="localisationTemperatureUnits">
                {{ $t('organisations.overview.localisation.labels.temperatureUnits') }}
              </label>
              <select
                id="localisationTemperatureUnits"
                v-model="v$.buffer.temperature.$model"
                class="customSelect"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-localisation-temperature
                required
              >
                <option
                  :value="null"
                  disabled
                >
                  {{ $t('organisations.overview.localisation.placeholders.temperatureUnits') }}
                </option>
                <option
                  v-for="temperature in temperatureUnits"
                  :key="temperature.id"
                  :value="temperature.id"
                >
                  {{ temperature.description }}
                </option>
              </select>
            </Validation-group>
          </div>

          <!-- Propagate changes check -->
          <div
            v-if="node.level >= 4 && node.level <= 15 && node.childrenCount"
            class="formGroup customControl customControl--checkbox"
          >
            <input
              id="localisationPropagate"
              v-model="v$.propagateChanges.$model"
              data-test-localisation-propagate
              type="checkbox"
              class="customControl-input"
            />
            <label
              class="customControl-label"
              for="localisationPropagate"
            >
              {{ $t('organisations.messages.propagateToChildren', { count: node.childrenCount }) }}
            </label>
          </div>
          <div class="buttonGroup">
            <button
              type="submit"
              class="button button--primary"
              :disabled="!v$.$anyDirty || savingStatus === 'SAVING'"
              data-test-save
            >
              <SvgIcon
                v-if="savingStatus !== 'SAVING'"
                name="24-ic-check"
              />
              <Loading-Spinner
                v-if="savingStatus === 'SAVING'"
                class="icon spinner--white"
              />
              <span>{{ $t('actions.saveTheseChanges') }}</span>
            </button>
            <button
              type="reset"
              class="button button--secondary"
              :disabled="!v$.$anyDirty || savingStatus === 'SAVING'"
              data-test-cancel
            >
              {{ $t('actions.cancel') }}
            </button>
          </div>
        </form>
      </Loaded-content>
    </template>
  </Collapsible-section>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import FilterChip from '@/components/FilterChip.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

function LocalisationModel(overwrites) {
  return {
    locale: null,
    currency: null,
    timezone: null,
    weight: null,
    temperature: null,
    ...overwrites,
  }
}

export default {
  components: {
    LoadedContent,
    CollapsibleSection,
    FilterChip,
    ValidationGroup,
  },
  props: {
    node: Object,
    isOpen: Boolean,
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      propagateChanges: false,
      buffer: this.mapLocalisationModel(),
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
    }
  },
  validations: {
    propagateChanges: {},
    buffer: {
      locale: { required },
      timezone: { required },
      currency: { required },
      weight: { required },
      temperature: { required },
    },
  },
  computed: {
    ...mapGetters('hierarchy/localisation', [
      'cachedStatus',
      'locales',
      'currencies',
      'timezones',
      'weightUnits',
      'temperatureUnits',
    ]),
    localesSorted() {
      return [...this.locales].sort((a, b) => a.name.localeCompare(b.name))
    },
    currenciesSorted() {
      return [...this.currencies].sort((a, b) => a.code.localeCompare(b.code))
    },
    timezonesSorted() {
      return [...this.timezones].sort((a, b) => a.code.localeCompare(b.code))
    },
    selectedLocale() {
      return this.$store.getters['hierarchy/localisation/localeById'](this.node.localisation.locale)
    },
    selectedCurrency() {
      return this.$store.getters['hierarchy/localisation/currencyById'](
        this.node.localisation.currencyCode
      )
    },
    selectedTimezone() {
      return this.$store.getters['hierarchy/localisation/timezoneById'](
        this.node.localisation.timezone
      )
    },
    selectedWeight() {
      return this.$store.getters['hierarchy/localisation/weightUnitById'](
        this.node.localisation.weightUnitId
      )
    },
    selectedTemperature() {
      return this.$store.getters['hierarchy/localisation/temperatureUnitById'](
        this.node.localisation.temperatureUnitId
      )
    },
  },
  watch: {
    isOpen(open) {
      if (open && this.loadingStatus === 'IDLE') {
        this.loadData()
      }
    },
  },
  created() {
    if (this.cachedStatus === false) {
      this.loadData()
    } else {
      this.loadingStatus = 'LOADED'
    }
  },
  methods: {
    loadData() {
      this.loadingStatus = 'LOADING'
      return this.$store
        .dispatch('hierarchy/localisation/getOptions')
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch(() => {
          this.loadingStatus = 'ERROR'
        })
    },
    saveData() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('hierarchy/localisation/saveLocalisation', {
          nodeId: this.node.id,
          propagateChanges: this.propagateChanges,
          data: {
            localisation: {
              locale: this.buffer.locale,
              timezone: this.buffer.timezone,
              currencyCode: this.buffer.currency,
              weightUnitId: this.buffer.weight,
              temperatureUnitId: this.buffer.temperature,
            },
          },
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.resetValues()
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    mapLocalisationModel() {
      return new LocalisationModel({
        locale: this.node.localisation.locale,
        currency: this.node.localisation.currencyCode,
        timezone: this.node.localisation.timezone,
        weight: this.node.localisation.weightUnitId,
        temperature: this.node.localisation.temperatureUnitId,
      })
    },
    resetValues() {
      this.buffer = this.mapLocalisationModel()
      this.propagateChanges = false
      this.v$.$reset()
    },
    onOpen() {
      this.$emit('open')
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>

<style></style>
