<template>
  <div class="day-nav">
    <span
      v-if="showInput"
      class="day-nav-item day-nav-input"
    >
      <input
        class="formControl singleDate"
        type="date"
        :value="formattedDate"
        @change="onDateChange($event.target.value)"
      />
    </span>
    <span class="day-nav-item day-nav-prev">
      <button
        class="button button--icon p-0"
        :aria-label="$t('wasteTimeline.actions.prevDay')"
        @click="prevDay()"
      >
        <SvgIcon
          name="48-ic-arrow-prev"
          xl
        />
      </button>
    </span>
    <span class="day-nav-item day-nav-next">
      <button
        class="button button--icon p-0"
        :aria-label="$t('wasteTimeline.actions.nextDay')"
        @click="nextDay()"
      >
        <SvgIcon
          name="48-ic-arrow-next"
          xl
        />
      </button>
    </span>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { DATE_FORMAT_ISO_8601 } from '@/store/constants'
export default {
  props: {
    date: String,
    showInput: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['date-change'],
  data() {
    return {
      currentDate: this.date,
    }
  },
  computed: {
    formattedDate() {
      // the displayed date is formatted based on the locale of the user's browser,
      // but the parsed value should always be formatted yyyy-mm-dd.
      return moment(this.currentDate).locale('en-US').format(DATE_FORMAT_ISO_8601)
    },
  },
  watch: {
    date(newDate) {
      this.currentDate = newDate
    },
  },
  methods: {
    onDateChange(value) {
      this.applyDate(moment(value, DATE_FORMAT_ISO_8601))
    },
    applyDate(date) {
      this.currentDate = date
      this.$emit('date-change', date)
    },
    prevDay() {
      this.applyDate(moment(this.currentDate).add(-1, 'days'))
    },

    nextDay() {
      this.applyDate(moment(this.currentDate).add(1, 'days'))
    },
  },
}
</script>

<style lang="scss">
.day-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: calc(-1 * theme('spacing.2'));
  margin-left: calc(-1 * theme('spacing.2'));

  .day-nav-item {
    margin-top: theme('spacing.2');
    margin-left: theme('spacing.2');
  }

  .day-nav-input {
    width: 100%;
  }

  @include respond-min(small) {
    flex-wrap: nowrap;
    flex-grow: 0;

    .day-nav-prev {
      order: 0;
    }

    .day-nav-input {
      width: auto;
      order: 1;
    }

    .day-nav-next {
      order: 2;
    }
  }
}
</style>
