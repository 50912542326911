<template>
  <component
    :is="tag || 'div'"
    class="chip"
  >
    <div
      v-if="hasThumbnail"
      class="chip-thumbnail"
    >
      <slot name="thumbnail"> </slot>
    </div>
    <div
      class="chip-content"
      data-test-chip-content
    >
      <slot />
    </div>
    <div
      v-if="$slots['counter']"
      class="chip-counter"
      data-test-chip-counter
    >
      <slot name="counter" />
    </div>
    <button
      v-if="removable"
      type="button"
      class="button button--icon chip-control p-0"
      data-test-chip-remove
      @click="$emit('remove')"
    >
      <SvgIcon name="24-ic-clear" />
      <span class="sr-only">{{ $t('actions.delete') }}</span>
    </button>
    <button
      v-if="available"
      type="button"
      class="button button--icon chip-control p-0"
      data-test-chip-add
      @click="$emit('add')"
    >
      <SvgIcon name="24-ic-add" />
      <span class="sr-only">{{ $t('actions.add') }}</span>
    </button>
  </component>
</template>

<script>
export default {
  name: 'HubChip',
  props: ['removable', 'available', 'tag', 'hasThumbnail'],
  emits: ['add', 'remove'],
}
</script>

<style lang="scss">
.chip {
  padding: theme('spacing.0') theme('spacing.3');
  display: flex;
  align-items: flex-start;
  background: theme('colors.white');
  border: 1px solid theme('colors.slate.DEFAULT');
  border-radius: theme('borderRadius.full');
  color: theme('colors.acai.DEFAULT');
  background: theme('colors.white');
  align-items: center;
  position: relative;
  min-height: calc(theme('spacing.10') + 2px);
  gap: theme('spacing.2');
}

.chip--squared {
  border-radius: theme('borderRadius.DEFAULT');
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  gap: theme('spacing.1');
}

.chip--success {
  border-color: theme('colors.mint.DEFAULT');
}
.chip--info {
  border-color: theme('colors.slate.DEFAULT');
}
.chip--warning {
  border-color: theme('colors.carrot.DEFAULT');
}
.chip--danger {
  border-color: theme('colors.apple.DEFAULT');
}
.chip--neutral {
  border-color: theme('colors.acai.DEFAULT');
}

.chip-content {
  flex: 0 1 auto;
}

.chip-control {
  flex: 0 1 auto;
  color: theme('colors.blueberry.DEFAULT');
  margin-right: calc(-1 * theme('spacing.1'));

  &:focus {
    border-color: theme('colors.blueberry.DEFAULT');
    box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv') inset;
    outline: none;
  }
}

.chip-thumbnail {
  @apply rounded-l;
  margin-top: calc(-1 * theme('spacing.1'));
  margin-right: theme('spacing.2');
  margin-bottom: calc(-1 * theme('spacing.1'));
  margin-left: calc(-1 * theme('spacing.3'));
  width: 2.5rem;
  height: 2.5rem;
  background: theme('colors.grey.200');
  position: relative;
  overflow: hidden;
}

.chip-counter {
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  background-color: theme('colors.blueberry.hsluv');
  color: theme('colors.white');
  border-radius: 1rem;
  font-size: theme('fontSize.sm');
  min-width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
}
</style>
