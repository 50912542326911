<template>
  <div class="Hero">
    <div class="wrapper">
      <slot name="top"></slot>
      <div class="Hero-title">
        <div class="Hero-title-link">
          <slot name="quicklinks"></slot>
        </div>
        <slot name="title">
          <h1
            class="flex-grow"
            data-test-main-title
          >
            <slot></slot>
          </h1>
        </slot>
        <div class="Hero-aside">
          <slot name="hero-aside"></slot>
        </div>
      </div>
      <slot name="hero-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroSection',
  props: {
    quicklinks: {
      default() {
        return []
      },
      type: Array,
    },
  },
}
</script>
<style lang="scss">
.Hero-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: theme('colors.grey.900');
  margin-bottom: theme('spacing.6');
  margin-top: theme('spacing.4');
  margin-left: theme('spacing.4');
  flex-wrap: wrap;
}
.Hero-title-link {
  width: 100%;
}
.Hero-title-link a {
  text-decoration: underline;
}
</style>
