<template>
  <!-- Move to different l1 denied -->
  <div
    v-if="isMovingToDifferentL1 && !$can('update', 'different-organisation-nodes')"
    class="copy p-8"
    data-hierarchy-move-modal
  >
    <h2
      class="mb-2 text-acai"
      data-hierarchy-move-modal-title
    >
      {{ $t('hierarchy.message.movingHeaderNotAllowed') }}
    </h2>
    <p class="mt-0">{{ $t('hierarchy.messages.moveHierarchyNodeChangeL1Warning') }}</p>
    <p>{{ $t('hierarchy.messages.moveHierarchyNodeLogTicket') }}.</p>
    <p class="alert alert--info font-bold p-0 mt-0">
      <SvgIcon
        name="24-ic-warning"
        class="text-carrot"
      ></SvgIcon>
      {{ $t('hierarchy.messages.moveHierarchyNodeChangeL1DeniedRequirements') }}
    </p>

    <div class="buttonGroup justify-end mt-4">
      <button
        type="button"
        data-test-move-cancel-btn
        class="button button--primary"
        @click="$emit('cancel')"
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('actions.acknowledge') }}</span>
      </button>
    </div>
  </div>

  <!-- Move to different L1 allowed -->
  <div
    v-else-if="isMovingToDifferentL1 && $can('update', 'different-organisation-nodes')"
    class="m-8"
  >
    <h2
      class="mb-2 text-acai"
      data-hierarchy-move-modal-title
    >
      {{ $t('hierarchy.message.movingHeader') }}
    </h2>
    <div
      class="copy"
      data-hierarchy-move-modal-content
    >
      <i18n-t
        keypath="messages.moveHierarchyNode"
        tag="p"
        scope="global"
      >
        <template #nodeToBeMoved
          ><b>{{ byId(id).name }}</b></template
        >
        <template #newParent
          ><b>{{ byId(parentId).name }}</b></template
        >
      </i18n-t>
      <p class="mt-0">{{ $t('hierarchy.messages.moveHierarchyNodeChangeL1Warning') }}</p>

      <p class="alert alert--info font-bold my-0 mb-6">
        <SvgIcon
          name="24-ic-info"
          class="text-acai"
        ></SvgIcon>
        {{ $t('hierarchy.messages.moveHierarchyNodeChangeL1Users') }}
      </p>
      <p class="alert alert--info font-bold my-0 mb-6">
        <SvgIcon
          name="24-ic-info"
          class="text-acai"
        ></SvgIcon>
        {{ $t('hierarchy.messages.moveHierarchyNodeChangeL1ReportsSubscribers') }}
      </p>
      <p class="alert alert--info font-bold my-0 mb-6">
        <SvgIcon
          name="24-ic-warning"
          class="text-carrot"
        ></SvgIcon>
        {{ $t('hierarchy.messages.moveHierarchyNodeChangeL1Requirements') }}
      </p>
    </div>

    <Loading-Spinner
      v-if="isLoading"
      class="spinner--sm mx-auto my-4"
    />
    <div
      v-else
      class="buttonGroup justify-end mt-4"
    >
      <button
        data-test-move-cancel-btn
        type="button"
        class="button button--secondary"
        @click="$emit('cancel')"
      >
        <SvgIcon name="24-ic-clear" />
        <span>{{ $t('actions.cancel') }}</span>
      </button>
      <button
        data-test-move-confirm-btn
        type="button"
        data-test-confirm-move
        class="button button--primary"
        @click="moveNode"
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('actions.move') }}</span>
      </button>
    </div>
  </div>

  <!-- Move within same L1 -->
  <div v-else>
    <h2
      class="mb-2"
      data-hierarchy-move-modal-title
    >
      {{ $t('hierarchy.message.movingHeader') }}
    </h2>
    <div data-hierarchy-move-modal-content>
      <i18n-t
        keypath="messages.moveHierarchyNode"
        tag="p"
        scope="global"
      >
        <template #nodeToBeMoved
          ><b>{{ byId(id).name }}</b></template
        >
        <template #newParent
          ><b>{{ byId(parentId).name }}</b></template
        >
      </i18n-t>
      <i18n-t
        keypath="messages.moveHierarchyNodeConfirm"
        tag="p"
        scope="global"
      ></i18n-t>
    </div>

    <Loading-Spinner
      v-if="isLoading"
      class="spinner--sm mx-auto my-4"
    />
    <div
      v-else
      class="buttonGroup flex-center mt-4"
    >
      <button
        type="button"
        class="button button--secondary"
        @click="$emit('cancel')"
      >
        <SvgIcon name="24-ic-clear" />
        <span>{{ $t('actions.cancel') }}</span>
      </button>
      <button
        data-test-move-confirm-btn
        type="button"
        data-test-confirm-move
        class="button button--primary"
        @click="moveNode"
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('actions.confirm') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  props: {
    id: String,
    parentId: String,
  },
  emits: ['cancel', 'success'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('hierarchy/nodes', ['byId']),
    isMovingToDifferentL1() {
      const rootOrgOfTargetNode = this.$store.getters[
        'hierarchy/nodes/getRootOrganisationForNodeById'
      ](this.id)

      const rootOrgOfMovingNode = this.$store.getters[
        'hierarchy/nodes/getRootOrganisationForNodeById'
      ](this.parentId)

      return rootOrgOfTargetNode !== rootOrgOfMovingNode
    },
  },
  methods: {
    moveNode() {
      this.isLoading = true

      this.$store
        .dispatch('hierarchy/nodes/moveNode', { id: this.id, newParentId: this.parentId })
        .then((id) => {
          this.$emit('success', { id, parentId: this.parentId })
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch((e) => {
          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
