<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Breadcrumbs
      class="mx-4 ps-4"
      :history="[{ name: 'facet-index', title: $t('taxman.facets') }]"
      :current="$t('taxman.facets.new')"
    />
    <Hero>{{ $t('navigation.taxonomy') }}</Hero>

    <FormFacet
      :record="record"
      :parent-id="$route.params.parentId"
    />
  </LayoutDefault>
</template>

<script>
import { newFacetModel } from '@/store/modules/taxonomy/facets'

import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'

import FormFacet from '@/components/FormFacet/FormFacet.vue'

export default {
  name: 'NewFacet',
  components: {
    LayoutDefault,
    Hero,
    Breadcrumbs,
    FormFacet,
  },
  data() {
    return {
      record: null,
    }
  },

  created() {
    this.$store.dispatch('taxonomy/getTermOptions')

    const parentId = this.$route.params.parentId

    this.record = newFacetModel({
      parentId,
    })
  },
}
</script>
