import api from '@/store/api/menus'
import getOldValues from '@/store/utils/getOldValues'

const state = {
  records: {},
}

const getters = {
  foodItemById: (state, getters, rootState, rootGetters) => (id) => {
    let foodItem = id ? state.records[id] : state.records['new id']
    if (!foodItem) return {}
    foodItem.displayName = rootGetters['menu-management/displayEnglish']
      ? foodItem.nameEnglish
      : foodItem.name
    return foodItem
  },
  foodItemsById: (state, getters) => (ids) =>
    ids ? ids.map((id) => getters['foodItemById'](id)) : [],
}

const actions = {
  getFoodItems({ commit }, { menuId, foodGroupId, query, options }) {
    return api.fetchFoodItems({ menuId, foodGroupId, query, options }).then((data) => {
      commit('STORE_FOOD_ITEMS', data.foodItemRecords)
      if (data.foodGroupRecords && Object.keys(data.foodGroupRecords).length) {
        commit(
          'menu-management/food-groups/STORE_FOOD_GROUPS',
          { records: data.foodGroupRecords },
          { root: true }
        )
      }
      if (data.stageRecords && Object.keys(data.stageRecords).length) {
        commit('menu-management/stages/STORE_STAGES', data.stageRecords, { root: true })
      }
      return data
    })
  },
  getFoodItem({ commit }, { menuId, foodItemId }) {
    return api
      .fetchFoodItem({ menuId, foodItemId })
      .then(({ foodItem, foodGroupRecords, stageRecords }) => {
        commit('STORE_FOOD_ITEM_DETAILS', foodItem)
        commit(
          'menu-management/food-groups/STORE_FOOD_GROUPS',
          { records: foodGroupRecords },
          { root: true }
        )
        commit('menu-management/stages/STORE_STAGES', stageRecords, { root: true })
      })
  },
  addFoodItem({ commit }, data) {
    commit('ADD_FOOD_ITEM', data)
  },
  updateFoodItem({ commit }, updates) {
    commit('STORE_FOOD_ITEM_DETAILS', updates)
  },
  saveNewItem({ commit }, { menuId, data }) {
    return api
      .postFoodItem({
        menuId,
        data,
      })
      .then((data) => {
        commit('STORE_FOOD_ITEM_DETAILS', data)
        commit('ADD_FOOD_ITEM', data)
        return data
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  saveItem({ commit, getters }, { itemId, menuId, data }) {
    return api
      .patchFoodItem({
        itemId,
        menuId,
        data: {
          old: getOldValues(getters.foodItemById(itemId), data),
          new: data,
        },
      })
      .then((foodItem) => {
        commit('STORE_FOOD_ITEM_DETAILS', foodItem)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  deleteItem({ commit }, { foodItemId, menuId }) {
    return api.deleteFoodItem({ foodItemId, menuId }).then(() => {
      commit('DELETE_FOOD_ITEM', { id: foodItemId })
    })
  },
}
const mutations = {
  STORE_FOOD_ITEMS(state, records) {
    Object.values(records).forEach((record) => {
      state.records[record.id] = {
        ...state.records[record.id],
        ...record,
      }
    })
  },
  STORE_FOOD_ITEM_DETAILS(state, foodItem) {
    state.records[foodItem.id] = { ...state.records[foodItem.id], ...foodItem }
  },
  ADD_FOOD_ITEM(state, data) {
    state.records[data.id] = data
  },
  DELETE_FOOD_ITEM(state, id) {
    delete state.records[id]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
