import FoodGroup from '@/store/models/food-group'
export default function (group) {
  let mapping = new FoodGroup({
    id: group.id,
    name: group.name,
    nameEnglish: group.nameEnglish,
    displayName: group.name,
    displayOrder: group.displayOrder,
    imageId: group.image,
    imageUrl: group.image ? '/image-library/images/' + group.image : null,
    manualOrdering: !group.alphabeticallySortedChildren,
    ...(group.stages && {
      stageIds: group.stages && group.stages.map((stage) => stage.id),
    }),
    ...(group.foodItems && {
      foodItemIds: group.foodItems && group.foodItems.map((item) => item.id),
    }),
  })
  return mapping
}
