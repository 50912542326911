import api from '@/store/api/taxonomy'
import { removeSearchMarkers } from '@/store/utils/taxonomy'
import generateCodeString from '@/store/utils/generateCodeString'
import generateInterpretedCode from '@/store/utils/generateInterpretedCode'

const STATUS_INITIAL = 'INITIAL'
const STATUS_VALIDATING = 'VALIDATING'
const STATUS_ERROR = 'ERROR'
const STATUS_LOADING = 'LOADING'

const InitialState = function (defaults) {
  return {
    term: null,
    facets: [],
    error: null,
    status: STATUS_INITIAL,
    validationQueue: [],
    ...defaults,
  }
}

const state = new InitialState()

const getters = {
  term: (state) => state.term,
  facets: (state) => state.facets,

  minimumReqsPassed: (state) => state.term !== null,
  error: (state) => state.error,
  code: (state) => {
    const termCode = state.term && state.term.code
    const facetCodes =
      state.facets &&
      state.facets.map((facet) => ({
        rootRecordCode: facet.rootRecord.code,
        recordCode: facet.record.code,
      }))
    return removeSearchMarkers(generateCodeString(termCode, facetCodes))
  },
  interpretedCode: (state) => {
    return generateInterpretedCode(state.term, state.facets)
  },
  // there's a duplicate of this in the generateInterpretedCode.js helper
  strippedName: (state, getters) => (record) => {
    return removeSearchMarkers(getters.actualName(record))
  },
  // there's a duplicate of this in the generateInterpretedCode.js helper
  actualName: () => (record) => record.displayName || record.termName,

  isValidating: (state) => state.status === STATUS_VALIDATING,
  isError: (state) => state.status === STATUS_ERROR,
  isLoading: (state) => state.status === STATUS_LOADING,

  facetById: (state) => (id) => {
    return state.facets.find((el) => el.record.id === id)
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },

  selectTerm({ commit, dispatch }, record) {
    commit('SET_TERM', record)
    dispatch('validate')
  },

  removeTerm({ commit, dispatch }) {
    commit('REMOVE_TERM')
    dispatch('validate')
  },

  addFacet({ commit, dispatch, rootGetters }, { record, rootRecord }) {
    const rootFacets = rootGetters['taxonomy/facets/rootRecords']
    commit('ADD_FACET', { record, rootRecord, rootFacets })
    dispatch('validate')
  },

  removeFacet({ commit, dispatch }, id) {
    commit('REMOVE_FACET', id)
    dispatch('validate')
  },

  async decode({ rootGetters, commit, dispatch }, mappingCode) {
    // pretty much reset but keep the status
    commit('SET_TERM', null)
    commit('CLEAR_FACETS')
    commit('STORE_ERROR', null)
    const splitCode = mappingCode.split('#')
    const baseTermCode = splitCode[0]
    const facetCodes = splitCode[1] && splitCode[1].split('$')
    const requiredTrees = []
    const termsUpToDate = await dispatch('taxonomy/terms/checkHash', null, { root: true })
    const facetsUpToDate = await dispatch('taxonomy/facets/checkHash', null, { root: true })
    if (baseTermCode && !termsUpToDate) {
      requiredTrees.push(dispatch('taxonomy/terms/getTree', null, { root: true }))
    }

    if (facetCodes && !facetsUpToDate) {
      requiredTrees.push(dispatch('taxonomy/facets/getTree', null, { root: true }))
    }

    commit('SET_STATUS', STATUS_LOADING)
    return Promise.all(requiredTrees)
      .then(() => {
        if (baseTermCode) {
          let term = rootGetters['taxonomy/terms/byCode'](baseTermCode)
          commit('SET_TERM', term)
        }
        if (facetCodes) {
          facetCodes.forEach((code) => {
            const splitFacetCode = code.split('.')
            const rootCode = splitFacetCode[0]
            const facetCode = splitFacetCode[1]
            const rootFacet = rootGetters['taxonomy/facets/byCode'](rootCode)
            const facet = rootGetters['taxonomy/facets/byCode'](facetCode, rootCode)
            const rootFacets = rootGetters['taxonomy/facets/rootRecords']
            commit('ADD_FACET', { record: facet, rootRecord: rootFacet, rootFacets })
          })
        }
      })
      .finally(() => {
        commit('SET_STATUS', STATUS_INITIAL)
        dispatch('validate')
      })
  },

  async validate({ commit, state, getters }) {
    commit('SET_STATUS', STATUS_VALIDATING)
    const validationId = state.validationQueue.length + 1
    //fight the evil concurrency with a validation queue
    commit('ADD_VALIDATION_INSTANCE', validationId)
    if (getters.minimumReqsPassed) {
      try {
        const term = state.term
        const payload = {
          id: term.id,
          parentId: term.parentId,
          termType: term.termType,
          detailLevel: term.detailLevel,
          facets: state.facets.map((obj) => obj.record.id),
        }

        const response = await api.validateCode(payload)

        commit('REMOVE_VALIDATION_INSTANCE', validationId)
        if (!state.validationQueue.length) {
          commit('STORE_ERROR', null)
          commit('SET_STATUS', STATUS_INITIAL)
        }

        return response
      } catch (error) {
        commit('REMOVE_VALIDATION_INSTANCE', validationId)
        if (!state.validationQueue.length) {
          commit('STORE_ERROR', error)
          commit('SET_STATUS', STATUS_ERROR)
        }
      }
    } else {
      await commit('REMOVE_VALIDATION_INSTANCE', validationId)
      if (!state.validationQueue.length) {
        commit('STORE_ERROR', null)
        commit('SET_STATUS', STATUS_INITIAL)
      }
    }
  },
}

const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  },

  RESET_STATE(state) {
    Object.assign(state, new InitialState())
  },

  SET_TERM(state, record) {
    state.term = record
  },

  REMOVE_TERM(state) {
    state.term = null
  },
  CLEAR_FACETS(state) {
    state.facets = []
  },

  ADD_FACET(state, { record, rootRecord, rootFacets }) {
    const targetIndex = state.facets.findIndex((el) => el.record.id === record.id)
    if (targetIndex === -1) {
      state.facets.push({ record, rootRecord })

      state.facets = state.facets.sort((a, b) => {
        const rootA = a.rootRecord.code
        const rootAindex = rootFacets.findIndex((facet) => facet.code === rootA)
        const rootB = b.rootRecord.code
        const rootBindex = rootFacets.findIndex((facet) => facet.code === rootB)
        const recA = a.record.code
        const recB = b.record.code

        const rootCompare = rootAindex - rootBindex

        if (rootCompare === 0) {
          return recA.localeCompare(recB)
        }

        return rootCompare
      })
    }
  },

  REMOVE_FACET(state, id) {
    const targetIndex = state.facets.findIndex((el) => el.record.id === id)
    if (targetIndex !== -1) {
      state.facets.splice(targetIndex, 1)
    }
  },

  STORE_ERROR(state, error) {
    state.error = error
  },
  ADD_VALIDATION_INSTANCE(state, id) {
    state.validationQueue.push(id)
  },
  REMOVE_VALIDATION_INSTANCE(state, id) {
    const index = state.validationQueue.findIndex((el) => el === id)
    state.validationQueue.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
