<template>
  <div>
    <input
      v-bind="{ [`data-test-${id}`]: '' }"
      :id="id"
      class="visibility-toggle-input"
      type="checkbox"
      :checked="modelValue"
      @input="$emit('update:model-value', $event.target.checked)"
    />
    <label :for="id">
      <SvgIcon :name="modelValue ? '24-ic-view_on' : '24-ic-view_off'" />
      <slot />
    </label>
  </div>
</template>
<script>
export default {
  props: ['id', 'modelValue'],
  emits: ['update:model-value'],
}
</script>

<style lang="scss">
.visibility-toggle-input {
  position: absolute;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  opacity: 0;

  & + label {
    display: inline-flex;

    svg {
      margin-right: theme('spacing.4');
      color: theme('colors.slate.DEFAULT');
    }
  }
  &:checked + label {
    svg {
      color: theme('colors.blueberry.DEFAULT');
    }
  }
  &:focus + label {
    svg {
      box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv');
    }
  }
}
</style>
