<template>
  <categories-navigation
    class="box-content-fill-remaining"
    :links="parentNavigation"
    :parent-route="{ name: 'tablet-editor-stages' }"
    :parent-label="$t('menus.tabletEditor.stage.actions.return')"
    :create-route="{ name: 'tablet-editor-stage-create' }"
    :create-label="$t('menus.tabletEditor.stages.actions.createNew')"
    data-test-stage-editor
  >
    <!-- Return to stages link -->

    <ValidationForm
      autocomplete="off"
      class="box"
      ref="form"
      :error-structure="['GENERAL', 'stage name']"
      @submit.prevent="onSubmit"
      @reset.prevent="onCancel"
    >
      <loaded-content
        :is-loading="stagesLoadingStatus === 'LOADING'"
        :has-error="stagesLoadingStatus === 'ERROR'"
        :has-data="stagesLoadingStatus === 'LOADED'"
      >
        <Breadcrumbs
          :history="[{ name: 'tablet-editor-stages', title: 'Stages' }]"
          :current="stageId ? stage.displayName : $route.meta.breadcrumb"
          class="my-4"
        />
        <div class="tablet-editor-page mb-0">
          <div class="tablet-editor-page-generic-fields">
            <!-- Name -->
            <ValidationGroup
              :validator="v$.buffer.name"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="StageName">{{ $t('menus.tabletEditor.stage.labels.name') }}</label>
              <input
                id="StageName"
                v-model.trim="v$.buffer.name.$model"
                type="text"
                class="formControl"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-name-field
                :disabled="readonly"
                @change="onNameChange"
              />
            </ValidationGroup>
            <!-- English name -->
            <ValidationGroup
              v-if="menuIsNonEnglish"
              :validator="v$.buffer.nameEnglish"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="StageNameEnglish">{{
                $t('menus.tabletEditor.stage.labels.nameEnglish')
              }}</label>
              <input
                id="StageNameEnglish"
                v-model.trim="v$.buffer.nameEnglish.$model"
                type="text"
                class="formControl"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-name-english-field
                :disabled="readonly"
              />
            </ValidationGroup>

            <!-- Custom breakdown -->
            <Custom1-select
              v-model="v$.buffer.custom1.$model"
              class="formGroup"
              :menu-id="menuId"
              :stage-id="stageId"
              :readonly="readonly"
              @mode-change="custom1ModeChanged"
            />

            <!-- Image thumbnail -->
            <div
              v-if="stageId"
              class="formGroup tablet-editor-page-avatar"
            >
              <p class="tablet-editor-page-avatar-title label">
                {{ $t('labels.image') }}
              </p>
              <Image-loader
                class="tablet-editor-page-avatar-image"
                :src="generateThumbnailUrl(buffer.image.url)"
                :aspect-ratio="0.65"
              />
              <div
                v-if="!readonly"
                class="tablet-editor-page-avatar-actions"
              >
                <button
                  v-if="buffer.image.id"
                  type="button"
                  class="button button--link"
                  data-test-show-image-editor
                  @click="editMode = 'IMAGE_EDIT'"
                >
                  {{ $t('actions.editImage') }}
                </button>
                <button
                  type="button"
                  class="button button--link"
                  data-test-show-image-selector
                  @click="editMode = 'IMAGE'"
                >
                  {{ $t('actions.chooseAnotherImage') }}
                </button>
                <button
                  type="button"
                  class="button button--link"
                  data-test-show-image-uploader
                  @click="editMode = 'IMAGE_UPLOAD'"
                >
                  {{ $t('actions.uploadNew') }}
                </button>
              </div>
            </div>
          </div>

          <!-- Food group editor -->
          <div
            v-if="editMode === 'FOOD_GROUPS' && !stageId && !readonly"
            data-test-save-first
          >
            {{ $t('menus.tabletEditor.stage.messages.foodGroupsNotAvailable') }}
          </div>

          <Chip-selector
            v-if="editMode === 'FOOD_GROUPS' && stageId"
            v-model="v$.buffer.foodGroups.$model"
            :status="foodGroupsLoadingStatus"
            :available-items="availableFoodGroups"
            :available-actions="readonly ? [] : ['add', 'remove']"
            data-test-food-groups
            @request-available-items="getAllFoodGroups"
          >
            <template #title>
              <span
                v-if="!buffer.foodGroups.length"
                data-test-title-no-food-groups
                >{{ $t('menus.tabletEditor.stage.titles.noFoodGroups') }}</span
              >
              <span
                v-else
                data-test-title-food-groups
                >{{ $t('menus.tabletEditor.stage.titles.foodGroups') }}</span
              >
              <router-link
                v-if="buffer.foodGroups.length"
                :to="{
                  name: 'tablet-editor-food-groups',
                  params: {
                    menuId,
                    stageId,
                  },
                }"
              >
                {{ $t('actions.viewAsGrid') }}
              </router-link>
            </template>
            <template #show-available>
              {{ $t('menus.tabletEditor.stage.actions.showFoodGroups') }}
            </template>
            <template #custom-chips>
              <Chip
                v-if="!readonly"
                tag="li"
                class="chip--squared"
              >
                <router-link
                  :to="{
                    name: 'tablet-editor-food-group-create',
                    query: { ...$route.query, returnPath: $route.path },
                  }"
                  data-test-food-group-create
                >
                  {{ $t('menus.tabletEditor.foodGroups.actions.createNew') }}
                </router-link>
              </Chip>
            </template>
            <template #no-data>
              <p data-test-no-more-food-groups>
                {{ $t('menus.tabletEditor.stage.messages.foodGroupsEmpty') }}
              </p>
            </template>
          </Chip-selector>

          <Image-selector
            v-if="editMode === 'IMAGE'"
            v-model="v$.buffer.image.$model"
            class="tablet-editor-page-image-selector"
            :url-params="{ menuId: menuId, stageId: stageId }"
            :org-id="menu.parentLocationId"
            @close="editMode = 'FOOD_GROUPS'"
            @save="imageChanged"
          />
          <Image-uploader
            v-if="editMode === 'IMAGE_UPLOAD'"
            class="tablet-editor-page-image-selector"
            :org-id="menu.parentLocationId"
            :aspect-ratio="0.65"
            @close="editMode = 'FOOD_GROUPS'"
            @new-file="imageUploaded"
          />
          <Image-editor
            v-if="editMode === 'IMAGE_EDIT'"
            :id="buffer.image.id"
            :url="buffer.image.url"
            :org-id="menu.parentLocationId"
            :aspect-ratio="0.65"
            @close="editMode = 'FOOD_GROUPS'"
          />
        </div>

        <div
          v-if="!showNameChangeWarning && !readonly"
          class="buttonGroup"
        >
          <button
            class="button button--primary"
            type="submit"
            :disabled="saveDisabled || !v$.$anyDirty"
            data-test-save
          >
            {{ $t('actions.save') }}
          </button>
          <button
            class="button button--secondary"
            type="reset"
            :disabled="saveDisabled || !v$.$anyDirty"
            data-test-reset
          >
            {{ $t('actions.cancel') }}
          </button>
        </div>

        <transition name="slide-up">
          <div
            v-if="showNameChangeWarning"
            class="errorBlock is-warning text-carrot mb-4"
            data-test-rename-warning
          >
            <div class="errorBlock-icon">
              <SvgIcon name="24-ic-warning" />
            </div>
            <p
              v-html="
                $t('messages.itemRenamePhoto', {
                  item: buffer.nameEnglish,
                })
              "
            ></p>
          </div>
        </transition>

        <div
          v-if="showNameChangeWarning"
          class="buttonGroup buttonGroup--halfs"
        >
          <button
            type="button"
            class="button button--secondary"
            data-test-revert-name
            @click.prevent="revertName"
          >
            {{ $t('actions.revert') }}
          </button>
          <button
            type="button"
            class="button button--primary"
            data-test-confirm-name
            @click.prevent="confirmName"
          >
            {{ $t('actions.confirm') }}
          </button>
        </div>

        <DeleteWithPrompt
          v-if="stageId && !readonly"
          @confirm="deleteStage"
          ref="delete"
          class="my-4"
        >
          <p>{{ $t('menus.tabletEditor.stage.messages.deletePrompt') }}</p>
        </DeleteWithPrompt>
      </loaded-content>
    </ValidationForm>
  </categories-navigation>
</template>

<script>
import CategoriesNavigation from '@/components/CategoriesNavigation.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationForm from '@/components/Validation/ValidationForm.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import Custom1Select from './custom1-select.vue'
import ImageSelector from './image-selector.vue'
import ImageUploader from './image-uploader.vue'
import ImageEditor from './image-editor.vue'
import ImageLoader from '@/components/image-loader.vue'
import Chip from '@/components/Chip/Chip.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import ChipSelector from './chip-selector.vue'

import menuMixin from '../menuMixin'
import imagesMixin from '@/mixins/images'
import returnPathMixin from '@/mixins/returnPathMixin'

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { intersection, invert } from 'lodash'
import { useToast } from 'vue-toastification'

function EditModel(overwrites) {
  return {
    name: null,
    nameEnglish: null,
    custom1: null,
    foodGroups: [],
    image: {
      id: null,
      url: null,
    },
    ...overwrites,
  }
}

function FoodGroupModel(overwrites) {
  return {
    id: null,
    name: null,
    displayName: null,
    hasTranslation: true,
    imageUrl: null,
    link: null,
    ...overwrites,
  }
}

export default {
  components: {
    CategoriesNavigation,
    Breadcrumbs,
    ValidationGroup,
    ValidationForm,
    LoadedContent,
    Custom1Select,
    Chip,
    DeleteWithPrompt,
    ImageLoader,
    ImageSelector,
    ImageUploader,
    ImageEditor,
    ChipSelector,
  },
  mixins: [menuMixin, imagesMixin, returnPathMixin],
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      buffer: new EditModel(),
      saveDisabled: false,
      editMode: 'FOOD_GROUPS',
      showNameChangeWarning: false,
      confirmDelete: false,
      showFoodGroupsBin: false,
      stagesLoadingStatus: 'IDLE',
      foodGroupsLoadingStatus: 'IDLE',
      inputOutput: {
        name: 'name',
        nameEnglish: 'nameEnglish',
        custom1: 'custom1Id',
        foodGroups: 'foodGroupIds',
        image: 'imageId',
      },
    }
  },
  validations() {
    return {
      buffer: {
        name: {
          required,
        },
        nameEnglish: this.menuIsNonEnglish && {
          required,
        },
        custom1: {},
        foodGroups: {},
        image: {},
      },
    }
  },
  computed: {
    readonly() {
      return this.menu && this.menu.archived
    },
    parentNavigation() {
      let stages = this.$store.getters['menu-management/menus/stagesInMenu'](this.menuId)
      return stages.map((stage) => {
        return {
          id: stage.id,
          name: stage.name,
          imageUrl: stage.imageUrl,
          route: {
            name: 'tablet-editor-stage',
            params: {
              stageId: stage.id,
            },
          },
        }
      })
    },
    stageId() {
      return this.$route.params.stageId
    },
    stage() {
      return this.$store.getters['menu-management/stages/stageById'](this.stageId)
    },
    // list of food groups that can be assigned to the stage
    availableFoodGroups() {
      let foodGroups = this.$store.getters['menu-management/menus/foodGroupsInMenu'](this.menuId)
      return foodGroups.map(
        (fg) =>
          new FoodGroupModel({
            id: fg.id,
            hasWarning: this.menuIsNonEnglish && !fg.nameEnglish,
            warningMessage: this.$t('menus.tabletEditor.foodGroup.messages.noTranslation'),
            displayName: fg.displayName || fg.name,
            imageUrl: fg.imageUrl,
            link: {
              name: 'tablet-editor-food-group',
              params: { ...this.$route.params, foodGroupId: fg.id },
            },
          })
      )
    },
  },
  created() {
    this.stagesLoadingStatus = 'LOADING'
    this.$store
      .dispatch('menu-management/stages/getStages', { menuId: this.menuId })
      .then(() => {
        this.buffer = this.prepopulateFields(this.stage)
        this.stagesLoadingStatus = 'LOADED'
      })
      .catch(() => {
        this.stagesLoadingStatus = 'ERROR'
      })
  },
  methods: {
    onNameChange() {
      if (!this.menuIsNonEnglish) {
        this.buffer.nameEnglish = this.buffer.name
      }
      if (this.stage && this.v$.buffer.name.$model !== this.stage.name) {
        let hasImage = !!this.stage.imageId
        this.showNameChangeWarning = hasImage
      } else {
        this.showNameChangeWarning = false
      }
    },
    confirmName() {
      this.showNameChangeWarning = false
    },
    revertName() {
      this.buffer.name = this.stage.name
      this.buffer.nameEnglish = this.stage.nameEnglish
      this.showNameChangeWarning = false
    },
    onSubmit() {
      this.v$.$touch()
      if (this.v$.buffer.$invalid) return
      this.$refs.form.clearServerMessages()
      this.saveDisabled = true

      let updates = this.generateSavePayload()
      if (this.stageId) {
        this.saveChanges({ data: updates })
      } else {
        this.createNew({
          name: updates.name,
          custom1Id: updates.custom1Id,
          nameEnglish: updates.nameEnglish,
        }).finally(() => {
          this.saveDisabled = false
        })
      }
    },

    generateSavePayload({ props = [] } = {}) {
      props = props.length ? props : Object.values(this.inputOutput)
      let data = {
        name: this.buffer.name,
        nameEnglish: this.buffer.nameEnglish,
        custom1Id: this.buffer.custom1 || null,
        foodGroupIds: this.buffer.foodGroups.length
          ? this.buffer.foodGroups.map((item) => item.id)
          : null,
        imageId: this.buffer.image.id || null,
      }
      return intersection(props, Object.keys(data)).reduce((saveData, key) => {
        saveData[key] = data[key]
        return saveData
      }, {})
    },
    onCancel() {
      this.v$.$reset()
      this.buffer = this.prepopulateFields(this.stage)
      if (!this.stageId) {
        this.goBack({
          backupRoute: {
            name: 'tablet-editor-stages',
          },
        })
      }
    },
    prepopulateFields(stage) {
      if (!stage) return new EditModel()
      let foodGroups = this.$store.getters['menu-management/stages/foodGroupsInStage'](this.stageId)
      return new EditModel(
        stage && {
          name: stage.name,
          nameEnglish: stage.nameEnglish,
          custom1: stage.custom1Id,
          image: {
            id: stage.imageId,
            url: stage.imageUrl,
          },
          foodGroups: foodGroups.map(
            (fg) =>
              new FoodGroupModel({
                id: fg.id,
                displayName: fg.displayName || fg.name,
                hasWarning: this.menuIsNonEnglish && !fg.nameEnglish,
                warningMessage: this.$t('menus.tabletEditor.foodGroup.messages.noTranslation'),
                imageUrl: fg.imageUrl,
                link: {
                  name: 'tablet-editor-food-group',
                  params: { ...this.$route.params, foodGroupId: fg.id },
                },
              })
          ),
        }
      )
    },
    saveChanges({ data }) {
      return this.$store
        .dispatch('menu-management/stages/saveChanges', {
          menuId: this.menuId,
          stageId: this.stageId,
          data,
        })
        .then(() => {
          let outputInput = invert(this.inputOutput)
          Object.keys(data).forEach((key) => {
            this.v$.buffer[outputInput[key]].$reset()
          })
          let remainingDirtyFields = Object.keys(this.inputOutput).filter((key) => {
            return this.v$.buffer[key].$anyDirty
          })
          if (!remainingDirtyFields.length) {
            this.v$.$reset()
          }
          this.saveDisabled = false
          this.toast.success(this.$t('menus.tabletEditor.stage.messages.saveSuccess'))
        })
        .catch(this.$refs.form.handleValidationErrorsInResponse)
    },
    createNew(data) {
      return this.$store
        .dispatch('menu-management/stages/createNew', {
          menuId: this.menuId,
          data,
        })
        .then((newStage) => {
          this.$router.push({
            name: 'tablet-editor-stage',
            params: { ...this.$route.params, stageId: newStage.id },
          })
          this.$nextTick(() => {
            this.toast.success(this.$t('menus.tabletEditor.stage.messages.createSuccess'))
          })
        })
        .catch((error) => {
          this.$refs.form.handleValidationErrorsInResponse(error)
        })
    },
    custom1ModeChanged(mode) {
      this.saveDisabled = mode === 'CREATE'
    },
    deleteStage() {
      return this.$store
        .dispatch('menu-management/stages/deleteStage', {
          menuId: this.menuId,
          stageId: this.stageId,
          foodGroupId: this.foodGroupId,
        })
        .then(() => {
          this.toast.success(this.$t('menus.tabletEditor.stage.messages.deleteSuccess'))
          this.goBack({
            backupRoute: {
              name: 'tablet-editor-stages',
            },
          })
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.deleteData'))
        })
        .finally(() => {
          this.$refs.delete.finishProcessing()
        })
    },
    getAllFoodGroups() {
      this.showFoodGroupsBin = true
      this.foodGroupsLoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/food-groups/getFoodGroups', { menuId: this.menuId })
        .then(() => {
          this.foodGroupsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.foodGroupsLoadingStatus = 'ERROR'
        })
    },
    imageUploaded({ id, file }) {
      this.buffer.image = {
        id,
        url: URL.createObjectURL(file),
      }
      this.editMode = 'FOOD_GROUPS'
      this.saveChanges({ data: this.generateSavePayload({ props: ['imageId'] }) })
    },
    imageChanged({ id, url }) {
      this.buffer.image = { id, url }
      this.editMode = 'FOOD_GROUPS'
      this.saveChanges({ data: this.generateSavePayload({ props: ['imageId'] }) })
    },
  },
}
</script>

<style lang="scss"></style>
