<template>
  <tr :key="item.id">
    <td>{{ item.name }}</td>
    <td>{{ item.taxonomy.mapping }}</td>
    <td>{{ item.accuracy }}%</td>
    <td>
      <div class="buttonGroup">
        <button
          v-test="'select'"
          v-tooltip="{
            theme: 'tooltip-neutral',
            content: isSelected
              ? $t('menus.foodItems.actions.deselectSimilar')
              : $t('menus.foodItems.actions.selectSimilar'),
            placement: 'top',
          }"
          class="button button--icon button--round button--primary"
          type="button"
          :aria-label="$t('menus.foodItems.actions.deselectSimilar')"
          @click="onClick"
        >
          <transition
            name="rotate-fade"
            mode="out-in"
          >
            <SvgIcon
              v-if="isSelected"
              key="select"
              name="24-ic-clear"
            />
            <SvgIcon
              v-else
              key="deselect"
              name="24-ic-check"
            />
          </transition>
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  props: ['item', 'foodItem'],
  emits: ['select', 'deselect'],
  computed: {
    isSelected() {
      return this.item.taxonomy.code === this.foodItem.editing.taxonomy.code
    },
  },
  methods: {
    onClick() {
      if (this.isSelected) {
        this.$emit('deselect')
      } else {
        this.$emit('select', this.item)
      }
    },
  },
}
</script>
