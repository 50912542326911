<template>
  <Collapsible-section
    id="packageSection"
    tag="section"
    :force-open="isOpen"
    data-test-package-section
    @open="onOpen"
    @close="onClose"
  >
    <template #header>
      {{ $t('organisations.overview.package.title') }}
    </template>
    <!-- Summary of selected options -->
    <template #header-side>
      <Loading-Spinner v-if="loadingStatus === 'LOADING'" />
      <ul
        v-if="!isOpen && loadingStatus === 'LOADED'"
        data-test-package-summary
      >
        <Filter-chip
          v-if="selectedPackage"
          tag="li"
        >
          {{ selectedPackage.name }}
        </Filter-chip>
      </ul>
    </template>
    <template #default>
      <Loaded-content
        :is-loading="loadingStatus === 'LOADING'"
        :has-error="loadingStatus === 'ERROR'"
        :has-data="loadingStatus === 'LOADED' && !!node"
      >
        <form
          :action="$route.fullPath"
          novalidate
          @submit.prevent="onSave"
          @reset.prevent="resetValues"
        >
          <Validation-group
            :validator="v$.packageId"
            class="formGroup formGroup--half"
            v-slot="{ hasAnyErrors }"
          >
            <label for="localisationLocale">{{ $t('organisations.labels.package') }}</label>
            <select
              id="localisationLocale"
              v-model="v$.packageId.$model"
              class="customSelect"
              :class="{ 'is-invalid': hasAnyErrors }"
              data-test-package-select
              required
              @change="onPackageChange"
            >
              <option
                :value="null"
                disabled
              >
                Select a package
              </option>
              <option
                v-for="orgPackage in packages"
                :key="orgPackage.id"
                :value="orgPackage.id"
              >
                {{ orgPackage.name }}
              </option>
            </select>
          </Validation-group>
          <!-- Change warning message -->
          <transition name="slide-up">
            <div
              v-if="packageChangeWarningIsVisible"
              class="errorBlock is-warning text-carrot my-4 flex flex-middle"
              data-test-change-package-warning
            >
              <div class="errorBlock-icon">
                <SvgIcon name="24-ic-warning" />
              </div>
              <p>
                {{ $t('organisations.overview.package.changeWarning') }}
              </p>
            </div>
          </transition>

          <!-- Confirm package change-->
          <div
            v-if="packageChangeWarningIsVisible"
            class="buttonGroup"
          >
            <button
              type="button"
              class="button button--primary"
              data-test-confirm
              @click.prevent="saveData"
            >
              {{ $t('actions.confirm') }}
            </button>
            <button
              type="button"
              class="button button--secondary"
              data-test-revert
              @click.prevent="onCancel"
            >
              {{ $t('actions.cancel') }}
            </button>
          </div>

          <!-- Save and cancel -->
          <div
            v-if="!packageChangeWarningIsVisible"
            class="buttonGroup"
          >
            <button
              type="submit"
              class="button button--primary"
              :disabled="!v$.$anyDirty || savingStatus === 'SAVING'"
              data-test-save
            >
              <SvgIcon
                v-if="savingStatus !== 'SAVING'"
                name="24-ic-check"
              />
              <Loading-Spinner
                v-if="savingStatus === 'SAVING'"
                class="icon spinner--white"
              />
              <span>{{ $t('actions.saveTheseChanges') }}</span>
            </button>
            <button
              type="reset"
              class="button button--secondary"
              :disabled="!v$.$anyDirty || savingStatus === 'SAVING'"
              data-test-cancel
            >
              {{ $t('actions.cancel') }}
            </button>
          </div>
        </form>
      </Loaded-content>
    </template>
  </Collapsible-section>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import FilterChip from '@/components/FilterChip.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LoadedContent,
    CollapsibleSection,
    FilterChip,
    ValidationGroup,
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
      selectedPackage: null,
      packageId: null,
      packageChangeWarningIsVisible: false,
    }
  },
  computed: {
    ...mapGetters('hierarchy/nodes', ['packages']),
  },
  created() {
    if (this.packages && this.packages.length) {
      this.loadingStatus = 'LOADED'
      this.resetValues()
    } else {
      this.getPackages().then(this.resetValues)
    }
  },
  validations: {
    propagateChanges: {},
    packageId: { required },
  },

  methods: {
    getPackages() {
      this.loadingStatus = 'LOADING'
      return this.$store
        .dispatch('hierarchy/nodes/getPackages')
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch(() => {
          this.loadingStatus = 'ERROR'
        })
    },
    onOpen() {
      this.$emit('open')
    },
    onClose() {
      this.$emit('close')
    },

    onSave() {
      if (!this.selectedPackage) {
        this.saveData()
      } else if (this.selectedPackage.id !== this.packageId) {
        this.packageChangeWarningIsVisible = true
      } else {
        this.packageChangeWarningIsVisible = false
      }
    },

    onCancel() {
      this.packageChangeWarningIsVisible = false
      this.resetValues()
    },

    saveData() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.savingStatus = 'SAVING'
      this.packageChangeWarningIsVisible = false
      this.$store
        .dispatch('hierarchy/nodes/saveNodeChanges', {
          nodeId: this.node.id,
          data: {
            locationDetails: { packageId: this.packageId },
          },
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.resetValues()
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    resetValues() {
      this.selectedPackage = this.packages.find(({ id }) => {
        return id == this.node.locationDetails.packageId
      })
      this.packageId = this.selectedPackage ? this.selectedPackage.id : null
      this.v$.$reset()
    },
  },
}
</script>

<style></style>
