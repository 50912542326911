import axios, { spitURL } from '../api-axios'
import mapUser from './mappings/get-user'
import mapUserGroups from './mappings/get-user-group'
import mapOrganisation from './mappings/get-organisation'
import { keyBy } from 'lodash'

export default {
  fetchUsers({ query }) {
    return axios
      .get(spitURL('/user-management/users', query))
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        let users = data.map(mapUser)
        let organisations = Object.values(metadata.organizationDetails).map(mapOrganisation)
        return {
          records: keyBy(users, 'id'),
          ids: data.map((user) => user.id),
          organisationRecords: keyBy(organisations, 'id'),
          total: metadata.total,
        }
      })
  },
  fetchInternalUsers() {
    return axios
      .get('/user-management/internal-users')
      .then((response) => response.data)
      .then(({ data, metadata }) => {
        return {
          records: keyBy(data.map(mapUser), 'id'),
          ids: data.map((user) => user.id),
          metadata,
        }
      })
  },
  fetchUserGroups() {
    return axios
      .get('/user-management/groups')
      .then((response) => response.data)
      .then((json) => json.data)
      .then((groups) => keyBy(groups.map(mapUserGroups), 'id'))
  },
  postUser(data) {
    return axios
      .post('/user-management/user', data)
      .then((resp) => resp.data)
      .then((json) => {
        let user = json.data
        let subjectOrganization = json.metadata.organizationDetails[user.subjectOrganizationId]
        user.subjectOrganization = subjectOrganization
        return { record: mapUser(user), subjectOrganization }
      })
  },
  patchUser({ userId, ...data }) {
    return axios
      .patch(`/user-management/user/${userId}`, data)
      .then((resp) => resp.data)
      .then((json) => {
        let user = json.data
        let subjectOrganization = json.metadata.organizationDetails[user.subjectOrganizationId]
        user.subjectOrganization = subjectOrganization
        return { record: mapUser(user), subjectOrganization }
      })
  },
  passwordReset({ userId }) {
    return axios.put(`/user-management/user/${userId}/password-reset`)
  },
  forcePasswordReset({ userId }) {
    return axios
      .put(`/user-management/user/${userId}/force-password-reset`)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapUser)
  },
  postActiveStatus({ userId, ...data }) {
    return axios
      .post(`/user-management/user/${userId}/active-status`, data)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapUser)
  },
}
