<template>
  <div class="categories-navigation-container">
    <div
      v-click-outside="close"
      class="categories-navigation"
      :class="{ 'categories-navigation--open': isOpen }"
    >
      <button
        class="categories-navigation-toggle"
        @click="isOpen = !isOpen"
      >
        <SvgIcon
          :class="{ 'is-rotated-180': isOpen }"
          name="24-ic-arrow-next"
        />
        <span
          v-if="isOpen"
          class="sr-only"
          >{{ $t('actions.categoriesNavigation.hide') }}</span
        >
        <span
          v-else
          class="sr-only"
          >{{ $t('actions.categoriesNavigation.show') }}</span
        >
      </button>
      <nav
        ref="nav-container"
        class="categories-navigation-links"
        :style="{ visibility: isOpen ? 'visible' : 'hidden' }"
      >
        <div
          v-if="parentRoute"
          class="categories-navigation-parent-link"
        >
          <router-link
            class="button button--tertiary"
            :to="parentRoute"
          >
            <SvgIcon name="24-ic-arrow-prev" />
            <span>{{ parentLabel ? parentLabel : 'View all' }}</span>
          </router-link>
        </div>
        <ul class="categories-navigation-list">
          <li
            v-for="link in links"
            :key="link.id"
            @mousedown="keepOpen"
          >
            <router-link
              :to="link.route"
              class="categories-navigation-link"
              @blur="manageFocus"
            >
              <div class="categories-navigation-link-thumbnail">
                <image-loader
                  :src="link.imageUrl"
                  :aspect-ratio="1"
                />
              </div>
              {{ link.name }}
            </router-link>
          </li>
        </ul>
        <div
          v-if="createRoute"
          class="categories-navigation-create-link"
        >
          <router-link
            class="button button--tertiary"
            :to="createRoute"
            @blur="manageFocus"
          >
            <SvgIcon name="24-ic-add" />
            <span>{{ createLabel ? createLabel : 'Create new' }}</span>
          </router-link>
        </div>
      </nav>
    </div>
    <slot />
  </div>
</template>

<script>
import ImageLoader from '@/components/image-loader.vue'
export default {
  components: {
    ImageLoader,
  },
  props: {
    /**
     * List of links
     *
     * @id - you know...
     * @name - human readable name
     * @route - a vue router route
     * @imageUrl - thumbnail image link
     */
    links: Array,
    /**
     * Link to the parent category
     *
     * Should be a vue-router object
     */
    parentRoute: Object,
    /**
     * Link to parent label that replaces 'View all'
     */
    parentLabel: String,
    /**
     * Link to a create new item in the same category
     *
     * Should be a vue-router object
     */
    createRoute: Object,
    /**
     * Create new item label
     */
    createLabel: String,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  created() {
    if (window.keepCategoriesNavigationOpen) {
      this.isOpen = true
      delete window.keepCategoriesNavigationOpen
    }
  },
  methods: {
    keepOpen() {
      window.keepCategoriesNavigationOpen = true
    },
    close() {
      this.isOpen = false
    },
    manageFocus() {
      window.setTimeout(() => {
        if (
          this.$refs['nav-container'] &&
          !this.$refs['nav-container'].contains(document.activeElement)
        ) {
          this.isOpen = false
        }
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.categories-navigation-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  border-top: 1px solid theme('colors.slate.DEFAULT');
  position: relative;
  min-height: 20rem;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    position: relative;
    width: 2rem;
    flex: 0 0 auto;
  }

  // stretch content properly
  > * {
    flex: 1 1 auto;
  }
}

.categories-navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: theme('colors.slate.DEFAULT');
  z-index: 1;
  transform: translateX(-100%);
  will-change: transform;
  transition: transform 0.3s ease-in-out;
  background: theme('colors.white');

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -2.5rem;
    bottom: 0;
    width: 0.5rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgb(0, 0, 0, 0.15) 0%, rgb(0, 0, 0, 0) 100%);
  }
}

.categories-navigation--open {
  transform: translateX(0);

  &::after {
    opacity: 1;
  }

  .categories-navigation-toggle {
    background-color: theme('colors.white');
    border-color: transparent transparent transparent theme('colors.slate.DEFAULT');
  }

  .box-content-fill-remaining & .categories-navigation-toggle {
    border-radius: 0;
  }
}

.categories-navigation-toggle {
  padding: 0;
  margin: 0;
  display: block;
  border: solid theme('colors.slate.DEFAULT');
  border-width: 0 1px 1px;
  background-color: theme('colors.white');
  position: absolute;
  width: 2rem;
  top: 0;
  left: 100%;
  bottom: 0;
  color: theme('colors.blueberry.DEFAULT');
  will-change: background-color;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    background-color: theme('colors.acai.200');
  }
}

.categories-navigation-links {
  position: relative;
  overflow: auto;
  height: 100%;
}

.categories-navigation-parent-link,
.categories-navigation-create-link {
  padding: theme('spacing.1');
  display: flex;
  justify-content: center;
}

.categories-navigation-list {
  border-top: 1px solid theme('colors.slate.DEFAULT');
}

.categories-navigation-link {
  padding: theme('spacing.1') theme('spacing.2');
  display: flex;
  min-height: 2.5rem;
  align-items: center;
  border-bottom: 1px solid theme('colors.slate.DEFAULT');

  &:hover,
  &:focus {
    outline: none;
    background-color: theme('colors.acai.200');
    text-decoration: none;
  }

  &.is-active {
    background-color: theme('colors.lemon.100');
  }
}

.categories-navigation-link-thumbnail {
  margin-right: theme('spacing.2');
  width: 2.5rem;
}

// edge cases
.box-content-fill-remaining.categories-navigation-container {
  border-radius: 0 0 0 theme('borderRadius.lg');
}

.box-content-fill-remaining .categories-navigation-toggle {
  transition: all 0.3s ease-in-out;
  border-radius: 0 0 0 theme('borderRadius.lg');
}
</style>
