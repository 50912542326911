import processQualifiedName from '@/store/utils/processQualifiedName'

export default function (site) {
  const processedQualifiedName = processQualifiedName(site.qualifiedName)
  return {
    ...site,
    id: site.id,
    legacyId: site.legacyId,
    currency: site.currency,
    timeZone: site.timeZone,
    packageName: site.packageName,
    archived: site.archived,
    baselineStart: site.baselineStart,
    baselineEnd: site.baselineEnd,
    qualifiedName: site.qualifiedName,
    orgName: processedQualifiedName.orgName, // L1 org name
    parsedName: processedQualifiedName.parsedName, // L14 / L15 / L16
    shortName: processedQualifiedName.shortName, // L1 / ... / L14 / L15 / L16
    siteContext: processedQualifiedName.siteContext,
    orgQualifiedName: processedQualifiedName.orgQualifiedName, // L1 / .. / L16 - everything in between
  }
}
