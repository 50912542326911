<template>
  <div
    v-if="hideSingleFilter ? buttons.length > 1 : true"
    v-test="'filters-group'"
    class="filtersGroup"
  >
    <slot>
      <filter-option
        v-for="button in buttons"
        :key="button.id"
        :counter="button.items"
        :checked="button.checked"
        :disabled="button.disabled"
        :icon="button.icon"
        @selected="onSelect(button)"
      >
        {{ button.title }}
      </filter-option>
    </slot>
  </div>
</template>

<script>
import FilterOption from '@/components/FilterOption.vue'
export default {
  components: {
    FilterOption,
  },
  props: [
    'filters', // Object[] { id, title, items, highlight, disabled, icon }
    'behaviour', // 'checkbox' or 'radio' (checkbox not yet implemented)
    'selectedId', // id of the selected item
    'saveToUrl', // check if this component should save the filter `id` directly to the url query params as 'filteredBy',
    'hideEmptyFilters', // Boolean that is set to hide filters that have 0 on their counters
    'hideSingleFilter', // Hides all buttons if one single filter is left to be displayed
  ],
  emits: ['filteredBy'],
  data() {
    return {
      buttons: [],
    }
  },
  computed: {
    checkedButtons() {
      return this.buttons.filter((button) => button.checked)
    },
    checkedButtonIds() {
      return this.checkedButtons.map((button) => button.id)
    },
    visibleButtons() {
      return this.buttons.filter((button) => button.items)
    },
  },
  watch: {
    filters: {
      immediate: true,
      handler(filters) {
        this.buttons = filters.map((filter) => {
          return {
            ...filter,
            checked: this.verifyIfChecked(filter.id),
          }
        })
        if (this.hideEmptyFilters) {
          this.buttons = this.buttons.filter(
            // hide filter buttons that have a counter set to zero unless they're actually selected
            // in which case keep displaying until filter is changed
            (button) => {
              if (button.items === undefined) {
                // show the filters if a counter has not been defined
                return true
              } else if (button.items === true) {
                // show the filters if the counter is boolean true
                return true
              } else if (button.items > 0 || this.checkedButtonIds.includes(button.id)) {
                //  show the counters if there are items or the button is already selected
                return true
              }
            }
          )
        }
      },
    },
  },
  methods: {
    verifyIfChecked(id) {
      if (this.selectedId) {
        if (this.selectedId === id) {
          return true
        } else {
          return false
        }
      }
    },
    onSelect(button) {
      if (this.behaviour === 'radio' && !button.checked) {
        this.radioChange(button)
        if (this.saveToUrl) {
          // save the filter to url and also reset pagination if there is one
          // because filters should always display data from the beginning
          this.$router.push({
            query: {
              ...this.$route.query,
              filteredBy: button.id,
              page: undefined,
            },
          })
        } else {
          this.$emit('filteredBy', {
            id: button.id,
            title: button.title,
          })
        }
      }
    },
    radioChange(checkedButton) {
      if (this.checkedButtons[0].id != checkedButton.id) {
        this.buttons.forEach((button) => {
          if (checkedButton.id === button.id) {
            button.checked = true
          } else {
            button.checked = false
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
.filtersGroup {
  margin-top: calc(-1 * theme('spacing.2'));
  margin-right: calc(-1 * theme('spacing.2'));
  display: flex;
  flex-wrap: wrap;
}
</style>
