<template>
  <Page-section>
    <Section-header v-if="!editingDisabled">
      <template #section-title>Bulk update</template>
      <template #section-navigation-tabs>
        <Bulk-update-tabs :counters="counters" />
      </template>
      <template
        v-if="hasChanges"
        #section-actions
      >
        <button
          class="button button--primary"
          type="button"
          :disabled="savingStatus === 'SAVING' || !hasValidChanges"
          @click="applyAll"
        >
          {{ $t('menus.bulkUpdate.action.updateMenu') }}
        </button>
        <button
          class="button button--secondary"
          type="button"
          :disabled="savingStatus === 'SAVING'"
          @click="cancelBulkUpdate"
        >
          {{ $t('menus.bulkUpdate.action.cancelRemaining') }}
        </button>
      </template>
      <template
        v-else-if="loadingStatus === 'ERROR'"
        #section-actions
      >
        <button
          class="button button--secondary"
          type="button"
          :disabled="savingStatus === 'SAVING'"
          @click="cancelBulkUpdate"
        >
          {{ $t('menus.bulkUpdate.action.cancelRemaining') }}
        </button>
      </template>
    </Section-header>

    <Loaded-content
      v-if="!editingDisabled"
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus == 'ERROR' && !loadingErrorMessages.length"
      :has-data="hasData"
      :no-results-type="loadingErrorMessages.length ? 'WARNING' : 'NEUTRAL'"
    >
      <template
        v-if="loadingErrorMessages.length"
        #no-data
      >
        <ol>
          <li
            v-for="error in loadingErrorMessages"
            :key="error.message"
            class="mb-2"
          >
            {{ error.message }}
          </li>
        </ol>
      </template>

      <template
        v-else
        #no-data
      >
        <router-link :to="{ name: 'bulk-update-upload' }">
          {{ $t('menus.bulkUpdate.messages.empty') }}
        </router-link>
      </template>

      <div
        v-if="loadingErrorMessages.length"
        class="alert alert--warning mb-4"
      >
        <SvgIcon name="24-ic-warning" />
        <ol>
          <li
            v-for="error in loadingErrorMessages"
            :key="error.message"
            class="mb-2"
          >
            {{ error.message }}
          </li>
        </ol>
      </div>

      <router-view
        :saving-status="savingStatus"
        @saving="onSave"
      />
    </Loaded-content>
    <div
      v-else-if="editingDisabled && menu.archived"
      class="flex flex-column flex-middle"
    >
      <h1 class="mt-8 mb-2">{{ $t('menus.bulkUpdate.messages.disabledTitle') }}</h1>
      <p class="h3">{{ $t('menus.bulkUpdate.messages.disabledMenuArchived') }}</p>
      <SvgIcon
        class="NotFound-illustration"
        name="error-page"
      />
    </div>
  </Page-section>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import BulkUpdateTabs from './BulkUpdate/BulkUpdateTabs.vue'
import axios from 'axios'

import { useToast } from 'vue-toastification'
import { mapGetters } from 'vuex'

export default {
  components: {
    LoadedContent,
    BulkUpdateTabs,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('menu-management/bulk-update/RESET_STATE')
    next()
  },
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      csvFile: null,
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
      loadingErrorMessages: [],
      cancelGetChanges: null,
    }
  },
  computed: {
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    editingDisabled() {
      return this.menu.archived
    },
    hasChanges() {
      return this.$store.getters['menu-management/bulk-update/hasChanges']
    },
    ...mapGetters('menu-management/bulk-update', [
      'hasValidChanges',
      'foodItemsGroupedByName',
      'stagesRecordsList',
      'foodGroupRecordsList',
    ]),
    counters() {
      return {
        foodItems: this.foodItemsGroupedByName.length,
        stages: this.stagesRecordsList.length,
        foodGroups: this.foodGroupRecordsList.length,
      }
    },
    hasData() {
      return (
        !this.loadingErrorMessages.length &&
        ((this.loadingStatus === 'LOADED' && this.hasChanges) ||
          this.$route.name === 'bulk-update-upload')
      )
    },
  },
  created() {
    this.cancelGetChanges = axios.CancelToken.source()
    if (this.hasChanges) {
      this.loadingStatus = 'LOADED'
    } else {
      this.loadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/bulk-update/getChanges', {
          menuId: this.menuId,
          options: { cancelToken: this.cancelGetChanges.token },
        })
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            this.loadingStatus = 'LOADED'
          } else {
            this.loadingStatus = 'ERROR'
            this.loadingErrorMessages = err.data.errors
          }
        })
    }
  },
  methods: {
    cancelBulkUpdate() {
      if (this.cancelGetChanges.token) {
        this.cancelGetChanges.cancel()
      }
      this.$store
        .dispatch('menu-management/bulk-update/clearBulkChanges', {
          menuId: this.menuId,
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.bulkUpdate.clear'))
          this.loadingStatus = 'LOADED'
          this.loadingErrorMessages = []
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.success.bulkUpdate.clearError'))
          return
        })
    },
    onSave({ savingStatus = this.savingStatus, errors = [] }) {
      this.savingStatus = savingStatus
      this.loadingErrorMessages = errors
    },
    applyAll() {
      this.savingStatus = 'SAVING'
      this.loadingErrorMessages = []
      this.$store
        .dispatch('menu-management/bulk-update/saveAllFoodItems', {
          menuId: this.menuId,
          saveIgnored: false,
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch((err) => {
          this.savingStatus = 'ERROR'
          if (err.data) {
            this.loadingErrorMessages = err.data.errors
          }
          this.toast.error(this.$t('toast.error.saveData'))
          return
        })
        .finally(() => {
          this.$store.dispatch('menu-management/menus/checkMenuIssues', { menuId: this.menuId })
        })
    },
  },
}
</script>

<style></style>
