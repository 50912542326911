import api from '@/store/api/menus'

export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    similarMappingById: (state) => (id) => state.records[id],
    similarMappingsByIds: (state) => (ids) => ids ? ids.map((id) => state.records[id]) : [],
  },
  actions: {
    getSimilarMappings({ commit }, { foodItemId }) {
      return api
        .fetchSimilarMappings({ foodItemId })
        .then(({ records, ids }) => {
          commit('STORE_SIMILAR_MAPPINGS', records)
          commit(
            'menu-management/food-items/STORE_FOOD_ITEM_DETAILS',
            {
              id: foodItemId,
              similarMappingIds: ids,
            },
            { root: true }
          )
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
  },
  mutations: {
    STORE_SIMILAR_MAPPINGS(state, records) {
      state.records = { ...state.records, ...records }
    },
  },
}
