<template>
  <div class="box-content-fill-remaining p-4 border-t-slate">
    <Breadcrumbs
      :history="[
        { name: 'tablet-editor-stages', title: $t('menus.tabletEditor.nav.stages') },
        {
          name: 'tablet-editor-food-groups',
          params: { stageId },
          title: stage.displayName || stage.name,
        },
      ]"
      :current="foodGroupId ? foodGroup.displayName || foodGroup.name : $route.meta.breadcrumb"
    />
    <div class="flex flex-between flex-wrap my-4">
      <div
        v-if="foodItems.length"
        class="formGroup formGroup--inline mx-0"
      >
        <div class="label">{{ $t('labels.alphabeticOrdering') }}</div>
        <ToggleSwitch
          :id="'orderingToggle'"
          type="button"
          :state="!foodGroup.manualOrdering"
          :disabled="readonly"
          data-test-toggle-ordering
          @toggle="toggleSortMode"
        />
      </div>
      <router-link
        v-if="$can('manage', 'menus')"
        :to="{ name: 'tablet-editor-food-group' }"
        data-test-edit-food-group
      >
        {{ $t('menus.tabletEditor.foodGroup.actions.editFoodGroup') }}
      </router-link>
    </div>
    <loaded-content
      :is-loading="foodItemsLoadingStatus === 'LOADING'"
      :has-error="foodItemsLoadingStatus === 'ERROR'"
      :has-data="foodItemsLoadingStatus === 'LOADED'"
    >
      <p
        v-if="!foodItems.length"
        class="text-center"
        data-test-empty-list
      >
        {{ $t('messages.noResults') }}
      </p>

      <ordered-grid>
        <ordered-grid-item
          v-for="(foodItem, index) in foodItems"
          :key="foodItem.id"
          :index="index"
          :length="foodItems.length"
          :image-url="foodItem.imageUrl"
          :title="foodItem.displayName"
          :hidden="foodItem.hiddenFromTrackers"
          :link="{
            name: 'food-editor',
            query: { menuId, returnPath: $route.path },
            params: { foodItemId: foodItem.id },
          }"
          :reordering-active="foodGroup.manualOrdering && !readonly"
          data-test-food-item
          @move="
            moveItem({
              item: foodItem,
              list: foodItems,
              oldIndex: $event.oldIndex,
              newIndex: $event.newIndex,
              callback: updateList,
            })
          "
        />
        <ordered-grid-create
          v-if="!readonly"
          key="none"
          :title="$t('menus.tabletEditor.foodItems.actions.createNew')"
          :link="{
            name: 'food-create',
            query: {
              menuId,
              'default[foodGroupIds]': JSON.stringify([foodGroupId]),
              returnPath: $route.path,
              ...$route.query,
            },
          }"
        />
      </ordered-grid>
    </loaded-content>
  </div>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import OrderedGridMixin from '@/mixins/ordered-grid'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch.vue'
import menuMixin from '../menuMixin'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LoadedContent,
    ToggleSwitch,
    Breadcrumbs,
  },
  mixins: [OrderedGridMixin, menuMixin],
  props: {
    menuId: String,
    foodGroupId: String,
    stageId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      foodItemsLoadingStatus: 'IDLE',
    }
  },
  computed: {
    readonly() {
      return (this.menu && this.menu.archived) || !this.$can('manage', 'menus')
    },
    foodItems() {
      let list = this.$store.getters['menu-management/food-groups/manualOrderedFoodItems'](
        this.foodGroupId
      )
      if (!this.$can('manage', 'menus')) {
        list = list.filter((foodItem) => !foodItem.hiddenFromTrackers)
      }
      return list
    },
    stage() {
      return this.$store.getters['menu-management/stages/stageById'](this.stageId)
    },
    foodGroup() {
      return this.$store.getters['menu-management/food-groups/foodGroupById'](this.foodGroupId)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getFoodGroup() {
      return this.$store.dispatch('menu-management/food-groups/getFoodGroups', {
        menuId: this.menuId,
        stageId: this.stageId,
      })
    },
    getFoodItems() {
      return this.$store
        .dispatch('menu-management/food-items/getFoodItems', {
          menuId: this.menuId,
          foodGroupId: this.foodGroupId,
        })
        .then(({ foodItemIds }) => {
          this.$store.commit('menu-management/food-groups/UPDATE_FOOD_GROUP', {
            id: this.foodGroupId,
            foodItemIds,
          })
        })
    },
    getData() {
      // get food items
      this.foodItemsLoadingStatus = 'LOADING'
      this.getFoodGroup()
        .then(this.getFoodItems)
        .then(() => {
          this.foodItemsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.foodItemsLoadingStatus = 'ERROR'
        })
    },
    updateList(list) {
      list.forEach((item, index) => {
        this.$store.dispatch('menu-management/food-items/updateFoodItem', {
          ...item,
          displayOrder: index,
        })
      })
      this.saveData(list)
    },
    saveData() {
      let newFoodItemIds = this.foodItems.map((group) => group.id)
      this.$store
        .dispatch('menu-management/food-groups/saveChanges', {
          menuId: this.menuId,
          foodGroupId: this.foodGroupId,
          data: {
            foodItemIds: newFoodItemIds,
          },
        })
        .then(() => {
          this.$store.commit('menu-management/food-groups/UPDATE_FOOD_GROUP', {
            id: this.foodGroupId,
            foodItemIds: newFoodItemIds,
          })
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    toggleSortMode(alphabeticallySortedChildren) {
      this.foodItemsLoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/food-groups/saveChanges', {
          menuId: this.menuId,
          foodGroupId: this.foodGroupId,
          data: {
            manualOrdering: !alphabeticallySortedChildren,
          },
        })
        .then(() => {
          return this.getFoodItems()
        })
        .then(() => {
          this.foodItemsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.foodItemsLoadingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
  },
}
</script>

<style></style>
