export default function (qualifiedName) {
  const parts = qualifiedName.split(' / ')
  const noWinnowParts = parts[0] === 'Winnow' ? parts.slice(1) : parts
  const relevantParts = noWinnowParts.slice(-3)
  const orgName = noWinnowParts[0] // in the case of orphaned sites this may cause duplication
  return {
    orgName: orgName,
    parsedName: relevantParts.join(' / '),
    shortName: `${orgName} / ... / ${relevantParts.join(' / ')}`,
    siteContext: noWinnowParts.slice(0, -3).join(' / '),
    orgQualifiedName: noWinnowParts.join(' / '),
  }
}
