import { removeSearchMarkers } from './taxonomy'

function actualName(record) {
  // there's a duplicate of this in the code-builder.js getters
  return record ? record.displayName || record.termName : ''
}

function strippedName(record) {
  // there's a duplicate of this in the code-builder.js getters
  return removeSearchMarkers(actualName(record))
}

export function joinFacetStrings(facetStrings) {
  return facetStrings.filter((f) => !!f).join(', ')
}
export function joinFacetWithDescriptor(facet, descriptor) {
  return [facet.toUpperCase(), descriptor].join('=')
}

export default function (term, facets) {
  const termString = strippedName(term)

  let formattedFacets = facets.map(({ record, rootRecord }) => {
    const rootName = strippedName(rootRecord)
    const recordName = strippedName(record)

    return joinFacetWithDescriptor(rootName, recordName)
  })

  let facetString = joinFacetStrings(formattedFacets)

  return termString ? `${joinFacetStrings([termString, facetString])}` : ''
}
