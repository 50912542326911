<template>
  <MultiSelect
    :id="id"
    :ref="id"
    v-model="current"
    track-by="id"
    label="name"
    :options="options"
    :placeholder="$t('actions.typeSearch')"
    :use-internal-search="false"
    :is-loading="isRunning"
    :multiple="false"
    :allow-empty="false"
    @update:modelValue="onMultiselectInput"
    @search-change="onSearchChange"
  >
    <template #no-options>{{ $t('filters.noOptionsSearch') }}</template>
    <template #no-result>{{ $t('filters.noResults') }}</template>
  </MultiSelect>
</template>

<script>
import { debounce } from 'lodash'
import axios from 'axios'
import MultiSelect from '@/components/MultiSelect.vue'

import { DEBOUNCE_DELAY } from '@/store/constants'
import { isBlank } from '@/utils/string'
import api from '@/store/api/organisation'

export default {
  components: {
    MultiSelect,
  },
  props: ['modelValue', 'id'],
  emits: ['update:modelValue'],
  data() {
    return {
      isRunning: false,
      source: null,
      options: [],
      current: null,
    }
  },
  created() {
    if (this.modelValue) {
      this.current = this.modelValue
    }
  },
  watch: {
    modelValue(newValue) {
      if (newValue) {
        this.current = newValue
      }
    },
  },
  methods: {
    onSearchChange(token) {
      this.isRunning = true
      this.runTask(token)
    },
    runTask: debounce(async function (token) {
      if (this.source !== null) {
        this.source.cancel()
      }

      if (isBlank(token)) {
        this.clear()
        return
      }

      this.source = axios.CancelToken.source()
      await this.performSearch({
        query: {
          search: token,
        },
        options: { cancelToken: this.source.token },
      })

      this.isRunning = false
      this.source = null
    }, DEBOUNCE_DELAY),
    onMultiselectInput(value) {
      this.$emit('update:modelValue', value)

      this.current = value
    },
    clear() {
      this.isRunning = false
      this.options = []
    },

    async performSearch(params) {
      const { query, options = {} } = params
      this.options = await api.fetchWeatherLocations(query, options.cancelToken)
    },
  },
}
</script>
