<template>
  <!-- This is a "save" button which gets disabled and shows a progress spinner while data is being saved -->
  <ButtonWithSpinner
    class="button"
    :class="{ 'button--isLoading': isSaving, 'button--primary': shouldAddDefaultClass }"
    type="submit"
    :in-progress="isSaving"
    spinner-class="spinner--white"
    v-bind="$attrs"
  >
    <template #icon>
      <slot
        name="icon"
        v-if="hasIcon"
      >
        <SvgIcon name="24-ic-check" />
      </slot>
    </template>

    <template #default>
      <slot>
        {{ $t('actions.save') }}
      </slot>
    </template>
  </ButtonWithSpinner>
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'
import ButtonWithSpinner from './ButtonWithSpinner.vue'
// Stop attribute inheritance because we `v-bind="$attrs"` and this results in duplicated classes
defineOptions({
  inheritAttrs: false,
})
defineProps({
  // Toggle this to show the loading spinner
  isSaving: {
    type: Boolean,
    default: false,
  },
  // Toggle this to hide the default `check` icon
  hasIcon: {
    type: Boolean,
    default: true,
  },
})

// Figure out if we need to add the *default* `button--primary` class to the component
// This can be overwritten by other button classes (e.g. button--secondary) in which case we need to hide the *default*
// Also add the *default* class if the overwriting classes don't contain a button class
const shouldAddDefaultClass = computed(() => {
  const vm = getCurrentInstance()
  const hasButtonClassOverwrite = vm.attrs.class?.indexOf('button--') >= 0
  return !vm.attrs.class || !hasButtonClassOverwrite
})
</script>
