import api from '@/store/api/menus'

const state = {
  records: {},
}

const getters = {
  duplicateMappingsById: (state) => (ids) => ids.map((id) => state.records[id]),
}

const actions = {
  getDuplicateMappings({ commit, dispatch }, { menuId }) {
    return api
      .fetchDuplicateMappings({ menuId })
      .then(({ foodItemRecords, duplicateRecords, duplicateMappingIds }) => {
        commit('STORE_DUPLICATE_MAPPINGS', duplicateRecords)
        commit('menu-management/food-items/STORE_FOOD_ITEMS', foodItemRecords, {
          root: true,
        })
        dispatch(
          'menu-management/menus/updateDuplicateMappingsInMenu',
          { menuId, duplicateMappingIds },
          { root: true }
        )
      })
  },
  mergeDuplicateMappings({ dispatch }, { menuId, newFoodItem, duplicates }) {
    return api.postMergeItems({ menuId, newFoodItem, duplicates }).then((foodItem) => {
      dispatch('menu-management/food-items/updateFoodItem', foodItem, { root: true })
      return foodItem
    })
  },
}

const mutations = {
  STORE_DUPLICATE_MAPPINGS(state, records) {
    state.records = { ...state.records, ...records }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
