<template>
  <form
    v-bind="$attrs"
    :action="$route.fullPath"
    novalidate
  >
    <slot v-bind="{ serverErrors, serverWarnings, clearServerMessages }" />
    <Validation-results
      class="my-4"
      data-test-validation-results
      :server-errors="serverErrors"
      :server-warnings="serverWarnings"
    />
  </form>
</template>

<script setup>
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import { useValidation } from '@/composables/useValidation'
const props = defineProps({
  errorStructure: {
    type: Array,
    default: () => ['GENERAL'],
  },
  formatErrorMessage: {
    type: Function,
    default: null,
  },
  genericErrorMessage: {
    type: String,
    default: '',
  },
})
const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
  useValidation({
    errorStructure: props.errorStructure,
    formatErrorMessage: props.formatErrorMessage,
    genericErrorMessage: props.genericErrorMessage,
  })

defineExpose({
  handleValidationErrorsInResponse,
  clearServerMessages,
})
</script>

<style></style>
