export default function (overwrites) {
  return {
    id: null,
    orgName: null,
    parsedName: null,
    fullName: null,
    shortName: null,
    qualifiedName: null,
    trackerIds: [],
    ...overwrites,
  }
}
