export default {
  data() {
    return {
      openItems: this.readOpenItemsFromRoute(),
    }
  },

  methods: {
    openSection(sectionId) {
      if (!this.openItems.includes(sectionId)) {
        this.openItems.push(sectionId)
      }
      this.updateOpenItems()
    },
    closeSection(sectionId) {
      let index = this.openItems.findIndex((id) => id === sectionId)
      if (index > -1) {
        this.openItems.splice(index, 1)
      }
      this.updateOpenItems()
    },
    readOpenItemsFromRoute() {
      if (this.$route.query.openItems) {
        return this.$route.query.openItems.split('|')
      } else {
        return []
      }
    },
    updateOpenItems() {
      let openItems = this.openItems.join('|')

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          openItems: openItems ? openItems : undefined,
        },
      })
    },
  },
}
