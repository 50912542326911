<template>
  <div class="weekContainer">
    <div class="week">
      <resize-observer @notify="handleResize" />
      <template
        v-for="day in days"
        :key="day.id"
      >
        <div class="week-day">
          <slot
            name="day"
            :day="day"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  withLocale,
  getWeekdaysShort,
  add,
  subtract,
  isAfter,
  isBefore,
  isSame,
  weekday,
  startOf,
  formatDate,
  normalizeCalendarDay,
} from '../Calendar/adapter-moment'
import { DATE_FORMAT_ISO_8601 } from '@/store/constants'

export default {
  name: 'CurrentWeek',
  data() {
    return {
      today: startOf(new Date(), 'day'),
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },

    days() {
      let endDay = this.today
      let startDay = subtract(endDay, 6, 'day')
      let days = []

      while (isBefore(startDay, endDay)) {
        days.push(this.buildDay(startDay))
        startDay = add(startDay, 1, 'day')
      }

      days.push(this.buildDay(endDay))
      return days
    },

    weekdaysShort() {
      return withLocale(this.locale, getWeekdaysShort)
    },
  },
  mounted() {
    this.handleResize()
  },
  methods: {
    buildDay(date) {
      const id = formatDate(date, DATE_FORMAT_ISO_8601)
      const wd = withLocale(this.locale, () => weekday(date))

      return normalizeCalendarDay({
        id,
        date: date,
        number: date.getDate(),
        isToday: isSame(date, this.today, 'day'),
        isAfterToday: isAfter(date, this.today),
        weekday: wd,
        weekdayShort: this.weekdaysShort[wd],
      })
    },
    handleResize: function () {
      this.$el.scrollLeft = this.$el.scrollWidth - this.$el.clientWidth
    },
  },
}
</script>
