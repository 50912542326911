export default function (newData) {
  return {
    id: newData.id,
    name: newData.name,
    nameEnglish: newData.nameEnglish,
    annotationLabel: newData.annotationLabel,
    cost: newData.cost,
    portion: newData.portion && newData.portion.value,
    annotationEnabled: newData.annotationEnabled,
    predictionEnabled: newData.predictionEnabled,
    hiddenFromTrackers: newData.hiddenFromTrackers,
    created: newData.createdDate,
    updated: newData.updatedDate,
    displayOrder: newData.displayOrder,
    image: newData.imageId,
    taxonomyMapping: newData.taxonomy && newData.taxonomy.mapping,
    taxonomyCode: newData.taxonomy && newData.taxonomy.code,
    externalSystem: newData.externalSystem,
    externalId: newData.externalId,
    state: newData.status,
    foodGroupIds: newData.foodGroupIds,
  }
}
