<script setup>
import { reactive, computed } from 'vue'
import { useAbility } from '@casl/vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

const { can } = useAbility()
const { t } = useI18n()
const { getters } = useStore()

const isMultiSiteUser = computed(() => {
  return getters['auth/isMultiSiteUser']
})
const isReseller = computed(() => {
  return getters['auth/isReseller']
})

const quicklinks = reactive([
  {
    title: t('quicklinks.coversAndSales'),
    icon: '48-menu-log',
    protected: !can('read', 'covers-sales'),
    route: {
      name: 'covers-sales',
    },
    tracking: {
      action: 'Selected Enter Covers & Sales',
      meta: {
        destinationPage: 'Covers & Sales',
      },
    },
  },
  {
    title: t('quicklinks.wasteLog'),
    icon: '48-ic-list',
    protected: !can('read', 'waste-log'),
    route: {
      name: 'waste-log',
    },
    tracking: {
      action: 'Selected View Waste Activity',
      meta: {
        destinationPage: 'Waste Log',
      },
    },
  },
  {
    title: t('quicklinks.insightsDashboard'),
    icon: '48-menu-insights',
    protected: !(can('read', 'multi-site-dashboards') || can('read', 'single-site-dashboards')),
    route: {
      name:
        isMultiSiteUser.value || isReseller.value
          ? 'multi-site-dashboards'
          : 'single-site-dashboards',
    },
    tracking: {
      action: 'Selected Analyse Waste Trends',
      meta: {
        destinationPage: 'Dashboards',
      },
    },
  },
])
</script>

<template>
  <nav
    v-if="quicklinks.length"
    class="Hero-quicklinks"
  >
    <ul class="Hero-quicklinks-container">
      <template v-for="link in quicklinks">
        <li
          v-if="!link.protected"
          :key="link.title"
        >
          <router-link
            class="button button--link"
            :to="link.route"
            @click="analytics.track(link.tracking.action, link.tracking.meta)"
          >
            <SvgIcon :name="link.icon" />
            <span>{{ link.title }}</span>
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>

<style lang="scss">
.Hero-quicklinks-container {
  flex: 0 1 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-z;
  justify-content: center;
  padding: theme('spacing.8');
  gap: theme('spacing.12');
}
</style>
