<template>
  <div>
    <div class="alert alert--info mb-4">
      <SvgIcon name="24-ic-info" />
      Applying changes individually or ignoring items on save might break the ordering of food
      groups inside of stages. Please update the CSV and upload it again if you want the order to be
      maintained.
    </div>
    <Collapsible-section class="box-table">
      <template #header>
        {{ $t('titles.advancedFilters') }}
      </template>

      <Bulk-update-action-filters
        :selected-id="filteredByAction"
        :counters="counters"
        class="mb-4"
        @filtered-by="onActionFilterChange"
      />

      <Bulk-update-prop-filters
        :selected-id="filteredByProp"
        :counters="counters"
        :weight-preference="weightPreference"
        class="mb-4"
        @filtered-by="onPropFilterChange"
      />

      <div
        v-if="filteredByAction !== 'IGNORED'"
        class="formGroup mb-4"
      >
        <div class="customControl customControl--checkbox">
          <input
            id="showIgnored"
            v-model="showIgnored"
            type="checkbox"
            class="customControl-input"
          />
          <label
            class="customControl-label"
            for="showIgnored"
          >
            Show ignored items
          </label>
        </div>
      </div>
    </Collapsible-section>

    <Loaded-content
      :is-loading="false"
      :has-error="false"
      :has-data="!!visibleFoodItems.length"
    >
      <Table-responsive class="box-table">
        <Thead-generator
          :headers="headers"
          :sorted-column="sortedBy.column"
          :sort-direction="sortedBy.direction"
          @sorted="onSort"
        />
        <Food-item
          v-for="foodItem in sortedFoodItems"
          :key="foodItem.id"
          :class="{ 'tbody-pop': !foodItem.ignored }"
          :food-item="foodItem"
          :show-group-actions="showGroupActions"
          :header-ids="headerIds"
          :saving-status-overwrite="savingStatus"
          :show-ignored="showIgnored || filteredByAction === 'IGNORED'"
          :force-active="filteredByAction === 'IGNORED'"
          @save="$emit('saving')"
          @saved="$emit('saving', { errors: $event || [] })"
        />
      </Table-responsive>
      <div class="buttonGroup -mx-4 -mb-4 p-4 sticky-bottom bg-highlightLemon border-t-slate">
        <button
          v-if="filteredByAction !== 'ALL' || filteredByProp !== 'all'"
          class="button button--primary"
          type="button"
          :disabled="
            savingStatus === 'SAVING' || (!hasValidChanges && filteredByAction !== 'IGNORED')
          "
          @click="applyAll"
        >
          Apply all filtered changes
        </button>
        <button
          v-else
          class="button button--primary"
          type="button"
          :disabled="
            savingStatus === 'SAVING' || (!hasValidChanges && filteredByAction !== 'IGNORED')
          "
          @click="applyAll"
        >
          Apply all changes
        </button>
      </div>
    </Loaded-content>
  </div>
</template>

<script>
import TableResponsive from '@/components/TableResponsive/TableResponsive.vue'
import TheadGenerator from '@/components/TheadGenerator/TheadGenerator.vue'
import FoodItem from './BulkUpdateFoodItem.vue'
import BulkUpdateActionFilters from './BulkUpdateActionFilters.vue'
import BulkUpdatePropFilters from './BulkUpdatePropFilters.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import { differenceBy, sortBy, reverse } from 'lodash'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  components: {
    TableResponsive,
    TheadGenerator,
    FoodItem,
    BulkUpdateActionFilters,
    BulkUpdatePropFilters,
    LoadedContent,
    CollapsibleSection,
  },
  props: {
    menuId: String,
    savingStatus: String,
  },
  emits: ['saving'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      showIgnored: true,
      filteredByProp: 'all',
      filteredByAction: 'ALL',
      sortedBy: null,
    }
  },
  computed: {
    ...mapGetters('menu-management/bulk-update', ['hasValidChanges']),
    weightPreference() {
      return this.$store.getters['auth/weightUnit']
    },
    headers() {
      let columns = [
        {
          id: `th-item`,
          title: 'Item',
          sortable: 'true',
          style: 'min-width: 30ch; width: 20%',
        },
        { id: `th-action`, title: 'Action', class: 'td-small-col', hidden: true },
        { id: `th-prop`, title: 'Property', sortable: false },
        { id: `th-old`, title: 'Current', sortable: this.filteredByProp !== 'all' },
        { id: `th-new`, title: 'Update to', sortable: this.filteredByProp !== 'all' },
        { id: `th-actions`, title: 'Actions', class: 'td-small-col' },
      ]
      if (this.hiddenColumns.length) {
        return differenceBy(columns, this.hiddenColumns, 'id')
      }
      return columns
    },
    headerIds() {
      return this.headers.map(({ id }) => id)
    },
    showGroupActions() {
      // hide group actions if filtered by single field
      return this.filteredByProp === 'all'
    },
    foodItems() {
      let foodItems
      if (this.filteredByProp !== 'all') {
        foodItems = this.$store.getters['menu-management/bulk-update/foodItemsFilteredByKey'](
          this.filteredByProp
        )
      } else {
        foodItems = this.$store.getters['menu-management/bulk-update/foodItemsGroupedByName']
      }

      if (['NEW', 'MODIFIED', 'DELETE'].includes(this.filteredByAction)) {
        return foodItems.filter(({ action }) => action === this.filteredByAction)
      }
      if (this.filteredByAction === 'IGNORED') {
        return foodItems.filter((foodItem) => {
          return !!foodItem.changes.filter((change) => change.ignored).length
        })
      }
      return foodItems
    },
    visibleFoodItems() {
      if (this.filteredByAction === 'IGNORED') {
        return this.foodItems
      } else if (this.showIgnored) {
        return this.foodItems
      } else {
        return this.foodItems.filter(({ ignored }) => !ignored)
      }
    },
    sortedFoodItems() {
      if (this.sortedBy) {
        if (this.sortedBy.column === 'th-item') {
          if (this.sortedBy.direction === 'descending')
            return reverse(sortBy(this.visibleFoodItems, ['name']))
          else return sortBy(this.visibleFoodItems, ['name'])
        }
        if (this.sortedBy.column === 'th-new') {
          let sortable = this.visibleFoodItems.map((item) => ({
            ...item,
            newValue: item.changes && item.changes[0].newValue,
          }))
          if (this.sortedBy.direction === 'descending') {
            return reverse(sortBy(sortable, ['newValue']))
          } else {
            return sortBy(sortable, ['newValue'])
          }
        }
        if (this.sortedBy.column === 'th-old') {
          let sortable = this.visibleFoodItems.map((item) => ({
            ...item,
            oldValue: item.changes && item.changes[0].oldValue,
          }))
          if (this.sortedBy.direction === 'descending') {
            return reverse(sortBy(sortable, ['oldValue']))
          } else {
            return sortBy(sortable, ['oldValue'])
          }
        }
      }
      return sortBy(this.visibleFoodItems, ['name'])
    },
    counters() {
      let foodItems
      if (this.filteredByProp !== 'all') {
        foodItems = this.$store.getters['menu-management/bulk-update/foodItemsFilteredByKey'](
          this.filteredByProp
        )
      } else {
        foodItems = this.$store.getters['menu-management/bulk-update/foodItemsGroupedByName']
      }
      return {
        foodItemsAdded: foodItems.filter(({ action }) => action === 'NEW').length,
        foodItemsModified: foodItems.filter(({ action }) => action === 'MODIFIED').length,
        foodItemsDeleted: foodItems.filter(({ action }) => action === 'DELETE').length,
        foodItemsIgnored: foodItems.filter(({ ignored }) => ignored).length,
      }
    },
    // Some columns can be hidden depending on selected filters
    //
    // the action column is the first one to go once an action filter is selected
    // because it should be obvious that only those items are being displayed
    hiddenColumns() {
      switch (this.filteredByAction) {
        case 'MODIFIED':
          return [{ id: 'th-action' }]
        case 'NEW':
          // we don't need the old value column for new items
          return [{ id: 'th-old' }, { id: 'th-action' }]
        case 'DELETE':
          // we don't need the new value column for deleted items
          // also disable individual field actions
          return [{ id: 'th-new' }, { id: 'th-action' }, { id: 'th-actions' }]
        default:
          return []
      }
    },
  },
  created() {
    this.resetSort()
  },
  methods: {
    onPropFilterChange(filter) {
      this.filteredByProp = filter.id
      if (filter.id === 'all') {
        this.resetSort()
      }
    },
    onActionFilterChange(filter) {
      this.filteredByAction = filter.id
    },
    applyAll() {
      this.$emit('saving', {
        savingStatus: 'SAVING',
      })
      this.$store
        .dispatch('menu-management/bulk-update/saveAllFoodItems', {
          menuId: this.menuId,
          action: this.filteredByAction,
          key: this.filteredByProp,
          saveIgnored: this.filteredByAction === 'IGNORED' ? true : false,
        })
        .then(() => {
          this.$emit('saving', {
            savingStatus: 'SAVED',
          })
        })
        .catch((err) => {
          this.$emit('saving', {
            savingStatus: 'ERROR',
            errors: err.data.errors,
          })

          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    onSort({ column, direction }) {
      this.sortedBy = { column, direction }
    },
    resetSort() {
      this.sortedBy = {
        column: 'th-item',
        direction: 'ascending',
      }
    },
  },
}
</script>

<style></style>
