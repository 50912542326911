<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <div
      v-if="menuLoadingStatus === 'LOADING'"
      class="m-8 flex flex-center"
    >
      <Loading-Spinner class="spinner--lg" />>
    </div>

    <Breadcrumbs
      class="mx-4 ps-4"
      :history="
        [
          { name: 'menus', title: $t('navigation.menus') },
          menuId
            ? {
                route: { name: 'food-items', params: { menuId } },
                title: menu && menu.organisation.shortName,
              }
            : undefined,
        ].filter((item) => item)
      "
      :current="$route.meta.breadcrumb"
    />
    <Hero v-if="menuLoadingStatus === 'LOADED'">
      <template #default>{{ pageTitle }}</template>
      <template #hero-content>
        <TabsLvl1Links v-if="foodItemId">
          <li>
            <router-link
              :to="{
                name: 'food-editor',
                query: $route.query,
                params: { foodItemId },
              }"
              class="tab-item"
            >
              <span>{{ $t('menus.foodItem.tabs.attributes') }}</span>
            </router-link>
          </li>
          <li v-if="$can('manage', 'menus')">
            <router-link
              :to="{ name: 'food-groups', query: $route.query, params: { foodItemId } }"
              class="tab-item"
            >
              <span>{{ $t('menus.foodItem.tabs.foodGroups') }}</span>
            </router-link>
          </li>
          <li v-if="$can('manage', 'menus')">
            <router-link
              :to="{ name: 'food-mapping', query: $route.query, params: { foodItemId } }"
              class="tab-item"
            >
              <span>{{ $t('menus.foodItem.tabs.mapping') }}</span>
            </router-link>
          </li>
        </TabsLvl1Links>
      </template>
    </Hero>
    <div
      v-if="foodItemLoadingStatus === 'LOADING'"
      class="wrapper"
    >
      <div class="box">
        <div class="flex flex-center py-8">
          <Loading-Spinner class="spinner--lg" />
        </div>
      </div>
    </div>
    <router-view
      v-if="foodItemLoadingStatus === 'LOADED' && menuLoadingStatus === 'LOADED'"
      :key="$route.path"
    />
  </LayoutDefault>
</template>
<script>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import TabsLvl1Links from '@/components/Tabs/TabsLvl1Links.vue'
import menuMixin from '../Menus/menuMixin'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LayoutDefault,
    Hero,
    Breadcrumbs,
    TabsLvl1Links,
  },
  mixins: [menuMixin],
  props: {
    foodItemId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      foodItemLoadingStatus: 'IDLE',
      menuLoadingStatus: 'IDLE',
    }
  },
  computed: {
    pageTitle() {
      if (this.foodItem.name) {
        return this.foodItem.name
      } else {
        return this.$t('menus.foodItem.title.create')
      }
    },
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
    menuId() {
      return this.$route.query.menuId
    },
  },
  created() {
    // load food item
    if (this.menuId && this.foodItemId) {
      this.foodItemLoadingStatus = 'LOADING'
      this.getFoodItem(this.menuId, this.foodItemId)
        .then(() => {
          this.foodItemLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.foodItemLoadingStatus = 'ERROR'
        })
    } else {
      this.foodItemLoadingStatus = 'LOADED'
    }

    // load menu data
    if (this.menuId && !this.menu) {
      this.menuLoadingStatus = 'LOADING'
      this.getMenu(this.menuId)
        .then(this.getExternalSystems)
        .then(() => {
          this.menuLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.menuLoadingStatus = 'ERROR'
        })
    } else {
      this.menuLoadingStatus = 'LOADED'
    }
  },
  methods: {
    getFoodItem(menuId, foodItemId) {
      return this.$store.dispatch('menu-management/food-items/getFoodItem', {
        menuId,
        foodItemId,
      })
    },
    getMenu() {
      return this.$store
        .dispatch('menu-management/menus/getMenu', { menuId: this.menuId })
        .then(({ menu, organisation }) => {
          this.$store.commit('menu-management/organisations/UPDATE_ORGANISATION', organisation)
          return { menu, organisation }
        })
    },
    getExternalSystems({ menu }) {
      return this.$store.dispatch('menu-management/menus/getExternalSystems', {
        organisationId: menu.organisation.id,
      })
    },
  },
}
</script>
