<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Breadcrumbs
      class="mx-4 ps-4"
      :history="[{ name: 'menus', title: $t('navigation.menus') }]"
      :current="$t('navigation.createMenu')"
    />
    <Hero>
      <template #default>{{ $t('titles.createMenu') }}</template>
      <template #hero-aside>
        <a
          v-tooltip="{
            content: $t('messages.menuManagementHelp'),
          }"
          href="https://winnow.atlassian.net/wiki/spaces/HELP/pages/1508737153/Menus"
          target="_blank"
          class="button button--link"
        >
          <span>{{ $t('navigation.menuManagementHelp') }}</span>
          <SvgIcon name="48-menu-help" />
        </a>
      </template>
    </Hero>

    <PageSection>
      <ValidationForm
        action="/"
        method="POST"
        @submit.prevent="save"
        ref="form"
      >
        <template #default="{ serverErrors }">
          <div class="grid-layout grid--multiline grid--half">
            <div class="grid-unit">
              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                :validator="v$.buffer.name"
                :server-errors="serverErrors.name"
                class="formGroup"
              >
                <label for="name">{{ $t('menus.form.name.label') }}</label>
                <input
                  id="name"
                  v-model.trim="buffer.name"
                  v-test="'name'"
                  class="formControl"
                  :class="{ 'is-invalid': hasAnyErrors }"
                  name="name"
                  maxlength="100"
                />
              </ValidationGroup>
              <div class="formGroup">
                <label for="description">{{ $t('menus.form.descriptionLabel') }}</label>
                <input
                  id="description"
                  v-model.trim="buffer.description"
                  v-test="'description'"
                  class="formControl"
                  name="description"
                  maxlength="100"
                />
              </div>
              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                :validator="v$.buffer.organisationId"
                :server-errors="serverErrors.organisationId"
                class="formGroup"
              >
                <label for="siteSelector">{{ $t('menus.form.siteSelectorLabel') }}</label>
                <SearchOrgs
                  id="siteSelector"
                  v-model="v$.buffer.organisationId.$model"
                  placeholder="Search organisations"
                  :has-any-errors="hasAnyErrors"
                  data-test-organisation-selector
                />
              </ValidationGroup>
              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                :validator="v$.buffer.locale"
                :server-errors="serverErrors.locale"
                class="formGroup"
              >
                <label for="languages">{{ $t('menus.form.locale.label') }}</label>
                <select
                  id="languages"
                  v-model="v$.buffer.locale.$model"
                  v-test="'languages-selector'"
                  :class="['customSelect', { 'is-invalid': hasAnyErrors }]"
                  name="languages"
                  @change="$refs.form.clearServerMessages('locale')"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('menus.form.locale.placeholder') }}
                  </option>
                  <option
                    v-for="{ code, name } in languages"
                    :key="code"
                    :value="code"
                  >
                    {{ name }}
                  </option>
                </select>
              </ValidationGroup>

              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                :validator="v$.buffer.currency"
                :server-errors="serverErrors.currency"
                class="formGroup"
              >
                <label for="currency">{{ $t('menus.form.currency.label') }}</label>
                <select
                  id="currency"
                  v-model="v$.buffer.currency.$model"
                  v-test="'currencies-selector'"
                  :class="['customSelect', { 'is-invalid': hasAnyErrors }]"
                  name="currency"
                  @change="$refs.form.clearServerMessages('currency')"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('menus.form.currency.placeholder') }}
                  </option>
                  <option
                    v-for="{ code, displayName } in currencies"
                    :key="code"
                    :value="code"
                  >
                    {{ displayName }}
                  </option>
                </select>
              </ValidationGroup>

              <!-- Vision enabled -->
              <div class="formGroup">
                <div class="flex flex-between">
                  <label for="visionCheck">{{ $t('menus.form.visionLabel') }}</label>
                  <ToggleSwitch
                    :id="'visionCheck'"
                    v-model="buffer.vision"
                    type="checkbox"
                    :name="'visionCheck'"
                  />
                </div>
              </div>

              <!-- Upload CSV -->
              <p
                class="mt-8 pt-4"
                v-html="$t('menus.form.documentation')"
              />
              <div class="formGroup flex flex-between mb-4 pt-4">
                <ValidationGroup
                  :validator="v$.buffer.file"
                  :server-errors="serverErrors.file"
                  class="formGroup"
                >
                  <FileUpload
                    id="csvUpload"
                    ref="file-field"
                    accept=".csv"
                    :multiple="false"
                    data-test-file-selector
                    @file-added="fileAdded"
                  >
                    {{ $t('actions.uploadCSV') }}
                  </FileUpload>
                </ValidationGroup>

                <div>
                  <Loading-Spinner
                    v-if="isSaving"
                    ref="progressBar"
                    v-test="'progress'"
                    class="spinner--md"
                    :progress="uploadProgressPercentage"
                  />
                  <button
                    v-else
                    v-test="'submit-form'"
                    type="submit"
                    class="button button--primary"
                  >
                    {{ $t('actions.continue') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ValidationForm>
    </PageSection>
  </LayoutDefault>
</template>

<script>
import { useToast } from 'vue-toastification'
import { mapState } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import SearchOrgs from '@/components/SearchOrgs/SearchOrgs.vue'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationForm from '@/components/Validation/ValidationForm.vue'
import FileUpload from '@/components/FileUpload/FileUpload.vue'
let MenuModel = function () {
  return {
    id: null,
    name: null,
    locale: null,
    currency: null,
    vision: false,
    description: null,
    organisationId: null,
  }
}

export default {
  components: {
    LayoutDefault,
    Hero,
    Breadcrumbs,
    SearchOrgs,
    ToggleSwitch,
    ValidationGroup,
    ValidationForm,
    FileUpload,
  },

  beforeRouteLeave(to, from, next) {
    this.rerouteOnDone = false
    next()
  },
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      isSaving: false,
      buffer: new MenuModel(),
      // This flag is used to block or enable routing at the end of menu upload.
      // Because this step takes a long time, users might choose to leave the create page
      // while the success promise is still kept alive in the store.
      // This leads to awkward redirects to the newly created menu even when the user is on a
      // totally different section of the application
      // see https://winnow.atlassian.net/browse/HUB-2906
      rerouteOnDone: true,
    }
  },
  validations() {
    return {
      buffer: {
        name: {
          required,
        },
        locale: {
          required,
        },
        currency: {
          required,
        },
        organisationId: {
          required,
        },
        file: {
          MENU_CSV_UPLOAD_REQUIRED: required,
        },
      },
    }
  },

  computed: {
    ...mapState('menu-management/menus', ['languages', 'currencies', 'uploadProgressPercentage']),
  },

  created() {
    this.$store.dispatch('menu-management/menus/getSettings')
  },

  methods: {
    save() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.isSaving = true
      this.$nextTick(() => {
        this.$refs.progressBar.focus()
      })

      this.$store
        .dispatch('menu-management/menus/saveMenu', this.buffer)
        .then((response) => {
          // go to tranlations if locale is something other than english
          this.$router.push({ name: 'menu-summary', params: { menuId: response.id } })
          this.$nextTick(() => {
            this.toast.success(this.$t('menus.form.successMessage'))
          })
        })
        .catch((errorObj) => {
          this.$refs.form.handleValidationErrorsInResponse(errorObj)
          this.removeFile()
          this.v$.buffer.file.$reset()
        })
        .finally(() => {
          this.isSaving = false
        })
    },

    removeFile() {
      this.$refs['file-field'].clear()
    },

    fileAdded(file) {
      this.v$.buffer.file.$touch
      this.buffer.file = file
    },
  },
}
</script>
