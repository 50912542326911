export default function (overwrites) {
  return {
    id: null,
    name: null,
    organisation: {}, // see expanded organisation structure in the organisations utils
    description: '',
    created: null,
    modified: null,
    deployed: null,
    externalSystemId: null,
    locale: null,
    currency: null,
    vision: null,
    stageIds: [],
    foodGroupIds: [],
    foodItemIds: [],
    custom1Ids: [],
    duplicateMappingIds: [],
    parentChildConflictIds: [],
    manualOrdering: true,
    activeTask: null,
    ...overwrites,
  }
}
