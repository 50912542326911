import OrderedGrid from '@/components/OrderedGrid/ordered-grid.vue'
import OrderedGridItem from '@/components/OrderedGrid/ordered-grid-item.vue'
import OrderedGridCreate from '@/components/OrderedGrid/ordered-grid-create.vue'

export default {
  components: {
    OrderedGrid,
    OrderedGridItem,
    OrderedGridCreate,
  },
  methods: {
    moveItem({ item, list, oldIndex, newIndex, callback }) {
      list = [...list]
      list.splice(oldIndex, 1)
      list.splice(newIndex, 0, item)
      callback(list)
    },
  },
}
