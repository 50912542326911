<template>
  <div>
    <div
      v-if="selectedNode.level == 16 && !hasLocalisation"
      data-test-localisation-missing-alert
      class="alert alert--warning mb-4"
    >
      <p>
        <SvgIcon name="24-ic-warning" />
        {{ $t('organisations.overview.localisation.messages.missingWarning') }}
      </p>
    </div>
    <p
      v-if="!isEditingName"
      class="h3"
      data-test-org-context
    >
      <span class="text-grey-400">{{ selectedNodeLevel }} (L{{ selectedNode.level }}):</span>
      <b data-test-org-level>
        {{ selectedNode.name }}
      </b>
      <button
        ref="edit-trigger"
        v-tooltip="`Edit name`"
        type="button"
        class="button button--icon p-1 m-2 align-middle"
        data-test-edit-name-trigger
        @click="isEditingName = true"
      >
        <SvgIcon name="24-ic-edit" />
        <span class="sr-only">{{ $t('organisations.actions.editName') }}</span>
      </button>
    </p>
    <div class="flex flex-wrap flex-between">
      <p
        class="text-grey-400"
        :title="$t('hierarchy.label.path')"
        data-test-full-path
      >
        <span class="sr-only">{{ $t('hierarchy.label.path') }}</span>
        {{ selectedNode.qualifiedName }}
      </p>
      <p
        v-if="status"
        class="text-grey-400"
        :title="$t('hierarchy.label.siteStatus')"
        data-test-site-status
      >
        <span class="sr-only">{{ $t('hierarchy.label.siteStatus') }}</span>
        {{ $t(`hierarchy.node.statusLabel.${status.label}`) }}
        <span :class="[status ? status.class : '']" />
      </p>
    </div>
    <form
      v-if="isEditingName"
      :action="$route.fullPath"
      autocomplete="off"
      @submit.prevent="saveNewName"
      @reset="resetNameChange"
      @keyup.esc="resetNameChange"
      class="my-4"
    >
      <ValidationGroup
        :validator="v$.organisationName"
        :server-errors="serverErrors.organisationName"
        class="formGroup"
        v-slot="{ hasAnyErrors }"
        data-test-edit-name
      >
        <div class="formGroup--inline">
          <label for="orgName">
            <span class="text-grey-400"
              >{{ selectedNodeLevel }} (L{{ selectedNode.level }}) -
            </span>
            <strong>{{ $t('organisations.labels.newName') }}</strong>
          </label>
          <input
            id="orgName"
            ref="edit-field"
            v-model.trim="organisationName"
            type="text"
            :placeholder="selectedNode.name"
            class="formControl basis-80 flex-grow-0 flex-shrink w-80"
            :class="{ 'is-invalid': hasAnyErrors }"
            :disabled="isFormDisabled"
            data-test-edit-name-field
          />
        </div>
        <div class="flex flex-grow items-center gap-2">
          <ButtonSave
            :disabled="isFormDisabled"
            :is-saving="savingStatus === 'SAVING'"
            data-test-edit-name-submit
          />
          <button
            type="reset"
            :disabled="isFormDisabled"
            class="button button--tertiary"
            data-test-edit-name-cancel
          >
            {{ $t('actions.cancel') }}
          </button>
        </div>
      </ValidationGroup>
    </form>

    <!-- @TODO: implement a proper fix for the if condition. Details in Hierarchy.vue -->
    <Hierarchy-node-localisation
      v-if="selectedNode.localisation"
      :node="selectedNode"
      :is-open="openItems.includes('LOCALISATION')"
      class="collapsible-section--first mt-4 box-table"
      @open="openSection('LOCALISATION')"
      @close="closeSection('LOCALISATION')"
    />

    <Hierarchy-node-package
      v-if="selectedNode.level === 16"
      :node="selectedNode"
      class="box-table"
      :is-open="openItems.includes('PACKAGE')"
      @open="openSection('PACKAGE')"
      @close="closeSection('PACKAGE')"
    />

    <Hierarchy-node-advanced-settings
      v-if="selectedNode.level === 1"
      :node="selectedNode"
      class="box-table"
      :is-open="openItems.includes('ADVANCED')"
      @open="openSection('ADVANCED')"
      @close="closeSection('ADVANCED')"
    />

    <delete-with-prompt
      v-if="!selectedNode.archived"
      class="my-4"
      :disabled="savingStatus === 'SAVING'"
      data-test-deactivate
      @confirm="deactivateNode"
      ref="deactivate"
    >
      <template #delete-button>
        <span>{{ $t('actions.deactivate') }}</span>
      </template>
      <template v-if="selectedNode.sitesCount">
        {{
          $t('organisations.messages.deactivateWithCounter', {
            count: selectedNode.sitesCount,
          })
        }}
      </template>
      <template v-else>
        {{ $t('organisations.messages.deactivate') }}
      </template>
    </delete-with-prompt>
    <Validation-results
      :server-errors="serverErrors"
      :server-warnings="serverWarnings"
      class="mt-1"
      data-test-validation-results
    />
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import openSectionsMixin from '@/mixins/openSectionsMixin'
import { ORG_NODE_STATUSES } from '@/store/constants'
import HierarchyNodeLocalisation from './HierarchyNodeOverview/HierarchyNodeLocalisation.vue'
import HierarchyNodePackage from './HierarchyNodeOverview/HierarchyNodePackage.vue'
import HierarchyNodeAdvancedSettings from './HierarchyNodeOverview/HierarchyNodeAdvancedSettings.vue'
import { useToast } from 'vue-toastification'
import { useVuelidate } from '@vuelidate/core'
import { useValidation } from '@/composables/useValidation'
import ButtonSave from '@/components/ButtonSave.vue'

export default {
  components: {
    ValidationGroup,
    ValidationResults,
    DeleteWithPrompt,
    HierarchyNodeLocalisation,
    HierarchyNodePackage,
    HierarchyNodeAdvancedSettings,
    ButtonSave,
  },
  mixins: [openSectionsMixin],

  props: {
    nodeId: String,
  },
  emits: ['node-updated'],
  setup: () => {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation({
        errorStructure: ['GENERAL', 'organisationName', 'organisation'],
      })
    return {
      v$: useVuelidate(),
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },
  data() {
    return {
      isEditingName: false,
      organisationName: '',
      savingStatus: 'IDLE',
      statusTable: {
        [ORG_NODE_STATUSES.SETUP]: {
          class: 'site-status-bubble--setup',
          label: ORG_NODE_STATUSES.SETUP,
        },
        [ORG_NODE_STATUSES.BASELINE]: {
          class: 'site-status-bubble--baseline',
          label: ORG_NODE_STATUSES.BASELINE,
        },
        [ORG_NODE_STATUSES.SUCCESS]: {
          class: 'site-status-bubble--success',
          label: ORG_NODE_STATUSES.SUCCESS,
        },
        [ORG_NODE_STATUSES.LIVE]: {
          class: 'site-status-bubble--live',
          label: ORG_NODE_STATUSES.LIVE,
        },
        [ORG_NODE_STATUSES.INACTIVE]: {
          class: 'site-status-bubble--inactive',
          label: ORG_NODE_STATUSES.INACTIVE,
        },
      },
    }
  },
  computed: {
    selectedNode() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    selectedNodeLevel() {
      return this.selectedNode.locationLevel.type
    },
    mainOrganisation() {
      return this.selectedNode.qualifiedName.split(' / ')[0]
    },
    displayedName() {
      return this.selectedNode.qualifiedName.split(' / ').slice(-3).join(' / ')
    },
    isFormDisabled() {
      return this.savingStatus === 'SAVING'
    },

    status() {
      return this.statusTable[this.selectedNode.siteStatusSummary]
    },
    hasLocalisation() {
      return (
        this.selectedNode.localisation.locale &&
        this.selectedNode.localisation.currencyCode &&
        this.selectedNode.localisation.timezone &&
        this.selectedNode.localisation.weightUnitId &&
        this.selectedNode.localisation.temperatureUnitId
      )
    },
  },
  validations: {
    organisationName: {
      required,
    },
  },
  watch: {
    isEditingName(isEditing) {
      if (!isEditing) {
        this.$nextTick(() => {
          this.$refs['edit-trigger'].focus()
        })
      } else if (isEditing) {
        this.$nextTick(() => {
          this.$refs['edit-field'].focus()
        })
      }
    },
  },
  methods: {
    saveNewName() {
      this.clearServerMessages('organisationName')
      this.v$.$touch()
      if (this.v$.organisationName.$invalid) {
        return
      }
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('hierarchy/nodes/saveNodeChanges', {
          nodeId: this.selectedNode.id,
          data: { name: this.organisationName },
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
        })
        .then(() => {
          this.resetNameChange()
        })
        .then(() => {
          this.savingStatus = 'SAVED'
        })
        .catch((errorObj) => {
          this.savingStatus = 'ERROR'
          this.handleValidationErrorsInResponse(errorObj)
        })
    },
    resetNameChange() {
      this.isEditingName = false
      this.organisationName = ''
      this.v$.$reset()
      this.clearServerMessages()
    },
    deactivateNode() {
      this.clearServerMessages('organisation')
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('hierarchy/nodes/saveNodeChanges', {
          nodeId: this.selectedNode.id,
          data: { archived: true },
        })
        .then(() => this.$emit('node-updated', this.nodeId))
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((errorObj) => {
          this.savingStatus = 'ERROR'
          this.handleValidationErrorsInResponse(errorObj)
        })
        .finally(() => {
          if (this.$refs.deactivate) {
            this.$refs.deactivate.finishProcessing()
          }
        })
    },
  },
}
</script>

<style lang="scss">
%site-status-bubble {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  line-height: 1em;
  vertical-align: text-bottom;
}

.site-status-bubble--setup {
  @extend %site-status-bubble;
  background-color: theme('colors.apple.DEFAULT');
}

.site-status-bubble--baseline {
  @extend %site-status-bubble;
  background-color: theme('colors.lemon.DEFAULT');
}

.site-status-bubble--success {
  @extend %site-status-bubble;
  background-color: theme('colors.blueberry.DEFAULT');
}

.site-status-bubble--live {
  @extend %site-status-bubble;
  background-color: theme('colors.mint.DEFAULT');
}

.site-status-bubble--inactive {
  @extend %site-status-bubble;
  background-color: theme('colors.slate.DEFAULT');
}
</style>
