import api from '@/store/api/menus'

export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    custom1ById: (state, getters, rootState, rootGetters) => (id) => {
      let custom1 = state.records[id]
      if (!custom1) return {}
      custom1.displayName = rootGetters['menu-management/displayEnglish']
        ? custom1.nameEnglish
        : custom1.name
      return custom1
    },
    custom1sById: (state, getters) => (ids) => {
      if (!ids) return []
      return ids.map((id) => getters['custom1ById'](id))
    },
  },
  actions: {
    getCustom1s({ commit }, { menuId }) {
      return api.fetchCustom1s({ menuId }).then((records) => {
        commit('STORE_CUSTOM1S', records)
        commit(
          'menu-management/menus/UPDATE_MENU',
          { id: menuId, custom1Ids: Object.keys(records) },
          { root: true }
        )
      })
    },
    createNewCustom1({ commit, dispatch }, { menuId, data }) {
      return api.postCustom1({ menuId, data }).then((custom1) => {
        commit('UPDATE_CUSTOM1', custom1)
        dispatch(
          'menu-management/menus/addCustom1ToMenu',
          { menuId, custom1Id: custom1.id },
          { root: true }
        )
        return custom1
      })
    },
  },
  mutations: {
    SET_MENU_ID(state, id) {
      state.menuId = id
    },
    STORE_CUSTOM1S(state, records) {
      state.records = { ...state.records, ...records }
    },
    UPDATE_CUSTOM1(state, custom1) {
      state.records[custom1.id] = custom1
    },
  },
}
