import { createI18n } from 'vue-i18n'
import { merge } from 'lodash'
import axios from '@/store/api-axios'
import messages from '@/languages/i18n/en.json'
import feCoreMessages from '@winnow/fe-core/dist/locales/en.json'
import extra from '@/languages/not-translated-en-GB.json'

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en: merge(messages, feCoreMessages, extra) },
  silentFallbackWarn: import.meta.env.DEV,
})

const loadedLanguages = ['en'] // our default language that is preloaded

const setI18nLanguage = (lang) => {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = lang
  } else {
    i18n.global.locale.value = lang
  }
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(locale) {
  // `en-GB` or `zh-HANS` will turn into `en`, `zh`
  const lang = locale.split('-')[0]
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return Promise.all([
    import(/* webpackChunkName: "[request]" */ `@/languages/i18n/${lang}.json`),
    import(
      /* webpackChunkName: "[request]" */ `../../node_modules/@winnow/fe-core/dist/locales/${lang}.json`
    ),
  ]).then(([messages, feCoreMessages]) => {
    i18n.global.setLocaleMessage(lang, merge(messages.default, feCoreMessages.default))
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n
