<template>
  <LayoutDefault
    extended-content
    class="pt-5"
  >
    <Hero>
      <template #default>{{ $t('navigation.taxonomy') }}</template>
      <template #hero-content>
        <TabsLvl1Links>
          <li>
            <router-link
              to="/taxonomy/terms"
              class="tab-item"
            >
              <span>{{ $t('taxman.baseTerms') }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/taxonomy/facets"
              class="tab-item"
            >
              <span>{{ $t('taxman.facets') }}</span>
            </router-link>
          </li>
        </TabsLvl1Links>
      </template>
    </Hero>

    <div class="shell-expandedContent">
      <div class="wrapper">
        <TreeBox class="TreeBox--fancy">
          <template #main>
            <Modal ref="moveConfirmModal">
              <template #default="{ close }">
                <div class="modal-body">
                  <p
                    class="mb-4"
                    v-html="
                      $t('messages.moveTaxonomyNode', {
                        nodeToBeMovedName: byId(pendingTermId).termName,
                        nodeToBeMovedChildrenCount: getTotalCount(pendingTermId),
                        newParent: byId(pendingNewParentId).termName,
                        newParentChildrenCount: getTotalCount(pendingNewParentId),
                      })
                    "
                  />

                  <div
                    v-if="isMoving"
                    class="flex flex-center p-4"
                  >
                    <Loading-Spinner class="spinner--lg" />
                  </div>
                  <div
                    v-else
                    class="buttonGroup flex-center"
                  >
                    <button
                      type="button"
                      class="button button--secondary"
                      @click="close"
                    >
                      <SvgIcon name="24-ic-delete" />
                      <span>{{ $t('actions.cancel') }}</span>
                    </button>
                    <button
                      type="button"
                      data-test-confirm-move
                      class="button button--primary"
                      @click="moveTerm(close)"
                    >
                      <SvgIcon name="24-ic-check" />
                      <span>{{ $t('actions.confirm') }}</span>
                    </button>
                  </div>
                </div>
              </template>
            </Modal>
            <TreeOfTerms
              :node-classes="
                (nodeId) => ({
                  'is-active': displayedId === nodeId,
                })
              "
              @node-click="displayRecord($event)"
              @result-click="displayRecord($event)"
            >
              <template
                v-if="pendingTermId"
                #sticky-top-content
              >
                <div class="p-2 flex flex-middle flex-between termToBeMoved">
                  {{ byId(pendingTermId).termName }}
                  <button
                    class="button button--icon button--round button--primary ml-2"
                    @click="pendingTermId = null"
                  >
                    <SvgIcon name="24-ic-clear" />
                  </button>
                </div>
              </template>
              <template #node="{ nodeId }">
                <div
                  v-if="$can('manage', 'taxonomy')"
                  v-test="'node-controls'"
                  class="Tree-controls"
                >
                  <template v-if="pendingTermId">
                    <div
                      v-if="isNotAllowedToBeNewParent(nodeId)"
                      data-test-move-not-allowed
                    >
                      {{ $t('messages.notAllowed') }}
                    </div>
                    <div v-else>
                      <button
                        data-test-move-term
                        class="button button--primary button--sm"
                        @click.stop="openMoveConfirmationModal(nodeId)"
                      >
                        {{ $t('actions.move') }}
                      </button>
                    </div>
                  </template>
                  <router-link
                    v-else
                    :to="{ name: 'new-term', params: { parentId: nodeId } }"
                    class="button button--icon button--round button--primary"
                  >
                    <SvgIcon name="24-ic-add" />
                  </router-link>
                </div>
              </template>
            </TreeOfTerms>
          </template>

          <template #column>
            <TermView
              v-if="displayedId"
              :record-id="displayedId"
            >
              <template
                v-if="$can('manage', 'taxonomy')"
                #controls
              >
                <router-link
                  :to="{ name: 'edit-term', params: { termId: displayedId } }"
                  class="button button--icon button--roundBig button--primary"
                >
                  <SvgIcon name="24-ic-edit" />
                </router-link>
              </template>
              <template
                v-if="$can('manage', 'taxonomy') && !isRootNode"
                #actions
              >
                <div class="flex mt-4 flex-between">
                  <DeleteWithPrompt
                    class="mr-2"
                    delete-button-class="button--secondary"
                    @confirm="deleteTerm"
                    ref="delete"
                  >
                    <p class="mb-4 font-bold">
                      {{
                        $t('actions.deleteTerm', getTotalCount(displayedId), {
                          count: getTotalCount(displayedId),
                        })
                      }}
                    </p>
                  </DeleteWithPrompt>
                  <div v-if="pendingTermId !== displayedId">
                    <button
                      data-test-move-term-button
                      class="button button--primary"
                      @click="pendingTermId = displayedId"
                    >
                      {{ $t('actions.moveTerm') }}
                    </button>
                  </div>
                </div>
              </template>
            </TermView>
          </template>
        </TreeBox>
      </div>
    </div>
  </LayoutDefault>
</template>

<script>
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import TreeBox from '@/components/TreeBox/TreeBox.vue'
import TermView from '@/components/TermView/TermView.vue'
import TreeOfTerms from '@/components/TreeOfTerms.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import Modal from '@/components/Modal.vue'
import TabsLvl1Links from '@/components/Tabs/TabsLvl1Links.vue'
export default {
  components: {
    LayoutDefault,
    Hero,
    TreeBox,
    TermView,
    TreeOfTerms,
    DeleteWithPrompt,
    Modal,
    TabsLvl1Links,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },

  data() {
    return {
      displayedId: null,
      isMoving: false,
      pendingTermId: null,
      pendingNewParentId: null,
    }
  },
  computed: {
    ...mapGetters('taxonomy/terms', ['byId', 'childIds', 'allTermChildIds']),
    isRootNode() {
      return this.byId(this.displayedId).parentId === null ? true : false
    },
  },
  methods: {
    displayRecord(id) {
      this.displayedId = id
    },
    isNotAllowedToBeNewParent(id) {
      const isSameNode = this.pendingFacetId === id
      // A node cannot be moved to a node that is one of its children
      const isChildOfPendingNode = this.allTermChildIds(this.pendingTermId).indexOf(id) > -1
      const isAlreadyParent = id === this.byId(this.pendingTermId).parentId

      return isSameNode || isChildOfPendingNode || isAlreadyParent
    },
    getTotalCount(id) {
      return this.allTermChildIds(id).length
    },
    async moveTerm(callback) {
      this.isMoving = true

      try {
        await this.$store.dispatch('taxonomy/terms/moveTerms', {
          termId: this.pendingTermId,
          newParentId: this.pendingNewParentId,
        })

        this.toast.success(this.$t('toast.success.generic'))
      } catch (error) {
        this.toast.error(this.$t('toast.error.saveData'))
      } finally {
        callback()
        this.isMoving = false
        this.pendingTermId = null
        this.pendingNewParentId = null
      }
    },
    openMoveConfirmationModal(id) {
      this.pendingNewParentId = id
      this.$refs.moveConfirmModal.open()
    },
    async deleteTerm() {
      const id = this.displayedId
      try {
        await this.$store.dispatch('taxonomy/terms/deleteTerm', id)

        this.displayedId = null

        this.$store.dispatch('taxonomy/terms/removeFromRecordsAndTreeStructure', id)

        this.toast.success(this.$t('toast.success.generic'))
      } catch (error) {
        this.toast.error(this.$t('toast.error.saveData'))
      } finally {
        this.$refs.delete.finishProcessing()
      }
    },
  },
}
</script>

<style lang="scss">
.termToBeMoved {
  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  background: theme('colors.lemon.100');
}
</style>
