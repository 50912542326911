<template>
  <ul>
    <li
      v-for="state in displayedStates"
      :key="state.key"
      class="mb-4"
      data-test-progress-step
    >
      <SvgIcon
        v-if="state.status === 'DONE'"
        class="mr-2 text-mint inline"
        name="24-ic-check"
        data-test-progress-done
      />
      <Loading-Spinner
        v-else-if="state.status === 'PROCESSING'"
        class="flex-inline mr-2"
        data-test-progress-pending
      />
      <SvgIcon
        v-else-if="state.status === 'ERROR'"
        class="mr-2 text-apple inline"
        data-test-progress-done
        name="24-ic-clear"
      />
      <SvgIcon
        v-else-if="state.status === 'PAUSED'"
        class="mr-2 text-carrot inline"
        name="24-ic-timer"
        data-test-progress-remaining
      />
      <SvgIcon
        v-else-if="state.status === 'IDLE'"
        class="mr-2 text-grey-400 inline"
        name="24-ic-timer"
        data-test-progress-remaining
      />
      <span data-test-progress-label>{{ state.label }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    // [...{key: String, order: Number, label: String}...]
    // order is 1 based
    states: {
      type: Array,
      required: true,
    },
    errorStates: {
      type: Array,
      required: false,
    },
    // needs to be one of the state keys
    inProgress: {
      type: String,
      required: true,
    },
    paused: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    pendingState() {
      return this.states.find((state) => state.key === this.inProgress)
    },
    errorState() {
      return this.errorStates.find((state) => state.key === this.inProgress)
    },
    displayedStates() {
      return this.states.map((state) => {
        let status = 'IDLE'
        if (this.pendingState) {
          if (state.order === this.pendingState.order) {
            status = this.paused ? 'PAUSED' : 'PROCESSING'
          } else if (state.order < this.pendingState.order) {
            status = 'DONE'
          }
        } else if (this.errorState) {
          if (state.order === this.errorState.order * -1) {
            status = 'ERROR'
          } else if (state.order < this.errorState.order * -1) {
            status = 'DONE'
          }
        } else {
          status = 'DONE'
        }
        return {
          ...state,
          status,
        }
      })
    },
  },
}
</script>

<style></style>
