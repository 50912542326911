<template>
  <ValidationForm
    action="/"
    @submit.prevent="saveData"
    @reset.prevent="$emit('cancel')"
    ref="form"
  >
    <template v-slot="{ serverErrors }">
      <div class="form-layout--x3 mb-8">
        <!-- Target Type -->
        <ValidationGroup
          v-slot="{ hasAnyErrors }"
          :validator="v$.buffer.type"
        >
          <label for="wasteTargetType">{{ $t('organisations.labels.targetType') }}</label>
          <select
            id="wasteTargetType"
            v-model="buffer.type"
            data-test-target-type-field
            :class="['customSelect', { 'is-invalid': hasAnyErrors }]"
            class="customSelect"
          >
            <option
              :value="null"
              disabled
            >
              {{ $t('organisations.options.selectTarget') }}
            </option>
            <option value="SALES_PERCENTAGE">
              {{ $t('titles.wastePercentageAsSales') }}
            </option>
            <option value="GRAMS_PER_COVER">
              {{ $t('titles.gramsPerCover') }}
            </option>
          </select>
        </ValidationGroup>
        <!-- Target Value -->
        <ValidationGroup
          v-slot="{ hasAnyErrors }"
          :validator="v$.buffer.value"
        >
          <label for="wasteTargetValue">{{ $t('organisations.labels.targetValue') }}</label>
          <input
            id="wasteTargetValue"
            v-model="buffer.value"
            type="number"
            step="any"
            :class="['formControl', { 'is-invalid': hasAnyErrors }]"
            :disabled="!buffer.type"
            :placeholder="$t('organisations.placeholder.targetValue')"
            data-test-target-value-field
          />
        </ValidationGroup>

        <div class="flex">
          <!-- Target Effective Date -->
          <ValidationGroup
            v-slot="{ hasAnyErrors }"
            :validator="v$.buffer.effectiveFromDate"
            :server-errors="serverErrors.organisation"
            class="w-full mr-1"
          >
            <label for="wasteTargetEffectiveDate">{{ $t('organisations.labels.effective') }}</label>
            <input
              id="wasteTargetEffectiveDate"
              v-model="buffer.effectiveFromDate"
              :placeholder="DATE_FORMAT_ISO_8601"
              :class="['formControl singleDate', { 'is-invalid': hasAnyErrors }]"
              type="date"
              data-test-target-effective-field
            />
          </ValidationGroup>
          <!-- Target End Date -->
          <div class="w-full">
            <div class="flex flex-between">
              <label for="wasteTargetEndDate">{{ $t('organisations.tableHeaders.endDate') }}</label>
              <button
                v-if="buffer.targetEndDate"
                data-test-target-end-date-clear-btn
                type="button"
                class="mb-1 button button--link"
                :aria-label="$t('organisations.wasteTargets.action.clearEndDate')"
                @click.stop="buffer.targetEndDate = null"
              >
                {{ $t('actions.clear') }}
              </button>
            </div>
            <input
              id="wasteTargetEndDate"
              v-model="buffer.targetEndDate"
              :disabled="!buffer.effectiveFromDate"
              :min="buffer.effectiveFromDate"
              :placeholder="DATE_FORMAT_ISO_8601"
              class="formControl singleDate"
              type="date"
            />
          </div>
        </div>

        <div>
          <!-- Target Description -->
          <label for="wasteTargetDescription">{{ $t('organisations.labels.description') }}</label>
          <input
            id="wasteTargetDescription"
            v-model="buffer.description"
            data-test-target-description-field
            type="text"
            class="formControl"
            :placeholder="$t('organisations.placeholder.targetDescription')"
          />
        </div>
      </div>
      <div class="flex flex-between flex-wrap">
        <Loading-Spinner
          v-if="savingStatus === 'SAVING'"
          class="spinner--lg mt-4"
        />
        <slot
          v-else
          name="actions"
        >
          <div class="buttonGroup">
            <button
              data-test-waste-targets-submit-create
              type="submit"
              class="button button--primary"
            >
              <SvgIcon name="24-ic-check" />
              <span>{{ $t('organisations.action.createTarget') }}</span>
            </button>
            <button
              type="reset"
              class="button button--secondary"
            >
              <span>{{ $t('actions.cancel') }}</span>
            </button>
          </div>
        </slot>
      </div>
    </template>
  </ValidationForm>
</template>
<script>
import { useToast } from 'vue-toastification'
import moment from 'moment-timezone'
import { required, minValue, between, integer } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { DATE_FORMAT_ISO_8601 } from '@/store/constants'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationForm from '@/components/Validation/ValidationForm.vue'

// the displayed date is formatted based on the locale of the user's browser,
// but the parsed value should always be formatted yyyy-mm-dd.
const formatDate = (date) => moment(date).locale('en-US').format(DATE_FORMAT_ISO_8601)

function Model(overwrites) {
  if (overwrites) {
    const { effectiveFromDate, targetEndDate } = overwrites

    overwrites = {
      ...overwrites,
      ...(effectiveFromDate && { effectiveFromDate: formatDate(effectiveFromDate) }),
      ...(targetEndDate && { targetEndDate: formatDate(targetEndDate) }),
    }
  }
  return {
    type: null,
    value: null,
    description: null,
    effectiveFromDate: null,
    targetEndDate: null,
    ...overwrites,
  }
}

export default {
  components: {
    ValidationGroup,
    ValidationForm,
  },
  props: {
    id: String,
    actionType: String,
    locationId: String,
  },
  emits: ['cancel', 'saveData'],
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      savingStatus: 'IDLE',
      buffer: new Model(
        this.id ? this.$store.getters['hierarchy/waste-targets/byId'](this.id) : null
      ),
      DATE_FORMAT_ISO_8601,
    }
  },
  validations() {
    const { type } = this.buffer

    return {
      buffer: {
        type: {
          required,
        },
        value: {
          required,
          minValue: minValue(0),
          ...(type === 'GRAMS_PER_COVER' && {
            integer,
          }),
          ...(type === 'SALES_PERCENTAGE' && {
            percentageValue: between(0, 100),
          }),
        },
        effectiveFromDate: {
          required,
        },
      },
    }
  },
  methods: {
    saveData() {
      this.$refs.form.clearServerMessages()

      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.savingStatus = 'SAVING'

      this.$store
        .dispatch(`hierarchy/waste-targets/${this.actionType}`, {
          nodeId: this.locationId,
          targetId: this.id,
          payload: this.buffer,
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.toast.success(this.$t('toast.success.generic'))
          this.$emit('saveData')
        })
        .catch((errorObj) => {
          this.savingStatus = 'ERROR'
          this.$refs.form.handleValidationErrorsInResponse(errorObj)
        })
    },
  },
}
</script>
