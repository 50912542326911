/**
 * Mixin for helping users go back to a previous page in a specific state
 * Optionally scroll to and highlight an item on the return page
 */

export default {
  data() {
    return {
      returnPath: null,
      returnQuery: null,
    }
  },
  created() {
    this.extractReturnParams()
  },
  methods: {
    /**
     * Extract the path and any parameters required for navigating back to a previous route in a specific state
     * @param {Array[String]} ignoreProps query params that might not be helpful for the return route
     */
    extractReturnParams(ignoreProps) {
      let returnQuery = { ...this.$route.query }
      this.returnPath = returnQuery.returnPath
      delete returnQuery['returnPath']
      if (ignoreProps) {
        for (let prop of ignoreProps) {
          delete returnQuery[prop]
        }
      }
      this.returnQuery = returnQuery
    },
    /**
     *
     * @param {Object}  props
     * @param {String}  props.hash optional id of an element on the return route to which the browser should scroll and highlight
     * @param {Route}   props.backupRoute optional route that can be used if there aren't return params but the user still needs to go to a specific route
     */
    goBack({ hash, backupRoute, onComplete } = {}) {
      if (this.returnPath) {
        this.$router.push(
          {
            query: this.returnQuery,
            hash: hash && `#${hash}`,
            path: this.returnPath,
          },
          function navigationCallback() {
            this.$nextTick(onComplete)
          }.bind(this)
        )
      } else if (backupRoute) {
        this.$router.push(
          backupRoute,
          function navigationCallback() {
            this.$nextTick(onComplete)
          }.bind(this)
        )
      }
    },
  },
}
