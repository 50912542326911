<template>
  <div
    v-test="'content-area'"
    class="box-content-full-width"
  >
    <loaded-content
      :is-loading="duplicateMappingsLoadingStatus === 'LOADING'"
      :has-error="duplicateMappingsLoadingStatus === 'ERROR'"
      :has-data="duplicateMappingsLoadingStatus === 'LOADED' && !!duplicateGroups.length"
      no-results-type="SUCCESS"
    >
      <template #no-data>
        <p
          class="text-center"
          data-test-empty-list
        >
          {{ $t('menus.duplicateMappings.messages.noRecords') }}
        </p>
      </template>

      <ol data-test-duplicates>
        <menu-duplicate-group
          v-for="group in duplicateGroups"
          :key="group.taxonomyCode"
          v-test="'duplicate-group'"
          tag="li"
          :is-open="openItem === group.taxonomyCode"
          :code="group.taxonomyCode"
          :mapping="group.taxonomyMapping"
          :food-item-ids="group.instances"
          :menu-id="menuId"
          :readonly="readonly"
          @group-open="openItem = group.taxonomyCode"
          @group-close="openItem = ''"
          @merge="getData"
        />
      </ol>
    </loaded-content>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import MenuDuplicateGroup from './DuplicateMappings/MenuDuplicateGroup.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import menuMixin from '@/pages/Menus/menuMixin.js'

export default {
  components: {
    MenuDuplicateGroup,
    LoadedContent,
  },
  mixins: [menuMixin],
  props: {
    menuId: String,
  },
  data() {
    return {
      openItem: '',
      duplicateMappingsLoadingStatus: 'IDLE',
    }
  },
  computed: {
    readonly() {
      return this.menu && this.menu.archived
    },
    ...mapState('menu-management/duplicate-mappings', {
      duplicateGroups: 'groups',
    }),
    duplicateGroups() {
      return this.$store.getters['menu-management/menus/duplicateMappingsInMenu'](this.menuId)
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.duplicateMappingsLoadingStatus = 'LOADING'
      return this.$store
        .dispatch('menu-management/duplicate-mappings/getDuplicateMappings', {
          menuId: this.menuId,
        })
        .then(() => {
          this.duplicateMappingsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.duplicateMappingsLoadingStatus = 'ERROR'
        })
    },
  },
}
</script>

<style></style>
