<template>
  <div>
    <div class="flex flex-middle">
      <label :for="id">{{ $t('hierarchy.label.package') }}</label>
      <Loading-Spinner
        v-if="isLoading"
        class="spinner--xs mb-1 ml-2"
      />
    </div>
    <select
      :id="id"
      v-model="selected"
      data-hierarchy-create-package-select
      class="customSelect"
      :disabled="isLoading"
      @change="$emit('update:modelValue', generateOutputValue(selected))"
    >
      <option
        value=""
        disabled
      >
        {{ $t('hierarchy.option.packageDefault') }}
      </option>
      <option
        v-for="pckg in packages"
        :key="pckg.id"
        :value="pckg.id"
      >
        {{ pckg.name }}
      </option>
    </select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

export default {
  props: {
    id: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      isLoading: false,
      selected: '',
      outputValue: null,
    }
  },
  computed: {
    ...mapGetters('hierarchy/nodes', ['packages']),
  },
  watch: {
    modelValue: {
      handler(value) {
        if (value === null) {
          this.selected = ''
        } else {
          this.selected = value
        }
      },
      immediate: true,
    },
  },
  created() {
    if (!this.packages.length) {
      this.isLoading = true

      this.$store
        .dispatch(`hierarchy/nodes/getPackages`)
        .catch((e) => {
          this.toast.error(this.$t('toast.error.getData'))
          throw e
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  methods: {
    generateOutputValue(selected) {
      return selected === '' ? null : selected
    },
  },
}
</script>
