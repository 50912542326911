import api from '@/store/api/waste-log'
const state = {
  records: {},
  meta: null,
  selectedTransaction: null,
  selectedTracker: null,
  selectedDay: null,
  trackerRecords: {},
  images: {},
  focusedTime: {
    start: null,
    end: null,
  },
}

const getters = {
  /**
   * Get one transaction by its id
   * @returns {Object}
   */
  byId: (state) => (id) => state.records[id],

  /**
   * Get image for a transaction
   * @returns {string}
   */
  image: (state) => (id) => {
    return state.images[id]
  },

  /**
   * Meta properties for the current list of transactions
   * @returns {Object | null}
   */
  meta: (state) => state.meta,

  /**
   * List of all transactions for the current time period
   * @returns {Array}
   */
  transactions: (state) => Object.values(state.records) || [],

  /**
   * One transaction that is flagged as selected
   * @returns {Object}
   */
  selectedTransaction: (state) => state.records[state.selectedTransaction],

  /**
   * List of tracker names
   * @returns {Array}
   */
  trackers: (state) => Object.keys(state.trackerRecords).sort(),

  /**
   * List of transactions grouped by tracker
   * @returns {Object}
   */
  trackerData: (state) =>
    Object.keys(state.trackerRecords).reduce((trackers, trackerName) => {
      trackers[trackerName] = state.trackerRecords[trackerName].map(
        (transactionid) => state.records[transactionid]
      )
      return trackers
    }, {}),

  /**
   * Get total weight in grams summed across all transactions
   * @returns {number}
   */
  totalWeight: (state, getters) =>
    getters.transactions.reduce((total, transaction) => (total += transaction.weight.value), 0),

  /**
   * Get total cost of waste summed across all transactions
   * @returns {number}
   */
  totalCost: (state, getters) =>
    getters.transactions.reduce((total, transaction) => (total += transaction.cost || 0), 0),
}

const actions = {
  getTransactions({ commit, rootGetters }, params = {}) {
    const locale = rootGetters['auth/preferredMenuLocale']

    return api
      .fetchAllWaste({
        ...params,
        locale,
        sortBy: 'createdDate',
        page: 0,
        size: 9999,
      })
      .then((response) => {
        const { data, metadata: meta } = response.data

        commit('CLEAR_RECORDS')
        commit('CLEAR_META')
        commit('STORE_RECORDS', data)
        commit('STORE_META', meta)
        commit('STORE_TRACKERS_DATA', data)
        return data
      })
  },
}

const mutations = {
  STORE_RECORDS(state, newRecords) {
    state.records = newRecords.reduce((acc, item) => {
      acc[item.id] = item
      return acc
    }, {})
  },

  STORE_RECORD(state, newRecord) {
    const { records } = state

    records[newRecord.id] = newRecord
  },

  STORE_TRACKERS_DATA(state, data) {
    state.trackerRecords = data.reduce((trackers, item) => {
      trackers[item.trackerName] = trackers[item.trackerName] || []
      trackers[item.trackerName].push(item.id)
      return trackers
    }, {})
  },

  STORE_META(state, meta) {
    state.meta = meta
  },

  CLEAR_RECORD(state, id) {
    delete state.records[id]
  },

  CLEAR_RECORDS(state) {
    state.records = {}
  },

  CLEAR_META(state) {
    state.meta = null
  },

  SET_SELECTED_TRANSACTION(state, id) {
    state.selectedTransaction = id
  },
  SET_SELECTED_TRACKER(state, name) {
    state.selectedTracker = name
  },
  SET_SELECTED_DAY(state, date) {
    state.selectedDay = date
  },
  STORE_IMAGE(state, { id, src }) {
    // Vue.set(state.images, id, src)
    state.images[id] = src
  },
  SET_FOCUSED_TIME(state, { start, end }) {
    state.focusedTime = { start, end }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
