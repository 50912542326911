<template>
  <div>
    <template v-if="internalMovingNodeId">
      <button
        type="button"
        class="mx-2 button button--secondary button--primary"
        @click="cancelMove"
      >
        {{ $t('actions.cancelMoving') }}
      </button>
      <button
        type="button"
        data-test-hierarchy-choose-move-btn
        :disabled="!isValidMoveTarget"
        class="mx-2 button button--primary"
        @click="selectTarget"
      >
        {{ $t('actions.choose') }}
      </button>
      <Modal ref="moveConfirmModal">
        <template #default="{ close }">
          <div class="modal-body">
            <OrgMove
              :id="internalMovingNodeId"
              :parent-id="nodeId"
              @success="onMoveSuccess($event, close)"
              @cancel="close"
            />
          </div>
        </template>
      </Modal>
    </template>
    <template v-else>
      <button
        type="button"
        data-test-hierarchy-init-move-btn
        class="mx-2 button button--secondary button--primary"
        @click="move"
      >
        {{ $t('actions.move') }}
      </button>
    </template>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import OrgMove from '@/components/OrgBrowser/OrgMove.vue'
export default {
  components: {
    Modal,
    OrgMove,
  },
  props: {
    nodeId: [String, Number],
    movingNodeId: {
      type: [String, Number],
      default: null,
    },
    disabled: Boolean,
  },
  emits: ['validate-target', 'move', 'move-success', 'move-cancel'],
  data() {
    return {
      internalMovingNodeId: this.movingNodeId,
    }
  },
  computed: {
    node() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    movingNode() {
      return this.$store.getters['hierarchy/nodes/byId'](this.internalMovingNodeId)
    },
    isValidMoveTarget() {
      if (!this.node || !this.movingNode) {
        return false
      }

      if (this.node.id === this.movingNode.id || this.movingNode.parentId === this.node.id) {
        return false
      }

      const levelsRules = {
        1: { min: 1, max: 3 },
        2: { min: 2, max: 3 },
        3: { min: 3, max: 4 },
        4: { min: 4, max: 14 },
        5: { min: 5, max: 14 },
        6: { min: 6, max: 14 },
        7: { min: 7, max: 14 },
        8: { min: 8, max: 14 },
        9: { min: 9, max: 14 },
        10: { min: 10, max: 14 },
        11: { min: 11, max: 14 },
        12: { min: 12, max: 14 },
        13: { min: 13, max: 14 },
        14: { min: 14, max: 15 },
        15: { min: 15, max: 16 },
        16: { min: 16, max: 16 },
      }

      const range = levelsRules[this.node.level]
      if (!range) return false
      return this.movingNode.level >= range.min && this.movingNode.level <= range.max
    },
  },
  watch: {
    movingNodeId(id) {
      this.internalMovingNodeId = id
    },
    isValidMoveTarget(isValid) {
      this.$emit('validate-target', isValid)
    },
  },
  methods: {
    selectTarget() {
      if (this.isValidMoveTarget) this.$refs.moveConfirmModal.open()
    },
    move() {
      this.internalMovingNodeId = this.nodeId
      this.$emit('move', this.internalMovingNodeId)
    },
    onMoveSuccess({ id }, callback) {
      this.$emit('move-success', id)
      this.internalMovingNodeId = null
      callback()
    },
    cancelMove() {
      this.$emit('move-cancel', this.internalMovingNodeId)
      this.internalMovingNodeId = null
    },
  },
}
</script>

<style></style>
