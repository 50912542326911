import { ORG_LEVELS } from '@/store/constants'

export const getOrgDisplayName = (node, maxLevelsDisplayed = 3) => {
  const { level, qualifiedName, name } = node

  if (level > ORG_LEVELS - maxLevelsDisplayed) {
    return qualifiedName
      .split(' / ')
      .slice((level - ORG_LEVELS + maxLevelsDisplayed) * -1)
      .join(' / ')
  } else {
    return name
  }
}
