<template>
  <div
    v-if="day.isToday"
    class="card-tooltip"
  >
    {{ $t('titles.today') }}
  </div>
  <div
    class="card-header"
    v-bind="$attrs"
  >
    <div
      class="card-weekday"
      data-test-weekday
    >
      {{ day.weekdayShort }}
    </div>
    <div
      class="card-day"
      data-test-day
    >
      {{ day.number }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    day: Object,
  },
}
</script>
