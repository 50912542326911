import { WINNOW_ORG_ID } from '@/store/constants'
import mapUser from './get-user'

export default function mapOrganisation(data) {
  let level = data.level || (data.locationLevel && data.locationLevel.level)
  return {
    id: data.id,
    name: data.name,
    qualifiedName: data.qualifiedName,
    level: data.id.toString() === WINNOW_ORG_ID ? 0 : level,
    locationLevel: data.locationLevel,
    archived: data.archived,
    parentId: data.parentId,
    siteStatusSummary: data.siteStatusSummary,
    ...(data.users && { userIds: data.users.map(({ id }) => id) }),
    // in order to avoid updating the records with null values for when we get the tree
    // only add the key-value pairs if they exist in the payload
    ...(data.childrenCount && { childrenCount: data.childrenCount }),
    ...(data.sitesCount && { sitesCount: data.sitesCount }),
    ...(data.localisation && {
      localisation: {
        locale: data.localisation.locale || null,
        timezone: data.localisation.timezone || null,
        currencyCode: data.localisation.currency ? data.localisation.currency.code : null,
        weightUnitId: data.localisation.weightUnit ? data.localisation.weightUnit.id : null,
        temperatureUnitId: data.localisation.temperatureUnit
          ? data.localisation.temperatureUnit.id
          : null,
        currency: data.localisation.currency
          ? {
              code: data.localisation.currency.code,
              country: data.localisation.currency.country,
              symbol: data.localisation.currency.symbol,
            }
          : null,
        weightUnit: data.localisation.weightUnit
          ? {
              id: data.localisation.weightUnit.id,
              name: data.localisation.weightUnit.name,
              description: data.localisation.weightUnit.description,
            }
          : null,
        temperatureUnit: data.localisation.temperatureUnit
          ? {
              id: data.localisation.temperatureUnit.id,
              name: data.localisation.temperatureUnit.name,
              description: data.localisation.temperatureUnit.description,
            }
          : null,
      },
    }),
    ...(data.locationDetails && {
      locationDetails: {
        customerSuccessManager:
          data.locationDetails.customerSuccessManager &&
          mapUser(data.locationDetails.customerSuccessManager),
        packageId: data.locationDetails.packageId,
        sharedImageLibraryEnabled: data.locationDetails.sharedImageLibraryEnabled,
      },
    }),
  }
}
