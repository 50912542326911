<template>
  <form
    action="/"
    class="form-layout--x3"
    @submit.prevent="submit"
  >
    <ValidationGroup
      v-slot="{ hasAnyErrors }"
      :validator="v$.buffer.domain"
    >
      <div class="flex flex-between flex-middle">
        <label for="reporing-sending-control-domain-field">{{
          $t('organisations.sendingControls.domains.actions.add')
        }}</label>
        <SvgIcon
          v-tooltip="{
            theme: 'tooltip-white',
            content: $t('organisations.sendingControls.domains.messages.multiple'),
            placement: 'top',
          }"
          class="text-slate ml-2"
          name="24-ic-info"
        ></SvgIcon>
      </div>
      <div class="sendingControlsDomainFieldWrapper">
        <add-sending-control-field
          id="reporing-sending-control-domain-field"
          ref="addDomainField"
          v-model="buffer.domain"
          data-test-sending-controls-domain-filed
          :placeholder="$t('organisations.sendingControls.domains.placeholders.add')"
          type="text"
          :class="['formControl', { 'is-invalid': hasAnyErrors }]"
          @comma-press="submit"
        />
        <button
          :disabled="!buffer.domain"
          type="submit"
          class="button button--primary button--inputAction"
        >
          <SvgIcon name="24-ic-check" />
        </button>
      </div>
    </ValidationGroup>
  </form>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import ValidationGroup from '@/components/Validation/ValidationGroup.vue'

import AddSendingControlField from './AddSendingControlField.vue'

export default {
  components: {
    ValidationGroup,
    AddSendingControlField,
  },
  props: {
    locationDomains: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submit-domain'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      buffer: {
        domain: '',
      },
    }
  },
  mounted() {
    this.$refs.addDomainField.$el.focus()
  },
  validations() {
    return {
      buffer: {
        domain: {
          required,
          duplicatedDomainForNode: (val) =>
            !this.locationDomains.find((locationDomain) => locationDomain === val),
        },
      },
    }
  },
  methods: {
    submit() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.$emit('submit-domain', this.buffer.domain)

      this.v$.$reset()
      this.buffer.domain = ''
    },
  },
}
</script>
<style lang="scss">
.sendingControlsDomainFieldWrapper {
  position: relative;
}
</style>
