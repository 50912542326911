<template>
  <div class="timeline my-4">
    <h2>
      {{ $t('wasteTimeline.titles.detail', { currency, weightUnit }) }}
    </h2>
    <timeline-detail
      :timezone="timezone"
      :data-points="dataPoints"
      :start="start"
      :end="end"
      :focused-time="focusedTime"
      :selected-id="selectedId"
      :weight-unit="weightUnit"
      :value-label="valueLabel"
      v-bind="$attrs"
      @bar-clicked="$emit('change', $event.id)"
    />
    <h2 class="mt-4">{{ $t('wasteTimeline.titles.overview') }}</h2>
    <p class="text-sm">{{ $t('wasteTimeline.titles.overviewSubtitle') }}</p>
    <timeline-overview
      :timezone="timezone"
      :data-points="dataPoints"
      :start="start"
      :end="end"
      :focused-time="focusedTime"
      :selected-id="selectedId"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import TimelineDetail from './timeline-detail.vue'
import TimelineOverview from './timeline-overview.vue'

export default {
  components: {
    TimelineDetail,
    TimelineOverview,
  },
  props: {
    dataPoints: Array,
    selectedId: [String, Number],
    start: Date,
    end: Date,
    timezone: String,
    weightLabel: String,
    weightUnit: String,
    currency: String,
    valueLabel: String,
    focusedTime: null,
  },
  emits: ['change'],
}
</script>

<style lang="scss">
.timeline {
  position: relative;
  shape-rendering: crispEdges;
}
</style>
