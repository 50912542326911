import api from '@/store/api/menus'
import getOldValues from '@/store/utils/getOldValues'

const state = {
  menuId: null,
  records: {},
}
const getters = {
  foodGroupById: (state, getters, rootState, rootGetters) => (id) => {
    let foodGroup = state.records[id]
    if (!foodGroup) return {}
    foodGroup.displayName = rootGetters['menu-management/displayEnglish']
      ? foodGroup.nameEnglish
      : foodGroup.name
    return foodGroup
  },
  foodGroupsById: (state, getters) => (ids) =>
    ids ? ids.map((id) => getters.foodGroupById(id)) : [],
  manualOrderedFoodItems: (state, getters, rootState, rootGetters) => (foodGroupId) => {
    let foodGroup = state.records[foodGroupId]
    if (!foodGroup) return []
    if (!foodGroup.foodItemIds) return []
    let foodItems = rootGetters['menu-management/food-items/foodItemsById'](foodGroup.foodItemIds)
    if (!foodItems) return []
    return foodItems.sort((a, b) => a.displayOrder - b.displayOrder)
  },
}
const actions = {
  getFoodGroups({ commit }, { menuId, stageId }) {
    return api.fetchFoodGroups(menuId, stageId).then(({ foodGroupRecords, stageRecords }) => {
      commit('STORE_FOOD_GROUPS', { records: foodGroupRecords })
      commit('menu-management/stages/STORE_STAGES', stageRecords, { root: true })
      if (stageId) {
        commit(
          'menu-management/stages/UPDATE_STAGE',
          { id: stageId, foodGroupIds: Object.keys(foodGroupRecords) },
          { root: true }
        )
      }
      if (menuId) {
        commit(
          'menu-management/menus/UPDATE_MENU',
          { id: menuId, foodGroupIds: Object.keys(foodGroupRecords) },
          { root: true }
        )
      }
    })
  },
  saveChanges({ commit, getters }, { menuId, foodGroupId, data }) {
    return api
      .patchFoodGroup({
        menuId,
        foodGroupId,
        data: {
          old: getOldValues(getters.foodGroupById(foodGroupId), data),
          new: data,
        },
      })
      .then((foodGroup) => {
        commit('UPDATE_FOOD_GROUP', foodGroup)
        return foodGroup
      })
  },
  createFoodGroup({ commit }, { menuId, stageId, data }) {
    return api.postFoodGroup({ menuId, stageId, data }).then((foodGroup) => {
      commit('UPDATE_FOOD_GROUP', foodGroup)
      return foodGroup
    })
  },
  addFoodGroup({ commit }, foodGroup) {
    commit('UPDATE_FOOD_GROUP', foodGroup)
  },
  updateFoodGroup({ commit }, foodGroup) {
    commit('UPDATE_FOOD_GROUP', foodGroup)
  },
  deleteFoodGroup({ commit }, { menuId, foodGroupId }) {
    return api.deleteFoodGroup({ menuId, foodGroupId }).then(() => {
      commit('DELETE_FOOD_GROUP', foodGroupId)
    })
  },
}
const mutations = {
  STORE_FOOD_GROUPS(state, { records }) {
    Object.values(records).forEach((record) => {
      state.records[record.id] = {
        ...state.records[record.id],
        ...record,
      }
    })
  },
  UPDATE_FOOD_GROUP(state, foodGroup) {
    // food items are requested separately because they're not part of the food groups fetch call
    // but, for the sake of consistency, we're using a model that includes the foodItemIds and
    // is used to map the incoming response, which means the foodItemsIds will always come in empty.
    // This, in turn, means that whenever we update a food group, we also remove the foodItemIds
    // In consequence, we need to manually check if the foodItemIds exists at all.
    // If it doesn't, than we use what was already stored. If it does, even if it's an empty array,
    // we will overwrite the current data
    let existingFoodGroup = state.records[foodGroup.id] || {}
    state.records[foodGroup.id] = {
      ...existingFoodGroup,
      ...foodGroup,
      foodItemIds: foodGroup.foodItemIds || existingFoodGroup.foodItemIds,
    }
  },
  DELETE_FOOD_GROUP(state, id) {
    delete state.records[id]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
