import api from '@/store/api/backdate'
export default {
  namespaced: true,
  state: {
    siteRecords: {},
    processingRecords: {},
  },
  getters: {
    sites: (state) => Object.values(state.siteRecords),
    siteById: (state) => (id) => state.siteRecords[id],
    processingStatusesForMenuId: (state) => (id) => {
      return Object.values(state.processingRecords)
        .filter((record) => record.menuId === id)
        .sort((a, b) => {
          return new Date(b.processingStart) - new Date(a.processingStart)
        })
    },
  },
  actions: {
    getBackdateSites({ commit }, { menuId, query }) {
      return api.fetchBackdateSites({ menuId, query }).then(({ sites }) => {
        commit('CLEAR_SITE_RECORDS')
        commit('STORE_SITE_RECORDS', sites)
      })
    },
    executeBackdate({ commit }, { menuId, ...payload }) {
      return api.postBackdateSites({ menuId, ...payload }).then((record) => {
        commit('STORE_PROCESSING_RECORD', record)
      })
    },
    fetchProcessingStatusForMenuId({ commit }, menuId) {
      return api.fetchProcessingStatusForMenuId(menuId).then((records) => {
        records.forEach((record) => {
          commit('STORE_PROCESSING_RECORD', record)
        })
      })
    },
  },
  mutations: {
    STORE_SITE_RECORDS(state, records) {
      records.forEach((record) => {
        state.siteRecords[record.id] = record
      })
    },
    STORE_PROCESSING_RECORD(state, record) {
      state.processingRecords[record.id] = record
    },
    CLEAR_SITE_RECORDS(state) {
      Object.keys(state.siteRecords).forEach((id) => {
        delete state.siteRecords[id]
      })
    },
  },
}
