<template>
  <form
    tabindex="-1"
    @submit.prevent="handleNodeCreate"
  >
    <h2
      class="mb-2"
      v-html="
        $t('hierarchy.message.createNode', {
          parent: node.name,
        })
      "
    ></h2>
    <div class="formGroup">
      <label :for="`create-node-levels-${parentId}`">{{ $t('hierarchy.label.level') }}</label>
      <select
        :id="`create-node-levels-${parentId}`"
        v-model="level"
        data-hierarchy-create-level-select
        :disabled="levelOptions.length === 1"
        class="customSelect"
      >
        <option
          v-for="{ name, value } in levelOptions"
          :key="value"
          :value="value"
        >
          {{ name }}
        </option>
      </select>
    </div>

    <OrgPackageSelect
      v-if="level === ORG_LEVELS || level === 'SITE_SHORTCUT'"
      :id="`create-node-package-${parentId}`"
      v-model="selectedPackage"
      class="formGroup"
    />

    <ValidationGroup
      v-if="level === 'SITE_SHORTCUT'"
      v-slot="{ hasAnyErrors }"
      :server-errors="serverErrors.organisationName"
      class="formGroup"
      data-test-name-validation-error
    >
      <label :for="`create-node-name-${parentId}`">
        {{ $t('hierarchy.action.createNodeLabel') }}
        ({{ getOptionName(14) }})
      </label>

      <OrgCreateNameField
        :id="`create-node-name-${parentId}`"
        v-model.trim="name"
        :class="[{ 'is-invalid': hasAnyErrors }]"
        :level="null"
        :parent-id="parentId"
      />
      <p
        class="my-2"
        v-html="
          $t('organisations.messages.newKitchenName', {
            name: `<b>${name}</b> / Main Building / Main kitchen`,
          })
        "
      />
    </ValidationGroup>
    <ValidationGroup
      v-else
      v-slot="{ hasAnyErrors }"
      :server-errors="serverErrors.organisationName"
      class="formGroup"
      data-test-name-validation-error
    >
      <label :for="`create-node-name-${parentId}`">
        {{ $t('hierarchy.action.createNodeLabel') }}
      </label>

      <OrgCreateNameField
        :id="`create-node-name-${parentId}`"
        v-model.trim="name"
        :class="[{ 'is-invalid': hasAnyErrors }]"
        :level="level"
        :parent-id="parentId"
      />
    </ValidationGroup>

    <Loading-Spinner
      v-if="isLoading"
      class="spinner--sm mx-auto my-4"
    />
    <div
      v-else
      class="flex flex-right mt-4"
    >
      <button
        type="button"
        class="button button--secondary mr-2"
        @click="$emit('close')"
      >
        {{ $t('actions.cancel') }}
      </button>
      <button
        data-hierarchy-create-confirm-button
        :disabled="saveDisabled"
        class="button button--primary"
      >
        {{ $t('actions.create') }}
      </button>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'

import OrgPackageSelect from '@/components/OrgBrowser/OrgPackageSelect.vue'
import OrgCreateNameField from '@/components/OrgBrowser/OrgCreateNameField.vue'

import { mapGetters } from 'vuex'
import { ORG_LEVELS } from '@/store/constants'
import { useToast } from 'vue-toastification'
import { useOrgCreate } from './useOrgCreate'
export default {
  components: {
    OrgPackageSelect,
    OrgCreateNameField,
    ValidationGroup,
  },
  props: {
    parentId: String,
  },
  emits: ['close'],
  setup(props) {
    const name = ref('')
    const level = ref(null)
    const selectedPackage = ref(null)
    const { serverErrors, createNode } = useOrgCreate({
      parentId: props.parentId,
      level,
      name,
      selectedPackage,
    })
    return {
      toast: useToast(),
      name,
      level,
      selectedPackage,
      serverErrors,
      createNode,
    }
  },
  data() {
    return {
      isLoading: false,
      ORG_LEVELS,
    }
  },
  computed: {
    ...mapGetters('hierarchy/nodes', ['getLevelName', 'levelsList', 'getChildrenNodes', 'byId']),

    availableLevels() {
      // build a new levels list with custom options
      let levels = [
        { id: 'SITE_SHORTCUT', name: 'Quick create kitchen (L14 / L15 / L16)' },
        ...this.levelsList,
      ]
      // min - minimum level at which the node can be created
      // max = maximum level at which the node can be created
      const levelRules = {
        1: { min: 2, max: 3 },
        2: { min: 3, max: 3 },
        3: { min: 4, max: 4 },
        4: { min: 5, max: 14, SITE_SHORTCUT: true },
        5: { min: 6, max: 14, SITE_SHORTCUT: true },
        6: { min: 7, max: 14, SITE_SHORTCUT: true },
        7: { min: 8, max: 14, SITE_SHORTCUT: true },
        8: { min: 9, max: 14, SITE_SHORTCUT: true },
        9: { min: 10, max: 14, SITE_SHORTCUT: true },
        10: { min: 11, max: 14, SITE_SHORTCUT: true },
        11: { min: 12, max: 14, SITE_SHORTCUT: true },
        12: { min: 13, max: 14, SITE_SHORTCUT: true },
        13: { min: 14, max: 14, SITE_SHORTCUT: true },
        14: { min: 15, max: 15 },
        15: { min: 16, max: 16 },
      }
      return levels.filter((level) => {
        const levelRule = levelRules[this.node.level]
        // we can display a custom option if we can find it defined
        // as a truthy property in one of the level rules
        if (levelRule[level.id]) return true
        return level.id >= levelRule.min && level.id <= levelRule.max
      })
    },
    // the options that will be displayed in the UI
    levelOptions() {
      return this.availableLevels.map(({ id, name }) => ({
        value: id,
        name: this.formatOptionName({ id, name }),
      }))
    },
    node() {
      return this.byId(this.parentId)
    },
    saveDisabled() {
      if (!this.name) {
        return true
      }
      if (!this.level) {
        return true
      }
      if (this.level === ORG_LEVELS && !this.selectedPackage) {
        return true
      }
      if (this.level === 'SITE_SHORTCUT' && !this.selectedPackage) {
        return true
      }
      return false
    },
  },
  created() {
    this.level = this.availableLevels[0].id
  },
  methods: {
    // if there is custom formatting needed on certain options,
    // this is the place to do it
    formatOptionName({ id, name }) {
      if (typeof id === 'number') return `${id}: ${name}`
      return name
    },
    getOptionName(id) {
      return this.levelOptions.find(({ value }) => value === id).name
    },
    handleNodeCreate() {
      this.createNode().then((node) => {
        this.$emit('created', { nodeId: node.id })
      })
    },
  },
}
</script>
