<template>
  <LayoutDefault extended-content>
    <LoadedContent
      :is-loading="loadingStatus === 'LOADING'"
      :has-data="loadingStatus === 'LOADED'"
      :has-error="loadingStatus === 'ERROR'"
      class="pentaho-wrapper"
    >
      <iframe
        ref="singleSiteDashboard"
        data-hj-allow-iframe
        class="pentaho-iframe"
        :src="iframeUrl"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
        scrolling="no"
      >
      </iframe>
    </LoadedContent>
  </LayoutDefault>
</template>

<script>
import { createNamespacedHelpers, useStore } from 'vuex'
import { computed, ref } from 'vue'
import queryString from 'query-string'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import { useSelectedSite } from '@/composables/useSelectedSite'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'DownloadsPage',
  components: {
    LayoutDefault,
    LoadedContent,
  },
  setup() {
    const { getters, dispatch } = useStore()
    const allowedSites = computed(() => getters['auth/organisations/sites'])
    const userId = computed(() => getters['auth/userId'])
    const { selectedSite, initSelectedSite } = useSelectedSite({
      userId: userId.value,
      allowedSites,
    })
    const loadingStatus = ref('LOADING')
    dispatch('auth/organisations/getAllowedSites')
      .then(initSelectedSite)
      .then(() => {
        loadingStatus.value = 'LOADED'
      })
      .catch(() => {
        loadingStatus.value = 'ERROR'
      })
    return { selectedSite, loadingStatus }
  },
  computed: {
    ...mapGetters(['userSettings', 'isMultiSiteUser', 'isReseller']),
    baseUrl() {
      const singleSiteUserUrl =
        import.meta.env.VUE_APP_PENTAHO_URL +
        '/pentaho/api/repos/%3Apublic%3AWinnow%3Adashboards%3Asingle_site_download_reports.wcdf/generatedContent'
      const multiSiteUserUrl =
        import.meta.env.VUE_APP_PENTAHO_URL +
        '/pentaho/api/repos/%3Apublic%3AWinnow%3Adashboards%3AMulti_Site_Download_Reports.wcdf/generatedContent'
      return this.isMultiSiteUser || this.isReseller ? multiSiteUserUrl : singleSiteUserUrl
    },
    iframeUrl() {
      const p_site = this.isMultiSiteUser ? undefined : this.selectedSite?.legacyId
      const params = queryString.stringify({
        p_site,
        p_weight: this.userSettings.weight.code,
        p_locale: this.userSettings.locale.code,
      })
      return `${this.baseUrl}?${params}`
    },
  },
  mounted() {
    this.bindIframeResize()
  },
  beforeUnmount() {
    window.removeEventListener('message', this.resizeHandler)
  },
  methods: {
    bindIframeResize() {
      window.addEventListener('message', this.resizeHandler)
    },
    resizeHandler(ev) {
      if (ev.origin === import.meta.env.VUE_APP_PENTAHO_URL && ev.data && ev.data.pageheight) {
        this.$refs.singleSiteDashboard.style.height = Math.ceil(ev.data.pageheight) + 'px'
      }
    },
  },
}
</script>
