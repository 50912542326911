import { transactionTypes } from '@/store/modules/waste-log'

export const wasteLogParamsToProps = (route) => {
  const {
    name,
    params: { siteId },
    query: {
      categorised,
      status,
      start,
      end,
      page,
      sortBy,
      size,
      minWeight,
      maxWeight,
      minCost,
      maxCost,
      types,
      foodItems,
      stages,
      trackers,
      displayMode,
      onlyImages,
      preopenFilters,
    },
  } = route

  // map default values which should differ from one page to other
  const defaultValuesMap = {
    'waste-log-list': {
      size: 20,
    },
    'waste-log-grid': {
      size: 50,
    },
  }

  const defaultValues = defaultValuesMap[name]

  return {
    siteId,
    displayMode: displayMode !== undefined ? displayMode : 'scaled',

    start,
    end,
    categorised: categorised ? categorised === 'true' : undefined,
    preopenFilters: preopenFilters ? preopenFilters === 'true' : undefined,
    onlyImages: !onlyImages ? true : onlyImages === 'true',
    status,
    // BE expects page to start form 0
    page: page !== undefined ? parseInt(page, 10) - 1 : 0,
    sortBy: sortBy || '-createdDate',
    size: parseInt(size, 10) || defaultValues.size,

    minWeight: minWeight !== undefined ? parseFloat(minWeight) : undefined,
    maxWeight: maxWeight !== undefined ? parseFloat(maxWeight) : undefined,
    minCost: minCost !== undefined ? parseFloat(minCost) : undefined,
    maxCost: maxCost !== undefined ? parseFloat(maxCost) : undefined,
    types: types !== undefined ? types.split(',') : transactionTypes,
    foodItems: foodItems !== undefined ? (Array.isArray(foodItems) ? foodItems : [foodItems]) : [],
    stages: stages !== undefined ? (Array.isArray(stages) ? stages : [stages]) : [],
    trackers: trackers !== undefined ? trackers.split(',') : [],
  }
}
