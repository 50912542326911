<template>
  <div>
    <page-section class="mb-4">
      <!-- Header area -->
      <section-header>
        <template #section-title>
          {{ $t('menus.foodItems.title.mapping') }}
        </template>
        <template
          v-if="foodItem"
          #section-navigation
        >
          <button
            type="button"
            class="button button--secondary"
            data-test-return
            @click="goBack({ hash: foodItem.id })"
          >
            <SvgIcon name="24-ic-back" />
            <span>{{ $t('actions.return') }}</span>
          </button>
        </template>
      </section-header>
      <form
        class="box-content"
        action="/"
        @submit.prevent="onSubmit"
      >
        <table class="table table--key-value-pairs">
          <tr>
            <th>{{ $t('menus.foodItem.name') }}</th>
            <td data-test-food-item-name>{{ foodItem.name }}</td>
          </tr>
          <tr v-if="foodItem.englishTranslation">
            <th>{{ $t('menus.foodItem.englishName') }}</th>
            <td>{{ foodItem.englishTranslation }}</td>
          </tr>
          <tr>
            <th scope="row">{{ $t('menus.foodItem.mapping') }}</th>
            <transition
              name="slide-up"
              mode="out-in"
            >
              <td
                :key="`new-mapping-${foodItem.changeIndex}`"
                data-test-food-item-taxonomy-mapping
              >
                {{ editing.taxonomy.mapping || '-' }}
                <input
                  type="hidden"
                  :value="editing.taxonomy"
                />
              </td>
            </transition>
          </tr>
          <tr>
            <th
              scope="row"
              class="py-4"
            >
              {{ $t('menus.foodItems.legend.title') }}
            </th>
            <td data-test-food-item-status><food-item-state :status="foodItem.status" /></td>
          </tr>

          <tr>
            <td colspan="2">
              <div class="buttonGroup">
                <button
                  v-if="!foodItem.changeIndex"
                  v-test="'cancel'"
                  type="reset"
                  class="button button--secondary"
                  @click="cancelAndReturn"
                >
                  <SvgIcon name="24-ic-clear" />
                  <span>{{ $t('menus.foodItems.actions.returnToMenu') }}</span>
                </button>
                <button
                  v-else-if="!readonly"
                  type="reset"
                  class="button button--secondary"
                  data-test-cancel
                  @click="cancelAndReturn"
                >
                  <SvgIcon name="24-ic-clear" />
                  <span>{{ $t('menus.foodItems.actions.cancelAndReturn') }}</span>
                </button>
                <button
                  v-if="foodItem.allowSave && !readonly"
                  type="submit"
                  class="button button--primary"
                  data-test-save
                >
                  <SvgIcon name="24-ic-check" />
                  <span>{{ $t('actions.update') }}</span>
                </button>
                <button
                  v-if="foodItem.allowApprove && !readonly"
                  data-test-approve
                  type="button"
                  class="button button--primary"
                  @click="updateAndApprove"
                >
                  <SvgIcon name="24-ic-check-all" />
                  <span>{{ $t('menus.foodItems.actions.updateAndApprove') }}</span>
                </button>
              </div>
            </td>
          </tr>
        </table>
      </form>
    </page-section>

    <template v-if="!readonly">
      <TabsLvl1Links class="mx-4">
        <li>
          <router-link
            :to="{ name: 'food-mapping-similar', query: $route.query }"
            class="tab-item"
            data-test-link-similar
          >
            <span>{{ $t('menus.foodItem.mapping.tabs.similar') }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'food-mapping-taxonomy', query: $route.query }"
            class="tab-item"
            data-test-link-taxonomy
          >
            <span>{{ $t('menus.foodItem.mapping.tabs.manual') }}</span>
          </router-link>
        </li>
      </TabsLvl1Links>
      <page-section>
        <div
          id="code-builder"
          class="box-content"
        >
          <router-view
            @new-mapping="onChange"
            @reset-code="resetToOriginal"
          />
        </div>
      </page-section>
    </template>
  </div>
</template>

<script>
import FoodItemState from '@/components/FoodItemState.vue'
import TabsLvl1Links from '@/components/Tabs/TabsLvl1Links.vue'
import { FOOD_ITEM_STATES } from '@/store/constants'
import { useToast } from 'vue-toastification'
import returnPathMixin from '@/mixins/returnPathMixin'
import menuMixin from '../Menus/menuMixin'
export default {
  components: {
    FoodItemState,
    TabsLvl1Links,
  },
  mixins: [returnPathMixin, menuMixin],
  beforeRouteLeave(to, from, next) {
    this.cleanup()
    next()
  },
  props: {
    foodItemId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  computed: {
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
    menuId() {
      return this.$route.query.menuId
    },
    readonly() {
      return this.menu && this.menu.archived
    },
    editing() {
      return this.foodItem && this.foodItem.editing
    },
  },
  created() {
    // show actions that could be available at page load (like approving for PENDING_APPROVAL items)
    if (this.foodItem.status !== FOOD_ITEM_STATES.APPROVE) {
      this.showAvailableActions({
        term: !!(this.foodItem.taxonomy && this.foodItem.taxonomy.code),
        hasErrors: this.foodItem.status === FOOD_ITEM_STATES.FAILED,
        status: this.foodItem.status,
      })
    }
  },
  methods: {
    setAllowSaveFlag(state) {
      this.$store.commit('menu-management/food-items/STORE_FOOD_ITEM_DETAILS', {
        id: this.foodItemId,
        allowSave: state,
      })
    },
    setAllowApproveFlag(state) {
      this.$store.commit('menu-management/food-items/STORE_FOOD_ITEM_DETAILS', {
        id: this.foodItemId,
        allowApprove: state,
      })
    },
    onChange(taxonomyEventData) {
      this.$store.commit('menu-management/food-items/STORE_FOOD_ITEM_DETAILS', {
        id: this.foodItemId,
        editing: {
          taxonomy: { mapping: taxonomyEventData.mapping, code: taxonomyEventData.code },
        },
        changeIndex: this.foodItem.changeIndex + 1,
      })
      // implement save/approval scenarios table
      this.showAvailableActions({
        term: taxonomyEventData.term,
        hasErrors: taxonomyEventData.hasErrors,
        status: this.foodItem.status,
      })
    },
    resetToOriginal() {
      // this is intended to revert the mapping to the original code of the food item
      this.$store.dispatch('code-builder/resetState')
      this.$store.dispatch('menu-management/food-items/updateFoodItem', {
        id: this.foodItemId,
        editing: { taxonomy: { ...this.foodItem.taxonomy } },
      })
    },
    cleanup() {
      this.$store.dispatch('code-builder/resetState')
    },
    onSubmit() {
      this.save()
    },
    save(customParams = {}) {
      this.$store
        .dispatch('menu-management/food-items/saveItem', {
          menuId: this.$route.query.menuId,
          itemId: this.foodItem.id,
          data: {
            ...customParams,
            taxonomy: this.editing.taxonomy,
          },
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.goBack({
            hash: this.foodItem.id,
          })
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    updateAndApprove() {
      this.save({ status: FOOD_ITEM_STATES.APPROVED })
    },
    cancelAndReturn() {
      this.goBack({
        hash: this.foodItem.id,
        backupRoute: { name: 'food-items', params: { menuId: this.$route.query.menuId } },
      })
    },
    showAvailableActions({ term, hasErrors, status }) {
      // created this "table" to match the acceptance criteria on
      // https://winnow.atlassian.net/wiki/spaces/PROD/pages/1166148087/MILESTONE+Menu+mapping
      // see "Saving a manual mapping truth table" at the bottom

      // no actions permited until the food item has a base term selected
      if (!term) {
        this.setAllowSaveFlag(false)
        this.setAllowApproveFlag(false)
      }
      // cases in the truth table
      else if (status === FOOD_ITEM_STATES.FAILED && !hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(true)
      } else if (status === FOOD_ITEM_STATES.FAILED && hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(false)
      } else if (status === FOOD_ITEM_STATES.PENDING && !hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(true)
      } else if (status === FOOD_ITEM_STATES.PENDING && hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(false)
      } else if (status === FOOD_ITEM_STATES.APPROVED && !hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(true)
      } else if (status === FOOD_ITEM_STATES.APPROVED && hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(false)
      }
      // cases not in the truth table but make sense
      else if (status === FOOD_ITEM_STATES.UNMAPPED && !hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(true)
      } else if (status === FOOD_ITEM_STATES.UNMAPPED && hasErrors) {
        this.setAllowSaveFlag(true)
        this.setAllowApproveFlag(false)
      }
    },
  },
}
</script>
