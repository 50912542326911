<template>
  <div>
    <i18n-t
      keypath="messages.newEnterpriseInsights"
      tag="p"
      scope="global"
    >
      <template #newEnterpriseInsightsLink>
        <a
          :href="insightsUrl"
          target="_self"
        >
          {{ $t('messages.newEnterpriseInsightsLink') }}
        </a>
      </template>
    </i18n-t>
  </div>
</template>
<script setup>
const insightsUrl = import.meta.env.VUE_APP_INSIGHTS_DEMO_URL
</script>
