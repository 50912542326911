<template>
  <div>
    <h3 class="UI-title mt-6">Icons</h3>
    <div class="box UI">
      <h2 class="formSection-title"><code class="boss">16px</code> <code>SvgIcon sm</code></h2>

      <div class="icongrid">
        <template
          v-for="icon in icons"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <SvgIcon
              :name="icon"
              sm
            />
          </div>
        </template>
      </div>

      <p class="mt-4 mb-2"><code>.button-icon</code></p>

      <div class="icongrid">
        <template
          v-for="icon in icons"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <button class="button button--primary button--icon button--round">
              <SvgIcon
                :name="icon"
                sm
              />
            </button>
          </div>
        </template>
      </div>

      <h2 class="formSection-title mt-7"><code class="boss">24px</code> <code>default</code></h2>

      <div class="icongrid icongrid--lg">
        <template
          v-for="icon in icons"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <SvgIcon :name="icon" />
          </div>
        </template>
      </div>

      <p class="mt-4 mb-2"><code>.button-icon</code></p>

      <div class="icongrid icongrid--lg">
        <template
          v-for="icon in icons"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <button class="button button--primary button--icon button--round">
              <SvgIcon :name="icon" />
            </button>
          </div>
        </template>
      </div>

      <h2 class="formSection-title mt-7"><code class="boss">32px</code> <code>SvgIcon lg</code></h2>

      <div class="icongrid icongrid--lg">
        <template
          v-for="icon in icons"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <SvgIcon
              :name="icon"
              lg
            />
          </div>
        </template>
      </div>
    </div>

    <h3 class="UI-title mt-6">Illustrations</h3>
    <div class="box UI">
      <h2 class="formSection-title mt-7"><code class="boss">32px</code> <code>SvgIcon lg</code></h2>

      <div class="icongrid icongrid--lg">
        <template
          v-for="icon in stash.illustrations"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <SvgIcon
              :name="icon"
              lg
            />
          </div>
        </template>
      </div>
    </div>

    <h3 class="UI-title mt-6">Flags</h3>
    <div class="box UI">
      <h2 class="formSection-title mt-7">
        <code class="boss">30px x 24px</code> <code>.flag</code>
      </h2>

      <div class="icongrid icongrid--lg">
        <template
          v-for="icon in stash.flags"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <SvgIcon
              class="flag"
              :name="icon"
            />
          </div>
        </template>
      </div>

      <p class="mt-4 mb-2"><code>.flagContainer (24px x 24px)</code></p>

      <div class="icongrid icongrid--lg">
        <template
          v-for="icon in stash.flags"
          :key="icon"
        >
          <div
            v-tooltip="{ content: icon, classes: 'tooltip-ui' }"
            class="icongrid-unit"
          >
            <span class="flagContainer">
              <SvgIcon
                class="flag flag--lg"
                :name="icon"
              />
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import svgs from '@/injectSVG'

let stash = {}

svgs.forEach((svg) => {
  const tokens = svg.split('/')

  let group
  let filename
  if (tokens.length === 2) {
    group = 'root'
    filename = tokens[1]
  } else {
    group = tokens[1]
    filename = tokens[2]
  }
  const name = filename.slice(0, -4)

  if (!stash[group]) {
    stash[group] = []
  }
  stash[group].push(name)
})

const icons_16 = stash[16].map((icon) => `16-${icon}`)
const icons_24 = stash[24].map((icon) => `24-${icon}`)
const icons_48 = stash[48].map((icon) => `48-${icon}`)
const oldIcons = stash.icons

const allIcons = [...icons_16, ...icons_24, ...icons_48, ...oldIcons]

export default {
  name: 'UIIcons',
  data() {
    return {
      stash,
      icons: allIcons,
    }
  },
}
</script>
