<template>
  <div class="validationSummary text-right">
    <p
      v-if="showTitle && (hasAnyErrors || hasWarnings)"
      class="text-sm text-acai"
    >
      {{ $t('validation.summary.review') }}
    </p>
    <p>
      <span
        v-if="hasAnyErrors"
        class="text-apple"
      >
        {{ $t('validation.summary.errors', { count: allErrors.length }) }}
      </span>
      <span
        v-if="hasWarnings"
        class="text-carrot"
      >
        {{ $t('validation.summary.warnings', { count: allServerWarnings.length }) }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ValidationSummary',
  props: {
    validator: {
      type: Object,
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    serverErrors: {
      type: Object,
      default: () => ({}),
    },
    serverWarnings: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    activeErrors() {
      if (!this.validator) return []
      return (this.validator.$errors || []).map((error, index) => {
        return {
          ...error,
          id: `${index} - ${error.$uid}`,
          key: error.$validator,
        }
      })
    },
    hasErrors() {
      return !!this.activeErrors.length
    },
    activeErrorMessages() {
      return this.activeErrors
    },
    allServerErrors() {
      return Object.keys(this.serverErrors).reduce(
        (bucket, key) => bucket.concat(this.serverErrors[key]),
        []
      )
    },
    allServerWarnings() {
      return Object.keys(this.serverWarnings).reduce(
        (bucket, key) => bucket.concat(this.serverWarnings[key]),
        []
      )
    },
    allErrors() {
      return this.activeErrorMessages.concat(this.allServerErrors)
    },
    hasServerErrors() {
      return this.allServerErrors.length
    },
    hasAnyErrors() {
      return this.hasErrors || this.hasServerErrors
    },
    hasWarnings() {
      return this.allServerWarnings.length
    },
  },
}
</script>
