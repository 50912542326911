import { useRouter, useRoute } from 'vue-router'
import { useStorage } from '@vueuse/core'
import { ref } from 'vue'

// use the same values in every instance
const userSettings = useStorage('user-settings', {})
const selectedSite = ref(null)

export function useSelectedSite({
  siteParamName = 'siteId',
  userId = null,
  allowedSites = ref([]),
} = {}) {
  if (!userId) {
    throw 'userId is required'
  }
  const router = useRouter()
  const route = useRoute()

  // keep current selected value or use the one from local storage if null
  selectedSite.value = selectedSite.value || userSettings.value[userId]?.selectedSite

  /**
   * Checks if a site legacy id is available to use as selected site by looking for it in the list of available sites
   * @param {*} legacyId
   * @returns {boolean}
   */
  const isSiteLegacyIdValid = (legacyId) => {
    return !!allowedSites.value.find((allowed) => legacyId === allowed.legacyId)
  }

  /**
   * Save a new selected site
   * @param {object} site
   */
  const changeSelectedSite = async (site) => {
    if (isSiteLegacyIdValid(site.legacyId)) {
      if (site.legacyId !== route.params[siteParamName]) {
        await router.push({
          params: {
            ...route.params,
            [siteParamName]: site.legacyId,
          },
        })
      }
      userSettings.value[userId] = { selectedSite: site }
      selectedSite.value = userSettings.value[userId].selectedSite
    }
  }
  /**
   * Sets the selected site temporarily without saving it to local storage
   * @param {string} sessionSelectedSiteLegacyId
   */
  const setSelectedSite = (sessionSelectedSiteLegacyId) => {
    selectedSite.value = allowedSites.value.find(
      ({ legacyId }) => legacyId === sessionSelectedSiteLegacyId
    )
  }
  /**
   * This sets up the selected site for the "first time".
   * Only needs to be called in contexts where the selected site CAN BE CHANGED.
   *
   * It checks for selected site stored in the local storage vs a legacyId stored in the url
   * The url will take priority and set up the selected site by finding it in the list of allowed sites.
   * But importantly, it will not save this new site in local storage.
   * Only sites changed manually, via `changeSelectedSite` get saved.
   *
   * Also, if the user only has access to a single site,
   * that site will be set as the selected site and will be saved to local storage.
   *
   * For now, only the <SiteSelector> component uses it and it will most likely not be needed by anything else.
   */

  const init = async () => {
    // This is the legacyId present in the url at the time of loading. it could be a shared url so the legacy id could be different to the user's selected site
    let accessedSiteLegacyId = route.params[siteParamName]
    // This is the legacyId of the currently set selected site
    const selectedSiteLegacyId = selectedSite.value?.legacyId
    // This is the legacy id we're going to use for this session
    const sessionSelectedSiteLegacyId = accessedSiteLegacyId || selectedSiteLegacyId
    // If there is a legacy id in the url that is not the same as the selected site, use it for the current browser session
    if (accessedSiteLegacyId && accessedSiteLegacyId !== selectedSiteLegacyId) {
      setSelectedSite(accessedSiteLegacyId)
    }
    // If the legacy id was not present in the url, we're adding it for navigation consistency
    if (!accessedSiteLegacyId && selectedSiteLegacyId) {
      accessedSiteLegacyId = selectedSiteLegacyId
      await router.replace({ ...route, params: { [siteParamName]: selectedSiteLegacyId } })
    }
    // If the list of sites is only 1 element long, we save that as the selected site as well
    else if (!sessionSelectedSiteLegacyId && allowedSites.value.length === 1) {
      changeSelectedSite(allowedSites.value[0])
    }
    // Finally, check if the currently saved selected site is still valid
    if (isSiteLegacyIdValid(userSettings.value[userId]?.selectedSite?.legacyId)) {
      // Update it in in case any details have changed since last saved
      userSettings.value[userId].selectedSite = allowedSites.value.find(
        ({ legacyId }) => legacyId === userSettings.value[userId].selectedSite.legacyId
      )
      // update selected site as well if the user was viewing it
      if (accessedSiteLegacyId === userSettings.value[userId].selectedSite.legacyId) {
        selectedSite.value = userSettings.value[userId].selectedSite
      }
    } else if (userSettings.value[userId]?.selectedSite) {
      // Remove it if user no longer can access it
      userSettings.value[userId].selectedSite = null
      // update selected site as well if the user was viewing it
      if (accessedSiteLegacyId === selectedSiteLegacyId) {
        selectedSite.value = null
      }
    }
  }

  return {
    initSelectedSite: init,
    selectedSite,
    changeSelectedSite,
  }
}
