<template>
  <div class="formGroup--fileUpload">
    <input
      :id="id"
      ref="file"
      v-test="'file'"
      class="formControl--fileInput"
      type="file"
      :name="id"
      :accept="accept"
      :multiple="multiple"
      @change="filesAdded"
    />
    <label
      class="button button--secondary"
      :for="id"
    >
      <SvgIcon name="24-ic-table" />
      <span>
        <slot>{{ $t('actions.upload') }}</slot>
      </span>
    </label>
    <ul class="mx-2 my-1">
      <li
        v-for="file in files"
        :key="file.name"
        class="text-sm flex items-center"
      >
        <SvgIcon
          class="mr-1 inline"
          name="24-ic-table"
        />
        <span>{{ file.name }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    accept: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    modelValue: File,
  },
  emits: ['files-added', 'file-added', 'update:modelValue'],
  data() {
    return {
      files: [],
    }
  },
  methods: {
    filesAdded(ev) {
      let files = [...ev.target.files]
      this.files = files.map((file) => {
        return {
          name: file.name,
        }
      })
      if (this.multiple) {
        this.$emit('files-added', files)
        this.$emit('update:modelValue', files)
      } else {
        this.$emit('file-added', files[0])
        this.$emit('update:modelValue', files[0])
      }
    },
    clear() {
      this.files = []
      this.$refs['file'].value = ''
      this.$emit('files-added', [])
      this.$emit('file-added', '')
    },
  },
}
</script>
