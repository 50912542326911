<template>
  <div class="carousel">
    <template
      v-for="(item, index) in slicedData"
      :key="index"
    >
      <div
        v-if="index === halfwayPoint"
        class="carousel-item carousel-current"
        :class="{
          'carousel-current-uncategorised': !isCategorised(item),
        }"
      >
        <transaction-image
          :width="400"
          :height="400"
          :item="item"
          :allow-full-screen="true"
          @image-loaded="imageLoaded"
        >
          <template
            v-if="item.type !== 'other'"
            #placeholder
          >
            <SvgIcon
              class="icon--illustration text-grey-400"
              name="no-vision"
            />
            <p class="carousel-placeholder-text">
              {{
                $t('wasteTimeline.messages.noImage', {
                  type: $t('waste.' + item.type).toLowerCase(),
                })
              }}
            </p>
          </template>
          <template
            v-else-if="item.type === 'other' && !item.imageId"
            #placeholder
          >
            <SvgIcon
              class="icon--illustration text-grey-400"
              name="no-image"
            />
            <p class="carousel-placeholder-text">
              {{ $t('wasteTimeline.messages.unavailableImage') }}
            </p>
          </template>
        </transaction-image>
      </div>

      <div
        v-else
        v-show="index >= hiddenCount && index < slicedData.length - hiddenCount"
        class="carousel-item"
        :class="{ 'carousel-before': index < halfwayPoint, 'carousel-after': index > halfwayPoint }"
      >
        <button
          v-if="item"
          @click="goTo(item.id)"
        >
          <transaction-image
            :width="400"
            :height="400"
            :item="item"
            :allow-full-screen="false"
            :allow-empty="true"
            @image-loaded="imageLoaded"
          >
            <template
              v-if="item.type !== 'other'"
              #placeholder
            >
              <SvgIcon
                class="icon--illustration text-grey-400"
                name="no-vision"
              />
              <p class="carousel-placeholder-text">
                {{
                  $t('wasteTimeline.messages.noImage', {
                    type: $t('waste.' + item.type).toLowerCase(),
                  })
                }}
              </p>
            </template>
            <template
              v-else-if="item.type === 'other' && !item.imageId"
              #placeholder
            >
              <SvgIcon
                class="icon--illustration text-grey-400"
                name="no-image"
              />
              <p class="carousel-placeholder-text">
                {{ $t('wasteTimeline.messages.unavailableImage') }}
              </p>
            </template>
          </transaction-image>
          <template v-if="index < halfwayPoint">
            <div class="carousel-item-overlay">
              <SvgIcon
                class="icon-prev"
                name="24-ic-arrow-prev"
                xl
              />
            </div>
          </template>
          <template v-else-if="index > halfwayPoint">
            <div class="carousel-item-overlay">
              <SvgIcon
                class="icon-next"
                name="24-ic-arrow-next"
                xl
              />
            </div>
          </template>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import TransactionImage from '@/components/TransactionImage.vue'

const findIndex = (items, id) => {
  const result = items.findIndex((el) => el.id === id)
  return result === -1 ? 0 : result
}

export default {
  components: {
    TransactionImage,
  },
  props: {
    items: Array,
    selectedId: [String, Number],
  },
  emits: ['change', 'image-loaded'],
  data() {
    return {
      /**
       * Slice and visible
       *
       * This is a hacky way of preloading and caching images.
       * `slice` is the total number of items loaded at one time.
       * `visible` is the number of items that we want displayed.
       * Everything that falls outside of this range is hidden but still loaded
       */
      slice: 7,
      visible: 3,
      selected: null,
    }
  },
  computed: {
    index() {
      return findIndex(this.items, this.selected)
    },
    halfwayPoint() {
      return Math.ceil(this.slice / 2) - 1
    },
    // The number of hidden (but loaded) elements on each side of the carousel
    hiddenCount() {
      return (this.slice - this.visible) / 2
    },
    slicedData() {
      let sliceFromIndex = this.index - this.halfwayPoint
      let sliceToIndex = sliceFromIndex + this.slice
      let emptyBefore = []
      let emptyAfter = []
      if (sliceFromIndex < 0) {
        emptyBefore = Array(-sliceFromIndex).fill(null)
        sliceFromIndex = 0
      }
      if (sliceToIndex > this.items.length) {
        emptyAfter = Array(sliceToIndex - this.items.length).fill(null)
        sliceToIndex = this.items.length
      }
      let slice = [].concat(emptyBefore, this.items.slice(sliceFromIndex, sliceToIndex), emptyAfter)
      return slice
    },
  },
  watch: {
    selectedId: {
      handler(newId) {
        this.selected = newId
      },
      immediate: true,
    },
  },

  methods: {
    goTo(id) {
      this.selected = id
      this.$emit('change', id)
    },
    imageLoaded({ id, src }) {
      this.$emit('image-loaded', { id, src })
    },
    isCategorised(item) {
      if (item) {
        return item.stage && item.foodInstance
      } else {
        return true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: theme('spacing.2');

  button {
    display: block;
    margin: 0 auto;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
    background: none;
    overflow: hidden;
  }

  img {
    object-fit: cover;
  }

  &:hover .carousel-item-overlay {
    opacity: 1;
  }
}

.carousel-item {
  flex-basis: 33.333333%;
  height: to-rem(130px);
  flex-grow: 0;
  flex-shrink: 1;
  border-radius: 0.25rem;
  overflow: hidden;

  .icon {
    margin: theme('spacing.4');
  }

  .icon--illustration {
    max-width: 50%;
    margin: 0 auto;
  }
}

.carousel-current {
  flex-basis: to-rem(150px);
  box-shadow: 0 0 0 0.5rem theme('colors.lemon.DEFAULT');
  z-index: 2;
  width: to-rem(150px);
  height: to-rem(150px);
  flex-shrink: 0;
}

.carousel-current-uncategorised {
  box-shadow: 0 0 0 0.5rem theme('colors.apple.DEFAULT');
}

.carousel-before,
.carousel-after {
  overflow: hidden;
  position: relative;
}

.carousel-before {
  .carousel-item-overlay {
    justify-content: flex-end;
  }

  :hover > .icon-prev {
    transform: translateX(-0.5rem);
  }
}

.carousel-after {
  .carousel-item-overlay {
    justify-content: flex-start;
  }

  :hover .icon-next {
    transform: translateX(0.5rem);
  }
}

.carousel-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0, 0.35);
  color: theme('colors.white');
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.carousel-placeholder-text {
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  color: theme('colors.grey.400');
}

@include respond-min(small) {
  .carousel-item {
    height: to-rem(280px);
  }

  .carousel-current {
    height: to-rem(300px);
    width: to-rem(300px);
    flex-basis: to-rem(300px);
  }
}

@include respond-min(medium) {
  .carousel-item {
    height: to-rem(400px);
  }

  .carousel-current {
    height: to-rem(410px);
    width: to-rem(410px);
    flex-basis: to-rem(410px);
  }
}

@include respond-min('large') {
  .carousel-placeholder-text {
    width: auto;
    max-width: 25ch;
    height: auto;
    opacity: 1;
    position: static;
  }
}
</style>
