<template>
  <collapsible-section
    data-test-cross-reports-section
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.subscribers.crossSiteReports.title') }}
    </template>

    <template #header-side="{ isOpen }">
      <div
        v-if="isOpen"
        class="flex flex-right flex-expand"
      >
        {{ $t('organisations.subscribers.crossSiteReports.subTitle') }}
      </div>
      <template v-else>
        <Loading-Spinner
          v-if="loadingStatus === 'LOADING'"
          class="ml-2"
        />
        <inline-chips
          v-else
          data-test-cross-reports-inline-list
          class="flex-1"
          :items="inLineDisplayList"
        />
      </template>
    </template>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <subscribers-handler
        :location-id="locationId"
        :namespace="namespace"
      >
        <template #title>
          <h4>
            {{ $t('organisations.subscribers.crossSiteReports.label') }}
          </h4>
        </template>
      </subscribers-handler>
    </loaded-content>
  </collapsible-section>
</template>
<script>
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import SubscribersHandler from './SubscribersHandler.vue'
import InlineChips from './InlineChips.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    SubscribersHandler,
    InlineChips,
  },
  props: {
    locationId: String,
    preOpen: Boolean,
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      isSectionOpen: false,
      loadingStatus: 'IDLE',
    }
  },

  computed: {
    namespace() {
      return 'crossSiteReportsSubscribers'
    },
    inLineDisplayList() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId)[this.namespace].list.map(
        (subscriber) => subscriber.email
      )
    },
  },

  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeCrossSiteReportsSubscribers', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },
}
</script>
