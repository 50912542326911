<template>
  <li
    :id="id"
    class="ordered-grid-item ordered-grid-item--with-action"
  >
    <div
      class="ordered-grid-item-image-container"
      data-test-ordered-grid-image
      @click.stop="$router.push(link)"
    >
      <image-loader
        :src="generateThumbnailUrl(imageUrl)"
        :aspect-ratio="0.65"
      />
    </div>
    <slot>
      <router-link
        class="h3 font-bold ordered-grid-item-title"
        :to="link"
        data-test-ordered-grid-title
        @click="$event.stopImmediatePropagation()"
      >
        <SvgIcon
          v-if="hasWarning"
          v-tooltip="{
            theme: 'tooltip-neutral',
            content: warningMessage,
            placement: 'top',
          }"
          class="text-apple inline-block"
          name="24-ic-info"
        />
        {{ title }}
      </router-link>
    </slot>
    <div
      v-if="hidden"
      class="text-carrot"
    >
      {{ $t('menus.tabletEditor.foodItems.messages.hidden') }}
    </div>
    <div
      v-if="reorderingActive"
      class="buttonGroup flex flex-start mt-4"
    >
      <button
        class="button button--secondary button--iconOutline"
        type="button"
        :disabled="index === 0"
        data-test-move-left
        @click.stop="move(index - 1)"
      >
        <SvgIcon name="24-ic-arrow-prev" />
        <span class="sr-only">{{ $t('actions.moveBackward') }}</span>
      </button>
      <button
        class="button button--secondary button--iconOutline"
        type="button"
        :disabled="index === length - 1"
        data-test-move-right
        @click.stop="move(index + 1)"
      >
        <SvgIcon name="24-ic-arrow-next" />
        <span class="sr-only">{{ $t('actions.moveForward') }}</span>
      </button>
    </div>
  </li>
</template>

<script>
import ImageLoader from '@/components/image-loader.vue'
import imagesMixin from '@/mixins/images'
export default {
  components: {
    ImageLoader,
  },
  mixins: [imagesMixin],
  props: {
    id: String,
    imageUrl: String,
    title: String,
    link: Object,
    index: Number,
    length: Number,
    hasWarning: Boolean,
    warningMessage: String,
    reorderingActive: Boolean,
    hidden: Boolean,
  },
  emits: ['move'],
  methods: {
    move(newIndex) {
      if (newIndex > this.length - 1) {
        newIndex = this.length - 1
      } else if (newIndex < 0) {
        newIndex = 0
      }
      if (this.index !== newIndex) {
        this.$emit('move', { oldIndex: this.index, newIndex })
      }
    },
  },
}
</script>

<style></style>
