// taken from modules/organisations but modified because it depended on state.orgName for some reason
// @TODO: investigate and used this one everywhere
import Organisation from '../models/organisation'
export const parseOrganisationModel = (item) => {
  return new Organisation({
    ...item,
    ...expandOrganisation(item.qualifiedName),
    'level:nodeName': `${item.level}: ${item.name}`,
  })
}

const separator = ' / '

export const pickLevelOrgName = ({ orgFullName, level }) => {
  const parts = orgFullName.split(separator)
  return parts[level - 1]
}

export const expandOrganisation = (orgName) => {
  const parts = orgName.split(separator)
  const relevantParts = parts.slice(-3)
  return {
    orgName: parts[0], // in the case of orphaned sites this may cause duplication
    parsedName: relevantParts.join(separator),
    fullName: orgName,
    shortName:
      parts.length > 4
        ? `${parts[0]} / ... / ${relevantParts.join(separator)}`
        : parts.join(separator),
  }
}
