<template>
  <form
    action="/"
    class="form-layout--x3"
    @submit.prevent="submit"
  >
    <ValidationGroup
      v-slot="{ hasAnyErrors }"
      :validator="v$.buffer.email"
    >
      <div class="flex flex-between flex-middle">
        <label for="reporing-sending-control-email-field">{{
          $t('organisations.sendingControls.emails.actions.add')
        }}</label>
        <SvgIcon
          v-tooltip="{
            theme: 'tooltip-white',
            content: $t('organisations.sendingControls.emails.messages.multiple'),
            placement: 'top',
          }"
          class="text-slate ml-2"
          name="24-ic-info"
        ></SvgIcon>
      </div>
      <div class="sendingControlsEmailFieldWrapper">
        <add-sending-control-field
          id="reporing-sending-control-email-field"
          ref="addEmailField"
          v-model="buffer.email"
          data-test-sending-controls-email-filed
          :placeholder="$t('organisations.sendingControls.emails.placeholders.add')"
          type="text"
          :class="[
            'formControl',
            { 'is-invalid': hasAnyErrors, 'is-warning': isEmailCoveredByADomain },
          ]"
          @comma-press="submit"
        />
        <button
          :disabled="!buffer.email"
          type="submit"
          class="button button--primary button--inputAction"
        >
          <SvgIcon name="24-ic-check" />
        </button>
      </div>
      <div
        v-if="isEmailCoveredByADomain"
        class="formFeedback is-warning"
      >
        {{ $t('organisations.sendingControls.emails.redundantEmail') }}
      </div>
    </ValidationGroup>
  </form>
</template>
<script>
import { required, email } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import ValidationGroup from '@/components/Validation/ValidationGroup.vue'

import AddSendingControlField from './AddSendingControlField.vue'

export default {
  components: {
    ValidationGroup,
    AddSendingControlField,
  },
  props: {
    locationEmails: {
      type: Array,
      default: () => [],
    },
    coveredDomains: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submit-email'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      buffer: {
        email: '',
      },
    }
  },
  computed: {
    isEmailCoveredByADomain() {
      const emailDomain = this.buffer.email.split('@')[1]

      return !!this.coveredDomains.find((domain) => domain.name === emailDomain)
    },
  },
  mounted() {
    this.$refs.addEmailField.$el.focus()
  },
  validations() {
    return {
      buffer: {
        email: {
          required,
          email,
          duplicatedEmailForNode: (val) =>
            !this.locationEmails.find((locationEmail) => locationEmail === val),
        },
      },
    }
  },
  methods: {
    submit() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.$emit('submit-email', this.buffer.email)

      this.v$.$reset()
      this.buffer.email = ''
    },
  },
}
</script>
<style lang="scss">
.sendingControlsEmailFieldWrapper {
  position: relative;
}
</style>
