<template>
  <collapsible-section
    data-test-summary-reports-section
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.subscribers.summaryReports.title') }}
    </template>

    <template #header-side="{ isOpen }">
      <div
        v-if="isOpen"
        class="flex flex-right flex-expand"
      >
        {{ $t('organisations.subscribers.summaryReports.subTitle') }}
      </div>
      <template v-else>
        <Loading-Spinner
          v-if="loadingStatus === 'LOADING'"
          class="ml-2"
        />
        <template v-else-if="isSendingEnabled">
          <filter-chip v-if="storeConfigs.sendWeekly">
            {{ $t('organisations.configurations.summaryReports.weeklyLabel') }}
          </filter-chip>
          <filter-chip v-if="storeConfigs.sendMonthly">
            {{ $t('organisations.configurations.summaryReports.monthlyLabel') }}
          </filter-chip>
          <filter-chip v-if="storeConfigs.sendQuarterly">
            {{ $t('organisations.configurations.summaryReports.quarterlyLabel') }}
          </filter-chip>
        </template>
        <filter-chip
          v-else
          disabled
          >{{ $t('organisations.configurations.dailyReports.sendingInactiveStatus') }}</filter-chip
        >
      </template>
    </template>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <form
        action="/"
        @submit.prevent="saveChanges"
      >
        <div class="grid-layout grid--half">
          <div class="grid-unit">
            <!-- Report types -->
            <div class="formGroup configSendingWrapper grid grid--half m-0">
              <div
                v-for="{ key, labelKey, labelSubtitleKey } in reportTypes"
                :key="key"
                class="grid-unit"
              >
                <div class="customControl customControl--checkbox">
                  <input
                    v-bind="{ [`data-test-config-field-summary-${key}`]: '' }"
                    :id="`config-field-summary-${key}`"
                    v-model="buffer[key]"
                    type="checkbox"
                    class="customControl-input"
                  />
                  <label
                    class="customControl-label"
                    :for="`config-field-summary-${key}`"
                  >
                    <span class="d-block font-bold">
                      {{ $t(labelKey) }}
                    </span>
                    <span class="text-sm">
                      {{ $t(labelSubtitleKey) }}
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <!-- Focus -->
            <fieldset class="formGroup flex gap-4 flex-wrap">
              <legend class="label font-bold flex flex-middle w-full">
                {{ $t('organisations.configurations.focusLabel') }}
                <SvgIcon
                  v-tooltip="{
                    theme: 'tooltip-neutral',
                    content: $t('organisations.configurations.focusLabelDescription'),
                    placement: 'top',
                  }"
                  class="text-slate ml-2"
                  name="24-ic-info"
                ></SvgIcon>
              </legend>
              <div
                v-for="{ value, labelKey } in reportFocusOptions"
                :key="value"
                class="customControl customControl--radio"
              >
                <input
                  v-bind="{ [`data-test-config-field-summary-focus-${value}`]: '' }"
                  :id="`config-field-summary-focus-${value}`"
                  v-model="buffer.focus"
                  type="radio"
                  name="focus-summary"
                  class="customControl-input"
                  :value="value"
                />
                <label
                  :for="`config-field-summary-focus-${value}`"
                  class="customControl-label"
                >
                  {{ $t(labelKey) }}
                </label>
              </div>
            </fieldset>

            <!-- Comparison Level -->
            <fieldset class="formGroup flex gap-4 flex-wrap">
              <legend class="label font-bold flex flex-middle w-full">
                {{ $t('organisations.configurations.summaryReports.comparisonLvlLabel') }}
                <SvgIcon
                  v-tooltip="{
                    theme: 'tooltip-neutral',
                    content: $t(
                      'organisations.configurations.summaryReports.comparisonLvlDescription'
                    ),
                    placement: 'top',
                  }"
                  class="text-slate ml-2"
                  name="24-ic-info"
                >
                </SvgIcon>
              </legend>
              <div
                v-for="level in reportComparisonLevels"
                :key="level"
                class="customControl customControl--radio"
              >
                <input
                  v-bind="{ [`data-test-config-field-summary-comparison-${level}`]: '' }"
                  :id="`config-field-summary-comparison-${level}`"
                  v-model="buffer.comparisonLevel"
                  type="radio"
                  name="comparison-summary-level"
                  class="customControl-input"
                  :value="level"
                />
                <label
                  :for="`config-field-summary-comparison-${level}`"
                  class="customControl-label"
                >
                  {{ level }}
                </label>
              </div>
            </fieldset>

            <!-- Report Settings -->
            <div
              v-for="{ key, labelKey, labelSubtitleKey } in reportSettings"
              :key="key"
              class="formGroup customControl customControl--checkbox"
            >
              <input
                v-bind="{ [`data-test-config-field-summary-${key}`]: '' }"
                :id="`config-field-summary-${key}`"
                v-model="buffer[key]"
                type="checkbox"
                class="customControl-input"
              />
              <label
                class="customControl-label"
                :for="`config-field-summary-${key}`"
              >
                <span class="d-block font-bold">
                  {{ $t(labelKey) }}
                </span>
                <span class="text-sm">
                  {{ $t(labelSubtitleKey) }}
                </span>
              </label>
            </div>
          </div>

          <!-- Report Sections -->
          <div class="grid-unit">
            <fieldset>
              <legend class="mb-8 font-bold">
                {{ $t('organisations.configurations.reportSectionsLegend') }}
              </legend>

              <div>
                <visibility-toggle
                  v-for="{ key, labelKey, labelSubtitleKey } in reportSections"
                  :id="`config-field-summary-${key}`"
                  :key="key"
                  v-model="buffer[key]"
                  class="formGroup"
                >
                  <template #default>
                    <span class="flex-column">
                      <span :class="[buffer[key] && 'font-bold']">
                        {{ $t(labelKey) }}
                      </span>
                      <span class="text-sm">
                        {{ $t(labelSubtitleKey) }}
                      </span>
                    </span>
                  </template>
                </visibility-toggle>
              </div>
            </fieldset>
          </div>
        </div>

        <!-- Buttons Bar-->
        <div class="buttonGroup flex-between">
          <ButtonSave
            data-test-configs-submit-btn
            :disabled="!hasChanges || savingStatus === 'SAVING'"
            :is-saving="savingStatus === 'SAVING'"
          >
            {{ $t('actions.saveChanges') }}
          </ButtonSave>

          <router-link
            class="button button--link"
            :to="{
              name: 'hierarchy-node-reporting-subscribers',
              query: { ...$route.query, openItems: 'SUBSCRIBERS-SUMMARY' },
            }"
          >
            <SvgIcon name="24-ic-users" />
            <span>{{ $t('organisations.configurations.subscribersButton') }}</span>
          </router-link>
        </div>
      </form>
    </loaded-content>
  </collapsible-section>
</template>
<script>
import { isEqual } from 'lodash'

import CollapsibleSection from '@/components/CollapsibleSection.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import FilterChip from '@/components/FilterChip.vue'
import VisibilityToggle from '@/components/VisibilityToggle.vue'
import { useToast } from 'vue-toastification'
import ButtonSave from '@/components/ButtonSave.vue'

function Model(overwrites) {
  return {
    sendWeekly: false,
    sendMonthly: false,
    sendQuarterly: false,
    focus: null,
    comparisonLevel: 1,
    comparisonPoints: false,
    events: false,
    hideCosts: false,
    customBreakdown: false,
    annualisedSavings: false,
    vision: false,
    topAreas: false,
    wasteByDay: false,
    wasteByTimeOfDay: false,
    foodOverview: false,
    foodGroupBreakdown: false,
    topItems: false,
    otherProductionAreas: false,
    rework: false,
    staffFood: false,
    outOfStock: false,
    usage: false,
    benchmarking: false,
    savings: false,
    excludeCustom1: false,
    custom2: false,
    excludeCustom3: false,
    ...overwrites,
  }
}

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    VisibilityToggle,
    FilterChip,
    ButtonSave,
  },
  props: {
    locationId: String,
    preOpen: Boolean,
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
      buffer: {},
    }
  },
  computed: {
    storeConfigs() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId).summaryReportsConfigs
    },
    hasChanges() {
      return !isEqual(this.storeConfigs, this.buffer)
    },
    isSendingEnabled() {
      const { sendWeekly, sendMonthly, sendQuarterly } = this.storeConfigs

      return sendWeekly || sendMonthly || sendQuarterly
    },
    reportTypes() {
      return [
        {
          key: 'sendWeekly',
          labelKey: 'organisations.configurations.summaryReports.weeklyLabel',
          labelSubtitleKey: `organisations.configurations.summaryReports.weeklyLabelSubtitle.${
            this.buffer.sendWeekly ? 'enabled' : 'disabled'
          }`,
        },
        {
          key: 'sendMonthly',
          labelKey: 'organisations.configurations.summaryReports.monthlyLabel',
          labelSubtitleKey: `organisations.configurations.summaryReports.monthlyLabelSubtitle.${
            this.buffer.sendMonthly ? 'enabled' : 'disabled'
          }`,
        },
        {
          key: 'sendQuarterly',
          labelKey: 'organisations.configurations.summaryReports.quarterlyLabel',
          labelSubtitleKey: `organisations.configurations.summaryReports.quarterlyLabelSubtitle.${
            this.buffer.sendQuarterly ? 'enabled' : 'disabled'
          }`,
        },
      ]
    },
    reportFocusOptions() {
      return [
        {
          value: 'WEIGHT',
          labelKey: 'titles.weight',
        },
        {
          value: 'VALUE',
          labelKey: 'titles.value',
        },
        {
          value: 'MIXED',
          labelKey: 'titles.mixed',
        },
      ]
    },
    reportComparisonLevels() {
      return [1, 2, 3, 4, 5]
    },
    reportSettings() {
      return [
        {
          key: 'comparisonPoints',
          labelKey: 'organisations.configurations.comparisonPointsLabel',
          labelSubtitleKey: 'organisations.configurations.comparisonPointsLabelSubtitle',
        },
        {
          key: 'events',
          labelKey: 'organisations.configurations.eventsLabel',
          labelSubtitleKey: 'organisations.configurations.eventsLabelSubtitle',
        },
        {
          key: 'hideCosts',
          labelKey: 'organisations.configurations.hideCostsLabel',
          labelSubtitleKey: 'organisations.configurations.hideCostsLabelSubtitle',
        },
        {
          key: 'customBreakdown',
          labelKey: 'organisations.configurations.customBreakdownLabel',
          labelSubtitleKey: 'organisations.configurations.customBreakdownLabelSubtitle',
        },
        {
          key: 'annualisedSavings',
          labelKey: 'organisations.configurations.annualisedSavingsLabel',
          labelSubtitleKey: 'organisations.configurations.annualisedSavingsLabelSubtitle',
        },
        {
          key: 'excludeCustom3',
          labelKey: 'organisations.configurations.summaryReports.excludeCustom3Label',
          labelSubtitleKey:
            'organisations.configurations.summaryReports.excludeCustom3LabelSubtitle',
        },
        {
          key: 'excludeCustom1',
          labelKey: 'organisations.configurations.summaryReports.excludeCustom1Label',
          labelSubtitleKey:
            'organisations.configurations.summaryReports.excludeCustom1LabelSubtitle',
        },
      ]
    },

    reportSections() {
      return [
        {
          key: 'vision',
          labelKey: 'organisations.configurations.visionLabel',
          labelSubtitleKey: 'organisations.configurations.visionLabelSubtitle',
        },
        {
          key: 'topAreas',
          labelKey: 'organisations.configurations.topAreasLabel',
          labelSubtitleKey: 'organisations.configurations.topAreasLabelSubtitle',
        },
        {
          key: 'wasteByDay',
          labelKey: 'organisations.configurations.wasteByDayLabel',
          labelSubtitleKey: 'organisations.configurations.wasteByDayLabelSubtitle',
        },
        {
          key: 'wasteByTimeOfDay',
          labelKey: 'organisations.configurations.wasteByTimeOfDayLabel',
          labelSubtitleKey: 'organisations.configurations.wasteByTimeOfDayLabelSubtitle',
        },
        {
          key: 'foodOverview',
          labelKey: 'organisations.configurations.foodOverviewLabel',
          labelSubtitleKey: 'organisations.configurations.foodOverviewLabelSubtitle',
        },
        {
          key: 'foodGroupBreakdown',
          labelKey: 'organisations.configurations.foodGroupBreakdownLabel',
          labelSubtitleKey: 'organisations.configurations.foodGroupBreakdownLabelSubtitle',
        },
        {
          key: 'topItems',
          labelKey: 'organisations.configurations.topItemsLabel',
          labelSubtitleKey: 'organisations.configurations.topItemsLabelSubtitle',
        },
        {
          key: 'otherProductionAreas',
          labelKey: 'organisations.configurations.otherProductionAreasLabel',
          labelSubtitleKey: 'organisations.configurations.otherProductionAreasLabelSubtitle',
        },
        {
          key: 'rework',
          labelKey: 'waste.rework',
          labelSubtitleKey: 'organisations.configurations.reworkLabelSubtitle',
        },
        {
          key: 'staffFood',
          labelKey: 'waste.staff_food',
          labelSubtitleKey: 'organisations.configurations.staffFoodLabelSubtitle',
        },
        {
          key: 'outOfStock',
          labelKey: 'waste.stock_out',
          labelSubtitleKey: 'organisations.configurations.outOfStickLabelSubtitle',
        },
        {
          key: 'usage',
          labelKey: 'organisations.configurations.usageLabel',
          labelSubtitleKey: 'organisations.configurations.usageLabelSubtitle',
        },
        {
          key: 'benchmarking',
          labelKey: 'organisations.configurations.benchmarkingLabel',
          labelSubtitleKey: 'organisations.configurations.benchmarkingLabelSubtitle',
        },
        {
          key: 'savings',
          labelKey: 'organisations.configurations.savingsLabel',
          labelSubtitleKey: 'organisations.configurations.savingsLabelSubtitle',
        },
        {
          key: 'custom2',
          labelKey: 'organisations.configurations.summaryReports.custom2Label',
          labelSubtitleKey: 'organisations.configurations.summaryReports.custom2LabelSubtitle',
        },
      ]
    },
  },
  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeSummaryReportsConfigs', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.init()
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },
  methods: {
    init() {
      this.buffer = new Model(this.storeConfigs)
    },
    saveChanges() {
      this.savingStatus = 'SAVING'

      const changes = Object.entries(this.buffer).reduce(
        (acc, [key, value]) =>
          Object.assign(acc, this.storeConfigs[key] === this.buffer[key] ? {} : { [key]: value }),
        {}
      )

      this.$store
        .dispatch('hierarchy/nodes/saveNodeSummaryReportsConfigs', {
          nodeId: this.locationId,
          payload: changes,
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((e) => {
          this.savingStatus = 'ERROR'

          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
        .finally(() => {
          this.init()
        })
    },
  },
}
</script>
<style lang="scss">
.configSendingWrapper {
  border: solid 1px theme ('colors.slate.DEFAULT');
}
</style>
