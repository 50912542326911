<template>
  <LayoutDefault class="p-0">
    <template #siteNav>
      <SiteSelector />
    </template>
    <template #default>
      <NoSiteSelected v-if="isMultiSiteUser && !selectedSite" />
      <div
        v-if="selectedSite"
        class="pentaho-wrapper"
      >
        <iframe
          ref="dashboard"
          data-hj-allow-iframe
          class="pentaho-iframe"
          :src="iframeUrl"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          scrolling="no"
        ></iframe>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import { useStore, mapGetters } from 'vuex'
import { computed } from 'vue'
import queryString from 'query-string'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import SiteSelector from '@/components/SiteSelector/SiteSelector.vue'
import NoSiteSelected from '@/components/NoSiteSelected/NoSiteSelected.vue'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useToast } from 'vue-toastification'
import InsightsBetaMessage from './InsightsBetaMessage.vue'
export default {
  components: {
    LayoutDefault,
    SiteSelector,
    NoSiteSelected,
  },
  data() {
    return {}
  },
  setup() {
    const { getters } = useStore()
    const userId = computed(() => getters['auth/userId'])
    const { selectedSite } = useSelectedSite({ userId: userId.value })
    const toast = useToast()
    return { selectedSite, toast }
  },
  computed: {
    ...mapGetters({
      isMultiSiteUser: 'auth/isMultiSiteUser',
      isReseller: 'auth/isReseller',
      userSettings: 'auth/userSettings',
    }),
    iframeUrl() {
      const params = queryString.stringify({
        p_site: this.selectedSite.legacyId,
        p_weight: this.userSettings.weight.code,
        p_locale: this.userSettings.locale.code,
        p_preferred_menu_language: this.userSettings.preferredMenuLanguage.code,
      })
      const address =
        '/pentaho/api/repos/%3Apublic%3AWinnow%3Adashboards%3ASingle_Site_Dashboard.wcdf/generatedContent'
      return `${import.meta.env.VUE_APP_PENTAHO_URL}${address}?${params}`
    },
  },
  created() {
    // quick and dirty fix for now because we don't really need multiple toasts at a time
    // and single site dashboards displays the insights beta message twice because it pops it open
    // when first loading and after redirecting to the selected site
    this.toast.updateDefaults({ maxToasts: 1 })
    this.toast.success({
      component: InsightsBetaMessage,
    })
  },
  mounted() {
    window.addEventListener('message', this.iframeMessageHandler)
  },
  beforeUnmount() {
    window.removeEventListener('message', this.iframeMessageHandler)
  },
  beforeRouteLeave() {
    this.toast.clear()
  },
  methods: {
    iframeMessageHandler(ev) {
      if (ev.origin === import.meta.env.VUE_APP_PENTAHO_URL && ev.data && ev.data.pageheight) {
        this.$refs.dashboard.style.height = Math.ceil(ev.data.pageheight) + 'px'
      }
    },
  },
}
</script>
