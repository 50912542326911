import api from '@/store/api/menus'

export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    trackersById: (state, getters, rootState, rootGetters) => (ids) => {
      if (!ids) return []
      return ids.map((id) => {
        let tracker = { ...state.records[id] }
        if (tracker.organisationId) {
          tracker.organisation = rootGetters['menu-management/organisations/organisationById'](
            tracker.organisationId
          )
        }
        if (tracker.activeMenuId) {
          tracker.activeMenu = rootGetters['menu-management/menus/menuById'](tracker.activeMenuId)
        }
        return tracker
      })
    },
    assignedTrackersForMenu: (state, getters, rootState, rootGetters) => (menuId) => {
      let trackerIds = rootGetters['menu-management/menus/trackersInMenu'](menuId)
      return getters['trackersById'](trackerIds)
    },
  },
  actions: {
    getAssignedTrackers({ commit, dispatch }, { organisationId, menuId, ...query }) {
      return api
        .getAssignedTrackers({ menuId, ...query })
        .then(({ records, orderedIds, organisationRecords, menuRecords, total }) => {
          commit('STORE_TRACKERS', records)
          commit('menu-management/organisations/STORE_ORGANISATIONS', organisationRecords, {
            root: true,
          })
          // avoid overwriting the active menu as it messes up with validations and counters
          delete menuRecords[menuId]
          commit(
            'menu-management/menus/UPDATE_RECORDS',
            { records: menuRecords },
            {
              root: true,
            }
          )
          commit(
            'menu-management/organisations/UPDATE_ORGANISATION',
            {
              id: organisationId,
              trackerIds: orderedIds,
            },
            {
              root: true,
            }
          )
          dispatch(
            'menu-management/menus/addTrackersToMenu',
            { id: menuId, trackers: orderedIds },
            { root: true }
          )
          return { records, total }
        })
    },
    getCompatibleTrackers({ commit }, { organisationId, menuId, ...query }) {
      return api
        .getCompatibleTrackers({ menuId, ...query })
        .then(({ records, orderedIds, organisationRecords, menuRecords, total }) => {
          commit('STORE_TRACKERS', records)
          commit('menu-management/organisations/STORE_ORGANISATIONS', organisationRecords, {
            root: true,
          })
          commit(
            'menu-management/menus/UPDATE_RECORDS',
            { records: menuRecords },
            {
              root: true,
            }
          )
          commit(
            'menu-management/organisations/UPDATE_ORGANISATION',
            {
              id: organisationId,
              trackerIds: orderedIds,
            },
            {
              root: true,
            }
          )
          return { records, total }
        })
    },
    assignTracker({ commit }, { trackerId, activeMenuId, oldMenuId }) {
      return api
        .postAssignMenu({ trackerId, activeMenuId, oldMenuId })
        .then(({ tracker, menu }) => {
          commit('UPDATE_TRACKER', tracker)
          if (menu) {
            commit('menu-management/menus/UPDATE_MENU', menu, { root: true })
          }
        })
    },
    unassignTracker({ commit }, { trackerId, activeMenuId, oldMenuId }) {
      return api
        .postUnassignMenu({ trackerId, activeMenuId, oldMenuId })
        .then(({ tracker, menu }) => {
          commit('UPDATE_TRACKER', tracker)
          if (menu) {
            commit('menu-management/menus/UPDATE_MENU', menu, { root: true })
          }
        })
    },
  },
  mutations: {
    STORE_TRACKERS(state, records) {
      state.records = { ...state.records, ...records }
    },
    UPDATE_TRACKER(state, tracker) {
      state.records[tracker.id] = { ...state.records[tracker.id], ...tracker }
    },
  },
}
