<script>
import { INGREDIENTS_FACET_ID, INGREDIENTS_FACET_CODE } from '@/store/constants'
import { mapGetters } from 'vuex'

import TreeOfFacets from '@/components/TreeOfFacets.vue'

export default {
  components: {
    TreeOfFacets,
  },
  props: {
    buffer: [Boolean, String],
    updateBuffer: Function,
    serverErrors: Array,
    serverWarnings: Array,
    isValidating: Boolean,
  },
  data() {
    return {
      isEnabled: false,
      INGREDIENTS_FACET_ID,
      INGREDIENTS_FACET_CODE,
    }
  },

  computed: {
    ...mapGetters('taxonomy/facets', ['byId']),
  },

  watch: {
    isEnabled: {
      handler: function (newValue) {
        if (newValue === false) {
          this.updateBuffer(null)
        }
      },
      immediate: true,
    },
  },

  methods: {
    selectFacet(nodeId) {
      this.updateBuffer(nodeId)
    },

    isFacetSelected(nodeId) {
      return this.buffer === nodeId
    },
  },
}
</script>

<template>
  <PageSection>
    <SectionHeader :class="{ 'is-solo': !isEnabled }">
      <div class="customControl customControl--checkbox">
        <input
          id="add-to-ingredients"
          v-model="isEnabled"
          type="checkbox"
          class="customControl-input"
        />
        <label
          class="customControl-label"
          for="add-to-ingredients"
        >
          <h2 class="leading-7">{{ $t('taxman.ingredientsFacet.add') }}</h2>
          <p
            v-if="isEnabled"
            class="mt-1"
          >
            {{ $t('taxman.ingredientsFacet.description') }}
          </p>
        </label>
      </div>
    </SectionHeader>

    <template v-if="isEnabled">
      <div class="-mt-5 -mb-2 -mx-4">
        <TreeOfFacets
          id="parentbox2"
          :root-id="INGREDIENTS_FACET_ID"
          :search-query="{
            facetGroupCode: INGREDIENTS_FACET_CODE,
          }"
          :sticky-search-selectors="{
            parent: '#parentbox2',
            relative: '#parentbox2',
          }"
          :node-classes="
            (nodeId) => ({
              'is-checked': isFacetSelected(nodeId),
              'is-active': isFacetSelected(nodeId),
            })
          "
          :key-prefix="'ingredients'"
        >
          <template #node="{ nodeId, modifiers }">
            <div
              v-if="byId(nodeId).parentId !== null"
              v-test="'node-controls'"
              class="Tree-controls"
              :class="{ 'is-active': modifiers['is-checked'] }"
            >
              <template v-if="modifiers['is-checked']">
                <span class="iconCircle iconCircle--sm">
                  <SvgIcon
                    class="text-blueberry"
                    name="24-ic-check"
                  />
                </span>
              </template>
              <template v-else>
                <button
                  class="button button--icon button--round button--primary"
                  @click="selectFacet(nodeId)"
                >
                  <SvgIcon name="24-ic-check" />
                </button>
              </template>
            </div>
          </template>
        </TreeOfFacets>
      </div>
    </template>
  </PageSection>
</template>
