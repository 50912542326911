<script>
import { mapGetters } from 'vuex'
import { pathToRoot } from '@/store/utils/taxonomy'

import TreeBox from '@/components/TreeBox/TreeBox.vue'
import FacetView from '@/components/FacetView/FacetView.vue'
import TreeOfFacets from '@/components/TreeOfFacets.vue'

export default {
  name: 'FacetsPage',
  components: {
    TreeBox,
    FacetView,
    TreeOfFacets,
  },

  data() {
    return {
      displayedId: null,
    }
  },

  computed: {
    ...mapGetters('taxonomy/facets', ['byId']),
  },

  methods: {
    displayRecord(id) {
      // dont display roots
      if (this.byId(id).parentId !== null) {
        this.displayedId = id
      }
    },

    addFacet(nodeId) {
      const rootId = pathToRoot(nodeId, this.byId).pop()

      this.$store.dispatch('code-builder/addFacet', {
        record: this.byId(nodeId),
        rootRecord: this.byId(rootId),
      })
    },

    removeFacet(nodeId) {
      this.$store.dispatch('code-builder/removeFacet', nodeId)
    },

    isFacetSelected(nodeId) {
      return this.$store.getters['code-builder/facetById'](nodeId)
    },

    resetScroll() {
      this.$scrollTo(`#code-builder`, 1)
    },
  },
}
</script>

<template>
  <TreeBox class="box-content-full-width -mb-4 border-t border-t-slate">
    <template #main>
      <TreeOfFacets
        :node-classes="
          (nodeId) => ({
            'is-active': displayedId === nodeId,
          })
        "
        @node-click="displayRecord($event)"
        @result-click="displayRecord($event)"
      >
        <template #node="{ nodeId }">
          <div
            v-if="byId(nodeId).parentId !== null"
            v-test="'node-controls'"
            :class="['Tree-controls', { 'is-active': isFacetSelected(nodeId) }]"
          >
            <button
              v-if="!isFacetSelected(nodeId)"
              v-test="'select-facet'"
              class="button button--icon button--round button--primary"
              @click.stop="
                () => {
                  addFacet(nodeId)
                  resetScroll()
                }
              "
            >
              <SvgIcon name="24-ic-check" />
            </button>
            <template v-else>
              <span
                class="iconCircle iconCircle--sm hide-on-hover"
                data-test-facet-is-selected
              >
                <SvgIcon
                  class="text-blueberry"
                  name="24-ic-check"
                />
              </span>
              <button
                v-test="'remove-facet'"
                class="button button--icon button--round button--primary show-on-hover"
                @click.stop="removeFacet(nodeId)"
              >
                <SvgIcon name="24-ic-clear" />
              </button>
            </template>
          </div>
        </template>
        <template #result="{ nodeId }">
          <div
            v-test="'result-controls'"
            :class="['ResultNode-controls', { 'is-active': isFacetSelected(nodeId) }]"
          >
            <button
              v-if="!isFacetSelected(nodeId)"
              v-test="'select-facet'"
              class="button button--icon button--round button--primary"
              @click.stop="addFacet(nodeId)"
            >
              <SvgIcon name="24-ic-check" />
            </button>
            <template v-else>
              <span
                class="iconCircle iconCircle--sm hide-on-hover"
                data-test-facet-is-selected
              >
                <SvgIcon
                  class="text-blueberry"
                  name="24-ic-check"
                />
              </span>
              <button
                v-test="'remove-facet'"
                class="button button--icon button--round button--primary show-on-hover"
                @click.stop="removeFacet(nodeId)"
              >
                <SvgIcon name="24-ic-clear" />
              </button>
            </template>
          </div>
        </template>
      </TreeOfFacets>
    </template>

    <template #column>
      <FacetView
        v-if="displayedId"
        :record-id="displayedId"
      />
    </template>
  </TreeBox>
</template>
