import api from '@/store/api/organisation'

export default {
  namespaced: true,
  state: {
    cached: false,
    locales: {},
    currencies: {},
    timezones: {},
    weightUnits: {},
    temperatureUnits: {},
  },
  getters: {
    cachedStatus: (state) => state.cached,
    locales: (state) => Object.values(state.locales),
    localeById: (state) => (id) => state.locales[id],
    currencies: (state) => Object.values(state.currencies),
    currencyById: (state) => (id) => state.currencies[id],
    timezones: (state) => Object.values(state.timezones),
    timezoneById: (state) => (id) => state.timezones[id],
    weightUnits: (state) => Object.values(state.weightUnits),
    weightUnitById: (state) => (id) => state.weightUnits[id],
    temperatureUnits: (state) => Object.values(state.temperatureUnits),
    temperatureUnitById: (state) => (id) => state.temperatureUnits[id],
  },
  actions: {
    getOptions({ commit }) {
      return api
        .fetchLocalisation()
        .then(
          ({
            localeRecords,
            currencyRecords,
            timezoneRecords,
            weightUnitRecords,
            temperatureRecords,
          }) => {
            commit('STORE_LOCALES', localeRecords)
            commit('STORE_CURRENCIES', currencyRecords)
            commit('STORE_TIMEZONES', timezoneRecords)
            commit('STORE_WEIGHT_UNITS', weightUnitRecords)
            commit('STORE_TEMPERATURE_UNITS', temperatureRecords)
            commit('CACHED')
          }
        )
        .catch((error) => Promise.reject(error))
    },
    saveLocalisation({ dispatch }, { nodeId, propagateChanges, data }) {
      return dispatch(
        'hierarchy/nodes/saveNodeChanges',
        {
          nodeId,
          data,
          query: { propagateChanges },
        },
        { root: true }
      )
    },
  },
  mutations: {
    CACHED(state) {
      state.cached = true
    },
    STORE_LOCALES(state, records) {
      state.locales = records
    },
    STORE_CURRENCIES(state, records) {
      state.currencies = records
    },
    STORE_TIMEZONES(state, records) {
      state.timezones = records
    },
    STORE_WEIGHT_UNITS(state, records) {
      state.weightUnits = records
    },
    STORE_TEMPERATURE_UNITS(state, records) {
      state.temperatureUnits = records
    },
  },
}
