<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Hero>{{ $t('titles.menus') }}</Hero>

    <page-section>
      <!-- Empty results list -->
      <div class="flex flex-between flex-wrap mb-4">
        <div class="flex flex-middle -mx-1">
          <filter-input
            v-model="searchTerm"
            :total-results="availableItems"
            class="mx-1"
            @search="onSearch"
          />
          <div
            v-if="$can('manage', 'menus')"
            class="mx-1 customControl customControl--checkbox"
          >
            <input
              id="show-archived"
              v-model="showArchived"
              type="checkbox"
              class="customControl-input"
              data-test-show-archived
              @change="onShowArchivedChange"
            />
            <label
              class="customControl-label"
              for="show-archived"
            >
              {{ $t('action.showArchived') }}
            </label>
          </div>
        </div>
        <router-link
          v-if="$can('manage', 'menus')"
          v-test="'access-create-menu'"
          class="button button--primary"
          to="/menus/create-menu"
        >
          <SvgIcon name="24-ic-add" />
          <span>{{ $t('menus.actions.createMenu') }}</span>
        </router-link>
      </div>
      <Organisation-browser
        class="box-table"
        :selected-node-id="organisationId"
        :focused-node-id="organisationId"
        :is-editable="false"
        :archived-available="$can('manage', 'menus')"
        @node-select="onOrgSelect"
      />

      <loaded-content
        :is-loading="loadingStatus === 'LOADING' || searchStatus === 'LOADING'"
        :has-error="loadingStatus === 'ERROR'"
        :has-data="loadingStatus === 'LOADED' && !!menus.length"
      >
        <template #no-data>
          <div class="h2 text-center py-8 flex flex-column flex-center">
            <p class="m-1">{{ $t('menus.list.error.noMenus') }}</p>
            <p class="m-1">{{ $t('menus.list.error.noMenus.hint') }}</p>
            <SvgIcon
              class="mx-auto m-8 w-full h-full"
              name="no-results"
              lg
            />
          </div>
        </template>
        <!-- Display Results -->
        <div class="box-table">
          <SortableTable
            v-test="'menus-list'"
            :headers="headers"
            :sorted-by="sortedBy"
            :sorted-dir="sortedDir"
            @sorted="onSort"
          >
            <tbody>
              <menu-row
                v-for="menu in menus"
                :key="menu.id"
                :archived="menu.archived"
                :organisation="menu.organisation.shortName"
                :trackers-count="menu.numberOfActiveTrackers"
                :name="menu.name"
                :description="menu.description"
                :active-task="menu.activeTask"
                :created-date="menu.created"
                :original-author="menu.originalAuthor"
                :modified-date="menu.modified"
                :last-update-author="menu.lastUpdateAuthor"
                @menu-select="trClick(menu)"
              />
            </tbody>
          </SortableTable>
        </div>
        <Pagination
          :total="availableItems"
          :size="pageSize"
        />
      </loaded-content>
    </page-section>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import OrganisationBrowser from '@/components/OrgBrowser/OrgBrowser.vue'
import Hero from '@/components/Hero/Hero.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import SortableTable from '@/components/SortableTable/SortableTable.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import MenuRow from './MenusList/MenuRow.vue'
import { PAGESIZE_MENUS, MENU_STATES, WINNOW_ORG_ID } from '@/store/constants'

import { mapState, mapGetters } from 'vuex'
import FilterInput from '@/components/FilterInput.vue'

export default {
  components: {
    LayoutDefault,
    OrganisationBrowser,
    Hero,
    LoadedContent,
    SortableTable,
    Pagination,
    MenuRow,
    FilterInput,
  },
  data() {
    return {
      pageSize: PAGESIZE_MENUS,
      page: 0,
      sortedBy: 'created',
      sortedDir: 'descending',
      loadingStatus: 'IDLE',
      MENU_STATES_PROCESSED: MENU_STATES.PROCESSED,
      MENU_STATES_TRANSLATE: MENU_STATES.TRANSLATE,
      MENU_STATES_ERROR: MENU_STATES.ERROR,
      searchTerm: this.$route.query.search,
      searchStatus: 'IDLE',
      showArchived: false,
    }
  },
  computed: {
    ...mapState('menu-management/menus', ['availableItems']),
    ...mapGetters('menu-management/menus', ['menus', 'menuStates']),
    ...mapGetters('auth', ['userRootOrganisationId']),
    organisationId() {
      return this.$route.query.organisationId
    },
    headers() {
      let columns = [
        {
          id: 'organisation',
          title: this.$t('menus.list.organisation'),
        },
        {
          id: 'name',
          title: this.$t('menus.list.name'),
        },
        {
          id: 'created',
          title: this.$t('menus.list.created'),
          sortable: true,
        },
        {
          id: 'updated',
          title: this.$t('menus.list.updated'),
        },
        {
          id: 'trackers',
          title: this.$t('menus.list.trackers'),
          sortable: false,
          hidden: true,
        },
      ]

      return columns
    },
  },
  watch: {
    $route(to) {
      this.readUrl(to)
      this.getData().finally(() => {
        if (this.searchStatus === 'LOADING') {
          this.searchStatus = 'IDLE'
        }
      })
    },
  },
  created() {
    this.readUrl(this.$route)
    this.getData()
    if (!this.menuStates.length) {
      this.$store.dispatch('menu-management/menus/getMenuStates')
    }
  },
  methods: {
    onSort({ column, direction }) {
      this.sortedBy = column
      this.sortedDir = direction
      this.$router.push({
        query: { ...this.$route.query, sortedBy: this.sortedByAsString(), page: 1 },
      })
    },
    trClick(menu) {
      let params = { menuId: menu.id }
      if (menu.activeTask === MENU_STATES.TRANSLATE) {
        this.$router.push({ name: 'translate-menu', params })
      } else {
        this.$router.push({ name: 'menu-summary', params })
      }
    },
    sortedByAsString() {
      return this.sortedDir === 'descending' ? `-${this.sortedBy}` : this.sortedBy
    },
    readUrl(route) {
      const { sortedBy, page, archived } = route.query
      if (sortedBy) {
        this.sortedDir = sortedBy.indexOf('-') === 0 ? 'descending' : 'ascending'
        this.sortedBy = this.sortedDir === 'descending' ? sortedBy.match(/^-(.+)/)[1] : sortedBy
      }
      this.page = page ? parseInt(page - 1) : undefined
      this.showArchived = archived == 'true' || false
    },
    getData() {
      this.loadingStatus = 'LOADING'
      return this.$store
        .dispatch('menu-management/menus/getMenus', {
          page: this.page,
          sortBy: this.sortedByAsString(),
          search: this.searchTerm,
          filter: {
            organisationId: this.organisationId,
            archived: this.showArchived && this.$can('manage', 'menus'),
            // get only menus that are assigned to trackers if the user is not a menu manager
            // we're sending undefined for managers so that the filter doesn't get applied at all. We want all menus in this case
            // we're sending true for everyone else so that they can have a preview of the menus deployed on their trackers
            hasTrackersAssigned: this.$can('manage', 'menus') ? undefined : true,
          },
        })
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch(() => {
          this.loadingStatus = 'ERROR'
        })
    },
    onSearch() {
      this.searchStatus = 'LOADING'
      let search = this.searchTerm ? this.searchTerm : undefined
      this.$router.push({ ...this.$route, query: { ...this.$route.query, search } })
    },
    onOrgSelect(id) {
      let query = { ...this.$route.query }
      if (id && id == WINNOW_ORG_ID) {
        delete query.organisationId
      } else {
        query.organisationId = id
      }
      this.$router.push({
        ...this.$route,
        query,
      })
    },
    onShowArchivedChange() {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, archived: this.showArchived },
      })
    },
  },
}
</script>
