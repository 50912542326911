export default (data) => ({
  id: data.id,
  locationId: data.locationId,
  name: data.name,
  sourceLocation: data.sourceLocation
    ? {
        id: data.sourceLocation.id,
        level: data.sourceLocation.level,
        name: data.sourceLocation.name,
        qualifiedName: data.sourceLocation.qualifiedName,
      }
    : null,
})
