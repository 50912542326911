<template>
  <div
    ref="spinner"
    data-test-spinner
    role="progressbar"
    class="spinner"
    :tabindex="hasProgressIndicator ? 0 : null"
    :class="{ 'spinner--with-progress': hasProgressIndicator }"
    :aria-valuenow="hasProgressIndicator ? progress : null"
    :aria-valuemin="hasProgressIndicator ? 0 : null"
    :aria-valuemax="hasProgressIndicator ? 100 : null"
  >
    <span
      v-if="hasProgressIndicator"
      class="spinner-progress"
      :style="{ width: progress + '%' }"
    ></span>
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: ['progress'],
  computed: {
    hasProgressIndicator() {
      return this.progress !== undefined
    },
  },
  methods: {
    focus() {
      this.$refs.spinner.focus()
    },
  },
}
</script>
