<template>
  <v-tooltip
    v-if="isInherited"
    theme="tooltip-white"
    placement="top"
    class="flex-inline"
  >
    <filter-chip :disabled="true">
      <slot />
    </filter-chip>
    <template #popper>
      <slot name="tooltip-content" />
    </template>
  </v-tooltip>
  <filter-chip
    v-else-if="removable"
    :removable="true"
    @remove="$emit('remove')"
  >
    <slot />
  </filter-chip>
  <filter-chip v-else>
    <slot />
  </filter-chip>
</template>
<script>
import FilterChip from '@/components/FilterChip.vue'

export default {
  components: {
    FilterChip,
  },
  props: {
    id: String,
    sourceLocationId: String,
    locationId: String,
    removable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['remove'],
  computed: {
    isInherited() {
      return this.sourceLocationId !== this.locationId
    },
  },
}
</script>
