export default {
  computed: {
    defaultsFromQueryParams() {
      // defaults coming in as query params need to be strings or JSON.stringified values
      // example: `default[name]=...`
      let queryParams = this.$route.query
      let defaults = Object.keys(queryParams)
        .filter((key) => key.indexOf('default') === 0)
        .map((key) => key.match(/\[.*\]/)[0].slice(1, -1))
        .reduce((defaults, key) => {
          let val = queryParams['default[' + key + ']']
          // check if first character is [, {, or "
          if (val.match(/^[{["]/)) {
            defaults[key] = JSON.parse(val)
          } else {
            let boolIndex = ['true', 'false'].indexOf(val) + 1
            defaults[key] = boolIndex ? [true, false][boolIndex - 1] : val
          }
          return defaults
        }, {})
      if (!defaults) return
      return defaults
    },
  },
}
