<script>
import { mapGetters } from 'vuex'
import { recordBranchToTree } from '@/store/utils/taxonomy'

import StaticTree from '@/components/StaticTree.vue'
import NodeFacet from '@/components/NodeFacet.vue'

export default {
  components: {
    StaticTree,
    NodeFacet,
  },
  props: {
    record: Object,
  },
  data() {
    return {
      links: [],
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('taxonomy', ['getDetailLevelById']),

    linkTree() {
      let outputTree = {
        id: 'root',
        children: [],
      }

      this.links.forEach((branch) => {
        outputTree = recordBranchToTree(branch, outputTree)
      })

      return outputTree
    },
  },

  async created() {
    try {
      this.isLoading = true
      this.links = await this.$store.dispatch('taxonomy/terms/getLinks', this.record.id)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<template>
  <PageSection v-if="!isLoading && links.length > 0">
    <SectionHeader>
      <template #section-title>{{ $t('taxman.relatedFacets') }}</template>
    </SectionHeader>

    <ul class="Tree -mt-3 -mb-2 -mx-4">
      <StaticTree
        :tree="linkTree"
        :expanded="true"
        :key-prefix="'related'"
      >
        <template #default="{ node }">
          <NodeFacet
            :record="node.content"
            :detail-level="getDetailLevelById(node.content.detailLevel)"
          />
        </template>
      </StaticTree>
    </ul>
  </PageSection>
  <Loading-Spinner
    v-else-if="isLoading"
    class="spinner--md"
  />
</template>
