import moment from 'moment'
import { DATE_FORMAT_ISO_8601 } from '@/store/constants'
export const daysInMonth = (date) => {
  let startDay = moment.utc(date).startOf('month')
  let endDay = moment.utc(date).endOf('month')
  let days = []

  while (startDay.isBefore(endDay)) {
    days.push(startDay.format(DATE_FORMAT_ISO_8601))
    startDay.add(1, 'day')
  }

  return days
}

export const daysUpTo = (date) => {
  let endDay = moment.utc(date)
  let startDay = moment.utc(date).subtract(6, 'day')
  let days = []

  while (startDay.isSameOrBefore(endDay)) {
    days.push(startDay.format(DATE_FORMAT_ISO_8601))
    startDay.add(1, 'day')
  }

  return days
}
