<template>
  <header class="header">
    <div class="header-slot">
      <slot />
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.analytics.track('Selected log out')
      this.analytics.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  color: theme('colors.white');
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  grid-template-areas:
    'menu     menu     menu'
    'content  content  content';
}

.header-menu-toggle {
  grid-area: menu;
  height: theme('spacing.12');
  width: theme('spacing.12');
  padding: theme('spacing.3');
  background-color: theme('colors.blueberry.DEFAULT');
  &:hover {
    background-color: theme('colors.acai.900');
  }
}
.header-menu-toggle--open {
  background-color: theme('colors.acai.900');
}
.header-logo {
  color: theme('colors.white');
  grid-area: logo;
  justify-self: center;
}
.header-slot {
  grid-area: content;
}

@include respond-min('medium') {
  .header {
    background-color: transparent;
  }
  .header-menu-toggle,
  .header-logo {
    display: none;
  }
}
</style>
