<template>
  <LayoutDefault>
    <Hero>
      <template #default>UI Kit</template>
      <template #hero-content>
        <div class="tabs mb-3">
          <router-link
            to="/ui/elements"
            class="tabs-item"
            >Elements</router-link
          >
          <router-link
            to="/ui/forms"
            class="tabs-item"
            >Forms</router-link
          >
          <router-link
            to="/ui/icons"
            class="tabs-item"
            >SVG</router-link
          >
          <router-link
            to="/ui/typography"
            class="tabs-item"
            >Typography</router-link
          >
          <router-link
            to="/ui/tables"
            class="tabs-item"
            >Tables</router-link
          >
          <router-link
            to="/ui/collapsible-areas"
            class="tabs-item"
          >
            Collapsible Areas
          </router-link>
          <router-link
            to="/ui/page-elements"
            class="tabs-item"
          >
            Page Elements
          </router-link>
        </div>
      </template>
    </Hero>

    <div class="wrapper">
      <router-view />
    </div>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'

export default {
  name: 'UILibrary',
  components: {
    LayoutDefault,
    Hero,
  },
}
</script>
<style lang="scss">
.UI {
  margin: theme('spacing.5') 0;

  hr {
    margin: theme('spacing.8') calc(-1 * theme('spacing.4'));
  }

  .inverted {
    background: theme('colors.blueberry.DEFAULT');
  }

  code {
    font-size: theme('fontSize.xs');
    line-height: theme('lineHeight.normal');
    text-transform: none;
    background: theme('colors.white');
    border: 1px solid transparent;
    border-radius: 3px;
    color: theme('colors.acai.DEFAULT');
    display: inline-block;
    vertical-align: 2px;
  }

  .boss {
    font-weight: theme('fontWeight.bold');
    padding: 0 0.5em;
    border-color: theme('colors.acai.DEFAULT');
  }

  code + code {
    margin-left: 1em;
  }
}

.UI-title {
  color: theme('colors.acai.DEFAULT');
  padding: 0 theme('spacing.4');
}

.demo {
  padding: theme('spacing.5') theme('spacing.4');
  margin: calc(-1 * theme('spacing.4'));
}

.demo-buttons {
  margin-left: calc(-1 * theme('spacing.2'));
  margin-right: calc(-1 * theme('spacing.2'));

  .button {
    margin-left: theme('spacing.2');
    margin-bottom: theme('spacing[2.5]');
  }
}

.icongrid {
  display: flex;
  margin-left: calc(-1 * theme('spacing.2'));
  margin-right: calc(-1 * theme('spacing.2'));
  flex-wrap: wrap;

  .icongrid-unit {
    box-sizing: border-box;
    display: block;
    text-align: center;
    padding: theme('spacing.2');
    flex: 0 0 50px;

    &:hover {
      box-shadow: 0 0 0 3px theme('colors.acai.200');
    }
  }
}

.icongrid--withBox {
  .icon {
    background: theme('colors.apple.DEFAULT');
    color: theme('colors.white');
    box-shadow: 0 0 0 1px theme('colors.black');
  }
}

.icongrid--lg {
  .icongrid-unit {
    flex-basis: 68px;
    padding: theme('spacing.3') theme('spacing.2');
  }
}

.icongrid--xl {
  .icongrid-unit {
    flex-basis: 90px;
    padding: theme('spacing.3') theme('spacing.4');
  }
}

.tooltip-ui {
  transition: none !important;

  .tooltip-inner {
    background: theme('colors.blueberry.DEFAULT');
    border-color: theme('colors.blueberry.DEFAULT');
  }

  &[x-placement^='top'] .tooltip-arrow {
    border-top-color: theme('colors.blueberry.DEFAULT');
  }

  &[x-placement^='bottom'] .tooltip-arrow {
    border-bottom-color: theme('colors.blueberry.DEFAULT');
  }
}
</style>
