<template>
  <div class="CentralMessage">
    <div class="CentralMessage-wrapper">
      <SvgIcon
        class="mx-auto w-24 h-24"
        name="48-ic-winnow"
      />
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LayoutCentralMessage',
  mounted() {
    document.body.classList.add('is-inverted')
  },
  beforeUnmount() {
    document.body.classList.remove('is-inverted')
  },
}
</script>
<style lang="scss">
.CentralMessage {
  grid-area: 1 / 1 / 3 / 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p a {
    color: theme('colors.lemon.DEFAULT');
  }
}

.CentralMessage-wrapper {
  min-height: 75vh;
  max-width: $width-md;
  padding: theme('spacing.3');
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
</style>
