<template>
  <div>
    <svg
      id="timeline-svg-small"
      ref="chart"
      class="timeline-svg-small"
      :viewbox="`0 0 ${width} ${chartHeight + margins.top + margins.bottom}`"
      :width="width"
      :height="chartHeight + margins.top + margins.bottom"
    >
      <line
        :x1="margins.left + 1"
        :x2="margins.left + 1"
        :y1="margins.top"
        :y2="margins.top + chartHeight"
        class="guide-vertical-small"
      />
      <line
        :x1="margins.left + chartWidth - 1"
        :x2="margins.left + chartWidth - 1"
        :y1="margins.top"
        :y2="margins.top + chartHeight"
        class="guide-vertical-small"
      />
      <g
        ref="timeline-xAxis"
        class="timeline-axis timeline-xAxis"
      ></g>
      <g class="timeline-data">
        <rect
          v-for="data in displayedData"
          :key="data.id"
          class="timeline-overview-totals"
          :x="x(data.date)"
          :width="xBand.bandwidth()"
          :height="xBand.bandwidth()"
          :y="margins.top"
          :opacity="yTotals(data.transactions)"
        ></rect>
        <rect
          v-for="data in displayedData"
          :key="data.id"
          class="timeline-overview-cost"
          :x="x(data.date)"
          :width="xBand.bandwidth()"
          :height="xBand.bandwidth()"
          :y="margins.top + xBand.bandwidth()"
          :opacity="yCost(data.cost)"
        ></rect>
        <rect
          v-for="data in displayedData"
          :key="data.id"
          class="timeline-overview-weight"
          :x="x(data.date)"
          :width="xBand.bandwidth()"
          :height="xBand.bandwidth()"
          :y="margins.top + xBand.bandwidth() * 2"
          :opacity="yWeight(data.weight)"
        ></rect>
      </g>
      <g
        ref="brush"
        class="brush"
      ></g>
    </svg>
  </div>
</template>

<script>
import {
  rollups,
  sum,
  scaleUtc,
  scaleBand,
  timeMinutes,
  max,
  scaleLinear,
  brushX,
  select,
  timeHour,
  axisBottom,
} from 'd3'

import moment from 'moment-timezone'
import { TIME_FORMAT } from '@/store/constants'
export default {
  props: {
    timezone: String,
    selectedId: [String, Number],
    dataPoints: Array,
    start: Date,
    end: Date,
    dataInterval: {
      type: Number,
      default: 30,
    },
    /** focusedTime
     * {
     *   start: Date,
     *   end: Date
     * }
     */
    focusedTime: Object,
  },
  emits: ['focus-time'],
  data() {
    return {
      margins: { top: 20, right: 0, bottom: 20, left: 40 },
      padding: {
        top: 10,
      },
      width: 500,
      height: 100,
    }
  },
  computed: {
    chartHeight() {
      return this.xBand.bandwidth() * 3
    },
    chartWidth() {
      return this.width - this.margins.left - this.margins.right
    },
    displayedData() {
      const coeff = 1000 * 60 * this.dataInterval

      const summarizedData = rollups(
        this.dataPoints,
        (v) => {
          return {
            cost: sum(v, (d) => d.cost),
            weight: sum(v, (d) => d.weight),
            transactions: v.length,
          }
        },
        (d) => {
          return new Date(Math.floor(d.date.getTime() / coeff) * coeff)
        }
      )

      const displayedData = Array.from(summarizedData, ([key, value]) => ({
        date: key,
        cost: value.cost,
        weight: value.weight,
        transactions: value.transactions,
      }))

      return displayedData
    },
    x() {
      return scaleUtc()
        .domain([this.start, this.end])
        .range([this.margins.left, this.width - this.margins.right])
    },
    xBand() {
      return scaleBand()
        .domain(timeMinutes(this.start, this.end, this.dataInterval))
        .range([this.margins.left, this.width - this.margins.right])
        .padding(0.1)
    },
    yCost() {
      return scaleLinear()
        .domain([0, max(this.displayedData, (d) => d.cost)])
        .range([0.1, 1])
        .nice()
    },
    yWeight() {
      return scaleLinear()
        .domain([0, max(this.displayedData, (d) => d.weight)])
        .range([0.1, 1])
        .nice()
    },
    yTotals() {
      return scaleLinear()
        .domain([0, max(this.displayedData, (d) => d.transactions)])
        .range([0.1, 1])
        .nice()
    },
    brush() {
      return brushX()
        .extent([
          [this.margins.left, this.margins.top],
          [this.width - this.margins.right, this.margins.top + this.chartHeight],
        ])
        .on('start', this.brushstarted)
        .on('brush', this.brushed)
        .on('end', this.brushended)
    },
    defaultSelection() {
      return [this.x(this.focusedTime.start), this.x(this.focusedTime.end)]
    },
  },
  watch: {
    focusedTime({ start, end }) {
      select(this.$refs.brush).call(this.brush.move, [this.x(start), this.x(end)])
    },
    brush() {
      select(this.$refs.brush).selectAll('.handle').remove()
    },
  },
  mounted() {
    const isFocusedTime = this.focusedTime.start && this.focusedTime.end
    this.width = this.$el.clientWidth
    this.xAxis()
    select(this.$refs.brush)
      .call(this.brush)
      .call(this.brush.move, isFocusedTime ? this.defaultSelection : [0, 0])
  },
  methods: {
    tickFormat(d) {
      return moment(d).tz(this.timezone).format(TIME_FORMAT)
    },
    tickFilter(d) {
      // we expect in the future to get these hours dynamic for each site
      return ['0', '7', '12', '18'].includes(
        moment(d)
          // make sure that moment always formats the date hours
          // in _en-US_ language in order to match with the given list
          .locale('en-US')
          .tz(this.timezone)
          .format('H')
      )
    },
    xAxis() {
      select(this.$refs['timeline-xAxis'])
        .attr('transform', `translate(0,${this.chartHeight + this.margins.top})`)
        .call(
          axisBottom(this.x).ticks(timeHour.filter(this.tickFilter)).tickFormat(this.tickFormat)
        )
    },

    brushstarted(event) {
      if (event.sourceEvent) {
        this.centerBrush(event.sourceEvent.layerX)
      }
    },

    brushed(event) {
      if (event.type === 'brush' && event.mode === 'drag' && event.sourceEvent) {
        this.centerBrush(event.sourceEvent.layerX)
        // In case of drag, update focused time
        if (event.selection) {
          let focused = event.selection.map(this.x.invert)
          if (focused[0] !== this.focusedTime.start || focused[1] !== this.focused.end) {
            this.$emit('focus-time', { start: focused[0], end: focused[1] })
          }
        }
      }

      // In case of re-selection center brush
      if (event.mode === 'handle' && event.sourceEvent && event.selection) {
        this.centerBrush(event.sourceEvent.layerX)
      }
    },

    brushended(event) {
      if (event.selection && event.sourceEvent) {
        let focused = event.selection.map(this.x.invert)
        if (focused[0] !== this.focusedTime.start || focused[1] !== this.focused.end) {
          this.$emit('focus-time', { start: focused[0], end: focused[1] })
        }
      }
    },

    centerBrush(position) {
      let delta = this.defaultSelection[1] - this.defaultSelection[0]
      let start = position - delta / 2
      let end = position + delta / 2
      if (start <= this.margins.left) {
        end = this.margins.left - start + end
        start = this.margins.left
      }
      if (end >= this.width - this.margins.right) {
        start = start - (end - (this.width - this.margins.right))
        end = this.width - this.margins.right
      }
      select(this.$refs.brush).call(this.brush.move, [start, end])
    },
  },
}
</script>

<style lang="scss">
.timeline-svg-small {
  .timeline-data {
    rect {
      shape-rendering: crispEdges;
    }
  }

  .selection {
    stroke: theme('colors.blueberry.DEFAULT');
    stroke-width: 2px;
    fill: theme('colors.lemon.100');
  }
}

.timeline-legend-small {
  font-size: theme('fontSize.xs');
  text-anchor: end;
}

.timeline-overview-totals {
  fill: theme('colors.blueberry.DEFAULT');
}

.timeline-overview-cost {
  fill: theme('colors.blueberry.DEFAULT');
}

.timeline-overview-weight {
  fill: theme('colors.blueberry.DEFAULT');
}

.guide-vertical-small {
  stroke: theme('colors.acai.200');
}
</style>
