<template>
  <div
    class="buttonGroup"
    :aria-busy="isProcessing"
  >
    <button
      v-if="!deletePrompt"
      ref="initDelete"
      v-test="'delete-button'"
      type="button"
      :class="['button', deleteButtonClass]"
      :disabled="disabled"
      @click="initDelete"
    >
      <slot name="icon">
        <SvgIcon name="24-ic-delete" />
      </slot>
      <slot name="delete-button">
        <span>{{ $t('actions.delete') }}</span>
      </slot>
    </button>
    <div
      v-if="deletePrompt"
      v-test="'delete-prompt'"
    >
      <div
        id="deleteConfirm"
        class="mb-2"
      >
        <slot />
      </div>
      <div class="buttonGroup">
        <ButtonWithSpinner
          ref="confirmDelete"
          data-test-delete-confirm-button
          class="button--secondary"
          aria-describedby="delete-confirm"
          :disabled="disabled"
          @click="confirmDelete"
          :in-progress="isProcessing"
        >
          <template #icon><SvgIcon name="24-ic-check"></SvgIcon></template>
          {{ $t('actions.confirm') }}
        </ButtonWithSpinner>

        <button
          ref="cancelDelete"
          data-test-delete-cancel-button
          type="button"
          class="button button--secondary"
          :disabled="disabled || isProcessing"
          @click="cancelDelete"
        >
          <SvgIcon name="24-ic-clear" />
          <span>{{ $t('actions.cancel') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
export default {
  components: {
    ButtonWithSpinner,
  },
  props: {
    deleteButtonClass: {
      type: String,
      default: 'button--tertiary-warning',
    },
    disabled: Boolean,
  },
  emits: ['message', 'cancel', 'confirm'],
  data() {
    return {
      deletePrompt: false,
      isProcessing: false,
    }
  },
  methods: {
    initDelete() {
      this.deletePrompt = true
      this.isProcessing = false
      this.$emit('message')
      this.$nextTick(() => {
        this.$refs.confirmDelete.focus()
      })
    },
    cancelDelete() {
      this.deletePrompt = false
      this.$nextTick(() => {
        this.$refs.initDelete.focus()
      })
      this.$emit('cancel')
    },
    confirmDelete() {
      this.$emit('confirm')
      this.isProcessing = true
    },
    finishProcessing() {
      this.isProcessing = false
      this.deletePrompt = false
    },
  },
}
</script>
