export default function FoodItem(defaults) {
  return {
    id: null,
    name: null,
    nameEnglish: null,
    displayName: null,
    taxonomy: {
      mapping: null,
      code: null,
    },
    portion: {
      value: null,
      unit: null,
    },
    cost: null,
    status: null,
    foodGroups: [],
    foodGroupIds: [],
    created: null,
    updated: null,
    // flag for allowing a user to save changes
    allowSave: false,
    // flag for allowing a user to approve a mapping
    allowApprove: false,
    // keep track of the number of changes done
    changeIndex: 0,
    // buffer for properties being edited
    editing: {
      taxonomy: {},
    },
    similarmappingIds: [],
    ...(defaults ? defaults : {}),
  }
}
