import { gramsToOunces, ouncesToGrams, costPerWeight, toCostPerKg } from '@/utils/weight'
import { WEIGHT_METRIC_KEY, WEIGHT_US_STANDARD_KEY } from '@/store/constants'
import { isNumber } from '@/utils/number'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      weightPreference: 'auth/weightUnit',
    }),
    weightUnit: function () {
      switch (this.weightPreference) {
        case WEIGHT_US_STANDARD_KEY:
          return 'oz'
        case WEIGHT_METRIC_KEY:
          return 'grams'
        default:
          return null
      }
    },
    localisedPortionSize: function () {
      return this.convertWeightForDisplay(this.foodItem.portion.value)
    },
  },
  methods: {
    convertWeightForDisplay(value) {
      if (!isNumber(value)) return null
      switch (this.weightPreference) {
        case WEIGHT_US_STANDARD_KEY:
          return gramsToOunces(value).toFixed(2)
        case WEIGHT_METRIC_KEY:
          return parseFloat(value).toFixed(2)
        default:
          return null
      }
    },
    convertWeightForSave(value) {
      if (!isNumber(value)) return null
      switch (this.weightPreference) {
        case WEIGHT_US_STANDARD_KEY:
          return ouncesToGrams(value).toFixed(2)
        case WEIGHT_METRIC_KEY:
          return parseFloat(value).toFixed(2)
        default:
          return null
      }
    },
    /**
     * Converts cost/kg to cost/lbs or keeps the original cost depending on weight preference
     * @param { Number } value - cost/kg
     * @returns { Number || null }
     */
    convertCostForDisplay(value) {
      if (!isNumber(value)) return null
      return costPerWeight(value, this.weightPreference).toFixed(2)
    },
    /**
     * Converts a cost back to cost/kg depending on the weight preference
     * @param {Number} value
     * @returns {Number || null}
     */
    convertCostForSave(value) {
      if (!isNumber(value)) return null
      return toCostPerKg(value, this.weightPreference).toFixed(2)
    },
  },
}
