<template>
  <component
    :is="tag"
    :class="['filterChip', disabled && 'filterChip--disabled']"
  >
    <div
      class="filterChip-content"
      :aria-busy="isLoading"
    >
      <slot />
    </div>

    <button
      v-if="!disabled && removable"
      v-test="'chip-remove'"
      class="button button--icon filterChip-control"
      @click.stop="$emit('remove')"
    >
      <SvgIcon name="24-ic-clear" />
    </button>
  </component>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    removable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default() {
        return 'div'
      },
    },
  },
  emits: ['remove'],
}
</script>

<style lang="scss" scoped>
.filterChip {
  padding: theme('spacing.2');
  margin: theme('spacing.1');
  display: inline-flex;
  gap: theme('spacing.1');
  align-items: center;
  background: theme('colors.white');
  color: theme('colors.acai.DEFAULT');
  border: 2px solid theme('colors.blueberry.DEFAULT');
  border-radius: theme('spacing.5');

  &--disabled {
    background-color: theme('colors.grey.100');
    border-color: theme('colors.slate.DEFAULT');
  }
}

.filterChip-content {
  flex: 1 1 auto;
}

.filterChip-control {
  flex: 0 1 auto;
  color: theme('colors.blueberry.DEFAULT');
  border-radius: 50%;
  padding: 0;

  &:hover {
    color: theme('colors.acai.DEFAULT');
  }

  &:focus {
    box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv') inset;
    outline: none;
  }
}
</style>
