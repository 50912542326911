<template>
  <LayoutDefault class="overflow-hidden">
    <template #siteNav>
      <SiteSelector @loaded="handleSitesLoaded" />
    </template>

    <Hero>
      <template #default>{{ $t('titles.wasteLogTimeline') }}</template>
      <template #hero-content>
        <i18n-t
          v-if="dateFrom && dateTo && selectedSite"
          keypath="messages.customTimeZone"
          tag="p"
          class="WL-timezone mb-2 mx-4"
          scope="global"
        >
          <span>{{ timezone }}</span>
        </i18n-t>
      </template>
    </Hero>

    <PageSection v-test="'waste-log-content'">
      <NoSiteSelected
        v-if="!selectedSite && !isLoading"
        :svg-id="'no-results-waste'"
      />
      <template v-else>
        <div
          v-if="isLoading"
          class="WL-loading"
        >
          <Loading-Spinner class="spinner--lg mx-auto my-8" />
        </div>

        <template v-else>
          <!-- Date selector and switch to list -->
          <SectionHeader class="-mx-4 px-4 pb-4 border-b border-b-slate mb-4">
            <template #section-navigation>
              <timeline-day-navigation
                :date="selectedDay"
                @date-change="applyDate"
              />
            </template>
            <template #section-navigation-actions>
              <div class="-mx-1">
                <router-link
                  class="button button--icon mx-1"
                  :to="{
                    name: 'waste-log-list',
                    params: { siteId: selectedSite.legacyId },
                    query: { start: $route.query.start, end: $route.query.end },
                  }"
                >
                  <SvgIcon name="24-ic-list" />
                </router-link>
                <router-link
                  class="button button--icon mx-1"
                  :to="{
                    name: 'waste-log-grid',
                    params: { siteId: selectedSite.legacyId },
                    query: { start: $route.query.start, end: $route.query.end },
                  }"
                >
                  <SvgIcon name="24-ic-cards" />
                </router-link>
              </div>
            </template>
          </SectionHeader>

          <!-- Tracker & totals -->
          <div
            v-if="trackers.length"
            class="mb-4 flex flex-between flex-wrap"
          >
            <div class="flex flex-middle">
              <div class="trackerSelect">
                <select
                  class="customSelect"
                  :value="selectedTracker"
                  @change="trackerChange($event.target.value)"
                >
                  <option
                    v-for="tracker in trackers"
                    :key="tracker"
                    :value="tracker"
                  >
                    {{ tracker }} ({{ trackerData[tracker].length }})
                  </option>
                </select>
              </div>
              <div class="WL-grid-view-filters customControl customControl--checkbox">
                <input
                  id="WL-onlyImages"
                  :checked="onlyImages"
                  type="checkbox"
                  class="customControl-input"
                  @change="imagesOnlyToggle"
                />
                <label
                  data-test-WL-only-images-label
                  class="customControl-label"
                  for="WL-onlyImages"
                  >{{ $t('wasteGrid.label.imagesDisplay') }}</label
                >
              </div>
            </div>
            <div
              v-if="totals"
              class="mt-2"
              v-html="
                $t('wasteTimeline.messages.totals', {
                  weight: totals.weight,
                  cost: totals.cost,
                  transactions: transactions.length,
                })
              "
            ></div>
          </div>

          <!-- No results placeholder -->
          <NoWasteResults
            v-if="!isLoading && !transactions.length"
            :svg-id="'no-results'"
          />

          <!-- Main content -->
          <template v-else>
            <!-- Timeline Carousel -->
            <timeline-carousel
              v-if="selectedTransaction && carouselData.length"
              :items="carouselData"
              :selected-id="selectedTransaction.id"
              @change="transactionChange"
              @image-loaded="imageLoaded"
            />
            <!-- Carousel placeholder for when there are no transactions with images -->
            <template v-else>
              <div class="WL-noResults">
                <div class="text-center text-acai py-8">
                  <div class="h2">{{ $t('messages.noTransactionsWithImages') }}</div>
                  <SvgIcon
                    class="icon--illustration my-8 mx-auto"
                    name="no-results"
                  />
                </div>
              </div>
            </template>

            <!-- Timeline info -->
            <timeline-info
              v-if="selectedTransaction && transactions.length"
              :transaction="selectedTransaction"
              :weight-unit="weightUnit"
            >
              <router-link
                v-if="previousTransaction"
                :to="{ query: { ...$route.query, transaction: previousTransaction.id } }"
                class="button button--icon p-0"
              >
                <SvgIcon
                  name="48-ic-arrow-prev"
                  xl
                />
              </router-link>
              <router-link
                v-if="nextTransaction"
                :to="{ query: { ...$route.query, transaction: nextTransaction.id } }"
                class="button button--icon p-0"
              >
                <SvgIcon
                  name="48-ic-arrow-next"
                  xl
                />
              </router-link>

              <router-link
                class="button button--primary"
                :to="{
                  name: 'waste-log',
                  params: $route.params,
                  query: {
                    openItem: selectedTransaction.id,
                    start: selectedTransaction.createdDate,
                    end: selectedTransaction.createdDate,
                    returnPath: $route.fullPath,
                  },
                }"
                >{{ $t('actions.editTransaction') }}</router-link
              >
            </timeline-info>

            <!-- Chart representing a timeline of waste transactions  -->
            <timeline-weight-value-chart
              v-if="selectedTransaction && transactions.length && dateFrom && dateTo"
              :data-points="chartData"
              :selected-id="selectedTransaction.id"
              :start="dateFrom.toDate()"
              :end="dateTo.toDate()"
              :focused-time="focusedTime"
              :timezone="timezone"
              :weight-unit="$t('settings.weight.short.' + weightUnit)"
              :weight-label="`Weight(${$t('settings.weight.short.' + weightUnit)})`"
              :value-label="`Value (${selectedSite.currency})`"
              :currency="selectedSite.currency"
              @change="transactionChange"
              @focus-time="focusTimeChange"
              @timeline-pan="focusTimeChange"
            />
          </template>
        </template>
      </template>
    </PageSection>
  </LayoutDefault>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ref, computed } from 'vue'

import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import SiteSelector from '@/components/SiteSelector/SiteSelector.vue'
import NoSiteSelected from '@/components/NoSiteSelected/NoSiteSelected.vue'
import NoWasteResults from '@/components/NoWasteResults/NoWasteResults.vue'
import TimelineInfo from '@/pages/WasteTimeline/Timeline/timeline-info.vue'
import TimelineCarousel from '@/pages/WasteTimeline/Timeline/timeline-carousel.vue'
import TimelineWeightValueChart from '@/pages/WasteTimeline/Timeline/timeline-weight-value-chart.vue'
import TimelineDayNavigation from '@/pages/WasteTimeline/Timeline/timeline-day-navigation.vue'
import moment from 'moment-timezone'
import { useToast } from 'vue-toastification'
import { fromGrams } from '@/utils/weight'
import { isEqual } from 'lodash'
import { DATE_FORMAT } from '@/store/constants'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useStore } from 'vuex'
export default {
  components: {
    LayoutDefault,
    Hero,
    SiteSelector,
    NoSiteSelected,
    NoWasteResults,
    TimelineInfo,
    TimelineCarousel,
    TimelineWeightValueChart,
    TimelineDayNavigation,
  },
  beforeRouteUpdate(to, from, next) {
    next()
    // https://winnow.atlassian.net/browse/HUB-3579
    // we're tracking historic transactions in order to see if we impact anyone by archiving data older than a year
    if (to.query.day && moment(to.query.day).isBefore(moment().subtract(1, 'years'))) {
      this.analytics.track('Historic transaction lookup')
    }
  },
  setup() {
    const { getters } = useStore()
    const userId = computed(() => getters['auth/userId'])
    const { selectedSite } = useSelectedSite({ userId: userId.value })
    const isLoading = ref(true)

    return {
      toast: useToast(),
      selectedSite,
      isLoading,
    }
  },
  data() {
    return {
      // passed to datepicker
      dateFormat: DATE_FORMAT,
    }
  },

  computed: {
    ...mapGetters({
      userSettings: 'auth/userSettings',
    }),
    ...mapGetters('transactions', [
      'byId',
      'image',
      'meta',
      'transactions',
      'selectedTransaction',
      'trackerData',
      'totalWeight',
      'totalCost',
      'trackers',
    ]),
    ...mapState('transactions', ['selectedTracker', 'selectedDay', 'focusedTime']),
    selectedTransactionIndex() {
      return this.carouselData.findIndex((d) => d.id === this.selectedTransaction.id)
    },
    nextTransaction() {
      return this.carouselData[this.selectedTransactionIndex + 1]
    },
    previousTransaction() {
      return this.carouselData[this.selectedTransactionIndex - 1]
    },
    dateFrom() {
      return moment(this.selectedDay).startOf('day')
    },
    dateTo() {
      return moment(this.selectedDay).endOf('day')
    },

    chartData() {
      return (this.trackerData[this.selectedTracker] || []).map((t) => {
        // const step = 1
        // const timestamp = t.createdDate
        const d = new Date(t.createdDate)

        // d.setMinutes(Math.floor(d.getMinutes() / step) * step)
        d.setSeconds(0)
        d.setMilliseconds(0)
        let imageSrc = this.image(t.id)

        return {
          ...t,
          date: d,
          weight: parseFloat(fromGrams(t.weight.value, this.weightUnit).toFixed(2)),
          imageSrc,
        }
      })
    },

    carouselData() {
      const transactions = this.chartData
      return this.onlyImages
        ? transactions.filter((transaction) => !!transaction.imageId)
        : this.chartData
    },

    hasResults() {
      return this.meta && this.meta.total > 0
    },

    timezone() {
      return this.selectedSite ? this.selectedSite.timeZone : null
    },

    weightUnit() {
      return this.userSettings.weight.code
    },

    onlyImages() {
      const { onlyImages } = this.$route.query

      return !onlyImages ? true : onlyImages === 'true'
    },

    totals() {
      if (!this.transactions.length) {
        return
      }

      const shorthand = this.$t('settings.weight.short.' + this.weightUnit)
      const convertedWeight = fromGrams(this.totalWeight, this.weightUnit).toFixed(2)

      return {
        weight: `${convertedWeight} ${shorthand}`,
        cost: `${this.totalCost.toFixed(2)} ${this.transactions[0].currency}`,
      }
    },
  },

  watch: {
    '$route.query.day'(to, from) {
      if (to !== from) {
        this.getData().then(this.setupDefaults)
      }
    },
    '$route.query.transaction'(to, from) {
      if (to !== from) {
        this.$store.commit('transactions/SET_SELECTED_TRANSACTION', to)
      }
    },
  },

  unmounted() {
    moment.tz.setDefault()
  },

  methods: {
    handleSitesLoaded() {
      this.isLoading = false
      if (!this.selectedSite) return

      /**
       * Setup timezone and get data if selected site is available
       * otherwise, WATCH for selectedSite and get the timezone and data when we have it
       *
       * We need to do this because we don't have selectedSite on the first load as that
       * depends on the sites data request. Only when we have all the sites can we get
       * the selected site details like timezone
       *
       * This issue is especially a problem when loading the waste log via custom URL which
       * may contain a different siteID than what the current user has saved
       */

      moment.tz.setDefault(this.timezone)
      this.interpretUrl()
    },
    interpretUrl() {
      const day = this.formatDateForSaving(this.$route.query.day)

      // Navigating from a page that has data to a page that doesn't have data, trackers will be empty.
      this.applyDate(day).then(this.getData).then(this.setupDefaults)
    },

    getData() {
      this.isLoading = true
      return this.$store
        .dispatch('transactions/getTransactions', {
          filter: {
            start: this.dateFrom.toISOString(),
            end: this.dateTo.toISOString(),
            site: this.selectedSite.legacyId,
          },
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setupDefaults() {
      const { day, tracker, transaction } = this.$route.query

      const defaultTracker = tracker || this.trackers[0]
      this.$store.commit('transactions/SET_SELECTED_TRACKER', defaultTracker)

      const trackerData = this.trackerData[defaultTracker]

      if (defaultTracker) {
        const accessedTransaction =
          trackerData.find(({ id }) => id === transaction) || trackerData[0]
        // select default transaction from the new selected tracker
        let defaultTransaction = this.selectTransaction({
          transaction: accessedTransaction,
          data: trackerData,
          onlyImages: this.onlyImages,
        })
        this.$store.commit('transactions/SET_SELECTED_TRANSACTION', defaultTransaction.id)
        this.updateRoute({
          day: day,
          tracker: defaultTracker,
          transaction: defaultTransaction.id,
          onlyImages: this.onlyImages,
        })
      }
    },

    formatDateForSaving(date) {
      // make sure that the is always saved in the same format
      // and does not rely on the locale
      return moment(date).toISOString()
    },

    applyDate(day) {
      this.$store.commit('transactions/SET_SELECTED_DAY', this.formatDateForSaving(day))
      return this.updateRoute({
        day: this.formatDateForSaving(day),
      })
    },

    transactionChange(id) {
      const { imageId } = this.byId(id)

      this.$store.commit('transactions/SET_SELECTED_TRANSACTION', id)

      // if user clicks on a transaction with no image
      // from chart we should uncheck the only images checkbox
      this.updateRoute({
        transaction: id,
        onlyImages: Boolean(this.onlyImages && imageId),
      })
    },
    trackerChange(id) {
      const trackerData = this.trackerData[id]
      const transaction = trackerData[0]
      // select default transaction from the new selected tracker
      const defaultTransaction = this.selectTransaction({
        transaction,
        data: trackerData,
        onlyImages: this.onlyImages,
      })

      const defaultTracker = this.trackers.find((t) => t === id) || this.trackers[0]
      this.$store.commit('transactions/SET_SELECTED_TRACKER', defaultTracker)

      this.updateRoute({
        transaction: defaultTransaction.id,
        tracker: id,
      })
    },
    selectTransaction({ transaction, data, onlyImages }) {
      const dateToCompare = transaction.createdDate
      let selectedTransaction = transaction

      // If the only image option is checked we find and select the closest
      // transaction with image, otherwise, we just return the provided transaction.
      // This is because we still need to keep a transaction selected for the chart

      if (onlyImages) {
        selectedTransaction =
          data
            .sort(
              (a, b) =>
                Math.abs(moment(dateToCompare).diff(moment(a.createdDate))) -
                Math.abs(moment(dateToCompare).diff(moment(b.createdDate)))
            )
            .find((item) => item.imageId) || selectedTransaction
      }

      return selectedTransaction
    },
    imagesOnlyToggle(event) {
      const { checked } = event.target
      // in case that user is focused on a transaction without image and click on the checkbox to
      // only see transactions with images we should find and select the closest transaction with image
      const defaultTransaction = this.selectTransaction({
        transaction: this.selectedTransaction,
        data: this.chartData,
        onlyImages: checked,
      })

      this.updateRoute({
        onlyImages: checked,
        transaction: defaultTransaction.id,
      })
    },
    updateRoute(query) {
      let newQuery = { ...this.$route.query, ...query }
      if (!isEqual(newQuery, this.$route.query)) {
        return this.$router.push({ query: newQuery })
      }
      return Promise.resolve()
    },
    imageLoaded({ id, src }) {
      this.$store.commit('transactions/STORE_IMAGE', { id, src })
    },
    focusTimeChange({ start, end }) {
      this.$store.commit('transactions/SET_FOCUSED_TIME', { start, end })
    },
  },
}
</script>

<style lang="scss">
.trackerSelect {
  margin-right: theme('spacing.4');
}
</style>
