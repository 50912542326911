<template>
  <div>
    <div class="flex flex-middle flex-between mb-3">
      <!-- Title and edit -->
      <slot name="title" />
      <button
        v-if="isReportEnabled && storeSubscribers.length && !isAddMode"
        data-test-subscribers-edit-button
        class="button button--link"
        @click="toggleEdit('REMOVE')"
      >
        {{
          $t(`organisations.sendingControls.actions.${isRemoveMode ? 'cancelEdit' : 'editList'}`)
        }}
      </button>
    </div>

    <!--Current Subscribers List -->
    <ul :class="['flex flex-wrap flex-middle', hasChanges && 'box-content-separation pb-3']">
      <filter-chip
        v-for="subscriber in displayedSubscribers"
        :key="subscriber.id"
        data-test-subscriber-chip
        tag="li"
        :disabled="!isReportEnabled"
        :removable="isRemoveMode"
        @remove="addInPending(subscriber)"
      >
        {{ subscriber.email }}
      </filter-chip>

      <!-- Add button -->
      <li v-if="isReportEnabled && !isRemoveMode">
        <button
          data-test-subscribers-add-subscriber
          class="button button--link px-3 py-2"
          @click="toggleEdit('ADD')"
        >
          <SvgIcon
            :class="['icon', isAddMode && 'rotate-45']"
            name="24-ic-add"
          />
          <span class="font-bold">{{
            $t(isAddMode ? 'actions.close' : 'organisations.sendingControls.actions.add')
          }}</span>
        </button>
      </li>
    </ul>

    <!-- Pending list -->
    <div v-if="hasChanges">
      <h4
        data-test-subscribers-pending-message
        class="mb-3"
      >
        {{
          $t(
            `organisations.sendingControls.messages.${
              isAddMode ? 'pendingAddItems' : 'pendingRemoveItems'
            }`
          )
        }}
      </h4>
      <ul class="flex flex-wrap">
        <li
          v-for="(subscriber, index) in pendingSubscribers"
          :key="subscriber.id"
        >
          <filter-chip
            :key="subscriber.id"
            data-test-subscribers-pending-chip
            tag="li"
            :disabled="!isReportEnabled"
            :removable="true"
            @remove="pendingSubscribers.splice(index, 1)"
          >
            {{ subscriber.email }}
          </filter-chip>
        </li>
      </ul>
    </div>
    <form
      v-if="isAddMode"
      action="/"
      class="form-layout--x3 mt-3"
    >
      <div>
        <label :for="`search-${namespace}-filed`">{{
          $t('organisations.subscribers.actions.addAnother')
        }}</label>

        <search-subscribers
          :id="`search-${namespace}-filed`"
          data-test-subscribers-search
          :model-value="subscribers.concat(pendingSubscribers)"
          :location-id="locationId"
          @update:model-value="addInPending"
        />
      </div>
    </form>

    <!-- Buttons Bar -->
    <div
      v-if="isReportEnabled"
      class="flex flex-top flex-between mt-8"
    >
      <div class="buttonGroup">
        <ButtonSave
          data-test-subscribers-submit-btn
          type="button"
          :disabled="!hasChanges || savingStatus === 'SAVING'"
          :is-saving="savingStatus === 'SAVING'"
          @click="saveData"
        >
          {{ $t('actions.saveChanges') }}
        </ButtonSave>

        <button
          :disabled="!hasChanges"
          type="button"
          class="button button--secondary"
          @click="$refs.confirmationModal.open()"
        >
          <span>{{ $t('actions.cancel') }}</span>
        </button>
      </div>
      <slot name="config-route" />
    </div>

    <template v-else>
      <div class="flex flex-middle mt-3">
        <SvgIcon
          class="text-carrot mr-2"
          name="24-ic-info"
        />
        <p data-test-subscribers-disabled-report>
          {{ $t('organisations.subscribers.messages.disabledReports') }}
        </p>
      </div>
      <slot name="config-route" />
    </template>

    <!-- Edit Confirmation Modal -->
    <Modal ref="confirmationModal">
      <template #default="{ close }">
        <div class="modal-body">
          {{ $t('organisations.sendingControls.messages.confirmation') }}
          <div class="buttonGroup flex-center mt-4">
            <button
              class="button button--secondary"
              @click="close"
            >
              <SvgIcon name="24-ic-clear" />
              <span>{{ $t('actions.cancel') }}</span>
            </button>
            <button
              type="button"
              class="button button--primary"
              @click="
                () => {
                  pendingSubscribers = []
                  editMode = null
                  close()
                }
              "
            >
              <SvgIcon name="24-ic-check" />
              <span>{{ $t('actions.confirm') }}</span>
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { clone } from 'lodash'

import { useToast } from 'vue-toastification'
import FilterChip from '@/components/FilterChip.vue'
import Modal from '@/components/Modal.vue'
import { capitalize } from '@/utils/string'

import SearchSubscribers from './SearchSubscribers.vue'
import ButtonSave from '@/components/ButtonSave.vue'

export default {
  components: {
    FilterChip,
    SearchSubscribers,
    Modal,
    ButtonSave,
  },
  props: {
    locationId: String,
    namespace: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      editMode: null, // "ADD" "REMOVE"
      savingStatus: 'IDLE',
      subscribers: [],
      pendingSubscribers: [],
    }
  },

  computed: {
    location() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId)
    },
    storeSubscribers() {
      return this.location[this.namespace].list
    },
    isReportEnabled() {
      return this.location[this.namespace].isReportEnabled
    },
    hasChanges() {
      return !!this.pendingSubscribers.length
    },
    isRemoveMode() {
      return this.editMode === 'REMOVE'
    },
    isAddMode() {
      return this.editMode === 'ADD'
    },
    displayedSubscribers() {
      // filter out the subscribers that are in pending (they are the subscribers to be removed)
      return this.subscribers.filter(
        (filteredItem) => !this.pendingSubscribers.find((item) => item.id === filteredItem.id)
      )
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.subscribers = clone(this.storeSubscribers)
    },
    saveData() {
      this.savingStatus = 'SAVING'

      this.$store
        .dispatch(`hierarchy/nodes/saveNode${capitalize(this.namespace)}`, {
          nodeId: this.locationId,
          payload: this.isRemoveMode
            ? // for remove mode we just send the displayed subscribers they are alredy filtered
              this.displayedSubscribers
            : // for add mode we need to concat the current subscribers with what we have in pending
              this.subscribers.concat(this.pendingSubscribers),
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
          this.init()
          this.pendingSubscribers = []
          this.editMode = null
        })
        .catch((e) => {
          this.toast.error(this.$t('toast.error.saveData'))
          this.savingStatus = 'ERROR'
          this.init()
          throw e
        })
    },

    toggleEdit(type) {
      if (this.editMode === type) {
        if (this.pendingSubscribers.length) {
          this.$refs.confirmationModal.open()
        } else {
          this.editMode = null
        }
      } else {
        this.editMode = type
      }
    },

    addInPending(item) {
      this.pendingSubscribers.push({ ...item })
    },
  },
}
</script>
