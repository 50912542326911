import api from '@/store/api/menus'

const state = {
  defaultFacet: null,
  records: {},
}
const getters = {
  parentChildConflictsById: (state) => (ids) =>
    ids.map((id) => ({ id, conflicts: state.records[id] })),
}

const actions = {
  getConflicts({ commit, dispatch }, { menuId }) {
    return api
      .fetchParentChildConflicts({ menuId })
      .then(({ foodItemRecords, records, defaultFacet }) => {
        commit('menu-management/food-items/STORE_FOOD_ITEMS', foodItemRecords, {
          root: true,
        })
        commit('STORE_PARENT_CHILD_CONFLICTS', records)
        commit('STORE_DEFAULT_FACET', defaultFacet)
        dispatch(
          'menu-management/menus/updateParentChildConflictsInMenu',
          { menuId, parentChildConflictIds: Object.keys(records) },
          {
            root: true,
          }
        )
      })
  },
}
const mutations = {
  STORE_PARENT_CHILD_CONFLICTS(state, data) {
    state.records = data
  },
  STORE_DEFAULT_FACET(state, props) {
    state.defaultFacet = props
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
