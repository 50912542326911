<template>
  <fieldset class="formGroup">
    <legend class="label font-bold">{{ $t('menus.tabletEditor.images.labels.tags') }}</legend>
    <validation-group
      class="formGroup"
      :validator="v$.newTag"
      v-slot="{ hasAnyErrors }"
    >
      <label for="newTag">{{ $t('menus.tabletEditor.images.labels.newTag') }}</label>
      <div class="formGroup--inline">
        <input
          id="newTag"
          v-model.trim="newTag"
          type="text"
          class="formControl"
          :class="{ 'is-invalid': hasAnyErrors }"
          data-test-image-editor-tag-field
          @keydown.enter.prevent="addTag(newTag)"
        />
        <button
          type="button"
          class="button button--primary"
          data-test-image-editor-add-tag
          @click="addTag(newTag)"
        >
          <SvgIcon name="24-ic-add" />
          <span>{{ $t('actions.add') }}</span>
        </button>
      </div>
    </validation-group>
    <output
      class="formGroup"
      name="Tags"
      for="newTag"
      data-test-image-editor-tags
    >
      <ul class="chip-container">
        <chip
          v-for="(tag, index) in modelValue"
          :key="tag"
          tag="li"
          :removable="true"
          @remove="removeTagAtIndex(index)"
          >{{ tag }}</chip
        >
      </ul>
    </output>
  </fieldset>
</template>

<script>
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import Chip from '@/components/Chip/Chip.vue'
import { useVuelidate } from '@vuelidate/core'

export default {
  components: {
    ValidationGroup,
    Chip,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    allowOnlyLowerCase: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup: () => {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      newTag: '',
      tags: this.modelValue,
    }
  },
  watch: {
    modelValue(val) {
      this.tags = val
    },
    newTag: {
      handler(val) {
        if (this.allowOnlyLowerCase) {
          this.v$.newTag.$model = val.toLowerCase()
        }
      },
    },
  },
  validations() {
    return {
      newTag: { noDuplicateTag: this.noDuplicateTag, lowerCase: this.onlyLowerCase },
    }
  },
  methods: {
    addTag(tag) {
      this.v$.newTag.$touch()
      if (this.v$.newTag.$invalid) return
      if (tag) {
        this.tags.push(tag)
        this.newTag = ''
        this.$emit('update:modelValue', this.tags)
      }
    },
    removeTagAtIndex(index) {
      this.tags.splice(index, 1)
      this.$emit('update:modelValue', this.tags)
    },
    noDuplicateTag(newTag) {
      if (this.allowOnlyLowerCase) {
        return !this.tags.find((tag) => tag.toLowerCase() === newTag.toLowerCase())
      }
      return !this.tags.find((tag) => tag === newTag)
    },
    onlyLowerCase(tag) {
      if (!this.allowOnlyLowerCase) return true
      return tag === tag.toLowerCase()
    },
  },
}
</script>

<style></style>
