<template>
  <page-section>
    <section-header>
      <template
        v-if="!readonly"
        #section-title
      >
        {{ $t('menus.foodItems.title.editAttributes') }}
      </template>
      <template
        v-else
        #section-title
      >
        {{ $t('menus.foodItems.title.viewAttributes') }}
      </template>
      <template
        v-if="menuId && foodItem"
        #section-navigation
      >
        <button
          type="button"
          class="button button--secondary"
          @click="goBack({ hash: foodItem.id })"
        >
          <SvgIcon
            class="inline"
            name="24-ic-back"
          />
          <span>{{ $t('actions.return') }}</span>
        </button>
      </template>
    </section-header>
    <food-item-edit-form
      class="-mx-4"
      :food-item-id="foodItemId"
      :menu-id="menuId"
      :readonly="readonly"
      data-test-editor
      @cancel="onCancel"
      @save="onSave"
      @delete="onCancel"
    />
  </page-section>
</template>

<script>
import FoodItemEditForm from '@/pages/Menus/FoodItems/FoodItemEditForm.vue'
import returnPathMixin from '@/mixins/returnPathMixin.js'
import menuMixin from '@/pages/Menus/menuMixin'
import { useToast } from 'vue-toastification'

export default {
  components: {
    FoodItemEditForm,
  },
  mixins: [returnPathMixin, menuMixin],
  props: {
    foodItemId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  computed: {
    menuId() {
      return this.$route.query.menuId
    },
    readonly() {
      return (this.menu && this.menu.archived) || !this.$can('manage', 'menus')
    },
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
  },
  methods: {
    onSave({ itemId, menuId, data, onError }) {
      this.$store
        .dispatch('menu-management/food-items/saveItem', { itemId, menuId, data })
        .then(() => {
          let foodItemName = this.foodItem.name
          this.goBack()
          this.$nextTick(() => {
            // added a div and a closure here because `this.foodItem.name` gets lost for some reason while going
            // from one route to another
            this.toast.success(
              this.$t('menus.foodItem.messages.updateSuccessful', {
                item: foodItemName,
              })
            )
          })
        })
        .catch((errorObj) => {
          onError(errorObj)
        })
    },
    onCancel() {
      this.goBack({
        hash: this.foodItem.id,
        backupRoute: {
          name: 'food-items',
          params: {
            menuId: this.menuId,
          },
        },
      })
    },
  },
}
</script>

<style></style>
