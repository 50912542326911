export default function sendMenu(newData) {
  return {
    archived: newData.archived,
    id: newData.id,
    name: newData.name,
    organisationId: newData.organisation && newData.organisation.id,
    description: newData.description,
    created: newData.created,
    lastUpdated: newData.modified,
    deployed: newData.deployed,
    currency: newData.currency,
    vision: newData.vision,
    locale: newData.locale,
    foodGroupIds: newData.foodGroupIds,
    stageIds: newData.stageIds,
    foodItemIds: newData.foodItemIds,
    alphabeticallySortedChildren:
      newData.manualOrdering !== undefined ? !newData.manualOrdering : undefined,
  }
}
