export const ORG_LEVELS = 16
export const PAGESIZE_GLOBAL = 100
export const WINNOW_ORG_ID = '10000'
export const WINNOW_L2_SUPPORT = 'L2Support'
export const WINNOW_GLOBAL_ADMIN = 'GlobalAdmin'

export const PAGESIZE_TERMS = 100
export const PAGESIZE_TERM_SEARCH = 100
export const PAGESIZE_FACETS = 100
export const PAGESIZE_FACET_SEARCH = 100
export const PAGESIZE_MENUS = 20
export const PAGE_SIZE_MENU_ITEMS = 100

export const INGREDIENTS_FACET_ID = '102644'
export const INGREDIENTS_FACET_CODE = 'F04'

export const TOAST_DISMISS_DELAY = 4000
export const DEBOUNCE_DELAY = 400
export const EN_LOCALE = 'en-GB'
export const WEIGHT_METRIC_KEY = 'Metric'
export const WEIGHT_US_STANDARD_KEY = 'US_Standard'

export const MENU_STATES = {
  CREATING_ITEMS: 'create-menu-items',
  TRANSLATE: 'correct-translations',
  PROCESSED: 'menu-processed',
  ERROR: 'menu-upload-error',
}

export const DATE_FORMAT = 'D MMM YYYY'
export const TIME_FORMAT = 'HH:mm'
export const TIME_DATE_FORMAT = 'HH:mm D MMM YYYY'
export const DATE_FORMAT_TRANSACTION_IMAGE = 'DD-MM-YYYY_hh-mm'
export const DATE_FORMAT_ISO_8601 = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT_INTL = 'PPPP'
// this is needed because v-calendar uses this particular regex to validate timezones
// @TODO: remove once v-calendar updates and checks timezones using the browser API
export const IANA_TIMEZONE_CHECK = /(UTC|GMT|(?:[a-zA-Z]+\/[a-zA-Z_]+(?:\/[a-zA-Z_]+)?))$/
export const VALID_TIMEZONE_MAPPINGS = {
  CET: 'Europe/Paris',
  CST6CDT: 'America/Chicago',
  EET: 'Europe/Sofia',
  Egypt: 'Africa/Cairo',
  Eire: 'Europe/Dublin',
  EST: 'America/Cancun',
  EST5EDT: 'America/New_York',
  GB: 'Europe/London',
  'GB-Eire': 'Europe/Dublin',
  HST: 'Pacific/Honolulu',
  Hongkong: 'Asia/Hong_Kong',
  Iceland: 'Africa/Abidjan',
  Iran: 'Asia/Tehran',
  Israel: 'Asia/Jerusalem',
  Jamaica: 'America/Jamaica',
  Japan: 'Asia/Tokyo',
  Kwajalein: 'Pacific/Kwajalein',
  Libya: 'Africa/Tripoli',
  MET: 'Europe/Paris',
  MST: 'America/Phoenix',
  MST7MDT: 'America/Denver',
  NZ: 'Pacific/Auckland',
  'NZ-CHAT': 'Pacific/Chatham',
  Navajo: 'America/Denver',
  Poland: 'Europe/Warsaw',
  Portugal: 'Europe/Lisbon',
  PRC: 'Asia/Shanghai',
  PST8PDT: 'US/Pacific',
  ROC: 'Asia/Taipei',
  ROK: 'Asia/Seoul',
  Singapore: 'Asia/Singapore',
  Turkey: 'Europe/Istanbul',
  UCT: 'UTC',
  Universal: 'UTC',
  'W-SU': 'Europe/Moscow',
  WET: 'GMT',
  Zulu: 'UTC',
}

export const MENU_STATE_CREATING_ITEMS = 'create-menu-items'
export const MENU_STATE_TRANSLATE = 'correct-translations'
export const MENU_STATE_PROCESSED = 'menu-processed'
export const MENU_STATE_ERROR = 'menu-upload-error'
export const MENU_STATE_POLLING_INTERVAL = 2000

export const FOOD_ITEM_STATES = {
  PROCESSING: 'IN_PROGRESS',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING_APPROVAL',
  FAILED: 'VALIDATION_FAILED',
  UNMAPPED: 'UNMAPPED',
}

export const MENU_ITEM_TYPES = {
  FOOD_ITEM: 'FOOD_ITEM',
  STAGE: 'STAGE',
  FOOD_GROUP: 'FOOD_GROUP',
}

export const BASE_URLS = {
  BACKDATE: '/waste-log/backdate',
  MENU_MANAGEMENT: '/menu-management',
}

export const ORG_NODE_STATUSES = {
  SETUP: 'SETUP',
  BASELINE: 'BASELINE',
  SUCCESS: 'SUCCESS',
  LIVE: 'LIVE',
  INACTIVE: 'INACTIVE',
}

// Breakpoints
export const WINDOW_WIDTH_SM = 480
