<template>
  <div class="TreeBox fixedColumn flex-expand">
    <div class="fixedColumn-main">
      <slot name="main" />
    </div>
    <div class="fixedColumn-column">
      <div class="sticky-top TreeBox-details">
        <slot name="column" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actionBarStyle: {
        width: 'auto',
      },
    }
  },
  mounted() {
    this.handleResize()
  },
  methods: {
    handleResize() {
      const el = document.querySelector('.fixedColumn-main')
      this.actionBarStyle.width = el.offsetWidth + 'px'
    },
  },
}
</script>
<style lang="scss">
.fixedColumn {
  display: flex;
}

.fixedColumn-main {
  flex: 1 1 auto;
  min-width: 0;
}

.fixedColumn-column {
  flex: 0 0 auto;
  width: to-rem(500px);
  border-left: 1px solid theme('colors.slate.DEFAULT');
}

.TreeBox {
  background: theme('colors.white');
}

.TreeBox--border-top {
  border-top: 1px solid theme('colors.slate.DEFAULT');
}

.TreeBox--fancy {
  border-radius: theme('borderRadius.lg');
  box-shadow: rgb(0, 0, 0, 0.1);
}

.TreeBox-details {
  padding: theme('spacing.4');
}
</style>
