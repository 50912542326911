export default function (overwrites) {
  return {
    id: null,
    name: null,
    nameEnglish: null,
    displayName: null,
    displayOrder: null,
    imageId: null,
    custom1Id: null,
    foodGroupIds: [],
    manualOrdering: true,
    ...overwrites,
  }
}
