<template>
  <fieldset
    class="image-selector"
    data-test-image-selector
  >
    <p class="formGroup -mt-3 font-bold">
      {{ $t('titles.chooseAnotherimage') }}
    </p>

    <div class="formGroup flex flex-between flex-wrap">
      <div class="formGroup formGroup--inline">
        <label for="imageSearch">{{ $t('labels.searchLibrary') }}</label>
        <input
          id="imageSearch"
          v-model="searchTerm"
          type="search"
          class="formControl"
          @input="debouncedSearch($event.target.value)"
        />
      </div>
      <div class="formGroup--inline gap-4">
        <div>{{ $t('labels.include') }}</div>
        <div class="customControl customControl--checkbox">
          <input
            id="ImageAvailability"
            v-model="imageAvailability"
            type="checkbox"
            name="imageAvailability"
            class="customControl-input"
            @change="getImages({ reload: true })"
          />
          <label
            for="ImageAvailability"
            class="customControl-label"
            >{{ $t('labels.imageAvailability') }}</label
          >
        </div>
        <div class="customControl customControl--checkbox">
          <input
            id="PhotosFilter"
            v-model="imageTypePhoto"
            type="checkbox"
            name="imageTypePhoto"
            class="customControl-input"
            :disabled="!imageTypeIcon"
            @change="getImages({ reload: true })"
          />
          <label
            for="PhotosFilter"
            class="customControl-label"
            >{{ $t('labels.photos') }}</label
          >
        </div>

        <div class="customControl customControl--checkbox">
          <input
            id="IconsFilter"
            v-model="imageTypeIcon"
            type="checkbox"
            name="imageTypeIcon"
            class="customControl-input"
            :disabled="!imageTypePhoto"
            @change="getImages({ reload: true })"
          />
          <label
            for="IconsFilter"
            class="customControl-label"
            >{{ $t('labels.icons') }}</label
          >
        </div>
      </div>
    </div>

    <div class="image-selector-carousel">
      <button
        v-if="prevAvailable"
        type="button"
        class="image-selector-carousel-prev button button--secondary button--iconOutline"
        data-test-image-selector-load-prev
        @click="loadPrev"
      >
        <SvgIcon name="24-ic-arrow-prev" />
        <span class="sr-only">{{ $t('labels.imagesPagePrev') }}</span>
      </button>
      <div
        class="image-selector-carousel-scroll"
        :style="{ 'grid-template-columns': `repeat(${resultsSize}, minmax(${imageSize}px, 1fr))` }"
      >
        <div
          v-if="loadingStatus === 'LOADING'"
          class="image-selector-carousel-spinner"
          :style="{ 'grid-column': Math.round(resultsSize / 2) }"
        >
          <Loading-Spinner class="spinner--lg" />
        </div>
        <image-loader
          v-for="image in images"
          v-show="loadingStatus === 'LOADED' && totalResults"
          :key="image.id"
          :src="image.srcBlob || generateThumbnailUrl(image.imageUrl)"
          class="image-selector-carousel-item"
          :class="{ 'image-selector-carousel-active': image.id === activeId }"
          :aspect-ratio="0.65"
          data-test-image-selector-suggestion
          @image-loaded="onImageLoad(image.id, $event)"
          @click="activeId = image.id"
        />
      </div>
      <button
        v-if="nextAvailable"
        type="button"
        class="image-selector-carousel-next button button--secondary button--iconOutline"
        data-test-image-selector-load-next
        @click="loadNext"
      >
        <SvgIcon name="24-ic-arrow-next" />
        <span class="sr-only">{{ $t('labels.imagesPageNext') }}</span>
      </button>
    </div>
    <div
      v-if="totalResults"
      class="text-sm mb-4"
    >
      {{ $t('messages.resultsCount', { count: totalResults }) }}
    </div>
    <div
      v-if="loadingStatus === 'LOADED' && !totalResults"
      class="image-selector-carousel-no-results"
      data-test-image-selector-no-results
    >
      {{ $t('messages.noResults') }}
    </div>

    <!-- <div
      class="mb-2 text-blueberryLight"
      v-if="modelValue && activeImage && modelValue.id === activeImage.id"
      data-test-image-selector-source
    >
      <svg class="icon icon--sm"><use xlink:href="#check"></use></svg>
      {{ $t('labels.currentImage') }}
    </div> -->

    <template v-if="activeImageLoadingStatus === 'LOADED'">
      <template v-if="activeImage && activeImage.keywords.length">
        <h4>{{ $t('titles.keywords') }}</h4>
        <ul
          class="formGroup chip-container mb-4"
          data-test-image-selector-keywords
        >
          <chip
            v-for="keyword in activeImage.keywords"
            :key="keyword.name"
          >
            {{ keyword.name }}
            <template
              v-if="keyword.count > 1"
              #counter
            >
              {{ keyword.count }}
            </template>
          </chip>
        </ul>
      </template>

      <!-- <template v-if="activeImage && activeImage.taxonomyMappings.length">
      <h3>{{ $t('titles.taxonomyMappings') }}</h3>
      <ul class="formGroup mb-4" data-test-image-selector-mappings>
        <li v-for="label in activeImage.taxonomyMappings" :key="label">{{ label }}</li>
      </ul>
    </template> -->

      <template v-if="activeImage && activeImage.itemTypes.length">
        <h4>{{ $t('titles.itemTypes') }}</h4>
        <ul
          class="formGroup chip-container mb-4"
          data-test-image-selector-item-type
        >
          <chip
            v-for="itemType in activeImage.itemTypes"
            :key="itemType.name"
          >
            {{ $t('itemTypes.' + itemType.name) }}
            <template
              v-if="itemType.count > 1"
              #counter
            >
              {{ itemType.count }}
            </template>
          </chip>
        </ul>
      </template>
    </template>

    <div
      v-if="activeImage"
      class="flex flex-between flex-wrap flex-middle my-4"
    >
      <div class="image-selector-details">
        <image-loader
          :src="activeImage.srcBlob || generateSmallThumbnailUrl(activeImage.imageUrl)"
          :aspect-ratio="0.65"
        />
        <div data-test-image-selector-source>&copy; {{ activeImage.source }}</div>
      </div>

      <div class="buttonGroup">
        <button
          type="button"
          class="button button--secondary"
          data-test-image-selector-reset
          @click="$emit('close')"
        >
          <SvgIcon name="24-ic-clear" />
          <span>{{ $t('actions.cancel') }}</span>
        </button>
        <button
          type="button"
          class="button button--primary"
          :disabled="activeImage.id === modelValue.id"
          data-test-image-selector-save
          @click="saveNewImage"
        >
          <SvgIcon name="24-ic-check" />
          <span>{{ $t('actions.useImage') }}</span>
        </button>
      </div>
    </div>
    <div
      v-else
      class="flex flex-right"
    >
      <button
        class="button button--secondary"
        type="button"
        data-test-image-selector-cancel
        @click="$emit('close')"
      >
        <SvgIcon name="24-ic-clear" />
        <span>{{ $t('actions.cancel') }}</span>
      </button>
    </div>
  </fieldset>
</template>

<script>
import Chip from '@/components/Chip/Chip.vue'
import ImageLoader from '@/components/image-loader.vue'
import { isEqual } from 'lodash'
import imagesMixin from '@/mixins/images'
import { useToast } from 'vue-toastification'
export default {
  components: {
    Chip,
    ImageLoader,
  },
  mixins: [imagesMixin],
  props: {
    // {id, url}
    modelValue: Object,
    // {menuId, (stageId | foodGroupId | foodItemId)}
    urlParams: {
      type: Object,
      required: true,
    },
    orgId: String,
  },
  emits: ['close', 'update:modelValue', 'save'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      resultsPage: 0,
      resultsSize: 5,
      // kind of 8 rem
      imageSize: 8 * 16,
      imageTypePhoto: true,
      imageTypeIcon: true,
      imageAvailability: false,
      totalResults: 0,
      loadingStatus: 'IDLE',
      activeImageLoadingStatus: 'IDLE',
      activeId: this.modelValue.id,
      loadMap: {},
      pages: [],
      searchTerm: '',
    }
  },
  computed: {
    menuId() {
      return this.$route.params.menuId
    },
    stageId() {
      return this.$route.params.stageId
    },
    images() {
      let ids = this.pages[this.resultsPage]
      return this.$store.getters['menu-management/images/imagesById'](ids)
    },
    totalPages() {
      return this.totalResults / this.resultsSize
    },
    nextAvailable() {
      return this.resultsPage < this.totalPages - 1
    },
    prevAvailable() {
      return this.resultsPage > 0
    },
    activeImage() {
      return this.$store.getters['menu-management/images/imageById'](this.activeId)
    },
    imagesLoaded() {
      let statuses = Object.values(this.loadMap)
      let loadingItems = statuses.filter((status) => status === 'LOADING')
      return !loadingItems.length
    },
    imageTypeFilter() {
      let type = this.imageTypePhoto && this.imageTypeIcon
      if (!type) {
        type = (this.imageTypePhoto && 'photo') || (this.imageTypeIcon && 'icon')
      } else {
        type = undefined
      }
      return type
    },
  },
  created() {
    //get currently active image data independent of suggestions
    if (this.activeId) {
      this.activeImageLoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/images/getImage', { imageId: this.activeId })
        .then(() => {
          this.activeImageLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.activeImageLoadingStatus = 'ERROR'
          this.toast(this.$t('toast.error.getData'))
        })
    } else {
      this.activeImageLoadingStatus = 'LOADED'
    }
  },
  mounted() {
    this.resize()
    window.addEventListener('resize', this.debouncedResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedResize)
  },
  methods: {
    debouncedResize() {
      window.clearTimeout(this.debounce)
      this.debounce = window.setTimeout(this.resize, 300)
    },
    debouncedSearch() {
      window.clearTimeout(this.debounce)
      this.debounce = window.setTimeout(this.search, 300)
    },
    search() {
      this.getImages({ reload: true })
    },
    resize() {
      // calculate new chunk size and make it odd

      this.resultsSize = Math.floor(this.$el.clientWidth / this.imageSize)
      this.resultsSize -= (this.resultsSize % 2) + 1
      // save the first visizible item on page
      let firstOnPage = this.pages.length ? this.pages[this.resultsPage][0] : null
      // convert pages matrix to linear array
      let allResults = this.pages.reduce((results, page) => results.concat(page), [])
      // regroup the pages using the new chunk size
      let pages = []
      while (allResults.length) {
        pages.push(allResults.splice(0, this.resultsSize))
      }
      this.pages = pages
      // go to page that had the previously visible images
      if (firstOnPage) {
        this.resultsPage = this.pages.findIndex(
          (group) => !!group.find((image) => image.id === firstOnPage.id)
        )
      }
      // get any new images needed
      this.getImages()
    },
    getImages({ useSaved = true, reload = false } = {}) {
      if (!this.pages[this.resultsPage] || reload) {
        this.loadingStatus = 'LOADING'
      }
      if (reload) {
        this.pages = []
        this.resultsPage = 0
      }
      this.$store
        .dispatch('menu-management/images/getImages', {
          ...this.urlParams,
          useSaved,
          query: {
            page: this.resultsPage,
            size: this.resultsSize,
            filter: {
              type: this.imageTypeFilter,
              includeAllOrganisations: !this.imageAvailability,
              inOrganisation: this.orgId,
            },
            keyword: this.searchTerm || undefined,
          },
        })
        .then(({ imageIds, total }) => {
          this.totalResults = total
          if (!isEqual(imageIds, this.pages[this.resultsPage])) {
            this.pages[this.resultsPage] = imageIds
            this.resetLoadmap(imageIds)
          }
          if (!total) {
            this.loadingStatus = 'LOADED'
          }
        })
        .catch(() => {
          this.loadingStatus = 'ERROR'
        })
    },
    loadPrev() {
      this.resultsPage--
      this.getImages()
    },
    loadNext() {
      this.resultsPage++
      this.getImages()
    },
    onImageLoad(id, { srcBlob }) {
      this.$store.commit('menu-management/images/UPDATE_IMAGE', { id, srcBlob })
      this.loadMap[id] = 'LOADED'
      if (this.imagesLoaded) {
        this.loadingStatus = 'LOADED'
      }
    },
    resetLoadmap(ids) {
      this.loadMap = ids.reduce((map, id) => ({ ...map, [id]: 'LOADING' }), {})
      if (this.loadMap[this.activeId] && this.activeImage.srcBlob) {
        this.loadMap[this.activeId] = 'LOADED'
      }
    },
    saveNewImage() {
      this.$emit('update:modelValue', { id: this.activeId, url: this.activeImage.srcBlob })
      this.$emit('save', { id: this.activeId, url: this.activeImage.srcBlob })
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.image-selector {
  legend {
    float: left;
    margin-bottom: theme('spacing.4');
  }

  legend + * {
    clear: left;
  }
}

.image-selector-carousel {
  position: relative;

  &-scroll {
    display: flex;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: repeat(5, minmax(8rem, 1fr));
    margin: theme('spacing.4') calc(-1 * theme('spacing.2'));
    gap: theme('spacing.2');
  }

  &-item,
  &-spinner {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 8rem;
    max-width: 10rem;
    min-width: auto;
    margin: 0 theme('spacing.2');
    @supports (display: grid) {
      margin: 0;
      max-width: none;
    }
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      display: block;
      padding-bottom: 100%;
      float: left;
    }
  }

  &-item:hover {
    cursor: pointer;
  }

  &-spinner {
    width: 100%;
    flex-grow: 0;
    grid-column: 3;
    justify-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &-active {
    outline: 0.25rem solid theme('colors.lemon.DEFAULT');
  }

  img {
    position: relative;
    min-width: 100%;
    min-height: 65%; // this is basically for cypress tests where images don't get resized because they are mocked and the container sets them with height 0
  }

  &-next,
  &-prev {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background: theme('colors.white');
    width: 2rem;
    height: 2rem;
    justify-content: center;
  }

  &-next {
    right: -0.75rem;
  }

  &-prev {
    left: -0.75rem;
  }

  &-no-results {
    display: flex;
    padding: theme('spacing.8');
    justify-content: center;
    align-items: center;
  }
}

.image-selector-details {
  flex-grow: 1;
  font-size: theme('fontSize.sm');
  align-items: center;

  .image-loader {
    width: 4rem;
    height: auto;
    float: left;
    margin-right: 0.5rem;
  }
}
</style>
