<template>
  <div @keydown.esc.capture="$emit('close')">
    <ValidationGroup
      v-slot="{ hasAnyErrors }"
      data-test-name-validation-error
      :server-errors="serverErrors.organisationName"
    >
      <label
        class="sr-only"
        :for="`create-node-input-${level}`"
        >{{ $t('hierarchy.action.createNodeLabel') }}</label
      >
      <OrgCreateNameField
        :id="`create-node-name-${level}`"
        ref="nameField"
        v-model.trim="name"
        :class="[{ 'is-invalid': hasAnyErrors }]"
        :parent-id="parentId"
        :level="level"
        @keydown.enter.stop="handleCreateNode"
      />
    </ValidationGroup>

    <OrgPackageSelect
      v-if="level === ORG_LEVELS"
      :id="`create-node-package-${level}`"
      v-model="selectedPackage"
      class="mt-2"
    />

    <Loading-Spinner
      v-if="isLoading"
      class="spinner--sm mx-auto my-4"
    />
    <div
      v-else
      class="flex flex-between mt-2"
    >
      <button
        type="button"
        class="button button--secondary mr-1"
        @click="$emit('close')"
      >
        {{ $t('actions.cancel') }}
      </button>
      <button
        data-hierarchy-create-confirm-button
        :disabled="!name || (level === ORG_LEVELS && !selectedPackage)"
        type="button"
        class="button button--primary"
        @click="handleCreateNode"
      >
        {{ $t('actions.create') }}
      </button>
    </div>
    <ValidationResults
      v-test="'error-messages'"
      class="mt-2"
      :server-errors="serverErrors"
      :server-warnings="{}"
    />
  </div>
</template>
<script>
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'

import OrgPackageSelect from '@/components/OrgBrowser/OrgPackageSelect.vue'
import OrgCreateNameField from '@/components/OrgBrowser/OrgCreateNameField.vue'
import { ORG_LEVELS } from '@/store/constants'
import { useToast } from 'vue-toastification'
import { useOrgCreate } from './useOrgCreate'
import { ref } from 'vue'

export default {
  components: {
    OrgPackageSelect,
    OrgCreateNameField,
    ValidationGroup,
    ValidationResults,
  },

  props: {
    level: Number,
    parentId: String,
  },
  emits: ['close'],
  setup(props) {
    const name = ref('')
    const selectedPackage = ref(null)
    const { createNode, serverErrors } = useOrgCreate({
      parentId: props.parentId,
      level: props.level,
      selectedPackage,
      name,
    })
    return {
      toast: useToast(),
      createNode,
      serverErrors,
      name,
      selectedPackage,
    }
  },
  data() {
    return {
      isLoading: false,
      ORG_LEVELS,
    }
  },
  methods: {
    focus() {
      this.$refs.nameField.focus()
    },
    handleCreateNode() {
      this.createNode().then((node) => {
        this.$emit('created', { nodeId: node.id })
      })
    },
  },
}
</script>
