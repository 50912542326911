<template>
  <Page-section class="wrapper">
    <Section-header>
      <template #section-title>{{ $t('menus.translations.title') }}</template>
      <template #section-navigation>
        <FiltersGroup
          behaviour="radio"
          :filters="availableFilters"
          :selected-id="selectedFilter.id"
          :save-to-url="true"
        />
      </template>
    </Section-header>

    <form
      action="/"
      @submit.prevent="onSubmit"
      @reset.prevent="resetChanges"
    >
      <Loaded-content
        :is-loading="loadingStatus === 'LOADING'"
        :has-error="loadingStatus === 'ERROR'"
        :has-data="loadingStatus === 'LOADED' && !!menuItems.length"
      >
        <SortableTable
          class="box-table"
          :headers="headers"
        >
          <tbody>
            <tr
              v-for="menuItem in menuItems"
              :key="menuItem.id"
            >
              <td>{{ menuItem.name }}</td>
              <td class="p-2">
                <!-- Hidden label for accessibility -->
                <label
                  class="sr-only"
                  :for="menuItem.id"
                >
                  {{ $t('menus.translations.label.translate', { item: menuItem.name }) }}
                </label>
                <input
                  :id="menuItem.id"
                  v-model="menuItem.nameEnglish"
                  v-test="'translate-input'"
                  type="text"
                  autocomplete="off"
                  class="formControl text-acai"
                  @input="formIsTouched = true"
                  @change="translationChanged(menuItem, $event.target.value)"
                  @focus="$event.target.select()"
                  @keydown.enter.prevent
                />
                <div
                  v-if="
                    menuItem.defaultTranslation &&
                    menuItem.nameEnglish !== menuItem.defaultTranslation
                  "
                  v-test="'default-backup'"
                >
                  <small
                    class="default-translation"
                    v-html="
                      $t('menus.translations.label.default', {
                        translation: menuItem.defaultTranslation,
                      })
                    "
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </SortableTable>

        <Pagination
          v-if="selectedFilter.hasPagination"
          :total="total"
          :size="pageSize"
        />

        <!-- Save and reset buttons -->
        <div
          v-test="'form-actions'"
          class="buttonGroup flex-center my-8"
        >
          <button
            v-test="'reset-button'"
            type="reset"
            class="button button--secondary"
            :disabled="!changesInProgress"
          >
            {{ $t('menus.translations.actions.reset') }}
          </button>
          <button
            v-test="'save-button'"
            type="submit"
            class="button button--primary"
            :disabled="!changesInProgress"
          >
            {{ $t('menus.translations.actions.save') }}
          </button>
        </div>

        <!-- Continue to menu link -->
        <div
          v-test="'skip-translations'"
          class="flex-middle flex-column my-8"
        >
          <Validation-results
            ref="validation-results"
            :server-errors="serverErrors"
            :server-warnings="serverWarnings"
            class="mb-4"
            data-test-image-editor-validation-results
          />
          <p
            v-if="allItemsTranslated"
            class="mb-4"
          >
            {{ $t('menus.translations.messages.translationsComplete') }}
          </p>
          <p
            v-else
            class="mb-4"
          >
            {{ $t('menus.translations.messages.missingTranslations') }}
          </p>
          <button
            class="button button--primary"
            type="button"
            :disabled="!allItemsTranslated"
            @click="finishTranslations"
          >
            {{ $t('menus.translations.actions.continue') }}
          </button>
        </div>
      </Loaded-content>
    </form>
  </Page-section>
</template>

<script>
import { useToast } from 'vue-toastification'
import LoadedContent from '@/components/LoadedContent.vue'
import SortableTable from '@/components/SortableTable/SortableTable.vue'
import FiltersGroup from '@/components/FiltersGroup.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import { PAGE_SIZE_MENU_ITEMS, MENU_ITEM_TYPES } from '@/store/constants'
import { useValidation } from '@/composables/useValidation'

export default {
  components: {
    LoadedContent,
    SortableTable,
    FiltersGroup,
    Pagination,
    ValidationResults,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('menu-management/translations/RESET_CHANGES')
    next()
  },
  props: {
    menuId: String,
  },
  setup: () => {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation(function () {
        return {
          GENERAL: [],
          'menu item translation': [],
        }
      })
    return {
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      pageSize: PAGE_SIZE_MENU_ITEMS,
      total: 0,
      // if user starts typing in an input field, this should become true
      formIsTouched: false,
      allItemsTranslated: false,
    }
  },
  computed: {
    headers() {
      return [
        { id: 'th1', title: this.$t('menus.translations.headers.localName') },
        { id: 'th2', title: this.$t('menus.translations.headers.englishTranslation') },
      ]
    },
    availableFilters() {
      return [
        {
          id: MENU_ITEM_TYPES.FOOD_ITEM,
          title: this.$t('menus.translations.filters.foodItems'),
          hasPagination: true,
        },
        {
          id: MENU_ITEM_TYPES.STAGE,
          title: this.$t('menus.translations.filters.stages'),
          hasPagination: false,
        },
        {
          id: MENU_ITEM_TYPES.FOOD_GROUP,
          title: this.$t('menus.translations.filters.foodGroups'),
          hasPagination: false,
        },
      ]
    },
    selectedFilter() {
      let filterId = this.$route.query.filteredBy || this.availableFilters[0].id
      return this.availableFilters.find((f) => f.id === filterId)
    },
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    menuItems() {
      return this.$store.getters['menu-management/translations/menuItemsByType'](
        this.selectedFilter.id
      )
    },
    translatedItems() {
      return this.$store.getters['menu-management/translations/translatedItems']
    },
    changesInProgress() {
      return this.formIsTouched || !!this.translatedItems.length
    },
    currentPage() {
      return this.selectedFilter.hasPagination ? this.$route.query.page || 1 : null
    },
  },
  watch: {
    '$route.query': function (query) {
      this.loadData({ page: query.page })
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loadingStatus = 'LOADING'
      let query = this.currentPage ? { page: this.currentPage - 1, size: this.pageSize } : {}
      Promise.all([
        this.$store
          .dispatch('menu-management/menus/getMenu', { menuId: this.menuId })
          .then(({ menu, organisation }) => {
            this.$store.commit('menu-management/organisations/UPDATE_ORGANISATION', organisation)
            return { menu, organisation }
          })
          .then(({ menu }) => {
            this.allItemsTranslated = !menu.translationsRemaining
          }),
        this.$store
          .dispatch('menu-management/translations/getMenuItems', {
            menuId: this.menuId,
            type: this.selectedFilter.id,
            query,
          })
          .then(({ total }) => {
            this.total = total
            return Promise.resolve()
          })
          .catch(() => {
            return Promise.reject()
          }),
      ])
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch(() => {
          this.loadingStatus = 'ERROR'
        })
    },
    restoreProgress() {
      this.translatedItems.forEach((translatedItem) => {
        let menuItem = this.items.find((menuItem) => menuItem.id === translatedItem.id)
        let translation = translatedItem.englishName
        if (menuItem) {
          menuItem.nameEnglish = translation
        }
      })
    },
    onSubmit() {
      this.clearServerMessages()
      this.$store
        .dispatch('menu-management/translations/saveTranslations', {
          menuId: this.menuId,
          translations: this.translatedItems.map((item) => {
            let oldValue = this.$store.getters['menu-management/translations/originalItem']({
              id: item.id,
              type: item.type,
            })
            return {
              old: {
                name: oldValue.name,
                nameEnglish: oldValue.nameEnglish,
                type: oldValue.type,
              },
              new: {
                name: item.name,
                nameEnglish: item.nameEnglish,
                type: item.type,
              },
            }
          }),
        })
        .then(({ translationsRemaining }) => {
          this.toast.success(this.$t('toast.success.generic'))
          this.allItemsTranslated = !translationsRemaining
        })
        .then(this.reloadMenu)
        .catch((error) => {
          this.savingStatus = 'ERROR'
          this.handleValidationErrorsInResponse(error)
          if (error.status === 422) {
            this.$nextTick(() => {
              this.$refs['validation-results'].$el.focus()
            })
          }
        })
    },
    translationChanged(changedItem, translation) {
      translation = translation.trim()
      if (translation) {
        this.$store.dispatch('menu-management/translations/translateMenuItem', {
          ...changedItem,
          nameEnglish: translation,
        })
      } else {
        this.$store.dispatch('menu-management/translations/revertTranslationToDefault', changedItem)
      }
    },
    resetChanges() {
      this.$store.commit('menu-management/translations/RESET_CHANGES')
    },
    reloadMenu() {
      return this.$store
        .dispatch('menu-management/menus/getMenu', { menuId: this.menuId })
        .then(({ menu, organisation }) => {
          this.$store.commit('menu-management/organisations/UPDATE_ORGANISATION', organisation)
          return { menu, organisation }
        })
        .then(({ menu }) => {
          this.allItemsTranslated = !menu.translationsRemaining
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    finishTranslations() {
      this.$store
        .dispatch('menu-management/translations/finishTranslations', {
          menuId: this.menuId,
        })
        .then((menu) => {
          this.$store.commit('menu-management/menus/UPDATE_MENU', menu)
        })
        .then(() => {
          this.$router.push({
            name: 'menu-summary',
            params: { menuId: this.menuId },
            query: { lang: this.menu.locale.code },
          })
        })
    },
  },
}
</script>
<style lang="scss">
.default-translation {
  margin-left: theme('spacing.2');
  b {
    color: theme('colors.blueberry.DEFAULT');
  }
}
</style>
