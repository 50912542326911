<template>
  <div
    class="org-picker-list"
    :class="[displaySide === 'END' && 'org-picker-list--switch-side']"
    @keydown.esc.stop.prevent="changeFocusedNode(-1)"
  >
    <div class="m-1">
      <input
        ref="search"
        v-model="searchTerm"
        type="search"
        role="combobox"
        aria-label="Search node"
        :placeholder="$t('actions.typeSearch')"
        aria-haspopup="true"
        aria-autocomplete="list"
        aria-expanded="true"
        :aria-owns="`${parentId} - results-list`"
        class="formControl"
        @click.stop
        @focus="onInputFocus"
        @blur="searchActive = false"
        @keydown.up.stop.prevent="goToPreviousNode"
        @keydown.down.stop.prevent="goToNextNode"
        @keydown.stop
      />
    </div>
    <!--
      The list of sibling nodes
      - Preventing up and down keys from scrolling the page
    -->
    <ul
      :id="`${parentId} - results-list`"
      role="listbox"
      @keydown.up.stop.prevent="goToPreviousNode"
      @keydown.down.stop.prevent="goToNextNode"
      @keydown.home.stop="goToFirstNode"
      @keydown.end.stop="goToLastNode"
    >
      <li
        v-for="(node, index) in nodes"
        :key="index"
        ref="node"
        role="option"
        :class="['button', 'org-picker-list-node', { 'org-picker-list-active': node.id === id }]"
        :aria-selected="node.id === id"
        :tabindex="-1"
        data-test-org-node
        @click.stop="nodeSelect(node.id)"
        @keydown.stop.enter="nodeSelect(node.id)"
        @keydown.stop.space="nodeSelect(node.id)"
      >
        {{ node.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id: [String, Number],
    parentId: [String, Number],
    showArchived: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['return-focus', 'node-select'],
  data() {
    return {
      // keeps activating focus on elements
      // only one element should be focusable in this component
      // tab should not be allowed to move between the nodes
      focusedNodeId: null,

      // a pointer that helps with calculations when moving to new nodes
      selectedIndex: -1,
      searchActive: false,
      searchTerm: '',
      displaySide: 'START', // can be switched with 'END'
    }
  },
  computed: {
    nodes() {
      return this.$store.getters['hierarchy/nodes/getChildrenNodes'](this.parentId)
        .filter((node) => {
          return this.showArchived ? true : !node.archived
        })
        .filter((node) => {
          return node.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        })
        .sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })
        .sort((a, b) => {
          let matchIndexA = a.name.toLowerCase().indexOf(this.searchTerm.toLowerCase())
          let matchIndexB = b.name.toLowerCase().indexOf(this.searchTerm.toLowerCase())
          if (matchIndexA === matchIndexB) return 0
          if (matchIndexA === 0) return -1
          if (matchIndexB === 0) return 1
        })
    },
  },
  watch: {
    nodes() {
      this.selectedIndex = -1
    },
  },
  mounted() {
    this.pickDisplaySide()
  },
  methods: {
    pickDisplaySide() {
      let rect = this.$el.getBoundingClientRect()
      if (rect.x + rect.width > window.innerWidth) {
        this.displaySide = 'END'
      }
    },
    findFocusedNodeIndex() {
      return this.nodes.findIndex(({ id }) => id === this.focusedNodeId)
    },
    goToPreviousNode() {
      let index = this.selectedIndex
      index = index - 1 >= -1 ? index - 1 : index
      this.changeFocusedNode(index)
    },
    goToNextNode() {
      let index = this.selectedIndex
      index = index + 1 < this.nodes.length ? index + 1 : index
      this.changeFocusedNode(index)
    },
    goToFirstNode() {
      let index = 0
      this.changeFocusedNode(index)
    },
    goToLastNode() {
      let index = this.nodes.length - 1
      this.changeFocusedNode(index)
    },
    changeFocusedNode(index) {
      if (index === -1) {
        this.$emit('return-focus')
      } else {
        this.focusedNodeId = this.nodes[index].id
        this.selectedIndex = index
        this.$refs['node'][index].focus()
      }
    },
    nodeSelect(id) {
      this.$emit('node-select', id)
    },
    quickSearch() {
      this.$refs['search'].focus()
    },
    onInputFocus() {
      this.searchActive = true
      this.selectedIndex = -1
    },
  },
}
</script>

<style lang="scss" scoped>
.org-picker-list {
  position: absolute;
  top: 100%;
  left: 0;
  text-align: left;
  background: theme('colors.white');
  border: 1px solid theme('colors.slate.DEFAULT');
  z-index: 1;
  min-width: 20ch;
  max-height: 21rem;
  overflow: auto;

  &--switch-side {
    left: auto;
    right: 0;
  }

  .formControl {
    box-sizing: border-box;
  }
}

.org-picker-list-node {
  padding: theme('spacing.1') theme('spacing.2');
  border-top: 1px solid theme('colors.slate.DEFAULT');
  border-radius: 0;
  display: block;
  text-align: left;

  &:hover,
  &:focus {
    background-color: theme('colors.acai.200');
    cursor: pointer;
  }
}

.org-picker-list-active {
  font-weight: bold;
}
</style>
