<template>
  <div>
    <p class="font-bold">Filter by individual field changes</p>
    <Filters-group
      v-bind="$attrs"
      :filters="filters"
      :selected-id="selectedId"
      behaviour="radio"
    />
  </div>
</template>

<script>
import FiltersGroup from '@/components/FiltersGroup.vue'
import { WEIGHT_METRIC_KEY, WEIGHT_US_STANDARD_KEY } from '@/store/constants'
export default {
  components: {
    FiltersGroup,
  },
  props: {
    counters: Object,
    selectedId: String,
    weightPreference: {
      type: String,
      default: WEIGHT_METRIC_KEY,
    },
  },
  computed: {
    filters() {
      return [
        {
          id: 'all',
          title: 'All',
        },
        {
          id: 'name',
          title: 'Name',
        },
        {
          id: 'nameEnglish',
          title: 'Translations',
        },
        {
          // Add kg or lbs depending on user weight preference
          title: `Cost / ${this.$t(`settings.weight.short.${this.weightPreference}`)}`,
          id: 'cost',
        },
        {
          // Add oz or grams depending on user weihgt preference
          title: `Weight (${this.weightPreference === WEIGHT_US_STANDARD_KEY ? 'oz' : 'grams'})`,
          id: 'portion',
        },
        {
          title: 'Taxonomy Code',
          id: 'taxonomyCode',
        },
        {
          title: 'External System',
          id: 'externalSystem.name',
        },
        {
          title: 'External ID',
          id: 'externalId',
        },
      ]
    },
  },
}
</script>

<style></style>
