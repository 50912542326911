<template>
  <div>
    <slot
      :ranges="ranges"
      :is-loading="isLoading"
      :load-prev="loadPrevMonth"
      :load-next="loadNextMonth"
      :load-today="loadToday"
    />
  </div>
</template>

<script setup>
import { store } from '@/store'
import { useStore } from 'vuex'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import { ref, computed, watch } from 'vue'
import moment from 'moment'

const { state, getters } = useStore()

const toast = useToast()
const { t } = useI18n()
const month = ref(moment.utc().startOf('month').toDate())
const isLoading = computed(() => state['covers-sales'].currentMonth.loading)
const ranges = computed(() => state['covers-sales'].currentMonth.ranges)
const { selectedSite } = useSelectedSite({ userId: getters['auth/userId'] })

watch(
  selectedSite,
  (hasSelectedSite) => {
    if (hasSelectedSite) {
      const utcMonth = moment.utc(month.value)
      store
        .dispatch('covers-sales/getCurrentMonth', {
          legacyId: selectedSite.value.legacyId,
          month: utcMonth.toISOString(),
        })
        .catch(() => {
          toast.error(t('toast.error.getData'))
        })
    }
  },
  { immediate: true }
)
const loadPrevMonth = () => {
  const utcMonth = moment.utc(month.value).subtract(1, 'month')
  month.value = utcMonth.toDate()
  store.dispatch('covers-sales/getCurrentMonth', {
    legacyId: selectedSite.value.legacyId,
    month: utcMonth.toISOString(),
  })
  window.analytics.track('Activation - Load previous month')
}
const loadNextMonth = () => {
  const utcMonth = moment.utc(month.value).add(1, 'month')
  month.value = utcMonth.toDate()
  store.dispatch('covers-sales/getCurrentMonth', {
    legacyId: selectedSite.value.legacyId,
    month: utcMonth.toISOString(),
  })
  window.analytics.track('Activation - Load next month')
}
const loadToday = () => {
  const utcMonth = moment.utc().startOf('month')
  month.value = utcMonth.toDate()
  store.dispatch('covers-sales/getCurrentMonth', {
    legacyId: selectedSite.value.legacyId,
    month: utcMonth.toISOString(),
  })
  window.analytics.track('Activation - Load today')
}
</script>
