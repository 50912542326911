<template>
  <div>
    <slot
      :client-errors="clientErrors"
      :all-errors="allErrors"
      :has-errors="hasErrors"
      :has-server-errors="hasServerErrors"
      :has-any-errors="hasAnyErrors"
    >
    </slot>
    <template v-if="hasAnyErrors">
      <div class="formFeedback is-invalid">
        {{ $t(`validation.${allErrors[0].key}`) }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    serverErrors: {
      type: Array,
      default: () => [],
    },
    trackingData: {
      type: Object,
      default: null,
    },
    validator: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activeErrors() {
      if (!this.validator) return []
      return (this.validator.$errors || []).map((error) => ({
        ...error,
        id: error.$uid,
        validationKey: error.$validator,
      }))
    },
    hasErrors() {
      return !!this.activeErrors.length
    },
    firstErrorMessage() {
      if (this.hasErrors) return this.activeErrors[0].$message
      else return ''
    },
    clientErrors() {
      return this.activeErrors.map((el) => ({ ...el, key: el.validationKey }))
    },
    allErrors() {
      return this.clientErrors.concat(this.serverErrors)
    },
    hasServerErrors() {
      return !!this.serverErrors.length
    },
    hasAnyErrors() {
      return this.hasErrors || this.hasServerErrors
    },
  },
}
</script>
