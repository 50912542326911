<template>
  <table-responsive>
    <template #ResponsiveActions>
      <div class="p-4">
        <p class="mb-2">{{ $t('actions.sortBy') }}</p>
        <button-sort
          v-for="header in sortableHeaders"
          :key="header.id"
          class="mr-4 mb-2"
          :sorted="header.id === sortedBy ? sortedDir : null"
          :aria-label="header.id === sortedBy ? sortedDir : null"
          @sorted="onSort({ column: header.id, direction: $event })"
        >
          {{ header.title }}
        </button-sort>
      </div>
    </template>
    <thead-generator
      :headers="headers"
      :sorted-column="sortedBy"
      :sort-direction="sortedDir"
      @sorted="onSort"
      :all-selected="allSelected"
      :all-selected-indeterminate="allSelectedIndeterminate"
      @select-all="$emit('select-all', $event)"
    />
    <!-- Tbody goes in the slot -->
    <slot></slot>
  </table-responsive>
</template>

<script>
import TableResponsive from '@/components/TableResponsive/TableResponsive.vue'
import ButtonSort from '@/components/ButtonSort/ButtonSort.vue'
import TheadGenerator from '@/components/TheadGenerator/TheadGenerator.vue'
export default {
  components: {
    TableResponsive,
    ButtonSort,
    TheadGenerator,
  },
  props: ['headers', 'sortedBy', 'sortedDir', 'allSelected', 'allSelectedIndeterminate'],
  emits: ['sorted', 'select-all'],
  computed: {
    sortableHeaders() {
      return this.headers.filter((header) => header.sortable)
    },
  },
  methods: {
    onSort({ column, direction }) {
      this.$emit('sorted', { column, direction })
    },
  },
}
</script>
