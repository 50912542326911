export default function (newData) {
  return {
    id: newData.id,
    name: newData.name,
    nameEnglish: newData.nameEnglish,
    displayOrder: newData.displayOrder,
    image: newData.imageId,
    stageIds: newData.stageIds,
    foodItemIds: newData.foodItemIds,
    alphabeticallySortedChildren:
      newData.manualOrdering !== undefined ? !newData.manualOrdering : undefined,
  }
}
