export default (data) => ({
  custom3: data.custom3,
  excludeCustom2: data.excludeCustom2,
  excludeCustom1: data.excludeCustom1,
  help: data.help,
  uncategorised: data.uncategorised,
  otherProductionAreas: data.otherProductionAreas,
  topItems: data.topItems,
  foodGroupBreakdown: data.foodGroupBreakdown,
  topAreas: data.topAreas,
  vision: data.vision,
  actionPlan: data.actionPlan,
  planYourDay: data.planYourDay,
  startSending: data.startSending,
  timePeriod: data.timePeriod,
  focus: data.focus,
  customBreakdown: data.customBreakdown,
  comparisonPoints: data.comparisonPoints,
  events: data.events,
  hideCosts: data.hideCosts,
  enabled: data.enabled,
})
