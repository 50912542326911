<template>
  <div
    tabindex="0"
    :class="[
      'card',
      'is-inactive',
      {
        'is-today': isToday,
        'is-invalid': serverErrors.GENERAL.length,
      },
    ]"
  >
    <CardHeader :day="day" />
    <div class="card-body">
      <form
        v-test="'inactive-form'"
        class="CS-form"
        @submit.prevent="setActive(), save()"
      >
        <div
          v-test="'inactive-card'"
          class="CS-inactive"
        >
          <span class="iconCircle">
            <SvgIcon name="24-ic-lock" />
          </span>
          <div class="mt-2">{{ $t('titles.inactiveDay') }}</div>
        </div>
        <div class="CS-form-actions my-2">
          <Loading-Spinner v-if="isSaving" />
          <template v-else>
            <button
              v-test="'set-active-true'"
              v-tooltip="{
                theme: 'tooltip-neutral',
                content: $t('CS.actions.setActive'),
                placement: 'top',
              }"
              class="button button--icon button--round button--primary"
              type="submit"
            >
              <SvgIcon name="24-ic-lock" />
            </button>
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CardHeader from './CardHeader.vue'

export default {
  components: {
    CardHeader,
  },
  props: {
    day: {
      type: Object,
    },
    item: {
      type: Object,
    },
    serverErrors: {
      type: Object,
    },
    serverWarnings: {
      type: Object,
    },
    isSaving: {
      type: Boolean,
    },
  },
  emits: ['save'],
  data() {
    return {
      buffer: { ...this.item },
    }
  },

  computed: {
    isToday() {
      return this.day.isToday
    },
  },

  watch: {
    item: {
      handler: function () {
        this.buffer = { ...this.item }
      },
      immediate: true,
    },
  },

  methods: {
    setActive() {
      this.buffer.active = true

      this.analytics.track('Activation - Set active day', {
        id: this.buffer.id,
        date: this.buffer.date,
      })
    },

    save() {
      this.$emit('save', { data: this.buffer })
    },
  },
}
</script>
