<template>
  <div
    v-if="showNoResults"
    class="box-content loaded-content-empty"
    aria-busy="false"
  >
    <slot name="no-results">
      <div
        class="alert mb-4"
        :class="noResults.alertClass"
      >
        <SvgIcon :name="noResults.icon" />
        <p>
          <slot name="no-data-type">{{ noResults.message }}</slot>
        </p>
      </div>
      <slot name="no-data">{{ $t('messages.noResults') }}</slot>
    </slot>
  </div>

  <div
    v-else-if="showContent"
    class="box-content"
    aria-busy="false"
  >
    <slot />
  </div>

  <div
    v-else-if="isLoading"
    class="flex flex-center py-8"
    data-test-loading-spinner
    aria-busy="true"
  >
    <Loading-Spinner :class="spinnerClass" />
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
export default {
  props: {
    // @TODO: refactor props to have single status with 'LOADING', 'LOADED', 'ERROR' etc
    isLoading: {
      required: true,
      type: Boolean,
    },
    hasError: {
      required: true,
      type: Boolean,
    },
    hasData: {
      required: true,
      type: Boolean,
    },
    errorMessage: {
      required: false,
      type: String,
    },
    noResultsType: {
      type: String,
      default: 'NEUTRAL',
      validator(value) {
        return ['SUCCESS', 'NEUTRAL', 'INFO', 'WARNING'].includes(value)
      },
    },
    spinnerClass: {
      type: [String, Array],
      default: 'spinner--lg',
    },
  },
  setup() {
    return { toast: useToast() }
  },
  computed: {
    noResults() {
      let config = {
        SUCCESS: {
          alertClass: 'alert--success',
          icon: '24-ic-check',
          message: this.$t('messages.noResults.success'),
        },
        NEUTRAL: {
          alertClass: 'alert--neutral',
          icon: '24-ic-info',
          message: this.$t('messages.noResults.emphasis'),
        },
        INFO: {
          alertClass: 'alert--info',
          icon: '24-ic-info',
        },
        WARNING: {
          alertClass: 'alert--warning',
          icon: '24-ic-warning',
        },
      }
      return config[this.noResultsType]
    },
    showNoResults() {
      return !this.isLoading && !this.hasError && !this.hasData
    },
    showContent() {
      return !this.isLoading && !this.hasError
    },
  },
  watch: {
    hasError(error) {
      if (error) {
        this.toast.error(this.errorMessage || this.$t('toast.error.getData'))
      }
    },
  },
}
</script>

<style lang="scss">
.loaded-content-empty {
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    flex: 0 1 auto;
  }

  .alert {
    align-items: center;
    align-self: center;
    .icon {
      margin: 0;
    }
  }
}
</style>
