<template>
  <div>
    <CodeBuilder
      class="mb-8"
      :default-code="editing.taxonomy && editing.taxonomy.code"
      :hide-full-code="true"
      @code-change="onCodeChange"
      @reset-code="onReset"
    />
    <div class="section-header">
      <div class="section-navigation-tabs section-navigation-tabs-row">
        <TabsLvl2Links v-test="'tabs'">
          <li>
            <router-link
              v-test="'terms'"
              :to="{ name: 'food-item-mapping-terms', query: $route.query }"
              class="tab-item"
            >
              <span>{{ $t('taxman.baseTerms') }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              v-test="'facets'"
              :to="{ name: 'food-item-mapping-facets', query: $route.query }"
              class="tab-item"
            >
              <span>{{ $t('taxman.facets') }}</span>
            </router-link>
          </li>
        </TabsLvl2Links>
      </div>
    </div>
    <router-view ref="tree-view"></router-view>
  </div>
</template>
<script>
import CodeBuilder from '@/pages/MenuMapping/CodeBuilder/CodeBuilder.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
export default {
  components: { CodeBuilder, TabsLvl2Links },
  beforeRouteLeave(to, from, next) {
    this.$emit('reset-code')
    next()
  },
  props: {
    foodItemId: String,
  },
  emits: ['reset-code', 'new-mapping'],
  data() {
    return {}
  },
  computed: {
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
    editing() {
      return this.foodItem && this.foodItem.editing
    },
  },
  methods: {
    onCodeChange(changes) {
      this.$emit('new-mapping', changes)
    },
    onReset() {
      // We don't emit a reset event here to the food mapping component because that is intended
      // to reset the mapping to the original food item data.
      // The actual reset from the code builder is a new empty value that gets passed through the
      // code change event.
      if (this.$route.name === 'food-item-mapping-facets') {
        this.$router.push({
          name: 'food-item-mapping-terms',
          params: this.$route.params,
          query: this.$route.query,
        })
      }
    },
  },
}
</script>
