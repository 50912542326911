import api from '@/store/api/taxonomy'
import { normalizeSearch } from '@/store/utils/taxonomy'

const STATUS_INITIAL = 'INITIAL'
const STATUS_LOADING = 'LOADING'
const STATUS_ERROR = 'ERROR'
const STATUS_SUCCESS = 'SUCCESS'

const initialState = () => ({
  records: {},
  treeStructure: {},
  hash: null,
  meta: null,
  error: null,
  status: STATUS_INITIAL,
  treePromise: null,
})

export const newFacetModel = ({ parentId = null, termType = null, detailLevel = null }) => {
  return {
    id: null,
    parentId,
    termName: null,
    displayName: null,
    origin: 'WN',
    detailLevel,
    termType,
    description: null,
  }
}

const state = initialState()

const getters = {
  byId: (state) => (id) => state.records[id],
  byCode: (state) => (code, rootCode) => {
    // this also deals with duplicate codes under different roots
    let instances = Object.values(state.records).filter((rec) => rec.code === code)
    if (instances && instances.length > 1) {
      instances = instances.filter((instance) => {
        return rootCode === findRoot(instance.id)
      })
    }

    return instances[0]

    function findRoot(id) {
      if (state.records[id].parentId) return findRoot(state.records[id].parentId)
      else return state.records[id].code
    }
  },
  childIds: (state) => (id) => {
    const target = state.treeStructure[id]
    return target || []
  },

  isEmpty: (state) => state.status === STATUS_INITIAL,
  isSuccessful: (state) => state.status === STATUS_SUCCESS,
  isLoading: (state) => state.status === STATUS_LOADING,
  isError: (state) => state.status === STATUS_ERROR,

  treePromise: (state) => state.treePromise,
  rootRecords: (state, getters) => getters.childIds('root').map((id) => state.records[id]),
  rootFacetById: (state) => (id) => {
    let rootFacet = null

    function findRoot(id) {
      if (state.records[id].parentId) {
        findRoot(state.records[id].parentId)
      } else {
        rootFacet = state.records[id]
      }
    }

    findRoot(id)
    return rootFacet
  },
  allFacetChildIds: (state, getters) => (facetId) => {
    const { childIds: childIdsGetter } = getters
    let childIds = [facetId]

    function cumulate(children) {
      children.forEach((childId) => {
        childIds.push(childId)
        if (childIdsGetter(childId)) {
          cumulate(childIdsGetter(childId))
        }
      })
    }

    cumulate(childIdsGetter(facetId))

    return childIds
  },
}

const actions = {
  resetState({ commit }) {
    commit('RESET_STATE')
  },

  async getTree({ commit, state }) {
    try {
      commit('SET_STATUS', STATUS_LOADING)

      state.treePromise = api.fetchFacetsTree()
      const response = await state.treePromise
      const { data, metadata: meta } = response.data

      commit('BUILD_TREE', data)

      commit('taxonomy/TYPES_SET_DATA', meta.termTypes, { root: true })
      commit('taxonomy/LEVELS_SET_DATA', meta.detailLevels, { root: true })

      commit('SET_STATUS', STATUS_SUCCESS)
    } catch (error) {
      commit('SET_STATUS', STATUS_ERROR)
      throw error
    }
  },

  async checkHash({ state }) {
    const response = await api.fetchFacetsHash()
    const { hash } = response.data.data

    if (hash !== state.hash) {
      return false
    }

    return true
  },

  async getById({ commit }, id) {
    const response = await api.fetchFacet(id)
    const { data } = response.data

    commit('STORE_RECORD', data)

    return data
  },

  async search(context, { query, options = {} }) {
    const response = await api.fetchFacets(query, options.cancelToken)
    let { data, metadata: meta } = response.data

    data = normalizeSearch(data)

    return { data, meta }
  },

  async getWhere({ commit }, { query } = {}) {
    const response = await api.fetchFacets(query)
    const { data, metadata: meta } = response.data

    commit('STORE_RECORDS', data)

    return { data, meta }
  },

  async getLinks(context, id) {
    const response = await api.fetchFacetLinks(id)
    const { data } = response.data

    return data
  },

  saveFacet({ commit }, { oldModel, newModel }) {
    // POST
    let method = api.postFacet
    let args = newModel
    let onSuccess = ({ data: record, metadata: meta }) => {
      commit('STORE_RECORD', record)
      commit('ADD_TO_TREE_STRUCTURE', {
        parentId: record.parentId,
        childId: record.id,
      })

      if (meta.hash) {
        commit('UPDATE_HASH', meta.hash)
      }
    }

    // PUT
    if (newModel.id !== null) {
      method = api.putFacet
      args = { oldModel, newModel }

      onSuccess = ({ data: record, metadata: meta }) => {
        commit('STORE_RECORD', record)

        if (meta.hash) {
          commit('UPDATE_HASH', meta.hash)
        }
      }
    }

    return new Promise((resolve, reject) => {
      method(args)
        .then(({ data }) => {
          onSuccess(data)

          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  removeFromRecordsAndTreeStructure({ commit, getters }, id) {
    const term = state.records[id]
    const allFacetChildIds = getters.allFacetChildIds(id)
    const parentId = term.parentId

    commit('REMOVE_FROM_RECORDS', allFacetChildIds)
    commit('REMOVE_FROM_TREE_STRUCTURE', { id, parentId, allFacetChildIds })
  },

  validateFacetDelete(store, id) {
    return api.validateFacetDelete(id)
  },

  deleteFacet({ state, getters }, id) {
    const facetCode = state.records[id].code
    const rootCode = getters.rootFacetById(id).code

    return new Promise((resolve, reject) => {
      api
        // DELETE
        .deleteFacet({ facetCode, rootCode })
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  moveFacets({ getters, commit }, { facetId, newParentId }) {
    const { byId, rootFacetById } = getters

    const hierarchyCode = rootFacetById(facetId).code
    const facetCode = byId(facetId).code
    const oldParentCode = byId(byId(facetId).parentId).code
    const newParentCode = byId(newParentId).code

    const oldModel = {
      code: facetCode,
      parentCode: oldParentCode,
      hierarchyCode,
    }

    const newModel = {
      code: facetCode,
      parentCode: newParentCode,
      hierarchyCode,
    }

    return new Promise((resolve, reject) => {
      api
        // PUT
        .moveNodes('facets', { oldModel, newModel })
        .then(
          ({
            data: {
              data: { id, parentId: newParentId },
              metadata,
            },
          }) => {
            const record = byId(id)
            const oldParentId = record.parentId

            commit('MOVE_FACET_IN_TREE_STRUCTURE', {
              id,
              oldParentId,
              newParentId,
            })

            commit('STORE_RECORD', { ...record, parentId: newParentId })

            if (metadata) {
              commit('UPDATE_HASH', metadata.hash)
            }
            resolve()
          }
        )
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  },

  RESET_STATE(state) {
    Object.assign(state, initialState())
  },

  STORE_RECORDS(state, newRecords) {
    const { records } = state

    newRecords.forEach((newRecord) => {
      records[newRecord.id] = newRecord
    })
  },

  STORE_RECORD(state, newRecord) {
    const { records } = state

    records[newRecord.id] = newRecord
  },

  REMOVE_FROM_RECORDS(state, idsList) {
    const { records } = state

    idsList.forEach((id) => {
      delete records[id]
    })
  },

  STORE_ERROR(state, error) {
    state.error = error
  },

  BUILD_TREE(state, data) {
    state.records = data.records
    state.treeStructure = data.treeStructure
    state.hash = data.hash
  },

  UPDATE_HASH(state, hash) {
    state.hash = hash
  },

  ADD_TO_TREE_STRUCTURE(state, { parentId, childId }) {
    const found = state.treeStructure[parentId]

    if (!found) {
      state.treeStructure[parentId] = [childId]
    } else {
      state.treeStructure[parentId].push(childId)
    }
  },

  MOVE_FACET_IN_TREE_STRUCTURE(state, { id, oldParentId, newParentId }) {
    const idIndex = state.treeStructure[oldParentId].indexOf(id)

    state.treeStructure[oldParentId].splice(idIndex, 1)

    if (state.treeStructure[newParentId] === undefined) {
      state.treeStructure[newParentId] = []
    }
    state.treeStructure[newParentId].push(id)
  },

  REMOVE_FROM_TREE_STRUCTURE(state, { id, parentId, allFacetChildIds }) {
    const { treeStructure } = state
    const parentTreeStructure = treeStructure[parentId]
    const termIndex = parentTreeStructure.indexOf(id)
    // remove the deleted facet from the parent tree structure
    parentTreeStructure.splice(termIndex, 1)

    allFacetChildIds.forEach((id) => {
      delete treeStructure[id]
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
