<script>
import { mapGetters } from 'vuex'

import TreeBox from '@/components/TreeBox/TreeBox.vue'
import TermView from '@/components/TermView/TermView.vue'
import TreeOfTerms from '@/components/TreeOfTerms.vue'

export default {
  name: 'TermsPage',
  components: {
    TreeBox,
    TermView,
    TreeOfTerms,
  },

  data() {
    return {
      displayedId: null,
    }
  },

  computed: {
    ...mapGetters('taxonomy/terms', ['byId']),
    ...mapGetters('code-builder', ['term']),
  },
  methods: {
    displayRecord(id) {
      this.displayedId = id
    },

    selectTerm(nodeId) {
      this.$store.dispatch('code-builder/selectTerm', this.byId(nodeId))
    },

    removeTerm() {
      this.$store.dispatch('code-builder/removeTerm')
    },

    isTermSelected(nodeId) {
      return this.term && this.term.id === nodeId
    },

    resetScroll() {
      this.$scrollTo(`#code-builder`, 1)
    },
  },
}
</script>

<template>
  <TreeBox class="box-content-full-width -mb-4 border-t border-t-slate">
    <template #main>
      <TreeOfTerms
        :node-classes="
          (nodeId) => ({
            'is-active': displayedId === nodeId,
          })
        "
        @node-click="displayRecord($event)"
        @result-click="displayRecord($event)"
      >
        <template #node="{ nodeId }">
          <div
            v-test="'node-controls'"
            :class="['Tree-controls', { 'is-active': isTermSelected(nodeId) }]"
          >
            <button
              v-if="!isTermSelected(nodeId)"
              v-test="'select-term'"
              class="button button--icon button--round button--primary"
              @click.stop="
                () => {
                  selectTerm(nodeId)
                  resetScroll()
                }
              "
            >
              <SvgIcon name="24-ic-check" />
            </button>
            <template v-else>
              <span class="iconCircle iconCircle--sm hide-on-hover">
                <SvgIcon
                  class="text-blueberry"
                  name="24-ic-check"
                />
              </span>
              <button
                v-test="'remove-term'"
                class="button button--icon button--round button--primary show-on-hover"
                @click.stop="removeTerm()"
              >
                <SvgIcon name="24-ic-clear" />
              </button>
            </template>
          </div>
        </template>
        <template #result="{ nodeId }">
          <div
            v-test="'result-controls'"
            :class="['ResultNode-controls', { 'is-active': isTermSelected(nodeId) }]"
          >
            <button
              v-if="!isTermSelected(nodeId)"
              v-test="'search-select-term'"
              class="button button--icon button--round button--primary"
              @click.stop="selectTerm(nodeId)"
            >
              <SvgIcon name="24-ic-check" />
            </button>
            <template v-else>
              <span class="iconCircle iconCircle--sm hide-on-hover">
                <SvgIcon
                  class="text-blueberry"
                  name="24-ic-check"
                />
              </span>
              <button
                v-test="'remove-term'"
                class="button button--icon button--round button--primary show-on-hover"
                @click.stop="removeTerm()"
              >
                <SvgIcon name="24-ic-clear" />
              </button>
            </template>
          </div>
        </template>
      </TreeOfTerms>
    </template>

    <template #column>
      <TermView
        v-if="displayedId"
        :record-id="displayedId"
      />
    </template>
  </TreeBox>
</template>
