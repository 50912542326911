<template>
  <section-header>
    <template #section-title>
      {{ $t('organisations.reporting.title') }}
    </template>
    <template #section-navigation-tabs>
      <TabsLvl2Links>
        <li>
          <router-link
            class="tab-item"
            :to="{
              name: 'hierarchy-node-reporting-general-settings',
              query: { ...$route.query },
            }"
          >
            {{ $t('organisations.reporting.generalSettings.title') }}
          </router-link>
        </li>
        <li>
          <router-link
            class="tab-item"
            :to="{ name: 'hierarchy-node-reporting-subscribers', query: { ...$route.query } }"
          >
            {{ $t('organisations.reporting.subscribers.title') }}
          </router-link>
        </li>
        <li v-if="node.level === orgLevels">
          <router-link
            class="tab-item"
            :to="{ name: 'hierarchy-node-reporting-configurations', query: { ...$route.query } }"
          >
            {{ $t('organisations.reporting.configurations.title') }}
          </router-link>
        </li>
      </TabsLvl2Links>
    </template>
  </section-header>
  <div class="box-content-full-width">
    <router-view />
  </div>
</template>
<script>
import SectionHeader from '@/components/SectionHeader.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
import { ORG_LEVELS } from '@/store/constants'

export default {
  components: {
    SectionHeader,
    TabsLvl2Links,
  },
  props: {
    nodeId: String,
  },
  computed: {
    node() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    orgLevels() {
      return ORG_LEVELS
    },
  },
}
</script>
