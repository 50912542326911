<template>
  <LayoutCentralMessage>
    <h1 class="text-white mt-8 mb-2">{{ $t('messages.appError') }}</h1>
    <p
      class="h3"
      v-html="$t('messages.appError.details')"
    ></p>
    <SvgIcon
      class="NotFound-illustration text-acai-900"
      name="error-page"
    />
  </LayoutCentralMessage>
</template>

<script>
import LayoutCentralMessage from '@/layouts/LayoutCentralMessage/LayoutCentralMessage.vue'

export default {
  name: 'NotFound',
  components: {
    LayoutCentralMessage,
  },
}
</script>
