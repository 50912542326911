<template>
  <collapsible-section
    data-test-waste-targets-collapsible
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.headers.wasteTargets') }}
    </template>
    <template #header-side="{ isOpen }">
      <!-- Active Targets -->
      <template v-if="!isOpen">
        <Loading-Spinner
          v-if="loadingStatus === 'LOADING'"
          class="ml-2"
        />
        <ul v-else-if="activeTargetsChips.length">
          <filter-chip
            v-for="(displayTarget, i) in activeTargetsChips"
            :key="i"
            tag="li"
            data-test-active-target
          >
            {{ displayTarget }}
          </filter-chip>
        </ul>
      </template>
      <div
        v-else
        class="flex flex-right flex-expand"
      >
        {{ $t('organisations.messages.wasteTargets') }}
      </div>
    </template>

    <filters-group
      class="mb-2"
      behaviour="radio"
      :selected-id="queries.filters.past ? 'past' : 'set'"
      :filters="[
        { id: 'set', title: $t('organisations.filters.setTargets') },
        { id: 'past', title: $t('organisations.filters.pastTargets') },
      ]"
      @filtered-by="handleFilterChange"
    />

    <loaded-content
      :is-loading="loadingStatus === 'LOADING' && queries.page === 0"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="!!ids.length"
    >
      <!-- Waste targets table -->
      <table-responsive>
        <thead-generator :headers="headers" />
        <transition-group
          name="fade"
          tag="tbody"
        >
          <template
            v-for="id in ids"
            :key="id"
          >
            <waste-target-item-row
              :id="id"
              data-test-waste-target-row
              :is-open="openTarget === id"
              :location-id="locationId"
              :active="isTargetActive(id)"
              @edit="
                () => {
                  openTarget === id ? (openTarget = null) : (openTarget = id)
                }
              "
            />

            <tr-editable
              v-if="id === openTarget"
              :id="'editing-' + id"
              data-test-waste-target-edit-row
              class="hierarchy-waste-target-tr-editable"
              :colspan="headers.length"
              :is-open="id === openTarget"
            >
              <waste-target-form
                :id="id"
                action-type="saveWasteTargetChanges"
                :location-id="locationId"
                class="p-4"
                @cancel="
                  () => {
                    openTarget = null
                  }
                "
                @save-data="updateRouteAndFetchData({ targetsPage: undefined })"
              >
                <template #actions>
                  <!-- Save and cancel -->
                  <div class="buttonGroup flex-top">
                    <button
                      data-test-waste-target-submit-changes
                      type="submit"
                      class="button button--primary"
                    >
                      <SvgIcon name="24-ic-check" />
                      <span>{{ $t('actions.saveChanges') }}</span>
                    </button>
                    <button
                      type="reset"
                      class="button button--secondary"
                    >
                      <span>{{ $t('actions.cancel') }}</span>
                    </button>
                  </div>
                  <!-- Delete action -->
                  <Loading-Spinner
                    v-if="deletingStatus === 'DELETING'"
                    class="spinner--lg mt-4"
                  />
                  <delete-with-prompt
                    v-else
                    class="flex-top"
                    @confirm="handleDeleteTarget"
                  >
                    <p class="font-bold">
                      {{ $t('organisations.messages.deleteTarget') }}
                    </p>
                  </delete-with-prompt>
                </template>
              </waste-target-form>
            </tr-editable>
          </template>
        </transition-group>
      </table-responsive>
      <!-- Load More -->
      <div
        v-if="meta && meta.total > ids.length"
        class="flex flex-center mt-4"
      >
        <Loading-Spinner
          v-if="loadingStatus === 'LOADING'"
          class="spinner--lg"
        />
        <button
          v-else
          data-test-waste-targets-load-more
          class="button button--primary"
          @click="updateRouteAndFetchData({ targetsPage: queries.page + 1 })"
        >
          {{ $t('pagination.loadMore') }}
        </button>
      </div>
    </loaded-content>
    <!-- Create Target -->
    <div
      v-if="!queries.filters.past"
      class="mt-4"
    >
      <button
        v-if="openTarget !== 'createTarget'"
        class="button button--link"
        data-test-waste-targets-create-target
        @click="
          () => {
            openTarget = 'createTarget'
          }
        "
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('organisations.action.createTarget') }}</span>
      </button>
      <waste-target-form
        v-else
        action-type="createWasteTarget"
        :location-id="locationId"
        @save-data="
          () => {
            updateRouteAndFetchData({ targetsPage: undefined })
            openTarget = null
          }
        "
        @cancel="openTarget = null"
      />
    </div>
  </collapsible-section>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { useToast } from 'vue-toastification'

import WasteTargetItemRow from './ReportingWasteTargets/WasteTargetItemRow.vue'
import WasteTargetForm from './ReportingWasteTargets/WasteTargetForm.vue'

import TableResponsive from '@/components/TableResponsive/TableResponsive.vue'
import TheadGenerator from '@/components/TheadGenerator/TheadGenerator.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'

import LoadedContent from '@/components/LoadedContent.vue'
import FilterChip from '@/components/FilterChip.vue'
import FiltersGroup from '@/components/FiltersGroup.vue'
import TrEditable from '@/components/TrEditable.vue'
import { DATE_FORMAT } from '@/store/constants'

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    FilterChip,
    FiltersGroup,
    WasteTargetItemRow,
    WasteTargetForm,
    TrEditable,
    TableResponsive,
    TheadGenerator,
    DeleteWithPrompt,
  },
  props: {
    preOpen: {
      type: Boolean,
      default: false,
    },
    locationId: String,
    queries: Object,
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      openTarget: null,
      loadingStatus: 'IDLE',
      deletingStatus: 'IDLE',
    }
  },
  computed: {
    ...mapGetters('hierarchy/waste-targets', ['ids', 'byId', 'meta']),

    wasteTypesMap() {
      return {
        SALES_PERCENTAGE: {
          name: this.$t('titles.wastePercentageAsSales'),
          unit: '%',
        },
        GRAMS_PER_COVER: {
          name: this.$t('titles.gramsPerCover'),
          unit: 'g',
        },
      }
    },
    headers() {
      return [
        { id: 'th0', title: this.$t('actions.edit'), hidden: true },
        { id: 'th1', title: this.$t('organisations.tableHeaders.target') },
        { id: 'th2', title: this.$t('organisations.tableHeaders.effectiveDate') },
        { id: 'th3', title: this.$t('organisations.tableHeaders.endDate') },
        { id: 'th4', title: this.$t('organisations.tableHeaders.type') },
        { id: 'th5', title: this.$t('organisations.tableHeaders.description') },
        { id: 'th6', title: this.$t('organisations.tableHeaders.targetStatus'), hidden: true },
      ]
    },
    activeTargetsChips() {
      return this.meta
        ? this.meta.activeTargets.map((activeTarget) => {
            const { type, effectiveFromDate, value } = activeTarget

            return this.$t(`organisations.labels.activeTarget.${type}`, {
              value: value,
              unit: this.wasteTypesMap[type].unit,
              date: moment(effectiveFromDate).format(DATE_FORMAT),
            })
          })
        : []
    },
  },
  created() {
    this.getWasteTargets()
  },
  methods: {
    getWasteTargets() {
      this.loadingStatus = 'LOADING'

      const { page, filters } = this.queries
      const isLoadingMoreWasteTargets = page > 0

      if (!isLoadingMoreWasteTargets) {
        this.$store.commit('hierarchy/waste-targets/CLEAR_WASTE_TARGETS_IDS')
        this.$store.commit('hierarchy/waste-targets/CLEAR_RECORDS')
      }

      this.$store
        .dispatch('hierarchy/waste-targets/getWasteTargets', {
          nodeId: this.locationId,
          filter: filters,
          page,
        })
        .then(() => {
          this.loadingStatus = 'LOADED'

          this.$nextTick(() => {
            const preOpenTargetId = this.queries.preOpenTarget
            const editedTargetId = this.openTarget

            this.scrollToTargetAndHighlight(preOpenTargetId || editedTargetId)

            if (preOpenTargetId) {
              // pre open the target
              this.openTarget = preOpenTargetId
              // remove the query param once the target is opened
              this.$router.push({ query: { ...this.$route.query, preOpenWasteTarget: undefined } })
            } else if (editedTargetId) {
              // close the edited target
              this.openTarget = null
            }
          })
        })
        .catch((e) => {
          this.loadingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.getData'))
          throw e
        })
    },

    scrollToTargetAndHighlight(id) {
      if (id) {
        let el = document.getElementById(`target-row-${id}`)
        if (el) {
          el.classList.add('tr-highlight-fade')
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    },

    handleDeleteTarget() {
      this.deletingStatus = 'DELETING'

      this.$store
        .dispatch(`hierarchy/waste-targets/deleteWasteTarget`, {
          nodeId: this.locationId,
          targetId: this.openTarget,
        })
        .then(() => {
          this.deletingStatus = 'DELETED'
          this.toast.success(this.$t('toast.success.generic'))
          this.updateRouteAndFetchData()
        })
        .catch((e) => {
          this.deletingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
    },

    updateRouteAndFetchData(newQuery = {}) {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            ...newQuery,
          },
        })
        .then(() => {
          this.getWasteTargets()
        })
    },

    handleFilterChange({ id }) {
      this.updateRouteAndFetchData({
        targetsPage: undefined,
        targetsPast: id === 'past',
      })
    },

    isTargetActive(targetId) {
      return !!this.meta.activeTargets.find((target) => target.id === targetId)
    },
  },
}
</script>

<style lang="scss">
.hierarchy-waste-targets-table {
  background-color: theme('colors.white');
}
.hierarchy-waste-target-tr-editable {
  .collapsible-wrapper {
    margin: 0;
  }
}
</style>
