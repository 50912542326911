<template>
  <div
    v-test="'term-view'"
    class="TermView"
  >
    <div class="TermView-header">
      <div class="TermView-titleBar">
        <h2 class="TermView-title h3">
          <span
            v-test="'current-name'"
            v-html="actualName"
          ></span>
        </h2>
        <slot name="controls" />
      </div>
    </div>
    <div class="bg-grey-50 -mx-4 px-4 pt-2">
      <TabsLvl2Links>
        <Loading-Spinner
          v-if="isLoading"
          class="spinner--md p-4"
        />
        <template v-else>
          <li>
            <a
              href
              :class="['tab-item', { 'is-active': currentTab === 'properties' }]"
              @click.prevent="currentTab = 'properties'"
              ><span>Properties</span></a
            >
          </li>
          <li>
            <a
              href
              :class="['tab-item', { 'is-active': currentTab === 'facets' }]"
              @click.prevent="currentTab = 'facets'"
              ><span>Implicit facets</span></a
            >
          </li>
        </template>
      </TabsLvl2Links>
    </div>

    <template v-if="!isLoading">
      <div class="TermView-content">
        <template v-if="currentTab === 'properties'">
          <div class="TermDetails">
            <div
              v-if="record.origin === 'WN'"
              v-test="'current-origin'"
              class="TermDetails-origin"
            >
              <SvgIcon
                name="48-ic-winnow"
                xl
              />
            </div>

            <img
              v-if="record.iconUrl"
              v-test="'current-img'"
              class="TermDetails-img"
              :src="record.iconUrl"
              width="100"
              height="100"
              alt=""
            />

            <div
              v-if="termType"
              class="TermDetails-group"
            >
              <div class="TermDetails-label">Type of term</div>
              <div
                v-test="'current-term-type'"
                class="TermDetails-desc"
                v-html="termType"
              ></div>
            </div>

            <div class="TermDetails-group">
              <div class="TermDetails-label">Term code</div>
              <div
                v-test="'current-code'"
                class="TermDetails-desc"
              >
                {{ record.code }}
              </div>
            </div>

            <div class="TermDetails-group">
              <div class="TermDetails-label">Level of detail</div>

              <!-- prettier-ignore -->
              <div
                v-test="'current-detail-level'"
                :class="['TermDetails-desc', 'has-dot', 'level-' + detailLevelClass]"
              >{{ detailLevel }}</div>
            </div>

            <div class="TermDetails-group">
              <div class="TermDetails-label">Description</div>
              <div
                v-test="'current-description'"
                class="TermDetails-desc is-block"
                v-html="record.description"
              ></div>
            </div>

            <!-- <hr />

        <div class="TermDetails-group">
          <div class="TermDetails-label">Used in</div>
          <div class="TermDetails-desc">{{record.used.menuItems}} menu items and {{record.used.menus}} menus</div>
        </div>

        <hr />

        <div class="TermDetails-group">
          <div class="TermDetails-label">Last updated by</div>
          <div class="TermDetails-desc">{{record.updatedBy.author}} | {{record.updatedBy.date | moment('L')}}</div>
        </div>

        <div class="TermDetails-group">
          <div class="TermDetails-label"><a href>View revisions</a></div>
        </div>

        <div class="TermDetails-group">
          <div class="TermDetails-label">Created by</div>
          <div class="TermDetails-desc">{{record.createdBy.author}} | {{record.createdBy.date | moment('L')}}</div>
        </div> -->
          </div>

          <div
            v-if="record.references"
            class="TermView-links"
          >
            <template
              v-for="(reference, i) in record.references"
              :key="i"
            >
              <a
                target="_blank"
                class="button button--icon button--round"
                :href="reference.url"
              >
                <SvgIcon
                  :name="reference.origin.toLowerCase()"
                  sm
                />
              </a>
            </template>
          </div>
        </template>

        <template v-else>
          <ul
            v-if="record.implicitFacets"
            class="Tree"
          >
            <TreeLayout
              :node-id="'root'"
              :get-children="(nodeId) => implicitFacetsMap[nodeId] || []"
              :key-prefix="'implicit'"
              :expanded="true"
            >
              <template #default="{ nodeId }">
                <NodeFacet
                  :record="facetById(nodeId)"
                  :detail-level="getDetailLevelById(facetById(nodeId).detailLevel)"
                />
              </template>
            </TreeLayout>
          </ul>
        </template>
      </div>
      <slot name="actions" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dasherize } from '@/utils/string'

import TreeLayout from '@/components/TreeLayout.vue'
import NodeFacet from '@/components/NodeFacet.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    TreeLayout,
    NodeFacet,
    TabsLvl2Links,
  },
  props: {
    recordId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      currentTab: 'properties',
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('taxonomy', ['getTermTypeById', 'getDetailLevelById']),
    ...mapGetters('taxonomy/terms', {
      termById: 'byId',
    }),
    ...mapGetters('taxonomy/facets', {
      facetById: 'byId',
    }),

    record() {
      return this.termById(this.recordId)
    },
    isPartialRecord() {
      // duck typing, partial records dont have a description attr
      return !this.record.description
    },

    termType() {
      return this.record.termType ? this.getTermTypeById(this.record.termType) : null
    },
    detailLevel() {
      return this.getDetailLevelById(this.record.detailLevel)
    },
    detailLevelClass() {
      return dasherize(this.detailLevel)
    },
    actualName() {
      return this.record.displayName || this.record.termName
    },
    implicitFacetsMap() {
      const meta = this.record._meta.implicitFacetsByRoots

      return {
        root: Object.keys(meta),
        ...meta,
      }
    },
  },
  watch: {
    recordId: {
      handler: async function (newValue) {
        this.currentTab = 'properties'

        if (this.isPartialRecord) {
          try {
            this.isLoading = true
            await this.$store.dispatch('taxonomy/terms/getById', newValue)
          } catch (error) {
            this.toast.error(this.$t('toast.error.getData'))
            throw error
          } finally {
            this.isLoading = false
          }
        } else {
          // Update record in the background
          await this.$store.dispatch('taxonomy/terms/getById', newValue)
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss">
// .TermView-header {}

.TermView-titleBar {
  padding: 0 theme('spacing.4') theme('spacing.4');
  margin-left: calc(-1 * theme('spacing.4'));
  margin-right: calc(-1 * theme('spacing.4'));
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid theme('colors.slate.DEFAULT');

  .button + .button {
    margin-left: theme('spacing.2');
  }
}

.TermView-title {
  font-weight: bold;
  flex: 1 1 auto;
  color: theme('colors.acai.DEFAULT');
  margin-right: theme('spacing.2');
}

.TermView-links {
  padding: 0;
  padding: theme('spacing.2');
  margin-top: theme('spacing.2');
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button + .button {
    margin-left: theme('spacing.2');
  }
}

.TermView .tabs {
  margin: theme('spacing.2') 0;
}

.TermView-content {
  padding: theme('spacing.2') 0;
  margin-left: calc(-1 * theme('spacing.4'));
  margin-right: calc(-1 * theme('spacing.4'));
}

.TermDetails {
  position: relative;
  color: theme('colors.acai.DEFAULT');
  padding: theme('spacing.2') theme('spacing.4');

  hr {
    border-top: 3px solid theme('colors.white');
    margin: theme('spacing.2') calc(-1 * theme('spacing.4'));
  }
}

.TermDetails-group {
  & + & {
    margin-top: theme('spacing.1');
  }
}

.TermDetails-label {
  display: inline-block;
  font-weight: theme('fontWeight.bold');
  margin-right: theme('spacing[2.5]');
}

.TermDetails-desc {
  display: inline-block;
}

.has-dot {
  &::before {
    content: '';
    position: relative;
    vertical-align: middle;
    top: -0.15ch;
    display: inline-block;
    width: 0.55em;
    height: 0.55em;
    background: theme('colors.blueberry.DEFAULT');
    border-radius: 50%;
    margin-right: theme('spacing.2');
  }

  &.level-hierarchy-term::before {
    background: theme('colors.aqua.DEFAULT');
  }

  &.level-aggregation-term::before {
    background: theme('colors.slate.DEFAULT');
  }

  &.level-core-term::before {
    background: theme('colors.apple.DEFAULT');
  }

  &.level-non-specific-term::before {
    background: theme('colors.lemon.DEFAULT');
  }

  &.level-extended-term::before {
    background: theme('colors.mint.DEFAULT');
  }
}

.TermDetails-origin {
  float: right;
  margin-left: theme('spacing.2');
  margin-bottom: theme('spacing.2');
  .logo {
    color: theme('colors.carrot.DEFAULT');
  }
}

.TermDetails-img {
  border-radius: theme('borderRadius.lg');
  margin-bottom: theme('spacing.3');
}
</style>
