<template>
  <div>
    <div class="flex flex-between flex-middle">
      <label for="Custom1">
        {{ $t('menus.tabletEditor.stage.labels.custom1') }}
      </label>
      <div>
        <Loading-Spinner
          v-if="custom1LoadingStatus === 'LOADING'"
          class="spinner--xs"
        />
        <button
          v-if="custom1LoadingStatus === 'LOADED' && !readonly"
          type="button"
          class="button button--link"
          data-test-custom1-create
          :disabled="disabled"
          @click="mode = 'CREATE'"
        >
          {{ $t('actions.create') }}
        </button>
      </div>
    </div>
    <select
      id="Custom1"
      class="customSelect"
      :disabled="selectDisabled"
      :value="selectedId"
      data-test-custom1-select
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="">{{ $t('menus.tabletEditor.stage.labels.noSelect') }}</option>
      <option
        v-for="custom1 in custom1s"
        :key="custom1.id"
        :value="custom1.id"
      >
        {{ custom1.displayName }}
      </option>
    </select>

    <fieldset
      v-if="mode === 'CREATE'"
      class="formSection custom1-create"
      data-test-custom1-create-form
    >
      <legend class="formSection-title">
        {{ $t('menus.tabletEditor.stage.titles.createCustom1') }}
      </legend>
      <Validation-group
        :validator="v$.name"
        class="formGroup"
        v-slot="{ hasAnyErrors }"
      >
        <label for="newCustom1Name">{{ $t('menus.tabletEditor.stage.labels.custom1Name') }}</label>
        <input
          id="newCustom1Name"
          v-model.lazy.trim="name"
          class="formControl"
          :class="{ 'is-invalid': hasAnyErrors }"
          type="text"
          data-test-custom1-create-name-field
          @change="onNameChange"
        />
      </Validation-group>
      <Validation-group
        :validator="v$.nameEnglish"
        class="formGroup"
        v-slot="{ hasAnyErrors }"
      >
        <label for="newCustom1EnglishName">{{
          $t('menus.tabletEditor.stage.labels.custom1NameEnglish')
        }}</label>
        <input
          id="newCustom1EnglishName"
          v-model.lazy.trim="v$.nameEnglish.$model"
          class="formControl"
          :class="{ 'is-invalid': hasAnyErrors }"
          type="text"
          data-test-custom1-create-name-english-field
        />
      </Validation-group>
      <ValidationResults
        v-test="'error-messages'"
        class="my-4"
        :server-errors="serverErrors"
        :server-warnings="serverWarnings"
      />
      <div class="buttonGroup">
        <button
          type="button"
          class="button button--primary"
          :disabled="custom1CreateStatus === 'SAVING'"
          data-test-custom1-create-save
          @click="create"
        >
          {{ $t('actions.create') }}
        </button>
        <button
          type="button"
          class="button button--secondary"
          :disabled="custom1CreateStatus === 'SAVING'"
          data-test-custom1-create-cancel
          @click="reset"
        >
          {{ $t('actions.cancel') }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import { useToast } from 'vue-toastification'
import { useValidation } from '@/composables/useValidation'
import menuMixin from '../menuMixin'

export default {
  components: {
    ValidationGroup,
    ValidationResults,
  },
  mixins: [menuMixin],
  props: {
    modelValue: String,
    menuId: String,
    stageId: [String, Number],
    disabled: Boolean,
    readonly: Boolean,
  },
  emits: ['update:modelValue', 'mode-change'],
  setup: () => {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse } = useValidation()
    return {
      v$: useVuelidate(),
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
    }
  },
  data() {
    return {
      mode: 'SELECT',
      name: '',
      nameEnglish: '',
      selectedId: this.modelValue || '',
      custom1LoadingStatus: 'IDLE',
      custom1CreateStatus: 'IDLE',
    }
  },
  computed: {
    custom1s() {
      return this.$store.getters['menu-management/menus/custom1sInMenu'](this.menuId)
    },
    selectDisabled() {
      if (this.disabled || this.readonly) return true
      if (this.custom1LoadingStatus === 'LOADING') return true
      if (this.custom1LoadingStatus === 'ERROR') return true
      if (this.mode === 'CREATE') return true
      return false
    },
  },
  watch: {
    mode(mode) {
      this.$emit('mode-change', mode)
    },
    modelValue(id) {
      this.selectedId = id || ''
    },
  },
  created() {
    if (this.menuId !== this.custom1MenuId) {
      this.custom1LoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/custom1s/getCustom1s', { menuId: this.menuId })
        .then(() => {
          this.custom1LoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.custom1LoadingStatus = 'ERROR'
          this.toast.error('Error loading custom breakdowns')
        })
    }
  },
  validations() {
    return {
      name: {
        required,
      },
      nameEnglish: this.menuIsNonEnglish && {
        required,
      },
    }
  },
  methods: {
    onNameChange() {
      if (!this.menuIsNonEnglish) {
        this.nameEnglish = this.name
      }
    },
    create() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.custom1CreateStatus = 'SAVING'
      this.$store
        .dispatch('menu-management/custom1s/createNewCustom1', {
          menuId: this.menuId,
          stageId: this.stageId,
          data: { name: this.name, nameEnglish: this.nameEnglish },
        })
        .then((custom1) => {
          this.mode = 'SELECT'
          this.selectedId = custom1.id || ''
          this.custom1CreateStatus = 'SAVED'
          this.$emit('update:modelValue', custom1.id)
        })
        .catch((errorObj) => {
          this.handleValidationErrorsInResponse(errorObj)
          this.custom1CreateStatus = 'ERROR'
        })
    },
    reset() {
      this.mode = 'SELECT'
    },
  },
}
</script>

<style lang="scss">
.custom1-create {
  background: theme('colors.acai.200');
  margin-top: theme('spacing.4');
  margin-left: calc(-1 * theme('spacing.4'));
  margin-right: calc(-1 * theme('spacing.4'));
  padding: theme('spacing.4');
  legend {
    float: left;
  }
}
</style>
