<template>
  <div
    v-test="'facet-view'"
    class="TermView"
  >
    <div class="TermView-header">
      <div class="TermView-titleBar">
        <h2 class="TermView-title h3">
          <span
            v-test="'current-name'"
            v-html="actualName"
          ></span>
        </h2>
        <slot name="controls" />
      </div>
    </div>

    <Loading-Spinner
      v-if="isLoading"
      class="spinner--md mx-auto my-4"
    />
    <template v-else>
      <div class="TermView-content">
        <div class="TermDetails">
          <div
            v-if="record.origin === 'WN'"
            v-test="'current-origin'"
            class="TermDetails-origin"
          >
            <SvgIcon
              name="48-ic-winnow"
              xl
            />
          </div>

          <img
            v-if="record.imageUrl"
            v-test="'current-img'"
            class="TermDetails-img"
            :src="record.imageUrl"
            width="100"
            height="100"
            alt=""
          />

          <div class="TermDetails-group">
            <div class="TermDetails-label">Term code</div>
            <div
              v-test="'current-code'"
              class="TermDetails-desc"
            >
              {{ record.code }}
            </div>
          </div>

          <div class="TermDetails-group">
            <div class="TermDetails-label">Level of detail</div>
            <!-- prettier-ignore -->
            <div
              v-test="'current-detail-level'"
              :class="['TermDetails-desc', 'has-dot', 'level-' + detailLevelClass]"
            >{{ detailLevel }}</div>
          </div>

          <div class="TermDetails-group">
            <div class="TermDetails-label">Description</div>
            <div
              v-test="'current-description'"
              class="TermDetails-desc is-block"
              v-html="record.description"
            ></div>
          </div>
          <!--
        <hr />

        <div class="TermDetails-group">
          <div class="TermDetails-label">Used in</div>
          <div class="TermDetails-desc">{{record.used.terms}} base terms</div>
        </div>

        <hr />

        <div class="TermDetails-group">
          <div class="TermDetails-label">Last updated by</div>
          <div class="TermDetails-desc">{{record.updatedBy.author}} | {{record.updatedBy.date | moment('L')}}</div>
        </div>

        <div class="TermDetails-group">
          <div class="TermDetails-label"><a href>View revisions</a></div>
        </div>

        <div class="TermDetails-group">
          <div class="TermDetails-label">Created by</div>
          <div class="TermDetails-desc">{{record.createdBy.author}} | {{record.createdBy.date | moment('L')}}</div>
        </div>
-->
        </div>
      </div>
      <slot name="actions" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { dasherize } from '@/utils/string'
import { useToast } from 'vue-toastification'

export default {
  props: {
    recordId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('taxonomy', ['getDetailLevelById']),
    ...mapGetters('taxonomy/facets', ['byId']),

    record() {
      return this.byId(this.recordId)
    },
    isPartialRecord() {
      // duck typing, partial records dont have a description attr
      return !this.record.description
    },

    detailLevel() {
      return this.getDetailLevelById(this.record.detailLevel)
    },
    detailLevelClass() {
      return dasherize(this.detailLevel)
    },
    actualName() {
      return this.record.displayName || this.record.termName
    },
  },
  watch: {
    recordId: {
      handler: async function (newValue) {
        if (this.isPartialRecord) {
          try {
            this.isLoading = true
            await this.$store.dispatch('taxonomy/facets/getById', newValue)
          } catch (error) {
            this.toast.error(this.$t('toast.error.getData'))
            throw error
          } finally {
            this.isLoading = false
          }
        } else {
          // Update record in the background
          await this.$store.dispatch('taxonomy/facets/getById', newValue)
        }
      },
      immediate: true,
    },
  },
}
</script>
