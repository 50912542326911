<script>
import { mapGetters } from 'vuex'

import TreeLayout from '@/components/TreeLayout.vue'
import SearchRecords from '@/components/SearchRecords.vue'
import NodeTerm from '@/components/NodeTerm.vue'
import StickySearch from '@/components/StickySearch.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    TreeLayout,
    SearchRecords,
    NodeTerm,
    StickySearch,
  },

  provide() {
    return {
      // function because it needs to be reactive
      // https://github.com/vuejs/vue/issues/7017#issuecomment-480906691
      preopen: () => this.preopen,
    }
  },
  props: {
    nodeClasses: {
      type: Function,
      default: () => function () {},
    },
    stickySearchSelectors: {
      type: Object,
      default: () => ({
        parent: '.fixedColumn-main',
        relative: '.fixedColumn-column',
      }),
    },
    keyPrefix: {
      type: String,
      default: 'terms',
    },
    rootId: {
      type: String,
      default: 'root',
    },
  },
  emits: ['node-click', 'resultClick'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    // will not cover the roots themselves changing in the background
    // only for the initial render when the tree is loaded
    const roots = this.$store.getters['taxonomy/terms/childIds']('root')
    const toBePreopened = {}

    roots.forEach((rootId) => {
      toBePreopened[rootId] = true
    })

    return {
      actionBarStyle: {
        width: 'auto',
      },
      isLoadingRoots: false,
      preopen: { ...toBePreopened },
      activeResult: null,
    }
  },

  computed: {
    ...mapGetters('taxonomy/terms', ['byId', 'childIds']),
    ...mapGetters('taxonomy', ['getDetailLevelById']),
  },

  async created() {
    const isSuccessful = this.$store.getters['taxonomy/terms/isSuccessful']
    const isLoading = this.$store.getters['taxonomy/terms/isLoading']
    const treePromise = this.$store.getters['taxonomy/terms/treePromise']

    if (isSuccessful) {
      try {
        const isUpToDate = await this.$store.dispatch('taxonomy/terms/checkHash')

        if (!isUpToDate) {
          await this.$store.dispatch('taxonomy/terms/getTree')
        }
      } catch (error) {
        this.toast.error(this.$t('toast.error.getData'))
      }
      return
    }

    try {
      this.isLoadingRoots = true

      if (!isLoading) {
        await this.$store.dispatch('taxonomy/terms/getTree')
      } else {
        await treePromise
      }

      // roots are special snowflakes and should be opened
      const roots = this.$store.getters['taxonomy/terms/childIds']('root')
      const toBePreopened = {}

      roots.forEach((rootId) => {
        toBePreopened[rootId] = true
      })

      this.preopen = { ...this.preopen, ...toBePreopened }
    } catch (error) {
      this.toast.error(this.$t('toast.error.getData'))
    } finally {
      this.isLoadingRoots = false
    }
  },
  methods: {
    onSearch(branch) {
      const record = branch[branch.length - 1].record

      const hash = {}
      branch.slice(0, -1).forEach(({ record }) => {
        hash[record.id] = true
      })

      this.preopen = hash

      this.$nextTick(() => {
        this.$scrollTo(`#tree-${this.keyPrefix}-${record.id}`, 1, {
          offset: -1 * (50 + 36),
        })
      })

      this.$emit('resultClick', record.id)
      this.activeResult = record.id
    },
  },
}
</script>

<template>
  <div>
    <StickySearch :selectors="stickySearchSelectors">
      <div class="w-full">
        <slot name="sticky-top-content" />
        <SearchRecords
          :action="'taxonomy/terms/search'"
          :on-select="onSearch"
          :include-icon="true"
        >
          <template #result="{ nodeId }">
            <slot
              name="result"
              :node-id="nodeId"
            />
          </template>
        </SearchRecords>
      </div>
    </StickySearch>

    <div class="py-2 with-StickySearch">
      <Loading-Spinner
        v-if="isLoadingRoots"
        class="spinner--md mx-auto my-2"
      />
      <ul
        v-else
        class="Tree"
      >
        <TreeLayout
          :node-id="rootId"
          :get-children="(nodeId) => childIds(nodeId)"
          :key-prefix="keyPrefix"
          :node-classes="[
            (nodeId) => ({
              'is-match': activeResult === nodeId,
            }),
            nodeClasses,
          ]"
          @node-click="$emit('node-click', $event)"
        >
          <template #default="{ nodeId, modifiers }">
            <NodeTerm
              :record="byId(nodeId)"
              :detail-level="getDetailLevelById(byId(nodeId).detailLevel)"
            />

            <slot
              name="node"
              :node-id="nodeId"
              :modifiers="modifiers"
            />
          </template>
        </TreeLayout>
      </ul>
    </div>
  </div>
</template>
