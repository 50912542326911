<template>
  <component
    :is="wrapperEl"
    class="collapsible-section"
  >
    <div
      class="collapsible-section-trigger"
      :class="[
        `collapsible-section-trigger--${expandButtonPosition}`,
        `collapsible-section-trigger--${triggerTarget}`,
      ]"
      v-on="{ click: triggerTarget === 'full-area' ? onClick : () => {} }"
    >
      <Collapsible-toggle
        :open="isOpen"
        class="collapsible-section-action"
        :class="{
          'collapsible-section-action--stay-open': isOpen,
          'collapsible-section-action--hide-expand-button': expandButtonHidden,
          'mr-2': expandButtonPosition === 'left',
        }"
        :aria-controls="`${id}-content`"
        @open="onOpen"
        @close="onClose"
      />

      <h2
        v-if="$slots['header']"
        :id="`${id}-title`"
        class="py-2 text-base"
        :class="{
          'font-bold': isOpen,
          'font-normal': !isOpen,
          'text-xl': isOpen,
          'mr-2': hasContentForSeparation,
        }"
      >
        <slot
          name="header"
          :is-open="isOpen"
        />
      </h2>
      <slot
        name="header-side"
        :is-open="isOpen"
      />
    </div>
    <collapsible-content
      :id="`${id}-content`"
      :is-open="isOpen"
      role="region"
      :aria-labelledby="`${id}-title`"
    >
      <slot />
    </collapsible-content>
  </component>
</template>

<script>
import CollapsibleContent from '@/components/CollapsibleContent.vue'
import CollapsibleToggle from '@/components/CollapsibleToggle.vue'
export default {
  components: {
    CollapsibleContent,
    CollapsibleToggle,
  },
  props: {
    id: {
      type: [String, Number],
      default: 'Collapsible-Content',
    },
    tag: String,
    state: Boolean,
    expandButtonHidden: {
      type: Boolean,
      default: false,
    },
    triggerTarget: {
      type: String,
      default: 'full-area',
      validator: (prop) => ['expand-button', 'full-area'].includes(prop),
    },
    expandButtonPosition: {
      type: String,
      default: 'left',
      validator: (prop) => ['left', 'right'].includes(prop),
    },
    forceOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close'],

  data() {
    return {
      isOpen: this.forceOpen,
    }
  },
  computed: {
    wrapperEl() {
      return this.tag ? this.tag : 'div'
    },
    hasContentForSeparation() {
      return !!this.$slots['header-side'] && !!this.$slots['header']
    },
  },
  watch: {
    state(isOpen) {
      this.isOpen = isOpen
    },
  },
  methods: {
    onClick() {
      if (this.isOpen) {
        this.onClose()
      } else {
        this.onOpen()
      }
    },
    onOpen() {
      this.isOpen = true
      this.$emit('open')
    },
    onClose() {
      this.isOpen = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.collapsible-section {
  &--primary {
    .collapsible-section-trigger {
      background-color: theme('colors.blueberry.DEFAULT');
      color: theme('colors.white');
    }
  }

  &--emphasis {
    .collapsible-section-trigger {
      background-color: theme('colors.acai.DEFAULT');
      color: theme('colors.white');
    }
  }

  &--primaryTransparent {
    .collapsible-section-trigger {
      background-color: transparent;
      color: theme('colors.white');
    }
  }

  &--floating {
    position: relative;

    .collapsible-section-trigger {
      padding: 0;
      border-bottom: 0;
    }

    .collapsible-wrapper {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
    }
  }

  &--first {
    border-top: 1px solid theme('colors.slate.DEFAULT');
  }
}

.collapsible-section--rounded {
  border-radius: theme('borderRadius.lg');

  .collapsible-section-trigger {
    border-radius: theme('borderRadius.lg') theme('borderRadius.lg') 0 0;
    overflow: hidden;
  }
}

.collapsible-section-trigger {
  padding: theme('spacing.2') theme('spacing.4');
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid theme('colors.slate.DEFAULT');

  button {
    color: inherit;
  }

  &--right {
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &--full-area:hover {
    background-color: theme('colors.acai.100');
    color: theme('colors.acai.DEFAULT');
    cursor: pointer;

    .collapsible-section-action {
      // even if the expand button is hidden we still need to
      // display it when hover over the collapsible section
      &--hide-expand-button {
        opacity: 1;
      }
    }
  }
}

.collapsible-section-action {
  &--hide-expand-button {
    opacity: 0;
  }

  &--hide-expand-button:focus {
    opacity: 1;
  }

  // always show the expand button when the section is open
  &--stay-open {
    opacity: 1;
  }
}
</style>
