import mapPermissionsToCASLAbilities from '@/utils/permissions'

/**
 * Transform the permissions structure coming from the backend to our internal abilities format that can be used by CASL
 * Also remap the transformed structure to our internal equivalents for each subject and action
 * @param {Object} permissions key/value pairs of actions a user can take on certain "subjects"
 * @returns {Array} list of subject/action pairs
 */
export default (permissions, groups) => {
  const isTrackerLicense = groups[0] === 'Tracker Licenses'
  let abilities = mapPermissionsToCASLAbilities(permissions).map(
    remapAbilitiesToInternalEquivalents
  )
  if (permissions && !isTrackerLicense) {
    // Everyone should have access to pages like home, setup, profile etc.
    // There is, however, one exception: Tracker Licenses.
    // Users in this group are not allowed to access any hub pages so they would not get any permissions.
    // Those users should be redirected to 404
    abilities = addDefaultAbilities(abilities)
  }
  return abilities
}

/**
 * Remap the permissions to the internal equivalents structure in order to keep our current logic
 * ex:
 *   incoming: `{subject: 'waste-log-transactions', action: 'write'}
 *   output:  `{subject: 'waste-log', action: 'manage'}`
 * @param {Object} abilities
 */
const remapAbilitiesToInternalEquivalents = ({ subject, action }) => ({
  subject: remapSubject(subject),
  action: remapAction(action),
})

/**
 * Transform a list of actions coming from the backend into our internal equivalents in order to keep our current logic
 *
 * Note: `create` and `update` are both turned to `manage` because we don't have them separate in the UI yet. Anyone that can create, can also update subjects.
 * CASL doesn't care about duplicate values and handles them as a single rule.
 * @TODO: Separate `manage` throughout the app into `update` and `create` then remove this mapping
 * @param {String} action a verb coming from the backend services, ex: READ, WRITE, DELETE
 * @returns {String} our internal equivalent for the same verb or the verb itself
 */
const remapAction = (action) => {
  const mapping = {
    read: 'read',
    create: 'manage',
    update: 'manage',
    delete: 'delete',
  }
  return mapping[action] || action
}

/**
 * Transform a list of subjects coming from the backend into our internal equivalents in order to keep our current logic
 * @param {String} subject an area or section of the application that can be accessed by a user
 * @returns {String} our internal equivalent or the subject itself
 */
const remapSubject = (subject) => {
  const mapping = {
    'single-site-dashboard': 'single-site-dashboards',
    'waste-log-transaction': 'waste-log',
    'menu-mapping-tax-code': 'menu-mapping',
    'organisation-nodes': 'hierarchy',
    'report-downloads': 'downloads',
    'insights-dashboard': 'insights',
    'multi-site-dashboard': 'multi-site-dashboards',
    'homepage-multi-site-dashboard': 'homepage-dashboard',
    'homepage-single-site-dashboard': 'homepage-dashboard',
  }
  return mapping[subject] || subject
}

/**
 * Add generic access to users who have at least one permission to access HUB.
 *
 * @param {Array} abilities - the currently available abilities for the user
 * @returns {Array} - list of abilities with the default abilities added to the front
 */

const addDefaultAbilities = (abilities) => {
  const defaultAbilities = [{ subject: 'generic', action: 'read' }]
  return [].concat(defaultAbilities, abilities)
}
