<template>
  <section-header ref="users-header">
    <template #section-title>
      {{ $t('organisations.users.title') }}
    </template>
    <template #section-actions>
      <!-- Search button -->
      <Search-button
        v-if="searchIsVisible"
        id="users-search"
        v-model="searchTerm"
        :preopen="!!$route.query.search"
        @update:model-value="debouncedSearch"
      />

      <!-- Create user top button -->
      <router-link
        v-if="isCreateAvailable"
        :to="{
          name: isCreateAvailableOnRoute ? $route.name : 'hierarchy-node-users-node',
          query: {
            ...(isCreateAvailableOnRoute ? $route.query : {}),
            create: true,
            scrollIntoView: true,
          },
        }"
        class="button button--primary"
        data-test-create-user
      >
        <SvgIcon name="24-ic-add" />
        <span>{{ $t('organisations.createUser.actions.createUser') }}</span>
      </router-link>
    </template>
    <template #section-navigation-tabs>
      <TabsLvl2Links>
        <li
          v-for="tab in availableTabs"
          :key="tab.id"
          v-bind="{ [tab.testHook]: true }"
        >
          <router-link
            class="tab-item"
            :to="tab.link"
            :exact-path="true"
          >
            {{ tab.title }}
          </router-link>
        </li>
        <li class="tab-separate">
          <router-link
            class="tab-item"
            v-bind="{ [archivedTab.testHook]: true }"
            :to="archivedTab.link"
            :exact-path="true"
          >
            {{ archivedTab.title }}
          </router-link>
        </li>
      </TabsLvl2Links>
    </template>
  </section-header>

  <router-view />
</template>

<script>
import SectionHeader from '@/components/SectionHeader.vue'
import SearchButton from '@/components/SearchButton.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
import { WINNOW_ORG_ID } from '@/store/constants'
import { DEBOUNCE_DELAY } from '@/store/constants'
import { debounce } from 'lodash'

export default {
  components: {
    SectionHeader,
    SearchButton,
    TabsLvl2Links,
  },
  beforeRouteLeave(to, from, next) {
    if (Object.values(to.query).length) {
      next({ ...to, query: {} })
    }
    next()
  },
  props: {
    nodeId: String,
  },
  data() {
    return {
      searchTerm: this.$route.query.search || '',
    }
  },
  computed: {
    node() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    isWinnow() {
      return this.nodeId === WINNOW_ORG_ID
    },
    tabs() {
      let query = { ...this.$route.query }
      delete query.page
      delete query.create
      return [
        {
          id: 'tab-users-node',
          link: {
            name: 'hierarchy-node-users-node',
            query,
          },
          title: this.$t('organisations.users.navigation.node'),
          testHook: 'data-test-tabs-users-node',
        },
        {
          id: 'tab-users-viewing',
          link: {
            name: 'hierarchy-node-users-viewing',
            query,
          },
          title: this.$t('organisations.users.navigation.viewing'),
          testHook: 'data-test-tabs-users-viewing',
        },
        {
          id: 'tab-users-settings',
          link: {
            name: 'hierarchy-node-users-settings',
            query: {},
          },
          title: this.$t('organisations.users.navigation.settings'),
          testHook: 'data-test-tabs-users-settings',
        },
      ]
    },
    availableTabs() {
      return this.tabs.filter((tab) => {
        if (this.isWinnow) {
          switch (tab.id) {
            case 'tab-users-settings':
              return false
            case 'tab-users-viewing':
              return false
            default:
              return true
          }
        }
        return true
      })
    },
    archivedTab() {
      return {
        id: 'tab-users-archived',
        link: {
          name: 'hierarchy-node-users-archived',
          query: {
            search: this.$route.query.search,
          },
        },
        title: this.$t('organisations.users.navigation.deleted'),
        testHook: 'data-test-tabs-users-archived',
      }
    },
    // this flags if users can be created
    isCreateAvailable() {
      if (this.node.archived) return false
      return true
    },
    // this flags if the users can be created on the current route
    isCreateAvailableOnRoute() {
      return ['hierarchy-node-users-node', 'hierarchy-node-users-viewing'].includes(
        this.$route.name
      )
    },
    searchIsVisible() {
      if (this.$route.name == 'hierarchy-node-users-settings') return false
      return true
    },
  },
  watch: {
    '$route.query.page'(to, from) {
      if (to !== from) {
        this.$refs['users-header'].$el.scrollIntoView()
      }
    },
    '$route.query.search'(search) {
      this.searchTerm = search
    },
  },
  methods: {
    debouncedSearch: debounce(function (val) {
      let query = { ...this.$route.query }
      if (val) {
        delete query.page
        query.search = val
      } else {
        delete query.search
      }
      this.$router.push({ query })
    }, DEBOUNCE_DELAY),
  },
}
</script>
