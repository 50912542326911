<template>
  <SvgIcon
    v-if="record.parentId === null"
    class="Tree-folder"
    name="folder"
  />
  <a
    v-if="record.parentId === null"
    :class="classes"
    data-test-node-name
    @click="$emit('anchor-click', record.id)"
    v-html="fullName"
  ></a>
  <span
    v-else
    :class="classes"
    data-test-node-name
    v-html="fullName"
  ></span>
</template>
<script>
import { dasherize } from '@/utils/string'
export default {
  props: {
    record: Object,
    detailLevel: String,
  },
  emits: ['anchor-click'],
  computed: {
    classes() {
      let classes = ['Tree-name']

      if (this.detailLevel !== null) {
        classes.push('has-dot', 'level-' + dasherize(this.detailLevel))
      }
      return classes
    },
    fullName() {
      const name = this.record.displayName || this.record.termName
      const fullName = `${name} (${this.record.code})`
      return fullName
    },
  },
}
</script>
