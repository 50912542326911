<template>
  <VueMultiselect
    v-model="selectedOptions"
    :id="id"
    :options="displayedOptions"
    :multiple="multiple"
    :disabled="disabled"
    :track-by="trackBy"
    :label="label"
    :searchable="searchable"
    :allow-empty="allowEmpty"
    :preserve-search="true"
    :close-on-select="multiple ? false : true"
    :clear-on-select="false"
    :internal-search="useInternalSearch"
    :placeholder="placeholder"
    :loading="isLoading"
    :max-height="maxHeight"
    @update:modelValue="handleUpdate"
    @select="handleSelect"
    @remove="handleRemove"
    @search-change="handleSearchChange"
    @open="handleOpen"
    @close="handleClose"
    ref="multiselect"
  >
    <!-- Add the loading spinner at the top of the results list, the list will be hidden while data is loading -->
    <template #beforeList>
      <div
        v-if="isLoading"
        class="flex justify-center relative z-[2] p-6"
      >
        <LoadingSpinner class="spinner--md" />
      </div>
    </template>

    <template #selection="{ values, isOpen }">
      <SvgIcon
        v-if="!isOpen || (isOpen && !query.length)"
        name="24-ic-search"
        class="text-slate absolute top-3 left-3 z-[3]"
        data-test-multiselect-search-icon
      />
      <slot
        name="selection"
        v-bind="{ values, isOpen }"
      >
        <!--
          for multi select variant, display the placeholder.
          the placeholder should be adapted from the outside to display an appropriate message for the number of items selected
        -->
        <span v-if="!isOpen && multiple && values.length">
          {{ placeholder }}
        </span>
      </slot>
      <button
        type="button"
        class="button button--icon text-slate absolute top-0 left-0 z-[4]"
        v-if="isOpen && query.length"
        @click="handleQueryClear"
        @mousedown.prevent
      >
        <SvgIcon name="24-ic-clear" />
      </button>
    </template>
    <template #placeholder>{{ placeholder }}</template>

    <template #caret>
      <button
        v-if="isOpen"
        type="button"
        class="button button--icon text-slate absolute right-0 top-0 z-[4]"
        ref="toggle"
        data-test-multiselect-open
        @click="multiselect.deactivate()"
        @mousedown.prevent
        tabindex="-1"
        :disabled="disabled"
      >
        <SvgIcon name="24-ic-arrow-up" />
      </button>
      <button
        v-else
        type="button"
        class="button button--icon text-slate absolute right-0 top-0 z-[4]"
        ref="toggle"
        data-test-multiselect-open
        @click="multiselect.activate()"
        tabindex="-1"
        :disabled="disabled"
      >
        <SvgIcon name="24-ic-arrow-down" />
      </button>
    </template>

    <template #option="props">
      <slot
        name="option"
        v-bind="props"
      >
        {{ props.option[label] }}
      </slot>
    </template>

    <template #singleLabel="{ option }">
      <slot
        name="single-label"
        v-bind="{ option }"
      >
        {{ option[label] }}
      </slot>
    </template>

    <template #noResult>
      <div class="p-2">
        <slot name="no-results">{{ $t('messages.noResults') }}</slot>
      </div>
    </template>

    <template #noOptions>
      <div class="p-2">
        <slot name="no-options">{{ $t('actions.typeSearch') }}</slot>
      </div>
    </template>
  </VueMultiselect>
</template>
<script setup>
import VueMultiselect from 'vue-multiselect'
import { ref, watch } from 'vue'
import { uniq, uniqBy } from 'lodash'

const selectedOptions = ref(null)
const displayedOptions = ref([])
const query = ref('')
const toggle = ref(null)
const multiselect = ref(null)
const isOpen = ref(false)

const props = defineProps({
  id: [String, Number],
  // selected option(s) from the list
  // be aware that this value will not be displayed in the input field if the option itself does not exist in the `option`
  modelValue: {
    type: [Array, Object, String],
    default: null,
  },
  modelValueType: {
    type: String,
    default: (props) => {
      if (props.multiple) {
        return 'LIST_OF_OBJECTS'
      }
      return 'OBJECT'
    },
    validator: (value) => {
      return [
        'LIST_OF_OBJECTS',
        'LIST_OF_STRINGS',
        'LIST_OF_NUMBERS',
        'OBJECT',
        'STRING',
        'NUMBER',
      ].includes(value)
    },
  },
  trackBy: String, // a unique / non repeating property in the options
  label: String, // the property name that contains the value that should be shown in the UI
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: String,
  // the list of selectable options.
  // At this point, it should be a list of objects with a unique prop that can be tracked
  // and a display value (label) that will be shown in the UI
  options: {
    type: Array,
    default: () => [],
  },
  // wether to use in-memory search or to rely on parent component to provide the filtered data
  useInternalSearch: {
    type: Boolean,
    default: true,
  },
  // wether multiple options can be selected
  multiple: {
    type: Boolean,
    default: true,
  },
  // wether the multiselect can have "no selection"
  // by default, once an option was selected, there is no option to remove that selection
  allowEmpty: {
    type: Boolean,
    default: false,
  },
  // loading status when data is being provided by parent component
  isLoading: {
    type: Boolean,
    default: false,
  },
  // wether the user can type in the input to filter the list
  searchable: {
    type: Boolean,
    default: true,
  },
  maxHeight: {
    type: Number,
    default: 300,
  },
})

const emit = defineEmits(['select', 'update:modelValue', 'open', 'close', 'search-change'])

const focusInput = () => {
  multiselect.value.$el.focus()
}

defineExpose({ focusInput })

watch(
  () => [props.options, props.modelValue],
  () => setDefaultState()
)

const setDefaultState = () => {
  displayedOptions.value = props.options
  // default for multi select
  if (props.modelValueType === 'LIST_OF_OBJECTS') {
    selectedOptions.value = props.modelValue
  }
  // default for single select
  if (props.modelValueType === 'OBJECT') {
    selectedOptions.value = props.modelValue
  }
  if (props.modelValueType === 'LIST_OF_STRINGS') {
    selectedOptions.value = uniqBy(
      props.options.filter((option) => props.modelValue.includes(option[props.trackBy])),
      props.trackBy
    )
  }
  if (props.modelValueType === 'LIST_OF_NUMBERS') {
    selectedOptions.value = uniqBy(
      props.options.filter((option) => props.modelValue.includes(option[props.trackBy])),
      props.trackBy
    )
  }
  if (props.modelValueType === 'STRING') {
    selectedOptions.value = props.options.find(
      (option) => option[props.trackBy] === props.modelValue
    )
  }
  if (props.modelValueType === 'NUMBER') {
    selectedOptions.value = props.options.find(
      (option) => option[props.trackBy] === props.modelValue
    )
  }
}

const handleUpdate = (selected) => {
  let output = null
  if (props.modelValueType === 'LIST_OF_OBJECTS') {
    output = uniqBy(selected, props.trackBy)
  }
  // default for single select
  if (props.modelValueType === 'OBJECT') {
    output = selected
  }
  if (props.modelValueType === 'LIST_OF_STRINGS') {
    if (props.multiple) {
      output = uniq(selected.map((option) => option[props.trackBy]))
    } else {
      output = [selected[props.trackBy]]
    }
  }
  if (props.modelValueType === 'LIST_OF_NUMBERS') {
    if (props.multiple) {
      output = uniq(selected.map((option) => option[props.trackBy]))
    } else {
      output = [selected[props.trackBy]]
    }
  }
  if (props.modelValueType === 'STRING') {
    output = selected ? selected[props.trackBy] : ''
  }
  if (props.modelValueType === 'NUMBER') {
    output = selected ? [props.trackBy] : undefined
  }
  emit('update:modelValue', output)
}
const handleSelect = (value) => {
  emit('select', value)
}
const handleRemove = (value) => {
  emit('remove', value)
}

const handleSearchChange = (value) => {
  query.value = value || ''
  if (!props.useInternalSearch) {
    displayedOptions.value = []
  }
  emit('search-change', value)
}

// clear the input text (not the selected options)
const handleQueryClear = () => {
  multiselect.value.updateSearch('')
}

const handleOpen = () => {
  isOpen.value = true
  emit('open')
}
const handleClose = () => {
  isOpen.value = false
  emit('close')
}

setDefaultState()
</script>

<style>
.multiselect,
.multiselect__input,
.multiselect__single {
  touch-action: manipulation;
}
.multiselect {
  position: relative;
}
.multiselect__content-wrapper {
  z-index: 1;
}
.multiselect--active {
  z-index: 5;
}
.multiselect__tags {
  @apply bg-white py-inputPaddingY px-inputPaddingX pl-10 text-input-text border border-slate rounded relative;
  height: calc(theme('width.inputHeight') + 2 * theme('borderWidth.DEFAULT'));
}
.multiselect--disabled .multiselect__tags {
  background-color: theme('colors.grey.100');
  color: theme('colors.grey.500');
}
.multiselect--active::before {
  content: '';
  z-index: 1;
  @apply bg-white rounded-t-md shadow-md border-t border-x border-slate absolute -top-2 -left-2 -right-2 h-14;
}
.multiselect__content-wrapper {
  @apply bg-white rounded-b-md shadow-md border-b border-x border-slate absolute top-inputHeight -left-2 -right-2 pt-2 overflow-auto;
}
.multiselect__content {
  @apply h-full;
  width: 100%;
}
.multiselect__single {
  position: relative;
  z-index: 2;
  padding-right: theme('spacing.10');
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 100%;
}
.multiselect .multiselect__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-color: transparent;
  cursor: default;
}
.multiselect--active .multiselect__input {
  z-index: 2;
  opacity: 1;
  /* otherwise overwritten by inline styles */
  padding-left: theme('spacing.10') !important;
  padding-right: theme('spacing.10') !important;
}
.multiselect__option {
  @apply p-2 border-b border-b-slate hover:cursor-pointer block;
}
.multiselect__option--highlight {
  background-color: theme('colors.lemon.100');
}
.multiselect__option--selected {
  background-color: theme('colors.acai.100');
}
.multiselect__option--disabled {
  background-color: theme('colors.slate.DEFAULT');
  color: theme('colors.grey.400');
}
</style>
