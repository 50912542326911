<template>
  <button
    :disabled="!imageBlob"
    type="button"
    class="button button--secondary"
    @click.stop="saveImage"
  >
    {{ $t('actions.downloadImage') }}
  </button>
</template>
<script>
import { saveAs } from 'file-saver'
export default {
  props: {
    fileName: String,
    imageBlob: String,
  },

  methods: {
    saveImage() {
      saveAs(this.imageBlob, this.fileName)
    },
  },
}
</script>
