import { h, useSlots, onMounted, onBeforeUnmount, ref, nextTick, watch } from 'vue'
import { useRoute } from 'vue-router'
// requires three refs to template elements
// @TODO: refactor these with render functions maybe
export function useResponsiveTabs({ moreToggle, moreList, list } = {}) {
  if (!moreToggle || !moreList || !list) {
    throw new Error('useResponsiveTabs: Required properties missing')
  }
  const slots = useSlots()

  // this should be in sync with the css gap value between tab items
  const TABS_SPACING = 8
  // render functions for the separated ndoes
  const primaryList = () => {
    return h(() => slots.default())
  }
  const secondaryList = () => {
    return h(() => slots.default())
  }

  // check if there is an active link inside of the secondary list
  // router-links have an 'is-active' class added by vue-router when the link and current url reference the same location
  const isMoreButtonActive = ref(false)
  const toggleMoreButtonActiveStatus = () => {
    isMoreButtonActive.value = !!moreList.value.querySelector(':scope > li:not(.hidden) .is-active')
  }

  const hasIssues = ref(false)
  const toggleIssuesWarning = () => {
    const hasWarningTab = !!moreList.value.querySelector(':scope > li:not(.hidden) .tab-warning')
    hasIssues.value = !isMoreOpen.value && hasWarningTab
  }

  const repositionSecondaryListOnOverflow = () => {
    // added something quick to prevent overflowing on the left side for mobile screens
    // @TODO: maybe improve this function with something nice

    const { x } = moreList.value.getBoundingClientRect()
    if (x < 0) {
      moreList.value.style = `left: 0`
    }
  }

  // toggle to show/hide the secondary list
  const isMoreOpen = ref(false)
  const openSecondaryList = () => {
    isMoreOpen.value = true
    nextTick(() => {
      repositionSecondaryListOnOverflow()
    })
  }
  const closeSecondaryList = () => {
    if (isMoreOpen.value) {
      isMoreOpen.value = false
      nextTick(() => {
        toggleIssuesWarning()
      })
    }
  }

  // this splits the slot content into two lists, one displayed as regular tabs, the other displayed as a dropdown after clicking the more button
  const generateSplitLayout = () => {
    // display all items before calculating the split
    list.value
      .querySelectorAll('li')
      .forEach((item) => item.classList.remove('hidden', 'tab--first', 'tab--last'))

    // get a starting value that depends on wether the more button is already displayed or not
    const moreButtonWidth = moreToggle.value.offsetWidth + TABS_SPACING * 2
    const $list = list.value
    const $moreList = moreList.value
    const listWidth = $list.offsetWidth - TABS_SPACING
    const renderedItems = Array.from($list.children)
    const renderedSecondaryItems = Array.from($moreList.children)

    let stopWidth = moreButtonWidth
    let hasHiddenItems = false

    // remove the last element which is the "more" button and secondary list
    renderedItems.pop()

    // calculate the index at which the two lists need to be split
    let splitIndex = renderedItems.findIndex(($el) => {
      if (listWidth >= stopWidth + $el.offsetWidth + TABS_SPACING) {
        stopWidth += $el.offsetWidth + TABS_SPACING
        return false
      }
      return true
    })

    if (splitIndex >= 0) {
      hasHiddenItems = true
    } else {
      splitIndex = renderedItems.length
    }

    if (!hasHiddenItems) {
      // hide the more toggle button if there are no hidden items
      moreToggle.value.parentElement.classList.add('hidden')
    } else {
      // hide the elements after the split index in the primary list
      renderedItems.forEach(($el, index) => {
        if (index >= splitIndex) $el.classList.add('hidden')
      })
      // hide the elements before the split index in the secondary list
      renderedSecondaryItems.forEach(($el, index, list) => {
        if (index < splitIndex) {
          $el.classList.add('hidden')
        }
        // add classes to help style the rounded corners of the first and last visible items
        if (index === splitIndex) {
          $el.classList.add('tab--first')
        }
        if (index === list.length - 1) {
          $el.classList.add('tab--last')
        }
      })
    }

    if (isMoreOpen.value) {
      repositionSecondaryListOnOverflow()
    }
    toggleMoreButtonActiveStatus()
    toggleIssuesWarning()
  }

  onMounted(() => {
    list.value.addEventListener('click', generateSplitLayout)
    /**
     * Weird edge case
     *
     * There are instances in which `onMounted` is called even though the content itself doesn't yet exist.
     *
     * It was found while working with <LoadedContent> slots, which have v-if cases for loading/error/loaded.
     * The loaded state renders the slot and triggers the `onMounted` but the content inside the slot might not yet be rendered.
     *
     * Couldn't find a way to fix <LoadedContent> so a fix was added here, hopefully preventing similar edge cases.
     * Catching the bug is also hard because it can only happen in Incognito browser mode.
     * In real life, it usually happens the first time a user loads the buggy page.
     *
     * Link to bug: https://winnow.atlassian.net/browse/HUB-5056
     */
    nextTick(() => {
      generateSplitLayout()
    })
  })

  onBeforeUnmount(() => {
    list.value.removeEventListener('click', generateSplitLayout)
  })

  const handleResize = () => {
    generateSplitLayout()
  }

  // update the lists on route change for good measure, in case the .click event wasn't enough
  // mainly when navigation happens by clicking on links in the content, not the tabs
  const route = useRoute()
  watch(
    () => route.fullPath,
    () => {
      closeSecondaryList()
      generateSplitLayout()
    }
  )

  return {
    primaryList,
    secondaryList,
    handleResize,
    openSecondaryList,
    closeSecondaryList,
    isMoreButtonActive,
    hasIssues,
    isMoreOpen,
  }
}
