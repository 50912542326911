<template>
  <tr
    class="tr-link"
    :class="{ 'tr-highlight--highlightNeutral': archived }"
    role="link"
    tabindex="0"
    data-test-menu
    @click="$emit('menu-select')"
    @keyup.enter.space="$emit('menu-select')"
  >
    <td>
      {{ organisation }}
    </td>
    <td>
      <div class="overflow-wrap-anywhere">{{ name }}</div>
      <div class="text-sm text-grey-400">{{ description }}</div>
      <div
        v-if="archived"
        class="text-sm text-grey-400"
      >
        Archived
      </div>
    </td>
    <td>
      {{ formatDate(createdDate) }}
      <div class="sr-only">
        {{ $t(`menus.list.messages.state.${error ? 'failed' : activeTask}`) }}
      </div>
      <SvgIcon
        v-if="activeTask === MENU_STATES_TRANSLATE"
        v-tooltip="{
          theme: 'tooltip-info',
          content: $t(`menus.list.messages.state.${MENU_STATES_TRANSLATE}`),
          placement: 'top',
        }"
        class="text-blueberry ml-2 inline-block"
        data-test-menu-not-translated
        aria-hidden="true"
        name="24-ic-info"
      />
      <SvgIcon
        v-else-if="error"
        v-tooltip="{
          theme: 'tooltip-danger',
          content: $t(`menus.list.messages.state.failed`),
          placement: 'top',
        }"
        class="text-blueberry ml-2 inline-block"
        data-test-menu-processing
        aria-hidden="true"
        name="24-ic-info"
      />
      <SvgIcon
        v-else-if="activeTask !== MENU_STATES_PROCESSED"
        v-tooltip="{
          theme: 'tooltip-info',
          content: $t(`menus.list.messages.state.${activeTask}`),
          placement: 'top',
        }"
        class="text-blueberry ml-2 inline-block"
        data-test-menu-processing
        aria-hidden="true"
        name="24-ic-pending"
      />
      <br />
      <span
        v-if="$can('manage', 'menus')"
        class="text-sm text-grey-400"
        >{{ originalAuthor }}</span
      >
    </td>
    <td>
      {{ formatDate(modifiedDate) }}
      <br />
      <span
        v-if="$can('manage', 'menus')"
        class="text-sm text-grey-400"
        >{{ lastUpdateAuthor }}</span
      >
    </td>
    <td
      class="td-small-col"
      data-test-trackers-count
    >
      <template v-if="trackersCount">
        <span
          v-tooltip="$t('menus.list.messages.trackers', trackersCount, { count: trackersCount })"
          class="alert alert--inline alert--outline"
        >
          {{ trackersCount }}
        </span>
      </template>
    </td>
  </tr>
</template>

<script>
import { MENU_STATES, DATE_FORMAT } from '@/store/constants'
import moment from 'moment'

export default {
  props: {
    archived: { type: Boolean, required: false },
    organisation: { type: String, required: true },
    name: { type: String, required: true },
    description: { required: true }, // did not add type because it gets messed up when null, even when adding [String, null]
    activeTask: { type: String, required: true },
    createdDate: { type: String, required: true },
    originalAuthor: { type: String, required: true },
    modifiedDate: { type: String, required: true },
    lastUpdateAuthor: { type: String, required: true },
    trackersCount: { type: Number, required: false },
  },
  emits: ['menu-select'],
  data() {
    return {
      MENU_STATES_PROCESSED: MENU_STATES.PROCESSED,
      MENU_STATES_TRANSLATE: MENU_STATES.TRANSLATE,
      MENU_STATES_ERROR: MENU_STATES.ERROR,
    }
  },
  computed: {
    error() {
      return [
        'create-menu-items-failed',
        'correct-translations-failed',
        'propagate-translations-failed',
        'taxonomy-validation-failed',
        'auto-map-to-taxonomy-code-failed',
        'auto-map-to-images-failed',
      ].includes(this.activeTask)
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format(DATE_FORMAT)
    },
  },
}
</script>

<style></style>
