<template>
  <categories-navigation
    class="box-content-fill-remaining"
    :links="parentNavigation"
    :parent-route="{ name: 'tablet-editor-stage', params: { id: stage.id } }"
    :parent-label="$t('menus.tabletEditor.foodGroup.actions.return')"
    :create-route="{ name: 'tablet-editor-food-group-create' }"
    :create-label="$t('menus.tabletEditor.foodGroups.actions.createNew')"
    data-test-food-group-editor
  >
    <ValidationForm
      class="box"
      ref="form"
      :error-structure="['GENERAL', 'food group name']"
      @submit.prevent="onSubmit"
      @reset.prevent="onCancel"
    >
      <Loaded-content
        :is-loading="foodGroupsLoadingStatus === 'LOADING'"
        :has-error="foodGroupsLoadingStatus === 'ERROR'"
        :has-data="foodGroupsLoadingStatus === 'LOADED'"
      >
        <Breadcrumbs
          :history="[
            { name: 'tablet-editor-stages', title: 'Stages' },
            { name: 'tablet-editor-stage', params: { stageId }, title: stage.displayName },
          ]"
          :current="foodGroupId ? foodGroup.displayName : $route.meta.breadcrumb"
          class="my-4"
        />
        <div class="tablet-editor-page mb-0">
          <div class="tablet-editor-page-generic-fields">
            <!-- Name -->
            <ValidationGroup
              :validator="v$.buffer.name"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="FoodGroupName">
                {{ $t('menus.tabletEditor.foodGroup.labels.name') }}
              </label>
              <input
                id="FoodGroupName"
                v-model.trim="v$.buffer.name.$model"
                type="text"
                class="formControl"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-name-field
                :disabled="readonly"
                @change="onNameChange"
              />
            </ValidationGroup>

            <!-- English name -->
            <ValidationGroup
              v-if="menuIsNonEnglish"
              :validator="v$.buffer.nameEnglish"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="FoodGroupNameEnglish">
                {{ $t('menus.tabletEditor.foodGroup.labels.nameEnglish') }}
              </label>
              <input
                id="FoodGroupNameEnglish"
                v-model.trim="v$.buffer.nameEnglish.$model"
                type="text"
                class="formControl"
                :class="{ 'is-invalid': hasAnyErrors }"
                data-test-name-english-field
                :disabled="readonly"
              />
            </ValidationGroup>

            <!-- Image avatar -->
            <div
              v-if="foodGroupId"
              class="formGroup tablet-editor-page-avatar"
            >
              <p class="tablet-editor-page-avatar-title label">
                {{ $t('labels.image') }}
              </p>
              <Image-loader
                class="tablet-editor-page-avatar-image"
                :src="generateThumbnailUrl(buffer.image.url)"
                :aspect-ratio="0.65"
              />
              <div
                v-if="!readonly"
                class="tablet-editor-page-avatar-actions"
              >
                <button
                  v-if="buffer.image.id"
                  type="button"
                  class="button button--link"
                  data-test-show-image-editor
                  @click="editMode = 'IMAGE_EDIT'"
                >
                  {{ $t('actions.editImage') }}
                </button>
                <button
                  type="button"
                  class="button button--link"
                  data-test-show-image-selector
                  @click="editMode = 'IMAGE'"
                >
                  {{ $t('actions.chooseAnotherImage') }}
                </button>
                <button
                  type="button"
                  class="button button--link"
                  data-test-show-image-uploader
                  @click="editMode = 'IMAGE_UPLOAD'"
                >
                  {{ $t('actions.uploadNew') }}
                </button>
              </div>
            </div>
          </div>

          <Loaded-content
            v-if="editMode === 'FOOD_ITEMS' && foodGroupId"
            :is-loading="foodItemsLoadingStatus === 'LOADING'"
            :has-error="foodItemsLoadingStatus === 'ERROR'"
            :has-data="foodItemsLoadingStatus === 'LOADED'"
          >
            <Chip-selector
              v-model="v$.buffer.foodItems.$model"
              use-search
              :status="availableItemsStatus"
              :show-available="false"
              :available-items="availableFoodItems"
              :available-items-total="availableFoodItemsTotal"
              :available-actions="readonly ? [] : ['add', 'remove']"
              data-test-food-items
              @search="getAvailableFoodItems"
            >
              <template #title>
                <span
                  v-if="!buffer.foodItems.length"
                  data-test-title-no-food-items
                >
                  {{ $t('menus.tabletEditor.foodGroup.titles.noFoodItems') }}
                </span>
                <span
                  v-else
                  data-test-title-food-items
                >
                  {{ $t('menus.tabletEditor.foodGroup.titles.foodItems') }}
                </span>
                <router-link
                  v-if="buffer.foodItems.length"
                  :to="{
                    name: 'tablet-editor-food-items',
                  }"
                >
                  {{ $t('actions.viewAsGrid') }}
                </router-link>
              </template>
              <template #no-data>
                <p data-test-no-more-food-items>
                  {{ $t('menus.tabletEditor.foodGroup.messages.noAvailableItems') }}
                </p>
              </template>
              <template
                v-if="!readonly"
                #custom-chips
              >
                <Chip
                  tag="li"
                  class="chip--squared"
                >
                  <router-link
                    :to="{
                      name: 'food-create',
                      query: {
                        menuId,
                        'default[foodGroupIds]': JSON.stringify([foodGroupId]),
                        returnPath: $route.path,
                      },
                    }"
                    data-test-food-item-create
                  >
                    {{ $t('menus.tabletEditor.foodItems.actions.createNew') }}
                  </router-link>
                </Chip>
              </template>
            </Chip-selector>
          </Loaded-content>
          <Image-selector
            v-if="editMode === 'IMAGE'"
            v-model="v$.buffer.image.$model"
            class="tablet-editor-page-image-selector"
            :url-params="{ menuId: menuId, foodGroupId: foodGroupId }"
            :org-id="menu.parentLocationId"
            @close="editMode = 'FOOD_ITEMS'"
            @save="imageChanged"
          />
          <Image-uploader
            v-if="editMode === 'IMAGE_UPLOAD'"
            class="tablet-editor-page-image-selector"
            :org-id="menu.parentLocationId"
            :aspect-ratio="0.65"
            @close="editMode = 'FOOD_ITEMS'"
            @new-file="imageUploaded"
          />
          <Image-editor
            v-if="editMode === 'IMAGE_EDIT'"
            :id="buffer.image.id"
            :url="buffer.image.url"
            :org-id="menu.parentLocationId"
            :aspect-ratio="0.65"
            @close="editMode = 'FOOD_ITEMS'"
          />
        </div>

        <div
          v-if="!showNameChangeWarning && !readonly"
          class="buttonGroup"
        >
          <button
            type="submit"
            class="button button--primary"
            :disabled="saveDisabled || !v$.$anyDirty"
            data-test-save
          >
            {{ $t('actions.save') }}
          </button>
          <button
            type="reset"
            class="button button--secondary"
            :disabled="saveDisabled || !v$.$anyDirty"
            data-test-reset
          >
            {{ $t('actions.cancel') }}
          </button>
        </div>

        <transition name="slide-up">
          <div
            v-if="showNameChangeWarning"
            class="errorBlock is-warning text-carrot mb-4"
            data-test-rename-warning
          >
            <div class="errorBlock-icon">
              <SvgIcon name="24-ic-warning" />
            </div>
            <p
              v-html="
                $t('messages.itemRenamePhoto', {
                  item: buffer.nameEnglish,
                })
              "
            ></p>
          </div>
        </transition>
        <div
          v-if="showNameChangeWarning"
          class="buttonGroup buttonGroup--halfs"
        >
          <button
            type="button"
            class="button button--secondary"
            data-test-revert-name
            @click.prevent="revertName"
          >
            {{ $t('actions.revert') }}
          </button>
          <button
            type="button"
            class="button button--primary"
            data-test-confirm-name
            @click.prevent="confirmName"
          >
            {{ $t('actions.confirm') }}
          </button>
        </div>

        <!-- Delete food group -->
        <DeleteWithPrompt
          v-if="foodGroupId && !readonly"
          @confirm="deleteFoodGroup"
          ref="delete"
          class="my-4"
        >
          <p>{{ $t('menus.tabletEditor.foodGroup.messages.deletePrompt') }}</p>
        </DeleteWithPrompt>
      </Loaded-content>
    </ValidationForm>
  </categories-navigation>
</template>

<script>
import CategoriesNavigation from '@/components/CategoriesNavigation.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationForm from '@/components/Validation/ValidationForm.vue'
import ImageSelector from './image-selector.vue'
import ImageUploader from './image-uploader.vue'
import ImageEditor from '../TabletEditor/image-editor.vue'

import ImageLoader from '@/components/image-loader.vue'
import Chip from '@/components/Chip/Chip.vue'
import ChipSelector from './chip-selector.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import menuMixin from '../menuMixin'
import returnPathMixin from '@/mixins/returnPathMixin'
import imagesMixin from '@/mixins/images'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { intersection, invert } from 'lodash'
import { useToast } from 'vue-toastification'

function EditModel(overwrites) {
  return {
    name: null,
    nameEnglish: null,
    image: {
      id: null,
      url: null,
    },
    foodItems: [],
    ...overwrites,
  }
}

function FoodItemModel(overwrites) {
  return {
    id: null,
    displayName: null,
    imageUrl: null,
    link: null,
    ...overwrites,
  }
}

function mapFoodItemToChip(foodItem) {
  return new FoodItemModel({
    id: foodItem.id,
    displayName: foodItem.displayName,
    imageUrl: foodItem.imageUrl,
    hidden: foodItem.hiddenFromTrackers,
    link: {
      name: 'food-editor',
      params: {
        foodItemId: foodItem.id,
      },
      query: {
        menuId: this.menuId,
        returnPath: this.$route.path,
      },
    },
  })
}

export default {
  components: {
    CategoriesNavigation,
    Breadcrumbs,
    LoadedContent,
    ValidationGroup,
    Chip,
    ChipSelector,
    DeleteWithPrompt,
    ImageLoader,
    ImageSelector,
    ImageUploader,
    ImageEditor,
    ValidationForm,
  },
  mixins: [menuMixin, returnPathMixin, imagesMixin],
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      buffer: new EditModel(),
      showNameChangeWarning: false,
      saveDisabled: false,
      confirmDelete: false,
      editMode: 'FOOD_ITEMS',
      foodItems: [],
      availableFoodItems: [],
      availableFoodItemsTotal: 0,
      availableItemsStatus: 'IDLE',
      foodItemsLoadingStatus: 'IDLE',
      foodGroupsLoadingStatus: 'IDLE',
      inputOutput: {
        name: 'name',
        nameEnglish: 'nameEnglish',
        foodItems: 'foodItemIds',
        image: 'imageId',
      },
    }
  },
  computed: {
    readonly() {
      return this.menu && this.menu.archived
    },
    parentNavigation() {
      return this.foodGroupsInStage.map((foodGroup) => {
        return {
          id: foodGroup.id,
          name: foodGroup.name,
          imageUrl: foodGroup.imageUrl,
          route: {
            name: 'tablet-editor-food-group',
            params: {
              foodGroupId: foodGroup.id,
            },
          },
        }
      })
    },
    stageId() {
      return this.$route.params.stageId
    },
    stage() {
      return this.$store.getters['menu-management/stages/stageById'](this.stageId)
    },
    foodGroupsInStage() {
      return this.$store.getters['menu-management/stages/foodGroupsInStage'](this.stageId)
    },
    foodGroupId() {
      return this.$route.params.foodGroupId
    },
    foodGroup() {
      return this.$store.getters['menu-management/food-groups/foodGroupById'](this.foodGroupId)
    },
    returnToStagePath() {
      return {
        name: 'tablet-editor-stage',
        params: {
          menuId: this.menuId,
          stageId: this.stageId,
        },
      }
    },
  },
  watch: {
    foodGroupsStatus(status) {
      if (status === 'SAVING_ERROR') {
        this.toast.error(this.$t('toast.error.saveData'))
      }
    },
  },
  created() {
    this.prepopulateFields()
    this.getFoodGroup().then(this.getFoodItems)
    this.getAvailableFoodItems()
  },
  validations() {
    return {
      buffer: {
        name: {
          required,
        },
        nameEnglish: this.menuIsNonEnglish && {
          required,
        },
        foodItems: {},
        image: {},
      },
    }
  },
  methods: {
    onNameChange() {
      if (!this.menuIsNonEnglish) {
        this.buffer.nameEnglish = this.buffer.name
      }
      if (this.foodGroup && this.v$.buffer.name.$model !== this.foodGroup.name) {
        let hasImage = !!this.foodGroup.imageId
        this.showNameChangeWarning = hasImage
      } else {
        this.showNameChangeWarning = false
      }
    },
    confirmName() {
      this.showNameChangeWarning = false
    },
    revertName() {
      this.buffer.name = this.foodGroup.name
      this.buffer.nameEnglish = this.foodGroup.nameEnglish
      this.showNameChangeWarning = false
    },
    getFoodGroup() {
      this.foodGroupsLoadingStatus = 'LOADING'
      return this.$store
        .dispatch('menu-management/food-groups/getFoodGroups', { menuId: this.menuId })
        .then(() => {
          this.prepopulateFields()
          this.foodGroupsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.foodGroupsLoadingStatus = 'ERROR'
        })
    },
    getFoodItems() {
      // get food items
      this.foodItemsLoadingStatus = 'LOADING'
      return this.$store
        .dispatch('menu-management/food-items/getFoodItems', {
          menuId: this.menuId,
          foodGroupId: this.foodGroupId,
        })
        .then(({ foodItemIds }) => {
          this.$store.commit('menu-management/food-groups/UPDATE_FOOD_GROUP', {
            id: this.foodGroupId,
            foodItemIds,
          })
          this.foodItems = this.$store.getters[
            'menu-management/food-groups/manualOrderedFoodItems'
          ](this.foodGroupId)
          this.prepopulateFields()
          this.foodItemsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.foodItemsLoadingStatus = 'ERROR'
        })
    },
    // these are for the food items search
    getAvailableFoodItems({ search = '', page = 0 } = {}) {
      this.availableItemsStatus = 'LOADING'
      return this.$store
        .dispatch('menu-management/food-items/getFoodItems', {
          menuId: this.menuId,
          query: {
            page: page,
            size: 20,
            search: search || undefined,
          },
        })
        .then(({ foodItemIds, total }) => {
          if (page) {
            foodItemIds.unshift(...this.menu.foodItemIds)
          }
          this.$store.commit('menu-management/menus/UPDATE_MENU', {
            id: this.menuId,
            foodItemIds,
          })
          let availableFoodItems =
            this.$store.getters['menu-management/food-items/foodItemsById'](foodItemIds)
          this.availableFoodItems = availableFoodItems.map((foodItem) =>
            mapFoodItemToChip.call(this, foodItem)
          )
          this.availableFoodItemsTotal = total
          this.availableItemsStatus = 'LOADED'
        })
        .catch(() => {
          this.availableItemsStatus = 'ERROR'
        })
    },
    onSubmit() {
      this.v$.$touch()
      if (this.v$.buffer.$invalid) return
      this.$refs.form.clearServerMessages()
      this.saveDisabled = true

      let updates = this.generateSavePayload()
      if (this.foodGroupId) {
        this.saveChanges({ data: updates })
      } else {
        this.createNew({ name: updates.name, nameEnglish: updates.nameEnglish }).finally(() => {
          this.saveDisabled = false
        })
      }
    },

    generateSavePayload({ props = [] } = {}) {
      props = props.length ? props : Object.values(this.inputOutput)
      let data = {
        name: this.buffer.name,
        nameEnglish: this.buffer.nameEnglish,
        foodItemIds: this.buffer.foodItems.length
          ? this.buffer.foodItems.map((item) => item.id)
          : null,
        imageId: this.buffer.image.id || null,
      }
      return intersection(props, Object.keys(data)).reduce((saveData, key) => {
        saveData[key] = data[key]
        return saveData
      }, {})
    },
    saveChanges({ data }) {
      return this.$store
        .dispatch('menu-management/food-groups/saveChanges', {
          menuId: this.menuId,
          foodGroupId: this.foodGroupId,
          data,
        })
        .then(() => {
          let outputInput = invert(this.inputOutput)
          Object.keys(data).forEach((key) => {
            this.v$.buffer[outputInput[key]].$reset()
          })
          let remainingDirtyFields = Object.keys(this.inputOutput).filter((key) => {
            return this.v$.buffer[key].$anyDirty
          })
          if (!remainingDirtyFields.length) {
            this.v$.$reset()
          }
          this.saveDisabled = false
          this.toast.success(this.$t('menus.tabletEditor.foodGroup.messages.saveSuccess'))

          this.goBack()
        })
        .catch((error) => {
          this.saveDisabled = false
          this.$refs.form.handleValidationErrorsInResponse(error)
        })
    },
    createNew(data) {
      return this.$store
        .dispatch('menu-management/food-groups/createFoodGroup', {
          menuId: this.menuId,
          stageId: this.stageId,
          data,
        })
        .then((newGroup) => {
          this.$router.push({
            name: 'tablet-editor-food-group',
            params: { ...this.$route.params, foodGroupId: newGroup.id },
          })
          this.$nextTick(() => {
            this.toast.success(this.$t('menus.tabletEditor.foodGroup.messages.createSuccess'))
          })
        })
        .catch(this.$refs.form.handleValidationErrorsInResponse)
    },
    onCancel() {
      this.v$.$reset()
      this.prepopulateFields()
      if (!this.foodGroupId) {
        this.goBack()
      }
      this.v$.$reset()
    },
    prepopulateFields() {
      let { foodGroup, foodItems } = this
      if (!foodGroup) this.buffer = new EditModel()
      else
        this.buffer = new EditModel({
          ...foodGroup,
          image: {
            id: foodGroup.imageId,
            url: foodGroup.imageUrl,
          },
          foodItems: foodItems.map((foodItem) => mapFoodItemToChip.call(this, foodItem)),
        })
    },
    deleteFoodGroup() {
      return this.$store
        .dispatch('menu-management/food-groups/deleteFoodGroup', {
          menuId: this.menuId,
          stageId: this.stageId,
          foodGroupId: this.foodGroupId,
        })
        .then(() => {
          this.$router.push(this.returnToStagePath)
          this.$nextTick(() => {
            this.toast.success(this.$t('menus.tabletEditor.foodGroup.messages.deleteSuccess'))
          })
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.delete'))
        })
        .finally(() => {
          this.$refs.delete.finishProcessing()
        })
    },
    imageUploaded({ id, file }) {
      this.buffer.image = {
        id,
        url: URL.createObjectURL(file),
      }
      this.editMode = 'FOOD_ITEMS'
      this.saveChanges({ data: this.generateSavePayload({ props: ['imageId'] }) })
    },
    imageChanged({ id, url }) {
      this.buffer.image = { id, url }
      this.editMode = 'FOOD_ITEMS'
      this.saveChanges({ data: this.generateSavePayload({ props: ['imageId'] }) })
    },
  },
}
</script>

<style lang="scss"></style>
