<script>
import { mapGetters } from 'vuex'
import { difference } from 'lodash'
import { decimal } from '@vuelidate/validators'
import { isBlank } from '@/utils/string'
import { transactionTypes } from '@/store/modules/waste-log'
import { useVuelidate } from '@vuelidate/core'

import FilterChip from '@/components/FilterChip.vue'
import ValidationGroup from '@/components/Validation/ValidationGroupFirstError.vue'
import MultiSelect from '@/components/MultiSelect.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'

const serialize = (obj) => {
  let final = {}

  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    final[key] = isBlank(value) ? undefined : value
  })

  return final
}

const isEqual = (a, b) => {
  if (isBlank(a) && isBlank(b)) {
    return true
  }

  return parseFloat(a) === parseFloat(b)
}

const truncateDecimals = (num, digits = 2) => {
  const re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)')
  const m = num.toString().match(re)
  return m ? parseFloat(m[1]) : num
}

const positive = (value) => (!isBlank(value) ? value > 0 : true)
const minMax = (otherKey) => (value, vm) => {
  if (
    isBlank(value) ||
    isBlank(vm[otherKey]) ||
    !decimal.$validator(value) ||
    !decimal.$validator(vm[otherKey])
  ) {
    return true
  }

  return parseFloat(value) <= parseFloat(vm[otherKey])
}

const arrayToString = (data) => {
  let chunk = data
  const length = chunk.length

  if (length > 2) {
    chunk = chunk.slice(0, 2)
    chunk.push(`+${length - 2}`)
  }

  return chunk.join(', ')
}

export default {
  components: {
    FilterChip,
    ValidationGroup,
    MultiSelect,
    CollapsibleSection,
  },
  props: {
    preopenFilters: Boolean,
    weightUnit: String,
    currency: String,
    meta: Object,
    filters: {
      type: Object,
      default: () => ({
        minWeight: undefined,
        maxWeight: undefined,
        minCost: undefined,
        maxCost: undefined,
        types: [...transactionTypes],
        foodItems: [],
        stages: [],
        trackers: [],
        status: undefined,
        categorised: undefined,
      }),
    },
  },
  emits: ['submit'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      weightRange: !isEqual(this.filters.minWeight, this.filters.maxWeight),
      costRange: !isEqual(this.filters.minCost, this.filters.maxCost),

      buffer: { ...this.filters },
      transactionTypes,

      includeUncategorised: this.filters.categorised !== true,
      includeModified: this.filters.status !== 'new',
    }
  },
  validations() {
    return {
      buffer: {
        minWeight: {
          decimal,
          positive,
          minMax: minMax('maxWeight'),
        },
        maxWeight: {
          decimal,
          positive,
        },
        minCost: {
          decimal,
          positive,
          minMax: minMax('maxCost'),
        },
        maxCost: {
          decimal,
          positive,
        },
      },
    }
  },

  computed: {
    ...mapGetters('waste-log', {
      foodItems: 'filterFoodItems',
      stages: 'filterStages',
      trackers: 'filterTrackers',

      foodItemsLoaded: 'filterFoodItemsLoaded',
      stagesLoaded: 'filterStagesLoaded',
      trackersLoaded: 'filterTrackersLoaded',
    }),

    formattedFoodItems() {
      return arrayToString(
        this.filters.foodItems.map((appliedItem) => {
          const item = this.foodItems.find((item) => item.name === appliedItem)
          return item && item.nameLocalised
        })
      )
    },

    formattedStages() {
      return arrayToString(
        this.filters.stages.map((appliedStage) => {
          const stage = this.stages.find((stage) => stage.name === appliedStage)
          return stage && stage.nameLocalised
        })
      )
    },

    formattedTrackers() {
      return arrayToString(
        this.filters.trackers
          .map(
            (id) =>
              this.meta.tracker.find((el) => el.id.toString() === id.toString()) ||
              this.trackers.find((el) => el.id.toString() === id.toString()) || { name: id }
          )
          .map((el) => el.name)
      )
    },

    formattedHiddenTypes() {
      return arrayToString(
        difference(transactionTypes, this.buffer.types).map((key) => this.$t('waste.' + key))
      )
    },

    isFoodItemLoading() {
      return !this.meta.foodItem
    },

    isStageLoading() {
      return !this.meta.stage
    },

    isTrackerLoading() {
      return !this.meta.tracker
    },

    formattedIncludes() {
      let base = []
      if (this.filters.categorised === true) {
        base.push('Uncategorised')
      }
      if (this.filters.status === 'new') {
        base.push('Modified')
      }

      return arrayToString(base)
    },

    typesDirty() {
      return difference(transactionTypes, this.buffer.types).length > 0
    },

    includesDirty() {
      return this.filters.categorised === true || this.filters.status === 'new'
    },

    weightDirty() {
      if (this.v$.buffer.minWeight.$invalid || this.v$.buffer.maxWeight.$invalid) {
        return false
      }

      return (
        !isEqual(this.buffer.minWeight, this.filters.minWeight) ||
        !isEqual(this.buffer.maxWeight, this.filters.maxWeight)
      )
    },

    costDirty() {
      if (this.v$.buffer.minCost.$invalid || this.v$.buffer.maxCost.$invalid) {
        return false
      }

      return (
        !isEqual(this.buffer.minCost, this.filters.minCost) ||
        !isEqual(this.buffer.maxCost, this.filters.maxCost)
      )
    },
  },
  methods: {
    toggleWeightRange() {
      if (this.weightRange) {
        this.weightRange = false
        this.buffer.maxWeight = this.buffer.minWeight
      } else {
        this.weightRange = true
        this.buffer.maxWeight = undefined
      }
    },

    toggleCostRange() {
      if (this.costRange) {
        this.costRange = false
        this.buffer.maxCost = this.buffer.minCost
      } else {
        this.costRange = true
        this.buffer.maxCost = undefined
      }
    },

    submitTypes() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          types: this.buffer.types,
        })
      )
    },

    submitCategorised() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          categorised: !this.includeUncategorised ? true : undefined,
        })
      )
    },

    submitModified() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          status: !this.includeModified ? 'new' : undefined,
        })
      )
    },

    submitIncludes() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          categorised: !this.includeUncategorised ? true : undefined,
          status: !this.includeModified ? 'new' : undefined,
        })
      )
    },

    submitWeight() {
      this.v$.buffer.minWeight.$touch()
      this.v$.buffer.maxWeight.$touch()

      if (this.v$.buffer.minWeight.$invalid || this.v$.buffer.maxWeight.$invalid) {
        return
      }

      if (!isBlank(this.buffer.minWeight)) {
        this.buffer.minWeight = truncateDecimals(this.buffer.minWeight)
      }
      if (!isBlank(this.buffer.maxWeight)) {
        this.buffer.maxWeight = truncateDecimals(this.buffer.maxWeight)
      }

      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          minWeight: this.buffer.minWeight,
          maxWeight: this.buffer.maxWeight,
        })
      )
    },

    submitCost() {
      this.v$.buffer.minCost.$touch()
      this.v$.buffer.maxCost.$touch()

      if (this.v$.buffer.minCost.$invalid || this.v$.buffer.maxCost.$invalid) {
        return
      }

      if (!isBlank(this.buffer.minCost)) {
        this.buffer.minCost = truncateDecimals(this.buffer.minCost)
      }
      if (!isBlank(this.buffer.maxCost)) {
        this.buffer.maxCost = truncateDecimals(this.buffer.maxCost)
      }

      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          minCost: this.buffer.minCost,
          maxCost: this.buffer.maxCost,
        })
      )
    },

    submitFoodItems() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          foodItems: this.buffer.foodItems,
        })
      )
    },

    submitStages() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          stages: this.buffer.stages,
        })
      )
    },

    submitTrackers() {
      this.$emit(
        'submit',
        serialize({
          ...this.filters,
          trackers: this.buffer.trackers,
        })
      )
    },

    // assumes at least 1 param is valid
    formatRange(min, max) {
      if (min === undefined) {
        return `Max: ${max}`
      }
      if (max === undefined) {
        return `Min: ${min}`
      }
      if (min === max) {
        return min
      }
      return `${min}–${max}`
    },
  },
}
</script>

<template>
  <div class="-mx-4 mb-2">
    <collapsible-section
      :force-open="preopenFilters"
      @open="$router.push({ query: { ...$route.query, preopenFilters: true } })"
      @close="$router.push({ query: { ...$route.query, preopenFilters: undefined } })"
    >
      <template #header>
        {{ $t('titles.advancedFilters') }}
      </template>
      <template #header-side>
        <div
          class="advanced-current"
          data-test-wl-filters-chips
        >
          <FilterChip
            v-if="typesDirty"
            :removable="true"
            @remove="
              () => {
                buffer.types = [...transactionTypes]
                submitTypes()
              }
            "
          >
            {{ $t('filters.WL.hideChip', { item: formattedHiddenTypes }) }}
          </FilterChip>
          <FilterChip
            v-if="includesDirty"
            :removable="true"
            @remove="
              () => {
                includeUncategorised = true
                includeModified = true
                submitIncludes()
              }
            "
          >
            {{ $t('filters.WL.hideChip', { item: formattedIncludes }) }}
          </FilterChip>
          <FilterChip
            v-if="filters.foodItems.length"
            :is-loading="isFoodItemLoading"
            :removable="true"
            @remove="
              () => {
                buffer.foodItems = []
                submitFoodItems()
              }
            "
          >
            <Loading-Spinner v-if="isFoodItemLoading" />
            <div v-else>{{ $t('filters.WL.itemChip', { item: formattedFoodItems }) }}</div>
          </FilterChip>
          <FilterChip
            v-if="filters.stages.length"
            :is-loading="isStageLoading"
            :removable="true"
            @remove="
              () => {
                buffer.stages = []
                submitStages()
              }
            "
          >
            <Loading-Spinner v-if="isStageLoading" />
            <div v-else>{{ $t('filters.WL.stageChip', { item: formattedStages }) }}</div>
          </FilterChip>
          <FilterChip
            v-if="filters.trackers.length"
            :is-loading="isTrackerLoading"
            :removable="true"
            @remove="
              () => {
                buffer.trackers = []
                submitTrackers()
              }
            "
          >
            <Loading-Spinner v-if="isTrackerLoading" />
            <div v-else>{{ $t('filters.WL.trackerChip', { item: formattedTrackers }) }}</div>
          </FilterChip>
          <FilterChip
            v-if="filters.minWeight !== undefined || filters.maxWeight !== undefined"
            :removable="true"
            @remove="
              () => {
                buffer.minWeight = undefined
                buffer.maxWeight = undefined
                submitWeight()
              }
            "
          >
            {{ formatRange(filters.minWeight, filters.maxWeight) }}
            {{ $t('settings.weight.short.' + weightUnit) }}
          </FilterChip>
          <FilterChip
            v-if="filters.minCost !== undefined || filters.maxCost !== undefined"
            :removable="true"
            @remove="
              () => {
                buffer.minCost = undefined
                buffer.maxCost = undefined
                submitCost()
              }
            "
          >
            {{ formatRange(filters.minCost, filters.maxCost) }} {{ currency }}
          </FilterChip>
        </div>
      </template>

      <div class="advanced-form">
        <div class="mb-5">
          <div class="advanced-title">
            <p class="font-bold mr-4">{{ $t('filters.WL.include') }}</p>
            <div
              v-if="buffer.types.length === 1"
              class="newAlert"
            >
              {{ $t('filters.WL.minimumInGroup') }}
            </div>
          </div>

          <div class="advanced-checkbox-wrapper">
            <div class="advanced-checkbox-group flex gap-4">
              <template
                v-for="type in transactionTypes"
                :key="type"
              >
                <div class="customControl customControl--checkbox">
                  <input
                    :id="`filter-type-${type}`"
                    v-model="buffer.types"
                    data-test-wl-filter-type
                    type="checkbox"
                    class="customControl-input"
                    :value="type"
                    :disabled="buffer.types.length === 1 && buffer.types.includes(type)"
                    @change="submitTypes"
                  />
                  <label
                    class="customControl-label"
                    :for="`filter-type-${type}`"
                  >
                    {{ $t('waste.' + type) }}
                  </label>
                </div>
              </template>
            </div>

            <div class="advanced-checkbox-group flex gap-4">
              <div class="customControl customControl--checkbox">
                <input
                  id="filter-include-uncategorised"
                  v-model="includeUncategorised"
                  data-test-wl-filter-include-uncategorised
                  type="checkbox"
                  class="customControl-input"
                  :disabled="filters.categorised === false"
                  @change="submitCategorised"
                />
                <label
                  class="customControl-label"
                  for="filter-include-uncategorised"
                  >{{ $t('titles.uncategorised') }}</label
                >
              </div>
              <div class="customControl customControl--checkbox">
                <input
                  id="filter-include-modified"
                  v-model="includeModified"
                  type="checkbox"
                  class="customControl-input"
                  :disabled="['deleted', 'modified'].includes(filters.status)"
                  @change="submitModified"
                />
                <label
                  class="customControl-label"
                  for="filter-include-modified"
                  >{{ $t('titles.modified') }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="advanced-row mb-5">
          <div class="advanced-col">
            <div class="flex flex-middle">
              <label for="filter-food-item">
                {{ $t('titles.foodItem') }}
              </label>
              <Loading-Spinner
                v-if="!foodItemsLoaded"
                class="spinner--xs mb-1 ml-2"
              />
            </div>

            <MultiSelect
              id="filter-food-item"
              data-test-wl-filter-food-item
              v-model="buffer.foodItems"
              model-value-type="LIST_OF_STRINGS"
              :allow-empty="true"
              :options="foodItems"
              track-by="name"
              label="nameLocalised"
              :placeholder="
                buffer.foodItems.length
                  ? $t('filters.numApplied', { count: buffer.foodItems.length })
                  : $t('filters.add')
              "
              :disabled="!foodItemsLoaded"
              @update:modelValue="submitFoodItems"
            />
          </div>

          <div class="advanced-col">
            <div class="flex flex-middle">
              <label for="filter-stage">
                {{ $t('titles.stage') }}
              </label>
              <Loading-Spinner
                v-if="!stagesLoaded"
                class="spinner--xs mb-1 ml-2"
              />
            </div>

            <MultiSelect
              id="filter-stage"
              data-test-wl-filter-stage
              v-model="buffer.stages"
              model-value-type="LIST_OF_STRINGS"
              :allow-empty="true"
              :options="stages"
              track-by="name"
              label="nameLocalised"
              :placeholder="
                buffer.stages.length
                  ? $t('filters.numApplied', { count: buffer.stages.length })
                  : $t('filters.add')
              "
              :disabled="!stagesLoaded"
              @update:modelValue="submitStages"
            />
          </div>

          <div class="advanced-col">
            <div class="flex flex-middle">
              <label for="filter-tracker">
                {{ $t('titles.tracker') }}
              </label>
              <Loading-Spinner
                v-if="!trackersLoaded"
                class="spinner--xs mb-1 ml-2"
              />
            </div>

            <MultiSelect
              id="filter-tracker"
              data-test-wl-filter-tracker
              v-model="buffer.trackers"
              model-value-type="LIST_OF_STRINGS"
              :allow-empty="true"
              :options="trackers.map((tracker) => ({ ...tracker, id: tracker.id.toString() }))"
              track-by="id"
              label="name"
              :placeholder="
                buffer.trackers.length
                  ? $t('filters.numApplied', { count: buffer.trackers.length })
                  : $t('filters.add')
              "
              :disabled="!trackersLoaded"
              @update:modelValue="submitTrackers"
            />
          </div>
        </div>
        <div class="advanced-row">
          <form
            class="advanced-col"
            @submit.prevent="submitWeight()"
          >
            <div class="flex flex-between items-center">
              <label for="filter-min-weight">
                {{ `${$t('titles.weight')} (${$t('settings.weight.short.' + weightUnit)})` }}
              </label>
              <div class="advanced-rangeToggle mb-1">
                <a
                  href
                  @click.prevent="toggleWeightRange"
                  >{{ weightRange ? $t('filters.exact') : $t('filters.range') }}</a
                >

                <v-tooltip
                  theme="tooltip-white"
                  placement="bottom-end"
                >
                  <button
                    type="button"
                    class="button p-0 align-bottom border-none"
                  >
                    <SvgIcon name="24-ic-info" />
                  </button>
                  <template #popper>
                    <div class="advanced-popover">
                      <p v-html="$t('filters.rangePopup')"></p>
                    </div>
                  </template>
                </v-tooltip>
              </div>
            </div>
            <div class="flex advanced-group">
              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                class="w-full"
                :validator="v$.buffer.minWeight"
              >
                <input
                  id="filter-min-weight"
                  v-model="v$.buffer.minWeight.$model"
                  data-test-wl-filter-weight
                  type="text"
                  maxlength="10"
                  autocomplete="off"
                  :class="[
                    'formControl pr-8',
                    {
                      'is-invalid': hasAnyErrors,
                    },
                  ]"
                  :placeholder="
                    weightRange
                      ? $t('filters.WL.weightMinimum')
                      : $t('filters.WL.weightInUnit', {
                          unit: $t('settings.weight.short.' + weightUnit),
                        })
                  "
                  @input="
                    ($event) => {
                      if (!weightRange) {
                        buffer.maxWeight = $event.target.value
                      }
                    }
                  "
                />
              </ValidationGroup>
              <ValidationGroup
                v-if="weightRange"
                v-slot="{ hasAnyErrors }"
                class="w-full ml-2"
                :validator="v$.buffer.maxWeight"
              >
                <input
                  id="filter-max-weight"
                  v-model="v$.buffer.maxWeight.$model"
                  type="text"
                  maxlength="10"
                  autocomplete="off"
                  :class="[
                    'formControl pr-8',
                    {
                      'is-invalid': hasAnyErrors || !v$.buffer.minWeight.minMax,
                    },
                  ]"
                  :placeholder="$t('filters.WL.weightMaximum')"
                  @input="v$.buffer.minWeight.$touch()"
                />
              </ValidationGroup>
              <button
                v-if="weightDirty"
                data-test-wl-filter-submit-weight
                type="submit"
                class="button button--primary button--inputAction"
              >
                <SvgIcon name="24-ic-check" />
              </button>
            </div>
          </form>

          <form
            class="advanced-col"
            @submit.prevent="submitCost()"
          >
            <div class="flex flex-between items-center">
              <label for="filter-min-cost">{{ $t('titles.value') }} ({{ currency }})</label>
              <div class="advanced-rangeToggle mb-1">
                <a
                  href
                  @click.prevent="toggleCostRange"
                  >{{ costRange ? $t('filters.exact') : $t('filters.range') }}</a
                >

                <v-tooltip
                  theme="tooltip-white"
                  placement="bottom-end"
                >
                  <button
                    type="button"
                    class="button p-0 align-bottom border-none"
                  >
                    <SvgIcon name="24-ic-info" />
                  </button>
                  <template #popper>
                    <div class="advanced-popover">
                      <p v-html="$t('filters.rangePopup')"></p>
                    </div>
                  </template>
                </v-tooltip>
              </div>
            </div>
            <div class="flex advanced-group">
              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                class="w-full"
                :validator="v$.buffer.minCost"
              >
                <input
                  id="filter-min-cost"
                  v-model="v$.buffer.minCost.$model"
                  data-test-wl-filter-cost
                  type="text"
                  maxlength="10"
                  autocomplete="off"
                  :class="[
                    'formControl pr-8',
                    {
                      'is-invalid': hasAnyErrors,
                    },
                  ]"
                  :placeholder="
                    costRange
                      ? $t('filters.WL.costMinimum')
                      : $t('filters.WL.costInUnit', { unit: currency })
                  "
                  @input="
                    ($event) => {
                      if (!costRange) {
                        buffer.maxCost = $event.target.value
                      }
                    }
                  "
                />
              </ValidationGroup>
              <ValidationGroup
                v-if="costRange"
                v-slot="{ hasAnyErrors }"
                class="w-full ml-2"
                :validator="v$.buffer.maxCost"
              >
                <input
                  id="filter-max-cost"
                  v-model="v$.buffer.maxCost.$model"
                  type="text"
                  maxlength="10"
                  autocomplete="off"
                  :class="[
                    'formControl pr-8',
                    {
                      'is-invalid': hasAnyErrors || !v$.buffer.minCost.minMax,
                    },
                  ]"
                  :placeholder="$t('filters.WL.costMaximum')"
                  @input="v$.buffer.minCost.$touch()"
                />
              </ValidationGroup>
              <button
                v-if="costDirty"
                data-test-wl-filter-submit-cost
                type="submit"
                class="button button--primary button--inputAction"
              >
                <SvgIcon name="24-ic-check" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </collapsible-section>
  </div>
</template>

<style lang="scss">
.advanced-current {
  margin: 0 calc(-1 * theme('spacing.1'));
  display: flex;
  flex-wrap: wrap;
}

.advanced-form {
  color: theme('colors.grey.900');

  label {
    color: theme('colors.grey.900');
    font-weight: theme('fontWeight.bold');
  }

  .customControl-label {
    font-weight: theme('fontWeight.normal');
  }

  .customControl-input:disabled ~ .customControl-label {
    color: theme('colors.grey.400');
  }
}
.advanced-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  gap: theme('spacing.4');
  &::before {
    content: '';
    border-left: 1px solid theme('colors.slate.DEFAULT');
    border-bottom: 1px solid theme('colors.slate.DEFAULT');
    align-self: stretch;
    justify-self: stretch;
  }
}
@media (min-width: theme('screens.xl')) {
  .advanced-checkbox-wrapper {
    flex-direction: row;
  }
}

.advanced-checkbox-group:first-child {
  order: -1;
}

.advanced-group {
  position: relative;
}

.advanced-row {
  margin: theme('spacing.9') calc(-1 * theme('spacing.4'));
}

.advanced-col {
  padding: 0 theme('spacing.4');

  & + & {
    margin-top: theme('spacing.4');
  }
}

.advanced-rangeToggle {
  display: flex;
  align-items: center;
  gap: theme('spacing.1');
  a {
    text-decoration: underline;
  }
}

.advanced-popover {
  color: theme('colors.grey.900');
  font-size: theme('fontSize.sm');
}

@include respond(medium) {
  .advanced-row {
    display: flex;
  }

  .advanced-col {
    flex: 1 1 50%;
    max-width: to-rem(360px);

    & + & {
      margin-top: 0;
    }
  }
}

.advanced-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.advanced-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  p {
    padding: theme('spacing[1.5]') 0;
  }
}

.newAlert {
  padding: theme('spacing.1') theme('spacing.4');
  background: theme('colors.white');
  border: 1px solid theme('colors.blueberry.hsluv');
  border-radius: theme('spacing.12');
  color: theme('colors.acai.DEFAULT');
  font-weight: theme('fontWeight.bold');
  font-size: theme('fontSize.sm');
}
</style>
