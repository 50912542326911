<template>
  <div
    data-test-datepicker
    class="formGroup datepicker-trigger"
  >
    <DatePicker
      v-model="range"
      is-range
      color="indigo"
      class="datepicker"
      :columns="2"
      :step="1"
      transition="slide-h"
      :disabled-dates="{
        start: end,
      }"
      :popover="{
        placement: 'bottom-start',
        visibility: 'focus',
      }"
      :locale="userLocale"
      :masks="datePickerMasks"
      :model-config="datePickerModelConfig"
      :timezone="timezone"
    >
      <template #default="{ inputValue, inputEvents }">
        <div class="flex flex-middle -mx-1">
          <div class="formGroup formGroup--with-icon mx-1 w-full">
            <label
              class="sr-only"
              :for="`${inputId}`"
              >{{ inputLabel }}</label
            >
            <!-- Make field readonly for now as the datepicker is buggy: https://github.com/nathanreyes/v-calendar/issues/1270 -->
            <input
              :id="`${inputId}`"
              :value="`${moment(inputValue.start).format(dateFormat)} - ${moment(
                inputValue.end
              ).format(dateFormat)}`"
              class="formControl force-enabled-display"
              readonly
              v-on="inputEvents.end"
            />
            <SvgIcon name="24-ic-date" />
          </div>
        </div>
      </template>
    </DatePicker>
  </div>
</template>
<script>
import { DatePicker } from 'v-calendar'
import { DATE_FORMAT } from '@/store/constants'
import moment from 'moment-timezone'
export default {
  name: 'HubDatepicker',
  components: {
    DatePicker,
  },
  props: {
    // format to display the date range once selected (e.g. 'D MMM YYYY')
    dateFormat: {
      type: String,
      default: DATE_FORMAT,
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    minDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    timezone: {
      type: String,
      default: undefined,
    },
    // customize datepicker
    inputClasses: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
  },
  emits: ['select-date-range'],
  data() {
    return {
      minDatePicker: this.minDate,
      end: this.endDate,
      endDatePicker: this.endDate,
      range: {
        start: this.dateFrom,
        end: this.dateTo,
      },
      showPicker: true,
      datePickerMasks: {
        // this outputs dates formatted as ISO 8601
        // we prefer this so that moment can use it to format according to locale
        input: ['iso'],
      },
      datePickerModelConfig: {
        // data inputs into datepicker should be dates as iso strings
        start: {
          type: 'string',
          mask: 'iso',
        },
        end: {
          type: 'string',
          mask: 'iso',
        },
      },
      allowRangeEventEmitter: true,
    }
  },
  computed: {
    userLocale() {
      return this.$store.getters['auth/locale']
    },
  },
  watch: {
    /**
     * This is our "event handler" for when the dates get changed in v-calendar
     *
     * We need to work around the fact that we don't have any actual events fired when the user manually selects dates.
     * Problem is, any manual change or prop update will trigger this watcher. That means, possible loops:
     * Date change -> emit change event with values -> update props in parent -> props in parent update the props in this component -> Date change...
     *
     * Implemented 2 mechanisms to prevent redundant updates
     * 1. Check old vs new values to make sure actions are done only when changes happened
     * 2. Event blocker (`allowRangeEventEmitter`) that gets turned off whenever `dateFrom` or `dateTo` are updated from the parent component
     *
     * Needed both these checks because of one particular scenario:
     * 1. user selects a date range
     * 2. user wants to reset the date range to defaults
     * 3. user navigates to Waste log / View & edit using the menu
     * 4. dates get reset back to [current day] - [one month before]
     * 5. dateFrom / dateTo get the new values
     * 6. datepicker range updates with dateFrom
     * 7. datepicker range updates with dateTo
     * 8. `range` has the new values and does not emit an updated
     * 9. `range` triggers v-calendar updates
     * 10. v-calendar emits a "new" range that then triggers updates to the parent
     *
     * This all happens because navigating to the same route only changes the query params so the page does not get recreated.
     * On the other hand, we don't want to trigger recreating the page on each query param because that would obviously lead to
     * unnecessary wait times for other params that don't need a full page reset
     */
    range(newRange, oldRange) {
      const { start, end } = newRange
      const { start: oldStart, end: oldEnd } = oldRange
      const rangeChanged = start !== oldStart || end !== oldEnd
      if (this.allowRangeEventEmitter && rangeChanged) {
        this.$emit('select-date-range', {
          startDate: moment(start).startOf('day').toISOString(),
          endDate: moment(end).endOf('day').toISOString(),
        })
      } else {
        this.allowRangeEventEmitter = true
      }
    },
    dateFrom(val) {
      this.allowRangeEventEmitter = false
      this.range = {
        start: val,
        end: this.dateTo,
      }
    },
    dateTo(val) {
      this.allowRangeEventEmitter = false
      this.range = {
        start: this.dateFrom,
        end: val,
      }
    },
  },
  methods: {
    moment,
  },
}
</script>

<style lang="scss">
.datepicker-trigger .vc-container {
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  --text-xs: #{theme('fontSize.sm')};
  --text-sm: theme('fontSize.sm');
  --text-base: theme('fontSize.base');
  --text-lg: theme('fontSize.base');

  --leading-snug: 0;

  --rounded: 0px;
  --rounded-lg: 0px;
  --rounded-full: 0px;

  --shadow: 0;
  --shadow-lg: 0;
  --shadow-inner: 0;

  --slide-translate: theme('spacing.6');
  --slide-duration: 0.15s;
  --slide-timing: ease;

  --day-content-transition-time: 0.13s ease-in;
  --weeknumber-offset: calc(theme('spacing.8') * -1);

  --indigo-200: theme('colors.blueberry.200');
  --indigo-600: theme('colors.acai.DEFAULT');
  --blue-600: theme('colors.acai.DEFAULT');
}

.vc-pane {
  min-width: 2.5rem * 7 !important;
}

.vc-day {
  min-height: 2.5rem !important;
}

.vc-day-content {
  width: 100% !important;
  height: 100% !important;
  line-height: 100% !important;
  border: 1px solid theme('colors.grey.100');
}

.vc-highlights + .vc-day-content {
  border: 1px solid theme('colors.acai.100');
}

.vc-highlight {
  width: 100% !important;
  height: 100% !important;
  line-height: 100% !important;
  color: theme('colors.acai.DEFAULT') !important;
}
.datepicker-trigger .vc-day-content:focus {
  @apply shadow-btn;
  background: transparent;
}
.vc-day:focus-within {
  z-index: 2;
}
</style>
