import axios, { spitURL } from '../api-axios'
import { PAGESIZE_MENUS, PAGE_SIZE_MENU_ITEMS, BASE_URLS, MENU_ITEM_TYPES } from '@/store/constants'
import mapFoodItem from '@/store/api/mappings/get-food-item.js'
import sendFoodItem from '@/store/api/mappings/send-food-item.js'
import mapMenuSettings from '@/store/api/mappings/get-menu-settings.js'
import mapMenu from '@/store/api/mappings/get-menu.js'
import sendMenu from '@/store/api/mappings/send-menu.js'
import mapDuplicateMappings from '@/store/api/mappings/get-duplicate-mappings'
import mapParentChildConflicts from '@/store/api/mappings/get-parent-child-conflicts'
import mapStage from '@/store/api/mappings/get-stage'
import sendStage from '@/store/api/mappings/send-stage'
import mapFoodGroup from '@/store/api/mappings/get-food-group'
import sendFoodGroup from '@/store/api/mappings/send-food-group'
import mapImage from '@/store/api/mappings/get-image'
import mapExternalSystem from '@/store/api/mappings/get-external-system'
import mapSimilarMapping from '@/store/api/mappings/get-similar-mapping'
import mapTracker from '@/store/api/mappings/get-tracker'
import mapBulkChanges from '@/store/api/mappings/get-bulk-changes'
import { parseOrganisationModel } from '@/store/utils/organisations'
import { changedKey } from '@/store/modules/menu-management/translations'
const { MENU_MANAGEMENT } = BASE_URLS

export default {
  fetchSettings() {
    return axios
      .get(MENU_MANAGEMENT + `/settings`)
      .then((resp) => resp.data)
      .then(mapMenuSettings)
  },
  saveMenu(payload, progressCallback) {
    let postData = new FormData()
    // be careful not to send null values as those get converted to string in a FormData
    postData.append('name', payload.name)
    postData.append('organisationId', payload.organisationId)
    postData.append('description', payload.description || '')
    postData.append('vision', payload.vision)
    postData.append('currency', payload.currency)
    postData.append('locale', payload.locale)
    postData.append('csvFile', payload.file)

    return axios
      .post(MENU_MANAGEMENT + `/menu`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(ev) {
          const percentageLoaded = Math.round((ev.loaded * 100) / ev.total)
          progressCallback(percentageLoaded)
        },
      })
      .then((resp) => resp.data)
      .then((json) => mapMenu(json.data))
  },

  patchMenu({ menuId, data }) {
    return axios
      .patch(MENU_MANAGEMENT + `/menu/${menuId}`, {
        old: sendMenu(data.old),
        new: sendMenu(data.new),
      })
      .then((resp) => resp.data)
      .then((json) => mapMenu(json.data))
  },

  fetchOrganisations(query = {}, cancelToken = null) {
    const options = cancelToken ? { cancelToken } : {}
    const finalQuery = { size: 100, ...query, filter: { archived: false, ...query.filter } }

    return axios.get(spitURL('/organisation', finalQuery), options).then((resp) => resp.data)
  },

  fetchMenus(query = {}) {
    const finalQuery = { size: PAGESIZE_MENUS, ...query }
    return axios
      .get(spitURL(MENU_MANAGEMENT + `/menus`, finalQuery))
      .then((resp) => resp.data)
      .then((json) => {
        let { records, organisationRecords, ids } = json.data.reduce(
          ({ records, organisationRecords, ids }, menu) => {
            records[menu.id] = mapMenu(menu)
            organisationRecords[menu.organisation.id] = menu.organisation
            ids.push(menu.id)
            return { records, organisationRecords, ids }
          },
          { records: {}, organisationRecords: {}, ids: [] }
        )
        return {
          records,
          organisationRecords,
          ids,
          total: json.metadata.total,
        }
      })
  },

  fetchMenuData(menuId) {
    return axios
      .get(MENU_MANAGEMENT + `/menu/${menuId}`)
      .then((resp) => resp.data)
      .then((json) => {
        let menu = mapMenu({ ...json.data, ...json.metadata })
        return {
          menu,
          menuStates: json.metadata.menuStates.sort((a, b) => a.order - b.order),
          organisation: menu.organisation,
        }
      })
  },

  fetchMenuStates() {
    return axios
      .get(MENU_MANAGEMENT + '/menu/states')
      .then((resp) => resp.data)
      .then((json) => json.data.map((state) => ({ ...state, order: state.order - 1 })))
  },

  downloadMenu(menuId) {
    return axios({
      url: `/menu-management/menu/${menuId}/export`,
      responseType: 'text',
    }).then((resp) => resp.data)
  },

  fetchTranslations({ menuId, type, query }) {
    const MENU = `${MENU_MANAGEMENT}/menu/${menuId}/translations`
    let urls = {
      [MENU_ITEM_TYPES.FOOD_ITEM]: `${MENU}/food-items`,
      [MENU_ITEM_TYPES.STAGE]: `${MENU}/stages`,
      [MENU_ITEM_TYPES.FOOD_GROUP]: `${MENU}/food-groups`,
    }
    const finalQuery = { size: PAGESIZE_MENUS, ...query }
    let page = query.page || 0
    return axios
      .get(spitURL(urls[type], finalQuery))
      .then((resp) => resp.data)
      .then((json) => {
        let items = json.data.map((item, index) => ({
          id: `${type}-${page}-${index}`,
          type,
          name: item.name,
          defaultTranslation: item.autoTranslatedNameEnglish,
          nameEnglish: item.nameEnglish,
        }))
        let total = json.metadata ? json.metadata.total : null
        return { items, total }
      })
      .then(({ items, total }) => {
        let records = items.reduce((records, translation) => {
          records[changedKey({ id: translation.id, type: translation.type })] = translation
          return records
        }, {})
        return { records, total }
      })
  },

  fetchFoodGroups(menuId, stageId) {
    let url = MENU_MANAGEMENT + `/menu/${menuId}`
    if (stageId) {
      url += `/stage/${stageId}`
    }
    url += '/food-groups'
    return axios
      .get(url)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((foodGroups) => {
        let foodGroupRecords = {}
        let stageRecords = {}
        foodGroups.forEach((foodGroup) => {
          foodGroupRecords[foodGroup.id] = mapFoodGroup(foodGroup)
          if (foodGroup.stages) {
            foodGroup.stages.forEach((stage) => {
              if (stageRecords[stage.id]) {
                stageRecords[stage.id].foodGroupIds.push(foodGroup.id)
              } else {
                stageRecords[stage.id] = mapStage(stage)
                stageRecords[stage.id].foodGroupIds = [foodGroup.id]
              }
            })
          }
        })
        return { foodGroupRecords, stageRecords }
      })
  },

  patchFoodGroup({ menuId, foodGroupId, data }) {
    return axios
      .patch(MENU_MANAGEMENT + `/menu/${menuId}/food-group/${foodGroupId}`, {
        new: sendFoodGroup(data.new),
        old: sendFoodGroup(data.old),
      })
      .then((resp) => resp.data)
      .then((json) => mapFoodGroup(json.data))
  },

  postFoodGroup({ menuId, stageId, data }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/stage/${stageId}/food-group`, data)
      .then((resp) => resp.data)
      .then((json) => mapFoodGroup(json.data))
  },

  deleteFoodGroup({ foodGroupId }) {
    return axios.delete(MENU_MANAGEMENT + `/food-group/${foodGroupId}`)
  },

  postTranslations({ menuId, translations }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/menu-translate`, translations)
      .then((resp) => resp.data)
      .then((json) => {
        return {
          translationsRemaining: json.metadata.itemsWithNoEnglishName,
        }
      })
  },
  postFinishTranslations({ menuId }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/propagate-translation`)
      .then((resp) => resp.data)
      .then((json) => mapMenu({ ...json.data, ...json.metadata }))
  },
  fetchMenuStatistics(menuId) {
    return axios
      .get(MENU_MANAGEMENT + `/menu/${menuId}/statistics`)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((totals) => {
        return {
          foodItems: totals.totalAll,
          foodItemsPending: totals.totalPending,
          foodItemsApproved: totals.totalApproved,
          foodItemsFailed: totals.totalFailed,
          foodItemsUnmapped: totals.totalUnmapped,
          foodItemsCostZero: totals.totalCostZero,
          foodItemsCostNull: totals.totalCostNull,
          foodItemsPortionMissing: totals.totalMissingPortion,
        }
      })
  },
  fetchFoodItems({ menuId, foodGroupId, query = {}, options = {} }) {
    const finalQuery = { ...query, size: query.size || PAGE_SIZE_MENU_ITEMS }
    let url = MENU_MANAGEMENT + `/menu/${menuId}`
    if (foodGroupId) {
      url += `/food-group/${foodGroupId}`
    }
    url += '/food-items'
    return axios
      .get(spitURL(url, finalQuery), options)
      .then((resp) => resp.data)
      .then((json) => {
        let foodItemRecords = {}
        let foodItemIds = []
        let foodGroupRecords = {}
        let stageRecords = {}
        let { total } = json.metadata || {}
        json.data.forEach((item) => {
          foodItemRecords[item.id] = mapFoodItem(item)
          foodItemIds.push(item.id)
          if (item.foodGroups) {
            item.foodGroups.forEach((foodGroup) => {
              foodGroupRecords[foodGroup.id] = mapFoodGroup(foodGroup)
              foodGroup.stages.forEach((stage) => {
                stageRecords[stage.id] = mapStage(stage)
              })
            })
          }
        })
        return {
          foodItemRecords,
          foodItemIds,
          foodGroupRecords,
          stageRecords,
          total,
        }
      })
  },

  fetchFoodItem({ menuId, foodItemId }, query = {}) {
    if (menuId) {
      // generate a request that returns food item data with menu properties
      return axios
        .get(spitURL(MENU_MANAGEMENT + `/menu/${menuId}/food-items/${foodItemId}`, query))
        .then((resp) => resp.data)
        .then((json) => {
          let stageRecords = {}
          let foodGroupRecords = {}
          let foodGroups = json.data.foodGroups
          if (foodGroups) {
            foodGroups.forEach((group) => {
              foodGroupRecords[group.id] = mapFoodGroup(group)
              let stages = group.stages
              if (stages) {
                stages.forEach((stage) => {
                  stageRecords[stage.id] = mapStage(stage)
                })
              }
            })
          }
          return {
            foodItem: mapFoodItem(json.data),
            foodGroupRecords,
            stageRecords,
          }
        })
    } else {
      // generate a request that only returns food item properties independent of menu
      return axios
        .get(spitURL(MENU_MANAGEMENT + `/food-items/${foodItemId}`, query))
        .then((resp) => resp.data)
        .then((json) => mapFoodItem(json.data))
    }
  },
  postFoodItem({ menuId, data }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/food-item`, sendFoodItem(data))
      .then((resp) => resp.data)
      .then((json) => mapFoodItem(json.data))
  },
  patchFoodItem({ menuId, itemId, data }) {
    return axios
      .patch(MENU_MANAGEMENT + `/menu/${menuId}/food-items/${itemId}`, {
        new: sendFoodItem(data.new),
        old: sendFoodItem(data.old),
      })
      .then((resp) => resp.data)
      .then((json) => mapFoodItem(json.data))
  },

  fetchExternalSystems({ organisationId }) {
    return axios
      .get(`/organisation/${organisationId}/external-system-info`)
      .then((resp) => resp.data)
      .then((json) => json.data.map(mapExternalSystem))
  },

  fetchSimilarMappings({ foodItemId }) {
    return axios
      .get(
        spitURL(MENU_MANAGEMENT + `/food-items/${foodItemId}/mapping-suggestions`, { pageSize: 20 })
      )
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((similarMappings) => {
        let records = similarMappings.reduce((records, mapping) => {
          records[mapping.id] = mapSimilarMapping(mapping)
          return records
        }, {})
        return {
          records,
          ids: similarMappings.map(({ id }) => id),
        }
      })
  },
  deleteFoodItem({ foodItemId, menuId }) {
    return axios.delete(MENU_MANAGEMENT + `/menu/${menuId}/food-items/${foodItemId}`)
  },
  fetchDuplicateMappings({ menuId }) {
    return axios
      .get(MENU_MANAGEMENT + `/menu/${menuId}/diamond-rules/duplicate-mappings`)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapDuplicateMappings)
  },
  postMergeItems({ menuId, newFoodItem, duplicates }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/diamond-rules/duplicate-mappings`, {
        record: sendFoodItem(newFoodItem),
        duplicateMappings: duplicates,
      })
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapFoodItem)
  },
  fetchParentChildConflicts({ menuId }) {
    return axios
      .get(MENU_MANAGEMENT + `/menu/${menuId}/diamond-rules/parent-child-conflicts`)
      .then((resp) => resp.data)
      .then(mapParentChildConflicts)
  },

  // Get stages for tablet editor
  fetchStages({ menuId }) {
    return axios
      .get(MENU_MANAGEMENT + `/menu/${menuId}/stages`)
      .then((resp) => resp.data)
      .then((json) => {
        return json.data.reduce(
          ({ records, ids, foodGroups, foodGroupIds, custom1s }, stage) => {
            records[stage.id] = mapStage(stage)
            ids.push(stage.id)

            if (stage.custom1) {
              custom1s[stage.custom1.id] = stage.custom1
            }
            stage.foodGroups.reduce((foodGroups, foodGroup) => {
              foodGroups[foodGroup.id] = mapFoodGroup(foodGroup)
              foodGroups[foodGroup.id].stageIds = foodGroups[foodGroup.id].stageIds || []
              foodGroups[foodGroup.id].stageIds.push(stage.id)
              return foodGroups
            }, foodGroups)
            foodGroupIds = Object.keys(foodGroups)
            return { records, ids, foodGroups, foodGroupIds }
          },
          { records: {}, ids: [], foodGroups: {}, foodGroupIds: [], custom1s: {} }
        )
      })
  },
  patchStage({ menuId, stageId, data }) {
    return axios
      .patch(MENU_MANAGEMENT + `/menu/${menuId}/stage/${stageId}`, {
        old: sendStage(data.old),
        new: sendStage(data.new),
      })
      .then((resp) => resp.data)
      .then((json) => mapStage(json.data))
  },
  postStage({ menuId, data }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/stage`, sendStage(data))
      .then((resp) => resp.data)
      .then((json) => mapStage(json.data))
  },
  deleteStage({ stageId }) {
    return axios.delete(MENU_MANAGEMENT + `/stage/${stageId}`)
  },

  fetchCustom1s({ menuId }) {
    return axios
      .get(MENU_MANAGEMENT + `/custom-breakdowns`, {
        params: {
          'filter[menuId]': menuId,
        },
      })
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then((custom1s) => {
        return custom1s.reduce((records, data) => {
          records[data.id] = data
          return records
        }, {})
      })
  },

  postCustom1({ menuId, data }) {
    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/custom-breakdown`, data)
      .then((resp) => resp.data)
      .then((json) => json.data)
  },

  fetchImage({ imageId }) {
    return axios
      .get('/image-library/images/' + imageId + '/info')
      .then((resp) => resp.data)
      .then((json) => mapImage(json.data))
  },

  fetchImageRecommendations({ menuId, stageId, foodGroupId, foodItemId, query }) {
    let url = MENU_MANAGEMENT + `/menu/${menuId}`

    if (stageId) {
      url += `/stage/${stageId}`
    }
    if (foodGroupId) {
      url += `/food-group/${foodGroupId}`
    }
    if (foodItemId) {
      url += `/food-items/${foodItemId}`
    }

    if (query.keyword) {
      url = 'image-library/images/search'
    } else {
      url += '/image/search'
    }

    return axios
      .get(spitURL(url, query))
      .then((resp) => resp.data)
      .then((json) => {
        let { records, imageIds } = json.data.reduce(
          ({ records, imageIds }, image) => {
            records[image.id] = mapImage(image)
            imageIds.push(image.id)
            return { records, imageIds }
          },
          { records: {}, imageIds: [] }
        )
        return { records, imageIds, total: json.metadata.total }
      })
  },

  postImage({ data }) {
    let formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key])
    })
    return axios
      .post('/image-library/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((resp) => resp.data)
      .then((json) => mapImage(json.data))
  },

  patchImage({ imageId, data }) {
    return axios
      .patch(`/image-library/images/${imageId}`, data)
      .then((resp) => resp.data)
      .then((json) => mapImage(json.data))
  },

  getAssignedTrackers({ menuId, filters, ...query }) {
    return axios
      .get(
        spitURL(`/menu-management/menu/${menuId}/trackers/assigned`, { ...query, filter: filters })
      )
      .then((resp) => resp.data)
      .then((json) => ({ trackers: json.data, total: json.metadata.total }))
      .then(({ trackers, total }) => {
        let { records, orderedIds, organisationRecords, menuRecords } = trackers.reduce(
          ({ records, orderedIds, organisationRecords, menuRecords }, tracker) => {
            if (tracker.site) {
              organisationRecords[tracker.site.id] = parseOrganisationModel(tracker.site)
            }
            if (tracker.activeMenu) {
              menuRecords[tracker.activeMenu.id] = mapMenu(tracker.activeMenu)
            }
            records[tracker.id] = mapTracker(tracker)
            orderedIds.push(tracker.id)
            return { records, orderedIds, organisationRecords, menuRecords }
          },
          { records: {}, orderedIds: [], organisationRecords: {}, menuRecords: {} }
        )
        return {
          records,
          orderedIds,
          organisationRecords,
          menuRecords,
          total,
        }
      })
  },
  getCompatibleTrackers({ menuId, filters, ...query }) {
    return axios
      .get(
        spitURL(`/menu-management/menu/${menuId}/trackers/compatible`, {
          ...query,
          filter: filters,
        })
      )
      .then((resp) => resp.data)
      .then((json) => ({ trackers: json.data, total: json.metadata.total }))
      .then(({ trackers, total }) => {
        let { records, orderedIds, organisationRecords, menuRecords } = trackers.reduce(
          ({ records, orderedIds, organisationRecords, menuRecords }, tracker) => {
            if (tracker.site) {
              organisationRecords[tracker.site.id] = parseOrganisationModel(tracker.site)
            }
            // also check for id because "old menus" come in with id: null
            if (tracker.activeMenu && tracker.activeMenu.id) {
              menuRecords[tracker.activeMenu.id] = mapMenu(tracker.activeMenu)
            }
            records[tracker.id] = mapTracker(tracker)
            orderedIds.push(tracker.id)
            return { records, orderedIds, organisationRecords, menuRecords }
          },
          { records: {}, orderedIds: [], organisationRecords: {}, menuRecords: {} }
        )
        return {
          records,
          orderedIds,
          organisationRecords,
          menuRecords,
          total,
        }
      })
  },

  postAssignMenu({ trackerId, activeMenuId, oldMenuId }) {
    return axios
      .post(`/menu-management/menu/assignment`, { activeMenuId, trackerId, oldMenuId })
      .then((response) => response.data)
      .then((json) => {
        return {
          tracker: mapTracker(json.data),
          menu: json.data.activeMenu && mapMenu(json.data.activeMenu),
        }
      })
  },
  postUnassignMenu({ trackerId, activeMenuId, oldMenuId }) {
    return axios
      .post(`/menu-management/menu/unassign`, { activeMenuId, trackerId, oldMenuId })
      .then((response) => response.data)
      .then((json) => {
        return {
          tracker: mapTracker(json.data),
          menu: json.data.activeMenu && mapMenu(json.data.activeMenu),
        }
      })
  },

  deployMenu({ menuId }) {
    return axios.post(`/menu-management/menu/${menuId}/deploy`).then((response) => response.data)
  },
  validateMenu({ menuId }) {
    return axios
      .get(`/menu-management/menu/${menuId}/validate/deploy`)
      .then((response) => response.data)
      .then((json) => json.data)
  },

  postBulkChanges({ menuId, csvFile }) {
    let postData = new FormData()
    // be careful not to send null values as those get converted to string in a FormData
    postData.append('csvFile', csvFile)

    return axios
      .post(MENU_MANAGEMENT + `/menu/${menuId}/staging/update`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress(ev) {
        //   const percentageLoaded = Math.round((ev.loaded * 100) / ev.total)
        //   progressCallback(percentageLoaded)
        // }
      })
      .then((resp) => resp.data)
      .then((json) => json.data)
  },

  getBulkChanges({ menuId, options }) {
    return axios
      .get(MENU_MANAGEMENT + `/menu/${menuId}/staging/changes`, options)
      .then((resp) => resp.data)
      .then((json) => json.data)
      .then(mapBulkChanges)
  },

  clearBulkUpdates({ menuId }) {
    return axios.post(MENU_MANAGEMENT + `/menu/${menuId}/staging/clear`)
  },
}
