<script>
export default {
  props: {
    selectors: {
      type: Object,
      default: () => ({ parent: '', relative: '' }),
    },
  },
  data() {
    return {
      actionBarStyle: {
        width: 'auto',
      },
    }
  },

  mounted() {
    this.handleResize()
  },

  methods: {
    handleResize() {
      const el = document.querySelector(this.selectors.parent)
      this.actionBarStyle.width = el.offsetWidth + 'px'
    },
  },
}
</script>

<template>
  <resize-observer @notify="handleResize" />
  <div
    class="StickySearch sticky-top"
    :style="actionBarStyle"
  >
    <slot />
  </div>
</template>

<style lang="scss">
.StickySearch {
  @apply rounded-tl-lg;
  z-index: $zindex-sticky;
  display: flex;
  align-items: stretch;

  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  background: theme('colors.white');
  box-shadow: 0 5px 6px -2px rgb(0, 0, 0, 0.1);
  will-change: transform;
}
</style>
