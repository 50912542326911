<template>
  <LayoutDefault extended-content>
    <div class="wrapper">
      <div
        class="box"
        :class="{ 'overflow-hidden': isHierarchyBrowserExpanded }"
      >
        <OrgBrowser
          ref="org-browser"
          class="box-content-fill-remaining -mt-4"
          :is-editable="true"
          :selected-node-id="selectedNodeId"
          :focused-node-id="focusedNodeId"
          :is-open="defaultHierarchyBrowserExpandedState"
          :archived-available="true"
          @node-select="onNodeSelect"
          @node-focus="onNodeFocus({ selectedNodeId: $event })"
          @browser-expand="isHierarchyBrowserExpanded = true"
          @browser-collapse="isHierarchyBrowserExpanded = false"
        />
      </div>
    </div>

    <router-view :key="$route.params.nodeId" />
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import OrgBrowser from '@/components/OrgBrowser/OrgBrowser.vue'
import { WINNOW_ORG_ID } from '@/store/constants'

function executeRedirects(to, from, next) {
  /**
   * @TODO: Totally refactor this in the near future
   *
   * The routing in vue 3 is all async and components might be created when not intended.
   * Also, each reroute triggers a segment analytics event instead of logging only the final one.
   * Localisation section on the Hierarchy Node was especially buggy because of this.
   * Fixed it temporarily with a lazy hack but the underlying problems need to be fixed.
   *
   * Ref to https://winnow.atlassian.net/browse/HUB-4324 for one scenario that needs to be checked when refactoring.
   */
  if (to.params.nodeId && to.name === 'hierarchy') {
    next({ name: 'hierarchy-node', params: to.params })
    return
  }

  if (to.matched.find((match) => match.name === 'hierarchy-node-users')) {
    if (to.params.nodeId === WINNOW_ORG_ID) {
      if (to.name === 'hierarchy-node-users-viewing') {
        next({ name: 'hierarchy-node-users-node', params: to.params })
        return
      }
      if (to.name === 'hierarchy-node-users-settings') {
        next({ name: 'hierarchy-node-users-node', params: to.params })
        return
      }
    }
    if (from.params.nodeId === WINNOW_ORG_ID && to.params.nodeId !== WINNOW_ORG_ID) {
      if (to.name === 'hierarchy-node-users-node') {
        next({ name: 'hierarchy-node-users-viewing', params: to.params })
        return
      }
    }
  }
  next()
}

/**
 * Two terms that should be kept as possible around here are:
 * 1. focusedNode = a node that was clicked on in the browser in order to navigate to another node
 * 2. selectedNode = a node that was opened from the UI, either with the dedicated "Open" button
 *    or by double clicking / hitting enter etc on a focused node
 *
 * The url is a bit different though, as the organisation browser was refactored by trying to change as little
 * as possible outside of it. For that matter we have the terms mentioned above with different names:
 * 1. `nodeId` (which is the main url param) that corresponds to `selectedNode`
 * 2. `selectedNodeId` (which is a query param) that corresponds to the `focusedNode`
 * both of these need to have values in order for the org browser to work properly
 */
export default {
  name: 'HierarchyPage',
  components: {
    LayoutDefault,
    OrgBrowser,
  },
  beforeRouteEnter(to, from, next) {
    executeRedirects(to, from, next)
  },
  beforeRouteUpdate(to, from, next) {
    if (!to.params.nodeId) {
      next({
        ...this.$route,
        name: 'hierarchy-node',
        params: {
          nodeId: this.$store.getters['auth/userRootOrganisationId'],
        },
      })
      return
    }
    executeRedirects(to, from, next)
    /**
     * This is for refreshing the org browser tree when node changes following a direct
     * navigation to another node via a link
     * It also throws a console error when it happens because the org browser has missing data
     * until the tree gets loaded
     *
     * @TODO: implement this properly via dedicated mechanism in the org browser
     *
     */

    if (
      to.params.nodeId !== from.params.nodeId &&
      to.query.selectedNodeId !== from.query.selectedNodeId
    ) {
      this.$store.dispatch('hierarchy/nodes/getTree', to.params.nodeId)
    }
  },
  props: {
    nodeId: String,
  },
  data() {
    return {
      defaultHierarchyBrowserExpandedState: true,
      selectedNodeId: this.nodeId,
      focusedNodeId: this.$route.query.selectedNodeId,
      isHierarchyBrowserExpanded: this.defaultHierarchyBrowserExpandedState,
    }
  },
  watch: {
    nodeId(id) {
      this.selectedNodeId = id
    },
    '$route.query.selectedNodeId'(id) {
      this.focusedNodeId = id
    },
  },
  created() {
    if (!this.selectedNodeId) {
      this.$router.push({
        ...this.$route,
        name: 'hierarchy-node',
        params: {
          nodeId: this.$store.getters['auth/userRootOrganisationId'],
        },
      })
    }
  },
  methods: {
    onNodeFocus({ selectedNodeId }) {
      if (selectedNodeId !== this.$route.query.selectedNodeId) {
        this.$router.push({
          query: { ...this.$route.query, selectedNodeId },
        })
      }
    },
    // open a node id
    // if id isn't supplied, go back to the main hierarchy page
    // this is to support going back to the main root node
    onNodeSelect(id) {
      let query = {
        ...this.$route.query,
        selectedNodeId: id,
      }
      delete query.create

      this.$router.push({
        params: {
          nodeId: id,
        },
        query,
      })
    },
  },
}
</script>

<style lang="scss"></style>
