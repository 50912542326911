<template>
  <div class="info">
    <p>
      {{ $t(`${'waste.' + transaction.type}`) }}
      <br />
      <span class="text-sm text-grey-400">{{ formatTimeDate(transaction.createdDate) }}</span>
    </p>
    <div class="info-center">
      <p v-if="isCategorised">
        <b>{{ transaction.foodInstance && transaction.foodInstance.nameLocalised }}</b>
        <br />
        <span class="text-sm text-grey-400">
          {{ $t('titles.stage') }}: {{ transaction.stage && transaction.stage.nameLocalised }}
        </span>
      </p>
      <p
        v-else
        class="uppercase text-apple h3"
      >
        {{ $t('titles.uncategorised') }}
      </p>
      <p class="text-right">
        <b>{{ displayWeight }}</b>
        <br />
        <span class="text-sm text-grey-400">{{ $t('settings.weight.short.' + weightUnit) }}</span>
      </p>
      <p class="text-right">
        <b>{{ displayCost }}</b>
        <br />
        <span class="text-sm text-grey-400">{{ transaction.currency }}</span>
      </p>
    </div>
    <p class="info-actions">
      <slot />
    </p>
  </div>
</template>

<script>
import { fromGrams } from '@/utils/weight'
import moment from 'moment'
import { TIME_DATE_FORMAT } from '@/store/constants'
export default {
  props: {
    transaction: {
      type: Object,
      default: () => ({}),
    },
    weightUnit: String,
  },
  data() {
    return {}
  },
  computed: {
    displayWeight() {
      const convertedWeight = fromGrams(this.transaction.weight.value, this.weightUnit).toFixed(2)

      return `${convertedWeight}`
    },
    isCategorised() {
      if (this.transaction) {
        return this.transaction.stage && this.transaction.foodInstance
      } else {
        return true
      }
    },
    displayCost() {
      const { cost, foodInstance } = this.transaction

      if (!foodInstance) {
        return `0.00`
      }
      return `${cost.toFixed(2)}`
    },
  },

  methods: {
    formatTimeDate(date) {
      return moment(date).format(TIME_DATE_FORMAT)
    },
  },
}
</script>

<style lang="scss">
.info {
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  width: 100%;
  background-color: theme('colors.lemon.100');

  p {
    padding: theme('spacing.1') theme('spacing.3');
    flex-grow: 1;

    &:first-child {
      border-top-left-radius: theme('borderRadius.DEFAULT');
      border-bottom-left-radius: theme('borderRadius.DEFAULT');
    }

    &:last-child {
      border-top-right-radius: theme('borderRadius.DEFAULT');
      border-bottom-right-radius: theme('borderRadius.DEFAULT');
    }
  }

  .info-center {
    flex-basis: 100%;
    display: flex;
    justify-content: stretch;
    flex-grow: 1;
    align-items: center;
    @include respond-min(small) {
      flex-basis: to-rem(310px);
    }

    @include respond-min(medium) {
      flex-basis: to-rem(410px);
      flex-grow: 0;
    }
  }

  .info-actions {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-top: theme('spacing.2');
    align-items: flex-start;

    > * {
      margin-left: theme('spacing.2');
    }
  }

  p + p {
    border-left: 0;
  }
}
</style>
