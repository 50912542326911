import FoodItem from '@/store/models/food-item'
export default function mapFoodItem(foodItem) {
  return new FoodItem({
    id: foodItem.id,
    name: foodItem.name,
    displayName: foodItem.name,
    nameEnglish: foodItem.nameEnglish,
    annotationLabel: foodItem.annotationLabel,
    cost: foodItem.cost,
    predictionEnabled: foodItem.predictionEnabled,
    annotationEnabled: foodItem.annotationEnabled,
    hiddenFromTrackers: foodItem.hiddenFromTrackers,
    createdDate: foodItem.created,
    updatedDate: foodItem.updated,
    displayOrder: foodItem.displayOrder,
    imageId: foodItem.image,
    imageUrl: foodItem.image ? '/image-library/images/' + foodItem.image : null,
    portion: {
      value: foodItem.portion,
    },
    taxonomy: {
      mapping: foodItem.taxonomyMapping,
      code: foodItem.taxonomyCode,
    },
    externalSystem: foodItem.externalSystem
      ? {
          id: foodItem.externalSystem.id,
          name: foodItem.externalSystem.name,
        }
      : null,
    externalId: foodItem.externalId,
    status: foodItem.state,
    foodGroupIds: foodItem.foodGroups && foodItem.foodGroups.map((group) => group.id),
    editing: {
      taxonomy: foodItem.taxonomyCode
        ? {
            mapping: foodItem.taxonomyMapping,
            code: foodItem.taxonomyCode,
          }
        : {},
    },
  })
}
