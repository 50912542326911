<script>
import moment from 'moment'

import Modal from '@/components/Modal.vue'
import DownloadImage from '@/components/DownloadImage.vue'
import ImageLoader from '@/components/image-loader.vue'
import { DATE_FORMAT_TRANSACTION_IMAGE } from '@/store/constants'
import { useToast } from 'vue-toastification'

import { buildURL } from '@/store/api-axios'
import encode from '@/utils/strict-uri-encode'

export default {
  components: {
    DownloadImage,
    ImageLoader,
    Modal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    allowFullScreen: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 380,
    },
    height: {
      type: Number,
      default: 380,
    },
    // This will wrap the placeholder with an actionable button which will
    // show the supplied details slot in the modal view
    allowActionOnPlaceholder: {
      type: Boolean,
      default: false,
    },
    withPrevImage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['image-loaded'],

  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      statusPrevTransaction: 'IDLE',
      prevTransaction: null,
      isPrevImage: false,
    }
  },

  computed: {
    tag() {
      return this.allowFullScreen ? 'button' : 'div'
    },
    type() {
      return this.allowFullScreen ? 'type' : ''
    },
    thumbImageUrl() {
      return buildURL(`/waste-log/${encode(this.item.id)}/image/${encode(this.item.imageId)}`, {
        filters: {
          resize: `${this.width}x${this.height}`,
        },
      })
    },
    currentTranFullImgUrl() {
      return `/waste-log/${this.item.id}/image/${this.item.imageId}`
    },
    prevTranFullImgUrl() {
      return `/waste-log/${this.prevTransaction.id}/image/${this.prevTransaction.imageId}`
    },
    currentFullImgBlob() {
      return this.$store.getters['image-cache/imageBySrc'](this.currentTranFullImgUrl)
    },
    prevFullImgBlob() {
      return this.$store.getters['image-cache/imageBySrc'](this.prevTranFullImgUrl)
    },
    currentFullImgFileName() {
      return `transaction_${this.item.id}_${moment(this.item.createdDate).format(
        DATE_FORMAT_TRANSACTION_IMAGE
      )}.jpeg`
    },
    prevFullImgFileName() {
      return `transaction_${this.prevTransaction.id}_${moment(
        this.prevTransaction.createdDate
      ).format(DATE_FORMAT_TRANSACTION_IMAGE)}.jpeg`
    },
  },

  methods: {
    track() {
      this.analytics.track('View full image', {
        transaction: this.item,
      })
    },

    getPrevTransaction() {
      this.statusPrevTransaction = 'LOADING'

      this.$store
        .dispatch('waste-log/getPrevTransaction', { id: this.item.id, params: { withImage: true } })
        .then((transaction) => {
          this.statusPrevTransaction = 'SUCCESS'
          if (transaction) {
            this.prevTransaction = this.$store.getters['waste-log/byId'](transaction.id)
          }
        })
        .catch((e) => {
          this.statusPrevTransaction = 'ERROR'

          this.toast.error(this.$t('toast.error.getData'))
          throw e
        })
    },

    onFullScreen() {
      if (this.withPrevImage && !this.prevTransaction) {
        this.getPrevTransaction()
      }

      this.$refs.imageModal.open()
      this.track()
    },
  },
}
</script>

<template>
  <div
    :class="{
      'transaction-image-container': true,
      'transaction-image-placeholder': $slots['placeholder'] || $slots['error'],
    }"
  >
    <!-- Image placholder for transactions that don't have images -->
    <template v-if="$slots['placeholder']">
      <!-- Allow placeholder to open the modal -->
      <template v-if="allowActionOnPlaceholder">
        <button
          class="transaction-image-expand"
          @click="onFullScreen()"
        >
          <slot name="placeholder" />
          <div class="transaction-image-controls">
            <span class="button button--primary button--icon button--roundBig">
              <SvgIcon
                name="48-ic-fullview"
                lg
              />
            </span>
          </div>
        </button>
      </template>
      <p v-else>
        <slot name="placeholder" />
      </p>
    </template>

    <component
      :is="tag"
      v-else
      :[type]="'button'"
      class="transaction-image-expand"
      @click="allowFullScreen && onFullScreen()"
    >
      <ImageLoader
        data-test-WL-transaction-image
        :src="thumbImageUrl"
        :alt="
          item.foodInstance &&
          $t('waste.image.alt', { foodItemName: item.foodInstance.nameLocalised })
        "
        imageFitting="cover"
      >
        <template #loading>
          <Loading-Spinner class="spinner spinner--lg" />
        </template>
        <template #error>
          <div
            class="flex flex-column flex-middle mb-4"
            data-test-image-error
          >
            <SvgIcon
              name="24-ic-warning"
              class="w-32 h-32"
            />
            <span v-html="$t('waste.error.image')" />
          </div>
        </template>
      </ImageLoader>
      <div
        v-if="allowFullScreen"
        class="transaction-image-controls"
      >
        <span class="button button--primary button--icon button--roundBig">
          <SvgIcon
            name="48-ic-fullview"
            lg
          />
        </span>
      </div>
    </component>

    <!-- In case that we open the modal from the placeholder, we show only the details slot content -->
    <Modal
      v-if="$slots['placeholder'] && $slots['details'] && allowActionOnPlaceholder"
      ref="imageModal"
    >
      <template #default="{ close }">
        <div @click="close">
          <slot name="details" />
        </div>
      </template>
    </Modal>

    <Modal
      v-else
      ref="imageModal"
      wrapper-class="modal--image"
    >
      <template #default="{ close }">
        <div
          @click="
            () => {
              close()
              isPrevImage = false
            }
          "
        >
          <div class="transaction-image-modal-wrapper">
            <Loading-Spinner
              v-if="isPrevImage && statusPrevTransaction === 'LOADING'"
              class="spinner spinner--lg mx-auto my-8"
            />

            <div v-else-if="isPrevImage && statusPrevTransaction === 'SUCCESS' && !prevTransaction">
              <button
                class="button button--primary mb-4"
                @click.stop="isPrevImage = false"
              >
                <span>{{ $t('wasteList.actions.backToCurrentTransactionImg') }}</span>
              </button>
              <div class="p-2 flex-column flex-middle">
                <SvgIcon
                  class="text-grey-400"
                  name="no-image"
                  lg
                />
                <p class="mt-2">
                  {{ $t('wasteList.messages.noPrevTransactionImg') }}
                </p>
              </div>
            </div>
            <div
              v-else
              class="flex gap-4 mx-auto mb-4"
            >
              <button
                v-if="withPrevImage"
                class="button button--primary"
                @click.stop="isPrevImage = !isPrevImage"
              >
                <span>{{
                  isPrevImage
                    ? $t('wasteList.actions.hidePrevImg')
                    : $t('wasteList.actions.showPrevImg')
                }}</span>
              </button>
              <DownloadImage
                :file-name="isPrevImage ? prevFullImgFileName : currentFullImgFileName"
                :image-blob="isPrevImage ? prevFullImgBlob : currentFullImgBlob"
              />
            </div>

            <!--
              Made image loaders tabbable because the focus-trap library was throwing errors.
              This is a good thing because you don't want to "trap" the users in a modal with no way of closing it
             -->

            <ImageLoader
              v-if="prevTransaction"
              v-show="isPrevImage"
              class="transaction-image-loader"
              :src="prevTranFullImgUrl"
              tabindex="0"
            >
              <template #loading>
                <Loading-Spinner class="spinner--lg" />
              </template>
            </ImageLoader>

            <ImageLoader
              v-show="!isPrevImage"
              class="transaction-image-loader"
              :src="currentTranFullImgUrl"
              tabindex="0"
            >
              <template #loading>
                <Loading-Spinner class="spinner--lg" />
              </template>
            </ImageLoader>

            <div>
              <slot name="details" />
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss">
.transaction-image-container {
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.transaction-image-placeholder {
  background: theme('colors.grey.200');
}

.transaction-image-expand {
  margin: 0;
  padding: 0;
  position: relative;
  border: 0;
  background: none;
  width: 100%;
  height: 100%;
}

.transaction-image-controls {
  opacity: 0;
  position: absolute;
  top: theme('spacing.2');
  right: theme('spacing.2');
  @media screen and (min-width: theme('screens.sm')) {
    top: theme('spacing.5');
    right: theme('spacing.5');

  }
  display: flex;
  align-items: center;

  .transaction-image-container:hover & {
    opacity: 1;
  }
}

.transaction-image-modal-wrapper {
  --modal-dialog-spacing: theme('spacing.4')
  height: 100%;
  min-height: calc(100vh - var(--modal-dialog-spacing));
  padding: var(--modal-dialog-spacing);
  display: inline-flex;
  flex-direction: column;
}

.transaction-image-loader {
  &.image-loader {
    background: transparent;
  }
}
</style>
