import { toValue, ref } from 'vue'
import { useValidation } from '@/composables/useValidation'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
export function useOrgCreate({ parentId = null, selectedPackage = null, name = '', level = null }) {
  const toast = useToast()
  const store = useStore()
  const isLoading = ref(false)
  const { t } = useI18n()

  const { serverErrors, processValidationErrors, clearServerErrors } = useValidation({
    errorStructure: ['GENERAL', 'organisationName', 'level'],
  })

  const createNode = () => {
    isLoading.value = true
    const _package = toValue(selectedPackage)
    const _parentId = toValue(parentId)
    clearServerErrors()
    let data = {
      name: toValue(name),
      level: toValue(level),
    }
    if (_package) {
      data.locationDetails = { packageId: _package }
    }
    return store
      .dispatch(`hierarchy/nodes/createNode`, {
        parentId: _parentId,
        data,
      })
      .then((data) => {
        toast.success(t('toast.success.create.generic'))
        return data
      })
      .catch((e) => {
        if (e.status === 422) {
          processValidationErrors(e.data.errors)
        } else {
          toast.error(t('toast.error.saveData'))
        }
      })
      .finally(() => {
        isLoading.value = false
      })
  }
  return { serverErrors, createNode }
}
