<template>
  <div>
    <template v-if="node.level === orgLevels">
      <reporting-parameters
        class="collapsible-section--first"
        tag="section"
        :pre-open="openItems.includes('REPORTING-PARAMS')"
        :location-id="nodeId"
        @open="openSection('REPORTING-PARAMS')"
        @close="closeSection('REPORTING-PARAMS')"
      />
    </template>
    <reporting-sector-properties
      :class="[node.level !== orgLevels && 'collapsible-section--first']"
      :pre-open="openItems.includes('REPORTING-SECTOR-PROPS')"
      :location-id="nodeId"
      tag="section"
      @open="openSection('REPORTING-SECTOR-PROPS')"
      @close="closeSection('REPORTING-SECTOR-PROPS')"
    />
    <reporting-waste-targets
      tag="section"
      :pre-open="openItems.includes('REPORTING-WASTE-TARGETS')"
      :location-id="nodeId"
      :queries="wasteTargetsQueries"
      @open="openSection('REPORTING-WASTE-TARGETS')"
      @close="closeSection('REPORTING-WASTE-TARGETS')"
    />
  </div>
</template>
<script>
import { ORG_LEVELS } from '@/store/constants'
import openSectionsMixin from '@/mixins/openSectionsMixin'
import ReportingWasteTargets from './ReportingGeneralSettings/ReportingWasteTargets.vue'
import ReportingSectorProperties from './ReportingGeneralSettings/ReportingSectorProperties.vue'
import ReportingParameters from './ReportingGeneralSettings/ReportingParameters.vue'

export default {
  components: {
    ReportingWasteTargets,
    ReportingSectorProperties,
    ReportingParameters,
  },
  mixins: [openSectionsMixin],
  beforeRouteEnter(to, from, next) {
    /*
      If the user lands for the first time or refresed the page we need to reset the paginated chunks
      in order to make sure that the waste targets list is refreshed from the first chunk
    */
    if (to.query.targetsPage) {
      next({ path: to.path, query: { ...to.query, targetsPage: undefined } })
    } else {
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.query.targetsPage || to.query.targetsPast) {
      next({
        path: to.path,
        query: { ...to.query, targetsPage: undefined, targetsPast: undefined },
      })
    } else {
      next()
    }
  },
  props: {
    nodeId: String,
  },
  computed: {
    node() {
      return this.$store.getters['hierarchy/nodes/byId'](this.nodeId)
    },
    orgLevels() {
      return ORG_LEVELS
    },
    wasteTargetsQueries() {
      const { targetsPast, targetsPage, preOpenWasteTarget } = this.$route.query

      return {
        filters: {
          past: targetsPast === 'true',
        },
        page: parseInt(targetsPage, 10) || 0,
        preOpenTarget: preOpenWasteTarget,
      }
    },
  },
}
</script>
