<template>
  <page-section>
    <section-header>
      <template #section-title>
        {{ $t('menus.tabletEditor.title') }}
      </template>
    </section-header>

    <router-view :key="$route.fullPath" />
  </page-section>
</template>

<style lang="scss">
.tablet-editor-page {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: calc(-1 * theme('spacing.4'));
  flex-wrap: wrap;

  > * {
    //keep this here otherwise Chip components inside will break on IE 11
    width: 100%;
    margin: theme('spacing.4');
    flex: 1 1 auto;
  }
}

.tablet-editor-page-generic-fields {
  max-width: 20rem;
  padding-right: theme('spacing.4');
  margin-right: 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background: theme('colors.slate.DEFAULT');
  }
}

.tablet-editor-page-avatar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: calc(-1 * theme('spacing.1'));

  > * {
    margin-left: theme('spacing.1');
    margin-right: theme('spacing.1');
    flex: 0 1 8rem;
  }
}

.tablet-editor-page-avatar-title {
  flex-basis: 100%;
}

.tablet-editor-page-avatar-actions {
  @apply flex flex-col gap-4 flex-grow;
}

@include respond-min('large') {
  .tablet-editor-page {
    flex-wrap: nowrap;
  }

  .tablet-editor-page-generic-fields {
    flex: 1 0 20rem;
  }
}
</style>
