import axios, { spitURL } from '../../api-axios'
import processQualifiedName from '@/store/utils/processQualifiedName'

const state = {
  selectedSite: null,
  sites: [],
  loadingStatus: 'IDLE',
  error: false,
  // promise placeholder for the data request
  // used for components that rely on knowing when the organisations/sites are loaded
}

const getters = {
  sites: (state) => state.sites,
}

const actions = {
  getAllowedSites({ commit, state }) {
    if (state.loadingStatus === 'LOADED') return Promise.resolve()

    commit('SET_LOADING_START')
    commit('SET_ERROR', false)
    return axios
      .get(
        spitURL(`organisation/sites/allowed`, {
          filter: {
            active: true,
          },
        })
      )
      .then((response) => {
        commit('STORE_ALLOWED_SITES', response.data)
        commit('SET_LOADING_END')
      })
      .catch(() => {
        commit('SET_ERROR', true)
      })
  },
}

const processSite = (item) => {
  return {
    ...item,
    ...processQualifiedName(item.qualifiedName),
  }
}

const mutations = {
  STORE_ALLOWED_SITES(state, payload) {
    // state.orgName = payload.metadata.rootNode.name
    state.sites = payload.data.map(processSite)
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_LOADING_START(state) {
    state.loadingStatus = 'LOADING'
  },
  SET_LOADING_END(state) {
    state.loadingStatus = 'LOADED'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
