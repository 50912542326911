<template>
  <thead role="rowgroup">
    <tr role="row">
      <template v-for="header in headers">
        <th-sort
          v-if="header.sortable"
          :key="`sortable-${header.id}`"
          :sorted="header.id === sortCol ? sortDir : null"
          :class="header.class"
          :style="header.style"
          @sorted="onSort(header.id, $event)"
          >{{ header.title }}</th-sort
        >
        <th
          v-else-if="header.hidden"
          :key="`hidden-${header.id}`"
          :class="header.class"
          :style="header.style"
        >
          <span class="sr-only">{{ header.title }}</span>
        </th>
        <th
          v-else-if="header.selectAll"
          :key="`select-all-${header.id}`"
          class="th--select-all"
          :class="header.class"
          :style="header.style"
        >
          <div class="customControl customControl--checkbox">
            <input
              :id="header.id"
              type="checkbox"
              class="customControl-input"
              :checked="allSelected"
              :indeterminate="allSelectedIndeterminate"
              data-test-select-all
              @change="$emit('select-all', $event.target.checked)"
            />
            <label
              :for="header.id"
              class="customControl-label"
            >
              <span class="sr-only">{{ header.title }}</span>
            </label>
          </div>
        </th>
        <th
          v-else
          :key="header.id"
          :class="header.class"
          :style="header.style"
        >
          {{ header.title }}
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
import ThSort from '@/components/ThSort/ThSort.vue'
export default {
  components: { ThSort },
  props: {
    headers: {
      type: Array,
      // [...{ title<String>, sortable<Boolean>, id<String>, hidden<Boolean>, class<string>, style<string> }...],
      default: () => [],
    },
    // one of the header titles
    sortedColumn: {
      type: String,
      default: '',
    },
    sortDirection: {
      type: String,
      // 'ascending' or 'descending'
      default: 'ascending',
    },
    // flag for defaulting or changing the select all checkbox
    allSelected: {
      type: Boolean,
      default: false,
    },
    allSelectedIndeterminate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sorted', 'select-all'],
  data() {
    return {
      sortCol: null,
      sortDir: null,
    }
  },
  watch: {
    sortedColumn(column) {
      this.sortCol = column
    },
    sortDirection(direction) {
      this.sortDir = direction
    },
  },
  mounted() {
    this.sortCol = this.sortedColumn
    this.sortDir = this.sortDirection
  },
  methods: {
    onSort(column, direction) {
      this.sortCol = column
      this.sortDir = direction
      this.$emit('sorted', { column, direction })
    },
  },
}
</script>
