import { EN_LOCALE } from '@/store/constants'
import { mapGetters } from 'vuex'
import axios from '@/store/api-axios'
import { MENU_STATES } from '../../store/constants'

export default {
  data() {
    return {
      EN_LOCALE,
      axiosInterceptor: null,
    }
  },
  beforeUnmount() {
    this.allowEdits()
  },
  watch: {
    menuIsNotEditable: {
      handler(noEdit) {
        if (noEdit) {
          this.preventEdits()
        } else {
          this.allowEdits()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('menu-management', ['displayLanguage']),
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    menuIsNonEnglish() {
      if (!this.menu) return false
      return this.menu.locale.code !== EN_LOCALE
    },
    menuIsProcessing() {
      return ![MENU_STATES.PROCESSED, MENU_STATES.TRANSLATE].includes(this.menu.activeTask)
    },
    // this prevents users from sending update requests
    menuIsNotEditable() {
      return (
        this.menu && ![MENU_STATES.PROCESSED, MENU_STATES.TRANSLATE].includes(this.menu.activeTask)
      )
    },
    menuItemsNotAvailable() {
      return (
        this.menu &&
        [MENU_STATES.CREATING_ITEMS, MENU_STATES.TRANSLATE].includes(this.menu.activeTask)
      )
    },
    activeState() {
      let pendingIndex = this.pendingMenuStates.findIndex(
        (state) => state.key === this.menu.activeTask
      )
      let errorIndex = this.errorStates.findIndex((state) => state.key === this.menu.activeTask)
      let processStep = this.pendingMenuStates.length
      if (pendingIndex >= 0) {
        processStep = pendingIndex + 1
      } else if (errorIndex >= 0) {
        processStep = errorIndex + 1
      }
      return {
        ...this.menuStates.find((state) => state.key === this.menu.activeTask),
        processStep,
      }
    },
    menuStates() {
      let menuStates = this.$store.getters['menu-management/menus/menuStates']
      let activeState = menuStates.find((state) => state.key === this.menu.activeTask)
      return menuStates.map((state) => {
        if (state.order < 0) {
          return {
            ...state,
            label: this.$t('menus.summary.states.failed'),
          }
        }

        let label = this.$t('menus.summary.states.menu-processed')
        if (activeState.key === state.key) {
          label = this.$t('menus.summary.states.processing.' + state.key)
        } else if (activeState.order > state.order) {
          label = this.$t('menus.summary.states.done.' + state.key)
        } else if (state.key === MENU_STATES.PROCESSED) {
          label = this.$t('menus.summary.states.menu-processed')
        } else {
          label = this.$t('menus.summary.states.pending.' + state.key)
        }
        return {
          ...state,
          label,
        }
      })
    },
    // these are all states that are working as expected
    successStates() {
      return this.menuStates.filter((state) => state.order > 0)
    },
    // these are all the states that block progress
    errorStates() {
      return this.menuStates.filter((state) => state.order < 0)
    },
    // these are the states that need to be processed
    pendingMenuStates() {
      return this.menuStates.filter((state) => {
        return state.order > 0 && state.key !== MENU_STATES.PROCESSED
      })
    },
    menuProcessingPaused() {
      return this.menu.activeTask === MENU_STATES.TRANSLATE
    },
  },
  methods: {
    preventEdits() {
      this.axiosInterceptor = axios.interceptors.request.use((req) => {
        if (req.method !== 'get') {
          return Promise.reject()
        }
        return req
      })
    },
    allowEdits() {
      axios.interceptors.request.eject(this.axiosInterceptor)
    },
  },
}
