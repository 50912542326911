export function joinRootCodeWithFacetCode(rootCode, facetCode) {
  return [rootCode, facetCode].join('.')
}
export function joinFacetCodes(facets) {
  return facets.join('$')
}
export function joinBaseTermWithFacets(termString, facetsString) {
  return [termString, facetsString].join('#')
}

export default function (termCode, facets) {
  let str = ''

  if (termCode) {
    str += termCode
  }

  if (facets.length) {
    const formattedFacets = facets.map(({ recordCode, rootRecordCode }) => {
      return joinRootCodeWithFacetCode(rootRecordCode, recordCode)
    })
    const facetSubstring = joinFacetCodes(formattedFacets)

    str = joinBaseTermWithFacets(str, facetSubstring)
  }

  return str
}
