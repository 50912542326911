import api from '@/store/api/menus'
import { saveAs } from 'file-saver'

import { merge, intersection } from 'lodash'
import getOldValues from '@/store/utils/getOldValues'
export default {
  namespaced: true,
  state: {
    uploadProgressPercentage: 0,
    records: {},
    recordsSettings: {},
    ids: [],
    languages: [],
    currencies: [],
    externalSystems: [],
    availableItems: 0,
    menuStates: [],
  },
  getters: {
    menus: (state) => (state.ids ? state.ids.map((id) => state.records[id]) : []),
    foodItemsInMenu: (state, getters, rootState, rootGetters) => (menuId) =>
      rootGetters['menu-management/food-items/foodItemsById'](state.records[menuId].foodItemIds),
    foodGroupsInMenu: (state, getters, rootState, rootGetters) => (menuId) =>
      rootGetters['menu-management/food-groups/foodGroupsById'](
        state.records[menuId].foodGroupIds
      ).filter((fg) => fg),
    stagesInMenu: (state, getters, rootState, rootGetters) => (menuId) =>
      rootGetters['menu-management/stages/stagesById'](state.records[menuId].stageIds).filter(
        (stage) => stage
      ),
    custom1sInMenu: (state, getters, rootState, rootGetters) => (menuId) =>
      rootGetters['menu-management/custom1s/custom1sById'](state.records[menuId].custom1Ids).filter(
        (c1) => c1
      ),
    duplicateMappingsInMenu: (state, getters, rootState, rootGetters) => (menuId) =>
      rootGetters['menu-management/duplicate-mappings/duplicateMappingsById'](
        state.records[menuId].duplicateMappingIds
      ).filter((dm) => dm),
    parentChildConflictsInMenu: (state, getters, rootState, rootGetters) => (menuId) =>
      rootGetters['menu-management/parent-child-conflicts/parentChildConflictsById'](
        state.records[menuId].parentChildConflictIds
      ).filter((conflicts) => conflicts),
    menuById: (state) => (id) => state.records[id],
    menuSettingsById: (state) => (id) => state.recordsSettings[id],
    externalSystemByName: (state) => (name) =>
      state.externalSystems.find((sys) => sys.name === name),
    menuStates: (state) => state.menuStates,
    pendingMenuStates: (state) =>
      state.menuStates.filter((state, index, all) => state.order < all.length - 1),
    menuStatistics: (state, getters) => (menuId) => {
      let menu = getters.menuById(menuId)
      if (menu) return { ...menu.statistics }
    },
    trackersInMenu: (state, getters) => (menuId) => {
      let menu = getters.menuById(menuId)
      if (menu) return menu.trackers
    },
  },
  actions: {
    getMenus({ commit }, options) {
      return api
        .fetchMenus(options)
        .then(({ total, records, ids, organisationRecords }) => {
          commit('STORE_TOTAL', total)
          commit('STORE_RECORDS', { records, ids })
          commit('menu-management/organisations/STORE_ORGANISATIONS', organisationRecords, {
            root: true,
          })
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    getMenu({ commit }, { menuId }) {
      return api.fetchMenuData(menuId).then(({ menu, organisation, menuStates }) => {
        commit('UPDATE_MENU', menu)
        commit('STORE_MENU_STATES', menuStates)
        return { menu, organisation }
      })
    },
    getMenuStates({ commit }) {
      return api.fetchMenuStates().then((menuStates) => commit('STORE_MENU_STATES', menuStates))
    },
    getProgress({ commit }, { menuId }) {
      return api
        .fetchMenuData(menuId)
        .then(({ menu, menuStates }) => {
          commit('UPDATE_MENU', { id: menuId, activeTask: menu.activeTask })
          commit('STORE_MENU_STATES', menuStates)
          return Promise.resolve(menu)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    getSettings({ commit }) {
      return api
        .fetchSettings()
        .then(({ languages, currencies }) => {
          commit('STORE_LANGUAGES', languages)
          commit('STORE_CURRENCIES', currencies)
          return Promise.resolve({ languages, currencies })
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    getExternalSystems({ commit }, { organisationId }) {
      return api
        .fetchExternalSystems({ organisationId })
        .then((externalSystems) => {
          commit('STORE_EXTERNAL_SYSTEMS', externalSystems)
          return Promise.resolve(externalSystems)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    },
    getStatistics({ commit }, { menuId }) {
      return api.fetchMenuStatistics(menuId).then((data) => {
        commit('STORE_STATISTICS', { menuId, data })
      })
    },
    saveMenu({ commit }, payload) {
      commit('UPLOAD_PROGRESS', 0)
      return api
        .saveMenu(payload, (progress) => {
          commit('UPLOAD_PROGRESS', progress)
        })
        .then((menu) => {
          commit('UPDATE_MENU', menu)
          return menu
        })
        .catch((error) => {
          return Promise.reject(error)
        })
        .finally(() => {
          commit('UPLOAD_PROGRESS', 100)
        })
    },
    updateMenu({ commit, getters }, { menuId, data }) {
      return api
        .patchMenu({
          menuId,
          data: {
            old: getOldValues(getters.menuById(menuId), data),
            new: data,
          },
        })
        .then((menu) => {
          commit('UPDATE_MENU', menu)
          return menu
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    deployMenu(store, { menuId }) {
      return api.deployMenu({ menuId })
    },
    validateMenu({ commit }, { menuId }) {
      return api
        .validateMenu({ menuId })
        .then(() => {
          commit('STORE_STATISTICS', {
            menuId,
            data: {
              hasDuplicateMappings: false,
              hasParentChildConflicts: false,
              hasIssues: false,
            },
          })
        })
        .catch((errorObj) => {
          let hasDuplicateMappings = false
          let hasParentChildConflicts = false
          if (errorObj.status === 422) {
            let errorKeys = errorObj.data.errors.map(({ key }) => key)
            if (errorKeys.includes('DUPLICATE_MAPPINGS_EXCEPTION')) {
              hasDuplicateMappings = true
            }
            if (errorKeys.includes('PARENT_CHILD_CONFLICTS_EXCEPTION')) {
              hasParentChildConflicts = true
            }
          }
          commit('STORE_STATISTICS', {
            menuId,
            data: {
              hasDuplicateMappings,
              hasParentChildConflicts,
            },
          })
          return Promise.reject(errorObj)
        })
    },
    updateCustom1sInMenu({ commit }, { menuId, custom1Ids }) {
      commit('UPDATE_MENU', { id: menuId, custom1Ids })
    },
    addCustom1ToMenu({ commit, state }, { menuId, custom1Id }) {
      let custom1Ids = state.records[menuId].custom1Ids || []
      custom1Ids.push(custom1Id)
      commit('UPDATE_MENU', { id: menuId, custom1Ids })
    },
    deleteCustom1InMenu({ commit, state }, { menuId, custom1Id }) {
      let custom1Ids = state.records[menuId].custom1Ids || []
      let index = custom1Ids.indexOf(custom1Id)
      custom1Ids.splice(index, 1)
      commit('UPDATE_MENU', { id: menuId, custom1Ids })
    },
    deleteFoodItemIdInMenu({ commit, state }, { foodItemId, menuId }) {
      let foodItemIds = state.records[menuId].foodItemIds
      let index = foodItemIds.indexOf(foodItemId)
      foodItemIds.splice(index, 1)
      commit('UPDATE_MENU', { id: menuId, foodItemIds })
    },
    updateDuplicateMappingsInMenu({ commit }, { menuId, duplicateMappingIds }) {
      commit('UPDATE_MENU', { id: menuId, duplicateMappingIds })
    },
    updateParentChildConflictsInMenu({ commit }, { menuId, parentChildConflictIds }) {
      commit('UPDATE_MENU', { id: menuId, parentChildConflictIds })
    },
    downloadMenu({ state, getters }, { menuId }) {
      let menu = state.records[menuId]
      let name = menu.name
      let description = menu.description
      let org = getters.organisationShortName
      let fileName = (name || description || org).split(' ').join('_') + '.csv'
      return api.downloadMenu(menu.id).then((data) => {
        // Add BOM at the start of the blob so old Excel can parse as utf-8
        const blob = new Blob(['\uFEFF' + data], { type: 'text/csv;charset=utf-8' })
        saveAs(blob, fileName)
      })
    },
    checkMenuIssues({ getters, dispatch, commit }, { menuId }) {
      return dispatch('menu-management/menus/getStatistics', { menuId }, { root: true })
        .then(() =>
          dispatch('menu-management/menus/validateMenu', { menuId }, { root: true }).catch(
            (errorObj) => {
              if (errorObj.status === 422) {
                return Promise.resolve(errorObj)
              } else {
                return Promise.reject(errorObj)
              }
            }
          )
        )
        .then((validationError) => {
          let hasIssues = false
          let menu = getters.menuById(menuId)
          let statistics = menu.statistics
          let positiveStatistics = Object.keys(statistics).filter((key) => {
            return statistics[key] > 0
          })
          hasIssues = !!intersection(positiveStatistics, [
            'foodItemsCostZero',
            'foodItemsCostNull',
            'foodItemsPortionMissing',
            'hasDuplicateMappings',
            'hasParentChildConflicts',
          ]).length
          commit('STORE_STATISTICS', {
            menuId,
            data: {
              hasIssues,
            },
          })
          return validationError
        })
    },
    addTrackersToMenu({ commit }, { id, trackers }) {
      commit('UPDATE_MENU', { id, trackers })
    },
  },
  mutations: {
    UPLOAD_PROGRESS(state, percentage) {
      state.uploadProgressPercentage = percentage
    },
    STORE_TOTAL(state, total) {
      state.availableItems = total
    },
    STORE_RECORDS(state, { records, ids }) {
      state.records = { ...state.records, ...records }
      state.ids = ids
    },
    UPDATE_RECORDS(state, { records }) {
      let updates = Object.values(records)
        .map((record) => {
          return merge(state.records[record.id], record)
        })
        .reduce((updates, record) => ((updates[record.id] = record), updates), {})
      state.records = { ...state.records, ...updates }
    },
    STORE_LANGUAGES(state, languages) {
      state.languages = languages
    },
    STORE_CURRENCIES(state, currencies) {
      state.currencies = currencies
    },
    STORE_EXTERNAL_SYSTEMS(state, externalSystems) {
      state.externalSystems = externalSystems
    },
    UPDATE_MENU(state, menu) {
      if (!menu.stageIds) delete menu.stageIds
      state.records[menu.id] = { ...state.records[menu.id], ...menu }
    },
    STORE_MENU_STATES(state, menuStates) {
      state.menuStates = menuStates
    },
    STORE_STATISTICS(state, { menuId, data }) {
      state.records[menuId]['statistics'] = { ...state.records[menuId].statistics, ...data }
    },
    UPDATE_RECORD_SETTINGS(state, { id, ...settings }) {
      state.recordsSettings[id] = { id, ...state.recordsSettings[id], ...settings }
    },
  },
}
