import mapFoodItem from './get-food-item'

export default function (json) {
  let { records, conflicts } = json.data
  let defaultFacet = json.metadata.defaultFacet
  return {
    foodItemRecords: Object.values(records).reduce((records, foodItem) => {
      records[foodItem.id] = mapFoodItem(foodItem)
      return records
    }, {}),
    records: conflicts,
    defaultFacet: {
      code: defaultFacet.taxonomyCode,
      mapping: defaultFacet.taxonomyMapping,
    },
  }
}
