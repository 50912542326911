<template>
  <Loading-Spinner
    v-if="loadingStatus === 'LOADING'"
    class="mx-1"
  />
  <ul
    v-else
    v-click-outside="collapseNodes"
    class="org-picker"
    aria-orientation="horizontal"
    :aria-busy="loadingStatus === 'LOADING'"
    data-hierarchy-quick-select
    @keydown.left.stop.prevent="goToPreviousNode"
    @keydown.right.stop.prevent="goToNextNode"
    @keydown.home.stop.prevent="goToFirstNode"
    @keydown.end.stop.prevent="goToLastNode"
  >
    <Organisation-picker-node
      v-for="(node, index) in pathNodes"
      :id="node.id"
      :key="node.id"
      ref="node"
      :class="{
        'org-picker-node--allow-shrink': allowShrink(index),
      }"
      :parent-id="node.parentId"
      :tabindex="node.id === focusedNodeId ? 0 : -1"
      :is-open="expandedNode === node.id"
      :show-archived="showArchived"
      :aria-expanded="expandedNode === node.id"
      @open="expandNode(node.id)"
      @close="collapseNodes()"
      @node-select="onNodeSelect"
    />

    <!-- Fake node for the next children -->
    <Organisation-picker-node
      v-if="hasNextLevel"
      ref="node-next"
      class="org-picker-button-next button--icon"
      :parent-id="selectedNodeId"
      :tabindex="focusedNodeId === 'NEXT' ? 0 : -1"
      :is-open="expandedNode === 'NEXT'"
      :show-expand-indicator="false"
      :show-archived="showArchived"
      :aria-expanded="expandedNode === 'NEXT'"
      @open="expandNode('NEXT')"
      @close="collapseNodes()"
      @node-select="onNodeSelect"
    >
      <span class="org-picker-button-next-label">{{ $t('hierarchy.label.next') }}</span>

      <SvgIcon
        class="org-picker-icon-next p-0"
        :class="[expandedNode === 'NEXT' && 'org-picker-icon-next--open']"
        name="24-ic-arrow-next"
        sx
      />
    </Organisation-picker-node>
  </ul>
</template>

<script>
import OrganisationPickerNode from './OrgPickerNode.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    OrganisationPickerNode,
  },
  props: {
    selectedNodeId: {
      type: [String, Number],
    },
    showArchived: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['node-select'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      // keeps activating focus on elements
      // only one element should be focusable in this component
      // tab should not be allowed to move between the nodes
      focusedNodeId: this.selectedNodeId,

      // a pointer that helps with calculations when moving to new nodes
      selectedIndex: 0,
      expandedNode: null,
      loadingStatus: 'IDLE',
    }
  },
  computed: {
    // this is the node that is currently being focused
    selectedNode() {
      return this.$store.getters['hierarchy/nodes/byId'](this.selectedNodeId)
    },
    // "breadcrumb" arrangement of the node and its parent nodes
    // should be sorted like the qualified name
    pathNodes() {
      return this.getHierarchy(this.selectedNodeId)
    },
    hasNextLevel() {
      return !!this.$store.getters['hierarchy/nodes/getChildrenNodes'](this.selectedNodeId).length
    },
    maxIndex() {
      return this.pathNodes.length - 1 + (this.hasNextLevel ? 1 : 0)
    },
  },
  watch: {
    selectedNodeId: {
      handler(id) {
        this.focusedNodeId = id
        this.getTree(id)
      },
      immediate: true,
    },
    pathNodes(list) {
      if (list.length) {
        this.selectedIndex = this.findFocusedNodeIndex()
      } else this.selectedIndex = 0
    },
  },
  mounted() {
    this.selectedIndex = this.pathNodes.findIndex(({ id }) => id === this.focusedNodeId)
  },

  methods: {
    getTree(id) {
      this.loadingStatus = this.loadingStatus === 'LOADED' ? 'LOADED' : 'LOADING'
      return this.$store
        .dispatch(`hierarchy/nodes/getTree`, id)
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch((e) => {
          this.toast.error(this.$t('toast.error.getData'))
          this.loadingStatus = 'ERROR'
          throw e
        })
    },

    findFocusedNodeIndex() {
      if (this.focusedNodeId === 'NEXT') {
        return this.maxIndex
      }
      return this.pathNodes.findIndex(({ id }) => id === this.focusedNodeId)
    },
    getHierarchy(id, nodes = []) {
      // Recursively get the parents of a node.
      // This includes the original node
      // and it should look like the "qualifiedName" in terms of order.
      // The selected node should be the last item and each parent should
      // be arranged from right to left
      let node = this.$store.getters['hierarchy/nodes/byId'](id)
      if (!node) return nodes
      // adds the selectedNode as the single original item in the list
      if (!nodes.length) {
        nodes.push(node)
      }
      let parent = this.$store.getters['hierarchy/nodes/byId'](node.parentId)
      if (parent) {
        // starts adding parents to the left of the node
        nodes.unshift(parent)
        return this.getHierarchy(parent.id, nodes)
      }
      // when no more parents are found, return the list
      return nodes
    },
    goToPreviousNode() {
      let index = this.selectedIndex
      index = index - 1 >= 0 ? index - 1 : index
      this.changeFocusedNode(index)
    },
    goToNextNode() {
      let index = this.selectedIndex
      index = index + 1 <= this.maxIndex ? index + 1 : index
      this.changeFocusedNode(index)
    },
    goToFirstNode() {
      let index = 0
      this.changeFocusedNode(index)
    },
    goToLastNode() {
      let index = this.maxIndex - 1
      this.changeFocusedNode(index)
    },
    changeFocusedNode(index) {
      this.selectedIndex = index
      if (this.pathNodes[index]) {
        this.focusedNodeId = this.pathNodes[index].id
        this.$refs['node'][index].$el.focus()
        if (this.expandedNode) {
          this.expandNode(this.focusedNodeId)
        }
      } else {
        this.focusedNodeId = 'NEXT'
        this.$refs['node-next'].$el.focus()
        if (this.expandedNode) {
          this.expandNode('NEXT')
        }
      }
    },
    expandNode(id) {
      this.focusedNodeId = id
      this.selectedIndex = this.findFocusedNodeIndex()
      this.expandedNode = id
    },
    collapseNodes() {
      this.expandedNode = null
    },
    onNodeSelect(id) {
      this.$emit('node-select', id)
      this.$nextTick(() => {
        this.changeFocusedNode(this.selectedIndex)
      })
    },
    allowShrink(index) {
      // Allow shrinking only the levels between the first group and the last three groups
      return index > 0 && index < this.pathNodes.length - 2
    },
  },
}
</script>

<style lang="scss">
.org-picker {
  display: flex;
  min-width: 0;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: auto;
  @include respond-min(medium) {
    flex-direction: row;
  }
}

.org-picker-button-next {
  &:hover,
  &:focus {
    .org-picker-icon-next {
      transform: rotate(90deg);
    }

    .org-picker-icon-next--open {
      transform: rotate(-90deg);
    }
  }
}

.org-picker-button-next-label {
  @include respond-min(medium) {
    @include sr-only;
  }
}

.org-picker-icon-next {
  margin: 0 theme('spacing.1');
}

.org-picker-icon-next--open {
  transform: rotate(-90deg);
}
</style>
