<template>
  <div
    class="Toggle"
    :class="{ 'is-open': isOpen || keepOpen }"
  >
    <div
      ref="trigger"
      class="Toggle-trigger"
      @click="toggle"
    >
      <slot
        name="trigger"
        :is-open="isOpen"
        >Trigger</slot
      >
    </div>
    <div
      v-if="isOpen || keepOpen"
      v-click-outside="away"
      class="Toggle-content"
    >
      <slot
        name="content"
        :toggle="toggle"
        >Content</slot
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'HubToggle',
  props: {
    keepOpen: Boolean,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    away() {
      this.isOpen = false
    },
    setLabel(text) {
      this.$refs.trigger.querySelector('.Toggle-label').innerHTML = text
    },
  },
}
</script>
