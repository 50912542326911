export default function (data) {
  return {
    locales: data.locales.map((locale) => ({
      code: locale.code,
      name: locale.value,
    })),
    currencies: data.currencies.map((currency) => ({
      code: currency.code,
      country: currency.country,
      symbol: currency.symbol,
    })),
    timezones: data.timezones.map((timezone) => ({
      code: timezone.timeZone,
      name: timezone.displayName,
    })),
    weightUnits: data.weightUnits.map((weight) => ({
      id: weight.id,
      name: weight.name,
      description: weight.description,
    })),
    temperatureUnits: data.temperatureUnits.map((temp) => ({
      id: temp.id,
      name: temp.name,
      description: temp.description,
    })),
  }
}
