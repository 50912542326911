<template>
  <div class="SearchBox">
    <div class="SearchBox-header">
      <div class="formGroup--search">
        <SvgIcon
          name="24-ic-search"
          xl
          class="p-3"
        />
        <input
          ref="$search"
          type="text"
          class="formControl"
          :placeholder="$t('search.placeholder.sites')"
          @input="runTask($event.target.value)"
        />
      </div>
      <div class="SearchBox-count">{{ $t('search.results', { count: results.length }) }}</div>
    </div>
    <template v-if="results.length">
      <div
        v-if="performedTask"
        class="SearchBox-hint"
      >
        {{ $t('search.info.bestMatch') }}
      </div>
      <ul
        v-if="sites.length"
        class="results"
      >
        <template
          v-for="(site, i) in results"
          :key="site.legacyId"
        >
          <li :class="['results-item', 'results-item--' + i]">
            <a
              data-test-site-item
              href
              @click.prevent="handleSiteChange(site)"
            >
              <span
                class="block text-grey-500"
                v-html="site.orgName"
              ></span>
              <span
                class="block"
                v-html="site.parsedName"
              ></span>
            </a>
          </li>
        </template>
      </ul>
    </template>
  </div>
</template>

<script setup>
import fuzzysort from 'fuzzysort'
import { useSelectedSite } from '@/composables/useSelectedSite'
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'

const isBlank = (val) => {
  return /\S/.test(val) === false || val === undefined || val === null
}

const emit = defineEmits(['change'])
const results = ref([])
const performedTask = ref(false)
const $search = ref(null)

const { getters } = useStore()
const userId = computed(() => getters['auth/userId'])
const sites = computed(() => getters['auth/organisations/sites'])
useSelectedSite({ userId: userId.value, allowedSites: sites })

const runTask = (term) => {
  if (isBlank(term)) {
    results.value = sites.value
    performedTask.value = false
    return
  }

  const searchResults = fuzzysort.go(term, sites.value, {
    keys: ['shortName'],
    threshold: 0.5,
    limit: 100,
  })

  results.value = searchResults.map((r) => {
    // split short name into l1 and l14 / l15 / l16
    const highlights = r[0].highlight('<mark>', '</mark>').split(' / ... / ')
    return {
      id: r.obj.id,
      legacyId: r.obj.legacyId,
      orgName: highlights[0] || r.obj.orgName,
      parsedName: highlights[1] || r.obj.parsedName,
    }
  })

  performedTask.value = true
}
const handleSiteChange = (site) => {
  emit('change', site)
}
onMounted(() => {
  $search.value.focus()
  runTask('')
})
</script>
