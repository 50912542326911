import axios, { buildURL } from '../api-axios'
import encode from '@/utils/strict-uri-encode'

export default {
  fetchCoversSales(query = {}) {
    return axios.get(buildURL('/covers-sales', query))
  },

  postCoversSales(model) {
    return axios.post('/covers-sales', model)
  },

  putCoversSales(model) {
    return axios.put(`/covers-sales/${encode(model.new.id)}`, model)
  },
}
