<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Breadcrumbs
      class="mx-4 ps-4"
      :history="[{ name: 'term-index', title: $t('taxman.baseTerms') }]"
      :current="$t('taxman.baseTerms.new')"
    />
    <Hero>{{ $t('navigation.taxonomy') }}</Hero>

    <FormTerm
      :record="record"
      :parent-id="$route.params.parentId"
    />
  </LayoutDefault>
</template>

<script>
import { newTermModel } from '@/store/modules/taxonomy/terms'

import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'

import FormTerm from '@/components/FormTerm/FormTerm.vue'

export default {
  name: 'NewTerm',
  components: {
    LayoutDefault,
    Hero,
    Breadcrumbs,
    FormTerm,
  },
  data() {
    return {
      record: null,
    }
  },

  created() {
    this.$store.dispatch('taxonomy/getTermOptions')

    const parentId = this.$route.params.parentId

    this.record = newTermModel({
      parentId,
    })
  },
}
</script>
