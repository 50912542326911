<template>
  <!-- This is a "save" button which gets disabled and shows a progress spinner while data is being saved -->
  <button
    class="button"
    type="button"
    :class="{ 'button--isLoading': inProgress }"
    :disabled="inProgress"
    v-bind="$attrs"
    ref="button"
  >
    <slot name="icon"></slot>
    <span data-test-button-content><slot></slot></span>
    <LoadingSpinner
      v-if="inProgress"
      class="spinner"
      :class="spinnerClass"
    />
  </button>
</template>

<script setup>
import { ref } from 'vue'
const button = ref(null)
// Stop attribute inheritance because we `v-bind="$attrs"` and this results in duplicated classes
defineOptions({
  inheritAttrs: false,
})
defineProps({
  // Toggle this to show the loading spinner
  inProgress: {
    type: Boolean,
    default: false,
  },
  spinnerClass: {
    type: [String, Array, Object],
    default: '',
  },
})
defineExpose({
  // even though we use v-bind="$attrs", the native focus function does not seem to be attached like events and other props are
  focus: () => {
    button.value.focus()
  },
})
</script>
