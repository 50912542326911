<template>
  <div class="image-loader">
    <div
      v-if="status !== 'LOADED'"
      class="image-loader-accessories"
      :style="imgStyle"
    >
      <slot
        v-if="status === 'LOADING'"
        name="loading"
      >
        <Loading-Spinner />
      </slot>
      <slot
        v-if="status === 'ERROR'"
        name="error"
      >
        <SvgIcon
          name="no-image"
          xl
        />
      </slot>
    </div>
    <img
      v-show="status === 'LOADED'"
      ref="img"
      :class="`image-loader-fit-${imageFitting}`"
      :src="srcBlob"
      :alt="alt"
      :style="imgStyle /* had to do this otherwise the attributes get reset to 0 for some reason */"
    />
  </div>
</template>

<script>
import { DEBOUNCE_DELAY } from '@/store/constants'

import api from '@/store/api-axios'
import axios from 'axios'
import imagesMixin from '@/mixins/images'
export default {
  mixins: [imagesMixin],
  props: {
    src: {
      type: String,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    alt: {
      type: String,
      default: '',
    },
    imageFitting: {
      type: String,
      default() {
        return 'contain'
      },
      validator(val) {
        return ['contain', 'fill', 'cover'].indexOf(val) >= 0
      },
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
  },
  emits: ['image-loaded'],
  data() {
    return {
      status: 'IDLE',
      internalWidth: this.width,
      internalHeight: this.height,
      debouncer: null,
      imageUrl: null,
      srcBlob: '',
      cancelToken: null,
    }
  },
  computed: {
    imgStyle() {
      // Setup initial size values
      // If we don't have values or the values are 0, it's most likely an error so we stretch the image to fill the container
      // Also, the values get converted to strings for further processing
      let width = this.internalWidth ? this.internalWidth + '' : '100%'
      let height = this.internalHeight ? this.internalHeight + '' : '100%'
      // If sizes have suffix, we keep those.
      // Ortherwise it means we have numbers so we add the `px` suffix for the inline styling to work
      width = width.search(/[a-z]|%/) >= 0 ? width : `${width}px`
      height = height.search(/[a-z]|%/) >= 0 ? height : `${height}px`
      return { width, height }
    },
  },

  watch: {
    src: {
      handler(src) {
        // check if src is a blob so that we don't try to load it from server :)
        let cachedBlob = this.$store.getters['image-cache/imageBySrc'](src)
        if (cachedBlob) {
          this.srcBlob = cachedBlob
        } else if (src && !this.isBlob(src)) {
          this.status = 'LOADING'
          this.cancelToken = axios.CancelToken.source()
          // we might turn this into a src watcher if we need to handle dynamic src changes
          api
            .get(src, {
              responseType: 'arraybuffer',
              cancelToken: this.cancelToken.token,
            })
            .then((response) => {
              if (response.data instanceof ArrayBuffer) {
                return new Blob([response.data])
              } else {
                return Promise.reject()
              }
            })
            .then((blob) => {
              this.srcBlob = URL.createObjectURL(blob)
              this.$store.commit('image-cache/CACHE_IMAGE', {
                src: this.src,
                srcBlob: this.srcBlob,
              })
              this.onImageLoad()
              this.status = 'LOADED'
            })
            .catch(() => {
              this.srcBlob = null
              this.onImageLoad()
              this.status = 'ERROR'
            })
        } else if (src) {
          this.srcBlob = src
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.src) {
      if (this.isBlob(this.src)) this.status = 'LOADED'
      else this.status = 'LOADING'
    } else this.status = 'ERROR'
  },
  mounted() {
    this.resize()
    window.addEventListener('resize', this.debouncedResize)
    this.$refs.img.onload = this.onImageLoad
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedResize)
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
  },
  methods: {
    debouncedResize() {
      window.clearTimeout(this.debounce)
      this.debounce = window.setTimeout(this.resize, DEBOUNCE_DELAY)
    },
    resize() {
      if (this.aspectRatio) {
        this.internalWidth = this.$el.clientWidth
        this.internalHeight = this.$el.clientWidth * this.aspectRatio
      }
    },
    onImageLoad() {
      this.status = 'LOADED'
      this.$emit('image-loaded', { srcBlob: this.srcBlob })
      window.requestAnimationFrame(() => {
        // do the resize on next render so that the parent component has a chance to draw the containers
        this.resize()
      })
    },
  },
}
</script>

<style lang="scss">
.image-loader {
  background: theme('colors.white');
  position: relative;
  width: 100%;
  height: 100%;

  &-accessories {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .icon {
    max-width: 50%;
    min-width: 1rem;
    max-height: 50%;
    color: theme('colors.slate.DEFAULT');
  }

  img,
  .image-loader-fit-contain {
    display: block;
    object-fit: contain;
  }

  .image-loader-fit-cover {
    object-fit: cover;
  }

  .image-loader-fit-fill {
    object-fit: fill;
  }
}
</style>
