import nodes from './nodes'
import wasteTargets from './waste-targets'
import localisation from './localisation'

export default {
  namespaced: true,
  modules: {
    nodes: nodes,
    'waste-targets': wasteTargets,
    localisation: localisation,
  },
}
