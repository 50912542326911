export default (data) => ({
  sendWeekly: data.sendWeekly,
  sendMonthly: data.sendMonthly,
  sendQuarterly: data.sendQuarterly,
  focus: data.focus,
  comparisonLevel: data.comparisonLevel,
  comparisonPoints: data.comparisonPoints,
  events: data.events,
  hideCosts: data.hideCosts,
  customBreakdown: data.customBreakdown,
  annualisedSavings: data.annualisedSavings,
  vision: data.vision,
  topAreas: data.topAreas,
  wasteByDay: data.wasteByDay,
  wasteByTimeOfDay: data.wasteByTimeOfDay,
  foodOverview: data.foodOverview,
  foodGroupBreakdown: data.foodGroupBreakdown,
  topItems: data.topItems,
  otherProductionAreas: data.otherProductionAreas,
  rework: data.rework,
  staffFood: data.staffFood,
  outOfStock: data.outOfStock,
  usage: data.usage,
  benchmarking: data.benchmarking,
  savings: data.savings,
  excludeCustom1: data.excludeCustom1,
  custom2: data.custom2,
  excludeCustom3: data.excludeCustom3,
})
