<template>
  <div
    v-if="hasAnyMessages"
    role="alert"
    aria-live="assertive"
    tabindex="-1"
    class="flex flex-column gap-2"
    ref="container"
  >
    <slot
      name="errors"
      v-bind="{ allErrors }"
    >
      <div
        v-for="(error, i) in allErrors"
        :key="`e${error.key + i}`"
        class="text-acai font-bold rounded-3xl border border-carrot flex py-2 px-4 bg-carrot-200 gap-2 w-fit"
      >
        <SvgIcon
          name="24-ic-warning"
          class="text-carrot -ml-2 flex-grow-0 flex-shrink-0"
        />
        <div
          v-test="'validation-error'"
          class="flex-grow"
          v-html="error.message"
        />
      </div>
    </slot>

    <slot
      name="warnings"
      v-bind="{ allWarnings }"
    >
      <div
        v-for="(error, i) in allWarnings"
        :key="`w${error.key + i}`"
        class="text-acai font-bold rounded-3xl border border-grey-200 flex py-2 px-4 bg-white gap-2 w-fit"
      >
        <SvgIcon
          name="24-ic-info"
          class="text-carrot -ml-2 flex-grow-0 flex-shrink-0"
        />
        <div
          v-test="'validation-warning'"
          v-html="error.message"
        />
      </div>
    </slot>
  </div>
</template>

<script setup>
import { computed, watch, ref, nextTick } from 'vue'
const props = defineProps({
  validator: {
    type: Object,
    required: false,
  },
  scrollIntoView: {
    type: Boolean,
    default: true,
  },
  serverErrors: {
    type: Object,
    default: () => ({}),
  },
  serverWarnings: {
    type: Object,
    default: () => ({}),
  },
})

const container = ref(null)
const activeErrors = computed(() => {
  if (!props.validator) return []
  return (props.validator.$errors || []).map((error, index) => {
    return {
      ...error,
      id: `${index} - ${error.$uid}`,
      key: error.$validator,
    }
  })
})

const hasErrors = computed(() => {
  return !!activeErrors.value.length
})
const errorsReducer = (errors) => {
  return Object.keys(errors).reduce((bucket, key) => bucket.concat(errors[key]), [])
}
const allServerErrors = computed(() => {
  return errorsReducer(props.serverErrors)
})
const allWarnings = computed(() => {
  return errorsReducer(props.serverWarnings)
})
const allErrors = computed(() => {
  return activeErrors.value.concat(allServerErrors.value)
})
const hasServerErrors = computed(() => {
  return allServerErrors.value.length
})
const hasAnyErrors = computed(() => {
  return hasErrors.value || hasServerErrors.value
})
const hasWarnings = computed(() => {
  return allWarnings.value.length
})
const hasAnyMessages = computed(() => {
  return hasAnyErrors.value || hasWarnings.value
})

watch(hasAnyMessages, (isTrue) => {
  if (isTrue && props.scrollIntoView) {
    nextTick(() => {
      container.value.scrollIntoView({ behavior: 'smooth', block: 'center' })
    })
  }
})
</script>
