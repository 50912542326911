import moment from 'moment'

export function withLocale(locale, fn) {
  let returnValue
  if (locale) {
    let previousLocale = moment.locale()
    moment.locale(locale)
    returnValue = fn()
    moment.locale(previousLocale)
  } else {
    returnValue = fn()
  }
  return returnValue
}

export function formatDate(date, format, locale = null) {
  if (locale) {
    return withLocale(locale, () => moment(date).format(format))
  } else {
    return moment(date).format(format)
  }
}

export function weekday(date) {
  return moment(date).weekday()
}

export function isoWeekday(date) {
  return moment(date).isoWeekday()
}

export function getWeekdaysShort() {
  return moment.weekdaysShort(true)
}

export function getWeekdaysMin() {
  return moment.weekdaysMin(true)
}

export function getWeekdays() {
  return moment.weekdays(true)
}

export function add(date, quantity, unit) {
  return moment(date).add(quantity, unit).toDate()
}

export function subtract(date, quantity, unit) {
  return moment(date).subtract(quantity, unit).toDate()
}

export function startOf(date, unit) {
  return moment(date).startOf(unit).toDate()
}

export function endOf(date, unit) {
  return moment(date).endOf(unit).toDate()
}

export function isAfter(date1, date2) {
  return moment(date1).isAfter(date2)
}

export function isBefore(date1, date2) {
  return moment(date1).isBefore(date2)
}

export function isSame(date1, date2, unit) {
  return moment(date1).isSame(date2, unit)
}

export function isBetween(date, start, end, unit, inclusivity) {
  return moment(date).isBetween(start, end, unit, inclusivity)
}

export function diff(date1, date2) {
  return moment(date1).diff(date2)
}

export function normalizeDate(dateOrMoment) {
  if (dateOrMoment === undefined || dateOrMoment === null || dateOrMoment instanceof Date) {
    return dateOrMoment
  } else {
    return dateOrMoment.toDate()
  }
}

export function normalizeCalendarDay(day) {
  day.moment = moment(day.date)
  return day
}

export function normalizeCalendarValue(value) {
  if (value) {
    return { date: value.date, moment: value.date ? moment(value.date) : undefined }
  }
  return { date: undefined, moment: undefined }
}

export function getDefaultLocale() {
  return moment.locale()
}
