<template>
  <transition name="fade">
    <div
      v-if="date"
      class="timeline-tooltip"
      :style="{ left: `${left - contentOffset / 2}px`, top: `${top}px` }"
    >
      <div class="timeline-tooltip-content">
        <div class="pb-2">
          <b>{{ type }}</b>
        </div>
        <div
          v-if="!isCategorised"
          class="text-apple uppercase"
        >
          {{ $t('titles.uncategorised') }}
        </div>
        <div v-else>{{ foodInstance }}</div>
        <div>{{ `${cost || ''} ${cost && weight ? ' / ' : ''} ${weight || ''} ` }}</div>
        <div>{{ date }}</div>
      </div>
      <span
        class="timeline-tooltip-arrow"
        :style="{ left: `${contentOffset / 2}px` }"
      />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    type: String,
    stage: String,
    foodInstance: String,
    weight: String,
    cost: String,
    date: String,
    position: {
      type: Object,
      default() {
        return {
          x: 0,
          y: 0,
        }
      },
    },
  },
  data() {
    return {
      left: 0,
      top: 0,
      contentOffset: 0,
    }
  },
  computed: {
    isCategorised() {
      return this.stage && this.foodInstance
    },
  },
  watch: {
    position({ x, y }) {
      this.left = x
      this.top = y
      this.contentOffset = 0
      this.$nextTick(() => {
        let tooltipBox = this.$el.querySelector('.timeline-tooltip-content').getBoundingClientRect()
        let maxRight = this.$el.parentElement.clientWidth
        let placementRight = this.$el.offsetLeft + tooltipBox.width - 40
        let placementLeft = this.$el.offsetLeft - tooltipBox.width / 2 + 40
        if (placementRight > maxRight) {
          let delta = placementRight - this.$el.clientWidth / 2 - maxRight
          this.contentOffset = delta
        }
        if (placementLeft < 0) {
          let delta = placementLeft - tooltipBox.width / 2
          this.contentOffset = delta
        }
      })
    },
  },
}
</script>

<style lang="scss">
.timeline-tooltip {
  position: absolute;
}

.timeline-tooltip-content {
  padding: theme('spacing.3') theme('spacing.5') theme('spacing.4');
  position: absolute;
  left: -50%;
  bottom: theme('spacing[2.5]');
  transform: translateX(-50%);
  background: theme('colors.white');
  z-index: 1;
  box-shadow: 0 5px 15px 0 rgb(0, 0, 0, 0.1);
  color: theme('colors.acai.DEFAULT');
  transition: all 0.3s ease-out;
  font-size: theme('fontSize.sm');
  border-radius: 1rem;
  white-space: nowrap;
}

.timeline-tooltip-arrow {
  position: absolute;
  bottom: 0;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  background: theme('colors.white');
  box-shadow: -7px 7px 7px 0 rgb(0, 0, 0, 0.05);
  z-index: 1;
  width: theme('spacing.5');
  height: theme('spacing.5');
}
</style>
