const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g
const STRING_DASHERIZE_REGEXP = /[ _]/g

export const decamelize = (str) => {
  return str.replace(STRING_DECAMELIZE_REGEXP, '$1_$2').toLowerCase()
}

export const dasherize = (key) => {
  return decamelize(key).replace(STRING_DASHERIZE_REGEXP, '-')
}

export const isBlank = (val) => {
  return /\S/.test(val) === false || val === undefined || val === null
}

export const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('')
