import { spitURL } from '@/store/api-axios'
export default {
  methods: {
    isBlob(url) {
      return url.indexOf('blob') >= 0
    },
    generateSmallThumbnailUrl(url) {
      if (!url) return ''
      if (this.isBlob(url)) return url
      return url ? spitURL(url, { filter: { thumbnail: 'S' } }) : ''
    },
    generateThumbnailUrl(url) {
      if (!url) return ''
      if (this.isBlob(url)) return url
      return url ? spitURL(url, { filter: { thumbnail: 'M' } }) : ''
    },
  },
}
