export default {
  namespaced: true,
  state: {
    records: {},
  },
  getters: {
    imageBySrc: (state) => (src) => state.records[src],
  },
  mutations: {
    CACHE_IMAGE(state, { src, srcBlob }) {
      state.records[src] = srcBlob
    },
  },
}
