import Image from '../../models/image'
export default function (image) {
  return new Image({
    id: image.id,
    imageUrl: '/image-library/images/' + image.id,
    source: image.source,
    type: image.type,
    name: image.name,
    description: image.description,
    sizes: image.sizes,
    keywords: image.imageMeta
      .filter((meta) => meta.property === 'keywordFull')
      .map((meta) => ({ name: meta.value, count: meta.count })),
    taxonomyMappings: image.imageMeta
      .filter((meta) => meta.property === 'taxonomyCodeFull')
      .map((meta) => meta.value),
    itemTypes: image.imageMeta
      .filter((meta) => meta.property === 'itemType')
      .map((meta) => ({ name: meta.value, count: meta.count })),
    imageMeta: image.imageMeta || [],
    archive: image.archive,
    orgRestricted: image.orgRestricted,
    orgRestrictedId: image.orgRestrictedId,
  })
}
