export const replaceSearchMarkers = (prop) => {
  const pattern = /(\^)([^^$]*?)(\$)/gi
  return prop.replace(pattern, function (match, start, value) {
    return `<mark>${value}</mark>`
  })
}

export const removeSearchMarkers = (prop) => {
  const pattern = /(<mark>)([^^$]*?)(<\/mark>)/gi
  return prop.replace(pattern, (match, start, value) => value)
}

// Finds all markers (pairs of ^ and &) within a string.
// Returns each markers' position within the string
export const findSearchMarkers = (prop) => {
  const pattern = /(\^)([^^$]*?)(\$)/gi
  let ref = prop
  let result
  let indices = []

  while ((result = pattern.exec(ref)) !== null) {
    const start = result.index
    const end = start + result[0].length - 1

    indices.push([start, end])
  }

  return indices
}

// Removes all markers from a string and also returns the start & end indices
// for each value within those markers
export const sanitizeSearchMarkers = (prop) => {
  const indices = findSearchMarkers(prop)
  const pattern = /(\^)([^^$]*?)(\$)/gi

  const updatedIndices = indices.map((pair, i) => {
    const start = pair[0]
    const end = pair[1]

    // i * 2 accounts for any markers removed before the current pair
    return [start - i * 2, end - i * 2 - 2]
  })

  return {
    value: prop.replace(pattern, function (match, start, value) {
      return value
    }),
    indices: updatedIndices,
  }
}

// https://stackoverflow.com/questions/4313841/insert-a-string-at-a-specific-index
const stringSplice = function (substr, start, delCount, newSubStr) {
  return substr.slice(0, start) + newSubStr + substr.slice(start + Math.abs(delCount))
}

export const addSearchMarks = ({ record, matches }) => {
  const props = ['displayName', 'termName', 'code']

  props
    .filter((prop) => record[prop] !== null)
    .forEach((prop) => {
      let value = record[prop]

      // reverse order so we don't need to recalculate indices as we insert stuff
      matches[prop].reverse().forEach((pair) => {
        const start = pair[0]
        const end = pair[1]

        value = stringSplice(value, end + 1, 0, '</mark>')
        value = stringSplice(value, start, 0, '<mark>')
      })

      record[prop] = value
    })

  return record
}

export const normalizeSearch = (data) => {
  const props = ['displayName', 'termName', 'code']

  return data.map((branch) => {
    let lastRecord = branch.pop()
    let matches = {}

    props
      .filter((prop) => lastRecord[prop] !== null)
      .forEach((prop) => {
        const sanitised = sanitizeSearchMarkers(lastRecord[prop])
        lastRecord[prop] = sanitised.value
        matches[prop] = sanitised.indices
      })

    lastRecord = {
      record: lastRecord,
      matches,
    }

    return branch.map((record) => ({ record })).concat([lastRecord])
  })
}

export const recordBranchToTree = (branch, currentTree = { id: 'root', children: [] }) => {
  const record = branch[0]
  const nodeFound = currentTree.children.find((el) => el.id === record.id)

  let ref

  if (!nodeFound) {
    const newNode = {
      id: record.id,
      content: record,
      children: [],
      parent: currentTree,
    }

    currentTree.children.push(newNode)
    ref = newNode
  } else {
    ref = nodeFound
  }

  if (branch.length > 1) {
    recordBranchToTree(branch.slice(1), ref)
  }

  return currentTree
}

export const pathToRoot = (id, getParent, path = []) => {
  path.push(id)
  const record = getParent(id)

  if (record.parentId !== null) {
    pathToRoot(record.parentId, getParent, path)
  }

  return path
}

// Not necessarily efficient. DFS for now
/* eslint-disable no-param-reassign */
export const searchTree = (roots, id, result = false) => {
  for (let i = 0; !result && i < roots.length; i++) {
    let node = roots[i]

    if (node.id === id) {
      result = node
    }

    if (!result && node.children) {
      result = searchTree(node.children, id, result)
    }
  }

  return result
}
/* eslint-enable no-param-reassign */

export const normalizeImplicitFacets = (shallowTree) => {
  if (shallowTree === null) {
    return {
      ids: null,
      rootHash: {},
      records: [],
    }
  }

  let ids = []
  let rootHash = {}
  let records = []

  shallowTree.forEach((rootNode) => {
    records.push(rootNode)
    rootHash[rootNode.id] = []

    rootNode.children.forEach((item) => {
      records.push(item)
      ids.push(item.id)
      rootHash[rootNode.id].push(item.id)
    })
  })

  return {
    ids,
    rootHash,
    records,
  }
}
