<template>
  <collapsible-section
    data-test-reporting-params-collapsible
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.reportParams.title') }}
    </template>
    <template #header-side="{ isOpen }">
      <div
        v-if="isOpen"
        class="flex flex-right flex-expand"
      >
        {{ $t('organisations.reportParams.description') }}
      </div>
    </template>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <form
        action="/"
        @submit.prevent="saveChanges"
      >
        <div class="form-layout--x3 mb-8">
          <!-- Baseline period-->
          <div class="formGroup">
            <div>
              <div class="flex flex-between flex-middle">
                <label id="reportingParamsBaselineDuration">
                  {{ $t('organisations.reportParams.baselineDurationLabel') }}
                </label>
                <SvgIcon
                  v-tooltip="{
                    theme: 'tooltip-neutral',
                    content: $t('organisations.reportParams.baselineLabelDesc'),
                    placement: 'top',
                  }"
                  class="text-slate ml-2"
                  name="24-ic-info"
                >
                </SvgIcon>
              </div>
              <div class="flex">
                <ValidationGroup
                  v-slot="{ hasAnyErrors }"
                  class="mr-1 reporting-params-baseline-item"
                  :validator="v$.buffer.baselineStart"
                >
                  <input
                    v-model="v$.buffer.baselineStart.$model"
                    data-test-reporting-params-baseline-start
                    aria-labelledby="reportingParamsBaselineDuration"
                    :placeholder="DATE_FORMAT_ISO_8601"
                    :class="[
                      'formControl mr-1',
                      hasAnyErrors && 'is-invalid',
                      baselineWarningKeys.length && 'is-warning',
                    ]"
                    type="date"
                  />
                </ValidationGroup>

                <ValidationGroup
                  v-slot="{ hasAnyErrors }"
                  class="reporting-params-baseline-item"
                  :validator="v$.buffer.baselineEnd"
                >
                  <input
                    v-model="v$.buffer.baselineEnd.$model"
                    data-test-reporting-params-baseline-end
                    aria-labelledby="reportingParamsBaselineDuration"
                    :placeholder="DATE_FORMAT_ISO_8601"
                    :disabled="!buffer.baselineStart"
                    :min="buffer.baselineStart"
                    :class="[
                      'formControl',
                      hasAnyErrors && 'is-invalid',
                      baselineWarningKeys.length && 'is-warning',
                    ]"
                    type="date"
                  />
                </ValidationGroup>
              </div>
            </div>

            <div
              v-for="(warningKey, i) in baselineWarningKeys"
              :key="i"
              data-test-reporting-params-baseline-warnings
              class="formFeedback is-warning"
            >
              {{ $t(warningKey) }}
            </div>
          </div>

          <!-- Report Type -->
          <fieldset class="formGroup flex gap-4 flex-wrap">
            <legend class="label flex flex-middle w-full">
              {{ $t('organisations.reportParams.reportTypeLabel') }}
              <SvgIcon
                v-tooltip="{
                  theme: 'tooltip-neutral',
                  content: $t('organisations.reportParams.reportTypeLabelDesc'),
                  placement: 'top',
                }"
                class="text-slate ml-2"
                name="24-ic-info"
              ></SvgIcon>
            </legend>
            <div
              v-for="{ value, labelKey } in reportTypeOptions"
              :key="value"
              class="customControl customControl--radio"
            >
              <input
                v-bind="{ [`data-test-reporting-params-report-type-${value}`]: '' }"
                :id="`reporting-params-report-type-${value}`"
                v-model="v$.buffer.type.$model"
                type="radio"
                name="time-period"
                class="customControl-input"
                :value="value"
              />
              <label
                :for="`reporting-params-report-type-${value}`"
                class="customControl-label"
              >
                {{ $t(labelKey) }}
              </label>
            </div>
          </fieldset>

          <!-- Weekly report start-->
          <div class="row-start">
            <div class="flex flex-between flex-middle">
              <label for="reportingParamsEndOfReportingWeek">
                {{ $t('organisations.reportParams.endOfReportingWeekLabel') }}
              </label>
              <SvgIcon
                v-tooltip="{
                  theme: 'tooltip-neutral',
                  content: $t('organisations.reportParams.endOfReportingWeekLabelDesc'),
                  placement: 'top',
                }"
                class="text-slate ml-2"
                name="24-ic-info"
              ></SvgIcon>
            </div>
            <ValidationGroup
              v-slot="{ hasAnyErrors }"
              :validator="v$.buffer.endOfReportingWeek"
            >
              <input
                id="reportingParamsEndOfReportingWeek"
                v-model="v$.buffer.endOfReportingWeek.$model"
                data-test-reporting-params-end-of-reporting-week
                :placeholder="DATE_FORMAT_ISO_8601"
                :class="['formControl', { 'is-invalid': hasAnyErrors }]"
                type="date"
                :min="buffer.baselineEnd"
                :disabled="!buffer.baselineEnd"
              />
            </ValidationGroup>
          </div>

          <!-- Lunch duration -->
          <div class="row-start">
            <div class="flex flex-between flex-middle">
              <label id="reportingParamsLunchDuration">
                {{ $t('organisations.reportParams.lunchDurationLabel') }}
              </label>
              <SvgIcon
                v-tooltip="{
                  theme: 'tooltip-neutral',
                  content: $t('organisations.reportParams.lunchTimeLabelDesc'),
                  placement: 'top',
                }"
                class="text-slate ml-2"
                name="24-ic-info"
              ></SvgIcon>
            </div>
            <div class="flex">
              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                class="mr-1 reporting-params-baseline-item"
                :validator="v$.buffer.lunchTimeStart"
              >
                <select
                  v-model="v$.buffer.lunchTimeStart.$model"
                  aria-labelledby="reportingParamsLunchDuration"
                  data-test-reporting-params-lunch-start
                  :class="['customSelect', { 'is-invalid': hasAnyErrors }]"
                  @change="handleLunchStartChange"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('organisations.reportParams.lunchStartLDefaultOpt') }}
                  </option>
                  <option
                    v-for="option in lunchTimeOptions"
                    :key="option"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </ValidationGroup>

              <ValidationGroup
                v-slot="{ hasAnyErrors }"
                class="reporting-params-baseline-item"
                :validator="v$.buffer.lunchTimeEnd"
              >
                <select
                  v-model="v$.buffer.lunchTimeEnd.$model"
                  aria-labelledby="reportingParamsLunchDuration"
                  data-test-reporting-params-lunch-end
                  :class="['customSelect', { 'is-invalid': hasAnyErrors }]"
                >
                  <option
                    :value="null"
                    disabled
                  >
                    {{ $t('organisations.reportParams.lunchEndLDefaultOpt') }}
                  </option>
                  <option
                    v-for="option in lunchTimeOptions"
                    :key="option"
                    :disabled="isLunchEndOptionDisabled(option)"
                    :value="option"
                  >
                    {{ option }}
                  </option>
                </select>
              </ValidationGroup>
            </div>
          </div>

          <!-- Weather Location-->
          <div class="row-start">
            <div class="flex flex-between flex-middle">
              <label for="reportingParamsWeatherLocation">
                {{ $t('organisations.reportParams.weatherLocationLabel') }}
              </label>
              <SvgIcon
                v-tooltip="{
                  theme: 'tooltip-neutral',
                  content: $t('organisations.reportParams.weatherLocationLabelDesc'),
                  placement: 'top',
                }"
                class="text-slate ml-2"
                name="24-ic-info"
              ></SvgIcon>
            </div>

            <ValidationGroup
              v-slot="{ hasAnyErrors }"
              :validator="v$.buffer.weatherLocation"
            >
              <search-weather-location
                id="reportingParamsWeatherLocation"
                v-model="v$.buffer.weatherLocation.$model"
                data-test-reporting-params-weather-location
                :class="[hasAnyErrors && 'is-invalid']"
              />
            </ValidationGroup>
          </div>
        </div>

        <!-- Button Bar-->
        <div class="flex flex-between">
          <div class="buttonGroup">
            <ButtonSave
              data-test-reporting-params-submit
              :disabled="!hasChanges || v$.$anyError || savingStatus === 'SAVING'"
              :is-saving="savingStatus === 'SAVING'"
            >
              {{ $t('actions.saveChanges') }}
            </ButtonSave>

            <button
              :disabled="!hasChanges"
              type="button"
              class="button button--secondary"
              @click="init"
            >
              <span>{{ $t('actions.cancel') }}</span>
            </button>
          </div>
          <router-link
            class="button button--tertiary"
            :to="{
              name: 'hierarchy-node-reporting-configurations',
              query: { ...$route.query },
            }"
          >
            <SvgIcon name="24-ic-filters" />
            <span>{{ $t('organisations.subscribers.actions.configReports') }}</span>
          </router-link>
        </div>
      </form>
    </loaded-content>
  </collapsible-section>
</template>
<script>
import { useToast } from 'vue-toastification'
import moment from 'moment'
import { isEqual } from 'lodash'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import SearchWeatherLocation from './ReportingParameters/SearchWeatherLocation.vue'
import { TIME_FORMAT, DATE_FORMAT_ISO_8601 } from '@/store/constants'
import ButtonSave from '@/components/ButtonSave.vue'

const generateHoursList = ({ start, end }) => {
  let items = []
  let currentDate = moment(start, TIME_FORMAT)
  const endLimit = moment(end, TIME_FORMAT)

  while (currentDate.isBefore(endLimit) || currentDate.isSame(endLimit)) {
    items.push(currentDate.format(TIME_FORMAT))
    currentDate = currentDate.add(30, 'minutes')
  }

  return items
}

function Model(overwrites) {
  return {
    type: null,
    lunchTimeStart: null,
    lunchTimeEnd: null,
    baselineStart: null,
    baselineEnd: null,
    endOfReportingWeek: null,
    weatherLocation: null,
    ...overwrites,
  }
}

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    ValidationGroup,
    SearchWeatherLocation,
    ButtonSave,
  },
  props: {
    preOpen: {
      type: Boolean,
      default: false,
    },
    locationId: String,
  },
  emits: ['open', 'close'],

  validations() {
    return {
      buffer: {
        type: {},
        weatherLocation: {
          required,
        },
        lunchTimeStart: {
          required,
        },
        lunchTimeEnd: {
          required,
        },
        baselineStart: {
          required,
        },
        baselineEnd: {
          required,
        },
        endOfReportingWeek: {
          required,
        },
      },
    }
  },
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },

  data() {
    return {
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
      buffer: {},
      DATE_FORMAT_ISO_8601,
    }
  },
  computed: {
    storeReportingParams() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId).reportingParams
    },
    hasChanges() {
      return !isEqual(this.storeReportingParams, this.buffer)
    },
    lunchTimeOptions() {
      return generateHoursList({ start: '9:00', end: '17:30' })
    },
    baselineWarningKeys() {
      const baselineStart = moment(this.buffer.baselineStart)
      const baselineEnd = moment(this.buffer.baselineEnd)
      const baselineInDays = baselineEnd.diff(baselineStart, 'days') + 1
      let warningKeys = []

      if (baselineInDays % 7) {
        warningKeys.push('validation.multipleOfSeven')
      }
      if (baselineInDays > 21) {
        warningKeys.push('validation.moreThanThreeWeeks')
      }

      return warningKeys
    },
    reportTypeOptions() {
      return [
        {
          value: 'FIVE_DAYS',
          labelKey: 'organisations.reportParams.reportTypeOptFive',
        },
        {
          value: 'SEVEN_DAYS',
          labelKey: 'organisations.reportParams.reportTypeOptSeven',
        },
      ]
    },
  },
  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeReportingParams', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.init()

        const { baselineStart, baselineEnd, weatherLocation, endOfReportingWeek } = this.v$.buffer

        // Validate and highlight these fields
        // because on node creation they don't get any default values
        baselineStart.$touch()
        baselineEnd.$touch()
        weatherLocation.$touch()
        endOfReportingWeek.$touch()
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },

  methods: {
    init() {
      this.buffer = new Model(this.storeReportingParams)
    },
    handleLunchStartChange(evt) {
      const lunchTimeStartOption = moment(evt.target.value, TIME_FORMAT)
      const lunchTimeEnd = moment(this.buffer.lunchTimeEnd, TIME_FORMAT)

      if (lunchTimeStartOption.isAfter(lunchTimeEnd)) {
        this.buffer.lunchTimeEnd = null
      }
    },
    isLunchEndOptionDisabled(option) {
      const lunchTimeEndOption = moment(option, TIME_FORMAT)
      const lunchTimeStart = moment(this.buffer.lunchTimeStart, TIME_FORMAT)

      return lunchTimeEndOption.isBefore(lunchTimeStart)
    },
    saveChanges() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.savingStatus = 'SAVING'

      this.$store
        .dispatch('hierarchy/nodes/saveNodeReportingParams', {
          nodeId: this.locationId,
          payload: this.buffer,
        })
        .then(() => {
          this.v$.buffer.$reset()
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((e) => {
          this.savingStatus = 'ERROR'

          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
        .finally(() => {
          this.init()
        })
    },
  },
}
</script>
<style lang="scss">
.reporting-params-baseline-item {
  width: 100%;
  overflow: hidden;
}
</style>
