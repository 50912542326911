<template>
  <fieldset class="-mx-4">
    <legend class="mx-4 mb-1 font-bold"><slot name="title" /></legend>
    <ul class="flex flex-wrap">
      <li class="mx-4 mb-1 customControl customControl--checkbox">
        <input
          :id="`${id}-option-all`"
          v-model="allSelected"
          type="checkbox"
          class="customControl-input"
          name="option-all"
          data-test-checkbox-option
          @change="toggleAll"
        />
        <label
          class="customControl-label"
          :for="`${id}-option-all`"
          >All</label
        >
      </li>
      <li
        v-for="option in list"
        :key="option.id"
        class="mx-4 mb-1 customControl customControl--checkbox"
      >
        <input
          :id="`${id}-option-${option.id}`"
          v-model="selectedOptions"
          type="checkbox"
          class="customControl-input"
          :value="option.id"
          name="option"
          data-test-checkbox-option
          @change="selectOption"
        />
        <label
          class="customControl-label"
          :for="`${id}-option-${option.id}`"
          >{{ option.name }}</label
        >
      </li>
    </ul>
  </fieldset>
</template>

<script>
export default {
  props: {
    id: String,
    // list of all possible options (except `All`)
    // [{id: String, name: Text}]
    list: Array,
    // this can be an array or null
    // should be a subset of the `list`
    // [...ids]
    modelValue: Array,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      // the list of selected ids
      selectedOptions: this.modelValue,
      // state of the `All` button
      allSelected: false,
    }
  },
  computed: {
    listOptionIds() {
      return this.list.map(({ id }) => id)
    },
  },
  watch: {
    modelValue(ids) {
      if (!ids) {
        this.setupDefaults()
      } else if (ids.join(',') !== this.selectedOptions.join(',')) {
        this.setupDefaults(ids)
      }
    },
  },
  created() {
    this.setupDefaults(this.modelValue)
    if (!this.modelValue || !this.modelValue.length) {
      this.selectedOptions = this.listOptionIds
      this.allSelected = true
    }
  },
  methods: {
    setupDefaults(ids) {
      // many cases to cover when deciding on default values
      if (!ids) {
        // if we get null then it means we have no filters selected
        // `All` is unchecked)
        this.selectedOptions = []
        this.allSelected = false
      } else if (!ids.length) {
        // When we get an empty array we check all filters.
        // We do this because we should be getting all the data in this case.
        // Basically, all filters checked means we're no longer sending a filter to the backend
        // `All` is checked
        this.selectedOptions = this.listOptionIds
        this.allSelected = true
      } else if (ids.length === this.listOptionIds.length) {
        // if we get the full list of list checked, we also check the `All` button
        // because it makes sense
        this.allSelected = true
      } else if (ids.length !== this.listOptionIds.length) {
        // if any of the filters are unchecked, we also uncheck the `All` button
        // because it makes sense
        this.allSelected = false
      }
    },
    toggleAll() {
      // actions when toggleing the `All` buttong
      if (this.allSelected) {
        // fill the selected options with the list of all options
        this.selectedOptions = this.listOptionIds
        this.emitValueWithDelay([])
      } else {
        // empty the selected options
        this.selectedOptions = []
        this.emitValueWithDelay(null)
      }
    },
    selectOption() {
      // we need to implement a similar logic going out as when we do when setting up defaults
      if (!this.selectedOptions.length) {
        // we send null so it can be differentiated from an empty array
        // null means we should hide all data until the user selects an option
        this.allSelected = false
        this.emitValueWithDelay(null)
      } else if (this.selectedOptions.length === this.listOptionIds.length) {
        // we send an empty array when all filters are checked (or `All` button was pressed)
        // this is because we get all data by default if no filters are specified
        this.allSelected = true
        this.emitValueWithDelay([])
      } else {
        // if we have individual options selected then we send those as they are and get data based on them
        this.allSelected = false
        this.emitValueWithDelay(this.selectedOptions)
      }
    },
    emitValueWithDelay(value) {
      // delay the input event a bit because the check/uncheck animations get stuck while processing new data
      if (this.timeout) {
        window.clearTimeout(this.timeout)
      }
      this.timeout = window.setTimeout(() => {
        this.$emit('update:modelValue', value)
      }, 150)
    },
  },
}
</script>

<style></style>
