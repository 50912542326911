<script setup>
// A component used to display the appropriate custom icon inside the toast notification

import { getCurrentInstance, computed } from 'vue'

// Get the component instance in order to access the parent component
const instance = getCurrentInstance()

/**
 * @return {string} string representing the icon's name
 */
const iconName = computed(() => {
  // Get the toast type from the parent component's props
  const type = instance.parent.props.type

  switch (type) {
    case 'error':
    case 'warning':
      return '24-ic-warning'
    case 'success':
      return '24-ic-check'
    default:
      return '24-ic-info'
  }
})
</script>

<template>
  <SvgIcon :name="iconName"></SvgIcon>
</template>
