import axios, { spitURL } from '../api-axios'
import { keyBy } from 'lodash'
import mapSystem from './mappings/get-system'
export default {
  fetchSystems({ query, signal }) {
    return axios
      .get(spitURL('systems-management/v2/systems', query), { signal })
      .then((resp) => resp.data)
      .then((json) => ({
        records: keyBy(json.data.map(mapSystem), 'id'),
        sortedRecordIds: json.data.map(({ id }) => id),
        total: json.metadata.total,
      }))
  },
  fetchSystem(id) {
    return axios
      .get(`systems-management/systems/${id}`)
      .then((resp) => resp.data)
      .then(mapSystem)
  },
  patchSystem(id, body) {
    return axios
      .patch(`systems-management/systems/${id}`, body)
      .then((resp) => resp.data)
      .then(mapSystem)
  },
}
