<template>
  <input
    :id="id"
    :ref="id"
    data-hierarchy-create-name-field
    class="formControl"
    :value="modelValue"
    type="text"
    @input="$emit('update:modelValue', $event.target.value)"
    @keydown="$emit('keydown', $event)"
    @keydown.stop
  />
</template>
<script>
import { mapGetters } from 'vuex'
import { ORG_LEVELS } from '@/store/constants'

export default {
  props: {
    id: String,
    modelValue: String,
    parentId: String,
    level: [Number, String],
  },
  emits: ['update:modelValue', 'keydown'],
  computed: {
    ...mapGetters('hierarchy/nodes', ['getLevelName', 'getChildrenNodes']),
  },

  watch: {
    level: {
      handler(level) {
        let defaultName = this.hashDefaultNodeName(
          this.$t('hierarchy.action.createNodeDefault', {
            name: this.getLevelName(this.level),
          }).trim()
        )

        if (level === ORG_LEVELS - 1) {
          defaultName = this.hashDefaultNodeName(this.$t('hierarchy.action.createL15NodeDefault'))
        } else if (level === ORG_LEVELS) {
          defaultName = this.hashDefaultNodeName(this.$t('hierarchy.action.createL16NodeDefault'))
        }
        this.$emit('update:modelValue', defaultName)
      },
      immediate: true,
    },
  },
  mounted() {
    this.focus()
  },
  methods: {
    // In case of duplication add an increment number at the end of the default name
    // e.g. Main Kitchen (2)

    hashDefaultNodeName(name) {
      const siblings = this.getChildrenNodes(this.parentId)
      let hashedName = name
      let increment = 0

      while (siblings.filter((node) => node.name === hashedName).length) {
        increment++
        hashedName = `${name} (${increment})`
      }

      return hashedName
    },
    focus() {
      this.$refs[this.id].focus()

      this.$nextTick(() => {
        this.$refs[this.id].select()
      })
    },
  },
}
</script>
