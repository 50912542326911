<template>
  <form
    action="/"
    @submit.prevent="saveData"
    @reset.prevent="cancelEdit"
  >
    <div
      v-if="editMode !== 'IMAGE_SELECTOR'"
      class="editable-row-content-section layout-col-x3"
    >
      <div>
        <!-- Food Item Name -->
        <ValidationGroup
          :validator="v$.buffer.name"
          class="formGroup"
          v-slot="{ hasAnyErrors }"
        >
          <label for="FoodItem">{{ $t('menus.foodItems.labels.name') }}</label>
          <input
            id="FoodItem"
            v-model.trim="v$.buffer.name.$model"
            type="text"
            class="formControl"
            :class="{ 'is-invalid': hasAnyErrors }"
            data-test-food-item-name
            :disabled="disabled || readonly"
            @change="onNameChange"
          />
        </ValidationGroup>

        <!-- Food Item English Name -->
        <ValidationGroup
          v-if="menuIsNonEnglish"
          :validator="v$.buffer.nameEnglish"
          class="formGroup"
          v-slot="{ hasAnyErrors }"
        >
          <label for="FoodItemEnglish">{{ $t('menus.foodItems.labels.nameEnglish') }}</label>
          <input
            id="FoodItemEnglish"
            v-model.trim="v$.buffer.nameEnglish.$model"
            type="text"
            class="formControl"
            :class="{ 'is-invalid': hasAnyErrors }"
            data-test-food-item-name-english
            :disabled="disabled || readonly"
            @change="onNameChange"
          />
        </ValidationGroup>

        <!-- Annotation label -->
        <ValidationGroup
          v-if="isVisionMenu && $can('manage', 'menus')"
          :validator="v$.buffer.annotationLabel"
          class="formGroup"
          v-slot="{ hasAnyErrors }"
        >
          <label for="FoodItemAnnotationLabel">
            {{ $t('menus.foodItems.labels.annotationLabel') }}
          </label>
          <input
            id="FoodItemAnnotationLabel"
            v-model.trim="v$.buffer.annotationLabel.$model"
            type="text"
            class="formControl"
            :class="{ 'is-invalid': hasAnyErrors }"
            data-test-food-item-annotation-label
            :readonly="readonly"
          />
        </ValidationGroup>

        <!-- Cost -->
        <ValidationGroup
          :validator="v$.buffer.cost"
          class="formGroup"
          v-slot="{ hasAnyErrors }"
        >
          <label
            for="Cost"
            data-test-food-item-cost-label
          >
            {{
              $t('menus.foodItems.labels.cost', {
                currency: menu && menu.currency,
                weight: $t(`settings.weight.short.${weightPreference}`),
              })
            }}
          </label>
          <input
            id="Cost"
            v-model.trim="v$.buffer.cost.$model"
            type="number"
            step="any"
            class="formControl"
            :class="{ 'is-invalid': hasAnyErrors }"
            data-test-food-item-cost
            :disabled="disabled || readonly"
          />
        </ValidationGroup>

        <!-- Portion -->
        <ValidationGroup
          :validator="v$.buffer.weight"
          class="formGroup"
          v-slot="{ hasAnyErrors }"
        >
          <label
            for="Portion"
            data-test-food-item-portion-label
          >
            {{ $t('menus.foodItems.labels.portion', { unit: weightUnit }) }}
          </label>
          <input
            id="Portion"
            v-model.trim="v$.buffer.weight.$model"
            type="number"
            step="any"
            class="formControl"
            :class="{ 'is-invalid': hasAnyErrors }"
            data-test-food-item-portion
            :disabled="disabled || readonly"
          />
        </ValidationGroup>

        <!-- Annotation enabled -->
        <div
          v-if="isVisionMenu && $can('manage', 'menus')"
          class="formGroup customControl customControl--checkbox"
        >
          <input
            id="annotationEnabledCheck"
            v-model="v$.buffer.annotationEnabled.$model"
            type="checkbox"
            name="annotationEnabledCheck"
            class="customControl-input"
            data-test-food-item-annotation-enabled
            :disabled="disabled || readonly"
          />
          <label
            for="annotationEnabledCheck"
            class="customControl-label"
          >
            {{ $t('menus.foodItems.labels.annotationEnabled') }}
          </label>
        </div>

        <!-- Prediction enabled -->
        <div
          v-if="menu && menu.vision && $can('manage', 'menus')"
          class="formGroup customControl customControl--checkbox"
        >
          <input
            id="predictionEnabledCheck"
            v-model="v$.buffer.predictionEnabled.$model"
            type="checkbox"
            name="predictionEnabledCheck"
            class="customControl-input"
            data-test-food-item-prediction-enabled
            :disabled="disabled || readonly"
          />
          <label
            for="predictionEnabledCheck"
            class="customControl-label"
          >
            {{ $t('menus.foodItems.labels.predictionEnabled') }}
          </label>
        </div>

        <!-- Hide from trackers -->
        <div
          v-if="this.$can('manage', 'menus')"
          class="formGroup customControl customControl--checkbox"
        >
          <input
            id="trackerVisibilityCheck"
            v-model="v$.buffer.hiddenFromTrackers.$model"
            type="checkbox"
            name="trackerVisibilityCheck"
            class="customControl-input"
            data-test-food-item-hidden
            :disabled="disabled || readonly"
          />
          <label
            for="trackerVisibilityCheck"
            class="customControl-label"
          >
            {{ $t('menus.foodItems.labels.hiddenFromTrackers') }}
          </label>
        </div>
      </div>

      <div>
        <template v-if="externalSystems.length">
          <!-- External System Selector -->
          <div class="formGroup">
            <label for="ExternalSystem">
              {{ $t('menus.foodItems.labels.externalReference') }}
            </label>
            <select
              id="ExternalSystem"
              v-model.lazy="v$.buffer.externalSystemId.$model"
              class="customSelect"
              data-test-external-system-selector
              :disabled="disabled || readonly"
            >
              <option :value="null">{{ $t('actions.none') }}</option>
              <option
                v-for="system in externalSystems"
                :key="system.id"
                :value="system.id"
                :disabled="disabled || readonly"
              >
                {{ system.name }}
              </option>
            </select>
          </div>

          <!-- External System ID -->
          <div
            v-if="buffer.externalSystemId"
            class="formGroup"
          >
            <label for="ExternalReference">{{ $t('menus.foodItems.labels.externalFoodId') }}</label>
            <input
              id="ExternalReference"
              v-model="v$.buffer.externalId.$model"
              type="text"
              class="formControl"
              data-test-external-reference
              :disabled="disabled || readonly"
            />
          </div>
        </template>

        <!-- Food Group selector -->
        <fieldset
          v-if="showFoodGroups"
          class="formGroup"
        >
          <legend class="mb-1">
            {{ $t('menus.foodItems.legend.foodGroup') }}
          </legend>
          <ul
            v-if="buffer.foodGroups.length"
            class="chip-container mb-1"
            data-test-food-groups
          >
            <Chip
              v-for="(group, index) in buffer.foodGroups"
              :key="`${group.id}-${index}`"
              tag="li"
              data-test-food-group-chip
            >
              <template v-if="group.displayName">
                {{ group.displayName }} {{ `${showStageIfDuplicate(group)}` }}
              </template>
              <template v-else-if="group.name">
                <SvgIcon
                  v-tooltip="{
                    theme: 'tooltip-neutral',
                    content: 'Food group does not have an english translation',
                    placement: 'top',
                  }"
                  class="mr-1 icon--nudge-top-2 text-apple"
                  name="24-ic-info"
                />
                <span>{{ group.name }} {{ `${showStageIfDuplicate(group)}` }}</span>
              </template>
            </Chip>
          </ul>

          <router-link
            v-if="!disabled && !readonly && $route.name !== 'food-editor'"
            :to="{
              name: 'food-groups',
              params: { foodItemId: foodItem.id },
              query: { menuId, returnPath: $route.path },
            }"
            data-test-edit-food-groups
            class="button button--primary button--sm"
          >
            {{ $t('menus.foodItem.actions.editFoodGroups') }}
          </router-link>
        </fieldset>

        <fieldset
          class="formGroup"
          v-if="showFoodGroups && $route.name === 'food-editor'"
        >
          <legend class="mb-1">{{ $t('menu.foodItems.legend.foodGroupEn') }}</legend>
          <ul
            v-if="buffer.foodGroups.length"
            class="chip-container mb-1"
            data-test-food-groups-en
          >
            <template
              v-for="(group, index) in buffer.foodGroups"
              :key="`${group.id}-${index}`"
            >
              <Chip
                tag="li"
                data-test-food-group-chip-en
                :class="{
                  'chip--danger': !group.nameEnglish,
                }"
              >
                <template v-if="!group.nameEnglish">
                  <div class="flex items-center">
                    <SvgIcon
                      data-test-food-group-chip-en-tooltip
                      v-tooltip="{
                        theme: 'tooltip-neutral',
                        content: 'Food group does not have an english translation',
                        placement: 'top',
                      }"
                      class="mr-1 text-apple"
                      name="24-ic-info"
                    />
                    <span>{{ group.name }} {{ `${showStageIfDuplicate(group)}` }}</span>
                  </div>
                </template>
                <template v-else>
                  {{ group.nameEnglish }} {{ `${showStageIfDuplicate(group)}` }}
                </template>
              </Chip>
            </template>
          </ul>
        </fieldset>
      </div>

      <div
        v-if="foodItemId"
        class="editable-row-content-item"
      >
        <!-- Image thumbnail -->
        <div
          v-if="!editMode"
          class="formGroup tablet-editor-page-avatar"
        >
          <p class="tablet-editor-page-avatar-title label">{{ $t('labels.image') }}</p>
          <image-loader
            class="tablet-editor-page-avatar-image"
            :src="generateThumbnailUrl(buffer.image.url)"
            :aspect-ratio="0.65"
          />
          <div
            v-if="!readonly"
            class="tablet-editor-page-avatar-actions"
          >
            <button
              v-if="buffer.image.id"
              type="button"
              :disabled="disabled"
              class="button button--link"
              data-test-show-image-editor
              @click="editMode = 'IMAGE_EDIT'"
            >
              {{ $t('actions.editImage') }}
            </button>
            <button
              type="button"
              :disabled="disabled"
              class="button button--link"
              data-test-show-image-selector
              @click="editMode = 'IMAGE_SELECTOR'"
            >
              {{ $t('actions.chooseAnotherImage') }}
            </button>
            <button
              type="button"
              :disabled="disabled"
              class="button button--link"
              data-test-show-image-uploader
              @click="editMode = 'IMAGE_UPLOAD'"
            >
              {{ $t('actions.uploadNew') }}
            </button>
          </div>
        </div>

        <!-- Image Upload -->
        <Image-uploader
          v-if="editMode === 'IMAGE_UPLOAD'"
          :aspect-ratio="0.65"
          :org-id="menu.parentLocationId"
          @close="editMode = null"
          @new-file="imageUploaded"
        />
        <Image-editor
          v-if="editMode === 'IMAGE_EDIT'"
          :id="buffer.image.id"
          :url="buffer.image.url"
          :org-id="menu.parentLocationId"
          :aspect-ratio="0.65"
          @close="editMode = null"
          @save="imageEdited"
        />
      </div>
    </div>

    <!-- Suggested images -->
    <Image-selector
      v-if="editMode === 'IMAGE_SELECTOR'"
      v-model="v$.buffer.image.$model"
      class="editable-row-content-section"
      :url-params="{ menuId, foodItemId }"
      :org-id="menu.parentLocationId"
      @close="editMode = null"
      @save="imageChanged"
    />

    <div
      v-if="!readonly"
      class="editable-row-content-section"
    >
      <!-- Rename warning message -->
      <hr />
      <transition name="slide-up">
        <div
          v-if="showNameChangeWarning"
          class="errorBlock is-warning text-carrot mb-4"
          data-test-rename-warning
        >
          <div class="errorBlock-icon">
            <SvgIcon name="24-ic-warning" />
          </div>
          <p
            v-html="
              $t('menus.foodItems.messages.itemRename', {
                item: buffer.nameEnglish,
                mapping: foodItem.taxonomy.mapping,
              })
            "
          ></p>
        </div>
      </transition>
      <transition name="slide-up">
        <div
          v-if="showNameChangeWarning && foodItem.imageId"
          class="errorBlock is-warning text-carrot mb-4"
          data-test-rename-warning
        >
          <div class="errorBlock-icon">
            <SvgIcon name="24-ic-warning" />
          </div>
          <p
            v-html="
              $t('messages.itemRenamePhoto', {
                item: buffer.nameEnglish,
              })
            "
          ></p>
        </div>
      </transition>

      <!-- Validation errors -->
      <ValidationResults
        class="mb-4"
        :server-errors="serverErrors"
        :server-warnings="serverWarnings"
        data-test-error-messages
      />

      <!-- Form actions -->
      <template v-if="editMode !== 'IMAGE_SELECTOR'">
        <!-- Confirm name change -->
        <div
          v-if="showNameChangeWarning"
          class="buttonGroup buttonGroup--halfs"
        >
          <button
            type="button"
            class="button button--secondary"
            data-test-revert-name
            @click.prevent="revertName"
          >
            {{ $t('actions.revert') }}
          </button>
          <button
            type="button"
            class="button button--primary"
            data-test-confirm-name
            @click.prevent="confirmName"
          >
            {{ $t('actions.confirm') }}
          </button>
        </div>

        <slot
          v-if="!showNameChangeWarning"
          name="actions"
          :any-dirty="v$.$anyDirty"
        >
          <!-- Save and cancel -->
          <div class="buttonGroup buttonGroup--halfs mb-4">
            <button
              type="submit"
              class="button button--primary"
              :disabled="!v$.$anyDirty"
              data-test-save
            >
              {{ $t('actions.save') }}
            </button>
            <button
              type="reset"
              class="button button--secondary"
              :disabled="!v$.$anyDirty"
              data-test-cancel
            >
              {{ $t('actions.cancel') }}
            </button>
          </div>
          <!-- Delete action -->
          <DeleteWithPrompt
            :disabled="disabled"
            @confirm="deleteItem"
            ref="delete"
          >
            <p
              v-if="foodGroupsString"
              class="font-bold"
            >
              {{ $t('menus.foodItem.messages.deleteFromGroups') }}
            </p>
            <p
              v-else
              class="font-bold"
            >
              {{ $t('menus.foodItem.messages.delete') }}
            </p>
            <p class="mb-4">{{ foodGroupsString }}</p>
          </DeleteWithPrompt>
        </slot>
      </template>
    </div>
  </form>
</template>
<script>
import { useToast } from 'vue-toastification'
import { mapState } from 'vuex'
import { required, decimal } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Chip from '@/components/Chip/Chip.vue'
import FoodItemMixin from './FoodItemMixin'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import { isNumber } from '@/utils/number'
import ImageLoader from '@/components/image-loader.vue'
import ImageUploader from '../TabletEditor/image-uploader.vue'
import menuMixin from '../menuMixin'
import ImageSelector from '../TabletEditor/image-selector.vue'
import ImageEditor from '../TabletEditor/image-editor.vue'
import imagesMixin from '@/mixins/images'
import { intersection, invert, isEmpty, isEqual } from 'lodash'
import { useValidation } from '@/composables/useValidation'

function EditModel(overwrites) {
  return {
    name: null,
    nameEnglish: null,
    annotationLabel: null,
    cost: null,
    weight: null,
    externalSystemId: null,
    externalId: null,
    annotationEnabled: true,
    predictionEnabled: true,
    foodGroups: [],
    image: {
      id: null,
      url: null,
    },
    hiddenFromTrackers: false,
    ...overwrites,
  }
}

export default {
  components: {
    Chip,
    DeleteWithPrompt,
    ValidationGroup,
    ValidationResults,
    ImageLoader,
    ImageUploader,
    ImageEditor,
    ImageSelector,
  },
  mixins: [FoodItemMixin, imagesMixin, menuMixin],
  props: {
    menuId: String,
    foodItemId: String,
    disabled: Boolean, // disables all fields and buttons
    readonly: Boolean, // disables all fields and hides action buttons
    defaults: Object,
  },
  emits: ['delete', 'save', 'cancel'],
  setup: () => {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse } = useValidation()
    return {
      v$: useVuelidate(),
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
    }
  },
  data() {
    return {
      showNameChangeWarning: false,
      buffer: new EditModel(),
      deletePrompt: false,
      editMode: null,
      loadingStatus: 'IDLE',
    }
  },
  computed: {
    ...mapState('menu-management/menus', ['externalSystems']),
    foodItem() {
      if (this.defaults) return this.defaults
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
    foodGroups() {
      if (!this.foodItem || !this.foodItem.foodGroupIds) return []
      let ids = this.foodItem.foodGroupIds
      let foodGroups = this.$store.getters['menu-management/food-groups/foodGroupsById'](ids)
      // really stupid check here because the store getter can retun an empty list of objects
      // this happens when the user reloads the page and there are no foodgroups in the store
      if (foodGroups.length && ids[0] !== foodGroups[0].id) {
        foodGroups = ids.map((id) => ({
          id,
        }))
      }
      return foodGroups
    },
    foodGroupsString() {
      // {{ group.name }} {{ `${showStageIfDuplicate(group)}` }}
      if (!this.buffer.foodGroups) return ''
      return this.buffer.foodGroups
        .map((group) =>
          [group.displayName, this.showStageIfDuplicate(group)].filter((item) => !!item).join(' ')
        )
        .join(', ')
    },
    showFoodGroups() {
      // hide food groups if creating new food item
      if ((this.disabled || this.readonly) && !this.foodGroups.length) return false
      if (this.$route.name === 'food-editor' && !this.foodGroups.length) return false
      return !!(this.foodItem && this.foodItem.id)
    },
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    isVisionMenu() {
      return this.menu && this.menu.vision
    },
  },
  watch: {
    defaults: {
      handler(defaults, oldDefaults) {
        /** Support duplicate mappings behaviour where selecting food items to merge needs to change the defaults in this form
         * ! if defaults change, we need to update the food item and reset the form
         * ! we can only reset the form to the default values if the form wasn't manually changed by the user
         * ! if we have defaults, the form is always "changed" or "dirty" so that the save button is enabled
         *
         * Added a manual diff between the current food item and the buffer to check if it was actually changed
         */
        const formWasNotChanged = isEqual(this.buffer, this.buildBufferWithFoodItem(oldDefaults))
        if (oldDefaults && formWasNotChanged) {
          this.resetFormToInitialValues()
        }
      },
    },
    foodItem: {
      handler() {
        /**
         * Repopulate the form with the new food item values if it gets updated.
         * However, keep the form as it is if it was edited before the update
         */
        if (!this.v$.$anyDirty) {
          this.resetFormToInitialValues()
        }
      },
    },
  },
  mounted() {
    if (!isEmpty(this.defaults)) {
      // we need this in order to have the save button available if there are default values
      this.resetFormToInitialValues()
      this.v$.$touch()
    }
    if (!this.v$.$anyDirty) {
      this.resetFormToInitialValues()
    }
  },
  validations() {
    return {
      buffer: {
        name: {
          required,
        },
        nameEnglish: this.menuIsNonEnglish && {
          required,
        },
        annotationLabel: {},
        cost: {
          decimal,
        },
        weight: {
          decimal,
        },
        image: {},
        annotationEnabled: {},
        predictionEnabled: {},
        hiddenFromTrackers: {},
        externalSystemId: {},
        externalId: {},
        foodGroups: {},
      },
    }
  },
  methods: {
    onNameChange() {
      if (!this.menuIsNonEnglish) {
        this.buffer.nameEnglish = this.buffer.name
      }
      if (this.foodItem && this.v$.buffer.name.$model !== this.foodItem.name) {
        let hasImage = !!(this.foodItem.image && this.foodItem.image.id)
        let hasTaxonomy = !!(this.foodItem.taxonomy && this.foodItem.taxonomy.code)
        this.showNameChangeWarning = hasImage || hasTaxonomy
      } else {
        this.showNameChangeWarning = false
      }
    },
    confirmName() {
      this.showNameChangeWarning = false
    },
    revertName() {
      this.buffer.name = this.foodItem.name
      this.buffer.nameEnglish = this.foodItem.nameEnglish
      this.showNameChangeWarning = false
    },
    showStageIfDuplicate(group) {
      const isDuplicate =
        this.buffer.foodGroups.filter((g) => g.name && group.name && g.name === group.name).length >
        1
      if (!isDuplicate) return ''
      const stageNames = this.$store.getters['menu-management/stages/stagesById'](
        group.stageIds
      ).map(({ displayName, name }) => displayName || name)
      if (!stageNames) return ''
      return `(${stageNames.join(' / ')})`
    },
    deleteItem() {
      this.$store
        .dispatch('menu-management/food-items/deleteItem', {
          foodItemId: this.foodItem.id,
          menuId: this.menuId,
        })
        .then(() => {
          this.$emit('delete')
        })
        .finally(() => {
          this.$refs.delete.finishProcessing()
        })
    },
    resetFormToInitialValues() {
      this.v$.$reset()
      let foodItem = this.foodItem
      if (!foodItem) foodItem = new EditModel()
      else if (this.defaults) foodItem = this.defaults

      this.buffer = this.buildBufferWithFoodItem(foodItem)
    },
    buildBufferWithFoodItem(foodItem = {}) {
      return new EditModel({
        name: foodItem.name,
        nameEnglish: foodItem.nameEnglish,
        annotationLabel: foodItem.annotationLabel,
        cost: isNumber(foodItem.cost)
          ? this.convertCostForDisplay(parseFloat(foodItem.cost))
          : null,
        weight: this.convertWeightForDisplay(foodItem.portion && foodItem.portion.value),
        externalSystemId: (foodItem.externalSystem && foodItem.externalSystem.id) || null,
        externalId: foodItem.externalId || null,
        foodGroups: this.foodGroups || null,
        predictionEnabled:
          foodItem.predictionEnabled !== undefined ? foodItem.predictionEnabled : true,
        annotationEnabled:
          foodItem.annotationEnabled !== undefined ? foodItem.annotationEnabled : true,
        hiddenFromTrackers: foodItem.hiddenFromTrackers || false,
        image: {
          id: foodItem.imageId,
          url: foodItem.imageUrl,
        },
      })
    },
    // save the food item changes
    // optional `props` can be provided to save only a subset of properties
    saveData({ props = [] }) {
      this.v$.buffer.$touch()
      if (this.v$.$invalid || this.showNameChangeWarning) return

      let inputOutput = {
        name: 'name',
        nameEnglish: 'nameEnglish',
        annotationLabel: 'annotationLabel',
        cost: 'cost',
        weight: 'portion',
        externalSystemId: 'externalSystem',
        externalId: 'externalId',
        predictionEnabled: 'predictionEnabled',
        annotationEnabled: 'annotationEnabled',
        hiddenFromTrackers: 'hiddenFromTrackers',
        image: 'imageId',
        foodGroups: 'foodGroupIds',
      }

      let outputInput = invert(inputOutput)

      props = props.length ? props : Object.values(inputOutput)

      let { cost, weight, externalId, externalSystemId, foodGroups } = this.buffer
      cost = isNumber(cost) ? this.convertCostForSave(parseFloat(cost)) : null
      weight = isNumber(weight) ? this.convertWeightForSave(weight) : null
      externalId = externalSystemId && externalId
      let externalSystem = this.externalSystems.find((sys) => sys.id === externalSystemId) || null

      let data = {
        name: this.buffer.name,
        nameEnglish: this.buffer.nameEnglish,
        annotationLabel: this.buffer.annotationLabel,
        cost: parseFloat(cost),
        portion: { value: parseFloat(weight) },
        externalId,
        externalSystem,
        predictionEnabled: this.buffer.predictionEnabled,
        annotationEnabled: this.buffer.annotationEnabled,
        hiddenFromTrackers: this.buffer.hiddenFromTrackers,
        imageId: this.buffer.image.id,
        foodGroupIds: foodGroups && foodGroups.length ? foodGroups.map((fg) => fg.id) : undefined,
      }
      let saveData = intersection(props, Object.keys(data)).reduce((saveData, key) => {
        saveData[key] = data[key]
        return saveData
      }, {})

      Object.keys(saveData).forEach((key) => {
        this.v$.buffer[outputInput[key]].$reset()
      })

      this.$emit('save', {
        itemId: this.foodItemId,
        menuId: this.menuId,
        data: saveData,
        allChangesSaved: !this.v$.buffer.$anyDirty,
        onError: function (err) {
          Object.keys(saveData).forEach((key) => {
            this.v$.buffer[outputInput[key]].$touch()
          })
          this.handleValidationErrorsInResponse(err)
        }.bind(this),
      })
    },
    cancelEdit() {
      this.resetFormToInitialValues()
      this.$emit('cancel')
    },
    imageUploaded({ id, file }) {
      this.buffer.image = { id, url: URL.createObjectURL(file) }
      this.saveData({ props: ['imageId'] })
      this.editMode = null
    },
    imageChanged({ id, url }) {
      this.buffer.image = { id, url }
      this.saveData({ props: ['imageId'] })
      this.editMode = null
    },
    imageEdited() {
      this.editMode = null
      this.v$.buffer.image.$reset()
    },
  },
}
</script>
