<template>
  <div class="code-builder">
    <div
      v-if="isLoading"
      class="flex m-8"
    >
      <Loading-Spinner class="spinner--lg" />
    </div>
    <table v-else>
      <tbody>
        <tr
          v-if="!hideFullCode"
          data-test-full-code
        >
          <th
            scope="row"
            class="whitespace-nowrap"
          >
            {{ $t('menuMapping.fullCode') }}
          </th>
          <td>
            <!--
              `data-test` is used here because, for some reason, the v-test directive merges the values.
              Might be related to how the if/else logic gets executed.
              -->
            <span
              v-if="term"
              class="code-builder-code break-all"
              data-test-full-code-value
            >
              <button
                v-clipboard="generateClipboardValue"
                class="button button--link"
                :title="$t('menuMapping.copyCode')"
                @click="onCodeClick"
              >
                {{ code }}
              </button>
            </span>
            <span
              v-else
              data-test-full-code-placeholder
            >
              {{ $t('menuMapping.fullCodePlaceholder') }}
            </span>
          </td>
        </tr>

        <tr data-test-term-chips>
          <th
            scope="row"
            class="whitespace-nowrap py-4"
          >
            {{ $t('menuMapping.baseTerm') }}
          </th>
          <td v-if="term">
            <div class="code-builder__selected-items">
              <Chip
                :removable="!readonly"
                data-test-base-term-chip
                @remove="removeTerm"
              >
                {{ strippedName(term) }}
              </Chip>
            </div>
          </td>
          <td
            v-else
            class="text-grey-400 py-4"
          >
            {{ $t('menuMapping.baseTermPlaceholder') }}
          </td>
        </tr>

        <tr data-test-facet-chips>
          <th
            scope="row"
            class="whitespace-nowrap py-4"
          >
            {{ $t('menuMapping.facets') }}
          </th>
          <td v-if="facets.length">
            <div class="code-builder__selected-items">
              <Chip
                v-for="facet in facets"
                :key="`chip-${facet.record.id}`"
                :removable="!readonly"
                data-test-facet-chip
                :class="{
                  'chip--neutral': recordHasWarning(facet.record.id),
                  'chip--danger': recordHasError(facet.record.id),
                }"
                @remove="removeFacet(facet.record.id)"
              >
                {{ strippedName(facet.record) }}
              </Chip>
            </div>
          </td>
          <td
            v-else
            class="text-grey-400 py-4"
          >
            {{ $t('menuMapping.facetsPlaceholder') }}
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="term || facets.length"
      class="flex flex-middle mt-2"
    >
      <button
        v-if="!readonly"
        data-test-reset-code
        class="button button--secondary"
        @click="reset"
      >
        {{ $t('menuMapping.reset') }}
      </button>

      <p
        v-if="isValidating"
        class="ml-6 text-sm text-blueberry"
      >
        {{ $t('taxman.isValidating') }}<Loading-Spinner class="spinner--md flex-inline ml-2" />
      </p>
    </div>

    <ValidationResults
      class="mt-4"
      :server-errors="serverErrors"
      :server-warnings="serverWarnings"
      data-test-validation-errors
    />
  </div>
</template>
<script>
import { useToast } from 'vue-toastification'
import { mapGetters, mapActions } from 'vuex'
import Chip from '@/components/Chip/Chip.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import { useValidation } from '@/composables/useValidation'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    Chip,
    ValidationResults,
  },
  props: ['defaultCode', 'hideFullCode', 'readonly'],
  emits: ['reset-code', 'code-change'],
  setup: () => {
    const { t } = useI18n()
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation({ genericErrorMessage: t('menuMapping.genericError') })
    return {
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },
  data() {
    return {
      // flag for avoiding emitting the default code as a change event
      decodeInProgress: false,
    }
  },
  computed: {
    ...mapGetters('code-builder', [
      'term',
      'facets',
      'error',
      'isValidating',
      'isLoading',
      'code',
      'interpretedCode',
      'strippedName',
    ]),
  },
  watch: {
    isValidating(status) {
      // emit a change event if validation is done and the change is not coming from the decoding process
      if (!status && !this.decodeInProgress) {
        this.$nextTick(this.emitNewCode)
      } else if (!status) {
        // turn the flag off once the decode and validation have been finished so we can start emitting change events
        this.decodeInProgress = false
      }
    },
    error: {
      handler(newValue) {
        this.clearServerMessages()

        if (newValue !== null) {
          this.errorHandler()
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.defaultCode && this.defaultCode !== this.code) {
      // if we have a default code added, we send it to the decoder which will also trigger a validation
      this.decodeInProgress = true
      this.$store.dispatch('code-builder/decode', this.defaultCode).catch(() => {
        this.toast.error(this.$t('toast.error.getData'))
      })
    }
  },

  methods: {
    ...mapActions('code-builder', ['removeTerm', 'removeFacet']),

    generateClipboardValue() {
      return `${this.code}\t${this.interpretedCode}`
    },
    onCodeClick() {
      this.toast.success(this.$t('menuMapping.copyCodeConfirmation'))
    },
    reset() {
      this.$store.dispatch('code-builder/resetState')
      this.emitNewCode()
      this.$emit('reset-code')
    },
    emitNewCode() {
      this.$emit('code-change', {
        code: this.code,
        mapping: this.interpretedCode,
        term: this.term,
        facets: this.facets,
        hasErrors: !!this.serverErrors['GENERAL'].length,
        hasWarnings: !!this.serverWarnings['GENERAL'].length,
      })
    },

    errorHandler() {
      this.handleValidationErrorsInResponse(this.error)
    },

    errorForRecord(id) {
      if (this.error === null || this.error.status !== 422) {
        return false
      }

      const errors = this.error.data.errors

      return errors.find((err) => {
        return err.detail !== null && err.detail.includes(id)
      })
    },

    recordHasError(id) {
      const error = this.errorForRecord(id)

      if (!error || error.level !== 'HARD') {
        return false
      }

      return true
    },

    recordHasWarning(id) {
      const error = this.errorForRecord(id)

      if (!error || error.level !== 'SOFT') {
        return false
      }

      return true
    },
  },
}
</script>
<style lang="scss">
.code-builder {
  th,
  td {
    vertical-align: top;
    text-align: left;
  }

  td {
    padding: theme('spacing.2') theme('spacing.1') theme('spacing.3');
  }

  th {
    padding: theme('spacing.2') theme('spacing.4') theme('spacing.2') 0;
    height: theme('spacing.12');
  }
}
.code-builder__selected-items {
  display: flex;
  gap: theme('spacing.2');
}
</style>
