export default function (overwrites) {
  return {
    id: null,
    name: null,
    created: null,
    // updated is the date at which an activeMenu was assigned or unassigned
    updated: null,
    // last time the tracker communicated with menu management
    lastPing: null,
    timezone: null,
    currency: null,
    organisationId: null,
    activeMenuId: null,
    ...overwrites,
  }
}
