<template>
  <div @keydown.enter.prevent>
    <h3>{{ $t('menus.tabletEditor.images.titles.edit') }}</h3>
    <div class="image-uploader-avatar mb-4">
      <image-loader
        class="image-uploader-avatar-image"
        :src="url"
        :aspect-ratio="aspectRatio"
        data-test-image-editor-preview
      />
    </div>
    <Loaded-content
      class="image-uploader"
      data-test-image-editor
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <!-- Image source -->
      <validation-group
        :validator="v$.buffer.source"
        class="formGroup"
        v-slot="{ hasAnyErrors }"
      >
        <label for="imageSource">{{ $t('menus.tabletEditor.images.labels.source') }}</label>
        <input
          id="imageSource"
          v-model.trim="v$.buffer.source.$model"
          type="text"
          class="formControl"
          :class="{ 'is-invalid': hasAnyErrors }"
          data-test-image-editor-source-field
        />
      </validation-group>

      <!-- Availability -->
      <fieldset class="formGroup flex gap-4 flex-wrap">
        <legend class="label font-bold w-full">
          {{ $t('menus.tabletEditor.images.labels.availability') }}
        </legend>
        <div class="customControl customControl--radio">
          <input
            id="imageAvailabilityAll"
            v-model="v$.buffer.availability.$model"
            type="radio"
            name="imageAvailability"
            class="customControl-input"
            value="SHARED"
            data-test-image-editor-availability-radio
          />
          <label
            for="imageAvailabilityAll"
            class="customControl-label"
            data-test-image-editor-type-photo
          >
            {{ $t('menus.tabletEditor.images.labels.allOrganisations') }}
          </label>
        </div>
        <div class="customControl customControl--radio">
          <input
            id="imageAvailabilityOrgOnly"
            v-model="v$.buffer.availability.$model"
            type="radio"
            name="imageAvailability"
            class="customControl-input"
            value="RESTRICTED"
            data-test-image-editor-availability-radio
          />
          <label
            for="imageAvailabilityOrgOnly"
            class="customControl-label"
            data-test-image-editor-type-icon
          >
            {{ $t('menus.tabletEditor.images.labels.onlyOrganisation') }}
          </label>
        </div>
      </fieldset>

      <!-- Type -->
      <fieldset class="formGroup flex gap-4 flex-wrap">
        <legend class="label font-bold w-full">
          {{ $t('menus.tabletEditor.images.labels.type') }}
        </legend>
        <div class="customControl customControl--radio">
          <input
            id="imageTypePhoto"
            v-model="v$.buffer.type.$model"
            type="radio"
            name="imageType"
            class="customControl-input"
            value="photo"
            data-test-image-editor-type-radio
          />
          <label
            for="imageTypePhoto"
            class="customControl-label"
            data-test-image-editor-type-photo
          >
            {{ $t('menus.tabletEditor.images.labels.photo') }}
          </label>
        </div>
        <div class="customControl customControl--radio">
          <input
            id="imageTypeIcon"
            v-model="v$.buffer.type.$model"
            type="radio"
            name="imageType"
            class="customControl-input"
            value="icon"
            data-test-image-editor-type-radio
          />
          <label
            for="imageTypeIcon"
            class="customControl-label"
            data-test-image-editor-type-icon
          >
            {{ $t('menus.tabletEditor.images.labels.icon') }}
          </label>
        </div>
      </fieldset>

      <!-- Keywords -->

      <Image-keywords-editor
        v-model="v$.buffer.tags.$model"
        :allow-only-lower-case="true"
      />

      <!-- Item types -->
      <div
        v-if="image && image.itemTypes.length"
        class="formGroup"
      >
        <h4>{{ $t('titles.itemTypes') }}</h4>
        <ul
          class="formGroup chip-container mb-4"
          data-test-image-selector-item-type
        >
          <chip
            v-for="itemType in image.itemTypes"
            :key="itemType.name"
          >
            {{ $t('itemTypes.' + itemType.name) }}
            <template
              v-if="itemType.count > 1"
              #counter
            >
              {{ itemType.count }}
            </template>
          </chip>
        </ul>
      </div>

      <!-- Actions -->
      <div class="buttonGroup">
        <ButtonSave
          type="button"
          :is-saving="savingStatus === 'SAVING'"
          :disabled="!v$.buffer.$anyDirty || v$.buffer.$anyError || savingStatus === 'SAVING'"
          data-test-image-editor-save
          @click="onSave"
        >
          {{ $t('actions.saveImageChanges') }}
        </ButtonSave>

        <button
          type="button"
          class="button button--secondary"
          :disabled="savingStatus === 'SAVING'"
          data-test-image-editor-cancel
          @click="onCancel"
        >
          {{ $t('actions.cancel') }}
        </button>
      </div>
      <Validation-results
        ref="validation-results"
        :server-errors="serverErrors"
        :server-warnings="serverWarnings"
        class="mt-1"
        data-test-image-editor-validation-results
      />
    </Loaded-content>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import LoadedContent from '@/components/LoadedContent.vue'
import Chip from '@/components/Chip/Chip.vue'
import ImageLoader from '@/components/image-loader.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import ImageKeywordsEditor from './image-keywords-editor.vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useValidation } from '@/composables/useValidation'
import ButtonSave from '@/components/ButtonSave.vue'

export default {
  components: {
    LoadedContent,
    Chip,
    ImageLoader,
    ValidationGroup,
    ValidationResults,
    ImageKeywordsEditor,
    ButtonSave,
  },
  props: {
    id: [String, Number],
    url: String,
    orgId: [String, Number],
    aspectRatio: {
      type: Number,
      default: () => 1,
    },
  },
  emits: ['save', 'close'],
  setup: () => {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation({
        errorStructure: ['GENERAL', 'image', 'PROCESSING_UPLOAD', 'orgRestrictedId'],
      })
    return {
      v$: useVuelidate(),
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },
  data() {
    return {
      savingStatus: 'IDLE',
      loadingStatus: 'IDLE',
      newTag: '',
      buffer: {
        source: '',
        type: '',
        availability: 'SHARED',
        tags: [],
      },
    }
  },
  computed: {
    image() {
      return this.$store.getters['menu-management/images/imageById'](this.id)
    },
  },
  created() {
    this.loadingStatus = 'LOADING'
    this.$store
      .dispatch('menu-management/images/getImage', { imageId: this.id })
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.resetToInitialValues()
      })
      .catch(() => {
        this.loadingStatus = 'ERROR'
        this.toast(this.$t('toast.error.getData'))
      })
  },
  validations() {
    return {
      newTag: { noDuplicateTag: this.noDuplicateTag },
      buffer: {
        source: { required },
        availability: {},
        type: {},
        tags: {},
      },
    }
  },
  methods: {
    onCancel() {
      this.$emit('close')
    },
    onSave() {
      this.clearServerMessages()
      this.v$.$touch()
      if (this.v$.$invalid) return
      let { source, type, tags, availability } = this.buffer
      let keywords = tags.map((tag) => ({ property: 'keywordFull', value: tag }))
      let otherMeta = this.image.imageMeta.filter(
        (meta) => !['keyword', 'keywordFull'].includes(meta.property)
      )
      let imageMeta = [].concat(keywords, otherMeta)
      let orgRestricted = availability === 'RESTRICTED'
      let orgRestrictedId = orgRestricted ? this.orgId : undefined
      let data = { source, type, imageMeta, orgRestricted, orgRestrictedId }
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('menu-management/images/updateImage', {
          imageId: this.id,
          data,
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.$emit('save')
          this.$emit('close')
        })
        .catch((error) => {
          this.savingStatus = 'ERROR'
          this.handleValidationErrorsInResponse(error)
          if (error.status === 422) {
            this.$nextTick(() => {
              this.$refs['validation-results'].$el.focus()
            })
          }
        })
    },
    addTag(tag) {
      // @TODO: Check duplicate
      this.v$.newTag.$touch()
      this.v$.buffer.tags.$touch()
      if (this.v$.newTag.$invalid) return
      if (tag) {
        this.buffer.tags.push(tag)
        this.newTag = ''
      }
    },
    removeTagAtIndex(index) {
      this.v$.buffer.tags.$touch()
      this.buffer.tags.splice(index, 1)
    },
    noDuplicateTag(tag) {
      return !this.buffer.tags.find((t) => t === tag)
    },
    resetToInitialValues() {
      this.buffer.source = this.image.source
      this.buffer.type = this.image.type
      this.buffer.availability = this.image.orgRestricted ? 'RESTRICTED' : 'SHARED'
      this.buffer.tags = this.image.keywords.map(({ name }) => name)
    },
  },
}
</script>

<style lang="scss">
.image-uploader {
  .formControl {
    max-width: 18rem;
  }
}

.image-uploader-avatar {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-left: calc(-1 * theme('spacing.4'));
  margin-right: calc(-1 * theme('spacing.4'));

  > * {
    flex: 1 1 auto;
    margin-left: theme('spacing.4');
    margin-right: theme('spacing.4');
  }

  &-image {
    flex: 0 1 8rem;
  }
}
</style>
