export default function mapBackdateProcessStatus(record) {
  return {
    backdateStart: record.backdateStart,
    backdateEnd: record.backdateEnd,
    menuId: record.menuId,
    id: record.id,
    status: record.status,
    wasteEventsTotal: record.wasteEventsTotal,
    processedWasteEventsTotal: record.processedWasteEventsTotal,
    failedEventsTotal: record.failedEventsTotal,
    firstFailedDate: record.firstFailedDate,
    selectedSites: record.selectedSites,
    processingStart: record.processingStart,
    processingEnd: record.processingEnd,
    user: record.user && {
      id: record.user.id,
      email: record.user.email,
      name: record.user.name,
    },
  }
}
