<template>
  <div
    tabindex="0"
    aria-role="button"
    :class="[
      'card',
      'is-clickable',
      {
        'is-today': isToday,
      },
    ]"
    @click="handleClick"
    @keyup.enter.space="handleClick"
    @keydown.self.enter.space.stop.prevent
  >
    <CardHeader :day="day" />
    <div class="card-body">
      <div class="CSAttr CSAttr--covers">
        <div
          v-tooltip="{
            theme: 'tooltip-warning',
            content: coversWarnings.length && $t(`CS.warnings.covers.${coversWarnings[0].key}`),
            shown: !!coversWarnings.length,
            triggers: [],
          }"
          class="CSAttr-value"
        >
          <span
            v-if="item.covers !== null"
            ref="covers"
            v-test="'data-covers'"
          >
            {{ item.covers }}
          </span>
          <span
            v-else
            class="is-null"
            >{{ $t('CS.noCovers') }}</span
          >
        </div>
        <div class="CSAttr-bar">
          <div
            v-if="item.covers !== null"
            v-test="'bar-covers'"
            class="bar bar--orange"
            :style="`width: ${coversPercentage}%;`"
          />
        </div>
      </div>
      <div class="CSAttr CSAttr--sales mt-3">
        <div
          v-tooltip="{
            theme: 'tooltip-warning',
            content: salesWarnings.length && $t(`CS.warnings.sales.${salesWarnings[0].key}`),
            shown: !!salesWarnings.length,
            triggers: [],
          }"
          class="CSAttr-value"
        >
          <span
            v-if="item.sales !== null"
            ref="sales"
            v-test="'data-sales'"
            >{{ item.sales }}</span
          >
          <span
            v-else
            class="is-null"
            >{{ $t('CS.noSales') }}</span
          >
          <i class="textIcon">{{ currency }}</i>
        </div>
        <div class="CSAttr-bar">
          <div
            v-if="item.sales !== null"
            v-test="'bar-sales'"
            class="bar bar--blue"
            :style="`width: ${salesPercentage}%;`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fitty from 'fitty/dist/fitty.min.js'

import CardHeader from './CardHeader.vue'

const asPercentage = (value, { min, max }) => {
  const diff = max - min
  const remaining = value - min

  return (remaining / diff) * 100
}

export default {
  components: {
    CardHeader,
  },
  props: {
    day: {
      type: Object,
    },
    item: {
      type: Object,
    },
    ranges: {
      type: Object,
    },
    currency: {
      type: String,
    },
    serverWarnings: {
      type: Object,
    },
  },
  emits: ['open'],
  computed: {
    isToday() {
      return this.day.isToday
    },
    coversWarnings() {
      return this.serverWarnings && this.serverWarnings.covers
    },
    salesWarnings() {
      return this.serverWarnings && this.serverWarnings.sales
    },

    coversPercentage() {
      return this.ranges ? asPercentage(this.item.covers, this.ranges.covers) : 0
    },
    salesPercentage() {
      return this.ranges ? asPercentage(this.item.sales, this.ranges.sales) : 0
    },
  },
  updated() {
    const { covers, sales } = this.$refs
    const options = { maxSize: 16, minSize: 12 }

    if (covers && this.item.covers !== null) {
      fitty(covers, options)
    }
    if (sales && this.item.sales !== null) {
      fitty(sales, options)
    }
  },
  methods: {
    handleClick() {
      this.$emit('open')

      this.analytics.track('Activation - Edit C&S', {
        id: this.item.id,
        date: this.item.date,
      })
    },
  },
}
</script>
