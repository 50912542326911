import Stage from '../../models/stage'
export default function (stage) {
  return new Stage({
    id: stage.id,
    name: stage.name,
    nameEnglish: stage.nameEnglish,
    displayName: stage.name,
    displayOrder: stage.displayOrder,
    imageId: stage.image,
    imageUrl: stage.image ? '/image-library/images/' + stage.image : null,
    custom1Id: stage.customBreakdown && stage.customBreakdown.id,
    manualOrdering: !stage.alphabeticallySortedChildren,
    ...(stage.foodGroups && {
      foodGroupIds: stage.foodGroups && stage.foodGroups.map((fg) => fg.id),
    }),
  })
}
