<template>
  <LayoutDefault
    extended-content
    class="pt-5"
  >
    <Hero>
      <template #default>{{ $t('navigation.taxonomy') }}</template>
      <template #hero-content>
        <TabsLvl1Links>
          <li>
            <router-link
              to="/taxonomy/terms"
              class="tab-item"
            >
              <span>{{ $t('taxman.baseTerms') }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/taxonomy/facets"
              class="tab-item"
            >
              <span>{{ $t('taxman.facets') }}</span>
            </router-link>
          </li>
        </TabsLvl1Links>
      </template>
    </Hero>

    <div class="shell-expandedContent">
      <div class="wrapper">
        <TreeBox class="TreeBox--fancy">
          <template #main>
            <Modal ref="moveConfirmModal">
              <template #default="{ close }">
                <div class="modal-body">
                  <p
                    class="mb-4"
                    v-html="
                      $t('messages.moveTaxonomyNode', {
                        nodeToBeMovedName: byId(pendingFacetId).termName,
                        nodeToBeMovedChildrenCount: getTotalCount(pendingFacetId),
                        newParent: byId(pendingNewParentId).termName,
                        newParentChildrenCount: getTotalCount(pendingNewParentId),
                      })
                    "
                  />
                  <div
                    v-if="isMoving"
                    class="flex flex-center p-4"
                  >
                    <Loading-Spinner class="spinner--lg" />
                  </div>
                  <div
                    v-else
                    class="buttonGroup flex-center"
                  >
                    <button
                      type="button"
                      class="button button--secondary"
                      @click="close"
                    >
                      <SvgIcon name="24-ic-clear" />
                      <span>{{ $t('actions.cancel') }}</span>
                    </button>
                    <button
                      data-test-confirm-move
                      type="button"
                      class="button button--primary"
                      @click="moveFacet(close)"
                    >
                      <SvgIcon name="24-ic-check" />
                      <span>{{ $t('actions.confirm') }}</span>
                    </button>
                  </div>
                </div>
              </template>
            </Modal>
            <TreeOfFacets
              :node-classes="
                (nodeId) => ({
                  'is-active': displayedId === nodeId,
                })
              "
              @node-click="displayRecord($event)"
              @result-click="displayRecord($event)"
            >
              <template
                v-if="pendingFacetId"
                #sticky-top-content
              >
                <div class="p-2 flex flex-middle flex-between facetToBeMoved w-full">
                  {{ byId(pendingFacetId).termName }}
                  <button
                    class="button button--icon button--round button--primary ml-2"
                    @click="pendingFacetId = null"
                  >
                    <SvgIcon name="24-ic-clear" />
                  </button>
                </div>
              </template>
              <template #node="{ nodeId }">
                <div
                  v-if="$can('manage', 'taxonomy')"
                  v-test="'node-controls'"
                  class="Tree-controls"
                >
                  <template v-if="pendingFacetId">
                    <div
                      v-if="isNotAllowedToBeNewParent(nodeId)"
                      data-test-move-not-allowed
                    >
                      {{ $t('messages.notAllowed') }}
                    </div>
                    <div v-else>
                      <button
                        data-test-move-facet
                        class="button button--primary button--sm"
                        @click.stop="openMoveConfirmationModal(nodeId)"
                      >
                        {{ $t('actions.move') }}
                      </button>
                    </div>
                  </template>
                  <router-link
                    v-else
                    :to="{ name: 'new-facet', params: { parentId: nodeId } }"
                    class="button button--icon button--round button--primary"
                  >
                    <SvgIcon name="24-ic-add" />
                  </router-link>
                </div>
              </template>
            </TreeOfFacets>
          </template>

          <template #column>
            <FacetView
              v-if="displayedId"
              :record-id="displayedId"
            >
              <template
                v-if="$can('manage', 'taxonomy')"
                #controls
              >
                <router-link
                  :to="{ name: 'edit-facet', params: { facetId: displayedId } }"
                  class="button button--icon button--roundBig button--primary"
                >
                  <SvgIcon name="24-ic-edit" />
                </router-link>
              </template>
              <template
                v-if="$can('manage', 'taxonomy')"
                #actions
              >
                <div class="flex mt-4 flex-between">
                  <DeleteWithPrompt
                    delete-button-class="button--primary"
                    class="mr-2"
                    :disabled="deleteValidationStatus === 'LOADING'"
                    @message="validateDelete(displayedId)"
                    @confirm="deleteFacet"
                    @cancel="clearDeleteValidationMessages"
                    ref="delete"
                  >
                    <div
                      v-if="deleteValidationStatus === 'LOADING'"
                      class="flex-center p-2"
                    >
                      <Loading-Spinner class="spinner--lg mt-4" />
                    </div>
                    <Validation-results
                      ref="validation-results"
                      :server-errors="deleteValidationErrors"
                      :server-warnings="deleteValidationWarnings"
                      class="mb-4"
                      data-test-delete-validation
                    />

                    <p class="mb-4 font-bold">
                      {{
                        $t('actions.deleteFacet', getTotalCount(displayedId), {
                          count: getTotalCount(displayedId),
                        })
                      }}
                    </p>
                  </DeleteWithPrompt>
                  <div v-if="pendingFacetId !== displayedId && deleteValidationStatus === 'IDLE'">
                    <button
                      data-test-move-facet-button
                      class="button button--primary"
                      @click="pendingFacetId = displayedId"
                    >
                      {{ $t('actions.moveFacet') }}
                    </button>
                  </div>
                </div>
              </template>
            </FacetView>
          </template>
        </TreeBox>
      </div>
    </div>
  </LayoutDefault>
</template>

<script>
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import TreeBox from '@/components/TreeBox/TreeBox.vue'
import FacetView from '@/components/FacetView/FacetView.vue'
import TreeOfFacets from '@/components/TreeOfFacets.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import Modal from '@/components/Modal.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import TabsLvl1Links from '@/components/Tabs/TabsLvl1Links.vue'
import { useValidation } from '@/composables/useValidation'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    LayoutDefault,
    Hero,
    TreeBox,
    FacetView,
    TreeOfFacets,
    DeleteWithPrompt,
    ValidationResults,
    Modal,
    TabsLvl1Links,
  },
  setup: () => {
    const store = useStore()
    const { t } = useI18n()
    const formatErrorMessage = (error) => {
      if (error.source === 'implicitFacets') {
        let relations = error.detail
          .map((id) => {
            let term = store.getters['taxonomy/terms/byId'](id)
            return `(${term.code}) ${term.displayName || term.termName}`
          })
          .join('<br />')
        return t(`validation.${error.key}`, { terms: relations })
      }
      return error.message
    }

    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation({
        formatErrorMessage,
        errorStructure: ['GENERAL', 'implicitFacets'],
      })
    return {
      toast: useToast(),
      deleteValidationErrors: serverErrors,
      deleteValidationWarnings: serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },

  data() {
    return {
      displayedId: null,
      deleteValidationStatus: 'IDLE',
      isMoving: false,
      pendingFacetId: null,
      pendingNewParentId: null,
    }
  },

  computed: {
    ...mapGetters('taxonomy/facets', ['byId', 'allFacetChildIds', 'rootFacetById']),
  },
  methods: {
    displayRecord(id) {
      // dont display roots
      if (this.byId(id).parentId !== null) {
        this.displayedId = id
      }
    },
    isNotAllowedToBeNewParent(id) {
      const isSameNode = this.pendingFacetId === id
      // A node cannot be moved to a node that is one of its children
      const isChildOfPendingNode = this.allFacetChildIds(this.pendingFacetId).indexOf(id) > -1
      const hasTheSameRootNode =
        this.rootFacetById(id).id === this.rootFacetById(this.pendingFacetId).id
      const isAlreadyParent = id === this.byId(this.pendingFacetId).parentId

      return isSameNode || isChildOfPendingNode || isAlreadyParent || !hasTheSameRootNode
    },
    getTotalCount(id) {
      return this.allFacetChildIds(id).length
    },
    async moveFacet(callback) {
      this.isMoving = true

      try {
        await this.$store.dispatch('taxonomy/facets/moveFacets', {
          facetId: this.pendingFacetId,
          newParentId: this.pendingNewParentId,
        })

        this.toast.success(this.$t('toast.success.generic'))
      } catch (error) {
        this.toast.error(this.$t('toast.error.saveData'))
      } finally {
        callback()
        this.isMoving = false
        this.pendingFacetId = null
        this.pendingNewParentId = null
      }
    },
    openMoveConfirmationModal(id) {
      this.pendingNewParentId = id
      this.$refs.moveConfirmModal.open()
    },
    validateDelete(id) {
      this.clearDeleteValidationMessages()
      this.deleteValidationStatus = 'LOADING'
      this.$store
        .dispatch('taxonomy/facets/validateFacetDelete', id)
        .then(() => {
          this.deleteValidationStatus = 'LOADED'
        })
        .catch((error) => {
          if (error.status === 422) {
            this.processDeleteValidationErrors(error)
          }
        })
    },
    processDeleteValidationErrors(errors) {
      this.$store
        .dispatch('taxonomy/terms/checkHash')
        .then((upToDate) => {
          if (!upToDate) {
            return this.$store.dispatch('taxonomy/terms/getTree')
          }
        })
        .then(() => {
          this.deleteValidationStatus = 'ERROR'
          this.handleValidationErrorsInResponse(errors)
          this.$nextTick(() => {
            this.$refs['validation-results'].$el.focus()
          })
        })
    },
    clearDeleteValidationMessages() {
      this.deleteValidationStatus = 'IDLE'
      this.clearServerMessages()
    },
    async deleteFacet() {
      const id = this.displayedId
      try {
        await this.$store.dispatch('taxonomy/facets/deleteFacet', id)

        this.displayedId = null

        this.$store.dispatch('taxonomy/facets/removeFromRecordsAndTreeStructure', id)

        this.toast.success(this.$t('toast.success.generic'))
      } catch (error) {
        this.toast.error(this.$t('toast.error.saveData'))
      } finally {
        this.clearDeleteValidationMessages()
        this.$refs.delete.finishProcessing()
      }
    },
  },
}
</script>

<style lang="scss">
.facetToBeMoved {
  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  background: theme('colors.lemon.100');
}
</style>
