<template>
  <div class="WL-noResults">
    <div class="text-center text-acai py-8">
      <div class="h2">{{ $t('messages.noTransactions') }}</div>
      <div class="h2">{{ $t('messages.differentFilter') }}</div>
      <SvgIcon
        class="icon--illustration my-8 mx-auto"
        :name="svgId"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoWasteResults',
  props: {
    svgId: {
      type: String,
      default: 'no-results',
    },
  },
  data() {
    return {}
  },
}
</script>
