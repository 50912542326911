<template>
  <div
    class="org-navigator-column"
    data-hierarchy-level-column
  >
    <div
      v-if="loadingStatus === 'LOADING'"
      class="org-navigator-column-spinner"
    >
      <Loading-Spinner class="spinner--sm" />
    </div>
    <template v-else>
      <div
        :id="`level-${level.id}-title`"
        class="org-navigator-column-header"
      >
        <slot name="title">
          <div data-hierarchy-level-title>
            <span
              v-if="!isWinnow"
              class="org-navigator-column-title-number"
            >
              {{ `${level.id}: ` }}
            </span>
            <span class="org-navigator-column-title">
              {{ level.name }}
            </span>
          </div>
          <button
            v-if="isAbleToCreateNode"
            type="button"
            class="button button--icon org-navigator-column-create-btn p-0"
            @click="handleCreate"
          >
            <span class="sr-only">{{ $t('actions.createNode') }}</span>
            <SvgIcon
              name="24-ic-add"
              sm
            />
          </button>
        </slot>
      </div>
      <slot name="top" />
      <div
        ref="column"
        class="org-navigator-column-list"
        @keydown="quickSearch"
      >
        <ul
          :id="`level-${level.id}`"
          role="group"
          @keydown.stop.prevent.up="goToPreviousNode"
          @keydown.stop.prevent.down="goToNextNode"
        >
          <slot
            :nodes="nodes"
            :next-level="nextLevel"
            :next-level-id="nextLevelId"
            :highlighted-node-id="highlightedNodeId"
            :parent-id="parentId"
            :has-more="hasMore"
          >
            <Organisation-navigator-node
              v-for="node in nodes"
              :id="node.id"
              :key="node.id"
              ref="node"
              :is-selected="node.id === selectedNodeId"
              :is-highlighted="node.id === highlightedNodeId"
              :is-archived="node.archived"
              :allow-create="isAbleToCreateNode"
              :tabindex="node.id === focusedNodeId ? 0 : -1"
              role="treeitem"
              v-bind="{
                'aria-selected': node.id === selectedNodeId,
                'aria-describedby': `level-${level.id}-title`,
                'aria-expanded': node.id === selectedNodeId,
                'aria-owns': selectedNodeId && `level-${node.level}`,
              }"
              @node-focus="$emit('node-focus', $event)"
              @node-select="$emit('node-select', $event)"
            />
            <Organisation-navigator-node
              v-if="hasMore"
              v-bind="{
                'aria-expanded': false,
                'aria-owns': selectedNodeId && `level-${nextLevel}`,
              }"
              ref="node-next"
              role="treeitem"
              :is-selected="selectedNodeId === nextLevelId"
              :is-highlighted="highlightedNodeId === nextLevelId"
              :tabindex="focusedNodeId === nextLevelId ? 0 : -1"
              @node-focus="$emit('node-focus', nextLevelId)"
            >
              <div :class="['font-bold']">
                {{ $t('hierarchy.fakeNode.title', { level: nextLevel }) }}
              </div>
              <div class="text-sm">{{ $t('hierarchy.fakeNode.subtitle') }}</div>
            </Organisation-navigator-node>

            <!-- Create node -->
          </slot>
        </ul>
        <template v-if="isAbleToCreateNode">
          <OrgCreate
            v-if="isCreatingNode"
            ref="createNode"
            :parent-id="parentId"
            :level="levelNumber"
            class="p-1"
            @created="onCreateSuccess"
            @close="onCreateClose"
          />
          <div v-else>
            <button
              ref="create"
              data-hierarchy-level-column-create-btn
              type="button"
              class="p-1 button button--link"
              tabindex="-1"
              :aria-describedby="`level-${level.id}-title`"
              @click="handleCreate"
              @keydown.stop.prevent.up="goToPreviousNode"
            >
              {{ $t('actions.createNode') }}
            </button>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import OrganisationNavigatorNode from './OrgNavigatorNode.vue'
import OrgCreate from '@/components/OrgBrowser/OrgCreate.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    OrganisationNavigatorNode,
    OrgCreate,
  },
  props: {
    selectedNodeId: [String, Number],
    highlightedNodeId: [String, Number],
    focusedNodeId: [String, Number],
    parentId: [String, Number],
    customSelectedNode: Object,
    allowCreate: Boolean,
    levelNumber: Number,
    nextLevel: Number,
    showArchived: {
      type: Boolean,
      default: false,
    },
    disabledNodes: {
      type: Array,
      // ['NODES', 'MORE', ...numbers] // not fully implemented yet
      default() {
        return []
      },
    },
    hasMore: Boolean, // this means the parent node has children on multiple levels
  },
  emits: ['node-select', 'node-focus'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      focusedIndex: 0,
      loadingStatus: 'IDLE',
      isCreatingNode: false,
      quickSearchKeys: '',
      quickSearchTimeout: null,
    }
  },
  computed: {
    isWinnow() {
      return this.levelNumber === 0
    },
    nextLevelId() {
      return `${this.parentId}-${this.levelNumber}`
    },
    levels() {
      return this.$store.getters['hierarchy/nodes/levelsList']
    },
    level() {
      return this.levels[this.levelNumber]
    },
    highlighted() {
      return this.$store.getters['hierarchy/nodes/byId'](this.highlightedNodeId)
    },
    createEnabled() {
      return this.indexLandmarks.create !== null
    },
    moreEnabled() {
      return this.indexLandmarks.next !== null && !this.disabledNodes.includes('MORE')
    },
    nodesEnabled() {
      return !this.disabledNodes.includes('NODES')
    },
    nodes() {
      let nodes = []
      if (this.parentId) {
        nodes = this.$store.getters['hierarchy/nodes/getChildrenNodes'](this.parentId)
          .filter((node) => (this.showArchived ? true : !node.archived))
          .filter((node) => node.level === this.levelNumber)
          .sort((a, b) => a.name.localeCompare(b.name))
      } else if (this.highlighted) {
        nodes = [this.highlighted]
      }

      return nodes
    },
    indexLandmarks() {
      let max = -1
      let nodes =
        this.nodes.length && !this.disabledNodes.includes('NODES')
          ? (max += this.nodes.length)
          : null
      let next = this.hasMore && !this.disabledNodes.includes('MORE') ? (max += 1) : null
      let create = this.isAbleToCreateNode ? (max += 1) : null
      return {
        nodes,
        next,
        create,
        max,
      }
    },
    maxIndex() {
      return this.indexLandmarks.max
    },
    isAbleToCreateNode() {
      return this.allowCreate && this.levelNumber > 1
    },
    firstChars() {
      return this.nodes.map((node) => node.name.substring(0, 1).toLowerCase())
    },
  },
  watch: {
    parentId: {
      handler(parentId) {
        if (!this.nodes.length && parentId) {
          this.getTree(this.parentId)
        } else {
          this.loadingStatus = 'LOADED'
        }
      },
      immediate: true,
    },
    highlightedNodeId(id) {
      if (id && id.split('-').length == 1) {
        let index = this.findIdIndex(id)

        this.focusedIndex = index > -1 ? index : 0
      }
    },
  },
  created() {
    let index = this.findIdIndex(this.highlightedNodeId)
    this.focusedIndex = index > -1 ? index : 0
  },
  methods: {
    getTree(id) {
      this.loadingStatus = 'LOADING'
      return this.$store
        .dispatch(`hierarchy/nodes/getTree`, id)
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch((e) => {
          this.toast.error(this.$t('toast.error.getData'))
          this.loadingStatus = 'ERROR'
          throw e
        })
    },
    findIdIndex(id) {
      return this.nodes.findIndex((node) => node.id === id)
    },
    focus() {
      let index = 0

      if (this.highlightedNodeId) {
        let split = this.highlightedNodeId.split('-')
        if (split.length > 1) {
          index = this.findIdIndex(split[0])
        } else {
          index = this.findIdIndex(this.highlightedNodeId)
        }
        if (index < 0) {
          index = this.indexLandmarks.next
        }
      }

      this.changeFocusedNode(index)
    },
    goToPreviousNode() {
      let index = this.focusedIndex
      index = index - 1 >= 0 ? index - 1 : index
      this.changeFocusedNode(index)
    },
    goToNextNode() {
      let index = this.focusedIndex
      index = index + 1 <= this.maxIndex ? index + 1 : index
      this.changeFocusedNode(index)
    },
    goToFirstNode() {
      let index = 0
      this.changeFocusedNode(index)
    },
    goToLastNode() {
      let index = this.maxIndex
      this.changeFocusedNode(index)
    },
    changeFocusedNode(index) {
      this.focusedIndex = index
      if (this.createEnabled && index === this.indexLandmarks.create) {
        if (this.$refs['create']) {
          this.$refs['create'].focus()
        }
      } else if (this.moreEnabled && index === this.indexLandmarks.next) {
        this.$emit('node-focus', this.nextLevelId)
        if (this.$refs['node-next']) {
          this.$refs['node-next'].$el.focus()
        }
      } else if (this.nodesEnabled) {
        this.$emit('node-focus', this.nodes[index].id)
        if (this.$refs['node']) {
          this.$refs['node'][index].$el.focus()
        }
      }
    },
    handleCreate() {
      this.isCreatingNode = true
      const $column = this.$refs.column
      this.$nextTick(() => {
        $column.scroll({
          top: $column.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
    onCreateSuccess({ nodeId }) {
      this.$emit('node-focus', nodeId)
      this.$emit('node-select', nodeId)
      this.isCreatingNode = false
    },
    onCreateClose() {
      this.isCreatingNode = false
      this.$nextTick(() => {
        this.changeFocusedNode(this.indexLandmarks.create)
      })
    },
    quickSearch(ev) {
      if (ev.key.length) {
        this.setFocusByFirstCharacter(ev.key)
      }
    },
    setFocusByFirstCharacter(char) {
      let start, index
      char = char.toLowerCase()

      // Get start index for search based on position of currentItem
      start = this.focusedIndex + 1
      if (start === this.nodes.length) {
        start = 0
      }

      // Check remaining slots in the menu
      index = this.getIndexFirstChars(start, char)

      // If not found in remaining slots, check from beginning
      if (index === -1) {
        index = this.getIndexFirstChars(0, char)
      }

      // If match was found...
      if (index > -1) {
        this.changeFocusedNode(index)
      }
    },

    getIndexFirstChars(startIndex, char) {
      for (var i = startIndex; i < this.firstChars.length; i++) {
        if (char === this.firstChars[i]) {
          return i
        }
      }
      return -1
    },
  },
}
</script>

<style lang="scss">
$column-width: to-rem(180px);

.org-navigator-column {
  flex: 1 1 $column-width;
  min-width: $column-width;
  max-width: $column-width;
  display: flex;
  flex-direction: column;
  border-right: to-rem(1px) solid theme('colors.slate.DEFAULT');
  background: theme('colors.white');
  display: flex;
  justify-content: stretch;
  height: 100%;
  scroll-snap-align: end;

  &:focus-within {
    position: relative;
    z-index: 1;
  }
}

.org-navigator-column-spinner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 100%;
  padding: 3rem;
}

.org-navigator-column-header {
  background-color: theme('colors.grey.100');
  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  padding: theme('spacing.1');
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
}

.org-navigator-column-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: theme('fontWeight.bold');
}

.org-navigator-column-create-btn {
  display: none;
}

.org-navigator-column-title-number {
  display: none;
}

.org-navigator-column-header:hover {
  background-color: theme('colors.acai.200');
  color: theme('colors.grey.400');

  .org-navigator-column-title-number {
    display: inline;
  }

  .org-navigator-column-create-btn {
    display: flex;
  }

  .org-navigator-column-title {
    font-weight: theme('fontWeight.normal');
  }
}

.org-navigator-column-list {
  flex: 0 1 auto;
  overflow-y: auto;
  overflow-x: visible;
  height: auto;
  z-index: 1;
  position: relative;
}
</style>
