export default function (collection) {
  return {
    id: collection.id,
    name: collection.name,
    organisationId: Array.isArray(collection.organisationIds)
      ? collection.organisationIds[0]
      : collection.organisation?.id,
    numberOfActiveSites: collection.numberOfActiveSites,
    totalNumberOfSites: collection.totalNumberOfSites,
    archived: collection.archived,
    createdDate: collection.createdDate,
    siteIds: collection.siteIds,
  }
}
