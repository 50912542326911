<template>
  <div
    v-click-outside="handleClickOutside"
    tabindex="0"
    :class="[
      'card',
      {
        'is-today': isToday,
        'is-invalid': serverErrors.GENERAL.length,
      },
    ]"
    @keyup.esc="resetValues"
    @keydown.self.space.prevent.stop
  >
    <CardHeader :day="day" />
    <div class="card-body">
      <form
        v-test="'form'"
        class="CS-form"
        novalidate
        @submit.prevent="save()"
      >
        <div>
          <ValidationGroup
            v-slot="{ allErrors, hasAnyErrors, allWarnings, hasWarnings }"
            :validator="v$.buffer.covers"
            :server-errors="serverErrors.covers"
            :server-warnings="serverWarnings.covers"
            :custom="true"
            :tracking-data="{
              action: 'Edit covers validation error',
              data: {
                date: day.id,
                value: v$.buffer.covers.$model,
              },
            }"
          >
            <label>
              <!-- <svg class="icon icon--sm"><use xlink:href="#person-covers"></use></svg> -->
              <input
                ref="coversInput"
                v-model.trim="v$.buffer.covers.$model"
                v-test="'covers'"
                v-tooltip="{
                  theme: hasAnyErrors ? 'tooltip-danger' : hasWarnings ? 'tooltip-warning' : null,
                  content:
                    (hasAnyErrors && $t(`validation.${allErrors[0].key}`)) ||
                    (hasWarnings && $t(`CS.warnings.covers.${allWarnings[0].key}`)),
                  shown: (hasAnyErrors && focused.covers) || hasWarnings,
                }"
                type="number"
                :class="['formControl', { 'is-invalid': hasAnyErrors }]"
                :placeholder="$t('titles.covers')"
                @focus="focused.covers = true"
                @blur="focused.covers = false"
                @input="clearServerMessages('covers'), clearServerMessages('GENERAL')"
              />
            </label>
          </ValidationGroup>
          <ValidationGroup
            v-slot="{ allErrors, hasAnyErrors, allWarnings, hasWarnings }"
            :validator="v$.buffer.sales"
            :server-errors="serverErrors.sales"
            :server-warnings="serverWarnings.sales"
            class="mt-3"
            :custom="true"
            :tracking-data="{
              action: 'Edit sales validation error',
              data: {
                date: day.id,
                value: v$.buffer.sales.$model,
              },
            }"
          >
            <label>
              <input
                v-model.trim="v$.buffer.sales.$model"
                v-test="'sales'"
                v-tooltip="{
                  theme: hasAnyErrors ? 'tooltip-danger' : hasWarnings ? 'tooltip-warning' : null,
                  content:
                    (hasAnyErrors && $t(`validation.${allErrors[0].key}`)) ||
                    (hasWarnings && $t(`CS.warnings.sales.${allWarnings[0].key}`)),
                  shown: (hasAnyErrors && focused.covers) || hasWarnings,
                  triggers: [],
                }"
                type="number"
                step="any"
                :class="['formControl', { 'is-invalid': hasAnyErrors }]"
                :placeholder="$t('titles.sales')"
                @focus="focused.sales = true"
                @blur="focused.sales = false"
                @input="clearServerMessages('sales'), clearServerMessages('GENERAL')"
              />
              <i class="textIcon">{{ currency }}</i>
            </label>
          </ValidationGroup>
        </div>

        <div class="CS-form-actions my-2">
          <Loading-Spinner v-if="isSaving" />
          <template v-else>
            <button
              v-test="'set-active-false'"
              v-tooltip="{
                content: $t('CS.actions.setInactive'),
              }"
              class="button button--icon button--round button--primary"
              type="button"
              @click.stop="setInactive(), save(true)"
            >
              <SvgIcon name="24-ic-lock" />
            </button>

            <!--
              Show the save button if there are changes or if there are warnings.
              If there are warnings, the save button is only a target for dismissing that warning
              by faking a confirmation
            -->
            <template v-if="hasBufferedChanges || hasWarnings">
              <!-- <button class="button buttonicon" @click.prevent="reset()">
                      <svg class="icon icon--sm"><use xlink:href="#delete-circled"></use></svg>
                    </button> -->
              <button
                v-test="'save'"
                v-tooltip="{
                  content: $t('actions.save'),
                }"
                class="button button--icon button--round button--primary"
                type="submit"
              >
                <SvgIcon name="24-ic-check" />
              </button>
            </template>
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { helpers, integer } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import CardHeader from './CardHeader.vue'

const isBlank = (val) => {
  return /\S/.test(val) === false || val === null
}

const transform = (model) => {
  return {
    ...model,
    covers: isBlank(model.covers) ? null : parseInt(model.covers, 10),
    sales: isBlank(model.sales) ? null : parseFloat(model.sales, 10),
  }
}

const positive = (value) => (!isBlank(value) ? value > 0 : true)
const customDecimal = helpers.regex(/^[-]?\d*\.?\d*$/)

export default {
  components: {
    CardHeader,
    ValidationGroup,
  },
  props: {
    day: {
      type: Object,
    },
    item: {
      type: Object,
    },
    currency: {
      type: String,
    },
    serverErrors: {
      type: Object,
    },
    serverWarnings: {
      type: Object,
    },
    isSaving: {
      type: Boolean,
    },
  },
  emits: ['save', 'clearMessages', 'close'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      buffer: { ...this.item },
      focused: {
        covers: false,
        sales: false,
      },
    }
  },

  computed: {
    isToday() {
      return this.day.isToday
    },

    hasWarnings() {
      return Object.values(this.serverWarnings).find((f) => !!f.length)
    },

    hasBufferedChanges() {
      return !isEqual(transform(this.buffer), this.item)
    },
  },

  watch: {
    item: {
      handler: function () {
        this.buffer = { ...this.item }
      },
      immediate: true,
    },
  },

  validations() {
    return {
      buffer: {
        covers: {
          integer,
          positive,
        },
        sales: {
          decimal: customDecimal,
          positive,
        },
      },
    }
  },
  methods: {
    setInactive() {
      // reset any edits
      this.buffer = { ...this.item }
      this.buffer.active = false

      this.analytics.track('Activation - Set inactive day', {
        id: this.buffer.id,
        date: this.buffer.date,
      })
    },

    save(skipValidation = false) {
      // if there are warnings, dismiss them without actually saving the data.
      if (this.hasWarnings) {
        this.clearServerMessages()
        return
      }

      if (!this.hasBufferedChanges) {
        return
      }

      if (!skipValidation) {
        this.v$.buffer.$touch()

        if (this.v$.$invalid) {
          return
        }
      }

      this.$emit('save', { data: transform(this.buffer), wasDirty: this.v$.buffer.$dirty })
    },
    clearServerMessages(key = null) {
      this.$emit('clearMessages', key)
    },

    handleClickOutside() {
      if (!window.getSelection().toString()) {
        this.resetValues()
      }
    },

    resetValues() {
      this.buffer = { ...this.item }
      this.$emit('close')
    },
  },
}
</script>
