<template>
  <page-section>
    <div class="editable-row-content-section">
      <router-link
        :to="returnPath || backupRoute"
        data-test-return
      >
        <SvgIcon
          class="inline"
          name="24-ic-arrow-prev"
        />
        {{ $t('actions.return') }}
      </router-link>
    </div>
    <food-item-edit-form
      :menu-id="menuId"
      :defaults="defaultsFromQueryParams"
      data-test-editor
      @save="onSave"
      @cancel="onCancel"
      @delete="onCancel"
    >
      <template #actions="{ anyDirty }">
        <!-- Create and cancel -->
        <div class="buttonGroup">
          <button
            type="submit"
            class="button button--primary"
            data-test-create
            :disabled="saveDisabled || !anyDirty"
          >
            <SvgIcon name="24-ic-check" />
            <span>{{ $t('actions.create') }}</span>
          </button>
          <button
            type="reset"
            class="button button--secondary"
            data-test-cancel
            :disabled="saveDisabled || !anyDirty"
          >
            {{ $t('actions.cancel') }}
          </button>
        </div>
      </template>
    </food-item-edit-form>
  </page-section>
</template>

<script>
import FoodItemEditForm from '@/pages/Menus/FoodItems/FoodItemEditForm.vue'
import returnPathMixin from '@/mixins/returnPathMixin'
import defaultsFromQueryParamsMixin from '@/mixins/defaultsFromQueryParamsMixin'
import { useToast } from 'vue-toastification'
export default {
  components: { FoodItemEditForm },
  mixins: [returnPathMixin, defaultsFromQueryParamsMixin],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      menuId: this.$route.query.menuId,
      saveDisabled: false,
      backupRoute: {
        name: 'food-items',
        params: {
          menuId: this.$route.query.menuId,
        },
      },
    }
  },
  methods: {
    onSave({ menuId, data, onError }) {
      this.saveDisabled = true
      this.$store
        .dispatch('menu-management/food-items/saveNewItem', { menuId, data })
        .then((foodItem) => {
          this.toast.success(this.$t('menus.foodItem.messages.createSuccessful'))
          this.$router.push({
            name: 'food-mapping',
            query: this.$route.query,
            params: { foodItemId: foodItem.id },
          })
        })
        .catch((errorObj) => {
          onError(errorObj)
        })
        .finally(() => {
          this.saveDisabled = false
        })
    },
    onCancel() {
      this.goBack({
        backupRoute: this.backupRoute,
      })
    },
  },
}
</script>

<style></style>
