<template>
  <div class="box UI">
    <h1>Collapsible edit areas</h1>
    <hr />
    <div class="mb-4">
      <button
        type="button"
        class="button button--primary"
        @click="firstSectionOpen = !firstSectionOpen"
      >
        Toggle
      </button>
    </div>
    <collapsible-content :is-open="firstSectionOpen">
      <h2>Such top level</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil nisi blanditiis
        exercitationem unde, impedit aspernatur deleniti est? Maiores laudantium dignissimos
        quisquam! Tempore autem nulla numquam necessitatibus, rem animi voluptate eligendi.
      </p>
      <div class="my-4">
        <button
          type="button"
          class="button button--primary"
          @click="secondSectionOpen = !secondSectionOpen"
        >
          Toggle
        </button>
      </div>
      <collapsible-content :is-open="secondSectionOpen">
        <h3>Many lower</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil nisi blanditiis
          exercitationem unde, impedit aspernatur deleniti est? Maiores laudantium dignissimos
          quisquam! Tempore autem nulla numquam necessitatibus, rem animi voluptate eligendi.
        </p>
      </collapsible-content>
    </collapsible-content>
  </div>
</template>

<script>
import CollapsibleContent from '@/components/CollapsibleContent.vue'
export default {
  components: {
    CollapsibleContent,
  },
  data() {
    return {
      firstSectionOpen: false,
      secondSectionOpen: false,
    }
  },
}
</script>

<style></style>
