<template>
  <div>
    <p class="font-bold">Filter by individual actions</p>
    <Filters-group
      :filters="filters"
      :selected-id="selectedId"
      v-bind="$attrs"
      behaviour="radio"
    />
  </div>
</template>

<script>
import FiltersGroup from '@/components/FiltersGroup.vue'
export default {
  components: {
    FiltersGroup,
  },
  props: {
    counters: Object,
    selectedId: String,
  },
  computed: {
    filters() {
      return [
        {
          id: 'ALL',
          title: 'All',
          icon: '24-ic-list',
          items: this.counters.foodItems,
        },
        {
          id: 'NEW',
          title: 'Added',
          icon: '24-ic-add',
          items: this.counters.foodItemsAdded,
        },
        {
          id: 'MODIFIED',
          title: 'Edited',
          icon: '24-ic-edit',
          items: this.counters.foodItemsModified,
        },
        {
          id: 'DELETE',
          title: 'Deleted',
          icon: 'waste-log',
          items: this.counters.foodItemsDeleted,
        },
        {
          id: 'IGNORED',
          title: 'Ignored',
          icon: '24-ic-lock',
          items: this.counters.foodItemsIgnored,
        },
      ]
    },
  },
}
</script>

<style></style>
