<template>
  <collapsible-section
    :tag="tag"
    :state="isOpen"
    @open="$emit('group-open')"
    @close="$emit('group-close')"
  >
    <template #header>
      {{ mapping }}
    </template>
    <table-responsive v-test="'group-content'">
      <thead-generator
        :headers="headers"
        :all-selected="allSelected"
        @select-all="onSelectAll"
      />
      <tbody v-test="'results'">
        <template
          v-for="id in foodItemIds"
          :key="id"
        >
          <!-- Normal row data -->
          <menu-duplicate-row
            :food-item-id="id"
            :menu-id="menuId"
            :selected="foodItemsState[id].isSelected"
            :readonly="readonly"
            @select="onSelect(id, $event)"
          />
        </template>
      </tbody>
    </table-responsive>

    <div
      role="alert"
      aria-live="polite"
    >
      <p
        v-if="selectedFoodItems.length === 1"
        class="p-4"
      >
        <SvgIcon
          class="text-blueberry mr-2 inline"
          name="24-ic-info"
        />
        {{ $t('menus.duplicateMappings.messages.selectOneMore') }}
      </p>
      <p
        v-if="selectedFoodItems.length > 1"
        class="p-4"
      >
        <SvgIcon
          class="text-carrot mr-2 inline"
          name="24-ic-info"
        />
        {{
          $t('menus.duplicateMappings.messages.itemsWillBeReplaced', {
            items: selectedFoodItemNames,
          })
        }}
      </p>
    </div>
    <collapsible-content :is-open="selectedRows.length">
      <food-item-edit-form
        v-if="selectedRows.length"
        ref="editor"
        v-test="'details'"
        :menu-id="menuId"
        :defaults="defaults"
        :disabled="selectedRows.length <= 1"
        data-test-editor
        @save="onMerge"
      >
        <template #actions>
          <div class="buttonGroup">
            <button
              :disabled="selectedRows.length <= 1"
              class="button button--primary"
              type="submit"
              data-test-submit-merge
            >
              <SvgIcon
                class="iconCircle--sm"
                name="24-ic-check"
              />
              <span>{{ $t('menus.duplicateMappings.actions.mergeSelected') }}</span>
            </button>
            <button
              :disabled="selectedRows.length <= 1"
              class="button button--secondary"
              type="reset"
              data-test-merge-reset
            >
              {{ $t('actions.reset') }}
            </button>
          </div>
        </template>
      </food-item-edit-form>
    </collapsible-content>
    <div
      v-if="!selectedRows.length"
      class="buttonGroup p-4"
    >
      <button
        v-if="!readonly"
        class="button button--secondary"
        type="button"
        @click="onSelectAll(true)"
        @save="onMerge"
      >
        <SvgIcon
          class="iconCircle iconCircle--primary iconCircle--sm"
          name="24-ic-check"
        />
        <span>{{ $t('menus.duplicateMappings.actions.mergeAll') }}</span>
      </button>
    </div>
  </collapsible-section>
</template>

<script>
import { useToast } from 'vue-toastification'
import TheadGenerator from '@/components/TheadGenerator/TheadGenerator.vue'
import TableResponsive from '@/components/TableResponsive/TableResponsive.vue'
import CollapsibleSection from '@/components/CollapsibleSection.vue'
import CollapsibleContent from '@/components/CollapsibleContent.vue'
import MenuDuplicateRow from './MenuDuplicateRow.vue'
import FoodItemEditForm from '@/pages/Menus/FoodItems/FoodItemEditForm.vue'
import { mapGetters } from 'vuex'
import ToastMessage from '@/components/ToastMessage.vue'
export default {
  components: {
    TheadGenerator,
    TableResponsive,
    MenuDuplicateRow,
    FoodItemEditForm,
    CollapsibleSection,
    CollapsibleContent,
  },
  props: ['code', 'mapping', 'foodItemIds', 'tag', 'isOpen', 'menuId', 'readonly'],
  emits: ['group-open', 'group-close', 'merge'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      isMerging: false,
      allSelected: false,
      foodItemsState: {},
    }
  },
  computed: {
    weightPreference() {
      return this.$store.getters['auth/weightUnit']
    },
    headers() {
      let headers = [
        { id: 'thName', title: this.$t('menus.foodItems.headers.name') },
        { id: 'thUpdated', title: this.$t('menus.foodItems.headers.updatedDate') },
        { id: 'thStatus', title: this.$t('menus.foodItems.headers.status') },
        {
          id: 'thCost',
          title: this.$t('menus.foodItems.headers.cost', {
            unit: this.$t(`settings.weight.short.${this.weightPreference}`),
          }),
          class: 'text-right',
        },
        { id: 'thPortion', title: this.$t('menus.foodItems.headers.portion'), class: 'text-right' },
      ]
      if (!this.readonly) {
        headers.unshift({
          id: 'thSelectAll',
          title: this.$t('actions.selectAllRows'),
          selectAll: true,
        })
        headers.push({ id: 'thActions', title: this.$t('actions.header') })
      }
      return headers
    },
    ...mapGetters('menu-management/food-items', ['foodItemById']),
    selectedRows() {
      const states = Object.keys(this.foodItemsState)
      return states.filter((id) => this.foodItemsState[id].isSelected)
    },
    selectedFoodItems() {
      return this.selectedRows
        .map((id) => this.foodItemById(id))
        .sort((a, b) => {
          return new Date(b.updatedDate) - new Date(a.updatedDate)
        })
    },
    selectedFoodItemNames() {
      return this.selectedFoodItems.map((item) => item.name).join(', ')
    },
    averageCost() {
      let itemsWithCost = this.selectedFoodItems.filter((item) => item.cost)
      let cost = itemsWithCost.reduce((avg, item, index, arr) => {
        avg += parseFloat(item.cost)
        if (index === arr.length - 1) {
          return avg / arr.length
        } else {
          return avg
        }
      }, 0)

      return cost > 0 ? cost.toFixed(2) : null
    },
    averageWeight() {
      let itemsWithWeight = this.selectedFoodItems.filter((item) => item.portion.value)
      let weight = itemsWithWeight.reduce((avg, item, index, arr) => {
        avg += parseFloat(item.portion.value)
        if (index === arr.length - 1) {
          return avg / arr.length
        } else {
          return avg
        }
      }, 0)
      return weight > 0 ? weight.toFixed(2) : null
    },
    defaults() {
      let firstItem = this.selectedFoodItems[0]

      let foodGroupsMap = this.selectedFoodItems
        .map((item) => {
          return item.foodGroups
        })
        .concat()
        .flat()
        .reduce((map, group) => {
          if (group && group.id) {
            map[group.id] = group
          }
          return map
        }, {})

      return {
        name: firstItem.name,
        nameEnglish: firstItem.nameEnglish,
        cost: this.averageCost || null,
        portion: { value: this.averageWeight || null },
        foodGroups: Object.values(foodGroupsMap),
        predictionEnabled: firstItem.predictionEnabled,
        annotationEnabled: firstItem.annotationEnabled,
        hiddenFromTrackers: firstItem.hiddenFromTrackers,
        taxonomy: {
          code: firstItem.taxonomy.code,
          mapping: firstItem.taxonomy.mapping,
        },
      }
    },
  },
  watch: {
    foodItemIds: {
      handler(ids) {
        this.foodItemsState = ids.reduce((hash, id) => {
          hash[id] = {}
          hash[id].isSelected = false
          return hash
        }, {})
      },
      immediate: true,
    },
  },
  methods: {
    onMerge(event) {
      this.$store
        .dispatch('menu-management/duplicate-mappings/mergeDuplicateMappings', {
          menuId: this.menuId,
          newFoodItem: { ...event.data, taxonomy: { code: this.code, mapping: this.mapping } },
          duplicates: this.selectedRows,
        })
        .then((newFoodItem) => {
          this.$emit('merge')
          this.showSuccess(newFoodItem)
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
        .finally(() => {
          this.$store.dispatch('menu-management/menus/checkMenuIssues', { menuId: this.menuId })
        })
    },
    showSuccess(newFoodItem) {
      let successMessage = this.$t('menus.duplicateMappings.messages.mergeSuccess', {
        item: newFoodItem.name,
      })

      this.toast.success({
        component: ToastMessage,
        props: {
          message: successMessage,
          targetRoute: {
            name: 'food-editor',
            params: {
              foodItemId: newFoodItem.id,
            },
            query: {
              ...this.$route.query,
              returnPath: this.$route.path,
              menuId: this.menuId,
            },
          },
        },
      })
    },
    onSelectAll(status) {
      this.allSelected = status
      Object.values(this.foodItemsState).forEach((item) => {
        item.isSelected = status
      })
    },
    onSelect(id, status) {
      this.foodItemsState[id].isSelected = status
      if (!status) {
        this.allSelected = false
      } else {
        this.allSelected = this.checkIfAllSelected()
      }
    },
    checkIfAllSelected() {
      return this.selectedRows.length === this.foodItemIds.length
    },
  },
}
</script>

<style></style>
