<template>
  <div
    v-if="isOpen"
    class="flex gap-1"
  >
    <label
      class="sr-only"
      :for="id"
      >{{ $t('hierarchy.action.searchLabel') }}</label
    >
    <SearchOrgs
      :id="id"
      :placeholder="$t('filter.placeholder')"
      :preserve-selection="false"
      :pre-open="true"
      :hide-archived="hideArchived"
      class="hierarchy-filter-orgs"
      data-test-orgs-search
      @update:model-value="onInput"
      v-click-outside="closeSearch"
    />
    <button
      type="button"
      class="button button--icon"
      @click="closeSearch"
    >
      <span class="sr-only">{{ $t('actions.close') }}</span>
      <SvgIcon name="24-ic-clear"></SvgIcon>
    </button>
  </div>
  <button
    v-else
    class="button button--icon"
    data-test-org-search-toggle
    @click="openSearch"
  >
    <SvgIcon name="24-ic-search" />
    <span class="sr-only">{{ $t('hierarchy.action.searchLabel') }}</span>
  </button>
</template>

<script>
import SearchOrgs from '@/components/SearchOrgs/SearchOrgs.vue'
export default {
  components: {
    SearchOrgs,
  },
  props: {
    id: String,
    hideArchived: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onSelect'],

  data() {
    return {
      isOpen: false,
    }
  },

  methods: {
    onInput(value) {
      this.$emit('onSelect', value)
      this.isOpen = false
    },
    openSearch() {
      this.isOpen = true
    },
    closeSearch() {
      this.isOpen = false
    },
  },
}
</script>
<style lang="scss">
.hierarchy-filter-orgs {
  @include respond-min(medium) {
    min-width: to-rem(300px);
  }
}
</style>
