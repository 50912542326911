import axios, { spitURL } from '../api-axios'
import encode from '@/utils/strict-uri-encode'
import { PAGESIZE_TERMS, PAGESIZE_FACETS } from '../constants'

const NS = '/taxonomy'

export default {
  fetchTermsTree() {
    return axios.get(`${NS}/terms/tree`)
  },

  fetchFacetsTree() {
    return axios.get(`${NS}/facets/tree`)
  },

  fetchTermsHash() {
    return axios.get(`${NS}/terms/tree/hash`)
  },

  fetchFacetsHash() {
    return axios.get(`${NS}/facets/tree/hash`)
  },

  fetchTerms(query = {}, cancelToken = null) {
    const options = cancelToken ? { cancelToken } : {}
    const finalQuery = { size: PAGESIZE_TERMS, ...query }

    return axios.get(spitURL(`${NS}/terms`, finalQuery), options)
  },

  fetchFacets(query = {}, cancelToken = null) {
    const options = cancelToken ? { cancelToken } : {}
    const finalQuery = { size: PAGESIZE_FACETS, ...query }

    return axios.get(spitURL(`${NS}/facets`, finalQuery), options)
  },

  fetchTermOptions() {
    return axios.get(`${NS}/attribute-options`)
  },

  fetchTerm(id) {
    return axios.get(`${NS}/terms/${encode(id)}`)
  },

  fetchTermLinks(id) {
    return axios.get(`${NS}/terms/${encode(id)}/facet-links`)
  },

  postTerm(model) {
    return axios.post(`${NS}/terms`, model)
  },

  putTerm({ oldModel, newModel }) {
    return axios.put(`${NS}/terms/${encode(oldModel.id)}`, {
      old: oldModel,
      new: newModel,
    })
  },

  deleteTerm(code) {
    return axios.delete(`${NS}/terms/subtree/baseterm/${code}`)
  },

  validateTerm(model) {
    return axios.post(`${NS}/terms/validate`, model)
  },

  validateCode(obj) {
    return axios.post(`${NS}/terms/validate/mappingCodes`, obj)
  },

  fetchFacet(id) {
    return axios.get(`${NS}/facets/${encode(id)}`)
  },

  fetchFacetLinks(id) {
    return axios.get(`${NS}/facets/${encode(id)}/term-links`)
  },

  deleteFacet({ facetCode, rootCode }) {
    return axios.delete(`${NS}/terms/subtree/${rootCode}/${facetCode}`)
  },

  postFacet(model) {
    return axios.post(`${NS}/facets`, model)
  },

  putFacet({ oldModel, newModel }) {
    return axios.put(`${NS}/facets/${encode(oldModel.id)}`, {
      old: oldModel,
      new: newModel,
    })
  },

  validateFacetDelete(id) {
    return axios.post(`${NS}/facets/validate/${encode(id)}`)
  },

  moveNodes(type, { oldModel, newModel }) {
    return axios.put(`${NS}/${type}/move`, {
      old: oldModel,
      new: newModel,
    })
  },
}
