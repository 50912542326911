import menus from './menus'
import translations from './translations'
import foodItems from './food-items'
import duplicateMappings from './duplicate-mappings'
import parentChildConflicts from './parent-child-conflicts'
import stages from './stages'
import foodGroups from './food-groups'
import custom1s from './custom1s'
import images from './images'
import similarMappings from './similar-mappings'
import trackers from './trackers'
import organisations from './organisations'
import bulkUpdate from './bulk-update'
import backdate from './backdate'
import { EN_LOCALE } from '@/store/constants'

export default {
  namespaced: true,
  state: {
    menuId: null,
  },
  getters: {
    displayLanguage: (state, getters, rootState, rootGetters) => {
      const menuSettings = rootGetters['menu-management/menus/menuSettingsById'](state.menuId)
      return menuSettings ? menuSettings.displayLanguage : false
    },
    displayEnglish: (state, getters) => getters.displayLanguage === EN_LOCALE,
  },
  mutations: {
    SET_MENU_ID(state, id) {
      state.menuId = id
    },
  },
  modules: {
    menus,
    translations,
    'food-items': foodItems,
    'duplicate-mappings': duplicateMappings,
    'parent-child-conflicts': parentChildConflicts,
    'similar-mappings': similarMappings,
    stages,
    'food-groups': foodGroups,
    custom1s,
    images,
    trackers,
    organisations,
    'bulk-update': bulkUpdate,
    backdate,
  },
}
