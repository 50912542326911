<template>
  <button
    v-clipboard:success="onSuccess"
    v-clipboard:error="onError"
    v-clipboard="modelValue"
    type="button"
    class="button button--icon"
  >
    <span class="sr-only">{{ $t('actions.copyToClipboard') }}</span>
    <SvgIcon name="24-ic-file" />
  </button>
</template>
<script>
import { useToast } from 'vue-toastification'
export default {
  props: {
    /*
        use String for static values
        and Function for dynamic values
        see: https://github.com/euvl/v-clipboard#using
    */
    modelValue: [String, Function],
    successMessage: [String],
  },
  setup() {
    return { toast: useToast() }
  },
  methods: {
    onSuccess() {
      this.toast.success(this.successMessage || this.$t('messages.successCopiedToClipboard'))
    },
    onError() {
      this.toast.error(this.$t('messages.errorCopiedToClipboard'))
    },
  },
}
</script>
