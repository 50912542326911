<template>
  <transition name="fade">
    <tr
      :id="foodItemId"
      v-test="'duplicate-item'"
      :class="{ editing: isOpen }"
    >
      <td
        v-if="!readonly"
        class="td-small-col lg-move-last"
      >
        <div class="customControl customControl--checkbox">
          <input
            :id="'select-' + foodItemId"
            type="checkbox"
            class="customControl-input"
            :checked="selected"
            data-test-select-duplicate
            @change="$emit('select', $event.target.checked)"
          />
          <label
            class="customControl-label"
            :for="'select-' + foodItemId"
          >
            <span class="sr-only">{{
              $t('actions.selectRow', { name: foodItem.displayName })
            }}</span>
          </label>
        </div>
      </td>

      <!-- Name -->
      <td>
        <router-link
          :to="{
            name: 'food-editor',
            params: { foodItemId },
            query: {
              returnPath: $route.path,
              menuId,
              ...$route.query,
            },
          }"
          data-test-link-to-attributes
          >{{ foodItem.displayName }}</router-link
        >
      </td>
      <!-- Updated Date -->
      <td-value>
        <template #default>
          {{ formatDate(foodItem.updatedDate) }}
        </template>
        <template #sub>
          {{ formatTime(foodItem.updatedDate) }}
        </template>
      </td-value>
      <!-- Status -->
      <td class="whitespace-nowrap">
        <transition
          name="slide-up"
          mode="out-in"
        >
          <div :key="foodItem.status">
            <food-item-state :status="foodItem.status" />
          </div>
        </transition>
      </td>
      <td-value
        class="text-right"
        :model-value="cost"
        :sub="menu && menu.currency"
      />
      <td-value
        class="text-right"
        :model-value="portion"
        :sub="weightUnit"
      />

      <td
        v-if="!readonly"
        class="td-small-col"
      >
        <router-link
          v-if="foodItem.taxonomy.mapping"
          v-test="'food-item-mapping-link'"
          class="button button--secondary"
          :to="{
            name: 'food-mapping-taxonomy',
            params: { foodItemId },
            query: { menuId, returnPath: $route.path, ...$route.query },
          }"
        >
          {{ $t('menus.foodItems.actions.editMapping') }}
        </router-link>
      </td>
    </tr>
  </transition>
</template>
<script>
import { FOOD_ITEM_STATES } from '@/store/constants'
import FoodItemMixin from '@/pages/Menus/FoodItems/FoodItemMixin'
import FoodItemState from '@/components/FoodItemState.vue'
import TdValue from '@/components/TdValue.vue'
import moment from 'moment'
import { DATE_FORMAT, TIME_FORMAT } from '@/store/constants'
export default {
  components: {
    FoodItemState,
    TdValue,
  },
  mixins: [FoodItemMixin],
  props: ['isOpen', 'menuId', 'foodItemId', 'selected', 'readonly'],
  emits: ['select'],
  data() {
    return {
      FOOD_ITEM_STATES,
      rowSelected: false,
    }
  },
  computed: {
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    cost() {
      return this.foodItem.cost ? this.convertCostForDisplay(this.foodItem.cost) : null
    },
    portion() {
      return this.foodItem.portion && this.foodItem.portion.value ? this.localisedPortionSize : null
    },
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format(DATE_FORMAT)
    },
    formatTime(date) {
      return moment(date).format(TIME_FORMAT)
    },
  },
}
</script>
