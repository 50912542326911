<template>
  <component
    :is="wrapperEl"
    class="collapsible-wrapper"
  >
    <transition name="collapsible">
      <component
        :is="contentEl"
        v-if="isOpen"
        class="collapsible-content"
      >
        <slot />
      </component>
    </transition>
  </component>
</template>

<script>
export default {
  props: ['wrapperTag', 'contentTag', 'isOpen'],
  computed: {
    wrapperEl() {
      return this.wrapperTag || 'div'
    },
    contentEl() {
      return this.contentTag || 'div'
    },
  },
}
</script>

<style lang="scss">
.collapsible {
  &-wrapper {
    --bwidth: theme('spacing[3]');
    position: relative;
  }

  &-content {
    padding: theme('spacing.3');
    position: relative;
    background-color: theme('colors.lemon.100');
    padding-left: calc(var(--bwidth) + theme('spacing.3'));

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  @at-root {
    .collapsible-content {
      &::before {
        border-left: var(--bwidth) solid theme('colors.blueberry.hsluv');
      }
    }

    .collapsible-content .collapsible-content {
      padding: 0;
      padding-left: var(--bwidth);

      &::before {
        border-left: var(--bwidth) solid theme-color(primary);
      }
    }
  }

  &-wrapper .collapsible-wrapper {
    margin-left: calc(var(--bwidth) - theme('spacing.6'));
  }

  &-enter-active,
  &-leave-active {
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &::before {
      transition-property: transform;
      transition-duration: 0.2s;
      transition-timing-function: ease-out;
      transform-origin: 0 0;
    }
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
