import api from '@/store/api/organisation'
import getOldValues from '@/store/utils/getOldValues'

const state = {
  records: {},
  targetsIds: [],
  meta: null,
}

const getters = {
  byId: (state) => (id) => state.records[id],
  ids: (state) => state.targetsIds,
  meta: (state) => state.meta,
}

const actions = {
  getWasteTargets({ commit }, { nodeId, filter, page }) {
    return new Promise((resolve, reject) => {
      api
        .fetchWasteTargets(nodeId, { filter, page })
        .then(({ records, metadata, targetsIds }) => {
          commit('STORE_RECORDS', records)
          commit('APPEND_TO_WASTE_TARGETS_IDS', targetsIds)
          commit('STORE_META', metadata)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  createWasteTarget(_, { nodeId, payload }) {
    return api.postWasteTarget({ payload, nodeId })
  },

  deleteWasteTarget({ commit }, { nodeId, targetId }) {
    return new Promise((resolve, reject) => {
      api
        .deleteWasteTarget({ nodeId, targetId })
        .then(() => {
          commit('CLEAR_WASTE_TARGET_ID', targetId)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  saveWasteTargetChanges({ getters }, { nodeId, targetId, payload }) {
    return api.patchWasteTarget({
      nodeId,
      targetId,
      payload: {
        old: getOldValues(getters.byId(targetId), payload),
        new: payload,
      },
    })
  },
}

const mutations = {
  STORE_RECORDS(state, records) {
    state.records = { ...state.records, ...records }
  },

  CLEAR_RECORDS(state) {
    state.records = {}
  },

  STORE_META(state, meta) {
    state.meta = meta
  },

  APPEND_TO_WASTE_TARGETS_IDS(state, ids) {
    ids.forEach((id) => {
      state.targetsIds.push(id)
    })
  },

  CLEAR_WASTE_TARGET_ID(state, id) {
    state.targetsIds = state.targetsIds.filter((item) => item !== id)
  },

  CLEAR_WASTE_TARGETS_IDS(state) {
    state.targetsIds.splice(0)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
