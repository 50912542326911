export default function (defaults) {
  return {
    id: null,
    name: null,
    nameEnglish: null,
    displayName: null,
    displayOrder: null,
    imageId: null,
    imageUrl: null,
    stageIds: [],
    foodItemIds: [],
    manualOrdering: true,
    ...defaults,
  }
}
