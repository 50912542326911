<template>
  <div class="box UI">
    <h2 class="formSection-title">Basic Responsive Table</h2>
    <p>Import TableResponsive and TheadGenerator from '@/components'</p>
    <ol>
      <li>Wrap your table with the <b>&lt;table-responsive&gt;</b> component</li>
      <li>
        Add the <b>&lt;thead-generator :headers="[{...}]" /&gt; component</b>
        <p><b>headers</b> needs to be an array of objects with an <b>id</b> and a <b>title</b></p>
      </li>

      <li>Add a normal <code>&lt;tbody&gt;...&lt;/tbody&gt; with all your table data</code></li>
    </ol>

    <table-responsive class="my-8">
      <thead-generator
        :headers="[
          {
            id: 'col1',
            title: 'Header 1',
          },
          {
            id: 'col2',
            title: 'Header 2',
          },
          {
            id: 'col3',
            title: 'Header 3',
          },
          {
            id: 'col4',
            title: 'Header 4',
          },
          {
            id: 'col5',
            title: 'Header 5',
          },
        ]"
      />
      <tbody>
        <tr>
          <td>Row 01 Cell 01</td>
          <td>Row 01 Cell 02</td>
          <td>Row 01 Cell 03</td>
          <td>Row 01 Cell 04</td>
          <td>Row 01 Cell 05</td>
        </tr>
        <tr>
          <td>Row 02 Cell 01</td>
          <td>Row 02 Cell 02</td>
          <td>Row 02 Cell 03</td>
          <td>Row 02 Cell 04</td>
          <td>Row 02 Cell 05</td>
        </tr>
        <tr>
          <td>Row 03 Cell 01</td>
          <td>Row 03 Cell 02</td>
          <td>Row 03 Cell 03</td>
          <td>Row 03 Cell 04</td>
          <td>Row 03 Cell 05</td>
        </tr>
        <tr>
          <td>Row 04 Cell 01</td>
          <td>Row 04 Cell 02</td>
          <td>Row 04 Cell 03</td>
          <td>Row 04 Cell 04</td>
          <td>Row 04 Cell 05</td>
        </tr>
        <tr>
          <td>Row 05 Cell 01</td>
          <td>Row 05 Cell 02</td>
          <td>Row 05 Cell 03</td>
          <td>Row 05 Cell 04</td>
          <td>Row 05 Cell 05</td>
        </tr>
      </tbody>
    </table-responsive>

    <h2 class="formSection-title">Sortable Table</h2>
    <p>Import SortableTable '@/components'</p>
    <ol>
      <li>Wrap your table with the <b>&lt;SortableTable&gt;</b> component</li>
      <li>
        Add a <b>:headers="[{...}]"</b> property just like for the basic table but include a
        <b>sortable</b> property as well
      </li>

      <li>Add your table data inside</li>
    </ol>
    <SortableTable
      class="my-8"
      :headers="[
        {
          id: 'col1',
          title: 'Header 1',
        },
        {
          id: 'col2',
          title: 'Header 2',
          sortable: true,
        },
        {
          id: 'col3',
          title: 'Header 3',
        },
        {
          id: 'col4',
          title: 'Header 4',
          sortable: true,
        },
        {
          id: 'col5',
          title: 'Header 5',
        },
      ]"
    >
      <tbody>
        <tr>
          <td>Row 01 Cell 01</td>
          <td>Row 01 Cell 02</td>
          <td>Row 01 Cell 03</td>
          <td>Row 01 Cell 04</td>
          <td>Row 01 Cell 05</td>
        </tr>
        <tr>
          <td>Row 02 Cell 01</td>
          <td>Row 02 Cell 02</td>
          <td>Row 02 Cell 03</td>
          <td>Row 02 Cell 04</td>
          <td>Row 02 Cell 05</td>
        </tr>
        <tr>
          <td>Row 03 Cell 01</td>
          <td>Row 03 Cell 02</td>
          <td>Row 03 Cell 03</td>
          <td>Row 03 Cell 04</td>
          <td>Row 03 Cell 05</td>
        </tr>
        <tr>
          <td>Row 04 Cell 01</td>
          <td>Row 04 Cell 02</td>
          <td>Row 04 Cell 03</td>
          <td>Row 04 Cell 04</td>
          <td>Row 04 Cell 05</td>
        </tr>
        <tr>
          <td>Row 05 Cell 01</td>
          <td>Row 05 Cell 02</td>
          <td>Row 05 Cell 03</td>
          <td>Row 05 Cell 04</td>
          <td>Row 05 Cell 05</td>
        </tr>
      </tbody>
    </SortableTable>
  </div>
</template>
<script>
import TheadGenerator from '@/components/TheadGenerator/TheadGenerator.vue'
import TableResponsive from '@/components/TableResponsive/TableResponsive.vue'
import SortableTable from '@/components/SortableTable/SortableTable.vue'

export default {
  name: 'UITables',
  components: {
    TableResponsive,
    TheadGenerator,
    SortableTable,
  },
}
</script>
