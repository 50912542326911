<template>
  <LayoutCentralMessage>
    <form
      class="Setup flex flex-col items-center w-full"
      @submit.prevent="finishSetup()"
    >
      <h1 class="sr-only">Initial setup</h1>
      <Loading-Spinner
        v-if="isLoadingSettings"
        class="spinner spinner--lg spinner--white mx-auto my-4"
      />
      <template v-else>
        <section class="w-full">
          <h2 class="h1 mb-2">{{ $t('titles.chooseYourLanguage') }}</h2>
          <fieldset class="mt-2 mb-8">
            <legend class="pb-8 h3">{{ $t('messages.chooseYourLanguage') }}</legend>
            <div class="LanguagesBox">
              <div
                v-for="lang in settings.locale"
                :key="lang.key"
                class="LanguageFormGroup"
                data-test-language-selector
              >
                <input
                  :id="lang.key"
                  v-model="buffer.userSettings.locale"
                  class="LanguageRadio"
                  type="radio"
                  name="Language"
                  :value="{ id: lang.id, key: lang.key, code: lang.code }"
                />
                <label
                  class="Language flex flex-middle t-lg"
                  :for="lang.key"
                >
                  {{ lang.value }}
                </label>
              </div>
            </div>
          </fieldset>
        </section>
        <section
          v-if="jobTitleRequired"
          class="form--inverted w-full"
        >
          <h2 class="h1 mb-2">{{ $t('titles.selectJobTitle') }}</h2>
          <p
            for="jobTitleSelect"
            class="pb-8 h3"
          >
            {{ $t('messages.selectJobTitle') }}
          </p>
          <ValidationGroup :validator="v$.buffer.user.jobTitle">
            <label
              class="sr-only"
              for="jobTitleSelect"
              >{{ $t('profile.labels.selectJobTitle') }}</label
            >
            <select
              id="jobTitleSelect"
              v-model="v$.buffer.user.jobTitle.$model"
              class="customSelect"
              data-test-job-title-selector
            >
              <option
                :value="null"
                selected
                disabled
              >
                {{ $t('profile.labels.selectJobTitle') }}
              </option>
              <option
                v-for="job in jobTitles"
                :key="job.title"
                :value="job.title"
              >
                {{ $t(`profile.jobTitle.${job.jobKey}`) }}
              </option>
            </select>
          </ValidationGroup>
        </section>
      </template>
      <button
        type="submit"
        class="button button--inverted-outline mt-8"
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('actions.getStarted') }}</span>
      </button>
    </form>
  </LayoutCentralMessage>
</template>

<script>
import { useToast } from 'vue-toastification'
import { mapGetters } from 'vuex'
import { requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import LayoutCentralMessage from '@/layouts/LayoutCentralMessage/LayoutCentralMessage.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import { loadLanguageAsync } from '@/languages/index'
import moment from 'moment'

export default {
  name: 'SetupPage',
  components: {
    LayoutCentralMessage,
    ValidationGroup,
  },
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      isLoadingSettings: true,
      buffer: {
        user: {},
        userSettings: {},
        userMetadata: {},
      },
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user',
      'userSettings',
      'userMetadata',
      'settings',
      'jobTitles',
      'isWinnowUser',
    ]),
    jobTitleRequired() {
      return !this.user.jobTitle && !this.isWinnowUser
    },
  },
  watch: {
    'buffer.userSettings.locale'(newLocale) {
      this.onLanguageChange(newLocale)
    },
  },
  validations() {
    return {
      buffer: {
        user: {
          jobTitle: {
            required: requiredIf(() => this.jobTitleRequired),
          },
        },
      },
    }
  },
  mounted() {
    Promise.all([
      this.$store.dispatch('auth/getSettings', this.user.id),
      this.$store.dispatch('auth/getJobTitles'),
    ]).finally(() => {
      this.isLoadingSettings = false

      this.buffer = {
        user: { ...this.user },
        userSettings: { ...this.userSettings },
      }
    })

    // this is probably a hack, something is off a bit with the setup flow
    if (this.userMetadata.isSetupDone) {
      this.$router.push('/')
    }
  },
  methods: {
    finishSetup() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.buffer = {
        ...this.buffer,
        userMetadata: {
          isSetupDone: true,
        },
      }
      this.$store
        .dispatch('auth/saveSettings', this.buffer)
        .then(() => {
          const locale = this.$store.getters['auth/locale']
          loadLanguageAsync(locale)
          moment.locale(locale)

          this.$router.push('/')
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
      this.analytics.track('Selected a language', {
        language: this.buffer.userSettings.locale.code,
      })
    },
    onLanguageChange(newLanguageObj) {
      loadLanguageAsync(newLanguageObj.code)
    },
  },
}
</script>

<style lang="scss">
.Setup {
  min-height: 75vh;
}

.LanguagesBox {
  background-color: theme('colors.white');
  border-radius: theme('borderRadius.DEFAULT');
  max-height: 33vh;
  overflow: auto;
  box-shadow: rgb(0, 0, 0, 0.1);

  .Language:hover {
    background: theme('colors.grey.100');
  }
}

.Language {
  margin-bottom: 0;
  padding: theme('spacing.2') theme('spacing.3');
  cursor: pointer;
}

.LanguageFormGroup {
  position: relative;
}

.LanguageRadio {
  opacity: 0;
  position: absolute;
  top: theme('spacing.2');
  left: theme('spacing.3');
  width: theme('spacing.6');
  height: theme('spacing.6');
  z-index: -1;

  &:checked + .Language {
    background: theme('colors.lemon.100');
  }
}
</style>
