/* eslint-disable no-console */

import setupApp from './main'
import { store } from '@/store'
import axios from '@/store/api-axios'
var keycloak = window.Keycloak({
  url: import.meta.env.VUE_APP_KEYCLOAK_URL,
  realm: import.meta.env.VUE_APP_KEYCLOAK_REALM,
  clientId: import.meta.env.VUE_APP_KEYCLOAK_CLIENT_ID,
})

window.keycloak = keycloak
window.keycloakPromise = keycloak.init({ onLoad: 'login-required' })
const setHeaders = function (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

window.keycloakPromise
  .success((isAuthenticated) => {
    if (isAuthenticated) {
      setHeaders(window.keycloak.token)

      const refreshInterval = setInterval(() => window.keycloak.updateToken(60), 10000)
      store.commit('auth/SET_DATA', window.keycloak, true, refreshInterval)

      setupApp()
    } else {
      store.dispatch('auth/logout')
    }
  })
  .error((errorData) => {
    console.error('Keycloak error ._. ' + JSON.stringify(errorData))
  })

window.keycloak.onAuthRefreshSuccess = function () {
  setHeaders(window.keycloak.token)
}
window.keycloak.onAuthRefreshError = function () {
  console.error('Error while trying to refresh the token')
}
