<template>
  <div>
    <div class="flex flex-between flex-wrap">
      <slot name="title" />
    </div>
    <!-- Food groups -->
    <ol
      class="chip-container"
      data-test-current-items
    >
      <chip
        v-for="item in internalExisting"
        class="chip--squared"
        :key="item.id"
        tag="li"
        :removable="availableActions && availableActions.indexOf('remove') >= 0"
        :has-thumbnail="true"
        data-test-chip
        @remove="removeItem(item)"
      >
        <template #thumbnail>
          <image-loader
            :src="generateSmallThumbnailUrl(item.imageUrl)"
            :aspect-ratio="1"
            image-fitting="cover"
          />
        </template>
        <SvgIcon
          v-if="item.hasWarning"
          v-tooltip="{
            theme: 'tooltip-neutral',
            content: item.warningMessage,
            placement: 'top',
          }"
          class="text-apple icon--nudge-top-2"
          name="24-ic-info"
        />
        <router-link :to="item.link">
          {{ item.displayName }}
          <span
            v-if="item.hidden"
            class="text-carrot"
          >
            {{ $t('menus.tabletEditor.foodItems.messages.hidden') }}
          </span>
        </router-link>
      </chip>
      <chip
        v-if="!availableItemsVisible && canAddItems"
        tag="li"
        class="chip--squared"
      >
        <button
          type="button"
          class="button button--link"
          data-test-show-available
          @click="onShowAvailableClick"
        >
          <slot name="show-available">Show available</slot>
        </button>
      </chip>
      <slot name="custom-chips" />
    </ol>
    <hr />
    <!-- Available items -->
    <template v-if="canAddItems && availableItemsVisible">
      <div
        v-if="useSearch"
        class="flex flex-between flex-wrap flex-middle"
      >
        <Filter-input
          v-model="searchTerm"
          class="mb-2"
          :status="status"
          :total="availableItemsTotal"
          data-test-chip-search
          @search="onSearch"
        >
          {{ $t('menus.foodItems.labels.search') }}
        </Filter-input>
      </div>
      <loaded-content
        :is-loading="status === 'LOADING' && searchPage === 0"
        :has-error="status === 'LOADING_ERROR'"
        :has-data="status === 'IDLE' || !!internalAvailable.length"
        data-test-available-items
      >
        <template #no-data>
          <slot
            v-if="$slots['no-data']"
            name="no-data"
          ></slot>
        </template>
        <ul class="chip-container">
          <chip
            v-for="item in internalAvailable"
            :key="item.id"
            tag="li"
            :available="
              availableActions && availableActions.indexOf('add') >= 0 && !isAlreadyAdded(item)
            "
            :has-thumbnail="true"
            data-test-chip
            @add="addItem(item)"
            class="chip--squared"
          >
            <template #thumbnail>
              <image-loader
                :src="generateSmallThumbnailUrl(item.imageUrl)"
                :aspect-ratio="1"
                image-fitting="cover"
              />
            </template>
            <router-link :to="item.link">
              <SvgIcon
                v-if="item.hasWarning"
                v-tooltip="{
                  theme: 'tooltip-neutral',
                  content: warningMessage,
                  placement: 'top',
                }"
                class="text-apple icon--nudge-top-2"
                name="24-ic-info"
              />
              {{ item.displayName }}
            </router-link>
          </chip>
        </ul>
        <div class="flex flex-center my-2">
          <ButtonWithSpinner
            v-if="availableItemsTotal > availableItems.length + 1"
            class="button--primary"
            spinner-class="spinner--white"
            :in-progress="status === 'LOADING'"
            @click="onLoadMoreAvailable"
          >
            {{ $t('pagination.loadMore') }}
          </ButtonWithSpinner>
        </div>
      </loaded-content>
    </template>
  </div>
</template>

<script>
import Chip from '@/components/Chip/Chip.vue'
import ImageLoader from '@/components/image-loader.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import FilterInput from '@/components/FilterInput.vue'

import imagesMixin from '@/mixins/images'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'

export default {
  components: {
    Chip,
    ImageLoader,
    LoadedContent,
    FilterInput,
    ButtonWithSpinner,
  },
  mixins: [imagesMixin],
  props: {
    // [...{id, name, imageUrl, link}...]
    modelValue: {
      type: Array,
      default() {
        return []
      },
    },
    // [...{id, name, imageUrl, link}...]
    availableItems: {
      type: Array,
      default() {
        return []
      },
    },
    // check this if you want the ability to search for available ites
    useSearch: Boolean,
    // this is used for paginating the available items list
    availableItemsTotal: {
      type: Number,
      default() {
        return 0
      },
    },
    // "LOADING" || "LOADED" || "IDLE" || "ERROR"
    status: String,
    // check this if the available items should be displayed by default
    showAvailable: {
      type: Boolean,
      default: false,
    },
    // the actions available on chips
    // basically, if you are allowed to add or remove chips
    availableActions: {
      type: Array,
      default() {
        return ['add', 'remove']
      },
    },
  },
  emits: ['update:modelValue', 'request-available-items', 'search'],
  data() {
    return {
      showAlreadyAssigned: false,
      availableItemsVisible: this.showAvailable,
      internalExisting: this.modelValue,
      searchTerm: '',
      searchPage: 0,
      searchChunk: 20,
    }
  },
  computed: {
    canAddItems() {
      return this.availableActions && this.availableActions.indexOf('add') >= 0
    },
    internalAvailable() {
      if (this.showAlreadyAssigned) return [...this.availableItems]
      return this.availableItems.filter((item) => {
        return this.internalExisting.findIndex((_item) => _item.id === item.id) < 0
      })
    },
  },
  watch: {
    showAvailable(state) {
      this.availableItemsVisible = state
    },
    modelValue(items) {
      this.internalExisting = items
    },
  },
  methods: {
    removeItem(item) {
      let index = this.internalExisting.findIndex((f) => f.id === item.id)
      let newValue = [...this.internalExisting]
      newValue.splice(index, 1)
      this.$emit('update:modelValue', newValue)
    },
    addItem(item) {
      let newValue = [...this.internalExisting]
      newValue.push(item)
      this.$emit('update:modelValue', newValue)
    },
    onShowAvailableClick() {
      this.$emit('request-available-items')
      this.availableItemsVisible = true
    },
    isAlreadyAdded(item) {
      return this.internalExisting.find((_item) => _item.id === item.id)
    },
    onSearch() {
      this.searchPage = 0
      this.$emit('search', {
        search: this.searchTerm,
        page: this.searchPage,
        size: this.searchChunk,
      })
    },
    onLoadMoreAvailable() {
      this.searchPage++
      this.$emit('search', {
        search: this.searchTerm,
        page: this.searchPage,
        size: this.searchChunk,
      })
    },
  },
}
</script>

<style></style>
