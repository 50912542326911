<template>
  <div class="box-content-fill-remaining p-4 border-t-slate">
    <Breadcrumbs
      :history="[{ name: 'tablet-editor-stages', title: $t('menus.tabletEditor.nav.stages') }]"
      :current="stageId ? stage.displayName || stage.name : $route.meta.breadcrumb"
    />
    <div class="flex flex-between flex-wrap my-4">
      <div
        v-if="orderedFoodGroups.length"
        class="formGroup formGroup--inline mx-0"
      >
        <div class="label">{{ $t('labels.alphabeticOrdering') }}</div>
        <ToggleSwitch
          :id="'orderingToggle'"
          type="button"
          :state="!stage.manualOrdering"
          data-test-toggle-ordering
          :disabled="readonly"
          @toggle="toggleSortMode"
        />
      </div>
      <router-link
        v-if="$can('manage', 'menus')"
        :to="{ name: 'tablet-editor-stage' }"
        data-test-edit-stage
      >
        {{ $t('menus.tabletEditor.stage.actions.editStage') }}
      </router-link>
    </div>
    <loaded-content
      :is-loading="foodGroupsLoadingStatus === 'LOADING'"
      :has-error="foodGroupsLoadingStatus === 'ERROR'"
      :has-data="foodGroupsLoadingStatus === 'LOADED'"
    >
      <p
        v-if="!orderedFoodGroups.length"
        class="text-center"
        data-test-empty-list
      >
        {{ $t('menus.tabletEditor.foodGroups.noRecords') }}
      </p>

      <ordered-grid>
        <ordered-grid-item
          v-for="(foodGroup, index) in orderedFoodGroups"
          :key="foodGroup.id"
          :index="index"
          :length="orderedFoodGroups.length"
          :image-url="foodGroup.imageUrl"
          :title="foodGroup.displayName || foodGroup.name"
          :has-warning="!foodGroup.nameEnglish"
          :warning-message="$t('menus.tabletEditor.foodGroup.messages.noTranslation')"
          :link="{
            name: 'tablet-editor-food-items',
            params: { ...$route.params, foodGroupId: foodGroup.id },
          }"
          :reordering-active="stage.manualOrdering && !readonly"
          data-test-food-group
          @move="
            moveItem({
              item: foodGroup,
              list: orderedFoodGroups,
              oldIndex: $event.oldIndex,
              newIndex: $event.newIndex,
              callback: updateList,
            })
          "
        />
        <ordered-grid-create
          v-if="!readonly"
          key="none"
          :title="$t('menus.tabletEditor.foodGroups.actions.createNew')"
          :link="{
            name: 'tablet-editor-food-group-create',
            query: { ...$route.query, returnPath: $route.path },
          }"
        />
      </ordered-grid>
    </loaded-content>
  </div>
</template>

<script>
import LoadedContent from '@/components/LoadedContent.vue'
import OrderedGridMixin from '@/mixins/ordered-grid'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch.vue'
import menuMixin from '../menuMixin'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LoadedContent,
    ToggleSwitch,
    Breadcrumbs,
  },
  mixins: [OrderedGridMixin, menuMixin],
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      foodGroupsLoadingStatus: 'IDLE',
    }
  },
  computed: {
    foodGroups() {
      return this.$store.getters['menu-management/stages/foodGroupsInStage'](this.stageId)
    },
    orderedFoodGroups() {
      return this.foodGroups.slice().sort((a, b) => a.displayOrder - b.displayOrder)
    },
    stageId() {
      return this.$route.params.stageId
    },
    stage() {
      return this.$store.getters['menu-management/stages/stageById'](this.stageId)
    },
    readonly() {
      return (this.menu && this.menu.archived) || !this.$can('manage', 'menus')
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getStage() {
      return this.$store.dispatch('menu-management/stages/getStages', {
        menuId: this.menuId,
      })
    },
    getFoodGroups() {
      return this.$store.dispatch('menu-management/food-groups/getFoodGroups', {
        menuId: this.menuId,
        stageId: this.stageId,
      })
    },
    getData() {
      this.foodGroupsLoadingStatus = 'LOADING'
      this.getStage()
        .then(this.getFoodGroups)
        .then(() => {
          this.foodGroupsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.foodGroupsLoadingStatus = 'ERROR'
        })
    },
    updateList(list) {
      list.forEach((group, index) => {
        this.$store.dispatch('menu-management/food-groups/updateFoodGroup', {
          ...group,
          displayOrder: index,
        })
      })
      this.saveData(list)
    },
    saveData(list) {
      this.$store
        .dispatch('menu-management/stages/saveChanges', {
          menuId: this.menuId,
          stageId: this.stageId,
          data: {
            foodGroupIds: list.map((group) => group.id),
          },
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    toggleSortMode(alphabeticallySortedChildren) {
      this.foodGroupsLoadingStatus = 'LOADING'
      this.$store
        .dispatch('menu-management/stages/saveChanges', {
          menuId: this.menuId,
          stageId: this.stageId,
          data: {
            manualOrdering: !alphabeticallySortedChildren,
          },
        })
        .then(() => {
          return this.getFoodGroups()
        })
        .then(() => {
          this.foodGroupsLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.foodGroupsLoadingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
  },
}
</script>

<style></style>
