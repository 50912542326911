import api from '../api/covers-sales'

import moment from 'moment'
import { daysInMonth, daysUpTo } from '@/utils/dates'
import { ranges } from '@/utils/covers-sales'
import { DATE_FORMAT_ISO_8601 } from '@/store/constants'

const state = {
  cache: {},
  currentWeek: {
    date: null,
    days: [],
    loading: false,
    error: false,
  },
  currentMonth: {
    date: null,
    days: [],
    ranges: null,
    loading: false,
    error: false,
  },
}

const newModel = (date, site) => {
  const fullDate = moment.utc(date).hour(12).toISOString()
  return {
    id: null,
    active: true,
    covers: null,
    sales: null,
    date: fullDate,
    siteId: site,
  }
}

const getters = {
  getItemByDate: (state) => (ISODate) => state.cache[ISODate],
}

const actions = {
  getCurrentWeek({ commit }, { legacyId }) {
    const site = legacyId

    const today = moment.utc().endOf('day').toISOString()
    const start = moment.utc(today).subtract(6, 'day').startOf('day').toISOString()

    commit('WEEK_RESET', today)
    commit('WEEK_LOADING_START')

    return new Promise((resolve, reject) => {
      api
        .fetchCoversSales({
          filters: {
            start,
            end: today,
            site,
          },
        })
        .then((response) => {
          commit('WEEK_SET_DATA', response.data, site)
          resolve()
        })
        .catch((error) => {
          commit('WEEK_SET_ERROR', error)
          reject(error)
        })
        .finally(() => {
          commit('WEEK_LOADING_END')
        })
    })
  },
  getCurrentMonth({ commit }, { month, legacyId }) {
    const site = legacyId

    commit('MONTH_LOADING_START')
    commit('MONTH_RESET', month)

    return new Promise((resolve, reject) => {
      api
        .fetchCoversSales({
          filters: {
            start: month,
            end: moment.utc(month).endOf('month').toISOString(),
            site,
          },
        })
        .then((response) => {
          commit('MONTH_SET_DATA', response.data, site)
          commit('MONTH_CALCULATE_RANGES')
          resolve()
        })
        .catch((error) => {
          commit('MONTH_SET_ERROR', error)
          reject(error)
        })
        .finally(() => {
          commit('MONTH_LOADING_END')
        })
    })
  },

  save({ commit }, { legacyId, model }) {
    let method
    if (model.new.id === null) {
      model = model.new
      delete model.id
      model.siteId = legacyId
      method = api.postCoversSales
    } else {
      method = api.putCoversSales
    }

    return new Promise((resolve, reject) => {
      method(model)
        .then((response) => {
          const payload = response.data
          commit('ITEM_SET_DATA', payload)

          const d = moment.utc(payload.data.date)
          const currentMonth = moment.utc(state.currentMonth.date)

          // Because you might save something in the last 7 days
          // while looking at some other month
          if (d.isSame(currentMonth, 'month')) {
            commit('MONTH_CALCULATE_RANGES')
          }
          resolve(payload)
        })
        .catch((error) => {
          commit('ITEM_SET_ERROR', { error, model })
          reject(error)
        })
    })
  },
}

const mutations = {
  WEEK_RESET(state, date) {
    const days = daysUpTo(date)
    let hash = {}

    days.forEach((day) => {
      hash[day] = null
    })

    state.cache = { ...state.cache, ...hash }
    state.currentWeek.date = date
    state.currentWeek.days = days
  },
  WEEK_SET_DATA(state, payload, site) {
    payload.data.forEach((item) => {
      let d = moment.utc(item.date).format(DATE_FORMAT_ISO_8601)
      state.cache[d] = item
    })

    state.currentWeek.days.forEach((day) => {
      if (state.cache[day] === null) {
        state.cache[day] = { ...newModel(day, site) }
      }
    })
  },
  WEEK_SET_ERROR(state, error) {
    state.currentWeek.error = error
  },
  WEEK_LOADING_START(state) {
    state.currentWeek.loading = true
  },
  WEEK_LOADING_END(state) {
    state.currentWeek.loading = false
  },

  MONTH_RESET(state, date) {
    const days = daysInMonth(date)
    let hash = {}

    days.forEach((day) => {
      hash[day] = null
    })

    state.cache = { ...state.cache, ...hash }
    state.currentMonth.date = date
    state.currentMonth.days = days
    state.currentMonth.ranges = null
  },
  MONTH_SET_DATA(state, payload, site) {
    payload.data.forEach((item) => {
      let d = moment.utc(item.date).format(DATE_FORMAT_ISO_8601)
      state.cache[d] = item
    })

    state.currentMonth.days.forEach((day) => {
      if (state.cache[day] === null) {
        state.cache[day] = { ...newModel(day, site) }
      }
    })
  },
  MONTH_CALCULATE_RANGES(state) {
    let chunk = []

    state.currentMonth.days.forEach((day) => {
      const item = state.cache[day]
      if (item && item.active && (item.covers !== null || item.sales !== null)) {
        chunk.push(item)
      }
    })

    state.currentMonth.ranges = ranges(chunk)
  },
  MONTH_SET_ERROR(state, error) {
    state.currentMonth.error = error
  },
  MONTH_LOADING_START(state) {
    state.currentMonth.loading = true
  },
  MONTH_LOADING_END(state) {
    state.currentMonth.loading = false
  },

  ITEM_SET_DATA(state, payload) {
    const model = payload.data
    const day = moment.utc(model.date).format(DATE_FORMAT_ISO_8601)

    state.cache[day] = model
  },
  ITEM_SET_ERROR(/*state, { error, model }*/) {
    // state.currentMonth.error = error
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
