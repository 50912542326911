<template>
  <button
    class="button button--icon p-0 -ml-1.5"
    :aria-expanded="isOpen || null"
    data-test-expand
    @click.stop="onClick"
  >
    <SvgIcon
      v-if="isOpen"
      name="48-ic-collapse"
      xl
      class="text-blueberry"
    />
    <SvgIcon
      v-else
      :class="{ 'rotate-180': isOpen }"
      class="text-blueberry"
      name="48-ic-expand"
      xl
    />
    <span
      v-if="!isOpen"
      class="sr-only"
    >
      <slot name="toggle-open">{{ $t('actions.open') }}</slot>
    </span>
    <span
      v-if="isOpen"
      class="sr-only"
    >
      <slot name="toggle-close">{{ $t('actions.close') }}</slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close'],
  data() {
    return {
      isOpen: this.open,
    }
  },
  watch: {
    open(state) {
      this.isOpen = state
    },
  },
  methods: {
    onClick() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },
  },
}
</script>

<style></style>
