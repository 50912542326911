<template>
  <HubHeader class="shell-header">
    <slot name="siteNav" />
  </HubHeader>
  <Navigation
    class="shell-nav"
    parent-project="HUB"
    :analytics="analytics"
    :permissions="permissions"
    :handle-logout="logout"
    :is-multi-site-user="isMultiSiteUser"
    :hub-root="'/'"
    :insightsRoot="INSIGHTS_ROOT"
  />
  <div
    class="shell-body shell-grow-content"
    :class="{ 'shell-extended-content': extendedContent }"
    v-bind="$attrs"
  >
    <slot />
  </div>
  <HubFooter class="shell-footer" />
</template>

<script setup>
import { onMounted } from 'vue'
import HubHeader from '@/components/Header/Header.vue'
import { Navigation } from '@winnow/fe-core'
import HubFooter from '@/components/Footer/Footer.vue'
import { useAbility } from '@casl/vue'
import { useStore } from 'vuex'

const permissions = useAbility()
const store = useStore()
const isMultiSiteUser = store.getters['auth/isMultiSiteUser']
const INSIGHTS_ROOT = import.meta.env.VUE_APP_INSIGHTS_DEMO_URL
const analytics = window.analytics

defineProps({
  extendedContent: {
    type: Boolean,
    default: false,
  },
})

onMounted(() => {
  document.body.className = ''
})

const logout = () => {
  store.dispatch('auth/logout')
  analytics.track('Selected log out')
  analytics.reset()
}
</script>

<style lang="scss"></style>
