<template>
  <span v-if="type === 'checkbox'">
    <input
      :id="id"
      ref="button"
      v-model="switchedOn"
      v-test="'toggle'"
      type="checkbox"
      class="toggleSwitch-checkbox"
      :name="name"
      :disabled="disabled"
      @change="checkboxChanged"
    />
    <span
      :class="{ toggleSwitch: true, 'toggleSwitch--active': switchedOn }"
      @click="checkboxPressed"
    >
    </span>
  </span>

  <!--
    ! [aria-pressed] needs to always be present on the tag.
    ! if we pass in simple booleans, false/undefined/null will remove the attribute altogether
    -->
  <button
    v-else
    ref="button"
    v-test="'toggle'"
    type="button"
    :class="{ toggleSwitch: true, 'toggleSwitch--active': switchedOn }"
    :aria-pressed="switchedOn ? 'true' : 'false'"
    :aria-labelledby="labelledBy"
    :disabled="disabled"
    @click="togglePressed"
  ></button>
</template>

<script>
export default {
  props: [
    'type', // values: checkbox or button
    'name', // name for the checkbox name attribute (required when type is checkbox)
    'id', // id for the checkbox id attribute (required when type is checkbox)
    'labelledBy', // id of an outside element that labels the button for accessibility
    'state', // initial checked state
    'checked', // same as state but more appropriate for a checkbox
    'modelValue', // for compatibility with v-model
    'disabled',
  ],
  emits: ['toggle', 'update:modelValue'],
  data() {
    return {
      switchedOn: this.state || this.checked || this.modelValue ? true : false,
    }
  },
  computed: {},
  methods: {
    // this fires when the toggle is a checkbox and it was activated via keyboard
    checkboxChanged() {
      this.$emit('update:modelValue', this.switchedOn ? this.modelValue || true : false)
    },
    // this toggles between the states
    flipSwitch() {
      this.switchedOn = !this.switchedOn
    },
    // this fires when the checkbox UI has been clicked
    checkboxPressed() {
      this.flipSwitch()
      this.$refs.button.focus()
      this.checkboxChanged()
    },
    // this fires when the toggle is a button
    togglePressed() {
      this.flipSwitch()
      this.$emit('toggle', this.switchedOn)
    },
  },
}
</script>

<style lang="scss" scoped>
// Had to scope this styles because Tailwind is overwriting default buttons in manner that overwrites this toggle
// @TODO: Refactor component to use tailwind properly
$toggle-switch-width: 4em;
$toggle-switch-height: 1.75em;
$toggle-switch-border-radius: $toggle-switch-height;
$toggle-switch-border-width: 2px;
$toggle-switch-marker-width: $toggle-switch-width * 0.5;
$toggle-switch-animation-speed: 0.15s;

.toggleSwitch {
  display: inline-block;
  background: none;
  border: 0;
  border-radius: $toggle-switch-border-radius;
  width: $toggle-switch-width;
  height: $toggle-switch-height;
  vertical-align: middle;
  background: theme('colors.slate.DEFAULT');
  transition-property: background-color, border-color, box-shadow;
  transition-duration: $toggle-switch-animation-speed;
  transition-timing-function: ease-out;
  text-align: left;
  padding: 0;
  outline: none;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.1);
  }

  &[disabled] {
    opacity: 0.65;
  }

  // marker
  &::before {
    content: '';
    display: inline-block;
    width: $toggle-switch-marker-width;
    height: calc(#{$toggle-switch-height} - #{$toggle-switch-border-width * 2});
    margin-top: $toggle-switch-border-width;
    background: theme('colors.white');
    border-radius: $toggle-switch-border-radius;
    transition: transform $toggle-switch-animation-speed ease-out;
  }

  // border overlay
  &::after {
    content: '';
    border: $toggle-switch-border-width solid theme('colors.slate.DEFAULT');
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: $toggle-switch-border-radius;
    transition: border-color $toggle-switch-animation-speed ease-out;
  }
}

.toggleSwitch-checkbox {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;

  &:focus + .toggleSwitch {
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.1);
  }

  &:checked:focus + .toggleSwitch {
    box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv');
  }
}

.toggleSwitch--active {
  background: theme('colors.blueberry.DEFAULT');

  &:focus {
    box-shadow: 0 0 0 3px theme('colors.blueberry.hsluv');
  }

  &::before {
    transform: translate(calc(#{$toggle-switch-marker-width} - #{$toggle-switch-border-width}), 0);
    border-color: theme('colors.blueberry.DEFAULT');
  }

  &::after {
    border-color: theme('colors.blueberry.DEFAULT');
  }
}
</style>
