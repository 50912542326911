<template>
  <li
    class="org-navigator-node"
    :class="[
      {
        'org-navigator-node--selected': isSelected,
        'org-navigator-node--highlighted': isHighlighted,
        'org-navigator-node--show-hint': showCreateHint,
        'org-navigator-node--disabled': disabled,
      },
    ]"
    data-test-org-node
    @dblclick.stop="onSelect"
    @click="onFocus"
    @keydown.space.stop.prevent="onSelect"
    @keydown.enter.stop.prevent="onSelect"
    @focus="onFocus"
    @blur="createFocusable = false"
    @mousedown="preventSelection"
  >
    <div
      class="org-navigator-node-label"
      :class="status ? status.class : ''"
    >
      <slot>
        <template v-if="internalNode">
          {{ internalNode.name }}
          <br />
        </template>

        <span
          v-if="isArchived && !status"
          data-hierarchy-node-archived-flag
          class="text-sm"
        >
          {{ $t('hierarchy.node.archived') }}<br />
        </span>

        <span v-if="status">
          <span class="sr-only">{{ $t('hierarchy.label.siteStatus') }}</span>
          <span class="text-sm">
            {{ $t(`hierarchy.node.statusLabel.${status.label}`) }}
          </span>
        </span>
      </slot>
    </div>
    <template v-if="allowCreate">
      <button
        data-test-hierarchy-create-with-level
        type="button"
        class="org-navigator-node-create-btn"
        :tabindex="createFocusable ? 0 : -1"
        @click.stop="$refs.createNodeModal.open()"
        @keydown.enter.stop
        @keydown.space.stop
        @focus="showCreateHint = true"
        @blur="showCreateHint = false"
      >
        <span class="org-navigator-node-create-btn-label">
          {{ $t('actions.createNodeBelow') }}
        </span>
        <SvgIcon
          name="24-ic-add"
          class="org-navigator-node-create-btn-icon"
          @mouseover="showCreateHint = true"
          @mouseout="showCreateHint = false"
        ></SvgIcon>
      </button>

      <Modal ref="createNodeModal">
        <OrgCreateWithLevel
          class="modal-body"
          :parent-id="id"
          @created="onCreate"
          @close="onCreateCancel"
        />
      </Modal>
    </template>
  </li>
</template>

<script>
import OrgCreateWithLevel from '@/components/OrgBrowser/OrgCreateWithLevel.vue'
import Modal from '@/components/Modal.vue'
import { ORG_NODE_STATUSES } from '@/store/constants'

export default {
  components: {
    OrgCreateWithLevel,
    Modal,
  },
  props: {
    id: [String, Number],
    node: Object, //optional node object instead of id
    isSelected: Boolean,
    isHighlighted: Boolean,
    isArchived: Boolean,
    disabled: Boolean,
    allowCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['node-select', 'node-focus'],
  data() {
    return {
      showCreateHint: false,
      createFocusable: false,
      statusTable: {
        [ORG_NODE_STATUSES.SETUP]: {
          class: 'org-navigator-node-label--status-setup',
          label: ORG_NODE_STATUSES.SETUP,
        },
        [ORG_NODE_STATUSES.BASELINE]: {
          class: 'org-navigator-node-label--status-baseline',
          label: ORG_NODE_STATUSES.BASELINE,
        },
        [ORG_NODE_STATUSES.SUCCESS]: {
          class: 'org-navigator-node-label--status-success',
          label: ORG_NODE_STATUSES.SUCCESS,
        },
        [ORG_NODE_STATUSES.LIVE]: {
          class: 'org-navigator-node-label--status-live',
          label: ORG_NODE_STATUSES.LIVE,
        },
        [ORG_NODE_STATUSES.INACTIVE]: {
          class: 'org-navigator-node-label--status-inactive',
          label: ORG_NODE_STATUSES.INACTIVE,
        },
      },
    }
  },
  computed: {
    internalNode() {
      return this.node || this.$store.getters['hierarchy/nodes/byId'](this.id)
    },

    status() {
      return this.internalNode && this.statusTable[this.internalNode.siteStatusSummary]
    },
  },
  methods: {
    preventSelection(ev) {
      if (ev.detail == 2) ev.preventDefault()
    },
    onCreate({ nodeId }) {
      this.$emit('node-select', nodeId)
      this.$refs['createNodeModal'].close()
    },
    onSelect() {
      if (!this.disabled && !this.isSelected) {
        this.$emit('node-select', this.id)
      }
    },
    onFocus() {
      if (!this.disabled) {
        this.createFocusable = true
        this.$emit('node-focus', this.id)
      }
    },
    onCreateCancel() {
      this.$refs['createNodeModal'].close()
      this.refocus()
    },
    refocus() {
      this.$nextTick(() => {
        this.$el.focus()
      })
    },
  },
}
</script>

<style lang="scss">
.org-navigator-node {
  border-bottom: to-rem(1px) solid theme('colors.slate.DEFAULT');

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: calc(theme('spacing.14') + #{to-rem(1px)});
  border-radius: 0;
  overflow: hidden;

  &:focus,
  &:hover {
    background-color: theme('colors.acai.200');
    cursor: pointer;
  }

  &:focus {
    box-shadow: inset 0 0 0 3px theme('colors.blueberry.hsluv');
    outline: none;
  }

  &:hover,
  &:focus-within {
    .org-navigator-node-create-btn {
      opacity: 1;
      display: flex;
    }
  }
}

.org-navigator-node--highlighted,
.org-navigator-node--highlighted:hover,
.org-navigator-node--highlighted:focus {
  background-color: theme('colors.lemon.100');
  font-weight: theme('fontWeight.bold');
}

.org-navigator-node--archived,
.org-navigator-node--archived:hover,
.org-navigator-node--archived:focus {
  background-color: theme('colors.grey.100');
}

.org-navigator-node--selected,
.org-navigator-node--selected:hover,
.org-navigator-node--selected:focus {
  font-weight: theme('fontWeight.bold');
  background-color: theme('colors.acai.DEFAULT');
  color: theme('colors.white');
  opacity: 1;

  &:focus {
    box-shadow: inset 0 0 0 3px theme('colors.blueberry.hsluv');
  }
}

.org-navigator-node--disabled,
.org-navigator-node--disabled:hover,
.org-navigator-node--disabled:focus {
  opacity: 0.65;
}

.org-navigator-node--show-hint {
  .org-navigator-node-create-btn {
    width: 100%;
  }

  .org-navigator-node-create-btn-label {
    visibility: visible;
    white-space: unset;
    width: revert;
    opacity: 1;
  }

  .org-navigator-node-label {
    display: none;
  }
}

.org-navigator-node-label {
  flex: 1 0 auto;
  min-width: 0;
  width: calc(100% - theme('spacing.8'));
  line-height: theme('spacing.6');

  padding: theme('spacing.1');

  &--status-setup {
    border-left: $org-status-border-width solid transparent;
    border-left-color: theme('colors.apple.DEFAULT');
  }

  &--status-baseline {
    border-left: $org-status-border-width solid transparent;
    border-left-color: theme('colors.lemon.DEFAULT');
  }

  &--status-success {
    border-left: $org-status-border-width solid transparent;
    border-left-color: theme('colors.blueberry.DEFAULT');
  }

  &--status-live {
    border-left: $org-status-border-width solid transparent;
    border-left-color: theme('colors.mint.DEFAULT');
  }

  &--status-inactive {
    border-left: $org-status-border-width solid transparent;
    border-left-color: theme('colors.slate.DEFAULT');
  }
}

.org-navigator-node-create-btn {
  border: 0;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: theme('spacing.1');
  margin: 0;
  z-index: 1;
  color: inherit;
  flex: 0 1 auto;
  align-items: flex-start;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 3px theme('colors.blueberry.hsluv');
  }
}

.org-navigator-node-create-btn-label {
  visibility: hidden;
  white-space: nowrap;
  width: 0;
  overflow: hidden;
  color: theme('colors.grey.400');
}

.org-navigator-node-create-btn-icon {
  display: inline-block;
}
</style>
