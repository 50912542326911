<template>
  <div class="box UI">
    <form>
      <div class="formSection">
        <h2 class="formSection-title">Basic elements</h2>

        <div class="formGroup">
          <label for="ff-fname">First name</label>
          <input
            id="ff-fname"
            type="text"
            class="formControl"
            placeholder="Enter smth"
          />
        </div>

        <div class="formGroup">
          <label for="ff-lname">Last name</label>
          <input
            id="ff-lname"
            type="text"
            class="formControl"
            placeholder="Enter smth"
          />
        </div>

        <div class="formGroup">
          <label for="ff-email">Email address</label>
          <input
            id="ff-email"
            type="email"
            class="formControl"
            aria-describedby="ff-email-help"
            placeholder="Enter email"
          />
          <small
            id="ff-email-help"
            class="formText text-grey-400"
            >We'll never share your email with anyone else.</small
          >
        </div>

        <div class="formGroup">
          <label for="ff-select1">Example select</label>
          <select
            id="ff-select1"
            class="formControl"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="formGroup">
          <label for="ff-select2">Example multiple select</label>
          <select
            id="ff-select2"
            multiple
            class="formControl"
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="formGroup">
          <label for="ff-text">Example textarea</label>
          <textarea
            id="ff-text"
            class="formControl"
            rows="3"
          ></textarea>
        </div>
        <div class="formGroup">
          <label for="ff-readonly">Readonly</label>
          <input
            id="ff-readonly"
            type="text"
            class="formControl"
            placeholder="Readonly input here…"
            readonly
          />
        </div>
        <div class="formGroup">
          <label for="ff-static-email">Readonly plaintext style</label>
          <input
            id="ff-static-email"
            type="text"
            readonly
            class="formControlPlaintext"
            value="email@example.com"
          />
        </div>
      </div>

      <div class="formSection">
        <h2 class="formSection-title">Custom elements</h2>

        <div class="formGroup">
          <div class="customControl customControl--checkbox">
            <input
              id="customcheck1"
              type="checkbox"
              class="customControl-input"
            />
            <label
              class="customControl-label"
              for="customcheck1"
              >Check this custom checkbox</label
            >
          </div>
          <div class="customControl customControl--radio">
            <input
              id="radio1"
              name="radio"
              type="radio"
              class="customControl-input"
            />
            <label
              class="customControl-label"
              for="radio1"
              >Toggle this custom radio</label
            >
          </div>
          <div class="customControl customControl--radio">
            <input
              id="radio2"
              name="radio"
              type="radio"
              class="customControl-input"
            />
            <label
              class="customControl-label"
              for="radio2"
              >Or toggle this other custom radio</label
            >
          </div>
        </div>

        <div class="formGroup">
          <select class="customSelect">
            <option selected>Open this custom select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>

      <div class="formSection">
        <div class="grid-layout grid--half">
          <div class="grid-unit">
            <h2 class="formSection-title">Validation errors</h2>
            <ValidationGroup
              class="formGroup"
              :validator="v$.errors.password"
              :server-errors="serverErrors['email']"
              v-slot="{ hasAnyErrors }"
            >
              <label for="ff-passwd">Password</label>
              <input
                id="ff-passwd"
                v-model="v$.errors.password.$model"
                type="password"
                class="formControl"
                :class="{ 'is-invalid': hasAnyErrors }"
                placeholder="Password"
              />
            </ValidationGroup>

            <ValidationGroup
              :validator="v$.errors.options"
              class="formGroup"
              v-slot="{ hasAnyErrors }"
            >
              <label for="ff-options">Select an option</label>
              <select
                id="ff-options"
                class="customSelect"
                :class="{ 'is-invalid': hasAnyErrors }"
                v-model="v$.errors.options.$model"
              >
                <option
                  value=""
                  selected
                >
                  Open this custom select menu
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </ValidationGroup>

            <ValidationGroup
              :validator="v$.errors.checkbox"
              class="formGroup customControls--stacked"
              v-slot="{ hasAnyErrors }"
            >
              <div
                class="customControl customControl--checkbox"
                :class="{ 'is-invalid': hasAnyErrors }"
              >
                <input
                  id="customcheck2"
                  type="checkbox"
                  class="customControl-input"
                  v-model="v$.errors.checkbox.$model"
                />
                <label
                  class="customControl-label"
                  for="customcheck2"
                  >Check this custom checkbox</label
                >
              </div>
            </ValidationGroup>
            <ValidationGroup
              class="formGroup customControls--stacked"
              v-slot="{ hasAnyErrors }"
              :validator="v$.errors.radios"
            >
              <div
                class="customControl customControl--radio"
                :class="{ 'is-invalid': hasAnyErrors }"
              >
                <input
                  id="radio4"
                  name="validation-radio"
                  type="radio"
                  class="customControl-input"
                  v-model="v$.errors.radios.$model"
                  value="radio-validation-1"
                />
                <label
                  class="customControl-label"
                  for="radio4"
                  >Toggle this custom radio</label
                >
              </div>
              <div
                class="customControl customControl--radio"
                :class="{ 'is-invalid': hasAnyErrors }"
              >
                <input
                  id="radio5"
                  name="validation-radio"
                  type="radio"
                  class="customControl-input"
                  v-model="v$.errors.radios.$model"
                  value="radio-validation-2"
                />
                <label
                  class="customControl-label"
                  for="radio5"
                  >Or this one</label
                >
              </div>
            </ValidationGroup>
          </div>
          <div class="grid-unit">
            <h2 class="formSection-title">Validation warnings</h2>
            <ValidationGroup
              class="formGroup"
              :validator="v$.warnings.passwordWarning"
              :serverWarnings="serverWarnings['email']"
              v-slot="{ hasWarnings }"
            >
              <label for="ff-passwd-warning">Password</label>
              <input
                id="ff-passwd-warning"
                v-model="v$.warnings.password.$model"
                type="password"
                class="formControl"
                :class="{ 'is-warning': hasWarnings }"
                placeholder="Password"
              />
            </ValidationGroup>

            <ValidationGroup
              :validator="v$.warnings.optionsWarning"
              class="formGroup"
              :serverWarnings="serverWarnings['options']"
              v-slot="{ hasWarnings }"
            >
              <label for="ff-options-warning">Select an option</label>
              <select
                id="ff-options-warning"
                class="customSelect"
                :class="{ 'is-warning': hasWarnings }"
                v-model="v$.warnings.options.$model"
              >
                <option
                  value=""
                  selected
                >
                  Open this custom select menu
                </option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </ValidationGroup>

            <ValidationGroup
              :validator="v$.warnings.checkboxWarning"
              :serverWarnings="serverWarnings['checkbox']"
              class="formGroup customControls--stacked"
              v-slot="{ hasWarnings }"
            >
              <div
                class="customControl customControl--checkbox"
                :class="{ 'is-warning': hasWarnings }"
              >
                <input
                  id="customcheck2-warning"
                  type="checkbox"
                  class="customControl-input"
                  v-model="v$.warnings.checkbox.$model"
                />
                <label
                  class="customControl-label"
                  for="customcheck2-warning"
                  >Check this custom checkbox</label
                >
              </div>
            </ValidationGroup>
            <ValidationGroup
              class="formGroup customControls--stacked"
              :serverWarnings="serverWarnings['radios']"
              :validator="v$.warnings.radios"
              v-slot="{ hasWarnings }"
            >
              <div
                class="customControl customControl--radio"
                :class="{ 'is-warning': hasWarnings }"
              >
                <input
                  id="radio4-warning"
                  name="validation-radio-warning"
                  type="radio"
                  class="customControl-input"
                  v-model="v$.warnings.radios.$model"
                  value="radio-validation-1"
                />
                <label
                  class="customControl-label"
                  for="radio4-warning"
                  >Toggle this custom radio</label
                >
              </div>
              <div
                class="customControl customControl--radio"
                :class="{ 'is-warning': hasWarnings }"
              >
                <input
                  id="radio5-warning"
                  name="validation-radio-warning"
                  type="radio"
                  class="customControl-input"
                  v-model="v$.warnings.radios.$model"
                  value="radio-validation-2"
                />
                <label
                  class="customControl-label"
                  for="radio5-warning"
                  >Or this one</label
                >
              </div>
            </ValidationGroup>
          </div>
        </div>
      </div>

      <div class="formSection formSection--actions">
        <button
          type="submit"
          class="button button--primary"
        >
          Submit
        </button>
      </div>
      <ValidationResults
        class="my-4"
        :server-errors="validationResultsErrors"
        :server-warnings="validationResultsWarnings"
      />
    </form>

    <div class="demo inverted mt-6">
      <form class="form--inverted">
        <div class="formSection">
          <h2 class="formSection-title">Inverted form</h2>

          <div class="formGroup">
            <label for="ff1-fname">First name</label>
            <input
              id="ff1-fname"
              type="text"
              class="formControl"
              placeholder="Enter smth"
            />
            <div class="formFeedback is-invalid">Some reason why this is invalid</div>
          </div>

          <div class="formGroup">
            <label for="ff1-lname">Last name</label>
            <input
              id="ff1-lname"
              type="text"
              class="formControl"
              placeholder="Enter smth"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { reactive } from 'vue'
import { useValidation } from '@/composables/useValidation'

const buffer = reactive({
  errors: {
    email: '',
    password: '',
    options: '',
    checkbox: null,
    radios: '',
  },
  warnings: {
    email: '',
    password: '',
    options: '',
    checkbox: null,
    radios: '',
  },
})
const serverErrors = {
  email: [
    {
      level: 'HARD',
      key: 'lowerCase',
    },
  ],
}
const serverWarnings = {
  email: [
    {
      level: 'SOFT',
      key: 'email',
    },
  ],
  password: [
    {
      level: 'SOFT',
      key: 'minLength',
    },
  ],
  options: [
    {
      level: 'SOFT',
      key: 'required',
    },
  ],
  checkbox: [
    {
      level: 'SOFT',
      key: 'required',
    },
  ],
  radios: [
    {
      level: 'SOFT',
      key: 'required',
    },
  ],
}
const validations = {
  errors: {
    password: {
      minLength: minLength(6),
      required,
    },
    options: {
      required,
    },
    checkbox: {
      required,
    },
    radios: {
      radiosRequired: required,
    },
  },
  warnings: {
    password: {
      required,
      minLength: minLength(6),
    },
    options: {
      required,
    },
    checkbox: {
      required,
    },
    radios: {
      radiosRequired: required,
    },
  },
}
const v$ = useVuelidate(validations, buffer)

v$.value.errors.password.$touch()
v$.value.errors.options.$touch()
v$.value.errors.checkbox.$touch()
v$.value.errors.radios.$touch()

const {
  serverErrors: validationResultsErrors,
  serverWarnings: validationResultsWarnings,
  processValidation,
} = useValidation()
processValidation([
  {
    id: 1,
    level: 'HARD',
    key: 'DUPLICATE_NAME',
  },
  {
    id: 2,
    level: 'SOFT',
    key: 'lowerCase',
  },
])
</script>
