<template>
  <div>
    <MultiSelect
      :id="id"
      :ref="id"
      v-model="current"
      track-by="id"
      label="shortName"
      :class="[{ 'is-invalid': hasAnyErrors }]"
      :options="orgs"
      :placeholder="placeholder"
      :multiple="false"
      :use-internal-search="false"
      :is-loading="isRunning"
      :allow-empty="false"
      :disabled="disabled"
      :maxHeight="maxHeight"
      :customSelectedFormat="customSelectedFormat"
      @update:modelValue="onMultiselectInput"
      @search-change="onSearchChange"
    >
      <template #single-label="{ option }">
        {{ option[selectedFormat] }}
        <template v-if="option.archived">
          ({{ $t('organisations.users.labels.archivedNode') }})
        </template>
      </template>
      <template #no-options>
        {{ $t('filters.noOptionsSearch') }}
      </template>
      <template #no-results>
        {{ $t('organisations.messages.noOrganisationsFound') }}
      </template>
    </MultiSelect>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from '@/store/constants'
import { isBlank } from '@/utils/string'
import axios from 'axios'
import { parseOrganisationModel } from '@/store/utils/organisations'
import mapOrganisation from '@/store/api/mappings/get-organisation'
import MultiSelect from '@/components/MultiSelect.vue'
import api from '@/store/api/menus'
export default {
  components: {
    MultiSelect,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    hasAnyErrors: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    preserveSelection: {
      type: Boolean,
      default: true,
    },
    preOpen: {
      type: Boolean,
      default: false,
    },
    hideArchived: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: true,
    },
    selectedFormat: {
      type: String,
      default: 'shortName',
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isRunning: false,
      source: null,
      orgs: [],
      current: null,
    }
  },
  computed: {
    isBlank() {
      return isBlank(this.token)
    },
  },
  watch: {
    modelValue(newOrgId) {
      if (this.current && newOrgId !== this.current.id) {
        this.setupSelectedOrg(newOrgId)
      }
    },
  },
  created() {
    if (this.modelValue) {
      this.setupSelectedOrg(this.modelValue)
    }
  },
  mounted() {
    if (this.preOpen) {
      this.$refs[this.id].focusInput()
    }
  },
  methods: {
    setupSelectedOrg(id) {
      let selectedOrg = parseOrganisationModel(this.$store.getters['hierarchy/nodes/byId'](id))
      this.orgs = [selectedOrg]
      this.current = selectedOrg
    },
    onSearchChange(token) {
      this.isRunning = true
      this.runTask(token)
    },
    runTask: debounce(async function (token) {
      if (this.source !== null) {
        this.source.cancel()
      }

      if (isBlank(token)) {
        this.clear()
        return
      }

      this.source = axios.CancelToken.source()
      await this.performSearch({
        query: {
          search: token,
          // only send the archived param with "false" value when we want to hide all the archived locations
          // see contract: https://winnow.atlassian.net/browse/HUB-2716
          filter: { archived: !this.hideArchived },
        },
        options: { cancelToken: this.source.token },
      })

      this.isRunning = false
      this.source = null
    }, DEBOUNCE_DELAY),
    onMultiselectInput(value) {
      this.$emit('update:modelValue', value.id)

      if (this.preserveSelection) {
        this.current = value
      }
    },
    clear() {
      this.isRunning = false
      this.orgs = []
    },

    async performSearch(params) {
      const { query, options = {} } = params
      const { data } = await api.fetchOrganisations(query, options.cancelToken)
      this.orgs = data.map(mapOrganisation).map(parseOrganisationModel)
    },
    focusInput() {
      this.$refs.search.focusInput()
    },
    customSelectedFormat(option) {
      if (!option) return ''
      let formatted = option[this.selectedFormat]
      if (option.archived) {
        formatted = `${formatted} (${this.$t('organisations.users.labels.archivedNode')})`
      }
      return formatted
    },
  },
}
</script>
