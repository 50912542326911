/* eslint-disable no-console */
if (import.meta.env.DEV || import.meta.env.VUE_APP_SEGMENT_SILENT_STUB === 'true') {
  const methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ]

  let stub = {}

  if (import.meta.env.VUE_APP_SEGMENT_SILENT_STUB === 'true') {
    methods.forEach((method) => {
      stub[method] = function () {}
    })
  } else {
    methods.forEach((method) => {
      stub[method] = function (...args) {
        console.log('[segment]', method, args)
      }
    })
  }

  window.analytics = stub
}

export default {
  data() {
    return {
      analytics: window.analytics,
    }
  },
}
