<template>
  <Layout-central-message class="setup">
    <h1 class="font-light mb-2 text-white">{{ $t('titles.selectJobTitle') }}</h1>
    <p
      for="jobTitleSelect"
      class="pb-8"
    >
      {{ $t('messages.selectJobTitle') }}
    </p>
    <Loading-Spinner
      v-if="isLoadingJobOptions"
      class="spinner spinner--lg spinner--white mx-auto my-4"
    />
    <form
      v-else
      class="form--inverted flex flex-col items-center"
      @submit.prevent="saveJobTitle"
    >
      <ValidationGroup
        :validator="v$.buffer.user.jobTitle"
        v-slot="{ hasAnyErrors }"
      >
        <label
          class="sr-only"
          for="jobTitleSelect"
          >{{ $t('profile.labels.selectJobTitle') }}</label
        >
        <select
          id="jobTitleSelect"
          v-model="v$.buffer.user.jobTitle.$model"
          class="customSelect"
          :class="{ 'is-invalid': hasAnyErrors }"
          data-test-job-title-selector
        >
          <option
            :value="null"
            selected
            disabled
          >
            {{ $t('profile.labels.selectJobTitle') }}
          </option>
          <option
            v-for="job in jobTitles"
            :key="job.title"
            :value="job.title"
          >
            {{ $t(`profile.jobTitle.${job.jobKey}`) }}
          </option>
        </select>
      </ValidationGroup>
      <button
        type="submit"
        class="button button--inverted-outline mt-8"
      >
        <SvgIcon name="24-ic-check" />
        <span>{{ $t('actions.continue') }}</span>
      </button>
    </form>
  </Layout-central-message>
</template>

<script>
import { useToast } from 'vue-toastification'
import { mapGetters } from 'vuex'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import LayoutCentralMessage from '@/layouts/LayoutCentralMessage/LayoutCentralMessage.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'

export default {
  components: {
    LayoutCentralMessage,
    ValidationGroup,
  },
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      isLoadingJobOptions: true,
      buffer: {
        user: {},
        userSettings: {},
        userMetadata: {},
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'userSettings', 'userMetadata', 'jobTitles', 'isWinnowUser']),
  },
  created() {
    if (this.user.jobTitle || this.isWinnowUser) {
      this.$router.push('/')
    }
    this.$store.dispatch('auth/getJobTitles').then(() => {
      this.buffer = {
        user: { ...this.user },
        userSettings: { ...this.userSettings },
        userMetadata: { ...this.userMetadata },
      }
      this.isLoadingJobOptions = false
    })
  },
  methods: {
    saveJobTitle() {
      this.v$.$touch()
      if (this.v$.$invalid) return
      this.$store
        .dispatch('auth/saveSettings', this.buffer)
        .then(() => {
          this.$router.push('/')
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
      this.analytics.track('Selected a language', {
        language: this.buffer.userSettings.locale.code,
      })
    },
  },
  validations() {
    return {
      buffer: {
        user: {
          jobTitle: { required },
        },
      },
    }
  },
}
</script>

<style lang="scss">
.setup {
  min-height: 75vh;
}
</style>
