import SimilarMapping from '../../models/similar-mapping'

export default function (item) {
  return new SimilarMapping({
    id: item.id,
    name: item.name,
    taxonomy: {
      code: item.taxonomyCode,
      mapping: item.taxonomyMapping,
    },
    accuracy: item.matchingPercentage,
  })
}
