import api from '@/store/api/menus'
import getOldValues from '@/store/utils/getOldValues'

const state = {
  records: {},
}
const getters = {
  stageById: (state, getters, rootState, rootGetters) => (id) => {
    let stage = state.records[id]
    if (!stage) return {}
    stage.displayName = rootGetters['menu-management/displayEnglish']
      ? stage.nameEnglish
      : stage.name
    return stage
  },
  stagesById: (state, getters) => (ids) => {
    if (!ids) return []
    return ids.map((id) => getters['stageById'](id))
  },
  foodGroupsInStage: (state, getters, rootState, rootGetters) => (stageId) => {
    if (!stageId || !state.records[stageId]) return []
    return rootGetters['menu-management/food-groups/foodGroupsById'](
      state.records[stageId].foodGroupIds
    )
  },
}
const actions = {
  getStages({ commit }, { menuId }) {
    return api
      .fetchStages({ menuId })
      .then(({ records, ids, foodGroups, foodGroupIds, custom1s }) => {
        commit('STORE_STAGES', records)
        commit(
          'menu-management/menus/UPDATE_MENU',
          { id: menuId, stageIds: ids, ...(foodGroupIds && { foodGroupIds }) },
          { root: true }
        )
        commit(
          'menu-management/food-groups/STORE_FOOD_GROUPS',
          {
            records: foodGroups,
          },
          { root: true }
        )
        commit('menu-management/custom1s/STORE_CUSTOM1S', custom1s, { root: true })
        return { stageRecords: records, foodGroupRecords: foodGroups }
      })
  },
  saveChanges({ commit, getters }, { menuId, stageId, data }) {
    return api
      .patchStage({
        menuId,
        stageId,
        data: {
          old: getOldValues(getters.stageById(stageId), data),
          new: data,
        },
      })
      .then((stage) => {
        commit('UPDATE_STAGE', stage)
        return stage
      })
  },
  createNew({ commit }, { menuId, data }) {
    return api.postStage({ menuId, data }).then((stage) => {
      commit('UPDATE_STAGE', stage)
      return stage
    })
  },
  deleteStage({ commit }, { stageId }) {
    return api.deleteStage({ stageId }).then(() => {
      commit('DELETE_STAGE', stageId)
    })
  },
  updateStage({ commit }, stage) {
    commit('UPDATE_STAGE', stage)
  },
}
const mutations = {
  SET_MENU_ID(state, id) {
    state.menuId = id
  },
  STORE_STAGES(state, records) {
    Object.values(records).forEach((record) => {
      state.records[record.id] = {
        ...state.records[record.id],
        ...record,
      }
    })
  },
  UPDATE_STAGE(state, stage) {
    if (!stage.foodGroupIds) delete stage.foodGroupIds
    state.records[stage.id] = { ...state.records[stage.id], ...stage }
  },
  DELETE_STAGE(state, id) {
    delete state.records[id]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
