<template>
  <div class="timeline-detail">
    <svg
      ref="chart"
      :viewbox="`0 0 ${width} ${height}`"
      :width="width"
      :height="height"
      @wheel="onScroll"
      @mouseleave="showIndicator = false"
    >
      <!-- Axis -->
      <g
        ref="timeline-xAxis"
        class="timeline-axis timeline-xAxis"
      ></g>
      <g
        ref="timeline-yAxis"
        class="timeline-axis timeline-yAxis"
      ></g>
      <g
        ref="timeline-y2Axis"
        class="timeline-axis timeline-y2Axis"
      ></g>

      <!-- Pan target -->
      <rect
        ref="timeline-action-area"
        :x="margins.left + 1"
        :y="margins.top"
        :width="chartWidth"
        :height="chartHeight"
        class="timeline-action-area"
        stroke="transparent"
        fill="transparent"
        @mousemove="cursorMove"
      />

      <g :transform="`translate(${margins.left} ${margins.top})`">
        <timeline-indicator
          :label="indicatorTime"
          :position="indicatorPosition"
          :height="chartHeight"
          :label-position="chartHeight + margins.top"
          :visible="showIndicator"
          :size="indicatorSize"
        />
      </g>

      <!-- Timeline -->
      <g
        ref="timeline"
        class="timeline-data"
        clip-path="url(#viewport)"
        :transform="`translate(${margins.left + 1}, ${margins.top})`"
        :width="chartWidth"
        :height="height"
      >
        <g
          v-if="pan"
          :transform="pan"
          :style="{
            transition: shouldTransition
              ? `transform ${transitionDuration / 1000}s ease-out`
              : null,
          }"
        >
          <g
            v-for="data in internalDataPoints"
            :key="data.id"
            class="timeline-bar-g"
            @click="barClicked(data.id)"
            @mouseenter="barHover(data)"
          >
            <!-- Clamp bubble -->
            <!-- This gets shown if the values are more than the allowed range -->
            <circle
              v-if="y(data.cost) === 0 || y2(data.weight) === chartHeight / 2"
              class="timeline-clamp-bubble"
              :cx="x(data.date) + barWidth / 2"
              :cy="-barWidth"
              :r="barWidth / 2"
            />

            <!-- Bar hover effect -->
            <rect
              class="timeline-bar-highlight"
              :x="x(data.date)"
              :width="barWidth"
              :height="chartHeight"
            />

            <!-- Top bar -->
            <line
              :x1="x(data.date) + barPadding / 2"
              :x2="x(data.date) + barWidth - barPadding / 2"
              :y1="y(data.cost)"
              :y2="y(data.cost)"
              stroke="#000"
              :stroke-width="4"
            />
            <rect
              :x="x(data.date) + barPadding / 2"
              :width="barWidth - barPadding"
              :height="chartHeight / 2 - y(data.cost)"
              :y="y(data.cost)"
              class="timeline-bar-top"
              :class="setBarClass(data)"
            />

            <!-- Bottom bar -->
            <line
              :x1="x(data.date) + barPadding / 2"
              :x2="x(data.date) + barWidth - barPadding / 2"
              :y1="chartHeight / 2 + y2(data.weight)"
              :y2="chartHeight / 2 + y2(data.weight)"
              stroke="#000"
              :stroke-width="4"
            />
            <rect
              :x="x(data.date) + barPadding / 2"
              :width="barWidth - barPadding"
              :height="y2(data.weight)"
              :y="chartHeight / 2"
              :class="setBarClass(data)"
              class="timeline-bar-bottom"
            />
          </g>
        </g>
      </g>

      <!-- Decorative guide lines -->
      <g
        class="guide"
        :transform="`translate(${margins.left},${chartHeight / 2 + margins.top})`"
      >
        <line
          class="guide-vertical-bottom"
          :y2="chartHeight / 2"
        />
        <line
          class="guide-vertical-bottom"
          :y2="chartHeight / 2"
          :x1="chartWidth"
          :x2="chartWidth"
        />
        <line
          class="guide-vertical-top"
          :y2="-chartHeight / 2"
        />
        <line
          class="guide-vertical-top"
          :x1="chartWidth"
          :x2="chartWidth"
          y1="0"
          :y2="-chartHeight / 2"
        />
        <line
          class="guide-horizontal"
          :x2="width - margins.left - margins.right"
        />
      </g>

      <!-- Timeline clip mask -->
      <clipPath id="viewport">
        <rect
          :x="0"
          :y="-margins.top"
          :width="chartWidth"
          :height="height - margins.top"
        />
      </clipPath>
    </svg>
    <timeline-tooltip
      v-if="focusedTransaction"
      :position="tooltipPosition"
      :type="$t(`${'waste.' + focusedTransaction.type}`)"
      :stage="focusedTransaction.stage && focusedTransaction.stage.name"
      :food-instance="focusedTransaction.foodInstance && focusedTransaction.foodInstance.name"
      :weight="
        focusedTransaction.weight ? `${focusedTransaction.weight.toFixed(2)} ${weightUnit}` : ''
      "
      :cost="
        focusedTransaction.cost
          ? `${focusedTransaction.cost.toFixed(2)} ${focusedTransaction.currency}`
          : ''
      "
      :date="formatTime(focusedTransaction.date)"
    />
  </div>
</template>

<script>
import { max, scaleUtc, scaleLinear, zoom, select, axisBottom, axisLeft, zoomTransform } from 'd3'
import moment from 'moment-timezone'
import TimelineIndicator from './timeline-indicator.vue'
import TimelineTooltip from './timeline-tooltip.vue'
import { DATE_FORMAT, TIME_FORMAT } from '@/store/constants'

export default {
  components: {
    TimelineIndicator,
    TimelineTooltip,
  },
  props: {
    timezone: String,
    selectedId: [String, Number],
    dataPoints: Array,
    start: Date,
    end: Date,
    weightUnit: String,
    /** focusedTime
     * {
     *   start: Date,
     *   end: Date
     * }
     */
    focusedTime: Object,
  },
  emits: ['bar-clicked', 'timeline-pan'],
  data() {
    return {
      margins: { top: 30, right: 0, bottom: 30, left: 40 },
      step: 1, //minutes
      barWidth: 10,
      barPadding: 2,
      height: 260,
      width: 500,
      pan: null,
      shouldTransition: false,
      transitionDuration: 300,
      indicatorTime: '00:00',
      indicatorPosition: 0,
      showIndicator: false,
      indicatorSize: 2,
      mouseOffset: 0,
      focusedTransaction: null,
      tooltipPosition: null,
    }
  },
  computed: {
    // In D3 v7, scalerLinear fn does not accept null or undefined values
    internalDataPoints() {
      return this.dataPoints.map((data) => {
        return {
          ...data,
          cost: data.cost || 0,
          weight: data.weight || 0,
        }
      })
    },
    topDataClamp() {
      // In case there is only one transaction with cost 0, d3.max will throw undefined.
      return max(this.internalDataPoints, (d) => d.cost)
    },
    bottomDataClamp() {
      return max(this.internalDataPoints, (d) => d.weight)
    },
    numBars() {
      return (60 / this.step) * 24
    },
    dataWidth() {
      return this.barWidth * this.numBars
    },
    chartWidth() {
      return this.width - this.margins.left - this.margins.right
    },
    chartHeight() {
      return this.height - this.margins.bottom - this.margins.top
    },
    x() {
      return scaleUtc().domain([this.start, this.end]).range([0, this.dataWidth])
    },
    xFocused() {
      return scaleUtc()
        .domain([this.focusedTime.start, this.focusedTime.end])
        .range([0, this.chartWidth])
    },
    y() {
      return (
        scaleLinear()
          // Needs a min of 1 to display the chart y axis correctly, so that that it does not max to out 0.
          .domain([0, this.topDataClamp || 1])
          // .domain([0, 50])
          .range([this.chartHeight / 2, 0])
          .clamp(true)
          .nice()
      )
    },
    y2() {
      return (
        scaleLinear()
          // Same as above.
          .domain([this.bottomDataClamp || 1, 0])
          .range([this.chartHeight / 2, 0])
          .clamp(true)
          .nice()
      )
    },
    zoom() {
      return zoom()
        .scaleExtent([1, 1])
        .extent([
          [0, 0],
          [this.chartWidth, this.height - this.margins.bottom],
        ])
        .translateExtent([
          [0, 0],
          [this.dataWidth, this.height - this.margins.bottom],
        ])
        .on('zoom', this.zoomed)
    },
  },
  watch: {
    focusedTime(newTime, oldTime) {
      if (newTime.start && oldTime.start && newTime.start.valueOf() !== oldTime.start.valueOf()) {
        this.zoomTo({ date: new Date(newTime.start), centered: false })
      }
    },
    selectedId(newId, oldId) {
      if (newId !== oldId) {
        this.zoomToSelectedId(newId)
        this.emitPan()
      }
    },
  },
  mounted() {
    this.width = this.$el.clientWidth
    this.mouseOffset = this.$refs['timeline-action-area'].getBoundingClientRect().left
    // put this on nextTick so that the chart gets first drawn with the previous state properties
    // this helps with transitioning to the new properties because we need a render loop in between
    this.$nextTick(() => {
      this.bootstrapChart()
    })
    select(this.$refs['timeline-action-area']).call(this.zoom)
  },
  methods: {
    bootstrapChart(date) {
      if (this.focusedTime.start) {
        this.zoomTo({ date: new Date(this.focusedTime.start) })
        this.emitPan()
      }
      if (this.selectedId) {
        this.shouldTransition = true
        this.$nextTick(() => {
          this.zoomToSelectedId(this.selectedId)
          this.emitPan()
          window.setTimeout(() => {
            this.shouldTransition = false
          }, this.transitionDuration)
        })
      } else {
        this.zoomTo({ date: date, centered: true })
        this.emitPan()
      }
      this.xAxis()
      this.yAxis()
      this.yAxis2()
    },
    barHeight(d) {
      const space = this.chartHeight / 2

      return (
        space -
        this.y(d.cost) +
        this.margins.top +
        (this.y2(d.weight) - space - this.margins.bottom)
      )
    },
    xAxis(x) {
      select(this.$refs['timeline-xAxis'])
        .attr('transform', `translate(${this.margins.left},${this.height - this.margins.bottom})`)
        .call(
          axisBottom(x || this.x)
            .ticks(48)
            .tickFormat(this.tickFormat)
            .tickPadding(6)
            .tickSizeInner(12)
            .tickSize(-this.chartHeight)
        )
        .call((g) => g.select('.domain').remove())
    },
    yAxis() {
      select(this.$refs['timeline-yAxis'])
        .attr('transform', `translate(${this.margins.left},${this.margins.top})`)
        .call(
          axisLeft(this.y)
            .ticks(4)
            // .tickValues([0, 10, 20, 30, 40, 50])
            .tickSizeInner(-this.width + this.margins.left + this.margins.right)
            .tickPadding(10)
        )
    },
    yAxis2() {
      select(this.$refs['timeline-y2Axis'])
        .attr(
          'transform',
          `translate(${this.margins.left},${this.chartHeight / 2 + this.margins.top})`
        )
        .call(
          axisLeft(this.y2)
            .ticks(4)
            // .tickValues([0, 10, 20, 30, 40, 50])
            .tickSizeInner(-this.width + this.margins.left + this.margins.right)
            .tickPadding(10)
        )
    },
    setBarClass(d) {
      const list = {
        'is-active': d.id === this.selectedId,
        'no-image': d.imageId === null,
        'no-category': !d.stage && !d.foodInstance,
      }
      return Object.keys(list)
        .filter((k) => list[k])
        .join(' ')
    },
    tickFormat(d) {
      return moment(d).tz(this.timezone).format(TIME_FORMAT)
    },
    zoomed(event) {
      let transform = event.transform
      this.showIndicator = false
      this.focusedTransaction = null

      if (transform) {
        this.pan = transform.toString()
        this.xAxis(transform.rescaleX(this.x))
      }

      // only emit event if sourceEvent exists. This means it was triggered by a user action.
      // zoom.translatesTo does not populate this property
      // this is to prevent an infinite loop of events when zoom is changed from the timeline-overview component
      if (event.sourceEvent) {
        this.emitPan()
      }
    },
    zoomTo({ date, centered, emitEvent = false }) {
      let svg = select(this.$refs['timeline-action-area'])
      let position = this.x(date)
      let p = centered ? [Math.floor(this.width / 2), 0] : [0, 0]
      let transform = zoomTransform(this.$refs['timeline-action-area'])
      if (-position !== transform.x) {
        svg.call(this.zoom.translateTo, position, 0, p)
      }
      if (emitEvent) {
        this.emitPan()
      }
    },
    barClicked(id) {
      this.$emit('bar-clicked', { id })
    },
    barHover(transaction) {
      let position = this.xFocused(transaction.date) + this.barWidth / 2 + this.barPadding / 2
      this.indicatorPosition = position
      this.indicatorTime = this.formatTime(transaction.date)
      this.focusedTransaction = transaction
      this.$nextTick(() => {
        this.tooltipPosition = { x: this.margins.left + position, y: this.margins.top }
      })
    },
    cursorMove(e) {
      let position = e.clientX - this.mouseOffset
      this.indicatorPosition = position
      this.showIndicator = true
      this.focusedTransaction = null
      this.indicatorTime = this.formatTime(this.xFocused.invert(position))
    },
    emitPan() {
      let transform = zoomTransform(this.$refs['timeline-action-area'])
      let start = this.x.invert(-transform.x)
      let end = this.x.invert(-transform.x + this.chartWidth)
      this.$emit('timeline-pan', { start, end })
    },
    zoomToSelectedId(id) {
      this.zoomTo({
        date: this.dataPoints.find((d) => d.id && d.id === id).date,
        centered: true,
        emitEvent: false,
      })
    },
    onScroll(e) {
      if (e.deltaX) {
        e.preventDefault()
      }
      e.stopPropagation()
      let pos = -zoomTransform(this.$refs['timeline-action-area']).x
      pos += e.deltaX
      this.zoomTo({ date: this.x.invert(pos), emitEvent: true })
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT)
    },
    formatTime(date) {
      return moment(date).format(TIME_FORMAT)
    },
  },
}
</script>

<style lang="scss">
.timeline-detail {
  position: relative;
}

.timeline-axis {
  color: theme('colors.grey.400');
  font-size: theme('fontSize.sm');
}

.tick line {
  stroke: theme('colors.slate.DEFAULT');
  stroke-dasharray: 2px 4px;
}

.timeline-y2Axis .tick:last-child {
  display: none;
}

.guide {
  pointer-events: none;
  stroke-linecap: square;
}

.guide-vertical-top,
.guide-horizontal {
  stroke: theme('colors.acai.DEFAULT');
  stroke-width: 2px;
}

.guide-vertical-bottom {
  stroke: theme('colors.acai.200');
  stroke-width: 2px;
}

.timeline-bar-top {
  fill: theme('colors.blueberry.DEFAULT');
  shape-rendering: crispEdges;

  &:hover {
    cursor: pointer;
  }

  &.no-image {
    fill: theme('colors.slate.DEFAULT');
  }

  &.no-category {
    fill: theme('colors.apple.DEFAULT');
    stroke: theme('colors.acai.DEFAULT');
  }

  &.is-active {
    fill: theme('colors.lemon.DEFAULT');
  }
}

.timeline-bar-bottom {
  fill: theme('colors.blueberry.hsluv');
  shape-rendering: crispEdges;

  &:hover {
    cursor: pointer;
  }

  &.no-image {
    fill: theme('colors.slate.DEFAULT');
  }

  &.no-category {
    fill: theme('colors.apple.hsluv');
  }

  &.is-active {
    fill: theme('colors.lemon.100');
  }
}

.timeline-bar-highlight {
  fill: theme('colors.acai.200');
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &:hover {
    cursor: pointer;
  }

  .timeline-bar-g:hover & {
    opacity: 1;
  }
}

.timeline-action-area:hover {
  cursor: grab;
}

.timeline-clamp-bubble {
  fill: theme('colors.apple.DEFAULT');
  stroke: none;
}
</style>
