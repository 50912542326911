<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Breadcrumbs
      class="mx-4 ps-4"
      :history="[{ name: 'term-index', title: $t('taxman.baseTerms') }]"
    >
      <div
        v-test="'parent'"
        :class="['inline', 'has-dot', 'level-' + recordDetailLevelClass]"
      >
        {{ recordName }}
      </div>
    </Breadcrumbs>
    <Hero>{{ $t('navigation.taxonomy') }}</Hero>

    <FormTerm
      v-if="record !== null"
      :record="record"
      :parent-id="parentId"
      :is-editing="true"
    />
  </LayoutDefault>
</template>

<script>
import { store } from '@/store'
import { dasherize } from '@/utils/string'

import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'

import FormTerm from '@/components/FormTerm/FormTerm.vue'

const actualName = (item) => {
  return item.displayName || item.termName
}

export default {
  name: 'NewTerm',
  components: {
    LayoutDefault,
    Hero,
    Breadcrumbs,
    FormTerm,
  },

  beforeRouteEnter(to, from, next) {
    store.dispatch('taxonomy/getTermOptions')
    store
      .dispatch('taxonomy/terms/getById', to.params.termId)
      .then((term) => {
        next((vm) => {
          vm.record = term
          vm.parentId = term.parentId
        })
      })
      .catch(() => {
        this.toast.error(this.$t('toast.error.getData'))
      })
  },
  data() {
    return {
      record: null,
      parentId: null,
    }
  },

  computed: {
    recordName() {
      return this.record ? `${actualName(this.record)} (${this.record.code})` : ''
    },
    recordDetailLevelClass() {
      return this.record
        ? dasherize(store.getters['taxonomy/getDetailLevelById'](this.record.detailLevel))
        : ''
    },
  },
}
</script>
