export default function sendWasteTarget(data) {
  return {
    type: data.type,
    effectiveFromDate: data.effectiveFromDate,
    value: parseFloat(data.value),
    // optional fields
    targetEndDate: data.targetEndDate ? data.targetEndDate : null,
    description: data.description ? data.description : null,
  }
}
