<template>
  <tr
    :id="parentFoodItem.id"
    class="tr-link"
    :class="{ editing: isOpen }"
    data-test-conflict-row
    @click.stop="$emit('edit')"
  >
    <td
      class="lg-move-last td-small-col px-2 py-1"
      data-test-col-expand
    >
      <button
        v-tooltip="{
          theme: 'tooltip-neutral',
          content: $t('menus.foodItems.actions.edit'),
          placement: 'top',
        }"
        v-test="'edit-button'"
        :title="$t('menus.foodItems.actions.edit')"
        :aria-label="$t('menus.foodItems.actions.edit')"
        class="button button--icon p-0 -ml-1.5"
        @click.stop="$emit('edit')"
      >
        <transition
          name="rotate-fade"
          mode="out-in"
        >
          <SvgIcon
            v-if="!isOpen"
            key="edit"
            name="48-ic-expand"
            xl
          />
          <SvgIcon
            v-else
            key="close"
            name="48-ic-collapse"
            xl
          />
        </transition>
      </button>
    </td>
    <td data-test-col-name>
      <router-link
        :to="{
          name: 'food-editor',
          params: {
            foodItemId: foodItemId,
          },
          query: {
            menuId: menuId,
            returnPath: $route.path,
          },
        }"
      >
        {{ parentFoodItem.displayName }}
      </router-link>
    </td>
    <td data-test-col-mapping>
      <router-link
        :to="{
          name: 'food-item-mapping-facets',
          params: {
            foodItemId: foodItemId,
          },
          query: {
            menuId: menuId,
            returnPath: $route.path,
          },
        }"
      >
        {{ parentFoodItem.taxonomy.mapping }}
      </router-link>
    </td>
    <td data-test-col-children>{{ conflictingIds.length }}</td>
    <td
      v-if="!readonly"
      data-test-col-actions
      class="p-2"
    >
      <button
        type="button"
        class="button button--secondary show-on-hover-action"
        data-test-auto-fix
        @click.stop="appendDefaultCode"
      >
        Auto-fix
      </button>
    </td>
  </tr>
</template>

<script>
import { useToast } from 'vue-toastification'
import { mapGetters, mapState } from 'vuex'
import { joinFacetStrings } from '@/store/utils/generateInterpretedCode'
import { joinBaseTermWithFacets } from '@/store/utils/generateCodeString'
import ToastMessage from '@/components/ToastMessage.vue'
export default {
  props: ['foodItemId', 'conflictingIds', 'isOpen', 'menuId', 'readonly'],
  emits: ['edit', 'code-updated'],
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  computed: {
    ...mapGetters('menu-management/food-items', ['foodItemById']),
    ...mapState('menu-management/parent-child-conflicts', ['defaultFacet']),
    parentFoodItem() {
      return this.foodItemById(this.foodItemId)
    },
    conflicts() {
      return this.conflictingIds.map((id) => this.foodItemById(id))
    },
  },
  methods: {
    appendDefaultCode() {
      const foodItemId = this.parentFoodItem.id
      const item = this.parentFoodItem.name
      this.$store
        .dispatch('menu-management/food-items/saveItem', {
          menuId: this.menuId,
          itemId: this.parentFoodItem.id,
          data: {
            status: this.parentFoodItem.status,
            taxonomy: {
              code: joinBaseTermWithFacets(
                this.parentFoodItem.taxonomy.code,
                this.defaultFacet.code
              ),
              mapping: joinFacetStrings([
                this.parentFoodItem.taxonomy.mapping,
                this.defaultFacet.mapping,
              ]),
            },
          },
        })
        .then(() => {
          this.toast.success({
            component: ToastMessage,
            props: {
              message: this.$t('menus.foodItem.messages.parentChildFixSuccessful', { item }),
              targetRoute: {
                name: 'food-mapping-taxonomy',
                params: {
                  foodItemId,
                },
                query: {
                  ...this.$route.query,
                  returnPath: this.$route.path,
                  menuId: this.menuId,
                },
              },
            },
          })
          this.$emit('code-updated')
        })
        .then(
          this.$store.dispatch('menu-management/menus/checkMenuIssues', { menuId: this.menuId })
        )
        .catch(() => {
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
  },
}
</script>

<style></style>
