<template>
  <div
    class="image-uploader"
    data-test-image-uploader
  >
    <!-- Image browser -->
    <validation-group
      :validator="v$.file"
      class="formGroup"
    >
      <label for="Avatar">Upload a new image</label>
      <div class="image-uploader-avatar">
        <image-loader
          class="image-uploader-avatar-image"
          :src="fileUrl"
          :aspect-ratio="aspectRatio"
          data-test-image-uploader-preview
        />
        <file-upload
          id="Avatar"
          class="image-uploader-avatar-input"
          accept="image/jpeg, image/png"
          data-test-image-uploader-file-field
          @file-added="imageChanged"
        />
      </div>
    </validation-group>

    <!-- Source -->
    <validation-group
      :validator="v$.source"
      class="formGroup"
      v-slot="{ hasAnyErrors }"
    >
      <label for="imageSource">{{ $t('menus.tabletEditor.images.labels.source') }}</label>
      <input
        id="imageSource"
        v-model.trim="source"
        type="text"
        class="formControl"
        :class="{ 'is-invalid': hasAnyErrors }"
        data-test-image-uploader-source-field
      />
    </validation-group>

    <!-- Availability -->
    <Loaded-content
      class="formGroup"
      :is-loading="parentOrgLoadingStatus === 'LOADING'"
      :has-error="parentOrgLoadingStatus === 'ERROR'"
      :has-data="parentOrgLoadingStatus === 'LOADED'"
    >
      <fieldset class="flex gap-4 flex-wrap">
        <legend class="label font-bold w-full">
          {{ $t('menus.tabletEditor.images.labels.availability') }}
        </legend>
        <div class="customControl customControl--radio">
          <input
            id="imageAvailabilityAll"
            v-model="v$.availability.$model"
            type="radio"
            name="imageAvailability"
            class="customControl-input"
            value="SHARED"
            data-test-image-uploader-availability-radio
          />
          <label
            for="imageAvailabilityAll"
            class="customControl-label"
          >
            {{ $t('menus.tabletEditor.images.labels.allOrganisations') }}
          </label>
        </div>
        <div class="customControl customControl--radio">
          <input
            id="imageAvailabilityOrgOnly"
            v-model="v$.availability.$model"
            type="radio"
            name="imageAvailability"
            class="customControl-input"
            value="RESTRICTED"
            data-test-image-uploader-availability-radio
          />
          <label
            for="imageAvailabilityOrgOnly"
            class="customControl-label"
          >
            {{ $t('menus.tabletEditor.images.labels.onlyOrganisation') }}
          </label>
        </div>
      </fieldset>
    </Loaded-content>

    <!-- Type -->

    <fieldset class="formGroup flex flex-wrap gap-4">
      <legend class="label font-bold w-full">
        {{ $t('menus.tabletEditor.images.labels.type') }}
      </legend>
      <div class="customControl customControl--radio">
        <input
          id="imageTypePhoto"
          v-model="type"
          type="radio"
          name="imageType"
          class="customControl-input"
          value="photo"
        />
        <label
          for="imageTypePhoto"
          class="customControl-label"
          data-test-image-uploader-type-photo
        >
          {{ $t('menus.tabletEditor.images.labels.photo') }}
        </label>
      </div>
      <div class="customControl customControl--radio">
        <input
          id="imageTypeIcon"
          v-model="type"
          type="radio"
          name="imageType"
          class="customControl-input"
          value="icon"
        />
        <label
          for="imageTypeIcon"
          class="customControl-label"
          data-test-image-uploader-type-icon
        >
          {{ $t('menus.tabletEditor.images.labels.icon') }}
        </label>
      </div>
    </fieldset>
    <!-- Keywords -->
    <Image-keywords-editor
      v-model="v$.tags.$model"
      :allow-only-lower-case="true"
    />

    <!-- Actions -->
    <div class="buttonGroup">
      <ButtonSave
        type="button"
        :is-saving="status === 'SAVING'"
        data-test-image-uploader-save
        @click="onSave"
      >
        {{ $t('actions.saveNewImage') }}
      </ButtonSave>

      <button
        type="button"
        class="button button--secondary"
        :disabled="status === 'SAVING'"
        data-test-image-uploader-cancel
        @click="onCancel"
      >
        {{ $t('actions.cancel') }}
      </button>
    </div>
    <Validation-results
      ref="validation-results"
      :server-errors="serverErrors"
      :server-warnings="serverWarnings"
      class="mt-1"
      data-test-image-uploader-validation-results
    />
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload/FileUpload.vue'
import ImageLoader from '@/components/image-loader.vue'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ValidationResults from '@/components/Validation/ValidationResults.vue'
import ImageKeywordsEditor from './image-keywords-editor.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import { useToast } from 'vue-toastification'

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useValidation } from '@/composables/useValidation'
import ButtonSave from '@/components/ButtonSave.vue'

export default {
  components: {
    FileUpload,
    ImageLoader,
    ValidationGroup,
    ValidationResults,
    ImageKeywordsEditor,
    LoadedContent,
    ButtonSave,
  },
  props: {
    orgId: [String, Number],
    aspectRatio: {
      type: Number,
      default: 1,
    },
  },
  emits: ['close', 'new-file'],
  setup: () => {
    const { serverErrors, serverWarnings, handleValidationErrorsInResponse, clearServerMessages } =
      useValidation({
        errorStructure: ['GENERAL', 'image', 'PROCESSING_UPLOAD', 'orgRestrictedId'],
      })
    return {
      v$: useVuelidate(),
      toast: useToast(),
      serverErrors,
      serverWarnings,
      handleValidationErrorsInResponse,
      clearServerMessages,
    }
  },
  data() {
    return {
      source: '',
      type: 'photo',
      tags: [],
      newTag: '',
      fileUrl: '',
      file: null,
      status: 'IDLE',
      parentOrgLoadingStatus: 'IDLE',
      availability: 'SHARED',
    }
  },
  validations() {
    return {
      source: { required },
      newTag: { noDuplicateTag: this.noDuplicateTag },
      file: { imageRequired: required },
      tags: {},
      availability: {},
      type: {},
    }
  },
  computed: {
    parentOrganisation() {
      return this.$store.getters['hierarchy/nodes/byId'](this.orgId)
    },
  },
  created() {
    if (!this.parentOrganisation) {
      this.loadParentOrganisation().then(() => {
        this.setupAvailability()
      })
    } else {
      this.parentOrgLoadingStatus = 'LOADED'
      this.setupAvailability()
    }
  },
  methods: {
    loadParentOrganisation() {
      this.parentOrgLoadingStatus = 'LOADING'
      return this.$store
        .dispatch('hierarchy/nodes/getNode', this.orgId)
        .then(() => {
          this.parentOrgLoadingStatus = 'LOADED'
        })
        .catch(() => {
          this.parentOrgLoadingStatus = 'ERROR'
        })
    },
    setupAvailability() {
      if (this.parentOrganisation.locationDetails.sharedImageLibraryEnabled === false) {
        this.availability = 'RESTRICTED'
      } else {
        this.availability = 'SHARED'
      }
    },
    onCancel() {
      this.$emit('close')
    },
    onSave() {
      this.clearServerMessages()
      this.v$.$touch()
      if (this.v$.$invalid) return
      let { source, type, tags, file, availability } = this
      let imageMeta = JSON.stringify(tags.map((tag) => ({ property: 'keywordFull', value: tag })))
      let orgRestricted = availability === 'RESTRICTED'
      let data = { source, type, imageMeta, file, orgRestricted }
      if (orgRestricted) {
        data.orgRestrictedId = this.orgId
      }
      this.status = 'SAVING'
      this.$store
        .dispatch('menu-management/images/createImage', {
          data,
        })
        .then((newImage) => {
          this.status = 'SAVED'
          this.$emit('new-file', { id: newImage.id, ...data })
        })
        .catch((error) => {
          this.status = 'ERROR'
          this.handleValidationErrorsInResponse(error)
          if (error.status === 422) {
            this.$nextTick(() => {
              this.$refs['validation-results'].$el.focus()
            })
          }
        })
    },
    addTag(tag) {
      // @TODO: Check duplicate
      this.v$.newTag.$touch()
      this.v$.tags.$touch()
      if (this.v$.newTag.$invalid) return
      if (tag) {
        this.tags.push(tag)
        this.newTag = ''
      }
    },
    removeTagAtIndex(index) {
      this.tags.splice(index, 1)
    },
    imageChanged(file) {
      this.file = file
      this.fileUrl = URL.createObjectURL(file)
    },
    noDuplicateTag(tag) {
      return !this.tags.find((t) => t === tag)
    },
  },
}
</script>

<style lang="scss">
.image-uploader {
  .formControl {
    max-width: 18rem;
  }
}

.image-uploader-avatar {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-left: -1rem;
  margin-right: -1rem;

  > * {
    flex: 1 1 auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &-image {
    flex: 0 1 8rem;
  }
}
</style>
