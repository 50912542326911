import axios, { buildURL, spitURL } from '../api-axios'
import encode from '@/utils/strict-uri-encode'

export default {
  fetchAllWaste(query = {}) {
    return axios.get(spitURL('/waste-log', query))
  },
  fetchAllStages({ menuId, ...query }) {
    return axios.get(spitURL(`/menu-management/menu/${menuId}/stages`, query))
  },
  fetchPrevTransaction(id, query = {}) {
    return axios.get(spitURL(`/waste-log/${encode(id)}/previous`, query))
  },
  fetchAllCustom1(query = {}) {
    return axios.get(spitURL('/menu-management/custom-breakdowns', query))
  },
  fetchAllFood(query = {}) {
    return axios.get(spitURL('/menu-management/food-instances', query))
  },
  putWasteLog(item, query = {}) {
    return axios.put(spitURL(`/waste-log/${encode(item.new.id)}`, query), item)
  },
  deleteWasteLog(id) {
    return axios.delete(`/waste-log/${encode(id)}`)
  },
  deleteWasteLogsEvents(items) {
    return axios.post('/waste-log/batch/soft-delete', items)
  },
  downloadWasteLog(query = {}) {
    return axios({ url: spitURL('/waste-log/export', query), responseType: 'text' })
  },
  getImage(id, imageId, query = {}, cancelToken) {
    return axios
      .get(buildURL(`/waste-log/${encode(id)}/image/${encode(imageId)}`, query), {
        Accept: 'image',
        responseType: 'arraybuffer',
        cancelToken,
      })
      .then((response) => {
        return new Blob([response.data], {
          type: 'image',
        })
      })
      .catch((err) => {
        throw err
      })
  },

  fetchFilterFoodItems(query = {}) {
    return axios.get(spitURL('/waste-log/food-items', query))
  },
  fetchFilterStages(query = {}) {
    return axios.get(spitURL('/waste-log/stages', query))
  },
  fetchFilterTrackers(query = {}) {
    return axios.get(spitURL('/waste-log/trackers', query))
  },
  fetchTransactionHistory(id, query = {}) {
    return axios.get(spitURL(`waste-log/${encode(id)}/audit`, query))
  },
}
