<template>
  <collapsible-section
    data-test-transactions-extract-reports-section
    :force-open="preOpen"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('organisations.subscribers.transactionsExtract.title') }}
    </template>

    <template #header-side="{ isOpen }">
      <div
        v-if="isOpen"
        class="flex flex-right flex-expand"
      >
        {{ $t('organisations.subscribers.transactionsExtract.subTitle') }}
      </div>
    </template>

    <loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED'"
    >
      <form
        action="/"
        @submit.prevent="storeConfigs ? saveChanges() : createConfig()"
      >
        <!-- Report types -->
        <div class="formGroup configSendingWrapper grid-layout m-0">
          <div
            v-for="{ key, labelKey, labelSubtitleKey } in reportTypes"
            :key="key"
            class="grid-unit"
          >
            <div class="customControl customControl--checkbox">
              <input
                v-bind="{ [`data-test-config-field-transaction-extract-${key}`]: '' }"
                :id="`config-field-transaction-extract-${key}`"
                v-model="buffer[key]"
                type="checkbox"
                class="customControl-input"
              />
              <label
                class="customControl-label"
                :for="`config-field-transaction-extract-${key}`"
              >
                <span class="d-block font-bold">
                  {{ $t(labelKey) }}
                </span>
                <span class="text-sm">
                  {{ $t(labelSubtitleKey) }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <!-- Week ending -->
        <ValidationGroup
          :validator="v$.buffer.weekEnding"
          class="formGroup d-inline-block"
          v-slot="{ hasAnyErrors }"
        >
          <label for="config-field-transaction-extract-week-ending">{{
            $t('organisations.configurations.weekEndingLabel')
          }}</label>
          <select
            id="config-field-transaction-extract-week-ending"
            v-model="v$.buffer.weekEnding.$model"
            data-test-config-field-transaction-extract-week-ending
            class="customSelect"
            :class="{ 'is-invalid': hasAnyErrors }"
          >
            <option
              :value="null"
              disabled
            >
              {{ $t('organisations.configurations.weekEndingDefaultOpt') }}
            </option>
            <option
              v-for="{ value, label } in weekEndingOptions"
              :key="value"
              :value="value"
            >
              {{ label }}
            </option>
          </select>
        </ValidationGroup>

        <!-- Buttons Bar-->
        <div class="buttonGroup flex-between mt-8">
          <ButtonSave
            data-test-configs-submit-btn
            :disabled="!hasChanges || v$.$anyError || savingStatus === 'SAVING'"
            :is-saving="savingStatus === 'SAVING'"
          >
            {{ $t('actions.saveChanges') }}
          </ButtonSave>

          <router-link
            class="button button--link"
            :to="{
              name: 'hierarchy-node-reporting-subscribers',
              query: { ...$route.query, openItems: 'SUBSCRIBERS-EXTRACT' },
            }"
          >
            <SvgIcon name="24-ic-users" />
            <span>{{ $t('organisations.configurations.subscribersButton') }}</span>
          </router-link>
        </div>
      </form>
    </loaded-content>
  </collapsible-section>
</template>
<script>
import { useToast } from 'vue-toastification'
import { isEqual } from 'lodash'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import CollapsibleSection from '@/components/CollapsibleSection.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import moment from 'moment'
import ValidationGroup from '@/components/Validation/ValidationGroup.vue'
import ButtonSave from '@/components/ButtonSave.vue'

function Model(overwrites) {
  return {
    sendIkeaDaily: false,
    sendDaily: false,
    sendWeekly: false,
    sendMonthly: false,
    weekEnding: null,
    ...overwrites,
  }
}

export default {
  components: {
    CollapsibleSection,
    LoadedContent,
    ValidationGroup,
    ButtonSave,
  },
  props: {
    locationId: String,
    preOpen: Boolean,
  },
  validations() {
    return {
      buffer: {
        weekEnding: {
          required,
        },
      },
    }
  },
  emits: ['open', 'close'],
  setup: () => {
    return {
      v$: useVuelidate(),
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      savingStatus: 'IDLE',
      buffer: {},
    }
  },
  computed: {
    storeConfigs() {
      return this.$store.getters['hierarchy/nodes/byId'](this.locationId).transactionsExtractConfigs
    },
    hasChanges() {
      return !isEqual(new Model(this.storeConfigs), this.buffer)
    },
    reportTypes() {
      return [
        {
          key: 'sendDaily',
          labelKey: 'organisations.configurations.dailyReports.dailyLabel',
          labelSubtitleKey: `organisations.configurations.dailyReports.dailyLabelSubtitle.${
            this.buffer.sendDaily ? 'enabled' : 'disabled'
          }`,
        },
        {
          key: 'sendIkeaDaily',
          labelKey: 'organisations.configurations.dailyReports.ikeaDailyLabel',
          labelSubtitleKey: `organisations.configurations.dailyReports.ikeaDailyLabelSubtitle.${
            this.buffer.sendIkeaDaily ? 'enabled' : 'disabled'
          }`,
        },
        {
          key: 'sendWeekly',
          labelKey: 'organisations.configurations.summaryReports.weeklyLabel',
          labelSubtitleKey: `organisations.configurations.summaryReports.weeklyLabelSubtitle.${
            this.buffer.sendWeekly ? 'enabled' : 'disabled'
          }`,
        },
        {
          key: 'sendMonthly',
          labelKey: 'organisations.configurations.summaryReports.monthlyLabel',
          labelSubtitleKey: `organisations.configurations.summaryReports.monthlyLabelSubtitle.${
            this.buffer.sendMonthly ? 'enabled' : 'disabled'
          }`,
        },
      ]
    },
    weekEndingOptions() {
      return ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'].map(
        (value, index) => ({
          value,
          label: moment.weekdays(index),
        })
      )
    },
  },
  created() {
    this.loadingStatus = 'LOADING'

    this.$store
      .dispatch('hierarchy/nodes/getNodeTransactionsExtractConfigs', this.locationId)
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.init()
      })
      .catch((e) => {
        this.loadingStatus = 'ERROR'
        this.toast.error(this.$t('toast.error.getData'))
        throw e
      })
  },
  methods: {
    init() {
      this.buffer = new Model(this.storeConfigs)
    },
    createConfig() {
      this.v$.buffer.$touch()

      if (this.v$.$invalid) {
        return
      }
      this.savingStatus = 'SAVING'

      this.$store
        .dispatch('hierarchy/nodes/createNodeTransactionsExtractConfigs', {
          nodeId: this.locationId,
          payload: this.buffer,
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((e) => {
          this.savingStatus = 'ERROR'

          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
        .finally(() => {
          this.init()
        })
    },
    saveChanges() {
      this.savingStatus = 'SAVING'

      const changes = Object.entries(this.buffer).reduce(
        (acc, [key, value]) =>
          Object.assign(acc, this.storeConfigs[key] === this.buffer[key] ? {} : { [key]: value }),
        {}
      )

      this.$store
        .dispatch('hierarchy/nodes/saveNodeTransactionsExtractConfigs', {
          nodeId: this.locationId,
          payload: changes,
        })
        .then(() => {
          this.toast.success(this.$t('toast.success.generic'))
          this.savingStatus = 'SAVED'
        })
        .catch((e) => {
          this.savingStatus = 'ERROR'

          this.toast.error(this.$t('toast.error.saveData'))
          throw e
        })
        .finally(() => {
          this.init()
        })
    },
  },
}
</script>
<style lang="scss">
.configSendingWrapper {
  border: solid 1px theme ('colors.slate.DEFAULT');
}
</style>
