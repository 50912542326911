<template>
  <LayoutDefault
    extended-content
    class="pt-8"
  >
    <Loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED' && !!menu"
    >
      <Breadcrumbs
        class="mx-4 ps-4"
        :history="[{ name: 'menus', title: $t('navigation.menus') }]"
        :current="$route.meta.breadcrumb"
      />
      <Hero v-if="menu">
        <template #default>{{ menu.name }}</template>
        <template #hero-aside>
          <div class="text-right">
            <a
              v-if="$can('manage', 'menus')"
              v-tooltip="{
                content: $t('messages.menuManagementHelp'),
                theme: 'tooltip-white',
              }"
              href="https://winnow.atlassian.net/wiki/spaces/HELP/pages/1508737153/Menus"
              target="_blank"
              class="button button--link"
            >
              <span>{{ $t('navigation.menuManagementHelp') }}</span>
              <SvgIcon
                class="iconCircle"
                name="48-menu-help"
                lg
              />
            </a>
          </div>
        </template>
        <template #hero-content>
          <div
            class="alert alert--warning mb-4"
            v-if="showProgress && !menuStatesLoading && $route.name !== 'menu-summary'"
          >
            <SvgIcon name="24-ic-warning"></SvgIcon>
            <span>{{ $t('menus.summary.messages.noEditing') }}</span>
          </div>
        </template>
      </Hero>
      <div class="items-end mx-4 grid grid-flow-col auto-cols-auto">
        <!-- Tab navigation -->
        <TabsLvl1Links
          v-if="navigationVisible"
          data-test-tabs
        >
          <!-- Summary -->
          <li>
            <router-link
              :to="{ name: 'menu-summary' }"
              class="tab-item"
              data-test-link-summary
            >
              <span>{{ $t('menus.tabs.summary') }}</span>
            </router-link>
          </li>
          <!-- Food items -->
          <li v-if="!menuItemsNotAvailable">
            <router-link
              :to="{ name: 'food-items' }"
              class="tab-item"
              data-test-link-items
            >
              <span>{{ $t('menus.tabs.foodItems') }}</span>
            </router-link>
          </li>
          <!-- Issues -->
          <li v-if="!menuItemsNotAvailable && $can('manage', 'menus')">
            <router-link
              :to="{ name: 'menu-issues' }"
              class="tab-item"
              data-test-link-issues
              :class="{ 'tab-item--danger': hasIssues }"
            >
              <span>{{ $t('menus.tabs.menuIssues') }}</span>
              <span
                v-if="hasIssues"
                class="tab-warning"
                aria-role="status"
              >
                Has issues
              </span>
            </router-link>
          </li>
          <!-- Tablet editor / on device -->
          <li v-if="!menuItemsNotAvailable">
            <router-link
              :to="{ name: 'tablet-editor' }"
              class="tab-item"
              data-test-link-tablet-editor
            >
              <span>{{ $t('menus.tabs.tabletEditor') }}</span>
            </router-link>
          </li>
          <!-- Menu deploy -->
          <li v-if="!menuItemsNotAvailable && $can('manage', 'menus')">
            <router-link
              :to="{ name: 'menu-deploy' }"
              class="tab-item"
              data-test-link-deploy
            >
              <span>{{ $t('menus.tabs.menuDeploy') }}</span>
            </router-link>
          </li>
          <!-- Bulk update -->
          <li v-if="!menuItemsNotAvailable && $can('manage', 'menus')">
            <router-link
              :to="{ name: 'menu-bulk-update' }"
              class="tab-item"
              data-test-link-bulk-update
            >
              <span>{{ $t('menus.tabs.bulkUpdate') }}</span>
            </router-link>
          </li>
          <!-- Cost backdate -->
          <li v-if="!menuItemsNotAvailable && $can('manage', 'menus')">
            <router-link
              :to="{ name: 'backdate' }"
              class="tab-item"
              data-test-link-backdate
            >
              <span>{{ $t('menus.tabs.backdate') }}</span>
            </router-link>
          </li>
        </TabsLvl1Links>
        <!-- Language selector -->
        <div
          v-if="isLanguageSelectorVisible"
          class="formGroup justify-self-end"
        >
          <label
            for="LanguageSelector"
            class="sr-only"
            >{{ $t('menus.label.changeLanguage') }}</label
          >
          <select
            id="LanguageSelector"
            class="customSelect"
            :value="displayLanguage"
            data-test-menu-language-selector
            @change="saveLanguageSettings($event.target.value)"
          >
            <option :value="EN_LOCALE">English</option>
            <option :value="menu.locale.code">{{ menu.locale.name }}</option>
          </select>
        </div>
      </div>
      <router-view
        v-if="loadingStatus === 'LOADED'"
        :key="$route.path"
        :loading-status="reloadingStatus"
      />
    </Loaded-content>
  </LayoutDefault>
</template>
<script>
import LoadedContent from '@/components/LoadedContent.vue'
import LayoutDefault from '@/layouts/LayoutDefault/LayoutDefault.vue'
import Hero from '@/components/Hero/Hero.vue'
import Breadcrumbs from '@/components/Breadcrumbs/Breadcrumbs.vue'
import menuMixin from './menuMixin'
import { MENU_STATES, MENU_STATE_POLLING_INTERVAL } from '../../store/constants'
import { useToast } from 'vue-toastification'
import TabsLvl1Links from '@/components/Tabs/TabsLvl1Links.vue'
export default {
  name: 'MenuPage',
  components: {
    LoadedContent,
    LayoutDefault,
    Hero,
    Breadcrumbs,
    TabsLvl1Links,
  },
  mixins: [menuMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.menu && !vm.displayLanguage) {
        vm.setupLanguage()
      }
    })
  },
  beforeRouteUpdate(to) {
    this.$nextTick().then(() => {
      this.checkMenuState()
      if (to.name === 'menu-summary') {
        this.reloadingStatus = 'LOADING'
        this.getMenu().then(() => {
          this.reloadingStatus = 'LOADED'
        })
      }
    })
  },
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      loadingStatus: 'IDLE',
      reloadingStatus: 'IDLE',
      showProgress: false,
      menuStatesLoading: false,
    }
  },
  computed: {
    statistics() {
      return this.$store.getters['menu-management/menus/menuStatistics'](this.menuId)
    },
    hasIssues() {
      if (this.statistics) return this.statistics.hasIssues
      else return false
    },
    navigationVisible() {
      if (this.$route.name === 'translate-menu') return false
      return true
    },
    isLanguageSelectorVisible() {
      if (this.$route.name === 'translate-menu') return false
      if (this.$route.matched.find((route) => route.name === 'menu-bulk-update')) return false
      if (this.$route.matched.find((route) => route.name === 'backdate')) return false
      if (this.menuIsNonEnglish) return true
      else return false
    },
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    menuProcessing() {
      let activeTask = this.menu.activeTask
      let taskOrder = this.activeState.order

      // processing gets paused on the translate page so that users can review the translations
      if (activeTask === MENU_STATES.TRANSLATE) {
        return false
      }

      // processing is ongoing while the order is above 0 because negative orders are the errors
      if (taskOrder >= 0 && activeTask !== MENU_STATES.PROCESSED) {
        return true
      }

      if (taskOrder < 0) {
        return true
      }

      return false
    },
  },
  created() {
    this.$store.commit('menu-management/SET_MENU_ID', this.menuId)
    this.loadingStatus = 'LOADING'
    this.getMenu()
      .then(this.getExternalSystems)
      .then(this.setupLanguage)
      .then(this.checkMenuIssues)
      .then(() => {
        this.loadingStatus = 'LOADED'
        this.reloadingStatus = 'LOADED'
        this.checkMenuState()
      })
      .catch(() => {
        this.loadingStatus = 'ERROR'
      })
  },
  unmounted() {
    window.clearTimeout(window.statePolling)
  },
  methods: {
    getMenu() {
      return this.$store
        .dispatch('menu-management/menus/getMenu', { menuId: this.menuId })
        .then(({ menu, organisation }) => {
          this.$store.commit('menu-management/organisations/UPDATE_ORGANISATION', organisation)
          return { menu, organisation }
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
          this.loadingStatus = 'ERROR'
        })
    },
    getProgress() {
      return this.$store
        .dispatch('menu-management/menus/getProgress', { menuId: this.menuId })
        .then(() => {
          this.menuStatesLoading = false
          window.statePolling = window.setTimeout(this.checkMenuState, MENU_STATE_POLLING_INTERVAL)
        })
        .catch(() => {
          this.toast.error(this.$t('toast.error.getData'))
        })
    },
    checkMenuState() {
      window.clearTimeout(window.statePolling)
      if (this.menuProcessing) {
        this.showProgress = true
        return this.getProgress()
      } else if (this.menu.activeTask === MENU_STATES.TRANSLATE) {
        this.showProgress = false
        return Promise.resolve()
      } else {
        this.showProgress = false
        return this.checkMenuIssues()
      }
    },
    checkMenuIssues() {
      // only check for menu issues when not on the issues tab. otherwise we duplicate the request
      if (this.$route.matched.map(({ name }) => name).indexOf('menu-issues') == -1) {
        return this.$store.dispatch('menu-management/menus/checkMenuIssues', {
          menuId: this.menuId,
        })
      } else {
        return Promise.resolve()
      }
    },
    setupLanguage() {
      return new Promise((resolve) => {
        if (this.menuIsNonEnglish && !this.displayLanguage) {
          this.saveLanguageSettings(this.menu.locale.code)
        }
        resolve()
      })
    },
    getExternalSystems({ menu }) {
      return this.$store.dispatch('menu-management/menus/getExternalSystems', {
        organisationId: menu.organisation.id,
      })
    },
    saveLanguageSettings(code = false) {
      this.$store.commit('menu-management/menus/UPDATE_RECORD_SETTINGS', {
        id: this.menuId,
        displayLanguage: code,
      })
    },
  },
}
</script>
