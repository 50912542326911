import Tracker from '../../models/tracker'
export default function (tracker) {
  return new Tracker({
    id: tracker.id,
    name: tracker.name,
    created: tracker.created,
    updated: tracker.updated,
    lastPing: tracker.lastPing,
    timezone: tracker.timezone,
    currency: tracker.currency,
    organisationId: tracker.site ? tracker.site.id : null,
    activeMenuId: tracker.activeMenu
      ? tracker.activeMenu.id
        ? tracker.activeMenu.id
        : // identify and isolate old menus
          'OLD_MENU'
      : null,
    visionEnabled: tracker.activeMenu ? tracker.activeMenu.vision : null,
  })
}
