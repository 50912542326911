<template>
  <div
    v-test="'pagination'"
    class="pagination"
  >
    <nav class="pagination-container buttonGroup">
      <router-link
        v-if="currentPage > 1"
        v-test="'pagination-previous'"
        class="pagination-prev button button--icon gap-0"
        :to="{
          name: previousPage.routeName,
          params: previousPage.params,
          query: previousPage.query,
        }"
      >
        <SvgIcon name="24-ic-arrow-prev" />
        <span>{{ $t('pagination.previous') }}</span>
      </router-link>

      <form
        class="formGroup formGroup--inline pagination-current"
        novalidate
        @submit.prevent="changePage"
      >
        <label for="pagination-input">{{ $t('pagination.page') }}</label>
        <input
          id="pagination-input"
          v-model.lazy="currentPage"
          v-test="'pagination-input'"
          type="text"
          class="formControl"
          @blur="onBlur"
        />
        <span>{{ $t('pagination.ofValue', { value: totalPages }) }}</span>
      </form>

      <router-link
        v-if="currentPage < totalPages"
        v-test="'pagination-next'"
        data-test-next-page
        class="pagination-next button button--icon gap-0"
        :to="{ name: nextPage.routeName, params: nextPage.params, query: nextPage.query }"
      >
        <span>{{ $t('pagination.next') }}</span>
        <SvgIcon name="24-ic-arrow-next" />
      </router-link>
    </nav>
    <p class="pagination-helper">{{ $t('pagination.resultsPerPage', { number: size }) }}</p>
  </div>
</template>
<script>
export default {
  name: 'HubPagination',
  props: {
    total: Number,
    size: Number,
    initPage: Number,
  },
  data() {
    return {
      currentPage: 1,
      lastValidPage: 1,
    }
  },
  computed: {
    // props for routing the previous page button
    previousPage() {
      return {
        routeName: this.$route.link,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          page: this.currentPage - 1,
        },
      }
    },
    // props for routing the next page button
    nextPage() {
      return {
        routeName: this.$route.link,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          page: this.currentPage + 1,
        },
      }
    },
    // calculate the number of available pages based on the number of total records
    // and the size of each response
    totalPages() {
      return Math.ceil(this.total / this.size)
    },
  },
  watch: {
    // if we get a page as prop, set it as the current page
    initPage(val) {
      this.currentPage = parseInt(val, 10)
    },
  },
  beforeMount() {
    let queryPage = this.$route.query.page
    let queryPageNum = parseInt(queryPage, 10)
    // check if the page in the url query is valid
    if (this.isPageNumberValid(queryPage)) {
      // start using the pagination with the current page
      this.currentPage = queryPageNum
    } else if (queryPage && queryPageNum > this.totalPages) {
      // go to the last page if accessed page is bigger than the total number of pages
      this.goToPage(this.totalPages)
    } else if (queryPage) {
      // go to the first page if the page is invalid
      this.goToPage(1)
    }
    // don't do anything if there isn't a page in the url
  },
  beforeUnmount() {
    // clear the timeout created for the blur event
    window.clearTimeout(this.blurTimeout)
  },
  methods: {
    // navigate to another page or reset the page value if an invalid number was received
    changePage() {
      if (this.isPageNumberValid(this.currentPage)) {
        this.goToPage(this.currentPage)
      } else {
        this.currentPage = this.lastValidPage
      }
    },
    // reset the page input field if the user leaves it
    onBlur() {
      this.blurTimeout = window.setTimeout(() => {
        // put this in a timeout so that it always executes after
        // a potential submit event. Otherwise, this might change
        // the currentPage value back before the submit can navigate
        // to the new page
        this.currentPage = parseInt(this.$route.query.page || 1, 10)
      })
    },
    // check if a page number is between the first and last page
    isPageNumberValid(val) {
      let num = parseInt(val, 10)
      return !!(num >= 1 && num <= this.totalPages)
    },
    // navigate to a new page
    goToPage(val) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: {
          ...this.$route.query,
          page: val,
        },
      })
      this.lastValidPage = val
    },
  },
}
</script>
