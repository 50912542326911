<template>
  <th
    scope="col"
    role="columnheader"
    :aria-sort="sorted"
  >
    <button-sort
      :sorted="sorted"
      @sorted="$emit('sorted', $event)"
      ><slot></slot
    ></button-sort>
  </th>
</template>

<script>
import ButtonSort from '@/components/ButtonSort/ButtonSort.vue'
export default {
  components: {
    ButtonSort,
  },
  props: ['sorted'],
  emits: ['sorted'],
}
</script>
