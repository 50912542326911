<template>
  <div>
    <configurations-daily-reports
      class="collapsible-section--first"
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('CONFIGS-DAILY')"
      @open="openSection('CONFIGS-DAILY')"
      @close="closeSection('CONFIGS-DAILY')"
    />
    <configurations-summary-reports
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('CONFIGS-SUMMARY')"
      @open="openSection('CONFIGS-SUMMARY')"
      @close="closeSection('CONFIGS-SUMMARY')"
    />
    <configurations-transactions-extract
      :location-id="nodeId"
      tag="section"
      :pre-open="openItems.includes('CONFIGS-TRANSACTIONS-EXTRACT')"
      @open="openSection('CONFIGS-TRANSACTIONS-EXTRACT')"
      @close="closeSection('CONFIGS-TRANSACTIONS-EXTRACT')"
    />
  </div>
</template>
<script>
import openSectionsMixin from '@/mixins/openSectionsMixin'
import ConfigurationsDailyReports from './ReportingConfigurations/ConfigurationsDailyReports.vue'
import ConfigurationsSummaryReports from './ReportingConfigurations/ConfigurationsSummaryReports.vue'
import ConfigurationsTransactionsExtract from './ReportingConfigurations/ConfigurationsTransactionsExtract.vue'

export default {
  components: {
    ConfigurationsDailyReports,
    ConfigurationsSummaryReports,
    ConfigurationsTransactionsExtract,
  },
  mixins: [openSectionsMixin],
  beforeRouteUpdate(to, from, next) {
    const { level } = this.$store.getters['hierarchy/nodes/byId'](to.params.nodeId)

    if (level !== 16) {
      next({ ...to, name: 'hierarchy-node-reporting' })
    }
    next()
  },
  props: {
    nodeId: String,
  },
}
</script>
