<template>
  <page-section>
    <section-header>
      <template #section-title>
        {{ $t('menus.summary.title') }}
      </template>
    </section-header>
    <Loaded-content
      :is-loading="loadingStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED' && !!menu"
    >
      <div class="box--with-clear">
        <template v-if="menu.activeTask">
          <p
            v-if="menuIsNotEditable"
            class="mb-2"
            data-test-menu-processing
          >
            <strong>
              {{ $t('menus.summary.messages.processing') }}
            </strong>
          </p>

          <!-- Processing menu alert -->
          <p
            v-if="menuIsNotEditable"
            class="mb-4 alert alert--warning"
            data-test-menu-processing-alert
          >
            <SvgIcon name="24-ic-info" />
            {{
              $t('menus.summary.messages.noEditingWithProgress', {
                currentStep: activeState.processStep,
                totalSteps: pendingMenuStates.length,
              })
            }}
          </p>

          <!-- Processing done -->
          <p
            v-if="isProcessing && !menuIsNotEditable && !menuProcessingPaused"
            class="mb-4 alert alert--success"
            data-test-menu-processing-alert
          >
            <SvgIcon name="24-ic-check" />
            {{
              $t('menus.summary.messages.processingDone', {
                currentStep: activeState.processStep,
                totalSteps: pendingMenuStates.length,
              })
            }}
          </p>

          <!-- Processing paused -->
          <template v-if="menuProcessingPaused">
            <p class="alert alert--warning mb-2">
              {{ $t('menus.summary.messages.translationsPending') }}
            </p>
            <router-link
              class="button button--primary mb-4"
              :to="{ name: 'translate-menu' }"
            >
              {{ $t('menus.summary.actions.translate') }}
            </router-link>
          </template>

          <Progress-list
            v-if="isProcessing || menuProcessingPaused"
            :states="pendingMenuStates"
            :error-states="errorStates"
            :in-progress="menu.activeTask"
            :paused="menuProcessingPaused"
            class="menu-summary-progress"
            data-test-menu-progress
          />
        </template>
        <div
          v-if="menu.archived"
          class="alert alert--warning mb-4"
          data-test-archived-message
        >
          <p>
            <SvgIcon name="24-ic-info" />
            {{ $t('menus.summary.messages.deactivated') }}
            <ButtonWithSpinner
              class="button--link"
              data-test-activate-button
              @click="unarchiveMenu"
              :in-progress="savingStatus === 'SAVING'"
            >
              {{ $t('actions.reactivate') }}
            </ButtonWithSpinner>
          </p>
        </div>
        <table class="table table--key-value-pairs mb-4">
          <tbody>
            <tr data-test-name>
              <th>{{ $t('menus.list.name') }}</th>
              <td>{{ menu.name }}</td>
            </tr>
            <tr
              v-if="menu.description"
              data-test-description
            >
              <th>{{ $t('menus.list.description') }}</th>
              <td>{{ menu.description }}</td>
            </tr>
            <tr data-test-organisation>
              <th>{{ $t('menus.list.organisation') }}</th>
              <td>{{ menu.organisation.fullName }}</td>
            </tr>
            <tr data-test-created>
              <th>{{ $t('menus.list.created') }}</th>
              <td>
                {{ formatDate(menu.created) }}
                <template v-if="menu.originalAuthor && $can('manage', 'menus')">
                  by {{ menu.originalAuthor }}
                </template>
              </td>
            </tr>
            <tr data-test-updated>
              <th>{{ $t('menus.list.updated') }}</th>
              <td>
                {{ formatDate(menu.modified) }}
                <template v-if="menu.lastUpdateAuthor && $can('manage', 'menus')">
                  by {{ menu.lastUpdateAuthor }}
                </template>
              </td>
            </tr>
            <tr
              v-if="menu.deployed"
              data-test-deployed
            >
              <th>{{ $t('menus.list.deployed') }}</th>
              <td>{{ formatDate(menu.deployed) }}</td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="downloadVisible"
          class="my-4"
        >
          <ButtonWithSpinner
            class="button--primary"
            :disabled="downloadDisabled"
            data-test-export-button
            @click="download"
            spinner-class="spinner--white"
            :in-progress="downloadStatus === 'IN_PROGRESS'"
          >
            {{ $t('menus.actions.download') }}
          </ButtonWithSpinner>
        </div>
        <div>
          <delete-with-prompt
            v-if="archiveVisible"
            :disabled="archiveDisabled"
            data-test-deactivate
            @confirm="archiveMenu"
            ref="delete"
          >
            <template #delete-button>
              <span
                v-tooltip="{
                  placement: 'top',
                  content: menu.numberOfActiveTrackers
                    ? $t('menus.summary.messages.archiveDisabledWithTrackers')
                    : '',
                }"
                data-test-delete-label
                >{{ $t('actions.deactivate') }}</span
              >
            </template>
            <template #default>
              {{ $t('menus.summary.messages.confirmDeactivation') }}
            </template>
          </delete-with-prompt>
        </div>
      </div>
    </Loaded-content>
  </page-section>
</template>
<script>
import LoadedContent from '@/components/LoadedContent.vue'
import ProgressList from '@/components/ProgressList.vue'
import DeleteWithPrompt from '@/components/DeleteWithPrompt.vue'
import menuMixin from './menuMixin'
import moment from 'moment'
import { DATE_FORMAT } from '@/store/constants'
import { useToast } from 'vue-toastification'
import ButtonWithSpinner from '@/components/ButtonWithSpinner.vue'
export default {
  components: {
    LoadedContent,
    ProgressList,
    DeleteWithPrompt,
    ButtonWithSpinner,
  },
  mixins: [menuMixin],
  props: {
    menuId: String,
    loadingStatus: {
      type: String,
      default: 'LOADED',
    },
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      downloadStatus: 'IDLE',
      isProcessing: false,
      statePolling: null,
      savingStatus: 'IDLE',
    }
  },
  computed: {
    menu() {
      return this.$store.getters['menu-management/menus/menuById'](this.menuId)
    },
    downloadDisabled() {
      if (this.downloadStatus === 'IN_PROGRESS') return true
      if (this.menuIsNotEditable) return true
      return false
    },
    downloadVisible() {
      if (this.$can('manage', 'menus')) return true
      return false
    },
    archiveDisabled() {
      if (this.menuIsNotEditable) return true
      if (this.menu && this.menu.numberOfActiveTrackers) return true
      return false
    },
    archiveVisible() {
      let isVisible = false
      isVisible = this.$can('manage', 'menus')
      isVisible = isVisible && !this.menu.archived
      return isVisible
    },
  },
  created() {
    if (this.menuIsNotEditable) {
      this.isProcessing = true
    }
  },
  methods: {
    download() {
      this.downloadStatus = 'IN_PROGRESS'
      this.$store
        .dispatch('menu-management/menus/downloadMenu', { menuId: this.menuId })
        .then(() => {
          this.downloadStatus = 'DONE'
        })
        .catch(() => {
          this.toast.error(this.$t('menus.summary.error.download'))
          this.downloadStatus = 'ERROR'
        })
    },
    archiveMenu() {
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('menu-management/menus/updateMenu', {
          menuId: this.menu.id,
          data: { archived: true },
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    unarchiveMenu() {
      this.savingStatus = 'SAVING'
      this.$store
        .dispatch('menu-management/menus/updateMenu', {
          menuId: this.menu.id,
          data: { archived: false },
        })
        .then(() => {
          this.savingStatus = 'SAVED'
          this.toast.success(this.$t('toast.success.generic'))
        })
        .catch(() => {
          this.savingStatus = 'ERROR'
          this.toast.error(this.$t('toast.error.saveData'))
        })
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT)
    },
  },
}
</script>

<style lang="scss">
.menu-summary-progress {
  margin-top: theme('spacing.2');
  margin-bottom: theme('spacing.4');
  padding-bottom: theme('spacing.4');
  border-bottom: 1px solid theme('colors.slate.DEFAULT');
  @include respond-min(medium) {
    float: right;
    margin-left: theme('spacing.4');
    padding-left: theme('spacing.4');
    border-bottom: 0;
    border-left: 1px solid theme('colors.slate.DEFAULT');
  }
}
</style>
