<template>
  <div>
    <slot :is-loading="isLoading" />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSelectedSite } from '@/composables/useSelectedSite'

const { dispatch, state, getters } = useStore()
const isLoading = computed(() => state['covers-sales'].currentWeek.loading)
const toast = useToast()
const { t } = useI18n()
const { selectedSite } = useSelectedSite({ userId: getters['auth/userId'] })
watch(
  selectedSite,
  (hasSelectedSite) => {
    if (hasSelectedSite) {
      dispatch('covers-sales/getCurrentWeek', { legacyId: selectedSite.value.legacyId }).catch(
        () => {
          toast.error(t('toast.error.getData'))
        }
      )
    }
  },
  { immediate: true }
)
</script>
