<template>
  <page-section :is-loading="foodGroupsLoadingStatus === 'LOADING'">
    <section-header>
      <template #section-title>
        {{ $t('menus.foodItem.title.AddEditFoodGroups') }}
      </template>
      <template
        v-if="menuId && foodItem.id"
        #section-navigation
      >
        <button
          type="button"
          class="button button--secondary"
          data-test-return-link
          @click="goBack({ hash: foodItem.id })"
        >
          <SvgIcon name="24-ic-back" />
          <span>{{ $t('actions.return') }}</span>
        </button>
      </template>
    </section-header>
    <loaded-content
      :is-loading="foodGroupsLoadingStatus === 'LOADING'"
      :has-error="foodGroupsLoadingStatus === 'ERROR'"
      :has-data="foodGroupsLoadingStatus === 'LOADED'"
    >
      <form
        action="/"
        @submit.prevent="onSave"
        @reset.prevent="onReset"
      >
        <!-- Food Group selector -->
        <output class="formGroup">
          <p
            v-if="selected.length"
            class="my-2"
          >
            {{ $t('menus.foodItem.messages.selectedFoodGroups') }}
          </p>
          <p
            v-else
            data-test-empty-placeholder
            class="alert alert--neutral"
          >
            <SvgIcon name="24-ic-info" />
            <span>{{ $t('menus.foodItem.messages.noSelectedFoodGroups') }}</span>
          </p>
          <ul
            v-if="selected.length"
            class="chip-container"
            data-test-food-groups
          >
            <Chip
              v-for="(group, index) in selected"
              :key="`${group.id}-${index}`"
              tag="li"
              :removable="!readonly"
              data-test-food-group-chip
              @remove="removeGroup(group)"
            >
              {{ group.name }} {{ `${showStageIfDuplicate(group)}` }}
            </Chip>
          </ul>
        </output>
        <div class="formGroup formGroup--half mt-6">
          <label for="groupSelector">{{ $t('menus.foodItem.messages.searchFoodGroups') }}</label>
          <MultiSelect
            id="groupSelector"
            v-model="selected"
            :allow-empty="true"
            data-test-group-selector
            track-by="id"
            label="fullName"
            placeholder="Search food groups"
            :options="options"
            @select="addGroup"
            @remove="removeGroup"
            :disabled="readonly"
          >
            <template #option="props">
              <div class="flex gap-2">
                <SvgIcon
                  v-if="props.option.isSelected"
                  name="24-ic-check"
                  data-test-checked
                />
                <SvgIcon
                  v-if="!props.option.isSelected"
                  name="24-ic-add"
                  data-test-add
                />
                <span>{{ props.option.fullName }}</span>
              </div>
            </template>
          </MultiSelect>
        </div>
        <div
          class="buttonGroup buttongGroup--half"
          data-test-form-actions
        >
          <button
            type="submit"
            class="button button--primary"
            data-test-save
            :disabled="readonly || !touched"
          >
            {{ $t('actions.save') }}
          </button>
          <button
            type="reset"
            class="button button--secondary"
            data-test-reset
            :disabled="readonly || !touched"
          >
            {{ $t('actions.reset') }}
          </button>
        </div>
      </form>
    </loaded-content>
  </page-section>
</template>

<script>
import Chip from '@/components/Chip/Chip.vue'
import MultiSelect from '@/components/MultiSelect.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import returnPathMixin from '@/mixins/returnPathMixin'
import FoodItemMixin from '@/pages/Menus/FoodItems/FoodItemMixin'
import menuMixin from '@/pages/Menus/menuMixin'
import { useToast } from 'vue-toastification'

export default {
  components: {
    LoadedContent,
    MultiSelect,
    Chip,
  },
  mixins: [returnPathMixin, FoodItemMixin, menuMixin],
  props: {
    foodItemId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      touched: false,
      foodGroupsLoadingStatus: 'IDLE',
      selected: [],
    }
  },
  computed: {
    menuId() {
      return this.$route.query.menuId
    },
    readonly() {
      return this.menu && this.menu.archived
    },
    foodItem() {
      return this.$store.getters['menu-management/food-items/foodItemById'](this.foodItemId)
    },
    foodGroups() {
      if (!this.menu) return []
      else
        return this.$store.getters['menu-management/food-groups/foodGroupsById'](
          this.menu.foodGroupIds
        )
    },
    options() {
      if (!this.foodGroups.length || !this.foodItem) return []
      return this.foodGroups.map(({ id: foodGroupId, name, stageIds }) => {
        const hasStages = !!stageIds.length
        const stageNames = hasStages
          ? this.$store.getters['menu-management/stages/stagesById'](stageIds).map(
              ({ name }) => name
            )
          : null
        const fullName = stageNames ? this.generateDuplicateFoodGroupName(name, stageNames) : name
        const isSelected = !!(
          this.foodItem.foodGroupIds && this.foodItem.foodGroupIds.find((id) => foodGroupId === id)
        )
        return {
          id: foodGroupId,
          name,
          fullName,
          isSelected,
          stageNames,
        }
      })
    },
  },
  watch: {
    options(list) {
      this.selected = list.filter((option) => option.isSelected)
    },
  },
  created() {
    this.foodGroupsLoadingStatus = 'LOADING'
    this.$store
      .dispatch('menu-management/food-groups/getFoodGroups', { menuId: this.menuId })
      .then(() => {
        this.foodGroupsLoadingStatus = 'LOADED'
      })
      .catch(() => {
        this.foodGroupsLoadingStatus = 'ERROR'
      })
  },
  methods: {
    formatStageNames(stageNames) {
      return stageNames.join(' / ')
    },
    showStageIfDuplicate(option) {
      if (!this.foodItem) return
      const isDuplicate = this.selected.filter((group) => group.name === option.name).length > 1
      let stageNames = isDuplicate && option.stageNames ? option.stageNames : null
      if (stageNames) {
        return `(${this.formatStageNames(stageNames)})`
      } else {
        return ''
      }
    },
    addGroup(group) {
      if (!group.isSelected) {
        group.isSelected = true
        this.touched = true
      }
    },
    removeGroup(group) {
      if (group.isSelected) {
        group.isSelected = false
        this.selected = this.selected.filter((option) => option.id !== group.id)
        this.touched = true
      }
    },
    generateDuplicateFoodGroupName(name, stageNames) {
      return `${name} (${this.formatStageNames(stageNames)})`
    },
    onSave() {
      this.$store
        .dispatch('menu-management/food-items/saveItem', {
          menuId: this.menuId,
          itemId: this.foodItem.id,
          data: {
            foodGroupIds: this.selected.map((option) => option.id),
          },
        })
        .then(() => {
          this.toast.success(
            this.$t('menus.foodItem.messages.updateSuccessful', {
              item: this.foodItem.name,
            })
          )
          this.goBack({
            hash: this.foodItem.id,
            backupRoute: {
              name: 'food-items',
              params: {
                menuId: this.menuId,
              },
            },
          })
        })
    },
    onReset() {
      this.options.forEach((option) => {
        option.isSelected = !!this.foodItem.foodGroupIds.find((id) => id === option.id)
      })
      this.selected = this.options.filter((option) => option.isSelected)
      this.touched = false
    },
  },
}
</script>

<style></style>
