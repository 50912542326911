<template>
  <header class="section-header">
    <slot>
      <h2
        v-if="$slots['section-title']"
        class="section-title"
      >
        <slot name="section-title" />
      </h2>
      <h2
        v-if="$slots['section-title-hidden']"
        class="sr-only"
      >
        <slot name="section-title-hidden" />
      </h2>
      <div
        v-if="$slots['section-actions']"
        class="section-actions"
      >
        <slot name="section-actions" />
      </div>
      <div
        v-else-if="$slots['section-info']"
        class="section-info"
      >
        <slot name="section-info" />
      </div>
      <div
        v-if="$slots['section-navigation']"
        :class="{
          'section-navigation': $slots['section-title'],
          'section-title': !$slots['section-title'],
          'section-header-row': !$slots['section-navigation-actions'],
        }"
      >
        <slot name="section-navigation" />
      </div>
      <div
        v-if="$slots['section-navigation-tabs']"
        :class="{
          'section-navigation-tabs': $slots['section-title'],
          'section-title': !$slots['section-title'],
          'section-navigation-tabs-row':
            $slots['section-title'] && !$slots['section-navigation-actions'],
        }"
      >
        <slot name="section-navigation-tabs" />
      </div>
      <div
        v-if="$slots['section-navigation-actions']"
        class="section-navigation-actions"
      >
        <slot name="section-navigation-actions" />
      </div>
      <div
        v-if="$slots['section-description']"
        data-test-section-description
      >
        <slot name="section-description"></slot>
      </div>
    </slot>
  </header>
</template>

<script>
export default {}
</script>

<style lang="scss">
.section-header {
  color: theme('colors.grey.900');
  margin-bottom: theme('spacing.4');
  gap: theme('spacing.4');
  display: grid;
  align-items: center;
  grid-auto-flow: dense;
  justify-content: stretch;
}

.section-actions,
.section-navigation-actions,
.section-info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: theme('spacing.6');
}

.section-navigation-tabs {
  display: flex;
  align-items: flex-end;
  background-color: theme('colors.grey.100');
  margin: -0.5rem -1rem;
  padding: 1rem 1rem 0;
  align-self: stretch;
}

@include respond-min(medium) {
  .section-header {
    grid-template-columns: [col-start] repeat(2, minmax(20ch, auto)) [col-end];
  }

  .section-header-row {
    grid-column: col-start / col-end;
  }

  .section-title {
    grid-column: col-start / span 1;
    justify-self: start;
  }

  .section-actions,
  .section-info {
    grid-column-end: col-end;
    justify-self: end;
    flex-wrap: nowrap;
  }

  .section-navigation,
  .section-navigation-tabs {
    grid-column: col-start;
  }

  .section-title + .section-navigation-actions {
    grid-row-start: 1;
    justify-self: end;
  }
  .section-navigation-tabs + .section-navigation-actions {
    background-color: theme('colors.grey.100');
    margin: -0.5rem -1rem;
    padding: 0.5rem;
    justify-self: stretch;
    display: flex;
    justify-content: flex-end;
  }

  .section-navigation-tabs-row {
    grid-column-end: col-end;
  }

  .section-navigation-actions {
    grid-column-end: col-end;
    grid-row-start: 2;
  }
}
</style>
