<template>
  <tr
    :id="`target-row-${id}`"
    :class="{ 'tr-link': !targetIsInherited }"
    v-on="{ click: !targetIsInherited ? handleEdit : () => {} }"
  >
    <td class="lg-move-last td-small-col pr-0 pl-2">
      <button
        v-if="!targetIsInherited"
        v-tooltip="{
          content: tooltipTitle,
        }"
        data-test-waste-target-edit
        :title="tooltipTitle"
        :aria-label="tooltipTitle"
        class="button button--icon button--round button--primary"
        :class="{
          'show-on-hover-action': !isOpen,
        }"
        @click.stop="handleEdit"
      >
        <transition
          name="rotate-fade"
          mode="out-in"
        >
          <SvgIcon
            v-if="!isOpen"
            key="edit"
            name="24-ic-edit"
          />
          <SvgIcon
            v-else
            key="close"
            name="24-ic-clear"
          />
        </transition>
      </button>
    </td>
    <td>
      <span class="h3">
        <b>{{ `${target.value}${displayTargetUnit}` }}</b>
      </span>
    </td>
    <td>
      {{ displayEffectiveDate }}
    </td>
    <td>
      {{ displayEndDate }}
    </td>
    <td>
      <div class="flex flex-middle flex-between">
        <div class="flex-column">
          <span>{{ displayTargetType }}</span>
          <div
            v-if="targetIsInherited"
            class="text-sm"
          >
            {{ $t('organisations.messages.inheritedTarget') }}
            <router-link
              :to="{
                name: 'hierarchy-node-reporting-general-settings',
                params: { nodeId: target.sourceLocation.id },
                query: { ...$route.query, preOpenWasteTarget: id },
              }"
            >
              <span>{{ displaySource }}</span>
            </router-link>
          </div>
          <span
            v-else
            class="text-sm"
            >{{ displaySource }}</span
          >
        </div>
        <SvgIcon
          v-if="targetIsInherited"
          v-tooltip="{
            content: $t('organisations.messages.inheritedTargetTooltip', {
              node: displaySource,
            }),
          }"
          class="ml-1"
          name="24-ic-info"
        />
      </div>
    </td>
    <td>
      <span class="text-sm">{{ target.description }}</span>
    </td>
    <td>
      <span
        v-if="active"
        class="status-label"
        :class="`status-label--success`"
      >
        <SvgIcon
          key="edit"
          class="mr-1 inline"
          name="48-ic-winnow"
        />
        {{ 'Currently active' }}
      </span>
    </td>
  </tr>
</template>
<script>
import moment from 'moment'

import { getOrgDisplayName } from '@/utils/organisations'
import { DATE_FORMAT } from '@/store/constants'

export default {
  props: {
    id: String,
    isOpen: Boolean,
    locationId: String,
    active: Boolean,
  },
  emits: ['edit'],
  computed: {
    target() {
      return this.$store.getters['hierarchy/waste-targets/byId'](this.id)
    },

    wasteTypesMap() {
      return {
        SALES_PERCENTAGE: {
          name: this.$t('titles.wastePercentageAsSales'),
          unit: '%',
        },
        GRAMS_PER_COVER: {
          name: this.$t('titles.gramsPerCover'),
          unit: 'g',
        },
      }
    },
    tooltipTitle() {
      return this.isOpen ? this.$t('actions.close') : this.$t('actions.edit')
    },
    targetIsInherited() {
      return this.target.sourceLocation.id !== this.locationId
    },
    displayEndDate() {
      return this.target.targetEndDate
        ? moment(this.target.targetEndDate).format(DATE_FORMAT)
        : this.$t('organisations.messages.noEndDate')
    },
    displayEffectiveDate() {
      return moment(this.target.effectiveFromDate).format(DATE_FORMAT)
    },
    displayTargetType() {
      return this.wasteTypesMap[this.target.type].name
    },
    displayTargetUnit() {
      return this.wasteTypesMap[this.target.type].unit
    },
    displaySource() {
      const { sourceLocation } = this.target
      const orgName = getOrgDisplayName(sourceLocation)

      return this.targetIsInherited ? `${sourceLocation.level}: ${orgName}` : orgName
    },
  },
  methods: {
    handleEdit() {
      this.$emit('edit')
    },
  },
}
</script>
