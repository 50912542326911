<template>
  <button
    class="button button--sm filter-option"
    :class="{ 'button--lemon': checked, 'button--secondary': !checked }"
    :aria-pressed="checked ? 'true' : 'false'"
    :disabled="disabled"
    data-test-filter-option
    @click="onClick"
  >
    <SvgIcon
      v-if="icon"
      :name="icon"
    />
    <span>
      <slot>{{ title }}</slot>
    </span>
    <span
      v-if="!counterHighlight && typeof counter === 'number'"
      class="filter-counter"
    >
      ({{ counter }})
    </span>
    <span
      v-if="counterHighlight && counter >= 0"
      :class="`filter-counter-highlight t-${counterHighlight}`"
    >
      {{ counter }}
    </span>
  </button>
</template>
<script>
export default {
  props: ['title', 'checked', 'counter', 'counterHighlight', 'disabled', 'icon'],
  emits: ['selected'],
  methods: {
    onClick() {
      if (!this.checked) {
        this.$emit('selected')
      }
    },
  },
}
</script>
<style lang="scss">
.filter-option {
  margin-top: theme('spacing.2');
  margin-right: theme('spacing.2');
  min-width: 10ch;
  justify-content: center;
}

.filter-counter-highlight {
  background: theme('colors.white');
  border-radius: 0 theme('borderRadius.sm') theme('borderRadius.sm') 0;
  padding: theme('spacing.1') theme('spacing.2');
  margin: calc(-1 * theme('spacing.1')) calc(-1 * theme('spacing.3')) calc(-1 * theme('spacing.1'))
    theme('spacing.2');
  border-left: 1px solid theme('colors.blueberry.DEFAULT');
}
</style>
