<template>
  <TabsLvl2Links>
    <li
      v-for="filter in filters"
      :key="filter.id"
    >
      <router-link
        class="tab-item"
        :to="filter.route"
      >
        {{ filter.title }}
        <template v-if="filter.items"> ({{ filter.items }}) </template>
      </router-link>
    </li>
  </TabsLvl2Links>
</template>

<script>
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'
export default {
  components: {
    TabsLvl2Links,
  },
  props: {
    counters: Object,
    selectedId: String,
  },
  computed: {
    filters() {
      return [
        {
          id: 'FOOD_ITEM',
          title: 'Food items',
          items: this.counters.foodItems,
          route: {
            name: 'bulk-update-food-items',
          },
        },
        {
          id: 'FOOD_GROUP',
          title: 'Food groups',
          items: this.counters.foodGroups,
          route: {
            name: 'bulk-update-food-groups',
          },
        },
        {
          id: 'STAGE',
          title: 'Stages',
          items: this.counters.stages,
          route: {
            name: 'bulk-update-stages',
          },
        },
        {
          id: 'UPLOAD',
          title: 'Upload',
          route: {
            name: 'bulk-update-upload',
          },
        },
      ]
    },
  },
}
</script>

<style></style>
