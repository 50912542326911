<template>
  <page-section>
    <section-header>
      <template #section-title>
        {{ $t('menus.foodItems.title') }}
      </template>
      <!-- Add item -->
      <template #section-actions>
        <!-- @TODO: Replace translations with generic keys -->
        <div>
          <router-link
            :title="$t('waste.actions.viewAsList')"
            :active-class="'button--primary'"
            :class="['button', 'button--icon', 'mx-1']"
            :to="{
              name: 'food-items-list',
              params: $route.params,
              query: $route.query.page ? { ...$route.query, page: undefined } : $route.query,
            }"
          >
            <SvgIcon name="24-ic-list" />
            <span class="sr-only">{{ $t('waste.actions.viewAsList') }}</span>
          </router-link>
          <router-link
            :title="$t('waste.actions.viewAsGrid')"
            :active-class="'button--primary'"
            :class="['button', 'button--icon', 'mx-1']"
            :to="{
              name: 'food-items-grid',
              params: $route.params,
              query: $route.query.page ? { ...$route.query, page: undefined } : $route.query,
            }"
          >
            <SvgIcon name="24-ic-cards" />
            <span class="sr-only">{{ $t('waste.actions.viewAsGrid') }}</span>
          </router-link>
        </div>
        <router-link
          v-if="!readonly"
          class="button button--secondary"
          data-test-create-food-item
          :to="{
            name: 'food-create',
            query: { menuId, returnPath: $route.path, ...$route.query },
          }"
        >
          <SvgIcon name="24-ic-add" />
          <span>{{ $t('menus.foodItems.actions.addItem') }}</span>
        </router-link>
      </template>

      <!-- Filters -->
      <template
        v-if="$can('manage', 'menus')"
        #section-navigation-tabs
      >
        <TabsLvl2Links
          id="filters-navigation"
          data-test-filters-group
        >
          <li
            v-for="filter in visibleFilters"
            :key="filter.id"
            data-test-filter-option
          >
            <button
              class="tab-item"
              :class="{ 'is-active': filter.id === filteredBy }"
              @click="onFilterChange({ id: filter.id })"
            >
              <span>{{ filter.title }}</span>
            </button>
          </li>
        </TabsLvl2Links>
      </template>

      <!-- Search -->
      <template #section-navigation-actions>
        <filter-input
          v-model="searchTerm"
          :total-results="totals.items"
          @search="onSearch"
        />
      </template>
    </section-header>

    <Food-items-advanced-filters
      :menu-id="menuId"
      :selected-stages="selectedStageFilters"
      :selected-food-groups="selectedFoodGroupFilters"
      @filter="onAdvancedFiltersChange"
    />

    <loaded-content
      :is-loading="loadingStatus === 'LOADING' || searchStatus === 'LOADING'"
      :has-error="loadingStatus === 'ERROR'"
      :has-data="loadingStatus === 'LOADED' && !!foodItems.length"
    >
      <template
        v-if="searchTerm && searchStatus !== 'LOADING'"
        #no-data-type
        >{{ $t('messages.noResults') }}</template
      >
      <template #no-data>
        <p
          v-if="searchTerm"
          class="text-center"
          data-test-empty-list
        >
          {{ $t('menus.foodItems.messages.noSearchResults') }}
        </p>
        <p
          v-else
          class="text-center"
          data-test-empty-list
        >
          {{ $t('messages.noResults') }}
        </p>
      </template>

      <router-view
        @food-item-deleted="reloadAndCheckForIssues"
        @data-request="reloadAndCheckForIssues"
      />
      <Pagination
        v-if="totalOnCurrentFilter"
        :total="totalOnCurrentFilter"
        :size="pageSize"
      />
    </loaded-content>
  </page-section>
</template>

<script>
import { useToast } from 'vue-toastification'
import FilterInput from '@/components/FilterInput.vue'
import FiltersGroup from '@/components/FiltersGroup.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import LoadedContent from '@/components/LoadedContent.vue'
import FoodItemsAdvancedFilters from './FoodItemsAdvancedFilters.vue'
import TabsLvl2Links from '@/components/Tabs/TabsLvl2Links.vue'

import { PAGE_SIZE_MENU_ITEMS, FOOD_ITEM_STATES } from '@/store/constants'
import menuMixin from '../menuMixin'
import axios from 'axios'
import { isEqual } from 'lodash'
import { mapGetters } from 'vuex'

export const DEFAULT_SORT_FIELD = 'name'
export const DEFAULT_SORT_ORDER = 'ascending'
export const DEFAULT_SORT_FIELD_ENGLISH = 'nameEnglish'

export default {
  components: {
    FilterInput,
    FiltersGroup,
    Pagination,
    LoadedContent,
    FoodItemsAdvancedFilters,
    TabsLvl2Links,
  },
  mixins: [menuMixin],
  props: {
    menuId: String,
  },
  setup: () => {
    return {
      toast: useToast(),
    }
  },
  data() {
    return {
      source: null,
      pageSize: PAGE_SIZE_MENU_ITEMS,
      searchTerm: this.$route.query.search,
      loadingStatus: 'IDLE',
      searchStatus: 'IDLE',
      totalOnCurrentFilter: 0,
    }
  },
  computed: {
    ...mapGetters('menu-management', ['displayEnglish']),
    readonly() {
      return (this.menu && this.menu.archived) || !this.$can('manage', 'menus')
    },
    totals() {
      let statistics = this.$store.getters['menu-management/menus/menuStatistics'](this.menuId)
      return {
        items: statistics.foodItems,
        pending: statistics.foodItemsPending,
        failed: statistics.foodItemsFailed,
        unmapped: statistics.foodItemsUnmapped,
        costZero: statistics.foodItemsCostZero,
        costNull: statistics.foodItemsCostNull,
        portionMissing: statistics.foodItemsPortionMissing,
      }
    },
    foodItems() {
      return this.$store.getters['menu-management/menus/foodItemsInMenu'](this.menuId)
    },
    filteredBy() {
      return this.$route.query.filteredBy || this.filters[0].id
    },
    pageNumber() {
      return this.$route.query.page ? parseInt(this.$route.query.page - 1) : undefined
    },
    totalPending() {
      return this.totals.pending + this.totals.failed + this.totals.unmapped
    },
    filters() {
      return [
        {
          id: 'All',
          title: this.$t('menus.foodItems.filters.all'),
          items: this.totals.items,
        },
        {
          id: 'Pending',
          title: this.$t('menus.foodItems.filters.pending'),
          items: this.totalPending,
        },
        {
          id: 'zeroCost',
          title: this.$t('menus.foodItems.filters.costZero'),
          items: this.totals.costZero,
        },
        {
          id: 'nullCost',
          title: this.$t('menus.foodItems.filters.costNull'),
          items: this.totals.costNull,
        },
        {
          id: 'missingPortion',
          title: this.$t('menus.foodItems.filters.portionMissing'),
          items: this.totals.portionMissing,
        },
      ]
    },
    visibleFilters() {
      return this.filters.filter((filter) => filter.items > 0)
    },
    selectedStageFilters() {
      let stages = this.$route.query.stages
      let stagesList =
        stages && (Array.isArray(stages) ? stages : [stages]).map((id) => parseInt(id, 10))
      return stagesList
    },
    selectedFoodGroupFilters() {
      let foodGroups = this.$route.query.foodGroups
      let foodGroupsList =
        foodGroups &&
        (Array.isArray(foodGroups) ? foodGroups : [foodGroups]).map((id) => parseInt(id, 10))
      return foodGroupsList
    },
    sortBy() {
      /**
       * Default sorting is by name but if the user decides to switch to english (on non english menus), the same `name` column button now has to request `nameEnglish` sorting
       * Also, if there is no sort column defined, we request the `nameEnglish` anyway because the default response from BE is to sort by `name`
       */
      let sortBy = this.$route.query.sortBy
      if (this.displayEnglish && (!sortBy || sortBy === DEFAULT_SORT_FIELD)) {
        sortBy = DEFAULT_SORT_FIELD_ENGLISH
      }
      return sortBy
    },
    sortDir() {
      return this.$route.query.sortDir
    },
    sortedByAsString() {
      if (!this.sortBy) return undefined
      return this.sortDir === 'descending' ? `-${this.sortBy}` : this.sortBy
    },
  },
  watch: {
    '$route.query'(to, from) {
      if (!isEqual(to, from)) {
        this.searchTerm = to.search
        this.loadData()
      }
    },
    'displayLanguage'() {
      this.loadData()
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    getData() {
      let states =
        this.filteredBy === 'Pending'
          ? [FOOD_ITEM_STATES.UNMAPPED, FOOD_ITEM_STATES.PENDING, FOOD_ITEM_STATES.FAILED]
          : null
      let filter
      if (['zeroCost', 'nullCost', 'missingPortion'].includes(this.filteredBy)) {
        filter = { [this.filteredBy]: true }
      }
      if (this.selectedStageFilters) {
        filter = filter || {}
        filter.stages = this.selectedStageFilters
      }
      if (this.selectedFoodGroupFilters) {
        filter = filter || {}
        filter.foodGroups = this.selectedFoodGroupFilters
      }
      if (this.source !== null) {
        this.source.cancel()
      }
      this.source = axios.CancelToken.source()
      return this.$store
        .dispatch('menu-management/food-items/getFoodItems', {
          menuId: this.menuId,
          query: {
            search: this.searchTerm ? this.searchTerm : undefined,
            page: this.pageNumber,
            filter: {
              state: states,
              ...filter,
            },
            sortBy: this.sortedByAsString,
          },
          options: { cancelToken: this.source.token },
        })
        .then(({ foodItemIds, total }) => {
          // add loaded food item ids to the menu to which they belong in the store
          this.$store.commit('menu-management/menus/UPDATE_MENU', {
            id: this.menuId,
            foodItemIds,
          })
          this.totalOnCurrentFilter = total
        })
    },
    loadData() {
      this.loadingStatus = 'LOADING'
      this.getData()
        .then(this.$store.dispatch('menu-management/menus/getStatistics', { menuId: this.menuId }))
        .then(() => {
          this.loadingStatus = 'LOADED'
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            this.toast.error(this.$t('toast.error.getData'))
            this.loadingStatus = 'ERROR'
          }
        })
    },

    onSearch() {
      let search = this.searchTerm ? this.searchTerm : undefined
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, search, page: undefined },
      })
    },
    onFilterChange({ id }) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, filteredBy: id, page: undefined },
      })
    },
    onAdvancedFiltersChange(filters) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          ...filters,
          page: undefined,
        },
      })
    },
    reloadAndCheckForIssues() {
      this.getData().then(
        this.$store.dispatch('menu-management/menus/checkMenuIssues', { menuId: this.menuId })
      )
    },
  },
}
</script>

<style lang="scss"></style>
