<template>
  <form
    action="/"
    class="filter-input formGroup"
    data-test-filter-input
    @submit.prevent="runSearch({ type: 'INSTANT' })"
    @reset.prevent="clearField"
  >
    <label
      :for="id"
      class="filter-input-label"
      :class="{ 'sr-only': labelHidden }"
    >
      <slot>{{ $t('filter.placeholder') }}</slot>
    </label>
    <input
      :id="id"
      v-model="searchTerm"
      type="search"
      class="formControl filter-input-field"
      aria-controls="filterInputResults"
      @input="termChanged"
      @focus="labelHidden = true"
      @blur="searchTerm ? (labelHidden = true) : (labelHidden = false)"
    />
    <Loading-Spinner
      v-if="status === 'LOADING'"
      class="spinner--sm filter-input-search-button"
    />
    <button
      v-if="['IDLE', 'LOADED'].indexOf(status) >= 0"
      type="submit"
      class="button button--icon filter-input-search-button"
    >
      <SvgIcon name="24-ic-search" />
      <span class="sr-only">{{ $t('actions.search') }}</span>
    </button>
    <button
      v-if="!!searchTerm"
      type="reset"
      class="button button--icon filter-input-reset-button"
    >
      <SvgIcon name="24-ic-clear" />
      <span class="sr-only">{{ $t('actions.reset') }}</span>
    </button>
    <div
      id="filterInputResults"
      role="region"
      aria-live="polite"
      class="sr-only"
    >
      {{ $t('messages.resultsFound', { number: totalResults }) }}
    </div>
  </form>
</template>

<script>
// @TODO:
// - add an accessible report when results are loaded
import { DEBOUNCE_DELAY } from '@/store/constants'
export default {
  props: {
    id: {
      type: String,
      default: 'filterInput',
    },
    // this, along with emitting an input, makes v-model work "natively"
    modelValue: String,
    // debounce search event
    typeDelay: {
      type: Number,
      default: 300,
    },
    // populate this for accessibility
    totalResults: Number,
    status: {
      type: String,
      default: 'IDLE',
    },
  },
  emits: ['search', 'update:modelValue'],
  data() {
    return {
      searchTerm: '',
      debounce: 0,
      labelHidden: false,
    }
  },
  watch: {
    modelValue: {
      handler(value) {
        this.searchTerm = value
        this.labelHidden = value != null && value != ''
      },
      immediate: true,
    },
  },
  methods: {
    runSearch({ type } = {}) {
      if (!this.searchTerm) {
        type = 'INSTANT'
      }
      if (type === 'INSTANT') {
        // emit an input event to make v-model work on this components
        this.$emit('update:modelValue', this.searchTerm)
        this.$emit('search', this.searchTerm)
      } else {
        if (this.debounce) window.clearTimeout(this.debounce)
        this.debounce = window.setTimeout(() => {
          // emit an input event to make v-model work on this components at the same debounce delay as the search event
          // this helps when doing searches locally, in page
          this.$emit('update:modelValue', this.searchTerm)
          this.$emit('search', this.searchTerm)
        }, DEBOUNCE_DELAY)
      }
    },
    termChanged() {
      this.runSearch()
      // keep the label hidden while term is changing because the focus is still in the input
      this.$nextTick(() => {
        this.labelHidden = true
      })
    },
    clearField() {
      this.searchTerm = ''
      this.labelHidden = false
      this.$emit('update:modelValue', this.searchTerm)
      this.runSearch({ type: 'INSTANT' })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-input {
  display: flex;
  position: relative;
}

.filter-input-field {
  padding-left: theme('spacing.10');
  padding-right: theme('spacing.8');
}

.filter-input-label {
  left: theme('spacing.10');
  position: absolute;
  padding: theme('spacing.inputPaddingY') theme('spacing.inputPaddingX')
    theme('spacing.inputPaddingY') 0;
  line-height: theme('lineHeight.normal');
  height: theme('spacing.inputHeight');
  border: theme('borderSize.input') solid transparent;
  z-index: 1;
  font-weight: theme('fontWeight.normal');
  color: theme('colors.grey.500');
}

.filter-input-search-button {
  position: absolute;
  left: theme('spacing.3');
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}

.filter-input-reset-button {
  position: absolute;
  right: theme('spacing.3');
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}
</style>
