<script>
import { FocusTrap } from 'focus-trap-vue'
const getScrollbarWidth = function () {
  // thx d.walsh
  const scrollDiv = document.createElement('div')
  scrollDiv.className = 'modal-scrollbarMeasure'
  document.body.appendChild(scrollDiv)

  const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)

  return scrollbarWidth
}

export default {
  name: 'HubModal',
  components: {
    FocusTrap,
  },
  props: ['wrapperClass'],
  emits: ['open', 'close'],
  data() {
    return {
      isOpen: false,
      initialPadding: null,
      bodyModified: false,
    }
  },
  methods: {
    open() {
      this.$emit('open')
      if (this.isOpen === true) {
        return
      }

      this._fillScrollbarSpace()

      this.isOpen = true
      document.body.classList.add('modal-isOpen')
    },
    close() {
      this.$emit('close')
      if (this.isOpen === false) {
        return
      }

      this._resetScrollbarSpace()

      this.isOpen = false
      document.body.classList.remove('modal-isOpen')
    },

    // These private methods are borrowed from Bootstrap 4's modal.js
    // They are used to fix content jumping in case of scrollbars on the body
    _fillScrollbarSpace() {
      const rect = document.body.getBoundingClientRect()
      const isBodyOverflowing = rect.left + rect.right < window.innerWidth

      if (isBodyOverflowing) {
        this.initialPadding = document.body.style.paddingRight
        const computedPadding = window
          .getComputedStyle(document.body)
          .getPropertyValue('padding-right')

        document.body.style.paddingRight = `${parseFloat(computedPadding) + getScrollbarWidth()}px`
      }
    },
    _resetScrollbarSpace() {
      document.body.style.paddingRight = this.initialPadding
      this.initialPadding = null
    },
  },
}
</script>

<template>
  <Teleport
    v-if="isOpen"
    to="#modal-dropzone"
  >
    <div :class="['modal', wrapperClass]">
      <div class="modal-backdrop"></div>
      <div class="modal-dialog">
        <FocusTrap
          :active="isOpen"
          :click-outside-deactivates="true"
          :tabbableOptions="{
            /**
             * this stops unit tests from failing because of:
             * https://github.com/focus-trap/focus-trap#error-your-focus-trap-must-have-at-least-one-container-with-at-least-one-tabbable-node-in-it-at-all-times
             * this also means that wherever we implement a <FocusTrap>, we also need to provide a tabbable item inside like the first child <div> of this instance
             * `tabindex` of `-1`  is all that's needed to make this work
             */
            displayCheck: 'none',
          }"
          @activate="open"
          @deactivate="close"
        >
          <div
            class="modal-content"
            tabindex="-1"
          >
            <slot :close="close" />
          </div>
        </FocusTrap>
      </div>
    </div>
  </Teleport>
</template>
